import * as yup from 'yup';

const referFriendSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .required('First Name is required'),
  lastName: yup
    .string()
    .trim()
    .required('Last Name is required'),
  email: yup
    .string()
    .email()
    .trim()
    .required('Email is required'),
  message: yup
    .string()
    .required('Message is required'),
}).required();

export type ReferFriendSchema = yup.InferType<typeof referFriendSchema>;

export default referFriendSchema;
