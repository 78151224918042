import React, { useState } from 'react';

import { downArrowIcon } from '../../../../assets/Icons';
import {
  TFStyledDropdown,
  TFSelectedWrapper,
  TFOptionsWrapper,
  Icon,
  Option,
  Selected,
} from './Styles';

import { DropdownOption } from '../types';
import { DropdownProps } from './types';

const TableFilterDropdown: React.FC<DropdownProps> = ({
  overrideLabel,
  options = [],
  selectedOptions,
  placeholder,
  disabled = false,
  onOptionClick = () => null,
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<DropdownOption | null>(null);

  const handleOptionClick = (option: DropdownOption) => {
    onOptionClick(option);
    setOpen(false);
    setSelected(option);
  };

  const handleDropDownClick = () => {
    if (!disabled) setOpen(!open);
  };

  return (
    <TFStyledDropdown>
      <TFSelectedWrapper
        open={open}
        disabled={disabled}
        onClick={handleDropDownClick}
      >
        <Selected>{overrideLabel || selected || placeholder}</Selected>
        <Icon open={open} src={downArrowIcon} />
      </TFSelectedWrapper>
      {open && (
        <TFOptionsWrapper outlined>
          {options.map((option: DropdownOption) => (
            <Option
              selected={selectedOptions.some((opt) => opt === option.value)}
              key={option.value}
              onClick={() => handleOptionClick(option)}
            >
              <Selected>{option.label}</Selected>
            </Option>
          ))}
        </TFOptionsWrapper>
      )}
    </TFStyledDropdown>
  );
};

export default TableFilterDropdown;
