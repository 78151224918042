import React from 'react';
import { useField } from 'formik';

import {
  OuterWrapper,
  Label,
  Input,
  StyledInputLabel,
  RadioIcon,
} from './Styles';

import { StyledRadioProps } from './types';

const StyledRadio: React.FC<StyledRadioProps> = ({ name, value, label, icon, onChange, checked, inactive }) => {
  // Component Must be used inside of <Formik> tags to access Formik context
  const [field, meta] = useField(name);

  const { error } = meta;

  return (
    <OuterWrapper>
      <Input
        checked={checked}
        type="radio"
        id={value}
        name={name}
        value={value}
        onChange={() => onChange(value)}
      />
      <StyledInputLabel
        {...field}
        error={error}
        htmlFor={value}
        inactive={inactive}
      >
        <RadioIcon
          src={icon}
          inactive={inactive}
        />
        <Label inactive={inactive}>{label}</Label>
      </StyledInputLabel>
    </OuterWrapper>
  );
};

export default StyledRadio;
