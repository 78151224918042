import { apiFetch, apiRequest } from '../Utils';
import { setAdminUser } from '../../store/contexts/admin/user/actions';
import { serverAdminAPI } from '../../../CONSTANTS';

import { APIThunkResult } from '../../types/API';
import { UserDTO } from '../../types/DTO';

export const getAdminProfile = (): APIThunkResult<UserDTO> => (
  apiRequest<UserDTO>(async (dispatch) => {
    const { data, error } = await apiFetch<UserDTO>({
      method: 'GET',
      url: `${serverAdminAPI}/user`,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setAdminUser(data.user));

    return data;
  }, true)
);
