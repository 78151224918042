import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import dayjs from 'dayjs';

import { supportMessageStatusOptions } from '../../../../CONSTANTS';
import useStrings from '../../../../hooks/useStrings';
import { getThreadReplies, sendReplyMessage } from '../../../../lib/api/admin/contact';

import Grid from '../../../../components/Common/Grid';
import Button from '../../../../components/Common/Button';
import Dropdown from '../../../../components/Common/Dropdown';
import TextArea from '../../../../components/Common/TextArea';

import {
  CustomerInfoWrapper,
  EmailContent,
  EmailTitle,
  InfoLabel,
  Info,
  CustomerName,
  TimeAgo,
  LabelWrapper,
  ReplyWrapper,
  ReplyHeading,
  ButtonWrapper,
  ActionButtonWrapper,
} from './Styles';
import {
  ImageContainer,
  ProfileImage,
  ProfileInitials,
} from '../../Settings/AdminAccounts/Styles';
import { LineBreak } from '../../../../components/Common/CreatePlanModal/Styles';

import { EmailViewProps } from './types';
import { SupportMessageStatus } from '../../../../lib/types/SupportMessage';
import { SupportMessageListView } from '../../../../lib/types/DBViews';
import { APIThunkDispatch } from '../../../../lib/types/API';

const EmailView: React.FC<EmailViewProps> = ({
  supportMessage,
  handleUpdateThreadStatus,
}) => {
  const [{ Pages: { Support: Strings } }] = useStrings();
  const history = useHistory();
  const dispatch: APIThunkDispatch = useDispatch();

  const [threadReplies, setThreadReplies] = useState<SupportMessageListView[]>([]);

  const splitMessageContent = (message: string) => message
    .split('\n')
    .map((p) => (
      <>{p}<br /></>
    ));

  const renderReplies = () => threadReplies
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
    .map((reply) => (
      <Grid row marginTop="1.5rem" maxWidth="100%">
        <ReplyWrapper>
          <ReplyHeading>{`${Strings.replySent.toUpperCase()} ${dayjs(reply.createdAt).format('h:mma DD/MM/YYYY')}`}</ReplyHeading>
          <EmailContent>{splitMessageContent(reply.message)}</EmailContent>
        </ReplyWrapper>
      </Grid>
    ));

  useEffect(() => {
    (async () => {
      const { data } = await dispatch(getThreadReplies(supportMessage.supportMessageId));
      if (data) setThreadReplies(data.replies);
    })().catch(() => null);
  }, [dispatch, supportMessage]);

  const initials = supportMessage.customerName.split(' ').map((n) => n.slice(0, 1));

  const getMessageCreatedAgo = (): string => {
    const createdAgoSeconds = (new Date().getTime() - new Date(supportMessage.createdAt).getTime()) / 1000;
    if (createdAgoSeconds < 60) return `${Math.floor(createdAgoSeconds)}s ago`;
    if ((createdAgoSeconds / 60) < 60) return `${Math.floor(createdAgoSeconds / 60)}m ago`;
    if ((createdAgoSeconds / 60 / 60) < 24) return `${Math.floor(createdAgoSeconds / 60 / 60)}h ago`;
    return `${Math.floor(createdAgoSeconds / 60 / 60 / 24)}d ago`;
  };

  return (
    <Formik
      initialValues={{
        parentSupportMessageId: supportMessage.supportMessageId,
        message: '',
      }}
      validate={(values) => (values.message ? {} : { message: Strings.messageRequiredError })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        const { data } = await dispatch(sendReplyMessage(values.message, values.parentSupportMessageId));
        if (data) {
          setThreadReplies([data.message, ...threadReplies]);
          handleUpdateThreadStatus(supportMessage.supportMessageId, SupportMessageStatus.RESPONDED);
          resetForm();
        }
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({
        values,
        errors,
        isSubmitting,
        setFieldValue,
        setFieldError,
        handleSubmit,
      }) => (
        <>
          <Grid row>
            <ActionButtonWrapper>
              <Button
                variant="secondary"
                rounded
                height="56px"
                onClick={() => history.push(`user/${supportMessage.createdByUserId}/plans`)}
              >
                {Strings.viewProfileButton}
              </Button>
              <Button
                variant="secondary"
                rounded
                height="56px"
                onClick={() => history.push(`user/${supportMessage.createdByUserId}/notes`)}
              >
                {Strings.addNoteButton}
              </Button>
            </ActionButtonWrapper>
            <Grid column alignItems="center" justify="flex-end">
              <Dropdown
                name="status"
                label={Strings.statusDropdownLabel}
                placeholder=""
                onLoadOption={supportMessage.status}
                options={supportMessageStatusOptions}
                onOptionClick={(option) => handleUpdateThreadStatus(
                  supportMessage.supportMessageId,
                  SupportMessageStatus[option as keyof typeof SupportMessageStatus],
                )}
                margin="0.5rem 0"
              />
            </Grid>
          </Grid>
          <Grid row marginTop="0.5rem">
            <LineBreak />
          </Grid>
          <Grid row marginTop="0.5rem">
            <LabelWrapper>
              <InfoLabel>{Strings.emailLabel}</InfoLabel>
              <Info>{supportMessage.email}</Info>
            </LabelWrapper>
            <LabelWrapper>
              <InfoLabel>{Strings.phoneLabel}</InfoLabel>
              <Info>{supportMessage.phone}</Info>
            </LabelWrapper>
          </Grid>
          <Grid row marginTop="0.5rem">
            <LineBreak />
          </Grid>
          <Grid row marginTop="1rem">
            <CustomerInfoWrapper>
              <ImageContainer>
                <ProfileImage>
                  <ProfileInitials>{initials}</ProfileInitials>
                </ProfileImage>
              </ImageContainer>
              <CustomerName>{supportMessage.customerName}</CustomerName>
              <TimeAgo>{getMessageCreatedAgo()}</TimeAgo>
            </CustomerInfoWrapper>
          </Grid>
          <Grid row marginTop="2rem">
            <EmailTitle>{supportMessage.subject}</EmailTitle>
          </Grid>
          <Grid row marginTop="1rem">
            <EmailContent>
              {splitMessageContent(supportMessage.message)}
            </EmailContent>
          </Grid>
          <Grid row>
            <LineBreak />
          </Grid>
          {renderReplies()}
          {!threadReplies.length && (
            <>
              <Grid row marginTop="1.5rem">
                <TextArea
                  name="message"
                  label={Strings.replyInputLabel}
                  rows={8}
                  placeHolder={Strings.replyInputPlaceHolder}
                  handleChange={(val) => {
                    setFieldValue('message', val);
                    setFieldError('message', '');
                  }}
                  value={values.message}
                  error={errors.message}
                />
              </Grid>
              <Grid row maxWidth="100%" marginTop="0.5rem">
                <ButtonWrapper>
                  <Button
                    width="160px"
                    onClick={handleSubmit}
                    variant="primary"
                    disabled={isSubmitting}
                    rounded
                  >
                    {Strings.replyInputSubmitButton}
                  </Button>
                </ButtonWrapper>
              </Grid>
            </>
          )}
        </>
      )}
    </Formik>
  );
};
export default EmailView;