import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import dayjs from 'dayjs';

import useStrings from '../../../../hooks/useStrings';
import { buildHandlers } from '../../../../components/Common/Table/handlers';
import {
  PLAN_STATUS_DISPLAY,
  INSTALLMENT_FREQUENCY_DISPLAY,
  PLAN_TYPE_DISPLAY_ADMIN_TABLES,
  hiddenPlanStatusOptions,
  stateOptions,
} from '../../../../CONSTANTS';

import Grid from '../../../../components/Common/Grid';
import Pagination from '../../../../components/Common/Table/Pagination';
import SubHeader from './SubHeader/SubHeader';

import { Spinner } from '../../../../components/UI/LoadingScreen/Styles';
import {
  TableWrapper,
  Select,
  TableJssOverrides,
  NoRecordsText,
} from '../../../../components/Common/Table/Styles';

import {
  TableColumn,
} from '../../../../components/Common/Table/types';

import { DuePlansTableProps } from './types';
import { ApplicationState } from '../../../../lib/store';
import { OrderDirection, PlanFilterOptions } from '../../../../lib/types/API';
import { PlanDataView } from '../../../../lib/types/DBViews';
import { PlanStatus } from '../../../../lib/types/Plan';

const DuePlansTable: React.FC<DuePlansTableProps<PlanFilterOptions>> = ({
  currentData,
  recordCount,
  filter,
  updateFilter,
  onRowClicked,
  clearFilters,
  onSetPlanStatus,
  filterLimits,
}) => {
  const columns: TableColumn<PlanDataView>[] = [
    {
      name: 'Name',
      selector: 'customerName',
      sortable: true,
      grow: 2,
      conditionalCellStyles: [
        {
          when: () => true,
          style: { fontWeight: 'bold' },
        },
      ],
    },
    {
      name: 'Reg No',
      selector: 'registration',
      sortable: true,
      grow: 0.2,
      format: (row) => row.registration?.toString() || '',
    },
    {
      name: 'Plan Name',
      selector: 'planName',
      sortable: true,
      grow: 1.5,
    },
    {
      name: 'Type',
      selector: 'planType',
      sortable: true,
      grow: 0.8,
      format: (row) => PLAN_TYPE_DISPLAY_ADMIN_TABLES[row.planType as string],
    },
    {
      name: 'State',
      selector: 'vehicleRegisteredState',
      sortable: true,
      grow: 0.8,
      format: (row) => stateOptions.find((e) => e.value === row.vehicleRegisteredState)?.label || '',
    },
    {
      name: 'Frequency',
      selector: 'frequency',
      sortable: true,
      grow: 0.2,
      format: (row) => INSTALLMENT_FREQUENCY_DISPLAY[row.frequency as string],
    },
    {
      name: 'Start Date',
      selector: 'startDate',
      sortable: true,
      format: (row) => dayjs(row.startDate as Date).format('DD/MM/YYYY'),
    },
    {
      name: 'End Date',
      selector: 'endDate',
      sortable: true,
      grow: 1,
      format: (row) => dayjs((row.scheduledEndDate || row.endDate) as Date).format('DD/MM/YYYY'),
    },
    {
      name: 'Due Date',
      selector: 'dueDate',
      sortable: true,
      format: (row) => dayjs(row.dueDate as Date).format('DD/MM/YYYY'),
    },
    {
      name: 'Amount',
      selector: 'value',
      sortable: true,
      format: (row) => `$${Number(row.value).toFixed(2)}`,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      grow: 1.6,
      cell: ({ status, id }) => (
        <Select
          status={status as string}
          statusType="PLAN"
          key={id}
          onChange={(val) => onSetPlanStatus(id, PlanStatus[val.target.value as keyof typeof PlanStatus])}
          disabled={status === PlanStatus.CANCELLED || status === PlanStatus.COMPLETE}
        >
          <option value={status as string} key={id}>
            {PLAN_STATUS_DISPLAY[status as string]}
          </option>
          {hiddenPlanStatusOptions.map(({ value, label }) => value !== status && (
            <option key={`planStatus - ${value}:${id}`} value={value}>{label}</option>
          ))}
        </Select>
      ),
    },
  ];
  const [STRINGS] = useStrings();

  const [searchTerm, setSearchTerm] = useState(filter.keyword || '');
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.currentTarget.value || '');
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  // Manage state for custom filters that will be applied on button press
  const [localFilter, setLocalFilter] = useState<Record<string, number | Date | undefined>>();

  const { loading } = useSelector((state: ApplicationState) => ({
    loading: state.loadingState.apiCallsInProgress > 0,
  }));

  const {
    handlePlanTypeChange,
    handlePlanStatusChange,
    handlePlanStateChange,
    handleUpdatePlanValue,
    handleStartDateChange,
    handleDueDateChange,
    handleSearchComplete,
    handlePageChange,
    handleSortChange,
    handleApplyCustomFilters,
    handleClearFilters,
  } = buildHandlers<PlanFilterOptions>(
    updateFilter,
    filter,
    localFilter,
    setLocalFilter,
    clearFilters,
    handleSearchChange,
    setSearchTerm,
    setFilterMenuOpen,
  );

  const Loader = loading ? (
    <div>
      <Spinner />
    </div>
  ) : (
    <NoRecordsText>
      <p>{STRINGS.Components.Common.Table.noRecords}</p>
    </NoRecordsText>
  );

  const SubHeaderElement = (
    <SubHeader
      searchTerm={searchTerm}
      customFiltersOpen={filterMenuOpen}
      filter={filter}
      valueMin={filterLimits?.minPlanValue || 0}
      valueMax={filterLimits?.maxPlanValue || 0}
      onUpdateDueDate={handleDueDateChange}
      onUpdateStartDate={handleStartDateChange}
      onUpdateAmount={handleUpdatePlanValue}
      onUpdateState={handlePlanStateChange}
      onPlanTypeChange={handlePlanTypeChange}
      onStatusChange={handlePlanStatusChange}
      onSearchChange={handleSearchComplete}
      onOpenCustomFilters={() => setFilterMenuOpen(!filterMenuOpen)}
      onApplyCustomFilters={handleApplyCustomFilters}
      onClearCustomFilters={handleClearFilters}
    />
  );

  return (
    <Grid row maxWidth="100%">
      <Grid column sm={12}>
        <TableWrapper>
          <DataTable
            subHeaderAlign="left"
            highlightOnHover
            noContextMenu
            noHeader
            onRowClicked={onRowClicked}
            data={currentData}
            keyField="key"
            columns={columns}
            subHeader
            subHeaderComponent={SubHeaderElement}
            pagination
            paginationServer
            paginationPerPage={filter.rowsPerPage}
            paginationTotalRows={recordCount}
            paginationDefaultPage={filter.page}
            onChangePage={handlePageChange}
            paginationComponent={Pagination}
            onSort={handleSortChange}
            sortServer
            defaultSortField={filter.orderColumn}
            defaultSortAsc={filter.orderDirection === OrderDirection.ASC}
            noDataComponent={Loader}
            customStyles={TableJssOverrides}
            style={{ borderRadius: 0 }}
          />
        </TableWrapper>
      </Grid>
    </Grid>
  );
};

export default DuePlansTable;
