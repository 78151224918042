import React from 'react';
import { useSelector } from 'react-redux';

import {
  Container,
  FeesText,
  InstallmentsText,
  TotalText,
  Value,
  PlansText,
} from './Styles';
import { ApplicationState } from '../../../../lib/store';
import { PlanStatus } from '../../../../lib/types/Plan';
import useStrings from '../../../../hooks/useStrings';

const PlanTotal: React.FC = () => {
  const { planData } = useSelector((state: ApplicationState) => state.admin.selectedCustomerPlanState);

  const [{ Pages: { UserProfile: { Plans } } }] = useStrings();

  const planTotals = planData.plans.reduce((accumulator, plan) => {
    if (plan.status === PlanStatus.ACTIVE) {
      return {
        ...accumulator,
        totalFees: accumulator.totalFees + plan.adminFee,
        totalInstallments: accumulator.totalInstallments + plan.latestInstallmentValue,
        totalPlans: accumulator.totalPlans + plan.value,
      };
    }
    return {
      ...accumulator,
    };
  }, { totalInstallments: 0, totalFees: 0, totalPlans: 0 });

  return (
    <Container>
      <TotalText>{Plans.total}</TotalText>
      <PlansText>{Plans.plans}<Value>{planTotals.totalPlans ? `$${planTotals.totalPlans.toFixed(2)}` : 'N/A'}</Value></PlansText>
      <InstallmentsText>{Plans.installments}<Value>{planTotals.totalInstallments ? `$${planTotals.totalInstallments.toFixed(2)}` : 'N/A'}</Value></InstallmentsText>
      <FeesText>{Plans.fees}<Value>{`$${planTotals.totalFees ? planTotals.totalFees.toFixed(2) : 'N/A'}`}</Value></FeesText>
    </Container>
  );
};

export default PlanTotal;