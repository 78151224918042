import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useStrings from '../../../hooks/useStrings';
import {
  defaultFilter,
  getPlans,
  getPlanFilterLimits,
  updatePlanStatus,
} from '../../../lib/api/admin/plans';

import PageLayout from '../../../layouts/PageLayout';
import DuePlansTable from './DuePlansTable';

import { DuePlansProps } from './types';
import { TableRow } from '../../../components/Common/Table/types';
import {
  APIThunkDispatch,
  PlanFilterOptions,
} from '../../../lib/types/API';
import { ApplicationState } from '../../../lib/store';
import { PlanStatus, PlanFilterLimits } from '../../../lib/types/Plan';
import { resetSelectedCustomer } from '../../../lib/store/contexts/admin/customers/actions';
import { clearCustomerNotesData } from '../../../lib/store/contexts/admin/customerNotes/actions';
import { clearSelectedCustomerPlans } from '../../../lib/store/contexts/admin/selectedCustomer/plans/actions';
import { clearSelectedCustomerPayments } from '../../../lib/store/contexts/admin/selectedCustomer/payments/actions';
import { clearSelectedCustomerArchivedPlans } from '../../../lib/store/contexts/admin/selectedCustomer/archivedPlans/actions';
import { CUSTOMER_PROFILE_TABS_DISPLAY } from '../../../CONSTANTS';

const DuePlans: React.FC<DuePlansProps> = () => {
  const [{ Pages: { DuePlans: Strings } }] = useStrings();
  const Dispatch: APIThunkDispatch = useDispatch();
  const {
    planState: { planData },
    customerState: { selectedCustomer },
  } = useSelector((state: ApplicationState) => state.admin);

  // const viewUser = (row: TableRow) => history.push(`/admin/user/${row.userId}/plans`);
  const viewUser = (row: TableRow) => {
    if (selectedCustomer?.user.userId !== row.userId) {
      Dispatch(resetSelectedCustomer());
      Dispatch(clearCustomerNotesData());
      Dispatch(clearSelectedCustomerPlans());
      Dispatch(clearSelectedCustomerPayments());
      Dispatch(clearSelectedCustomerArchivedPlans());
    }
    const win = window.open(`/admin/user/${row.userId}/${CUSTOMER_PROFILE_TABS_DISPLAY.PLANS}`, '_blank');
    if (win) win.focus();
  };

  // Filter management
  const [filter, setFilter] = useState<PlanFilterOptions>(defaultFilter);
  const clearFilters = () => setFilter(defaultFilter);
  const updateFilter = (newFilter: PlanFilterOptions) => {
    setFilter({
      ...defaultFilter,
      ...newFilter,
    });
  };
  const [filterLimits, setFilterLimits] = useState<PlanFilterLimits>();

  const fetchPlans = useCallback(() => {
    Dispatch<void>(getPlans(filter));
  }, [Dispatch, filter]);

  const fetchPlanFilterLimits = useCallback(async () => {
    const { data } = await Dispatch(getPlanFilterLimits());
    if (data) setFilterLimits(data);
  }, [Dispatch]);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  useEffect(() => {
    fetchPlanFilterLimits().catch(() => null);
  }, [fetchPlanFilterLimits]);

  const currentData = planData.plans.map((plan) => ({ id: plan.planId, ...plan }));

  const handleUpdatePlanStatus = (planId: number, newStatus: PlanStatus) => {
    Dispatch<void>(updatePlanStatus(planId, newStatus));
  };

  return (
    <PageLayout
      pageTitle={`✅ ${Strings.pageTitle}`}
      maxWidth="100%"
    >
      <DuePlansTable
        currentData={currentData}
        recordCount={planData.count}
        filter={filter}
        updateFilter={updateFilter}
        onRowClicked={viewUser}
        clearFilters={clearFilters}
        onSetPlanStatus={handleUpdatePlanStatus}
        filterLimits={filterLimits}
      />
    </PageLayout>

  );
};

export default DuePlans;
