import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';

import useStrings from '../../../hooks/useStrings';

import { DocPreviewIcon, ImagePreviewIcon } from '../../../assets/Icons';
import {
  StyledFileUploader,
  IconWrapper,
  Icon,
  IconDivider,
  TypeText,
  UploadText,
  Error,
  PlaceholderWrapper,
  ValuePlaceholder,
  Wrapper,
  UploadWrapper,
  PlaceholderWrapperUploading,
  ProgressBar,
  ValuePlaceholderUploading,
} from './Styles';

import { FileInputNotesProps } from './types';

const FileInputNotes: FC<FileInputNotesProps> = ({
  values,
  maxImageSize = 5000000, // 5MB
  onFileUploadComplete,
  onFileUploadError,
  error,
  touched,
  uploadStatus,
  uploadingFile,
}) => {
  const [{ Components: { Common: { FileUploader: FileUploaderStrings } } }] = useStrings();

  const handleDropAccepted = (files: File[]) => {
    const file = files[0];
    onFileUploadComplete(file);
  };

  const handleDropRejected = () => {
    onFileUploadError();
  };

  const {
    getRootProps,
    getInputProps,
    isDragReject,
  } = useDropzone({
    multiple: false,
    maxSize: maxImageSize,
    accept: '.jpeg, .png, .pdf',
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,

  });

  const getFileName = (fileName: string) => (fileName.length < 10
    ? fileName
    : `${fileName.slice(0, 20)}`);

  return (
    <Wrapper>
      {values.map((file) => (
        <React.Fragment key={file.lastModified}>
          {touched && error && <Error className="error">{error}</Error>}
          <PlaceholderWrapper>
            <input {...getInputProps()} />
            <Icon src={DocPreviewIcon} />
            <ValuePlaceholder>
              {getFileName(file.name)}
            </ValuePlaceholder>
          </PlaceholderWrapper>
        </React.Fragment>
      ))}
      {uploadingFile && (
        <PlaceholderWrapperUploading>
          <input {...getInputProps()} />
          <Icon src={DocPreviewIcon} />
          <UploadWrapper>
            <ValuePlaceholderUploading>
              {getFileName(uploadingFile.name)}
            </ValuePlaceholderUploading>
            <ProgressBar percent={uploadStatus.progress} strokeColor="#359EEA" strokeWidth={2} trailWidth={2} trailColor="#E5E5E5" />
          </UploadWrapper>
        </PlaceholderWrapperUploading>
      )}
      <StyledFileUploader
        {...getRootProps({ isDragReject })}
        error={!!error && !!touched}
        hasValue={values.length > 0}
      >
        {touched && error && <Error className="error">{error}</Error>}
        <input {...getInputProps()} />
        <IconWrapper>
          <Icon src={DocPreviewIcon} />
          <IconDivider />
          <Icon src={ImagePreviewIcon} />
        </IconWrapper>
        <TypeText>{FileUploaderStrings.typeText}</TypeText>
        <UploadText error={!!error && !!touched}>{FileUploaderStrings.uploadText}</UploadText>
      </StyledFileUploader>
    </Wrapper>
  );
};

export default FileInputNotes;