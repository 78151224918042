import styled, { css } from 'styled-components/macro';

import ButtonProps, { Variant } from './types';

// spread as props for the button component when applicable
export const tabButtonStyles = {
  width: '100%',
  height: '30px',
  rounded: false,
  fontSize: '0.7rem',
};

const getButtonVariantStyles = (variant?: Variant) => {
  switch (variant) {
    case 'primary':
    default:
      return css`
        background-color: ${({ theme }) => theme.basePalette.blueMain};
        border: 1px solid ${({ theme }) => theme.basePalette.blueMain};
        color: ${({ theme }) => theme.basePalette.white};
        font-weight: ${({ theme }) => theme.fontWeight.semibold};
      `;
    case 'secondary':
      return css`
        background-color: ${({ theme }) => theme.basePalette.greyLight};
        border: 1px solid ${({ theme }) => theme.basePalette.greyLight};
        color: ${({ theme }) => theme.basePalette.navyMain};
        font-weight: ${({ theme }) => theme.fontWeight.semibold};
      `;
    case 'important':
      return css`
        background-color: ${({ theme }) => theme.basePalette.redMain};
        border: 1px solid ${({ theme }) => theme.basePalette.redMain};
        color: ${({ theme }) => theme.basePalette.white};
        font-weight: ${({ theme }) => theme.fontWeight.semibold};
      `;
    case 'outlined':
      return css`
        background-color: ${({ theme }) => theme.basePalette.white};
        border: 1px solid ${({ theme }) => theme.basePalette.greyMain};
        color: ${({ theme }) => theme.basePalette.navyMain};
        font-weight: ${({ theme }) => theme.fontWeight.semibold};
      `;
    case 'dark':
      return css`
        background-color: ${({ theme }) => theme.basePalette.navyMain};
        color: ${({ theme }) => theme.basePalette.white};
        border: none;
        font-weight: ${({ theme }) => theme.fontWeight.semibold};
      `;
    case 'light':
      return css`
        background-color: ${({ theme }) => theme.basePalette.white};
        color: ${({ theme }) => theme.basePalette.navyMain};
        border: none;
        font-weight: ${({ theme }) => theme.fontWeight.semibold};
      `;
    case 'dark-inverse':
      return css`
        background-color: ${({ theme }) => theme.basePalette.white};
        border: 1px solid ${({ theme }) => theme.basePalette.navyMain};;
        color: ${({ theme }) => theme.basePalette.navyMain};
        font-weight: ${({ theme }) => theme.fontWeight.semibold};
      `;
    case 'warning':
      return css`
        background-color: ${({ theme }) => theme.basePalette.white};
        color: ${({ theme }) => theme.basePalette.navyMain};
        border: 1px solid ${({ theme }) => theme.palette.borderRed};;
        font-weight: ${({ theme }) => theme.fontWeight.regular};
      `;
    case 'green':
      return css`
        background-color: ${({ theme }) => theme.palette.btnGreen};
        color: ${({ theme }) => theme.basePalette.white};
        border: 1px solid ${({ theme }) => theme.palette.btnGreen};;
        font-weight: ${({ theme }) => theme.fontWeight.regular};
      `;
    case 'dark-outline':
      return css`
        background-color: ${({ theme }) => theme.basePalette.navyMain};
        color: ${({ theme }) => theme.basePalette.white};
        border: 1px solid ${({ theme }) => theme.basePalette.greyLight};
        font-weight: ${({ theme }) => theme.fontWeight.semibold};
      `;
  }
};

export const StyledButton = styled.button<ButtonProps>`
  ${({ variant }) => getButtonVariantStyles(variant)};
  font-size: ${({ theme, fontSize }) => (fontSize || `${theme.fontScale.M}rem`)};
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  margin: ${({ margin }) => margin ?? '.5rem'};
  height: ${({ height }) => height ?? 'auto'};
  padding: ${({ theme, padding }) => padding ?? `1rem ${theme.spacing.XS}rem`};
  cursor: pointer;
  border-radius: ${({ rounded }) => (rounded ? '50px' : '16px')};
  outline: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: all .1s ease;
  font-family: ${({ theme }) => theme.fontFamily.body};
  ${({ backgroundOverride }) => (backgroundOverride && `background: ${backgroundOverride}`)};

  ${({ theme }) => theme.device.minDevice} {
    ${({ expandedMobile, width }) => (expandedMobile ? 'width: 100%' : `width: ${width}`)};
  }

  ${({ theme }) => theme.device.mobile} {
    ${({ expandedMobile, width }) => (expandedMobile ? 'width: 100%' : `width: ${width}`)};
  }

  ${({ theme }) => theme.device.tablet} {
    ${({ width }) => (width ? `width: ${width}` : 'width: auto')};
  }

  ${({ textTransform }) => (textTransform ? `text-transform: ${textTransform}` : '')};
  ${({ disabled }) => (disabled ? `
    pointer-events: none;
    opacity: .2;
    cursor: not-allowed;
    opacity: 0.3;
  ` : '')};

  &:hover {
    transform: scale(1.02);
    border-color: ${({ variant, theme }) => (variant === 'outlined' && theme.basePalette.blueMain)};
  }
  
  &:active {
    transform: scale(1);
  }
`;

export const LoadingSpinner = styled.div`
  border: 4px solid ${({ theme }) => theme.basePalette.white};
  border-top: 4px solid ${({ theme }) => theme.basePalette.blueMain};
  border-radius: 50%;
  width: 22px;
  height: 22px;
  animation: spin .6s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
