import React from 'react';

import Calculator from './Calculator';

import { PromoCalculatorProps } from './types';

const PromoCalculator: React.FC<PromoCalculatorProps> = () => (
  <Calculator />
);

export default PromoCalculator;
