import React from 'react';
import { Formik } from 'formik';

import useStrings from '../../../../../hooks/useStrings';

import Input from '../../../../../components/Common/Input';
import Button from '../../../../../components/Common/Button';

import {
  CustomerCreditsWrapper, Title, SubTitle, InputWrapper,
} from './Styles';

import { CustomerCreditsProps } from './types';
import { roundNum } from '../../../../../lib/utils';

const CustomerCredits: React.FC<CustomerCreditsProps> = ({ customerCredit = 0, onUpdateCredits }) => {
  const [{
    Pages: { UserProfile: { Credit: { CustomerCredits: CustomerCreditsStrings } } },
    GenericText }] = useStrings();
  return (

    <CustomerCreditsWrapper>
      <Title>{CustomerCreditsStrings.title}</Title>
      <SubTitle>{CustomerCreditsStrings.subTitle}</SubTitle>
      <InputWrapper>
        <Formik
          initialValues={{
            creditAmount: roundNum(customerCredit) || 0,
          }}
          validate={({ creditAmount }) => {
            const errors: { creditAmount?: string, } = {};

            if (typeof creditAmount !== 'number') errors.creditAmount = CustomerCreditsStrings.amountError;

            return errors;
          }}
          onSubmit={async ({ creditAmount }, { setSubmitting }) => {
            setSubmitting(true);
            await onUpdateCredits(Number(creditAmount));
            setSubmitting(false);
          }}
          enableReinitialize
        >
          {({ submitForm, dirty }) => (
            <>
              <Input name="creditAmount" type="currency" width="250px" />
              <Button onClick={submitForm} rounded height="50px" width="300px" disabled={!dirty}>
                {GenericText.saveChanges}
              </Button>
            </>
          )}
        </Formik>
      </InputWrapper>
    </CustomerCreditsWrapper>

  );
};

export default CustomerCredits;