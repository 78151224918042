import { setUpdatedRecordsInState } from '../../../utils';

import {
  SET_CUSTOMER_DATA,
  SET_SELECTED_CUSTOMER,
  RESET_SELECTED_CUSTOMER,
  SET_UPDATED_CUSTOMER_DATA,
  CustomerState,
  CustomerActionTypes,
} from './types';

const initialState: CustomerState = {
  customerData: {
    customers: [],
    count: 0,
  },
  selectedCustomer: null,
};

const customerReducer = (
  state: CustomerState = initialState,
  action: CustomerActionTypes,
): CustomerState => {
  switch (action.type) {
    case SET_CUSTOMER_DATA:
      return {
        ...state,
        customerData: {
          customers: action.customerData.results,
          count: action.customerData.count,
        },
      };
    case SET_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.selectedCustomer,
      };
    case RESET_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomer: null,
      };
    case SET_UPDATED_CUSTOMER_DATA:
      return {
        ...state,
        customerData: {
          customers: setUpdatedRecordsInState('customerId', state.customerData.customers, action.customers),
          count: state.customerData.count,
        },
      };
    default:
      return state;
  }
};

export default customerReducer;
