import * as yup from 'yup';

const createPasswordSchema = yup.object({
  newPassword: yup
    .string()
    .trim()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$^+=!*()@%&?-_]).{8,99}$/, 'Your password must be at least 8 characters long, contain at least one number, one special character and have a mixture of uppercase and lowercase letters')
    .required('A password is required'),
  confirmPassword: yup
    .string()
    .trim()
    .required('Password is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
}).required();

export type UpdatePasswordSchema = yup.InferType<typeof createPasswordSchema>;

export default createPasswordSchema;
