import styled from 'styled-components/macro';

import { StepIndicatorProps, WrapperProps } from './types';

export const FormStepsWrapper = styled.div<WrapperProps>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 ${({ theme, centered }) => (centered ? 0 : theme.spacing.XS)}rem;
  
  ${({ theme }) => theme.device.tablet} {
    margin: 0 ${({ theme }) => theme.spacing.XS}rem;
  }
`;

export const FormStepOuter = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.XXS}rem;

  ${({ theme }) => theme.device.tablet} {
    margin-right: ${({ theme }) => theme.spacing.XS}rem;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const FormStep = styled.div`
  align-items: center;
  display: flex;
  margin-right: ${({ theme }) => theme.spacing.XXS}rem;

  &:last-child {
    margin-right: 0;
  }

  ${({ theme }) => theme.device.tablet} {
    margin: 0 ${({ theme }) => theme.spacing.XXS}rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const StepIndicator = styled.div<StepIndicatorProps>`
  border-radius: 20px;
  border: 1px solid ${({ isCurrentStep, theme }) => (isCurrentStep ? theme.basePalette.blueMain : theme.palette.grey10)};
  background: ${({ isCurrentStep, theme }) => isCurrentStep && theme.basePalette.blueMain};
  color: ${({ isCurrentStep, theme }) => (isCurrentStep ? theme.basePalette.white : theme.basePalette.greyDark)};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
`;

export const StepText = styled.span<StepIndicatorProps>`
  color: ${({ isCurrentStep, theme }) => (isCurrentStep ? theme.basePalette.navyMain : theme.basePalette.greyMedium)};
  font-family: ${({ isCurrentStep, theme }) => (isCurrentStep ? theme.fontFamily.bodyBold : theme.fontFamily.body)};
  display: block;
`;
