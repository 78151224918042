import React from 'react';

import Logo from '../../Common/Logo';
import FormSteps from '../../Common/FormSteps';
import TopNav from '../TopNav';

import { Container, MainContent, LogoWrapper, Spacer } from './Styles';

import { NavHeaderProps } from './types';

const NavHeader: React.FC<NavHeaderProps> = ({ isCreateProfile, isOnboarding, currentFormStep }) => (
  <Container>
    <MainContent>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      {isOnboarding && currentFormStep !== undefined ? (
        <FormSteps
          isCreateProfile={isCreateProfile}
          isOnboarding={isOnboarding}
          currentStep={currentFormStep}
        />
      ) : (
        <TopNav />
      )}
      {isOnboarding && (
        /* Invisible spacer to be same width as logo to centre form stepper for onboarding */
        <Spacer><Logo /></Spacer>
      )}
    </MainContent>
  </Container>
);

export default NavHeader;
