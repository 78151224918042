import { apiFetch, apiRequest } from '../Utils';

import { serverAdminAPI } from '../../../CONSTANTS';
import { updatePassword } from '../../Authentication';
import { signOut } from '../Auth';

import { CreateAdminSchema } from '../../validators/createAdminSchema';
import { EditAdminSchema } from '../../validators/editAdminSchema';
import { DisableAdminSchema } from '../../validators/disableAdminSchema';
import { UpdateAdminProfileSchema } from '../../validators/updateAdminProfileSchema';

import { AdminsDTO, AdminDTO, UpdateSignInMetaDTO } from '../../types/DTO';
import { APIThunkResult } from '../../types/API';
import { setNotification } from '../../store/contexts/notification/actions';
import { setAdminUser } from '../../store/contexts/admin/user/actions';
import { User } from '../../types/DBModels';

export const getAdmins = (): APIThunkResult<AdminsDTO> => (
  apiRequest<AdminsDTO>(async () => {
    const url = `${serverAdminAPI}/admins`;
    const { data, error } = await apiFetch<AdminsDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);

export const createAdmin = (
  input: CreateAdminSchema,
): APIThunkResult<AdminDTO> => (
  apiRequest<AdminDTO>(async () => {
    const { data, error } = await apiFetch<AdminDTO>({
      method: 'POST',
      url: `${serverAdminAPI}/admins/create`,
      body: {
        ...input,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);

export const editAdmin = (
  input: EditAdminSchema,
): APIThunkResult<AdminDTO> => (
  apiRequest<AdminDTO>(async () => {
    const { data, error } = await apiFetch<AdminDTO>({
      method: 'PUT',
      url: `${serverAdminAPI}/admins/edit`,
      body: {
        ...input,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);

export const disableAdmin = (
  input: DisableAdminSchema,
): APIThunkResult<AdminDTO> => (
  apiRequest<AdminDTO>(async () => {
    const { data, error } = await apiFetch<AdminDTO>({
      method: 'PUT',
      url: `${serverAdminAPI}/admins/disable`,
      body: {
        ...input,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);

export const setPassword = (
  currentPassword: string,
  newPassword: string,
): APIThunkResult<UpdateSignInMetaDTO> => (
  apiRequest<UpdateSignInMetaDTO>(async (dispatch) => {
    await updatePassword(currentPassword, newPassword);

    const { data, error } = await apiFetch<UpdateSignInMetaDTO>({
      method: 'POST',
      url: `${serverAdminAPI}/admins/update-signin-meta`,
      body: {
        mustUpdatePassword: false,
      },
    });

    dispatch(setNotification({
      message: 'Password updated, you can now login with your new password.',
      variant: 'success',
      duration: 3,
    }));

    await dispatch(signOut());

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);

export const updateProfile = (
  input: UpdateAdminProfileSchema,
  oldValues: User,
): APIThunkResult<AdminDTO> => apiRequest<AdminDTO>(
  async (dispatch) => {
    const { data, error } = await apiFetch<AdminDTO>({
      method: 'POST',
      url: `${serverAdminAPI}/admins/update-profile`,
      body: {
        ...input,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setAdminUser(data.admin));

    dispatch(setNotification({
      message: input.email !== oldValues.email ? 'Profile updated! Please log in again.' : 'Profile updated!',
      variant: 'success',
      duration: 3,
    }));

    if (input.email !== oldValues.email) {
      await dispatch(signOut());
    }

    return data;
  },
  true,
);
