import { PlanDataView } from '../../../../../types/DBViews';

export const SET_ALL_TRANSFERABLE_PLAN_DATA = 'SET_ALL_TRANSFERABLE_PLAN_DATA';

export interface TransferablePlanState {
  transferablePlans: PlanDataView[];
}
export interface SetAllTransferablePlansAction {
  type: typeof SET_ALL_TRANSFERABLE_PLAN_DATA;
  transferablePlans: PlanDataView[];
}

export type TransferablePlanActionTypes =
SetAllTransferablePlansAction;