import styled from 'styled-components';

import { StatsTooltopProps } from './types';

export const Container = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  transform: translateX(-15%);
`;

export const StyledTooltip = styled.div<StatsTooltopProps>`
  align-items: ${({ leftAlign }) => (leftAlign ? 'left' : 'center')};
  background: ${({ theme }) => theme.basePalette.navyMain};
  border-radius: 6px;
  padding: 5px 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 60px;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: ${({ theme }) => theme.basePalette.navyMain} transparent transparent transparent;
  }
`;
