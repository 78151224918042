import React, { useState, useRef, useEffect } from 'react';
import dayjs from 'dayjs';

import DatePickerCalender from '../../../../components/Common/DatePickerCalendar';

import {
  Container,
  ContainerInner,
  TopContent,
  MainContent,
  LoadingContainer,
  LoadingSpinner,
  Title,
  Icon,
  YearSelect,
  CalendarToggle,
  DropdownCalendar,
  EmojiIcon,
  SelectedDates,
  SelectedDate,
} from './Styles';

import { DashboardPanelProps } from './types';
import useClickOutside from '../../../../hooks/useClickOutside';
import useStrings from '../../../../hooks/useStrings';
import { MIN_YEAR_FILTER } from '../../../../CONSTANTS';
import { generateChartSelectYears } from '../../../../lib/utils';
import { blueCaretDown } from '../../../../assets/Icons/index';

const DashboardPanel: React.FC<DashboardPanelProps> = ({
  title,
  icon,
  isEmoji,
  size,
  onChange,
  yearOnly = false,
  loading,
  defaultRange,
  children,
}) => {
  const [{ Pages: { Admin: { Dashboard: Strings } }, GenericText }] = useStrings();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarPosition, setCalendarPosition] = useState({ x: 0, y: 0 });
  const [selectedDates, setSelectedDates] = useState<Date[] | Date | null>(defaultRange || null);
  const ref = useRef<HTMLDivElement>(null);
  const selectYearOptions = generateChartSelectYears(MIN_YEAR_FILTER);

  const handleYearSelect = (val?: string) => {
    // Handle clear selected dates if val is all or undefined
    if (!val || val === 'all') {
      setSelectedDates(null);
      return onChange && onChange();
    }
    const fromDate = dayjs(val).startOf('year').toDate();
    const toDate = dayjs(val).endOf('year').toDate();
    return onChange && onChange([fromDate, toDate]);
  };

  useClickOutside(ref, () => setCalendarOpen(false));

  const handleToggle = (clickEvent: React.MouseEvent) => {
    setCalendarPosition({ x: clickEvent.pageX, y: clickEvent.pageY });
    setCalendarOpen(!calendarOpen);
  };

  const positionStyle = {
    top: `${calendarPosition.y + 10}px`,
    left: `${calendarPosition.x}px`,
  };

  const toggleScroll = (lock?: boolean) => {
    const ele: HTMLElement | null = document.querySelector('#scroll-lock');
    if (ele) {
      if (lock) ele.style.overflow = 'hidden';
      else ele.style.overflow = 'auto';
    }
  };

  useEffect(() => {
    if (calendarOpen) {
      toggleScroll(true);
    } else {
      toggleScroll();
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [calendarOpen]);

  const renderSelectedDates = () => {
    if (!selectedDates) return <SelectedDate>{Strings.filterAll}</SelectedDate>;
    if (Array.isArray(selectedDates)) {
      const dateString = `${dayjs(selectedDates[0]).format('DD/MM/YY')} - ${dayjs(selectedDates[1]).format('DD/MM/YY')}`;
      return (
        <SelectedDate>{dateString}</SelectedDate>
      );
    }
    return <SelectedDate>{dayjs(selectedDates).format('DD/MM/YY')}</SelectedDate>;
  };

  return (
    <Container size={size}>
      <ContainerInner ref={ref}>
        {!yearOnly && calendarOpen && (
          <DropdownCalendar style={positionStyle}>
            <DatePickerCalender
              selectRange
              onSelect={(val) => {
                setCalendarOpen(false);
                setSelectedDates(val);
                return onChange && onChange(val);
              }}
              onClear={selectedDates ? () => {
                handleYearSelect();
                setCalendarOpen(false);
              } : undefined}
              clearButtonText={GenericText.allTime}
              maxDetail="month"
            />
          </DropdownCalendar>
        )}
        <TopContent>
          <TopContent>
            {isEmoji ? <EmojiIcon>{icon}&nbsp;&nbsp;</EmojiIcon> : <Icon src={icon} />}
            <Title large={size !== 'small'}>{title}</Title>
          </TopContent>
          {onChange && (
            <TopContent>
              {!yearOnly && (
                <SelectedDates>{renderSelectedDates()}</SelectedDates>
              )}
              {yearOnly && (
                <YearSelect
                  name="year"
                  icon={blueCaretDown}
                  onChange={(e) => handleYearSelect(e.target.value)}
                >
                  <option value="all">{Strings.filterAll}</option>
                  {selectYearOptions.map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </YearSelect>
              )}
              {!yearOnly && (
                <CalendarToggle
                  src={blueCaretDown}
                  inverted={calendarOpen}
                  onClick={handleToggle}
                />
              )}
            </TopContent>
          )}
        </TopContent>
        <MainContent>
          {loading ? (
            <LoadingContainer>
              <LoadingSpinner small={size === 'small'} />
            </LoadingContainer>
          ) : (children)}
        </MainContent>
      </ContainerInner>
    </Container>
  );
};

export default DashboardPanel;
