import { AnyAction, CombinedState, combineReducers } from 'redux';

import { ProfileState, ProfileActionTypes } from './profile/types';
import { PlansState, PlansActionTypes } from './plans/types';
import { PaymentsState, PaymentsActionTypes } from './payments/types';
import profileReducer from './profile/reducers';
import plansReducer from './plans/reducers';
import paymentsReducer from './payments/reducers';
import { PlansPaginatedState } from './plansPaginated/types';
import plansPaginatedReducer from './plansPaginated/reducers';

export interface CustomerState {
  profileState: ProfileState;
  plansState: PlansState;
  plansPaginatedState: PlansPaginatedState;
  paymentsState: PaymentsState;
}

const customerReducer = combineReducers<CustomerState>({
  profileState: profileReducer,
  plansState: plansReducer,
  paymentsState: paymentsReducer,
  plansPaginatedState: plansPaginatedReducer,
});

const customerRootReducer = (
  state: CombinedState<CustomerState> | undefined,
  action: AnyAction,
): CombinedState<CustomerState> => {
  const clearState: CombinedState<CustomerState> | undefined = state;
  return customerReducer(clearState, action);
};

export type CustomerActionTypes =
  | ProfileActionTypes
  | PaymentsActionTypes
  | PlansActionTypes;

export default customerRootReducer;
