import React from 'react';
import Calendar from 'react-calendar';

import {
  StyledCalendar,
  CustomHeader,
  ClearButton,
} from './Styles';

import { DatePickerCalenderProps } from './types';

const DatePickerCalender: React.FC<DatePickerCalenderProps> = ({
  onLoadDate,
  onSelect,
  selectRange = false,
  maxDetail = 'month',
  onClear,
  clearButtonText,
}) => (
  <StyledCalendar>
    {onClear && (
      <CustomHeader>
        <ClearButton onClick={onClear}>{clearButtonText}</ClearButton>
      </CustomHeader>
    )}
    <Calendar
      value={onLoadDate}
      onChange={(date: Date | Date[]) => onSelect(date)}
      maxDetail={maxDetail}
      selectRange={selectRange}
    />
  </StyledCalendar>
);

export default DatePickerCalender;
