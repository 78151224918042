import styled from 'styled-components/macro';

export const FormWrapper = styled.div`
  padding-top: 2rem;
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    max-width: 90%;
    padding-top: 3rem;
  }

  ${({ theme }) => theme.device.desktop} {
    max-width: 75%;
  }
`;

export const SubText = styled.h3`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.basePalette.redMain};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-style: italic;
  position: absolute;
  bottom: -20px;
`;

export const InputWrap = styled.div`
  position: relative;
`;
