import styled from 'styled-components/macro';

import { StyledInputLabelProps, LabelProps } from './types';

export const OuterWrapper = styled.div`
  margin: 0.5rem 0rem;
  width: 100%;
`;

export const InnerWrapper = styled.div`
  position: relative;
  margin: ${({ theme }) => theme.spacing.XXS / 2}rem 0 0 0;
`;

export const Input = styled.input`
  position: absolute;
  visibility: hidden;

  &:checked + label {
    border: 1px solid ${({ theme }) => theme.basePalette.blueMain};
    background-color: ${({ theme }) => theme.basePalette.blueLight};
    color: ${({ theme }) => theme.basePalette.navyMain};
  }
`;

export const StyledInputLabel = styled.label<StyledInputLabelProps>`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacing.XS}rem;
  border: 1px solid ${({ error, theme }) => (error ? theme.palette.btnError : theme.basePalette.greyMain)};
  border-radius: 15px;
  outline: none;
  height: 125px;
  width: auto;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  background-color: ${({ theme }) => theme.palette.bgPrimary};
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.basePalette.blueMain};
    background-color: ${({ theme }) => theme.palette.bgPrimary};
  }
  
`;

export const Label = styled.p<LabelProps>`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme, inactive }) => !inactive && theme.fontWeight.semibold};
  margin-left: ${({ theme }) => theme.spacing.XXS * 1.5}rem;
  color: ${({ theme, inactive, error }) => {
    if (error) return theme.palette.inputError;
    if (inactive) return theme.palette.grey30;
    return theme.basePalette.navyMain;
  }};
  `;

export const RadioIcon = styled.img<LabelProps>`
  filter: ${({ inactive }) => inactive && 'opacity(0.4)'};
`;
