import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import Grid from '../../Common/Grid';
import Input from '../../Common/Input';
import Button from '../../Common/Button';
import Heading from '../../Common/Heading';
import Checkbox from '../../Common/Checkbox';
import DatePicker from '../../Common/DatePicker';

import {
  FormLink,
  FormWrapper,
  SubText,
  ButtonWrapper,
  BodyText,
  CheckboxWrap,
  ErrorMessage,
} from './Styles';

import { LoginFormProps } from './types';
import { APIThunkDispatch } from '../../../lib/types/API';
import { signIn, signUp } from '../../../lib/api/Auth';
import useStrings from '../../../hooks/useStrings';
import authSchema from '../../../lib/validators/authSchema';
import signupSchema from '../../../lib/validators/signupSchema';
import { termsAndConditionsLink } from '../../../CONSTANTS';
import { setNewPlanData } from '../../../lib/store/contexts/customer/plans/actions';

const LoginForm: React.FC<LoginFormProps> = ({
  title,
  titleHighlight,
  subtitle,
  showTermsCheckbox = false,
  isSignup = false,
  referralId,
  email: emailFromReferral,
}) => {
  const Dispatch: APIThunkDispatch = useDispatch();
  const [
    {
      Components: {
        UI: { LoginForm: Strings },
      },
    },
  ] = useStrings();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');

  const handleSignIn = async (
    email: string,
    password: string,
    setSubmitting: SetSubmitting,
  ) => {
    await Dispatch(signIn({ email, password })).then(({ error }) => {
      if (error && (error.info === 'auth/user-not-found'
         || error.info === 'auth/wrong-password')
      ) {
        setErrorMessage(Strings.authError);
      }
      setSubmitting(false);
    });
  };

  const handleSignUp = async (
    email: string,
    password: string,
    termsAccepted: boolean,
    newsletterAccepted: boolean,
    setSubmitting: SetSubmitting,
    planDueDate: Date | null,
  ) => {
    const { error, success } = await Dispatch(
      signUp({
        email,
        password,
        termsAccepted,
        newsletterAccepted,
        referralId: referralId ? +referralId : undefined,
      }),
    );
    if (planDueDate) Dispatch(setNewPlanData({ dueDate: planDueDate }));
    if (error) {
      setErrorMessage(error.message);
      setSubmitting(false);
    } else if (success) {
      history.push('/sign-up/profile');
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          planDueDate: null,
          email: emailFromReferral || '',
          password: '',
          termsAccepted: false,
          newsletterAccepted: false,
        }}
        validationSchema={isSignup ? signupSchema : authSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(
          { email, password, termsAccepted, newsletterAccepted, planDueDate },
          { setSubmitting },
        ) => (
          isSignup ? handleSignUp(
            email,
            password,
            termsAccepted,
            newsletterAccepted,
            setSubmitting,
            planDueDate,
          ) : handleSignIn(email, password, setSubmitting)
        )}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, setFieldError, errors, touched, setFieldTouched }) => (
          <FormWrapper>
            <Grid row>
              <Grid column alignItems="center" sm={12}>
                <Grid row justify="center">
                  <Heading defaultFont={false}>{title}</Heading>
                  {titleHighlight && (
                    <Heading highlight defaultFont={false}>
                      {' '}
                      &nbsp;
                      {titleHighlight}
                    </Heading>
                  )}
                </Grid>
                <SubText>{subtitle}</SubText>
              </Grid>
            </Grid>
            <Grid row marginTop="60px" expanded>
              <Grid column sm={12}>
                {isSignup && (
                  <DatePicker
                    outlined
                    label={Strings.registrationDueDateLabel}
                    onSelect={(date) => setFieldValue('planDueDate', date)}
                    error={errors.planDueDate}
                    touched={touched.planDueDate}
                    setError={(error) => setFieldError('planDueDate', error)}
                    setTouched={(fieldTouched) => setFieldTouched('planDueDate', fieldTouched)}
                  />
                )}
                <Input
                  type="email"
                  name="email"
                  label={Strings.emailInputLabel}
                  placeholder={Strings.emailPlaceholder}
                  width="100%"
                  outlined
                  onBlur={() => setErrorMessage('')}
                />
                <Input
                  type="password"
                  name="password"
                  label={Strings.passwordInputLabel}
                  placeholder={Strings.passwordPlaceholder}
                  width="100%"
                  outlined
                  onBlur={() => setErrorMessage('')}
                />
              </Grid>
            </Grid>
            <Grid row marginTop="1rem" expanded>
              <Grid
                column
                sm={12}
                alignItems={showTermsCheckbox ? 'flex-start' : 'center'}
              >
                {showTermsCheckbox ? (
                  <CheckboxWrap>
                    <Checkbox name="termsAccepted">
                      <div>
                        <BodyText>{Strings.termsText1}</BodyText>
                        &nbsp;
                        <FormLink href={termsAndConditionsLink} target="_blank">
                          {Strings.termsLink}
                        </FormLink>
                      </div>
                    </Checkbox>
                    <Checkbox name="newsletterAccepted">
                      <div>
                        <BodyText>{Strings.newsletterText}</BodyText>
                      </div>
                    </Checkbox>
                  </CheckboxWrap>
                ) : (
                  <FormLink href="/forgot-password">
                    {Strings.forgotPasswordText}
                  </FormLink>
                )}
              </Grid>
              <Grid column sm={12} alignItems="center">
                <ButtonWrapper>
                  {!!errorMessage && (
                    <ErrorMessage>{errorMessage}</ErrorMessage>
                  )}
                  <Button
                    onClick={handleSubmit}
                    margin="0px"
                    disabled={isSubmitting}
                    width="100%"
                    type="submit"
                    $loading={isSubmitting}
                  >
                    {isSignup ? Strings.signupButton : Strings.loginButton}
                  </Button>
                </ButtonWrapper>
              </Grid>
            </Grid>
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};

export type SetSubmitting = (isSubmitting: boolean) => void;

export default LoginForm;
