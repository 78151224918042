import {
  AnyAction, applyMiddleware, CombinedState, compose, createStore, Store,
} from 'redux';
import thunk from 'redux-thunk';
import rootReducer, { ApplicationState } from '.';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const configureStore = (): Store<CombinedState<ApplicationState>, AnyAction> => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk)),
  );
};

export default configureStore;
