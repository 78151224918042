import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import useStrings from '../../../hooks/useStrings';
import { updateStatus, deleteCustomer } from '../../../lib/api/admin/customers';

import Grid from '../../Common/Grid';
import Button from '../../Common/Button';
import ContextMenu from '../../Common/ContextMenu';
import DeleteModal from '../../Common/DeleteModal';

import {
  darkLeftArrow,
  dollarIcon,
  locationPinIcon,
} from '../../../assets/Icons';
import {
  Container,
  Back,
  ProfileWrapper,
  ImageContainer,
  ProfileInitials,
  ProfileContainer,
  LocationPin,
  CustomerName,
  CustomerAddress,
  ProfileImage,
  AddressWrapper,
  DollarIcon,
  HeaderRow,
  Select,
} from './Styles';

import { ProfileHeaderProps } from './types';
import { CustomerStatus, PaymentStatus } from '../../../lib/types/Customer';
import { APIThunkDispatch } from '../../../lib/types/API';
import { customerStatusOptions, CUSTOMER_STATUS_DISPLAY } from '../../../CONSTANTS';

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  customer,
  onBack = () => null,
  handleCreatePlan,
  handleFinalizePlan,
  handleTransferFunds,
}) => {
  const [{ GenericText, Pages: { UserProfile: { ProfileHeader: ProfileHeaderStrings } } }] = useStrings();
  const Dispatch: APIThunkDispatch = useDispatch();
  const history = useHistory();
  const [isDeleting, setIsDeleting] = useState<{
    modalOpen: boolean,
    loading: boolean,
  }>({ modalOpen: false, loading: false });

  const addressSummary = `${customer.suburb}, ${customer.state}`;
  const fullName = `${customer.user.firstName} ${customer.user.lastName}`;
  const initials = `
    ${customer.user.firstName.charAt(0).toUpperCase()}${customer.user.lastName.charAt(0).toUpperCase()}
  `;

  const onUpdateCustomerStatus = (userId: string, status?: CustomerStatus, paymentStatus?: PaymentStatus) => {
    Dispatch<void>(updateStatus(userId, status, paymentStatus));
  };

  const onDeleteUser = async () => {
    setIsDeleting({ ...isDeleting, loading: true });
    const { success } = await Dispatch(deleteCustomer(customer.userId));
    if (success) {
      setIsDeleting({ modalOpen: false, loading: false });
      history.push('/customers');
    }
    setIsDeleting({ modalOpen: true, loading: false });
  };

  const deleteUserModalSubtext = `${ProfileHeaderStrings.deleteUserModalSubTitlePartOne} ${customer.user.firstName}${ProfileHeaderStrings.deleteUserModalSubTitlePartTwo}`;

  return (
    <>
      {isDeleting.modalOpen && (
      <DeleteModal
        isOpen={isDeleting.modalOpen}
        onCancel={() => {
          setIsDeleting({ ...isDeleting, modalOpen: false });
        }}
        onConfirm={onDeleteUser}
        title={ProfileHeaderStrings.deleteUserModalTitle}
        subtext={deleteUserModalSubtext}
        cancelButtonText={GenericText.cancel}
        confirmButtonText={GenericText.confirm}
        isDeleting={isDeleting.loading}
      />
      )}
      <Grid row maxWidth="100%">
        <Grid column>
          <Container>
            <HeaderRow>
              <ProfileWrapper>
                <Back onClick={onBack}>
                  <img alt="back" src={darkLeftArrow} />
                </Back>
                <ImageContainer>
                  <ProfileImage>
                    <ProfileInitials>{initials}</ProfileInitials>
                  </ProfileImage>
                </ImageContainer>
                <ProfileContainer>
                  <CustomerName>{fullName}</CustomerName>
                  <AddressWrapper>
                    <LocationPin src={locationPinIcon} />
                    <CustomerAddress>{addressSummary}</CustomerAddress>
                  </AddressWrapper>
                  <Select
                    statusType="CUSTOMER"
                    status={customer.status}
                    onChange={
                    (val) => onUpdateCustomerStatus(
                      customer.userId,
                      CustomerStatus[val.target.value as keyof typeof CustomerStatus],
                    )
                  }
                  >
                    <option value={customer.status}>
                      {CUSTOMER_STATUS_DISPLAY[customer.status]}
                    </option>
                    {customerStatusOptions.map(({ value, label }) => value !== customer.status && (
                    <option key={`customerStatus - ${value}`} value={value}>{label}</option>
                    ))}
                  </Select>
                </ProfileContainer>
              </ProfileWrapper>
            </HeaderRow>
            <HeaderRow>
              <DollarIcon src={dollarIcon} />
              <Button
                onClick={handleTransferFunds}
                variant="dark-inverse"
                height="56px"
                fontSize="clamp(0.5rem, 0.8rem, 1rem)"
                padding="1rem 2rem"
                rounded
                margin="0 0.5rem"
              >
                {ProfileHeaderStrings.transferFundsButtonText}
              </Button>
              <Button
                onClick={handleCreatePlan}
                variant="dark"
                height="56px"
                rounded
                fontSize="clamp(0.5rem, 0.8rem, 1rem)"
                padding="1rem 2rem"
                margin="0 0.5rem"
              >
                {ProfileHeaderStrings.createPlanButtonText}
              </Button>
              <Button
                onClick={handleFinalizePlan}
                variant="primary"
                height="56px"
                rounded
                fontSize="clamp(0.5rem, 0.8rem, 1rem)"
                padding="1rem 2rem"
                margin="0 0.5rem"
              >
                {ProfileHeaderStrings.finalisePlanButtonText}
              </Button>
              <ContextMenu
                forProfileHeader
                onDeleteClick={() => setIsDeleting({ ...isDeleting, modalOpen: true })}
              />
            </HeaderRow>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileHeader;
