import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import CustomerConfirmModal from '../../Common/CustomerConfirmModal';

import {
  NavWrapper,
  NavItems,
  NavLink,
  NavLinkText,
  RightContentWrapper,
  UserBadge,
  UserNameWrapper,
  LogOut,
  MobileToggle,
  MobileLogout,
} from './Styles';

import useStrings from '../../../hooks/useStrings';
import { signOut } from '../../../lib/api/Auth';
import { HamburgerIcon } from '../../../assets/Icons';

import { ApplicationState } from '../../../lib/store/index';
import { APIThunkDispatch } from '../../../lib/types/API';

const TopNav: React.FC = () => {
  const dispatch: APIThunkDispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [{ Components: { UI: { TopNav: Strings } } }] = useStrings();
  const { profileState: { profileData } } = useSelector((state: ApplicationState) => state.customer);
  const { firstName, lastName } = profileData?.user || {};
  const initials = `${firstName?.charAt(0)}${lastName?.charAt(0)}`;

  const linkOptions = [
    {
      to: '/customer/dashboard',
      text: Strings.dashboard,
    },
    {
      to: '/customer/create-plan',
      text: Strings.createPlan,
    },
    {
      to: '/customer/receipts',
      text: Strings.receipts,
    },
    {
      to: '/customer/edit-profile',
      text: Strings.editProfile,
    },
    {
      to: '/customer/update-password',
      text: Strings.updatePassword,
    },
    {
      to: '/customer/contact-us',
      text: Strings.contactUs,
    },
    {
      to: '/customer/refer-friend',
      text: Strings.referFriend,
    },
  ];

  const handleLogout = async () => {
    await dispatch(signOut());
  };

  return (
    <NavWrapper>
      <CustomerConfirmModal
        title={Strings.modalTitle}
        titleHighlight={Strings.modalTitleHighlight}
        subtext={Strings.modalSubtext}
        onConfirm={handleLogout}
        onCancel={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        confirmButtonText={Strings.confirmButton}
        cancelButtonText={Strings.cancelButton}
      />
      <NavItems openMobile={isOpen}>
        {linkOptions.map((link) => (
          <NavLink key={link.to} to={link.to}>
            <NavLinkText>{link.text}</NavLinkText>
          </NavLink>
        ))}
        <MobileLogout
          onClick={async () => {
            await dispatch(signOut());
            history.push('/');
          }}
        >
          {Strings.logout}
        </MobileLogout>
      </NavItems>
      <RightContentWrapper>
        {!!initials && (
          <UserBadge>
            {initials}
          </UserBadge>
        )}
        <UserNameWrapper>
          <span>
            {firstName}
            &nbsp;
            {lastName}
          </span>
          <LogOut onClick={() => setIsModalOpen(true)}>{Strings.logout}</LogOut>
        </UserNameWrapper>
        <MobileToggle onClick={() => setIsOpen(!isOpen)} src={HamburgerIcon} />
      </RightContentWrapper>
    </NavWrapper>
  );
};

export default TopNav;
