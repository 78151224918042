import {
  apiFetch,
  apiRequest,
  objectToQueryString,
} from '../Utils';

import { DEFAULT_FILTER, serverAdminAPI } from '../../../CONSTANTS';

import {
  APIThunkResult,
  OrderDirection,
  APIFilterOptions,
} from '../../types/API';
import { FilteredReferralsDTO } from '../../types/DTO';

export const defaultFilter = {
  page: DEFAULT_FILTER.page,
  rowsPerPage: DEFAULT_FILTER.rowsPerPage,
  orderColumn: 'createdAt',
  orderDirection: OrderDirection.DESC,
};

export const getReferrals = (
  customerId: number,
  filter: APIFilterOptions,
): APIThunkResult<FilteredReferralsDTO> => (
  apiRequest<FilteredReferralsDTO>(async () => {
    const query = objectToQueryString(Object(filter));
    const { data, error } = await apiFetch<FilteredReferralsDTO>({
      method: 'GET',
      url: `${serverAdminAPI}/referrals/${customerId}/?${query}`,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);