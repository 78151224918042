import React from 'react';

import Button from '../Button';

import {
  ModalBackground,
  ModalWrapper,
  ButtonWrapper,
  TextWrapper,
  ModalTitle,
  ModalSubtext,
  HeadingWrapper,
} from './Styles';

import { SuccessIcon } from '../../../assets/Icons/index';

import { SuccessModalProps } from './types';
import useStrings from '../../../hooks/useStrings';

const SuccessModal: React.FC<SuccessModalProps> = ({
  isOpen,
  onClose,
  title,
  titleHighlight = false,
  subtext,
  buttonText,
  variant = 'primary',
  buttonDisabled = false,
}) => {
  const [{ GenericText }] = useStrings();
  const stopClickBubbling = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  return (
    <ModalBackground
      opened={isOpen}
      onClick={onClose}
    >
      <ModalWrapper
        onClick={stopClickBubbling}
        variant={variant}
      >
        <img alt="Success Icon" src={SuccessIcon} />
        <TextWrapper>
          <HeadingWrapper>
            <ModalTitle>{title}</ModalTitle>
            {titleHighlight && (
              <ModalTitle highlight>
                &nbsp;
                {titleHighlight}
              </ModalTitle>
            )}
          </HeadingWrapper>
          {subtext && (
            <ModalSubtext>
              {subtext}
            </ModalSubtext>
          )}
        </TextWrapper>
        <ButtonWrapper>
          <Button
            onClick={onClose}
            variant="primary"
            width="140px"
            disabled={buttonDisabled}
          >
            {buttonText || GenericText.okay}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </ModalBackground>
  );
};

export default SuccessModal;
