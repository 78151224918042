import React from 'react';

import useStrings from '../../../hooks/useStrings';

import Button from '../Button';

import {
  StyledBulkEdit,
  SelectedText,
  ButtonWrapper,
} from './Styles';

import { TableBulkEditProps } from './types';

const TableBulkEdit: React.FC<TableBulkEditProps> = ({
  numberSelected,
  allSelected,
  onMarkSelectedAsPaid,
  onMarkSelectedAsUnpaid,
}) => {
  const [{ Components: { Common: { TableBulkEdit: Strings } } }] = useStrings();

  const renderSelectedText = () => {
    if (allSelected) return `${Strings.allSelected} (${numberSelected})`;
    if (numberSelected === 1) return `${numberSelected} ${Strings.oneSelected}`;
    return `${numberSelected} ${Strings.multipleSelected}`;
  };

  return (
    <StyledBulkEdit>
      <SelectedText>{renderSelectedText()}</SelectedText>
      <ButtonWrapper>
        <Button
          onClick={onMarkSelectedAsUnpaid}
          variant="dark-inverse"
          height="28px"
          rounded
          fontSize="12px"
          padding="1rem 1.5rem"
        >
          {Strings.markAsUnPaidButton}
        </Button>
        <Button
          onClick={onMarkSelectedAsPaid}
          variant="green"
          height="28px"
          rounded
          fontSize="12px"
          padding="1rem 1.5rem"
        >
          {Strings.markAsPaidButton}
        </Button>
      </ButtonWrapper>
    </StyledBulkEdit>
  );
};

export default TableBulkEdit;
