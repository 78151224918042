import styled from 'styled-components/macro';
import { NavLink as ReactNavLink } from 'react-router-dom';

import { NavProps } from './types';

export const NavWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between; 
`;

export const NavItems = styled.div<NavProps>`
  align-items: center;
  background: ${({ theme }) => theme.basePalette.navyMain};
  flex-direction: column;
  justify-content: space-between; 
  display: ${({ openMobile }) => (openMobile ? 'flex' : 'none')};
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  width: 100vw;
  z-index: 10;
  padding: 20px 0;
  box-shadow: 0px 140px 200px 0px rgba(0,0,0,0.5);

  ${({ theme }) => theme.device.tablet} {
    left: unset;
    padding: 20px ${({ theme }) => theme.spacing.L}rem;
    width: auto;
  }

  ${({ theme }) => theme.device.laptop} {
    display: block;
    position: static;
    flex-direction: row;
    background: unset;
    padding: 0;
    width: auto;
  }
`;

export const NavLinkText = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export const NavLink = styled(ReactNavLink)<NavProps>`
  color: ${({ theme }) => theme.basePalette.white};
  text-decoration: none;
  padding: 20px 0;

  &.active ${NavLinkText} {
    font-weight: ${({ theme }) => theme.fontWeight.black};
    font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  }

  &:focus ${NavLinkText}, &:hover ${NavLinkText} {
    color: ${({ theme }) => theme.palette.titleHighlight};
  }

  ${({ theme }) => theme.device.laptop} {
    color: ${({ theme }) => theme.basePalette.navyMain};
    margin-right: ${({ theme }) => theme.spacing.XS}rem;
    padding: 0;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const RightContentWrapper = styled.div`
  border-left: 1px solid ${({ theme }) => theme.palette.borderGrey};
  display: flex;
  height: 44px;

  ${({ theme }) => theme.device.laptop} {
    height: auto;
  }
`;

export const UserBadge = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.basePalette.navyMain};
  border-radius: 25px;
  color: ${({ theme }) => theme.basePalette.white};
  display: none;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  height: 44px;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing.XXS}rem;
  margin-left: ${({ theme }) => theme.spacing.XS / 1.3}rem;
  width: 44px;

  ${({ theme }) => theme.device.laptop} {
    display: flex;
  }
`;

export const UserNameWrapper = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.device.laptop} {
    display: flex;
  }
`;

export const LogOut = styled.a`
  color: ${({ theme }) => theme.basePalette.blueMain};
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
`;

export const MobileToggle = styled.img`
  cursor: pointer;
  display: block;
  margin-left: ${({ theme }) => theme.spacing.XXS}rem;

  ${({ theme }) => theme.device.laptop} {
    display: none;
  }
`;

export const MobileLogout = styled.a`
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  padding: 40px 0;
  color: ${({ theme }) => theme.basePalette.white};
  cursor: pointer;

  &:focus, &:hover {
    color: ${({ theme }) => theme.palette.titleHighlight};
  }

  ${({ theme }) => theme.device.laptop} {
    display: none;
  }
`;
