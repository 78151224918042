import {
  SET_ALL_PAYABLE_PLAN_DATA,
  PayablePlanActionTypes,
  PayablePlanState,
} from './types';

const initialState: PayablePlanState = {
  payablePlans: [],
};

const PayablePlanReducer = (
  state: PayablePlanState = initialState,
  action: PayablePlanActionTypes,
): PayablePlanState => {
  switch (action.type) {
    case SET_ALL_PAYABLE_PLAN_DATA:
      return {
        ...state,
        payablePlans: action.payablePlans,
      };
    default:
      return state;
  }
};

export default PayablePlanReducer;
