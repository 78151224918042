import React, { FC } from 'react';

import Grid from '../../Grid';
import TableFilterSelectButton from './TableFilterSelectButton';

import { CustomFilterHeading, ButtonGroupWrapper } from './Styles';

import { TableFilterButtonGroupProps } from '../types';

const TableFilterButtonGroup: FC<TableFilterButtonGroupProps> = ({
  heading,
  options,
  selectedOptions,
  handleClick = () => null,
}) => {
  const FilterButtons = (
    <ButtonGroupWrapper>
      {options.map((option) => {
        const { label, value } = option;
        return (
          <TableFilterSelectButton
            key={value}
            selected={selectedOptions?.includes(value) || false}
            handleClick={() => handleClick(option)}
          >
            {label}
          </TableFilterSelectButton>
        );
      })}
    </ButtonGroupWrapper>
  );

  return (
    <Grid>
      <Grid row maxWidth="100%" marginTop="1.5rem">
        <Grid column sm={12}>
          <CustomFilterHeading>{heading}</CustomFilterHeading>
        </Grid>
      </Grid>
      <Grid column sm={12}>
        {FilterButtons}
      </Grid>
    </Grid>
  );
};

export default TableFilterButtonGroup;
