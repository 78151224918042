import React from 'react';

import { Row, Column } from './Styles';

import { GridProps } from './types';

const Grid: React.FC<GridProps> = ({
  alignItems,
  column,
  row,
  expanded,
  justify,
  lg,
  md,
  sm,
  marginTop = '0',
  maxWidth,
  children,
  expandedMobile = false,
}) => {
  const isRow = row || !column;

  return isRow ? (
    <Row
      expanded={expanded}
      justify={justify}
      alignItems={alignItems}
      marginTop={marginTop}
      maxWidth={maxWidth}
    >
      {children}
    </Row>
  ) : (
    <Column
      sm={sm}
      md={md}
      lg={lg}
      justify={justify}
      marginTop={marginTop}
      alignItems={alignItems}
      expanded={expanded}
      expandedMobile={expandedMobile}
    >
      {children}
    </Column>
  );
};

export default Grid;
