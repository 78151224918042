import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import Select from 'react-select';

import {
  StyledModalDropdown,
  Label,
  Error,
  Icon,
  IconWrapper,
} from './Styles';

import { ModalDropdownProps } from './types';
import { blueCaretDown } from '../../../assets/Icons';

import { DropdownOption } from '../../../lib/types/Dropdown';

const ModalDropdown: React.FC<ModalDropdownProps> = ({
  name,
  onLoadOption,
  options,
  placeholder,
  label,
  disabled = false,
  onOptionClick,
  notEditable,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<DropdownOption | null>();

  // Component Must be used inside of <Formik> tags to access Formik context
  const [, meta] = useField(name);
  const { error } = meta;

  const SelectIcon = () => (
    <IconWrapper>
      <Icon src={blueCaretDown} disabled={disabled} />
    </IconWrapper>
  );

  useEffect(() => {
    if (onLoadOption === undefined) return;
    const currentOption = options.find((option) => option.value === onLoadOption) || null;
    setSelected(currentOption);
  }, [onLoadOption, options, label]);

  return (
    <StyledModalDropdown
      disabled={disabled}
      error={!!error}
      notEditable={notEditable}
      isOpen={isOpen}
      onClick={() => !disabled && setIsOpen(!isOpen)}
    >
      {!!label && (
        <Label
          error={!!error}
          disabled={disabled}
        >
          {label}
        </Label>
      )}
      <Select
        classNamePrefix="react-select"
        options={options}
        disabled={disabled}
        onChange={(option) => {
          if (option) {
            setSelected(option);
            onOptionClick(option.value);
          }
        }}
        value={selected}
        placeholder={placeholder}
        isDisabled={disabled}
        components={{ DropdownIndicator: SelectIcon }}
        menuIsOpen={isOpen}
      />
      {error && <Error className="error">{error}</Error>}
    </StyledModalDropdown>
  );
};

export default ModalDropdown;
