import React from 'react';
import Slider, { SliderTooltip, Handle, SliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import './Slider.css';

import { StyledSlider, StyledSliderToolTip } from './Styles';

import { RangeSliderProps } from './types';

const RangeSlider: React.FC<RangeSliderProps> = ({ defaultValue, onChange }) => {
  const handle = (props: SliderProps) => {
    const { value, ...restProps } = props;
    return (
      <StyledSliderToolTip>
        <SliderTooltip
          prefixCls="rc-slider-tooltip"
          overlay={`$${value}`}
          placement="top"
          key={value}
          visible
        >
          <Handle value={value} {...restProps} />
        </SliderTooltip>
      </StyledSliderToolTip>
    );
  };

  return (
    <StyledSlider>
      <Slider
        min={0}
        max={1600}
        step={1}
        defaultValue={defaultValue}
        handle={handle}
        onChange={onChange}
      />
    </StyledSlider>
  );
};

export default RangeSlider;
