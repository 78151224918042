import styled from 'styled-components/macro';
import { ToolTipWrapperProps } from './types';

export const ToolTipWrapper = styled.div<ToolTipWrapperProps>`
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  background-color: ${({ theme }) => theme.basePalette.navyMain};
  color: ${({ theme }) => theme.basePalette.white};
  border-radius: 25px;
  padding: ${({ theme }) => theme.spacing.XXS}rem;
  position: absolute;
  z-index: 1;
  right: -173px;
  top: -120px;
  justify-content: center;
  align-items: center;
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) => theme.basePalette.black} transparent transparent transparent;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;