import {
  SET_ALL_OVERDUE_INSTALLMENT_DATA,
  SET_UPDATED_OVERDUE_INSTALLMENT_DATA,
  REMOVE_OVERDUE_INSTALLMENTS,
  OverdueInstallmentActionTypes,
  OverdueInstallmentState,
} from './types';

import {
  setUpdatedRecordsInState,
  removeRecordsFromState,
} from '../../../utils';

const initialState: OverdueInstallmentState = {
  overdueInstallmentData: {
    overdueInstallments: [],
    count: 0,
  },
};

const OverdueInstallmentsReducer = (
  state: OverdueInstallmentState = initialState,
  action: OverdueInstallmentActionTypes,
): OverdueInstallmentState => {
  switch (action.type) {
    case SET_ALL_OVERDUE_INSTALLMENT_DATA:
      return {
        ...state,
        overdueInstallmentData: {
          overdueInstallments: action.overdueInstallmentData.results,
          count: action.overdueInstallmentData.count,
        },
      };
    case SET_UPDATED_OVERDUE_INSTALLMENT_DATA:
      return {
        ...state,
        overdueInstallmentData: {
          overdueInstallments: setUpdatedRecordsInState('installmentId', state.overdueInstallmentData.overdueInstallments, action.overdueInstallments),
          count: state.overdueInstallmentData.count,
        },
      };
    case REMOVE_OVERDUE_INSTALLMENTS:
      return {
        ...state,
        overdueInstallmentData: {
          overdueInstallments: removeRecordsFromState('installmentId', state.overdueInstallmentData.overdueInstallments, action.installmentIds),
          count: state.overdueInstallmentData.count - action.installmentIds.length,
        },
      };
    default:
      return state;
  }
};

export default OverdueInstallmentsReducer;
