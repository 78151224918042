import React from 'react';
import DataTable from 'react-data-table-component';
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';

import useStrings from '../../../../hooks/useStrings';

import { buildHandlers } from '../../../../components/Common/Table/handlers';
import Pagination from '../../../../components/Common/Table/Pagination';
import { NoRecordsText } from '../../../../components/Common/Table/Styles';

import { extendedTableStyles } from './Styles';

import { TableColumn, TableRow } from '../../../../components/Common/Table/types';
import { OrderDirection, APIFilterOptions } from '../../../../lib/types/API';
import { EmailTableProps } from '../types';
import { SupportMessageListView } from '../../../../lib/types/DBViews';
import { SupportMessageStatus } from '../../../../lib/types/SupportMessage';
import { SUPPORT_MESSAGE_STATUS_DISPLAY } from '../../../../CONSTANTS';

const EmailTable: React.FC<EmailTableProps<APIFilterOptions>> = ({
  currentData,
  recordCount,
  filter,
  selectedMessage,
  updateFilter,
  onRowClicked,
}) => {
  const theme = useTheme();
  const columns: TableColumn<SupportMessageListView>[] = [
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: true,
      grow: 1,
      format: ({ createdAt }) => dayjs(createdAt as Date).format('DD/MM/YY h:mma'),
    },
    {
      name: 'Name',
      selector: 'customerName',
      sortable: true,
      grow: 1.2,
      conditionalCellStyles: [
        {
          when: () => true,
          style: { fontWeight: 'bold' },
        },
      ],
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      grow: 0.5,
      format: ({ status }) => SUPPORT_MESSAGE_STATUS_DISPLAY[status as string],
    },
    {
      name: 'Subject',
      selector: 'subject',
      sortable: true,
      grow: 1,
    },
    {
      name: 'Message',
      selector: 'message',
      sortable: true,
      grow: 1.2,
      format: ({ message }) => `${(message ? message.toString().slice(0, 20) : '')}...`,

    },
  ];
  const conditionalRowStyles = [
    {
      when: (row: TableRow) => row.status === SupportMessageStatus.RESOLVED,
      style: {
        opacity: '0.3',
      },
    },
    {
      when: (row: TableRow) => row.supportMessageId === selectedMessage?.supportMessageId,
      style: {
        backgroundColor: theme.basePalette.navyMain,
        color: theme.basePalette.white,
      },
    },
  ];

  const [STRINGS] = useStrings();

  const Loader = (
    <NoRecordsText>
      <p>{STRINGS.Components.Common.Table.noRecords}</p>
    </NoRecordsText>
  );

  const {
    handlePageChange,
    handleSortChange,
  } = buildHandlers<APIFilterOptions>(
    updateFilter,
    filter,
    undefined,
    undefined,
    undefined,
  );

  return (
    <DataTable
      highlightOnHover
      noContextMenu
      noHeader
      onRowClicked={onRowClicked}
      data={currentData}
      keyField="key"
      columns={columns}
      pagination
      paginationServer
      paginationPerPage={filter.rowsPerPage}
      paginationTotalRows={recordCount}
      onChangePage={handlePageChange}
      paginationComponent={Pagination}
      onSort={handleSortChange}
      sortServer
      defaultSortField={filter.orderColumn}
      defaultSortAsc={filter.orderDirection === OrderDirection.ASC}
      noDataComponent={Loader}
      customStyles={extendedTableStyles}
      conditionalRowStyles={conditionalRowStyles}
      style={{ borderRadius: 0 }}
    />
  );
};

export default EmailTable;
