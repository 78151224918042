import styled from 'styled-components';

export const StyledBulkEdit = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  background-color: ${({ theme }) => theme.basePalette.white};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 1px ${({ theme }) => theme.basePalette.greyMain};
  z-index: 100; 
  ${({ theme }) => theme.device.tablet} {
    left: 250px;
  }

  @keyframes slideUp {
    0% {
      height: 0;
    }
    100% {
      height: 60px;
    }
  }

  animation: 0.3s ease-in 0s 1 slideUp;
`;

export const SelectedText = styled.div`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-left: ${({ theme }) => theme.spacing.S}rem;

  @media(max-width: 831px) {
  margin-left: ${({ theme }) => theme.spacing.XS}rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: ${({ theme }) => theme.spacing.S}rem;

  @media(max-width: 831px) {
  margin-right: ${({ theme }) => theme.spacing.XS}rem;
  }
`;
