import { PaymentDataView } from '../../../../types/DBViews';

import {
  SetCustomerPaymentDataAction,
  SET_CUSTOMER_PAYMENT_DATA,
} from './types';

export const setCustomerPaymentData = (
  payments: PaymentDataView[],
): SetCustomerPaymentDataAction => ({
  type: SET_CUSTOMER_PAYMENT_DATA,
  payments,
});
