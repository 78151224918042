import { apiFetch, apiRequest, objectToQueryString, stripDate } from '../Utils';
import { serverCustomerAPI, DEFAULT_FILTER } from '../../../CONSTANTS';
import { setCustomerPlanData } from '../../store/contexts/customer/plans/actions';

import { APIThunkResult, OrderDirection, PlanFilterOptions } from '../../types/API';
import { FilteredPlansDTO, PagedResponse, PlanDTO } from '../../types/DTO';
import { Plan } from '../../types/DBModels';
import { CustomerPlansListView } from '../../types/DBViews';
import { setPaginatedCustomerPlanData } from '../../store/contexts/customer/plansPaginated/actions';

export const defaultFilter = {
  page: DEFAULT_FILTER.page,
  rowsPerPage: DEFAULT_FILTER.rowsPerPage,
  orderColumn: 'dueDate',
  orderDirection: OrderDirection.ASC,
};

export const getCustomerPlans = (): APIThunkResult<PagedResponse<CustomerPlansListView>> => (
  apiRequest<PagedResponse<CustomerPlansListView>>(async (dispatch) => {
    const url = `${serverCustomerAPI}/plan`;

    const { data, error } = await apiFetch<PagedResponse<CustomerPlansListView>>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setCustomerPlanData(data.results));

    return data;
  }, true)
);

export const getCustomerPlansPaginated = (filter: PlanFilterOptions): APIThunkResult<FilteredPlansDTO> => (
  apiRequest<FilteredPlansDTO>(async (dispatch) => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverCustomerAPI}/plan/paginated?${query}`;

    const { data, error } = await apiFetch<FilteredPlansDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setPaginatedCustomerPlanData(data.plans));

    return data;
  }, true)
);

export const createPlan = (
  input: Plan,
): APIThunkResult<PlanDTO> => apiRequest<PlanDTO>(
  async () => {
    const { data, error } = await apiFetch<PlanDTO>({
      method: 'POST',
      url: `${serverCustomerAPI}/plan/create`,
      body: {
        ...input,
        startDate: stripDate(input.startDate),
        endDate: stripDate(input.endDate),
        dueDate: stripDate(input.dueDate),
        paidDate: stripDate(input.paidDate),
        vehicle: {
          ...input.vehicle,
          year: stripDate(input.vehicle?.year),
        },
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  },
  true,
);
