import { apiFetch, apiRequest } from '../Utils';
import { getProfile } from './profile';
import { serverCustomerAPI } from '../../../CONSTANTS';

import { APIThunkResult } from '../../types/API';
import { RedirectParametersDTO, PaymentServiceResultDTO } from '../../types/DTO';

export const getRedirectParameters = (): APIThunkResult<RedirectParametersDTO> => (
  apiRequest<RedirectParametersDTO>(
    async () => {
      const url = `${serverCustomerAPI}/payment-service/get-redirect-parameters`;

      const { data, error } = await apiFetch<RedirectParametersDTO>({
        method: 'GET',
        url,
      });

      if (!data || error) throw new Error(`${error?.message}`);

      return data;
    }, true,
  )
);

export const getIntegrationResult = (
  webPageToken: string,
): APIThunkResult<PaymentServiceResultDTO> => (
  apiRequest<PaymentServiceResultDTO>(
    async (dispatch) => {
      const url = `${serverCustomerAPI}/payment-service/result`;

      const { data, error } = await apiFetch<PaymentServiceResultDTO>({
        method: 'POST',
        url,
        body: {
          webPageToken,
        },
      });

      if (!data || error) throw new Error(`${error?.message}`);

      if (data.success) dispatch<void>(getProfile());

      return data;
    }, false,
  )
);
