import React from 'react';
import dayjs from 'dayjs';

import {
  Container,
  ContentWrapper,
  IconWrapper,
  PlanTypeText,
  DueDateWrap,
  DueDate,
  InstallmentAmount,
  InstallmentFrequency,
  PlanInfoText,
  ProgressTextWrap,
  ProgressBar,
  ProgressFill,
  LineBreak,
  PlanInfoWrapper,
  StatusText,
} from './Styles';

import { PlanItemProps } from './types';
import { PlanIcons, VehicleIcons } from '../../../../assets/Icons';
import { INSTALLMENT_FREQUENCY_ABBR, PLAN_STATUS_DISPLAY, PLAN_TYPE_DISPLAY } from '../../../../CONSTANTS';
import { PlanStatus, PlanType } from '../../../../lib/types/Plan';

const PlanItem: React.FC<PlanItemProps> = ({ plan }) => {
  const {
    planType,
    dueDate,
    vehicleModel,
    definedVehicleMake,
    inputVehicleMake,
    vehicleColor,
    vehicleRegistration,
    latestInstallmentValue,
    frequency,
    value,
    vehicleType,
    planName,
    alreadyPaidLessFees,
    receivedValue,
    status,
  } = plan;

  const paidProgress = ((alreadyPaidLessFees + receivedValue) / value) * 100;

  const iconSrc = planType === PlanType.VEHICLE_REGISTRATION
    ? VehicleIcons[vehicleType]
    : PlanIcons[planType];

  const renderPlanInfo = () => (planType === PlanType.VEHICLE_REGISTRATION ? (
    <div>
      <PlanInfoText>
        {definedVehicleMake || inputVehicleMake}
        &nbsp;
        &#8226;
        &nbsp;
        {vehicleColor}
        {vehicleModel && vehicleModel !== 'UNKNOWN' && (
          <>
            &nbsp;
            &#8226;
            &nbsp;
            {vehicleModel}
          </>
        )}

      </PlanInfoText>
    </div>
  ) : (
    <div>
      <PlanInfoText>{PLAN_TYPE_DISPLAY[plan.planType]}</PlanInfoText>
    </div>
  ));

  return (
    <Container>
      <DueDateWrap>
        <DueDate>{dayjs(dueDate).format('DD MMM YY')}</DueDate>
      </DueDateWrap>
      <ContentWrapper>
        <IconWrapper>
          <img src={iconSrc} alt="Plan Icon" />
        </IconWrapper>
        <PlanInfoWrapper>
          <PlanTypeText>{planName || vehicleRegistration}</PlanTypeText>
          {renderPlanInfo()}
        </PlanInfoWrapper>
      </ContentWrapper>
      <LineBreak />
      <ContentWrapper>
        <ProgressTextWrap>
          {/* Set the paid progress number to the exact plan value if plan is set to due */}
          <PlanInfoText dark>${plan.status === PlanStatus.DUE
            ? value
            : (alreadyPaidLessFees + receivedValue).toFixed(2)}
          </PlanInfoText>
          <PlanInfoText>${value}</PlanInfoText>
        </ProgressTextWrap>
        <ProgressBar>
          <ProgressFill progress={paidProgress} />
        </ProgressBar>
      </ContentWrapper>
      <LineBreak />
      <ContentWrapper>
        <InstallmentAmount>${latestInstallmentValue?.toFixed(2) || ''}</InstallmentAmount>
        <InstallmentFrequency>{INSTALLMENT_FREQUENCY_ABBR[frequency]}</InstallmentFrequency>
        <StatusText planStatus={status}>{PLAN_STATUS_DISPLAY[status]}</StatusText>
      </ContentWrapper>
    </Container>
  );
};

export default PlanItem;
