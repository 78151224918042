import React, { useState } from 'react';

import Grid from '../../Grid';
import DatePicker from '../../DatePicker';

import { TableFilterDateRangeProps } from '../types';
import {
  FilterSubHeading,
  LineBreak,
} from './Styles';

const TableFilterDateRange: React.FC<TableFilterDateRangeProps> = ({
  heading,
  currentMin,
  currentMax,
  handleUpdate,
}) => {
  const [values, setValues] = useState({
    min: currentMin,
    max: currentMax,
  });

  const handleChangeMin = (date: Date | Date[]) => {
    const val = Array.isArray(date) ? date[0] : date;
    setValues({ min: val, max: values.max });
    handleUpdate({ min: val, max: values.max });
  };

  const handleChangeMax = (date: Date | Date[]) => {
    const val = Array.isArray(date) ? date[0] : date;
    setValues({ min: values.min, max: val });
    handleUpdate({ min: values.min, max: val });
  };

  return (
    <Grid>
      <Grid row maxWidth="100%" marginTop="1.5rem">
        <Grid column sm={12}>
          <FilterSubHeading>{heading}</FilterSubHeading>
        </Grid>
      </Grid>
      <Grid row maxWidth="100%" marginTop="0.5rem">
        <Grid column sm={6}>
          <DatePicker
            onSelect={handleChangeMin}
            onLoadDate={values.min && new Date(values.min)}
            margin="0"
          />
        </Grid>
        <Grid column sm={6}>
          <DatePicker
            onSelect={handleChangeMax}
            onLoadDate={values.max && new Date(values.max)}
            margin="0"
          />
        </Grid>
      </Grid>
      <Grid row maxWidth="100%" marginTop="2rem">
        <Grid column sm={12}>
          <LineBreak />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TableFilterDateRange;
