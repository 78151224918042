import { auth, User } from 'firebase/app';
import 'firebase/auth';

import { UserCredentials } from './types/Auth';

export const getAuthUser = (): User | null => auth().currentUser;

/** Auth Change Listener */
export const onAuthChange = (
  callback: (a: User | null) => Promise<void>,
): firebase.Unsubscribe => auth().onAuthStateChanged(callback);

/** Sign up with Username and Password */
export const signUp = (email: string, password: string): Promise<UserCredentials> => auth()
  .createUserWithEmailAndPassword(email, password);

/** Sign in with Username and Password */
// IMPORTANT: Do not call this directly - use function in /lib/api
export const signIn = (email: string, password: string): Promise<UserCredentials> => auth()
  .setPersistence(auth.Auth.Persistence.LOCAL)
  .then(() => auth().signInWithEmailAndPassword(email, password));

/** Sign out */
// IMPORTANT: Do not call this directly - use function in /lib/api
export const signOut = (): Promise<void> => auth().signOut();

/** Reset Password */
export const resetPassword = (email: string): Promise<void> => auth().sendPasswordResetEmail(email, { url: `${process.env.REACT_APP_HOME_PAGE}`, handleCodeInApp: false });

/** Update Password */
export const updatePassword = async (
  currentPassword: string,
  newPassword: string,
): Promise<void> => {
  const authUser = getAuthUser();
  return authUser && authUser.email
    ? authUser.reauthenticateWithCredential(auth.EmailAuthProvider.credential(authUser.email, currentPassword))
      .then(() => authUser.updatePassword(newPassword))
      .catch((error) => {
        throw new Error(error.message);
      })
    : undefined;
};
/** Create Password */
export const createPassword = async (
  newPassword: string,
): Promise<void> => {
  const authUser = getAuthUser();
  return authUser
    ? authUser.updatePassword(newPassword)
      .catch(async (error) => {
        if (error.code === 'auth/requires-recent-login') await signOut();
        throw new Error(error);
      })
    : undefined;
};

export const sendEmailVerification = (): Promise<void> | undefined => auth()
  .currentUser?.sendEmailVerification();

export const checkUserExists = async (email: string): Promise<boolean> => {
  const methods = await auth().fetchSignInMethodsForEmail(email);
  return methods.length > 0;
};

export default {
  getAuthUser,
  onAuthChange,
  signUp,
  signIn,
  signOut,
  resetPassword,
  updatePassword,
  sendEmailVerification,
  checkUserExists,
};
