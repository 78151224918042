import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import Select, { InputActionMeta } from 'react-select';

import {
  StyledModalDropdown,
  Label,
  Error,
  Icon,
  IconWrapper,
} from '../Styles';

import { ModalVehicleDropdownProps } from './types';
import { blueCaretDown } from '../../../../assets/Icons';

import { VehicleDropdownOption } from '../../../../lib/types/Dropdown';

// Component currently used as value for vehicle makes must be a number
// TODO: update existing dropdowns to handle this so we don't need this component
const ModalVehicleDropdown: React.FC<ModalVehicleDropdownProps> = ({
  name,
  onLoadOption,
  options,
  placeholder,
  label,
  disabled = false,
  onOptionClick,
  notEditable,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<VehicleDropdownOption | null>();

  // Component Must be used inside of <Formik> tags to access Formik context
  const [, meta] = useField(name);
  const { error } = meta;

  const SelectIcon = () => (
    <IconWrapper>
      <Icon src={blueCaretDown} disabled={disabled} />
    </IconWrapper>
  );

  useEffect(() => {
    if (onLoadOption === undefined) {
      setSelected(null);
      return;
    }
    const currentOption = options.find((option) => option.value === onLoadOption) || null;
    setSelected(currentOption);
  }, [onLoadOption, options, label]);

  return (
    <StyledModalDropdown
      disabled={disabled}
      error={!!error}
      notEditable={notEditable}
      isOpen={isOpen}
      onClick={() => !disabled && setIsOpen(!isOpen)}
    >
      {!!label && (
        <Label
          error={!!error}
          disabled={disabled}
        >
          {label}
        </Label>
      )}
      <Select
        classNamePrefix="react-select"
        options={options}
        disabled={disabled}
        onChange={(option) => {
          if (option) {
            setSelected(option);
            onOptionClick(option.value);
          }
        }}
        onInputChange={(newValue: string, actionMeta: InputActionMeta) => {
          if (actionMeta.action === 'set-value') setIsOpen(false);
          else if (newValue) setIsOpen(true);
        }}
        value={selected}
        placeholder={placeholder}
        isDisabled={disabled}
        components={{ DropdownIndicator: SelectIcon }}
        menuIsOpen={isOpen}
      />
      {error && <Error className="error">{error}</Error>}
    </StyledModalDropdown>
  );
};

export default ModalVehicleDropdown;
