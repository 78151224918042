import { useEffect, RefObject } from 'react';

/**
 * Hook that fires a callback on click anywhere outside of a ref
 */
const useClickOutside = (
  ref: RefObject<HTMLElement>,
  onOutsideClick: () => void,
): void => {
  useEffect(() => {
    /**
     * Fire if clicked on outside of any element passed in as a ref
     */
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onOutsideClick]);
};

export default useClickOutside;
