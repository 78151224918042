import * as yup from 'yup';
import dayjs from 'dayjs';

const signupSchema = yup
  .object({
    planDueDate: yup
      .date()
      .nullable()
      .min(
        dayjs().add(2, 'months').toDate(),
        'Oh No!!! Unfortunately, your registration is due too soon to set up a payment plan, as we are not a loan company. To set up a plan for future registrations(s), please call us on 1800 367 438 or log back in after you have paid the current registration.',
      )
      .required('Registration due date required'),
    email: yup
      .string()
      .trim()
      .email('Invalid Email Address')
      .required('Email is required'),
    password: yup
      .string()
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$^+=!*()@%&?-_]).{8,99}$/,
        'Your password must be at least 8 characters long, contain at least one number, one special character and have a mixture of uppercase and lowercase letters',
      )
      .required('Password is required'),
    termsAccepted: yup
      .boolean()
      .oneOf([true], 'You need to accept our terms and privacy policy'),
    newsletterAccepted: yup.boolean(),
    referralId: yup.number().notRequired(),
  })
  .required();

export type SignupSchema = yup.InferType<typeof signupSchema>;

export default signupSchema;
