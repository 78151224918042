import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import dayjs from 'dayjs';

import useStrings from '../../../hooks/useStrings';
import planDetailsSchema from '../../../lib/validators/planDetailsSchema';
import { setNewPlanData } from '../../../lib/store/contexts/customer/plans/actions';
import { getVehicleMakes } from '../../../lib/api/customer/vehicle';
import { roundNum } from '../../../lib/utils';
import {
  REGISTRATION_LENGTH_DISPLAY,
  INSTALLMENT_FREQUENCY_DISPLAY,
  stateOptions,
  NUMBER_REGEX,
  hiddenVehicleTypeOptions,
  CONCESSION_DISPLAY,
} from '../../../CONSTANTS';

import Grid from '../../Common/Grid';
import Input from '../../Common/Input';
import Button from '../../Common/Button';
import DatePicker from '../../Common/DatePicker';
import Dropdown from '../../Common/Dropdown';
import VehicleDropdown from '../../Common/Dropdown/VehicleDropdown';
import Toggle from '../../Common/Toggle';

import {
  FormSection,
  LineBreak,
  ButtonWrapper,
  ConcessionHeading,
  ConcessionText,
} from './Styles';

import { Concession, InstallmentFrequency, RegistrationLength } from '../../../lib/types/Plan';
import { NewPlan } from '../../../lib/store/contexts/customer/plans/types';
import { PlanDetailsFormProps } from './types';
import { VehicleType } from '../../../lib/types/Vehicle';
import { ApplicationState } from '../../../lib/store';
import { APIThunkDispatch } from '../../../lib/types/API';
import { VehicleDropdownOption } from '../../../lib/types/Dropdown';

const PlanDetailsForm: React.FC<PlanDetailsFormProps> = ({ newPlanData, setPlanValues, onBack, onNext }) => {
  const [{ Components: { UI: { PlanDetailsForm: Strings } }, GenericText }] = useStrings();
  const dispatch: APIThunkDispatch = useDispatch();

  const { vehicleMakes } = useSelector((state: ApplicationState) => state.vehicleMakesState);

  const regoLengthDisplayOptions = Object.entries(REGISTRATION_LENGTH_DISPLAY)
    .filter((option) => option[0] !== RegistrationLength.UNKNOWN);

  const carMakes = vehicleMakes.reduce<VehicleDropdownOption[]>((acc, curr) => (
    curr.type === VehicleType.CAR ? [
      ...acc,
      {
        label: curr.name,
        value: curr.vehicleMakeId,
      },
    ] : acc
  ), []);

  const motorcycleMakes = vehicleMakes.reduce<VehicleDropdownOption[]>((acc, curr) => (
    curr.type === VehicleType.MOTORCYCLE ? [
      ...acc,
      {
        label: curr.name,
        value: curr.vehicleMakeId,
      },
    ] : acc
  ), []);

  const fetchVehicleMakes = useCallback(() => {
    dispatch<void>(getVehicleMakes());
  }, [dispatch]);

  // Only allow dropdown for CAR and MOTORCYCLE type as we won't have a list of options to choose from for the others
  const showMakeDropdown = (values: Partial<NewPlan>) => (values.vehicle?.type === VehicleType.CAR
    || values.vehicle?.type === VehicleType.MOTORCYCLE);

  const getFrequencyOptions = (startDate: Date, dueDate: Date) => {
    // Remove ONE_OFF_PAYMENT options from customer plan creation forms
    const preFilteredOptions = INSTALLMENT_FREQUENCY_DISPLAY;
    delete preFilteredOptions[InstallmentFrequency.ONE_OFF_PAYMENT];

    if (dayjs(dueDate).diff(dayjs(startDate).add(1, 'day'), 'weeks') >= 7) {
      return {
        options: preFilteredOptions,
        values: Object.keys(preFilteredOptions),
      };
    }
    const filteredOptions = Object.entries(preFilteredOptions)
      .filter((option) => option[0] !== InstallmentFrequency.MONTH);
    return {
      options: Object.fromEntries(filteredOptions),
      values: [InstallmentFrequency.WEEK, InstallmentFrequency.FORTNIGHT],
    };
  };

  useEffect(() => {
    fetchVehicleMakes();
  }, [fetchVehicleMakes]);

  return (
    <Formik
      initialValues={{
        value: newPlanData?.value || undefined as number | undefined,
        registrationLength: newPlanData?.registrationLength || RegistrationLength.TWELVE_MONTHS,
        startDate: newPlanData?.startDate || undefined as Date | undefined,
        dueDate: newPlanData?.dueDate || undefined as Date | undefined,
        installmentFrequency: newPlanData?.installmentFrequency || InstallmentFrequency.WEEK,
        name: newPlanData?.name || '',
        vehicle: {
          type: newPlanData?.vehicle?.type || '',
          make: newPlanData?.vehicle?.make || '',
          vehicleMakeId: newPlanData?.vehicle?.vehicleMakeId || undefined,
          model: newPlanData?.vehicle?.model || '',
          year: newPlanData?.vehicle?.year || undefined as Date | undefined,
          registeredState: newPlanData?.vehicle?.registeredState || '',
          color: newPlanData?.vehicle?.color || '',
          registration: newPlanData?.vehicle?.registration || '',
        },
        concessionCard: newPlanData?.concessionCard || Concession.NONE,
      }}
      validationSchema={planDetailsSchema}
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        const planData = {
          value: values.value,
          startDate: values.startDate,
          dueDate: values.dueDate,
          installmentFrequency: values.installmentFrequency,
          registrationLength: values.registrationLength,
          name: values.name,
          vehicle: {
            type: values.vehicle.type,
            model: values.vehicle.model,
            make: values.vehicle.make,
            vehicleMakeId: values.vehicle.vehicleMakeId,
            color: values.vehicle.color,
            registration: values.vehicle.registration,
            registeredState: values.vehicle.registeredState,
            year: values.vehicle.year,
          },
          concessionCard: values.concessionCard,
        };
        dispatch(setNewPlanData(planData as Partial<NewPlan>));
        onNext();
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        isSubmitting,
        values,
        errors,
        touched,
        setFieldError,
        setFieldTouched,
      }) => (
        <>
          {isSubmitting && errors && window.scrollTo(0, 0)}
          <FormSection>
            <Grid row marginTop="1rem">
              <Grid column sm={12} alignItems="center">
                <Toggle
                  name="registrationLength"
                  placeholder={Strings.lengthSelect}
                  label={Strings.lengthSelect}
                  options={regoLengthDisplayOptions.map((option) => option[0])}
                  optionsDisplay={Object.fromEntries(regoLengthDisplayOptions)}
                  onOptionClick={((option) => {
                    setFieldValue('registrationLength', option);
                    setPlanValues('registrationLength', option);
                  })}
                  onLoadOption={newPlanData?.registrationLength || RegistrationLength.TWELVE_MONTHS}
                />
              </Grid>
            </Grid>
            <Grid row>
              <Grid column sm={12} lg={6} alignItems="center">
                <Input
                  type="currency"
                  name="value"
                  label={Strings.costInput}
                  placeholder={Strings.costInput}
                  width="100%"
                  outlined
                  onBlur={((e) => {
                    if (NUMBER_REGEX.test(e.target.value) && e.target.value !== '') {
                      setFieldValue('value', roundNum(parseFloat(e.target.value)));
                      setPlanValues('value', roundNum(parseFloat(e.target.value)));
                      setFieldError('value', undefined);
                    } else {
                      setFieldError('value', 'Must be a number');
                      setFieldTouched('value');
                      setFieldValue('value', '');
                    }
                  })}
                />
              </Grid>
              <Grid column sm={12} lg={6} alignItems="center">
                <Input
                  type="text"
                  name="vehicle.registration"
                  label={Strings.registrationInput}
                  placeholder={Strings.registrationInput}
                  width="100%"
                  outlined
                  onChange={((e) => {
                    setFieldValue('vehicle.registration', e.target.value.toUpperCase());
                    setPlanValues('registration', e.target.value.toUpperCase());
                  })}
                />
              </Grid>
            </Grid>
            <Grid row>
              <Grid column sm={12} lg={6}>
                <DatePicker
                  touched={touched.startDate}
                  error={errors.startDate}
                  label={Strings.startDate}
                  onLoadDate={newPlanData?.startDate}
                  minDate={dayjs().toDate()}
                  outlined
                  onSelect={(val) => {
                    setFieldValue('startDate', val);
                    setPlanValues('startDate', val);
                  }}
                />
              </Grid>
              <Grid column sm={12} lg={6}>
                <DatePicker
                  touched={touched.dueDate}
                  error={errors.dueDate}
                  label={Strings.dueDate}
                  onLoadDate={newPlanData?.dueDate}
                  outlined
                  minDate={dayjs(values.startDate).add(1, 'month').add(1, 'day').toDate()}
                  setTouched={(touch) => setFieldTouched('dueDate', touch)}
                  onSelect={(val) => {
                    setFieldValue('dueDate', val);
                    setPlanValues('dueDate', val);
                  }}
                  disabled={!values.startDate}
                />
              </Grid>
            </Grid>
            <Grid row>
              <Grid column sm={12} alignItems="center">
                <Toggle
                  name="installmentFrequency"
                  placeholder={Strings.frequencySelect}
                  label={Strings.frequencySelect}
                  options={(values.startDate && values.dueDate)
                    ? getFrequencyOptions(values.startDate, values.dueDate).values
                    : Object.keys(INSTALLMENT_FREQUENCY_DISPLAY)
                      .filter((key) => key !== InstallmentFrequency.ONE_OFF_PAYMENT)}
                  optionsDisplay={(values.startDate && values.dueDate)
                    ? getFrequencyOptions(values.startDate, values.dueDate).options
                    : INSTALLMENT_FREQUENCY_DISPLAY}
                  onOptionClick={((option) => {
                    setFieldValue('installmentFrequency', option);
                    setPlanValues('installmentFrequency', option);
                  })}
                  onLoadOption={newPlanData?.installmentFrequency || InstallmentFrequency.WEEK}
                />
              </Grid>
            </Grid>
          </FormSection>
          <Grid row marginTop="1rem">
            <LineBreak />
          </Grid>
          <FormSection>
            <Grid row marginTop="1rem">
              <Grid column sm={12} lg={6} alignItems="center">
                <Dropdown
                  name="vehicle.type"
                  label={Strings.vehicleTypeDropdown}
                  placeholder={Strings.vehicleTypeDropdown}
                  options={hiddenVehicleTypeOptions}
                  onOptionClick={((option) => {
                    setFieldValue('vehicle.type', option);
                    setPlanValues('type', option);
                    setFieldValue('vehicle.vehicleMakeId', undefined);
                    setFieldValue('vehicle.make', '');
                    setPlanValues('vehicleMakeId', undefined);
                    setPlanValues('make', '');
                  })}
                  onLoadOption={values.vehicle.type || ''}
                  outlined
                />
              </Grid>
            </Grid>
            <Grid row>
              <Grid column sm={12} lg={6} alignItems="center">
                {showMakeDropdown(values as Partial<NewPlan>) ? (
                  <VehicleDropdown
                    name="vehicle.make"
                    label={Strings.vehicleMakeDropdown}
                    placeholder={Strings.vehicleMakeDropdown}
                    options={values.vehicle.type === VehicleType.CAR
                      ? carMakes
                      : motorcycleMakes}
                    onOptionClick={((option) => {
                      setFieldValue('vehicle.vehicleMakeId', option.value);
                      setPlanValues('vehicleMakeId', option.value);
                      setFieldValue('vehicle.make', option.label);
                      setPlanValues('make', option.label);
                    })}
                    onLoadOption={newPlanData?.vehicle?.vehicleMakeId || values.vehicle.vehicleMakeId || null}
                    outlined
                    disabled={!values.vehicle.type}
                  />
                ) : (
                  <Input
                    type="text"
                    name="vehicle.make"
                    width="100%"
                    label={Strings.vehicleMakeDropdown}
                    placeholder={Strings.vehicleMakeDropdown}
                    onChange={((e) => {
                      setFieldValue('vehicle.make', e.target.value.toUpperCase());
                      setPlanValues('make', e.target.value.toUpperCase());
                    })}
                    outlined
                  />
                )}
              </Grid>
              <Grid column sm={12} lg={6} alignItems="center">
                <Input
                  type="text"
                  name="vehicle.model"
                  width="100%"
                  label={Strings.vehicleModelDropdown}
                  placeholder={Strings.vehicleModelDropdown}
                  outlined
                  onChange={(e) => {
                    setFieldValue('vehicle.model', e.target.value.toUpperCase());
                    setPlanValues('model', e.target.value.toUpperCase());
                  }}
                />
              </Grid>
            </Grid>
            <Grid row>
              <Grid column sm={12} lg={6} alignItems="center">
                <Dropdown
                  name="vehicle.registeredState"
                  label={Strings.vehicleStateDropdown}
                  placeholder={Strings.vehicleStateDropdown}
                  options={stateOptions}
                  onOptionClick={((option) => {
                    setFieldValue('vehicle.registeredState', option);
                    setPlanValues('registeredState', option);
                  })}
                  onLoadOption={values.vehicle.registeredState || ''}
                  outlined
                />
              </Grid>
              <Grid column sm={12} lg={6} alignItems="center">
                <DatePicker
                  touched={touched.vehicle?.year}
                  error={errors.vehicle?.year}
                  label={Strings.vehicleYearInput}
                  onLoadDate={newPlanData?.vehicle?.year}
                  maxDate={new Date()}
                  outlined
                  yearOnly
                  onSelect={((val) => {
                    setFieldValue('vehicle.year', val);
                    setPlanValues('year', val);
                  })}
                />
              </Grid>
            </Grid>
            <Grid row>
              <Grid column sm={12} lg={6} alignItems="center">
                <Input
                  type="text"
                  name="vehicle.color"
                  label={Strings.vehicleColourInput}
                  placeholder={Strings.vehicleColourInput}
                  width="100%"
                  outlined
                  onChange={(e) => {
                    setFieldValue('vehicle.color', e.target.value.toUpperCase());
                    setPlanValues('color', e.target.value.toUpperCase());
                  }}
                />
              </Grid>
              <Grid column sm={12} lg={6} alignItems="center">
                <Input
                  type="text"
                  name="name"
                  label={Strings.nameInput}
                  placeholder={Strings.namePlaceholder}
                  optional
                  width="100%"
                  outlined
                  onChange={(e) => {
                    setFieldValue('name', e.target.value);
                    setPlanValues('name', e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection>
            <Grid row marginTop="1rem">
              <Grid column sm={12} md={4} lg={6} justify="center">
                <ConcessionHeading>{Strings.concessionTitle}</ConcessionHeading>
                <ConcessionText>{Strings.concessionText}</ConcessionText>
              </Grid>
              <Grid column sm={12} md={8} lg={6} alignItems="center">
                <Toggle
                  name="concessionCard"
                  placeholder={Strings.concessionTitle}
                  options={Object.values(Concession)}
                  optionsDisplay={CONCESSION_DISPLAY}
                  onOptionClick={((option) => {
                    setFieldValue('concessionCard', option);
                    setPlanValues('concessionCard', option);
                  })}
                  onLoadOption={Concession[values.concessionCard]}
                />
              </Grid>
            </Grid>
          </FormSection>
          <Grid row marginTop="1rem">
            <LineBreak />
          </Grid>
          <ButtonWrapper>
            <Button
              margin="0"
              width="160px"
              variant="outlined"
              onClick={onBack}
              disabled={isSubmitting}
              expandedMobile={false}
            >
              {GenericText.back}
            </Button>
            <Button
              margin="0"
              width="160px"
              onClick={handleSubmit}
              disabled={isSubmitting}
              $loading={isSubmitting}
              expandedMobile={false}
            >
              {GenericText.next}
            </Button>
          </ButtonWrapper>
        </>
      )}
    </Formik>
  );
};

export default PlanDetailsForm;
