import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AuthRouteProps } from './types';

const AuthRoute: React.FC<AuthRouteProps> = ({
  path,
  permissions,
  role,
  requiresOneOfRole,
  requiredPermission,
  children,
}) => {
  // If Permissions & Required Permissions are set
  // Check if required exists in users permissions and the role matches requiredRole
  // if either checks are true e.g User Doesn't have required permissions or required role.
  // then redirect to /
  if (permissions && requiredPermission) {
    if (!permissions.includes(requiredPermission) || !requiresOneOfRole.includes(role)) {
      return <Redirect to={{ pathname: '/' }} />;
    }
  }

  // Fallback Required Role Check if no Permissions are set
  if (!requiresOneOfRole.includes(role)) {
    return <Redirect to={{ pathname: '/' }} />;
  }
  return <Route path={path} render={() => children} />;
};
export default AuthRoute;
