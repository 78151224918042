import * as yup from 'yup';

const authSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Invalid Email Address')
    .required('Email is required'),
  password: yup
    .string()
    .trim()
    .required('Password is required'),
}).required();

export type AuthSchema = yup.InferType<typeof authSchema>;

export default authSchema;
