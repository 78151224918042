import { CustomerPlansListView } from '../../../../types/DBViews';

import {
  SetCustomerPlanDataAction,
  AddCustomerPlanAction,
  SetNewPlanDataAction,
  ResetNewPlanDataAction,
  SET_CUSTOMER_PLANS,
  ADD_CUSTOMER_PLAN,
  SET_NEW_PLAN_DATA,
  RESET_NEW_PLAN_DATA,
  NewPlan,
} from './types';

export const setCustomerPlanData = (
  plans: CustomerPlansListView[],
): SetCustomerPlanDataAction => ({
  type: SET_CUSTOMER_PLANS,
  plans,
});

export const addCustomerPlan = (
  plan: CustomerPlansListView,
): AddCustomerPlanAction => ({
  type: ADD_CUSTOMER_PLAN,
  plan,
});

export const setNewPlanData = (
  newPlanData: Partial<NewPlan>,
): SetNewPlanDataAction => ({
  type: SET_NEW_PLAN_DATA,
  newPlanData,
});

export const resetNewPlanData = (): ResetNewPlanDataAction => ({
  type: RESET_NEW_PLAN_DATA,
  newPlanData: null,
});
