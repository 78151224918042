import React, { useState, useEffect } from 'react';

import Tooltip from '../../../../../components/Common/Tooltip';

import { closeWhite, DocPreviewIcon } from '../../../../../assets/Icons';
import { CloseIcon, FileTabWrapper, Icon, FileTabText } from './Styles';

import { FileTabProps } from './types';

const FileTab: React.FC<FileTabProps> = (
  { file, handleRemoveAttachment, toolTipsOpen, handleToolTipClose },
) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(toolTipsOpen);
  }, [toolTipsOpen]);

  return (
    <FileTabWrapper onClick={() => window.open(file.url || '', '_blank')}>
      <Tooltip
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
          handleToolTipClose('');
        }}
        onConfirm={() => { handleRemoveAttachment(file.url || ''); }}
      />
      <CloseIcon
        src={closeWhite}
        onClick={(e) => {
          handleToolTipClose(file.url || '');
          e.stopPropagation();
          setIsOpen(true);
        }}
      />
      <Icon src={DocPreviewIcon} />
      <FileTabText>{file.fileName}</FileTabText>
    </FileTabWrapper>
  );
};
export default FileTab;