import styled from 'styled-components/macro';
import { ModalStyleProps } from './types';

export const ModalBackground = styled.div<ModalStyleProps>`
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const ModalWrapper = styled.div<ModalStyleProps>`
  background-color: ${({ theme }) => theme.basePalette.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: default;
  border-radius: 24px;
  padding: ${({ theme }) => `${theme.spacing.XS}rem ${theme.spacing.S}rem`};
  width: 480px;

  ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => `${theme.spacing.S}rem ${theme.spacing.S}rem`};
  };
`;

export const ModalTitle = styled.h4`
  margin: 
  ${({ theme }) => theme.spacing.XXS}rem
  0
  ${({ theme }) => theme.spacing.XXS}rem
  0;

  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-size: ${({ theme }) => theme.fontScale.M as number * 1.2}rem;
`;

export const ModalSubtext = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  text-align: center;
  color: ${({ theme }) => theme.basePalette.greyDark};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  width: 90%;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: ${({ theme }) => `${theme.spacing.XS}rem`};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
