import { Credit, Transfers } from '../../../../types/DBModels';

export const SET_SELECTED_PLAN_TRANSFERS = 'SET_SELECTED_PLAN_TRANSFERS';

export interface SelectedPlanTransfersState {
  selectedPlanTransfers: (Credit | Transfers)[];
}

export interface SetSelectedPlanTransfersAction {
  type: typeof SET_SELECTED_PLAN_TRANSFERS;
  planTransfers: (Credit | Transfers)[];
}

export type SelectedPlanTransfersActionTypes =
SetSelectedPlanTransfersAction;
