import * as yup from 'yup';

const customerMessageSchema = yup.object({
  subject: yup
    .string()
    .trim()
    .required('Subject is required'),
  message: yup
    .string()
    .required('Message is required'),
}).required();

export type CustomerMessageSchema = yup.InferType<typeof customerMessageSchema>;

export default customerMessageSchema;
