import styled, { css, DefaultTheme } from 'styled-components/macro';

import { StyledDropdownProps, LabelProps } from './types';

const getBorderColour = (theme: DefaultTheme, error?: boolean, disabled?: boolean, outlined?: boolean) => {
  if (disabled) return theme.palette.btnGrey;
  if (error) return theme.palette.btnError;
  if (outlined) return theme.basePalette.greyMain;
  return theme.palette.btnMidGrey;
};

export const StyledDropdown = styled.div<StyledDropdownProps>`
  width: 100%;
  margin: ${({ margin }) => (margin || '0.75rem 0')};
  position: relative;
  & ~ & {
    margin: 5px 0px;
  }
  font-family: ${({ theme }) => theme.fontFamily.body};

  // react-select style overrides
  .react-select__control {
    border: 1px solid ${({ error, disabled, theme, outlined }) => getBorderColour(theme, error, disabled, outlined)};
    border-radius: ${({ outlined = false }) => (outlined ? '12px' : '8px')};
    cursor: text;
    height: 60px;
    margin: ${({ theme }) => theme.spacing.XXS / 2}rem 0 0 0;
    padding-left: 0.4rem;
    margin: ${({ theme }) => theme.spacing.XXS / 2}rem 0 0 0;

    &:hover {
      ${({ disabled, theme, outlined }) => !disabled && css`
        border: 1px solid ${theme.palette.borderBlue}};
        background-color: (${outlined ? 'theme.palette.bgPrimary' : 'theme.palette.btnMidGrey'})
      `};
    }
  }

  .react-select__control--menu-is-open {
    .react-select__indicators {
      transform: scaleY(-1);
    }
  }

  .react-select__placeholder {
    font-family: ${({ theme }) => theme.fontFamily.bodyLight};
    color: ${({ theme }) => theme.palette.grey30};
  }

  .react-select__indicators {
    cursor: pointer;
  }

  .react-select__indicator-separator {
    display: none;
  }
`;

export const MetaWrapper = styled.div`
  display: flex;
`;

export const Label = styled.p<LabelProps>`
  color: ${({ theme, uppercaseLabel }) => (uppercaseLabel ? theme.palette.label : theme.basePalette.navyMain)};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  margin-bottom: 5px;
  margin-right: auto;
  text-transform: ${({ uppercaseLabel }) => (uppercaseLabel ? 'uppercase' : 'none')};
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.palette.btnError};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-style: italic;
  margin-bottom: 5px;
  margin-left: auto;
  margin-top: 5px;
`;

export const IconWrapper = styled.div`
  padding: 0 0.9rem;
`;

export const Icon = styled.img`
  width: 12px;
`;
