/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useStrings from '../../../hooks/useStrings';
import { getVehicleMakesAdmin } from '../../../lib/api/admin/vehicle';

import Grid from '../Grid';
import EditPlanModalForm from './EditPlanModalForm';

import { EditPlanModalProps } from './types';

import { ApplicationState } from '../../../lib/store';
import { APIThunkDispatch } from '../../../lib/types/API';

import { getPlanTransferRecords, getSelectedCustomerPlan } from '../../../lib/api/admin/plans';
import { PlanDataView } from '../../../lib/types/DBViews';
import { Spinner } from '../../UI/LoadingScreen/Styles';
import { ModalTitle } from '../CreatePlanModal/Styles';
import { ModalBackground, ModalWrapper } from '../CompletePlanModal/Styles';

const EditPlanModal: React.FC<EditPlanModalProps> = ({
  planData,
  onCancel,
  onConfirm,
  onViewPayments,
}) => {
  const dispatch: APIThunkDispatch = useDispatch();
  const [{ Components: { Common: { PlanModal: Strings } } }] = useStrings();
  const stopClickBubbling = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  const [plan, setPlan] = useState<PlanDataView>();
  const [fetching, setFetching] = useState({
    vehicleMakes: true,
    selectedPlanTransfers: true,
    plan: true,
  });

  const { vehicleMakes, selectedPlanTransfers } = useSelector((state: ApplicationState) => ({
    vehicleMakes: state.vehicleMakesState.vehicleMakes,
    selectedPlanTransfers: state.admin.selectedPlanTransfersState.selectedPlanTransfers,
  }));

  const fetchVehicleMakes = useCallback(() => {
    dispatch(getVehicleMakesAdmin()).then(() => {
      setFetching((state) => ({
        ...state,
        vehicleMakes: false,
      }));
    }).catch(() => {});
  }, [dispatch]);

  const fetchPlanTransfers = useCallback((planId: number) => {
    dispatch(getPlanTransferRecords(planId)).then(() => {
      setFetching((state) => ({
        ...state,
        selectedPlanTransfers: false,
      }));
    }).catch(() => {});
  }, [dispatch]);

  const fetchPlan = useCallback(async (customerId: number, planId: number) => {
    const { data } = await dispatch(getSelectedCustomerPlan(customerId, planId));
    if (data) {
      setPlan(data);
      setFetching((state) => ({
        ...state,
        plan: false,
      }));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchVehicleMakes();
  }, [fetchVehicleMakes]);

  useEffect(() => {
    if (planData) {
      fetchPlan(planData.customerId, planData.planId).catch(() => {});
    }
  }, [fetchPlan, planData]);

  useEffect(() => {
    if (planData?.planId) fetchPlanTransfers(planData?.planId);
  }, [fetchPlanTransfers, planData?.planId]);

  if (!plan || fetching.plan || fetching.selectedPlanTransfers || fetching.vehicleMakes) {
    return (
      <ModalBackground onClick={onCancel}>
        <ModalWrapper onClick={stopClickBubbling}>
          <Grid row marginTop="3rem" maxWidth="100%">
            <Grid column sm={12} alignItems="center">
              <ModalTitle>
                { Strings.editPlanTitle }
              </ModalTitle>
            </Grid>
          </Grid>
          <Grid row marginTop="2rem" maxWidth="100%">
            <Grid column sm={12} alignItems="center">
              <Spinner />
            </Grid>
          </Grid>
        </ModalWrapper>
      </ModalBackground>
    );
  }

  return (
    <EditPlanModalForm
      plan={plan}
      vehicleMakes={vehicleMakes}
      selectedPlanTransfers={selectedPlanTransfers}
      onConfirm={onConfirm}
      onCancel={onCancel}
      onViewPayments={onViewPayments}
    />
  );
};

export default EditPlanModal;
