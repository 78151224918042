import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import useStrings from '../../../hooks/useStrings';
import { sendReferral } from '../../../lib/api/customer/referral';

import { Grid as LayoutGrid } from '../../../layouts/MasterLayout';

import Grid from '../../../components/Common/Grid';
import NavHeader from '../../../components/UI/NavHeader';
import Input from '../../../components/Common/Input';
import Button from '../../../components/Common/Button';
import TextArea from '../../../components/Common/TextArea';

import { ReferFriendIcon } from '../../../assets/Icons';
import {
  ReferralIcon,
  TitleWrapper,
  ConfirmationWrapper,
  Checkmark,
  CheckmarkStem,
  CheckmarkKick,
  SendAnotherText,
} from './Styles';
import { Container, ContentWrapper } from '../Dashboard/Styles';
import { Heading, Subtext } from '../Dashboard/UserInfo/Styles';

import { ReferFriendProps } from './types';
import { APIThunkDispatch } from '../../../lib/types/API';
import referFriendSchema from '../../../lib/validators/referFriendSchema';

const ReferFriend: React.FC<ReferFriendProps> = () => {
  const [{ GenericText, Pages: { Customer: { ReferFriend: Strings } } }] = useStrings();
  const dispatch: APIThunkDispatch = useDispatch();

  const [sendSuccess, setSendSuccess] = useState(false);

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        message: Strings.messageInputDefault,
      }}
      validationSchema={referFriendSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const { success } = await dispatch(sendReferral(values));
        if (success) setSendSuccess(true);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({
        values,
        errors,
        isSubmitting,
        setFieldValue,
        setFieldError,
        handleSubmit,
        resetForm,
      }) => (
        <Container>
          <NavHeader />
          <LayoutGrid fullWidth={false} maxWidth autoHeight>
            <ContentWrapper>
              <Grid row>
                <Grid column sm={12}>
                  <TitleWrapper>
                    <ReferralIcon src={ReferFriendIcon} />
                    <Heading>{Strings.pageTitle}</Heading>
                    <Heading highlight>&nbsp;{Strings.pageTitleHighlight}</Heading>
                  </TitleWrapper>
                </Grid>
              </Grid>
              <Grid row marginTop="3rem">
                <Grid column sm={12} md={6}>
                  <Grid row maxWidth="100%">
                    <Grid column sm={12}>
                      <TitleWrapper>
                        <Heading large>{Strings.pageSubtitle}</Heading>
                      </TitleWrapper>
                    </Grid>
                  </Grid>
                  <Grid row maxWidth="80%" marginTop="1.5rem" expandedMobile>
                    <Grid column sm={12}><Subtext>{Strings.bodyPart}</Subtext></Grid>
                  </Grid>
                </Grid>
                <Grid column sm={12} md={6}>
                  { sendSuccess ? (
                    <>
                      <Grid row maxWidth="100%" marginTop="3rem">
                        <ConfirmationWrapper>
                          <Checkmark>
                            <CheckmarkStem />
                            <CheckmarkKick />
                          </Checkmark>
                          <Heading highlight>{GenericText.thankYou}</Heading>
                        </ConfirmationWrapper>
                      </Grid>
                      <Grid row maxWidth="80%" marginTop="0.5rem">
                        <Grid column sm={12}><Subtext>{Strings.confirmationMessage}</Subtext></Grid>
                      </Grid>
                      <Grid row maxWidth="80%" marginTop="2.5rem" expandedMobile>
                        <Grid column sm={12}>
                          <SendAnotherText onClick={() => {
                            resetForm();
                            setSendSuccess(false);
                          }}
                          >
                            {Strings.sendAnother}
                          </SendAnotherText>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid row maxWidth="100%">
                        <Grid column sm={12}>
                          <Input
                            name="firstName"
                            type="text"
                            placeholder={Strings.firstNameInputPlaceHolder}
                            label={Strings.firstNameInputLabel}
                            outlined
                            width="100%"
                          />
                        </Grid>
                      </Grid>
                      <Grid row maxWidth="100%">
                        <Grid column sm={12}>
                          <Input
                            name="lastName"
                            type="text"
                            placeholder={Strings.lastNameInputPlaceHolder}
                            label={Strings.lastNameInputLabel}
                            outlined
                            width="100%"
                          />
                        </Grid>
                      </Grid>
                      <Grid row maxWidth="100%">
                        <Grid column sm={12}>
                          <Input
                            name="email"
                            type="text"
                            placeholder={Strings.emailInputPlaceHolder}
                            label={Strings.emailInputLabel}
                            outlined
                            width="100%"
                          />
                        </Grid>
                      </Grid>
                      <Grid row maxWidth="100%" marginTop="1rem">
                        <Grid column sm={12}>
                          <TextArea
                            name="message"
                            label={Strings.messageInputLabel}
                            rows={8}
                            placeHolder={Strings.messageInputPlaceholder}
                            handleChange={(val) => {
                              setFieldValue('message', val);
                              setFieldError('message', '');
                            }}
                            value={values.message}
                            error={errors.message}
                          />
                        </Grid>
                      </Grid>
                      <Grid row maxWidth="100%" marginTop="0.5rem">
                        <Grid column sm={12}>
                          <Button
                            onClick={handleSubmit}
                            variant="primary"
                            disabled={isSubmitting}
                          >
                            {Strings.submitInputButton}
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </ContentWrapper>
          </LayoutGrid>
        </Container>
      )}
    </Formik>
  );
};

export default ReferFriend;
