import { CustomerPlansListView } from '../../../../../types/DBViews';

import {
  SetAllPlansAction,
  SetUpdatedPlansAction,
  CreatePlan,
  RemovePlanAction,
  ClearSelectedCustomerPlansAction,
  SET_ALL_SELECTED_CUSTOMER_PLANS,
  CLEAR_SELECTED_CUSTOMER_PLANS,
  SET_UPDATED_PLAN_DATA,
  CREATE_PLAN,
  REMOVE_PLAN,
} from './types';

export const setAllPlanData = (
  planData: {
    results: CustomerPlansListView[];
    count: number;
  },
): SetAllPlansAction => ({
  type: SET_ALL_SELECTED_CUSTOMER_PLANS,
  planData,
});

export const clearSelectedCustomerPlans = (): ClearSelectedCustomerPlansAction => ({
  type: CLEAR_SELECTED_CUSTOMER_PLANS,
});

export const setUpdatedPlanData = (
  plans: CustomerPlansListView[],
): SetUpdatedPlansAction => ({
  type: SET_UPDATED_PLAN_DATA,
  plans,
});

export const createPlan = (
  plan: CustomerPlansListView,
): CreatePlan => ({
  type: CREATE_PLAN,
  plan,
});

export const removePlan = (
  planIds: number[],
): RemovePlanAction => ({
  type: REMOVE_PLAN,
  planIds,
});
