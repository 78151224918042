/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { apiFetch, apiRequest, objectToQueryString, stripDate } from '../Utils';
import { setCustomerData, setSelectedCustomer, setUpdatedCustomerData } from '../../store/contexts/admin/customers/actions';
import { setNotification } from '../../store/contexts/notification/actions';
import { setTotalCreditsData } from '../../store/contexts/admin/selectedCustomer/totalCredits/actions';
import { DEFAULT_FILTER, serverAdminAPI } from '../../../CONSTANTS';

import { APIThunkResult, CustomerFilterOptions, OrderDirection } from '../../types/API';
import { FilteredCustomersDTO, CustomerDTO, CustomerUpdateDTO, TotalCreditsDTO, CreditHistoryDTO, DeleteCustomerDTO } from '../../types/DTO';
import { UpdateCustomerProfileSchema } from '../../validators/updateCustomerProfileSchema';
import { CustomerStatus, PaymentStatus } from '../../types/Customer';
import { setCreditHistoryData } from '../../store/contexts/admin/selectedCustomer/creditHistory/actions';
import { deleteMedia } from '../../Media';

export const defaultFilter = {
  page: DEFAULT_FILTER.page,
  rowsPerPage: DEFAULT_FILTER.rowsPerPage,
  orderColumn: 'createdAt',
  orderDirection: OrderDirection.DESC,
};

export const getCustomers = (
  filter: CustomerFilterOptions,
): APIThunkResult<FilteredCustomersDTO> => (
  apiRequest<FilteredCustomersDTO>(async (dispatch) => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverAdminAPI}/customers?${query}`;

    const { data, error } = await apiFetch<FilteredCustomersDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setCustomerData(data.customers));

    return data;
  }, true)
);

export const getCustomer = (userId: string): APIThunkResult<CustomerDTO> => (
  apiRequest<CustomerDTO>(async (dispatch) => {
    const { data, error } = await apiFetch<CustomerDTO>({
      method: 'GET',
      url: `${serverAdminAPI}/customers/${userId}`,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setSelectedCustomer(data.customer));
    return data;
  }, true)
);

export const updateProfile = (
  input: UpdateCustomerProfileSchema,
): APIThunkResult<CustomerDTO> => apiRequest<CustomerDTO>(
  async (dispatch, getState) => {
    const userId = getState().admin.customerState.selectedCustomer?.userId;

    if (!userId) throw new Error('No customer selected');

    const { data, error } = await apiFetch<CustomerDTO>({
      method: 'POST',
      url: `${serverAdminAPI}/customers/updateProfile`,
      body: {
        userId,
        ...input,
        dob: stripDate(input.dob),
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setSelectedCustomer(data.customer));
    dispatch(setNotification({ message: 'Profile Updated', variant: 'success', duration: 3 }));

    return data;
  },
  true,
);

export const updateStatus = (
  userId: string,
  status?: CustomerStatus,
  paymentStatus?: PaymentStatus,
): APIThunkResult<CustomerUpdateDTO> => apiRequest<CustomerUpdateDTO>(
  async (dispatch) => {
    const { data, error } = await apiFetch<CustomerUpdateDTO>({
      method: 'POST',
      url: `${serverAdminAPI}/customers/updateStatus`,
      body: {
        userId,
        status,
        paymentStatus,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setUpdatedCustomerData([data.customer]));
    await dispatch(getCustomer(userId));
    dispatch(setNotification({ message: 'Status Updated', variant: 'success', duration: 3 }));
    return data;
  },
  true,
);

export const deleteCustomer = (
  userId: string,
): APIThunkResult<DeleteCustomerDTO> => apiRequest<DeleteCustomerDTO>(
  async (dispatch) => {
    const { data, error } = await apiFetch<DeleteCustomerDTO>({
      method: 'DELETE',
      url: `${serverAdminAPI}/customers/delete`,
      body: {
        userId,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    const { mediaUrls } = data;
    if (mediaUrls?.length) {
      await Promise.all(mediaUrls.map((u) => u && deleteMedia(u)));
    }

    dispatch(setNotification({ message: 'User deleted', variant: 'success', duration: 3 }));
    return data;
  },
  true,
);

export const getCustomerCredits = (
  userId: string,
): APIThunkResult<TotalCreditsDTO> => apiRequest<TotalCreditsDTO>(
  async (dispatch) => {
    const { data, error } = await apiFetch<TotalCreditsDTO>({
      method: 'GET',
      url: `${serverAdminAPI}/customers/${userId}/totalCredit`,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setTotalCreditsData(data.totalCredit));

    return data;
  },
  true,
);

export const getCustomerCreditHistory = (
  userId: string,
): APIThunkResult<CreditHistoryDTO> => apiRequest<CreditHistoryDTO>(
  async (dispatch) => {
    const { data, error } = await apiFetch<CreditHistoryDTO>({
      method: 'GET',
      url: `${serverAdminAPI}/customers/${userId}/creditHistory`,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setCreditHistoryData(data.credits));

    return data;
  },
  true,
);
export const updateCustomerCredits = (
  userId: string,
  creditAmount: number,
  createdByUserId: string,
): APIThunkResult<TotalCreditsDTO> => apiRequest<TotalCreditsDTO>(
  async (dispatch) => {
    const { data, error } = await apiFetch<TotalCreditsDTO>({
      method: 'POST',
      url: `${serverAdminAPI}/customers/updateTotalCredit`,
      body: {
        userId,
        amount: creditAmount,
        createdByUserId,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setTotalCreditsData(data.totalCredit));
    dispatch(setNotification({ message: 'Credits Updated', variant: 'success', duration: 3 }));

    return data;
  },
  true,
);