import { PlanDataView } from '../../../../types/DBViews';

import {
  SetAllPlansAction,
  SetUpdatedPlansAction,
  SET_ALL_PLAN_DATA,
  SET_UPDATED_PLAN_DATA,
} from './types';

export const setAllPlanData = (
  planData: {
    results: PlanDataView[];
    count: number;
  },
): SetAllPlansAction => ({
  type: SET_ALL_PLAN_DATA,
  planData,
});

export const setUpdatedPlanData = (
  plans: PlanDataView[],
): SetUpdatedPlansAction => ({
  type: SET_UPDATED_PLAN_DATA,
  plans,
});
