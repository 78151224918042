import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import useStrings from '../../../hooks/useStrings';
import { createPlan } from '../../../lib/api/customer/plans';
import { getRedirectParameters } from '../../../lib/api/customer/paymentService';
import {
  INSTALLMENT_FREQUENCY_DISPLAY,
  INSTALLMENT_FREQUENCY_ABBR,
  REGISTRATION_LENGTH_DISPLAY,
  PLAN_TYPE_DISPLAY,
  FEES,
  CONCESSION_DISPLAY,
} from '../../../CONSTANTS';

import Grid from '../../Common/Grid';
import Button from '../../Common/Button';
import CustomerConfirmModal from '../../Common/CustomerConfirmModal';

import {
  FormSection,
  LineBreak,
  PlanValueTile,
  PlanValueText,
  ButtonWrapper,
  InfoBar,
  InfoBarText,
  InfoBarPrice,
  InfoBarFrequency,
  AdminFee,
} from './Styles';

import { ReviewPlanProps } from './types';
import { APIThunkDispatch } from '../../../lib/types/API';
import { calculateCustomerPlanPaymentData } from '../../../lib/utils';
import { Concession, PlanType } from '../../../lib/types/Plan';
import { Plan } from '../../../lib/types/DBModels';
import { resetNewPlanData, addCustomerPlan } from '../../../lib/store/contexts/customer/plans/actions';
import { updateCustomerStatus } from '../../../lib/store/contexts/customer/profile/actions';
import { CustomerStatus, PaymentStatus } from '../../../lib/types/Customer';
import { ApplicationState } from '../../../lib/store';

dayjs.extend(advancedFormat);

const ReviewPlan: React.FC<ReviewPlanProps> = ({ newPlanData, onBack, resetForm }) => {
  const dispatch: APIThunkDispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [{ Components: { UI: { ReviewPlan: Strings } }, GenericText }] = useStrings();
  const { profileState: { profileData } } = useSelector((state: ApplicationState) => state.customer);

  const installmentFrequencyDisplay = newPlanData?.installmentFrequency
    && INSTALLMENT_FREQUENCY_DISPLAY[newPlanData?.installmentFrequency];
  const adminFee = installmentFrequencyDisplay
    && newPlanData?.installmentFrequency && FEES[installmentFrequencyDisplay.toLowerCase()];

  const paymentData = calculateCustomerPlanPaymentData({
    startDate: newPlanData?.startDate,
    dueDate: newPlanData?.dueDate,
    installmentFrequency: newPlanData?.installmentFrequency,
    totalPlanValue: newPlanData?.value,
  });

  const handleSubmit = async () => {
    // If data contains vehicleMakeId, remove make prop before submitting
    const sanitisedData = JSON.parse(JSON.stringify({ ...newPlanData, endDate: paymentData?.lastPaymentDate }));
    if (newPlanData?.vehicle?.vehicleMakeId) {
      delete sanitisedData?.vehicle?.make;
    }

    setIsSubmitting(true);
    await dispatch(createPlan(sanitisedData as unknown as Plan)).then(({ success, data }) => {
      setIsSubmitting(false);
      if (success && data) {
        setModalOpen(true);
        dispatch(addCustomerPlan(data.plan));
      }
    });
  };

  const handleCloseModal = (createAnother: boolean) => {
    if (profileData?.status === CustomerStatus.NO_PLANS) {
      dispatch(updateCustomerStatus(CustomerStatus.INACTIVE));
    }
    dispatch(resetNewPlanData());
    resetForm();
    setModalOpen(false);
    history.push(createAnother ? '/customer/create-plan' : '/customer/dashboard');
  };

  const handleConfirm = async () => {
    if (profileData?.paymentStatus === PaymentStatus.ACTIVE) handleCloseModal(false);
    else {
      setIsSubmitting(true);
      const { data } = await dispatch(getRedirectParameters());
      if (data) window.location.href = data?.redirectToUrl;
    }
  };

  const planType = newPlanData?.type || '';

  const modalSubtextHighlight = newPlanData?.type === 'VEHICLE_REGISTRATION'
    ? `${newPlanData?.vehicle?.make} ${newPlanData?.vehicle?.model} (${newPlanData?.vehicle?.registration})`
    : newPlanData?.name || PLAN_TYPE_DISPLAY[planType];

  // If plan name is defined, adjust colwidth to fit extra column
  const topRowColwidth = newPlanData?.name ? 3 : 4;

  return (
    <>
      <CustomerConfirmModal
        title={Strings.successTitle}
        titleHighlight={Strings.successTitleHighlight}
        subtext={newPlanData?.type === 'VEHICLE_REGISTRATION'
          ? Strings.successSubtitle
          : Strings.successSubtitleFund}
        subtextHighlight={modalSubtextHighlight}
        bodyText={Strings.successBodyText}
        cancelButtonText={Strings.modalCancelButton}
        confirmButtonText={
          profileData?.paymentStatus !== PaymentStatus.ACTIVE
            ? Strings.modalConfirmButton
            : GenericText.complete
        }
        isOpen={modalOpen}
        isSubmitting={isSubmitting}
        onCancel={() => handleCloseModal(true)}
        onConfirm={handleConfirm}
      />
      {newPlanData?.type === PlanType.VEHICLE_REGISTRATION ? (
        <>
          <FormSection>
            <Grid row>
              {newPlanData?.name && (
                <Grid column sm={12} md={3} alignItems="center">
                  <PlanValueTile>
                    <PlanValueText>{Strings.name}</PlanValueText>
                    <span>{newPlanData?.name}</span>
                  </PlanValueTile>
                </Grid>
              )}
              <Grid column sm={12} md={topRowColwidth} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.planType}</PlanValueText>
                  {newPlanData?.type && (
                    <span>{PLAN_TYPE_DISPLAY[newPlanData?.type]}</span>
                  )}
                </PlanValueTile>
              </Grid>
              <Grid column sm={12} md={topRowColwidth} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.amount}</PlanValueText>
                  <span>{`$${newPlanData?.value}`}</span>
                </PlanValueTile>
              </Grid>
              <Grid column sm={12} md={topRowColwidth} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.renewalPeriod}</PlanValueText>
                  {newPlanData?.registrationLength && (
                    <span>{REGISTRATION_LENGTH_DISPLAY[newPlanData?.registrationLength]}</span>
                  )}
                </PlanValueTile>
              </Grid>
            </Grid>
            <Grid row>
              <Grid column sm={12} md={3} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.starts}</PlanValueText>
                  <span>{dayjs(newPlanData?.startDate).format('Do MMMM YYYY')}</span>
                </PlanValueTile>
              </Grid>
              <Grid column sm={12} md={3} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.ends}</PlanValueText>
                  <span>{dayjs(paymentData?.lastPaymentDate).format('Do MMMM YYYY')}</span>
                </PlanValueTile>
              </Grid>
              <Grid column sm={12} md={3} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.due}</PlanValueText>
                  <span>{dayjs(newPlanData?.dueDate).format('Do MMMM YYYY')}</span>
                </PlanValueTile>
              </Grid>
              <Grid column sm={12} md={3} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.frequency}</PlanValueText>
                  {newPlanData?.installmentFrequency && (
                    <span>{INSTALLMENT_FREQUENCY_DISPLAY[newPlanData?.installmentFrequency]}</span>
                  )}
                </PlanValueTile>
              </Grid>
            </Grid>
          </FormSection>
          <Grid row>
            <LineBreak />
          </Grid>
          <FormSection>
            <Grid row>
              <Grid column sm={12} md={3} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.vehicleType}</PlanValueText>
                  <span>{newPlanData?.vehicle?.type}</span>
                </PlanValueTile>
              </Grid>
              <Grid column sm={12} md={3} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.vehicleYear}</PlanValueText>
                  <span>{dayjs(newPlanData?.vehicle?.year).format('YYYY')}</span>
                </PlanValueTile>
              </Grid>
              <Grid column sm={12} md={3} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.vehicleMake}</PlanValueText>
                  <span>{newPlanData?.vehicle?.make}</span>
                </PlanValueTile>
              </Grid>
              <Grid column sm={12} md={3} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.vehicleModel}</PlanValueText>
                  <span>{newPlanData?.vehicle?.model}</span>
                </PlanValueTile>
              </Grid>
            </Grid>
            <Grid row>
              <Grid column sm={12} md={3} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.vehicleColour}</PlanValueText>
                  <span>{newPlanData?.vehicle?.color}</span>
                </PlanValueTile>
              </Grid>
              <Grid column sm={12} md={3} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.registration}</PlanValueText>
                  <span>{newPlanData?.vehicle?.registration}</span>
                </PlanValueTile>
              </Grid>
              <Grid column sm={12} md={3} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.registeredState}</PlanValueText>
                  <span>{newPlanData?.vehicle?.registeredState}</span>
                </PlanValueTile>
              </Grid>
              <Grid column sm={12} md={3} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.concessionCard}</PlanValueText>
                  <span>
                    {newPlanData?.concessionCard
                      ? CONCESSION_DISPLAY[newPlanData.concessionCard] : CONCESSION_DISPLAY[Concession.NONE]}
                  </span>
                </PlanValueTile>
              </Grid>
            </Grid>
            <Grid row>
              <Grid expandedMobile column sm={12} alignItems="center">
                <InfoBar>
                  <div>
                    <InfoBarText>{Strings.startDateText1}</InfoBarText>
                    &nbsp;
                    <InfoBarText highlight>{dayjs(newPlanData?.startDate).format('Do MMMM')}</InfoBarText>
                    &nbsp;
                    <InfoBarText>{Strings.startDateText2}</InfoBarText>
                    &nbsp;
                    <InfoBarText highlight>{paymentData?.numberOfPayments}</InfoBarText>
                    &nbsp;
                    <InfoBarText>{Strings.startDateText3}</InfoBarText>
                    {adminFee && <AdminFee>{Strings.adminFee.replace('%', adminFee.toString())}</AdminFee>}
                  </div>
                  <div>
                    <InfoBarPrice>{`$${paymentData?.installmentAmount.toFixed(2)}`}</InfoBarPrice>
                    &nbsp;
                    {newPlanData?.installmentFrequency && (
                      <InfoBarFrequency>
                        {INSTALLMENT_FREQUENCY_ABBR[newPlanData?.installmentFrequency]}
                      </InfoBarFrequency>
                    )}
                  </div>
                </InfoBar>
              </Grid>
            </Grid>
          </FormSection>
        </>
      ) : (
        <>
          <FormSection>
            <Grid row>
              {newPlanData?.name && (
                <Grid column sm={12} md={3} alignItems="center">
                  <PlanValueTile>
                    <PlanValueText>{Strings.name}</PlanValueText>
                    <span>{newPlanData?.name}</span>
                  </PlanValueTile>
                </Grid>
              )}
              <Grid column sm={12} md={topRowColwidth} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.planType}</PlanValueText>
                  {newPlanData?.type && (
                    <span>{PLAN_TYPE_DISPLAY[newPlanData?.type]}</span>
                  )}
                </PlanValueTile>
              </Grid>
              <Grid column sm={12} md={topRowColwidth} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.amount}</PlanValueText>
                  <span>{`$${newPlanData?.value}`}</span>
                </PlanValueTile>
              </Grid>
              <Grid column sm={12} md={topRowColwidth} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.frequency}</PlanValueText>
                  {installmentFrequencyDisplay && (
                    <span>{installmentFrequencyDisplay}</span>
                  )}
                </PlanValueTile>
              </Grid>
            </Grid>
            <Grid row>
              <Grid column sm={12} md={4} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.starts}</PlanValueText>
                  <span>{dayjs(newPlanData?.startDate).format('Do MMMM YYYY')}</span>
                </PlanValueTile>
              </Grid>
              <Grid column sm={12} md={4} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.ends}</PlanValueText>
                  <span>{dayjs(paymentData?.lastPaymentDate).format('Do MMMM YYYY')}</span>
                </PlanValueTile>
              </Grid>
              <Grid column sm={12} md={4} alignItems="center">
                <PlanValueTile>
                  <PlanValueText>{Strings.due}</PlanValueText>
                  <span>{dayjs(newPlanData?.dueDate).format('Do MMMM YYYY')}</span>
                </PlanValueTile>
              </Grid>
            </Grid>
          </FormSection>
          <FormSection>
            <Grid row marginTop="-2rem">
              <Grid expandedMobile column sm={12} alignItems="center">
                <InfoBar>
                  <div>
                    <InfoBarText>{Strings.startDateText1}</InfoBarText>
                    &nbsp;
                    <InfoBarText highlight>{dayjs(newPlanData?.startDate).format('Do MMMM')}</InfoBarText>
                    &nbsp;
                    <InfoBarText>{Strings.startDateText2}</InfoBarText>
                    &nbsp;
                    <InfoBarText highlight>{paymentData?.numberOfPayments}</InfoBarText>
                    &nbsp;
                    <InfoBarText>{Strings.startDateText3}</InfoBarText>
                    {adminFee && <AdminFee>{Strings.adminFee.replace('%', adminFee.toString())}</AdminFee>}
                  </div>
                  <div>
                    <InfoBarPrice>{`$${paymentData?.installmentAmount.toFixed(2)}`}</InfoBarPrice>
                    &nbsp;
                    {newPlanData?.installmentFrequency && (
                      <InfoBarFrequency>
                        {INSTALLMENT_FREQUENCY_ABBR[newPlanData?.installmentFrequency]}
                      </InfoBarFrequency>
                    )}
                  </div>
                </InfoBar>
              </Grid>
            </Grid>
          </FormSection>
        </>
      )}
      <Grid row>
        <LineBreak />
      </Grid>
      <ButtonWrapper>
        <Button
          margin="0"
          width="160px"
          variant="outlined"
          onClick={onBack}
          disabled={isSubmitting}
          expandedMobile={false}
        >
          {GenericText.back}
        </Button>
        <Button
          margin="0"
          width="160px"
          onClick={handleSubmit}
          disabled={isSubmitting}
          expandedMobile={false}
          $loading={isSubmitting}
        >
          {GenericText.confirm}
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default ReviewPlan;
