import styled from 'styled-components/macro';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.XXS * 2}rem;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const PageSubtitle = styled.div`
  padding-left: ${({ theme }) => theme.spacing.XXS}rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-size: ${({ theme }) => theme.fontScale.M as number / 1.15}rem;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.palette.title};
`;

export const PageName = styled.div`
  padding-left: ${({ theme }) => theme.spacing.XXS}rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-size: ${({ theme }) => theme.fontScale.XL as number / 1.12}rem;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.palette.title};
`;
