import React from 'react';

import StyledHeading from './Styles';

import { HeadingProps } from './types';

const Heading: React.FC<HeadingProps> = ({ children, defaultFont = true, highlight = false }) => (
  <StyledHeading defaultFont={defaultFont} highlight={highlight}>
    { children }
  </StyledHeading>
);

export default Heading;
