import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import CustomerLayout from '../../../layouts/CustomerLayout';
import PlanTypeForm from '../../../components/UI/PlanTypeForm';
import PlanDetailsForm from '../../../components/UI/PlanDetailsForm';
import FundDetailsForm from '../../../components/UI/FundDetailsForm';
import ReviewPlan from '../../../components/UI/ReviewPlan';
import PlanEstimate from '../../../components/UI/PlanEstimate';

import useStrings from '../../../hooks/useStrings';
import { CreatePlanProps } from './types';
import { ApplicationState } from '../../../lib/store/index';
import {
  RegistrationLength,
  InstallmentFrequency,
  PlanType,
  Concession,
} from '../../../lib/types/Plan';
import { resetNewPlanData } from '../../../lib/store/contexts/customer/plans/actions';
import { CustomerStatus } from '../../../lib/types/Customer';

const initPlanValuesState = {
  registrationLength: RegistrationLength.TWELVE_MONTHS,
  installmentFrequency: InstallmentFrequency.WEEK,
  concessionCard: Concession.NONE,
};

const CreatePlan: React.FC<CreatePlanProps> = ({ isOnboarding = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [{ Pages: { Customer: { CreatePlan: Strings } } }] = useStrings();
  const [currentStep, setCurrentStep] = useState(0);
  const {
    plansState: { newPlanData },
    profileState: { profileData },
  } = useSelector((state: ApplicationState) => state.customer);
  // Plan values set from PlanDetailsForm to pass to PlanEstimate component
  const [planValues, setPlanValues] = useState<Record<string, unknown>>(newPlanData || initPlanValuesState);

  useEffect(() => {
    // Don't reset if is not the users first plan as we need due date taken from sign up form for onboarding plan creation
    if (currentStep === 0 && profileData?.status !== CustomerStatus.NO_PLANS) {
      dispatch(resetNewPlanData());
      setPlanValues(initPlanValuesState);
    }
  }, [dispatch, currentStep, profileData?.status]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [currentStep]);

  const handleSetPlanValues = (key: string, val: unknown) => {
    if (key === 'type'
      || key === 'make'
      || key === 'vehicleMakeId'
      || key === 'model'
      || key === 'registration'
      || key === 'color'
      || key === 'year'
      || key === 'registeredState') {
      setPlanValues({ ...planValues, vehicle: { ...planValues.vehicle as Record<string, unknown>, [key]: val } });
    } else {
      setPlanValues({ ...planValues, [key]: val });
    }
  };

  const getTitleText = (): ContentHeaderText => {
    if (currentStep === 1 && newPlanData?.type === PlanType.VEHICLE_REGISTRATION) {
      return {
        pageTitle: Strings.PlanDetails.pageTitle,
        pageTitleHighlight: Strings.PlanDetails.pageTitleHighlight,
        pageSubtitle: Strings.PlanDetails.pageSubtitle,
      };
    }
    if (currentStep === 1) {
      return {
        pageTitle: Strings.FundDetails.pageTitle,
        pageTitleHighlight: Strings.FundDetails.pageTitleHighlight,
        pageSubtitle: Strings.FundDetails.pageSubtitle,
      };
    }
    if (currentStep === 2) {
      return {
        pageTitle: Strings.ReviewPlan.pageTitle,
        pageTitleHighlight: Strings.ReviewPlan.pageTitleHighlight,
        pageSubtitle: Strings.ReviewPlan.pageSubtitle,
      };
    }
    return {
      pageTitle: Strings.PlanType.pageTitle,
      pageTitleTwo: Strings.PlanType.pageTitleTwo,
      pageTitleHighlight: Strings.PlanType.pageTitleHighlight,
      pageSubtitle: Strings.PlanType.pageSubtitle,
    };
  };

  const { pageTitle, pageTitleTwo, pageTitleHighlight, pageSubtitle } = getTitleText();

  const renderCurrentForm = () => {
    if (currentStep === 1 && newPlanData?.type === PlanType.VEHICLE_REGISTRATION) {
      return (
        <PlanDetailsForm
          newPlanData={newPlanData}
          setPlanValues={(key, val) => handleSetPlanValues(key, val)}
          onBack={() => setCurrentStep(currentStep - 1)}
          onNext={() => setCurrentStep(currentStep + 1)}
        />
      );
    }
    if (currentStep === 1) {
      return (
        <FundDetailsForm
          newPlanData={newPlanData}
          setPlanValues={(key, val) => handleSetPlanValues(key, val)}
          onBack={() => setCurrentStep(currentStep - 1)}
          onNext={() => setCurrentStep(currentStep + 1)}
        />
      );
    }
    if (currentStep === 2) {
      return (
        <ReviewPlan
          newPlanData={newPlanData}
          onBack={() => setCurrentStep(currentStep - 1)}
          resetForm={() => setCurrentStep(0)}
        />
      );
    }
    return (
      <PlanTypeForm
        newPlanData={newPlanData}
        onBack={() => history.push('/sign-up/profile')}
        onNext={() => setCurrentStep(currentStep + 1)}
      />
    );
  };

  return (
    <CustomerLayout
      pageTitle={pageTitle}
      pageTitleHighlight={pageTitleHighlight}
      pageTitleTwo={pageTitleTwo}
      pageSubtitle={pageSubtitle}
      currentFormStep={currentStep}
      isOnboarding={isOnboarding}
      isEditProfile={false}
      mainContentMaxWidth={currentStep === 0 ? '960px' : undefined}
      asideContent={currentStep === 1 && (
        <PlanEstimate
          planType={newPlanData?.type}
          planValues={planValues}
        />
      )}
    >
      {renderCurrentForm()}
    </CustomerLayout>

  );
};

export interface ContentHeaderText {
  pageTitle: string;
  pageTitleTwo?: string;
  pageTitleHighlight: string;
  pageSubtitle: string;
}

export default CreatePlan;
