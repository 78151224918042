import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import useStrings from '../../../hooks/useStrings';
import { sendContactMessage } from '../../../lib/api/customer/contact';

import { Grid as LayoutGrid } from '../../../layouts/MasterLayout';

import Grid from '../../../components/Common/Grid';
import NavHeader from '../../../components/UI/NavHeader';
import Input from '../../../components/Common/Input';
import Button from '../../../components/Common/Button';
import TextArea from '../../../components/Common/TextArea';

import {
  BlueEmailIcon,
  BluePhoneIcon,
  LifeSaverIcon,
} from '../../../assets/Icons';
import {
  GetHelpIcon,
  TitleWrapper,
  ContactWrapper,
  ContactIcon,
  ContactText,
  ConfirmationWrapper,
  Checkmark,
  CheckmarkStem,
  CheckmarkKick,
} from './Styles';
import { Container, ContentWrapper } from '../Dashboard/Styles';
import { Heading, Subtext } from '../Dashboard/UserInfo/Styles';

import { ContactUsProps } from './types';
import { APIThunkDispatch } from '../../../lib/types/API';
import customerMessageSchema from '../../../lib/validators/customerMessageSchema';

const ContactUs: React.FC<ContactUsProps> = () => {
  const [{ GenericText, Pages: { Customer: { ContactUs: Strings } } }] = useStrings();
  const dispatch: APIThunkDispatch = useDispatch();

  const [sendSuccess, setSendSuccess] = useState(false);

  return (
    <Formik
      initialValues={{
        subject: '',
        message: '',
      }}
      validationSchema={customerMessageSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const { success } = await dispatch(sendContactMessage(values));
        if (success) setSendSuccess(true);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({
        values,
        errors,
        isSubmitting,
        setFieldValue,
        setFieldError,
        handleSubmit,
      }) => (
        <Container>
          <NavHeader />
          <LayoutGrid fullWidth={false} maxWidth autoHeight>
            <ContentWrapper>
              <Grid row>
                <Grid column sm={12}>
                  <TitleWrapper>
                    <GetHelpIcon src={LifeSaverIcon} />
                    <Heading>{Strings.pageTitle}</Heading>
                    <Heading highlight>&nbsp;{Strings.pageTitleHighlight}</Heading>
                  </TitleWrapper>
                </Grid>
              </Grid>
              <Grid row marginTop="3rem">
                <Grid column sm={12} md={6}>
                  <Grid row maxWidth="100%">
                    <Grid column sm={12}>
                      <TitleWrapper>
                        <Heading large>{Strings.pageSubtitlePartOne}</Heading>
                        <Heading large highlight>&nbsp;{Strings.pageSubTitleHighlight}</Heading>
                      </TitleWrapper>
                      <Heading large>{Strings.pageSubtitlePartTwo}</Heading>
                    </Grid>
                  </Grid>
                  <Grid row maxWidth="80%" marginTop="1.5rem" expandedMobile>
                    <Grid column sm={12}><Subtext>{Strings.bodyPartOne}</Subtext></Grid>
                  </Grid>
                  <Grid row maxWidth="80%" marginTop="1rem" expandedMobile>
                    <Grid column sm={12}><Subtext>{Strings.bodyPartTwo}</Subtext></Grid>
                  </Grid>
                  <Grid row maxWidth="100%" marginTop="3rem">
                    <Grid column sm={12}>
                      <ContactWrapper>
                        <ContactIcon src={BlueEmailIcon} />
                        <ContactText>{Strings.contactEmail}</ContactText>
                      </ContactWrapper>
                    </Grid>
                  </Grid>
                  <Grid row maxWidth="100%" marginTop="1rem">
                    <Grid column sm={12}>
                      <ContactWrapper>
                        <ContactIcon src={BluePhoneIcon} />
                        <ContactText>
                          {Strings.contactPhone}
                        </ContactText>
                      </ContactWrapper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid column sm={12} md={6}>
                  { sendSuccess ? (
                    <>
                      <Grid row maxWidth="100%" marginTop="3rem">
                        <ConfirmationWrapper>
                          <Checkmark>
                            <CheckmarkStem />
                            <CheckmarkKick />
                          </Checkmark>
                          <Heading highlight>{GenericText.thankYou}</Heading>
                        </ConfirmationWrapper>
                      </Grid>
                      <Grid row maxWidth="80%" marginTop="0.5rem">
                        <Grid column sm={12}><Subtext>{Strings.confirmationMessage}</Subtext></Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid row maxWidth="100%" marginTop="1rem">
                        <Grid column sm={12}>
                          <Input
                            name="subject"
                            type="text"
                            placeholder={Strings.subjectInputPlaceholder}
                            label={Strings.subjectInputLabel}
                            outlined
                            width="100%"
                          />
                        </Grid>
                      </Grid>
                      <Grid row maxWidth="100%" marginTop="0.5rem">
                        <Grid column sm={12}>
                          <TextArea
                            name="message"
                            label={Strings.messageInputLabel}
                            rows={12}
                            placeHolder={Strings.messageInputPlaceholder}
                            handleChange={(val) => {
                              setFieldValue('message', val);
                              setFieldError('message', '');
                            }}
                            value={values.message}
                            error={errors.message}
                          />
                        </Grid>
                      </Grid>
                      <Grid row maxWidth="100%" marginTop="0.5rem">
                        <Grid column sm={12}>
                          <Button
                            onClick={handleSubmit}
                            variant="primary"
                            disabled={isSubmitting}
                          >
                            {Strings.submitInputButton}
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </ContentWrapper>
          </LayoutGrid>
        </Container>
      )}
    </Formik>
  );
};

export default ContactUs;
