import React from 'react';
import { useSelector } from 'react-redux';

import ToastNotification from '../../Common/ToastNotification';
import { dismissNotification } from '../../../lib/store/contexts/notification/actions';

import { ApplicationState } from '../../../lib/store';

const NotificationContainer: React.FC = () => {
  const { visible, options } = useSelector((state: ApplicationState) => ({
    visible: state.notificationState.visible,
    options: state.notificationState.options,
  }));

  return (
    <>
      {visible
      && <ToastNotification {...options} onDismiss={dismissNotification} />}
    </>
  );
};

export default NotificationContainer;
