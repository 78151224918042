import React from 'react';
import { useTheme } from 'styled-components';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  LabelList,
  Legend,
  TooltipProps,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import StatsTooltip from '../StatsTooltip';

import {
  Container,
  TooltipText,
} from './Styles';

import { CustomerStatusProps } from './types';
import { formatLargeNumber } from '../../../../lib/utils';

const CustomerStatus: React.FC<CustomerStatusProps> = ({
  data,
  showAllTime,
}) => {
  const theme = useTheme();
  const chartData = data.map(({ month, newUsers }) => ({ name: month, newUsers }));
  const chartDataYearTotals = data.map((item) => {
    const currentYearMonths = data.filter((i) => i.year === item.year);
    const currentYearTotal = currentYearMonths.reduce((acc, curr) => acc + Number(curr.newUsers), 0);
    const displayName = !!item.year && item.year.toString();
    return {
      name: displayName,
      newUsers: currentYearTotal,
    };
  }).filter((item, i, arr) => arr.findIndex((ele) => ele.name === item.name) === i);

  const labelStyle = {
    fontSize: `${theme.fontScale.XS}rem`,
    fill: theme.basePalette.navyMain,
  };

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <StatsTooltip leftAlign>
          {payload.map((item) => (
            <TooltipText style={{ color: item.color }} key={item.name}>
              {`${formatLargeNumber(item.value as number)}`}
            </TooltipText>
          ))}
        </StatsTooltip>
      );
    }
    return null;
  };

  return (
    <Container>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={150}
          height={40}
          barSize={5}
          data={showAllTime ? chartDataYearTotals : chartData}
          margin={{
            bottom: 25,
          }}
        >
          <Legend
            iconType="circle"
            iconSize={5}
            verticalAlign="bottom"
            align="right"
            wrapperStyle={{ position: 'absolute', bottom: 0 }}
            formatter={(value: string) => {
              const displayValue = value.charAt(0).toUpperCase() + value.slice(1, 3);
              return <span style={{ fontSize: `${theme.fontScale.XS}rem` }}>{displayValue}</span>;
            }}
          />
          <Tooltip
            content={<CustomTooltip />}
            labelFormatter={() => <></>}
            cursor={false}
          />
          <Bar
            isAnimationActive={false}
            dataKey="newUsers"
            fill={theme.basePalette.blueMain}
            radius={2}
          >
            <LabelList
              offset={10}
              style={labelStyle}
              dataKey="name"
              position="bottom"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default CustomerStatus;
