import { PlanDataView } from '../../../../../types/DBViews';

import {
  SetAllPayablePlansAction,
  SET_ALL_PAYABLE_PLAN_DATA,
} from './types';

export const setAllPayablePlanData = (
  payablePlans: PlanDataView[],
): SetAllPayablePlansAction => ({
  type: SET_ALL_PAYABLE_PLAN_DATA,
  payablePlans,
});
