import styled from 'styled-components/macro';

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.bgSecondary};
  min-height: 100vh;
  padding-bottom: ${({ theme }) => theme.spacing.XS}rem;
`;

export const ContentWrapper = styled.main`
  flex-direction: column;
  display: flex;
  z-index: 1;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ theme }) => theme.spacing.XXS}rem;

  ${({ theme }) => theme.device.tablet} {
    margin-top: ${({ theme }) => theme.spacing.M}rem;
    margin-bottom: ${({ theme }) => theme.spacing.S}rem;
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 0.9375rem;
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 395px;
  height: 44px;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #DFDFDF;
  box-shadow: 0 10px 30px 2px #00000000;
  cursor: pointer;
  margin-top: 1rem;

  :nth-of-type(1) {
    margin-right: 1rem;
  }
`;

export const LinkIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 10px;

  &:hover {
    transform: scale(1.02);
  }
`;

export const LinkTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  color: ${({ theme }) => theme.basePalette.black};
  text-align: left;
  margin-left: 10px;
`;

export const LinkSubTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  color: ${({ theme }) => theme.basePalette.black};
  text-align: left;
  margin-left: 10px;
`;