import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import dayjs from 'dayjs';
import qs from 'qs';

import { Grid as LayoutGrid } from '../../../layouts/MasterLayout';
import NavHeader from '../../../components/UI/NavHeader';
import ProcessPaymentService from './ProcessPaymentService';
import UserInfo from './UserInfo';
import PlansList from './PlansList';
import InternalLinks from './InternalLinks';

import { Container, ContentWrapper } from './Styles';

import { getAllOutstandingPayments } from '../../../lib/api/customer/payments';
import { getRedirectParameters } from '../../../lib/api/customer/paymentService';
import { getCustomerPlans } from '../../../lib/api/customer/plans';
import { ApplicationState } from '../../../lib/store';
import { DashboardProps } from './types';
import { PaymentDataView } from '../../../lib/types/DBViews';
import { APIThunkDispatch } from '../../../lib/types/API';
import { PaymentStatus } from '../../../lib/types/Customer';
import { PlanStatus } from '../../../lib/types/Plan';

function getNextPaymentDateTotal(payments: PaymentDataView[]): NextPaymentData | undefined {
  // filter out inactive plan payments
  const activePayments = payments.filter((payment) => payment.planStatus !== PlanStatus.INACTIVE);
  if (!activePayments.length) return undefined;
  const sortedPayments = activePayments.sort((a, b) => dayjs(a.scheduledDueDate || a.dueDate)
    .diff(dayjs(b.scheduledDueDate || b.dueDate)));
  const nextPaymentDate = dayjs(sortedPayments[0].scheduledDueDate || sortedPayments[0].dueDate).startOf('day');
  const nextPaymentDatePayments = sortedPayments.filter(({ dueDate, scheduledDueDate }) => dayjs(scheduledDueDate || dueDate).startOf('day').isSame(nextPaymentDate, 'day'));
  const nextPaymentTotal = nextPaymentDatePayments.reduce((acc, curr) => (acc + curr.installmentValue), 0);
  return { nextPaymentAmount: nextPaymentTotal, nextPaymentDate: nextPaymentDate.toDate() };
}

interface NextPaymentData {
  nextPaymentAmount: number;
  nextPaymentDate: Date;
}

const Dashboard: React.FC<DashboardProps> = ({ profileData }) => {
  const dispatch: APIThunkDispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [canFetchPaymentResult, setCanFetchPaymentResult] = useState(true);
  // Control button disable
  const [fetchingRedirectParams, setFetchingRedirectParams] = useState(false);

  // If this component was mounted due to a redirect from payment service, these values will be populated.
  const { fromPaymentService, webPageToken }: {
    fromPaymentService?: string,
    webPageToken?: string,
  } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const handleIntegratePaymentService = async () => {
    setFetchingRedirectParams(true);
    const { data } = await dispatch(getRedirectParameters());
    if (data) window.location.href = data?.redirectToUrl;
  };

  const handleCompleteIntegration = () => {
    setCanFetchPaymentResult(false);
    history.replace({
      search: '',
    });
  };

  const { user: { firstName }, paymentStatus } = profileData;
  const {
    paymentsState: { payments },
    plansState: { plans },
  } = useSelector((state: ApplicationState) => state.customer);
  const { nextPaymentAmount, nextPaymentDate } = getNextPaymentDateTotal(payments) || {};

  const activePlanData = plans.filter((plan) => (plan.status !== PlanStatus.CANCELLED
    && plan.status !== PlanStatus.COMPLETE));

  const getPayments = useCallback(() => {
    dispatch<void>(getAllOutstandingPayments());
  }, [dispatch]);

  const getPlans = useCallback(() => {
    dispatch<void>(getCustomerPlans());
  }, [dispatch]);

  useEffect(() => {
    getPayments();
  }, [getPayments]);

  useEffect(() => {
    getPlans();
  }, [getPlans]);

  return (
    <>
      {fromPaymentService && webPageToken && canFetchPaymentResult && (
        <ProcessPaymentService
          webPageToken={webPageToken}
          isOpen={canFetchPaymentResult}
          onComplete={handleCompleteIntegration}
        />
      )}
      <Container>
        <NavHeader />
        <LayoutGrid fullWidth={false} maxWidth autoHeight>
          <ContentWrapper>
            <UserInfo
              firstName={firstName}
              nextPayment={nextPaymentAmount}
              nextPaymentDate={nextPaymentDate}
              fetchingRedirectParams={fetchingRedirectParams}
              onIntegratePaymentService={handleIntegratePaymentService}
              hidePaymentBanner={paymentStatus === PaymentStatus.ACTIVE || !!fromPaymentService}
            />
            <PlansList plans={activePlanData} />
            <InternalLinks />
          </ContentWrapper>
        </LayoutGrid>
      </Container>
    </>
  );
};

export default Dashboard;
