import React, { useState } from 'react';
import { useField } from 'formik';

import {
  ToggleWrapper,
  OptionsWrapper,
  Option,
  MetaWrapper,
  Label,
  Error,
  Divider,
} from './Styles';

import { ToggleProps } from './types';

const Toggle: React.FC<ToggleProps> = ({
  name,
  onLoadOption,
  options = [],
  // optionsDisplay = key value pairs to use as display values if needed
  optionsDisplay,
  label,
  disabled = false,
  onOptionClick,
}) => {
  // Component Must be used inside of <Formik> tags to access Formik context
  const [, meta, helpers] = useField(name);
  const [selected, setSelected] = useState<string | null>(onLoadOption || null);

  const { setError } = helpers;
  const { touched, error } = meta;

  const handleOptionClick = (option: string) => {
    setSelected(option);
    onOptionClick(option);
    setError(null);
  };

  return (
    <ToggleWrapper>
      <MetaWrapper>
        {!!label && <Label>{label}</Label>}
      </MetaWrapper>
      <OptionsWrapper
        disabled={disabled}
        error={error}
      >
        {options.map((option, i) => (
          <React.Fragment key={option}>
            <Option
              onClick={() => handleOptionClick(option)}
              selected={option === selected}
            >
              {optionsDisplay ? optionsDisplay[option] : option}
            </Option>
            {i !== options.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </OptionsWrapper>
      {touched && error && <Error className="error">{error}</Error>}
    </ToggleWrapper>
  );
};

export default Toggle;
