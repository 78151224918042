import {
  SET_CUSTOMER_PLANS,
  ADD_CUSTOMER_PLAN,
  SET_NEW_PLAN_DATA,
  RESET_NEW_PLAN_DATA,
  PlansState,
  PlansActionTypes,
} from './types';

const initialState: PlansState = {
  newPlanData: null,
  plans: [],
};

const plansReducer = (
  state: PlansState = initialState,
  action: PlansActionTypes,
): PlansState => {
  switch (action.type) {
    case SET_CUSTOMER_PLANS:
      return {
        ...state,
        plans: action.plans,
      };
    case ADD_CUSTOMER_PLAN:
      return {
        ...state,
        plans: [
          ...state.plans,
          action.plan,
        ],
      };
    case SET_NEW_PLAN_DATA:
      return {
        ...state,
        newPlanData: {
          ...state.newPlanData,
          ...action.newPlanData,
        },
      };
    case RESET_NEW_PLAN_DATA:
      return {
        ...state,
        newPlanData: null,
      };
    default:
      return state;
  }
};

export default plansReducer;
