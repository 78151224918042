import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import useStrings from '../../../hooks/useStrings';
import { getAuthUser } from '../../../lib/Authentication';
import useAuth from '../../../hooks/useAuth';
import {
  fetchPlanPaymentsTotal,
  fetchPlanFeesTotal,
  fetchCustomersTotal,
  fetchPlansTotal,
  fetchAmountCollected,
  fetchPlanTypes,
  fetchCustomerLocations,
  fetchCustomerStatusTotal,
  fetchCustomerStatuses,
} from '../../../lib/api/admin/statistics';

import PageLayout from '../../../layouts/PageLayout';
import Grid from '../../../components/Common/Grid';
import DashboardPanel from './DashboardPanel';
import DashboardStats from './DashboardStats';
import AmountCollected from './AmountCollected';
import CustomerLocations from './CustomerLocations';
import CustomerStatuses from './CustomerStatuses';
import PlanTypes from './PlanTypes';
import CustomerStatus from './CustomerStatus';
import NoData from './NoData';

import {
  FeesStatsIcon,
  PlansStatsIcon,
  UsersStatsIcon,
  RevenueStatsIcon,
} from '../../../assets/Icons';
import { MobileSpacer } from './Styles';

import { DashboardProps } from './types';
import { ApplicationState } from '../../../lib/store/index';
import { StatsFilterOptions, APIThunkDispatch } from '../../../lib/types/API';
import { Roles } from '../../../lib/types/Auth';

const Dashboard: React.FC<DashboardProps> = () => {
  const history = useHistory();
  const Dispatch: APIThunkDispatch = useDispatch();
  const [{ Pages: { Admin: { Dashboard: Strings } } }] = useStrings();

  const { role } = useAuth();

  const [showAllCollected, setShowAllCollected] = useState(true);
  const [showAllCustomerStatus, setShowAllCustomerStatus] = useState(true);
  const [paymentsTotalLoading, setPaymentsTotalLoading] = useState(false);
  const [feesTotalLoading, setFeesTotalLoading] = useState(false);
  const [customersTotalLoading, setCustomersTotalLoading] = useState(false);
  const [plansTotalLoading, setPlansTotalLoading] = useState(false);
  const [amountCollectedLoading, setAmountCollectedLoading] = useState(false);
  const [planTypesLoading, setPlanTypesLoading] = useState(false);
  const [locationsLoading, setLocationsLoading] = useState(false);
  const [statusesLoading, setStatusesLoading] = useState(false);
  const [customerStatusLoading, setCustomerStatusLoading] = useState(false);

  const defaultRange = useRef([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1), // first day of month
    new Date(), // today
  ]);

  const {
    statisticsState: {
      planPaymentsTotal,
      planFeesTotal,
      customersTotal,
      plansTotal,
      amountCollected,
      planTypes,
      customerLocations,
      customerStatuses,
      customerStatusTotal,
    },
    adminUserState: { adminUser },
  } = useSelector((state: ApplicationState) => state.admin);

  // Check if a password update is required.
  useEffect(() => {
    (async () => {
      const user = getAuthUser();
      const token = await user?.getIdTokenResult();
      const claims = token?.claims;
      const mustUpdatePassword = claims?.mustUpdatePassword;
      if (mustUpdatePassword) {
        history.push('/admin/update-password');
      }
    })().catch(() => null);
  }, [history]);

  const getPlanPaymentsTotal = useCallback((filter?: StatsFilterOptions) => {
    setPaymentsTotalLoading(true);
    Dispatch(fetchPlanPaymentsTotal(filter)).then(() => {
      setPaymentsTotalLoading(false);
    }).catch(() => { });
  }, [Dispatch]);

  const getPlanFeesTotal = useCallback((filter?: StatsFilterOptions) => {
    setFeesTotalLoading(true);
    Dispatch(fetchPlanFeesTotal(filter)).then(() => {
      setFeesTotalLoading(false);
    }).catch(() => { });
  }, [Dispatch]);

  const getCustomersTotal = useCallback((filter?: StatsFilterOptions) => {
    setCustomersTotalLoading(true);
    Dispatch(fetchCustomersTotal(filter)).then(() => {
      setCustomersTotalLoading(false);
    }).catch(() => { });
  }, [Dispatch]);

  const getPlansTotal = useCallback((filter?: StatsFilterOptions) => {
    setPlansTotalLoading(true);
    Dispatch(fetchPlansTotal(filter)).then(() => {
      setPlansTotalLoading(false);
    }).catch(() => { });
  }, [Dispatch]);

  const getAmountCollected = useCallback((filter?: StatsFilterOptions) => {
    setAmountCollectedLoading(true);
    Dispatch(fetchAmountCollected(filter)).then((response) => {
      setAmountCollectedLoading(false);
      if (response.success && filter) {
        setShowAllCollected(false);
      } else if (response.success && !filter) {
        setShowAllCollected(true);
      }
    }).catch(() => { });
  }, [Dispatch]);

  const getCustomerStatusTotal = useCallback((filter?: StatsFilterOptions) => {
    setCustomerStatusLoading(true);
    Dispatch(fetchCustomerStatusTotal(filter)).then((response) => {
      setCustomerStatusLoading(false);
      if (response.success && filter) {
        setShowAllCustomerStatus(false);
      } else if (response.success && !filter) {
        setShowAllCustomerStatus(true);
      }
    }).catch(() => { });
  }, [Dispatch]);

  const getPlanTypes = useCallback((filter?: StatsFilterOptions) => {
    setPlanTypesLoading(true);
    Dispatch(fetchPlanTypes(filter)).then(() => {
      setPlanTypesLoading(false);
    }).catch(() => { });
  }, [Dispatch]);

  const getCustomerLocations = useCallback((filter?: StatsFilterOptions) => {
    setLocationsLoading(true);
    Dispatch(fetchCustomerLocations(filter)).then(() => {
      setLocationsLoading(false);
    }).catch(() => { });
  }, [Dispatch]);

  const getCustomerStatuses = useCallback((filter?: StatsFilterOptions) => {
    setStatusesLoading(true);
    Dispatch(fetchCustomerStatuses(filter)).then(() => {
      setStatusesLoading(false);
    }).catch(() => { });
  }, [Dispatch]);

  useEffect(() => {
    if (role === Roles.MASTER_ADMIN) {
      getPlanPaymentsTotal({ fromDate: defaultRange.current[0], toDate: defaultRange.current[1] });
    }
  }, [getPlanPaymentsTotal, role]);

  useEffect(() => {
    if (role === Roles.MASTER_ADMIN) {
      getPlanFeesTotal({ fromDate: defaultRange.current[0], toDate: defaultRange.current[1] });
    }
  }, [getPlanFeesTotal, role]);

  useEffect(() => {
    getCustomersTotal();
  }, [getCustomersTotal]);

  useEffect(() => {
    if (role === Roles.MASTER_ADMIN) getAmountCollected();
  }, [getAmountCollected, role]);

  useEffect(() => {
    getCustomerStatusTotal();
  }, [getCustomerStatusTotal]);

  useEffect(() => {
    getPlansTotal();
  }, [getPlansTotal]);

  useEffect(() => {
    getPlanTypes({ fromDate: defaultRange.current[0], toDate: defaultRange.current[1] });
  }, [getPlanTypes]);

  useEffect(() => {
    getCustomerLocations({ fromDate: defaultRange.current[0], toDate: defaultRange.current[1] });
  }, [getCustomerLocations]);

  useEffect(() => {
    getCustomerStatuses();
  }, [getCustomerStatuses]);

  return (
    <PageLayout
      pageSubtitle={Strings.pageSubtitle.replace('%', adminUser?.firstName || '')}
      pageTitle={`${Strings.pageTitle} 👋`}
      maxWidth="100%"
    >
      <Grid row>
        {role === Roles.MASTER_ADMIN ? (
          <>
            <Grid column lg={3} md={6} sm={12}>
              <DashboardPanel
                icon={RevenueStatsIcon}
                title={Strings.revenuePanel}
                size="small"
                loading={paymentsTotalLoading && !planPaymentsTotal}
                onChange={(val?: Date | Date[]) => {
                  if (!val) getPlanPaymentsTotal();
                  else if (Array.isArray(val)) getPlanPaymentsTotal({ fromDate: val[0], toDate: val[1] });
                  else getPlanPaymentsTotal({ fromDate: val });
                }}
                defaultRange={defaultRange.current}
              >
                <DashboardStats
                  value={planPaymentsTotal}
                  isCurrency
                />
              </DashboardPanel>
              <MobileSpacer />
            </Grid>
            <Grid column lg={3} md={6} sm={12}>
              <DashboardPanel
                icon={FeesStatsIcon}
                title={Strings.feesPanel}
                size="small"
                loading={feesTotalLoading && !planFeesTotal}
                onChange={(val?: Date | Date[]) => {
                  if (!val) getPlanFeesTotal();
                  else if (Array.isArray(val)) getPlanFeesTotal({ fromDate: val[0], toDate: val[1] });
                  else getPlanFeesTotal({ fromDate: val });
                }}
                defaultRange={defaultRange.current}
              >
                <DashboardStats
                  value={planFeesTotal}
                  isCurrency
                />
              </DashboardPanel>
              <MobileSpacer />
            </Grid>
          </>
        ) : (
          <Grid column lg={6} md={12}>
            <DashboardPanel
              icon="👦"
              isEmoji
              title={Strings.customersPanel}
              size="medium"
              yearOnly
              loading={customerStatusLoading && !customerStatusTotal.length}
              onChange={(val?: Date | Date[]) => {
                if (!val) getCustomerStatusTotal();
                else if (Array.isArray(val)) getCustomerStatusTotal({ fromDate: val[0], toDate: val[1] });
                else getCustomerStatusTotal({ fromDate: val });
              }}
              defaultRange={defaultRange.current}
            >

              {!customerStatusTotal.length ? (
                <NoData />
              ) : (
                <CustomerStatus data={customerStatusTotal} showAllTime={showAllCustomerStatus} />
              )}
            </DashboardPanel>
            <MobileSpacer />
          </Grid>
        )}
        <Grid column lg={3} md={6} sm={12}>
          <DashboardPanel
            icon={UsersStatsIcon}
            title={Strings.usersPanel}
            size={role === Roles.MASTER_ADMIN ? 'small' : 'medium'}
            loading={customersTotalLoading && !customersTotal}
          >
            <DashboardStats value={customersTotal} />
          </DashboardPanel>
        </Grid>
        <Grid column lg={3} md={6} sm={12}>
          <DashboardPanel
            icon={PlansStatsIcon}
            title={Strings.plansPanel}
            size={role === Roles.MASTER_ADMIN ? 'small' : 'medium'}
            loading={plansTotalLoading && !plansTotal}
          >
            <DashboardStats value={plansTotal} />
          </DashboardPanel>
        </Grid>
      </Grid>
      {role === Roles.MASTER_ADMIN && (
      <Grid row marginTop="2rem">
        <Grid column lg={6} md={12}>
          <DashboardPanel
            icon="👦"
            isEmoji
            title={Strings.customersPanel}
            size="medium"
            yearOnly
            loading={customerStatusLoading && !customerStatusTotal.length}
            onChange={(val?: Date | Date[]) => {
              if (!val) getCustomerStatusTotal();
              else if (Array.isArray(val)) getCustomerStatusTotal({ fromDate: val[0], toDate: val[1] });
              else getCustomerStatusTotal({ fromDate: val });
            }}
            defaultRange={defaultRange.current}
          >
            {!customerStatusTotal.length ? (
              <NoData />
            ) : (
              <CustomerStatus data={customerStatusTotal} showAllTime={showAllCustomerStatus} />
            )}
          </DashboardPanel>
          <MobileSpacer />
        </Grid>
        <Grid column lg={6} md={12}>
          <DashboardPanel
            icon="💵"
            isEmoji
            title={Strings.amountCollectedPanel}
            size="medium"
            yearOnly
            loading={amountCollectedLoading && !amountCollected.length}
            onChange={(val?: Date | Date[]) => {
              if (!val) getAmountCollected();
              else if (Array.isArray(val)) getAmountCollected({ fromDate: val[0], toDate: val[1] });
              else getAmountCollected({ fromDate: val });
            }}
            defaultRange={defaultRange.current}
          >
            {!amountCollected.length ? (
              <NoData />
            ) : (
              <AmountCollected data={amountCollected} showAllTime={showAllCollected} />
            )}
          </DashboardPanel>
        </Grid>
      </Grid>
      )}
      <Grid row marginTop="2rem">
        <Grid column lg={6} md={12}>
          <DashboardPanel
            icon="📊"
            isEmoji
            title={Strings.planTypesPanel}
            size="large"
            loading={planTypesLoading && !planTypes.length}
            onChange={(val?: Date | Date[]) => {
              if (!val) getPlanTypes();
              else if (Array.isArray(val)) getPlanTypes({ fromDate: val[0], toDate: val[1] });
              else getPlanTypes({ fromDate: val });
            }}
            defaultRange={defaultRange.current}
          >
            {!planTypes.length ? (
              <NoData />
            ) : (
              <PlanTypes data={planTypes} />
            )}
          </DashboardPanel>
          <MobileSpacer />
        </Grid>
        <Grid column lg={6} md={12}>
          <DashboardPanel
            icon="🌏"
            isEmoji
            title={Strings.locationsPanel}
            size="large"
            loading={locationsLoading && !customerLocations.length}
            onChange={(val?: Date | Date[]) => {
              if (!val) getCustomerLocations();
              else if (Array.isArray(val)) getCustomerLocations({ fromDate: val[0], toDate: val[1] });
              else getPlansTotal({ fromDate: val });
            }}
            defaultRange={defaultRange.current}
          >
            {!customerLocations.length ? (
              <NoData />
            ) : (
              <CustomerLocations data={customerLocations} />
            )}
          </DashboardPanel>
        </Grid>
      </Grid>
      <Grid row marginTop="2rem">
        <Grid column lg={6} md={12}>
          <DashboardPanel
            icon="👦"
            isEmoji
            title={Strings.customerStatusPanel}
            size="large"
            loading={statusesLoading && !customerStatuses.length}
            // onChange={(val?: Date | Date[]) => {
            //   if (!val) getCustomerStatuses();
            //   else if (Array.isArray(val)) getCustomerStatuses({ fromDate: val[0], toDate: val[1] });
            //   else getCustomerStatuses({ fromDate: val });
            // }}
            // defaultRange={defaultRange.current}
          >
            {!customerStatuses.length ? (
              <NoData />
            ) : (
              <CustomerStatuses data={customerStatuses} />
            )}
          </DashboardPanel>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default Dashboard;
