import styled from 'styled-components/macro';

export const ReferralIcon = styled.img`
  width: 44px;
  height: 44px;
  margin-right: 10px;
`;

export const TitleWrapper = styled.div`
  display: inline;
`;

export const ContactIcon = styled.img`
  width: 15px;
  height: 15px;
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: 'center';
  font-size: ${({ theme }) => theme.fontScale.M}rem;
`;

export const ContactText = styled.div`
  margin-left: 20px;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.basePalette.navyMain};
`;

export const ConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: row ;
  padding: 0 0.9375rem;
`;

export const Checkmark = styled.div`
  width: 50px;
  height:50px;
  -ms-transform: rotate(45deg) translate(0, -31px);
  -webkit-transform: rotate(45deg) translate(0, -31px);
  -moz-transform: rotate(45deg) translate(0, -31px);
  transform: rotate(45deg) translate(0, -31px);

  transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
`;

export const CheckmarkStem = styled.div`
  position: absolute;
  width:7px;
  height:35px;
  background-color:  ${({ theme }) => theme.basePalette.blueMain};
  left:28px;
  top:14px;
  border-bottom-right-radius:4px;
`;

export const CheckmarkKick = styled.div`
  position: absolute;
  width:20px;
  height:7px;
  background-color:  ${({ theme }) => theme.basePalette.blueMain};
  left:15px;
  top:42px;
  border-bottom-right-radius:4px;
`;

export const SendAnotherText = styled.div`
  color: ${({ theme }) => theme.basePalette.blueMain};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  display: inline;
  cursor: pointer;
`;