import { PaymentDataView } from '../../../../types/DBViews';

import {
  SetAllOverdueInstallmentsAction,
  SetUpdatedOverdueInstallmentsAction,
  RemoveOverdueInstallmentsAction,
  SET_ALL_OVERDUE_INSTALLMENT_DATA,
  SET_UPDATED_OVERDUE_INSTALLMENT_DATA,
  REMOVE_OVERDUE_INSTALLMENTS,
} from './types';

export const setAllOverdueInstallmentData = (
  overdueInstallmentData: {
    results: PaymentDataView[];
    count: number;
  },
): SetAllOverdueInstallmentsAction => ({
  type: SET_ALL_OVERDUE_INSTALLMENT_DATA,
  overdueInstallmentData,
});

export const setUpdatedOverdueInstallmentData = (
  overdueInstallments: PaymentDataView[],
): SetUpdatedOverdueInstallmentsAction => ({
  type: SET_UPDATED_OVERDUE_INSTALLMENT_DATA,
  overdueInstallments,
});

export const removeOverdueInstallmentData = (
  installmentIds: number[],
): RemoveOverdueInstallmentsAction => ({
  type: REMOVE_OVERDUE_INSTALLMENTS,
  installmentIds,
});
