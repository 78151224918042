import React, { useState } from 'react';

import { Formik } from 'formik';
import useStrings from '../../../../../hooks/useStrings';

import Button from '../../../../../components/Common/Button';
import FileUploadModal from '../../../../../components/Common/FileUploadModal';
import FileTab from '../FileTab/FileTab';
import { FileUploadIcon, NoteFooter, StyledTextArea, FileTabOutterWrapper } from './Styles';
import { UploadIcon } from '../../../../../assets/Icons';

import { NoteFile } from '../../../../../lib/types/DBModels';
import { NoteInputProps } from './types';
import { NewNoteFile } from '../types';

const NoteInput: React.FC<NoteInputProps> = ({
  note,
  handleAddAttachment,
  handleRemoveAttachment,
  handleNoteChange,
  saveNote,
  textAreaRef,
  dirty,
  userId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [toolTipsOpen, setToolTipsOpen] = useState('');

  const [{
    Pages: { UserProfile: { Notes: NotesStrings } },
    Components: { Common: { FileUploadModal: FileUploadModalStrings } },
    GenericText,
  }] = useStrings();

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleToolTipClose = (file: string) => {
    setToolTipsOpen(file);
  };
  return (
    <Formik
      initialValues={{
        note: note?.description || '',
        file: note?.noteFiles || [],
      }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const updatedNote = note;
        await saveNote(updatedNote);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {(formik) => (
        <>
          {isOpen && (
            <FileUploadModal
              onCancel={handleCancel}
              title={FileUploadModalStrings.title}
              formik={formik}
              handleAddAttachment={handleAddAttachment}
              userId={userId}
            />
          ) }
          <StyledTextArea
            name="note"
            rows={18}
            placeholder={NotesStrings.TextAreaPlaceholder}
            onChange={(e) => {
              handleNoteChange(e.target.value);
              formik.setFieldValue('note', e.target.value);
            }}
            value={note.description}
            ref={textAreaRef}
          />
          <NoteFooter>
            {note.noteFiles
              && note.noteFiles.map((file: NewNoteFile | NoteFile) => (
                <FileTabOutterWrapper key={file.url}>
                  <FileTab
                    key={file.url}
                    file={file}
                    handleRemoveAttachment={handleRemoveAttachment}
                    toolTipsOpen={file.url === toolTipsOpen}
                    handleToolTipClose={handleToolTipClose}
                  />
                </FileTabOutterWrapper>
              ))}
            <FileUploadIcon src={UploadIcon} onClick={() => setIsOpen(true)} />
            <Button onClick={() => { formik.handleSubmit(); }} rounded width="150px" height="50px" margin="0 2rem 0 auto" disabled={!dirty} $loading={formik.isSubmitting}>{GenericText.save}</Button>
          </NoteFooter>
        </>
      )}
    </Formik>
  );
};

export default NoteInput;
