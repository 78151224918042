export enum PlanStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  DUE = 'DUE',
}

export enum InstallmentStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  REJECTED = 'REJECTED',
  SKIPPED = 'SKIPPED',
  TRANSFERRED = 'TRANSFERRED',
  CANCELLED = 'CANCELLED',
}

// Car registration
export enum RegistrationLength {
  THREE_MONTHS = 'THREE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  TWELVE_MONTHS = 'TWELVE_MONTHS',
  UNKNOWN = 'UNKNOWN',
}

export enum InstallmentFrequency {
  ONE_OFF_PAYMENT = 'ONE_OFF_PAYMENT',
  WEEK = 'WEEK',
  FORTNIGHT = 'FORTNIGHT',
  MONTH = 'MONTH',
}

export enum PlanType {
  VEHICLE_REGISTRATION = 'VEHICLE_REGISTRATION',
  CHRISTMAS_FUND = 'CHRISTMAS_FUND',
  HOLIDAY = 'HOLIDAY',
  INSURANCE = 'INSURANCE',
  RATE_PAYMENTS = 'RATE_PAYMENTS',
  SAVINGS = 'SAVINGS',
  SCHOOL_FEES = 'SCHOOL_FEES',
  OTHER = 'OTHER',
}

export type PlanFilterLimits = {
  minPlanValue: number,
  maxPlanValue: number,
};

export type PaymentFilterLimits = {
  minPlanValue: number,
  maxPlanValue: number,
  minInstallmentValue: number,
  maxInstallmentValue: number,
};

export enum Concession {
  NONE = 'NONE',
  HEALTH_CARE = 'HEALTH_CARE',
  PENSIONER = 'PENSIONER',
}
