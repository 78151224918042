import React from 'react';

import CustomerLayout from '../../../layouts/CustomerLayout';

import useStrings from '../../../hooks/useStrings';
import { PaymentsProps } from './types';

const Payments: React.FC<PaymentsProps> = () => {
  const [{ Pages: { Customer: { Payments: Strings } } }] = useStrings();

  return (
    <CustomerLayout
      pageTitle={Strings.pageTitle}
      pageTitleHighlight={Strings.pageTitleHighlight}
    >
      <span>Payments page</span>
    </CustomerLayout>

  );
};

export default Payments;
