import React from 'react';

import {
  Container,
  StyledTooltip,
} from './Styles';

import { StatsTooltopProps } from './types';

const StatsTooltip: React.FC<StatsTooltopProps> = ({ leftAlign, children }) => (
  <Container>
    <StyledTooltip leftAlign={leftAlign}>
      {children}
    </StyledTooltip>
  </Container>
);

export default StatsTooltip;
