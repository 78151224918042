import React from 'react';

import Grid from '../../components/Common/Grid';
import { Grid as LayoutGrid } from '../MasterLayout';
import PageHeader from '../../components/UI/PageHeader';
import SideNav from '../../components/UI/SideNav';

import { SideContent, MainContent } from './Styles';

import { PageLayoutProps } from './types';

const PageLayout: React.FC<PageLayoutProps> = ({
  pageTitle,
  pageSubtitle,
  maxWidth,
  children,
}) => (
  <LayoutGrid>
    <SideContent>
      <SideNav />
    </SideContent>
    <MainContent id="scroll-lock">
      <Grid row maxWidth={maxWidth}>
        <Grid column sm={12} alignItems="flex-start">
          <PageHeader pageTitle={pageTitle} pageSubtitle={pageSubtitle} />
        </Grid>
      </Grid>
      {children}
    </MainContent>
  </LayoutGrid>
);

export default PageLayout;
