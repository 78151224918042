import { PlanDataView } from '../../../../../types/DBViews';

import {
  SetSelectedPlanAction,
  ClearSelectedPlanAction,
  CLEAR_SELECTED_PLAN,
  SET_SELECTED_PLAN,
} from './types';

export const setSelectedPlanData = (
  planData: PlanDataView,
): SetSelectedPlanAction => ({
  type: SET_SELECTED_PLAN,
  planData,
});

export const clearSelectedPlan = (): ClearSelectedPlanAction => ({
  type: CLEAR_SELECTED_PLAN,
});
