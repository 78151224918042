import styled, { css } from 'styled-components/macro';

interface StyledCheckboxProps {
  checked: boolean;
  error: boolean;
}
interface StyledCheckboxLabelProps {
  marginBottom?: string;
}

export const StyledCheckboxLabel = styled.label<StyledCheckboxLabelProps>`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  width: 1.4rem;
  position: relative;
  top: calc(50% - 0.9rem);
`;

export const StyledCheckboxOuter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
  cursor: pointer;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 0rem;
  margin: -0.1rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0.1rem;
`;

const getCheckedStyles = (checked: boolean) => css`
  background: ${({ theme }) => (checked ? theme.palette.checkboxChecked : theme.palette.checkbox)};
  ${({ theme }) => `border: 1px solid ${checked ? theme.palette.checkboxChecked : theme.palette.checkboxBorder}`};
`;

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  ${({ checked }) => getCheckedStyles(checked)};
  display: inline-block;
  position: relative;
  width: 2rem;
  height: 2rem;
  border-radius: 10px;
  transition: all .2s ease;
  border-color: ${({ theme, error }) => error && theme.palette.checkboxError};

  ${Icon} {
    width: 12px;
    transition: opacity .2s ease;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    -webkit-user-select: none;

    opacity: ${({ checked }) => (checked ? 1 : 0)};
  }
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.palette.btnError};
  font-size: ${({ theme }) => theme.fontScale.M as number * 0.75}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular as number + 50};
  font-style: italic;
  margin-bottom: 5px;
`;
