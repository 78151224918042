import { PlanDataView } from '../../../../types/DBViews';

import {
  SetPlansAction,
  SET_ALL_PLAN_DATA,
} from './types';

export const setPaginatedCustomerPlanData = (
  planData: {
    results: PlanDataView[];
    count: number;
  },
): SetPlansAction => ({
  type: SET_ALL_PLAN_DATA,
  planData,
});
