import { auth } from 'firebase/app';

export type UserCredentials = auth.UserCredential;

export enum Roles {

  DEFAULT = 'DEFAULT',

  // Admin roles
  MASTER_ADMIN = 'MASTER_ADMIN',
  ADMIN = 'ADMIN',

  // Customer roles
  CUSTOMER = 'CUSTOMER',
}

export enum Permissions {

  // admin account permissions
  // customers
  CUSTOMER_READER_ALL = 'CUSTOMER_READER_ALL',
  // plans
  PLAN_READER_ALL = 'PLAN_READER_ALL',
  // payments
  PAYMENT_READER_ALL = 'PAYMENT_READER_ALL',
  // statistics
  STATISTICS_READER_ALL = 'STATISTICS_READER_ALL',
  // notes
  NOTE_READER_ALL = 'NOTE_READER_ALL',
  // admins,
  ADMIN_READER_ALL = 'ADMIN_READER_ALL',
  ADMIN_EDITOR = 'ADMIN_EDITOR', // Create + remove admins

  // customer account permissions
  USERS_OWNER_MINE = 'USERS:OWNER:MINE',
}
