import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import Grid from '../../Common/Grid';
import Button from '../../Common/Button';
import StyledRadio from '../../Common/StyledRadio';

import {
  FormSection,
  LineBreak,
  ButtonWrapper,
  Error,
} from './Styles';

import { PlanTypeFormProps } from './types';
import useStrings from '../../../hooks/useStrings';
import selectPlanSchema from '../../../lib/validators/selectPlanSchema';
import { setNewPlanData } from '../../../lib/store/contexts/customer/plans/actions';
import { PlanType } from '../../../lib/types/Plan';
import { NewPlan } from '../../../lib/store/contexts/customer/plans/types';
import {
  CarIcon,
  GiftIcon,
  SunIcon,
  PiggyBankIcon,
  HouseIcon,
  ShieldIcon,
  SchoolHatIcon,
  TransparentEllipses,
} from '../../../assets/Icons';

const PlanTypeForm: React.FC<PlanTypeFormProps> = ({ newPlanData, onBack, onNext }) => {
  const [{ Components: { UI: { PlanTypeForm: Strings } }, GenericText }] = useStrings();
  const dispatch = useDispatch();
  const selectedPlan = newPlanData?.type;

  const handleSelectType = async (
    val: string,
    setFieldValue: (field: string, value: string) => void,
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void,
  ) => {
    await Promise.resolve<void>(setFieldValue('type', val)).then(() => handleSubmit());
  };

  return (
    <Formik
      initialValues={{
        type: selectedPlan || '',
      }}
      validationSchema={selectPlanSchema}
      validateOnChange
      validateOnBlur={false}
      enableReinitialize
      onSubmit={({ type }, { setSubmitting }) => {
        setSubmitting(false);
        dispatch(setNewPlanData({ ...newPlanData, type } as Partial<NewPlan>));
        onNext();
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values: { type },
        errors,
      }) => (
        <>
          <FormSection>
            {errors && (
              <Grid row>
                <Grid column sm={12} md={6}>
                  <Error>{errors.type}</Error>
                </Grid>
              </Grid>
            )}
            <Grid row marginTop="1rem">
              <Grid column sm={12} md={6} alignItems="center">
                <StyledRadio
                  name="type"
                  value={PlanType.VEHICLE_REGISTRATION}
                  label={Strings.registration}
                  icon={CarIcon}
                  onChange={(val: string) => handleSelectType(val, setFieldValue, handleSubmit)}
                  inactive={type !== null && type !== PlanType.VEHICLE_REGISTRATION}
                  checked={type === PlanType.VEHICLE_REGISTRATION}
                />
              </Grid>
              <Grid column sm={12} md={6} alignItems="center">
                <StyledRadio
                  name="type"
                  value={PlanType.CHRISTMAS_FUND}
                  label={Strings.christmas}
                  icon={GiftIcon}
                  onChange={(val: string) => handleSelectType(val, setFieldValue, handleSubmit)}
                  inactive={type !== null && type !== PlanType.CHRISTMAS_FUND}
                  checked={type === PlanType.CHRISTMAS_FUND}
                />
              </Grid>
            </Grid>
            <Grid row>
              <Grid column sm={12} md={6} alignItems="center">
                <StyledRadio
                  name="type"
                  value={PlanType.HOLIDAY}
                  label={Strings.holiday}
                  icon={SunIcon}
                  onChange={(val: string) => handleSelectType(val, setFieldValue, handleSubmit)}
                  inactive={type !== null && type !== PlanType.HOLIDAY}
                  checked={type === PlanType.HOLIDAY}
                />
              </Grid>
              <Grid column sm={12} md={6} alignItems="center">
                <StyledRadio
                  name="type"
                  label={Strings.insurance}
                  value={PlanType.INSURANCE}
                  icon={ShieldIcon}
                  onChange={(val: string) => handleSelectType(val, setFieldValue, handleSubmit)}
                  inactive={type !== null && type !== PlanType.INSURANCE}
                  checked={type === PlanType.INSURANCE}
                />
              </Grid>
            </Grid>
            <Grid row>
              <Grid column sm={12} md={6} alignItems="center">
                <StyledRadio
                  name="type"
                  value={PlanType.RATE_PAYMENTS}
                  label={Strings.ratePayments}
                  icon={HouseIcon}
                  onChange={(val: string) => handleSelectType(val, setFieldValue, handleSubmit)}
                  inactive={type !== null && type !== PlanType.RATE_PAYMENTS}
                  checked={type === PlanType.RATE_PAYMENTS}
                />
              </Grid>
              <Grid column sm={12} md={6} alignItems="center">
                <StyledRadio
                  name="type"
                  value={PlanType.SAVINGS}
                  label={Strings.savings}
                  icon={PiggyBankIcon}
                  onChange={(val: string) => handleSelectType(val, setFieldValue, handleSubmit)}
                  inactive={type !== null && type !== PlanType.SAVINGS}
                  checked={type === PlanType.SAVINGS}
                />
              </Grid>
            </Grid>
            <Grid row>
              <Grid column sm={12} md={6} alignItems="center">
                <StyledRadio
                  name="type"
                  value={PlanType.SCHOOL_FEES}
                  label={Strings.schoolFees}
                  icon={SchoolHatIcon}
                  onChange={(val: string) => handleSelectType(val, setFieldValue, handleSubmit)}
                  inactive={type !== null && type !== PlanType.SCHOOL_FEES}
                  checked={type === PlanType.SCHOOL_FEES}
                />
              </Grid>
              <Grid column sm={12} md={6} alignItems="center">
                <StyledRadio
                  name="type"
                  value={PlanType.OTHER}
                  label={Strings.other}
                  icon={TransparentEllipses}
                  onChange={(val: string) => handleSelectType(val, setFieldValue, handleSubmit)}
                  inactive={type !== null && type !== PlanType.OTHER}
                  checked={type === PlanType.OTHER}
                />
              </Grid>
            </Grid>
          </FormSection>
          <Grid row marginTop="1rem">
            <LineBreak />
          </Grid>
          <ButtonWrapper>
            <Button
              margin="0"
              width="160px"
              variant="outlined"
              onClick={onBack}
              disabled={isSubmitting}
              expandedMobile={false}
            >
              {GenericText.back}
            </Button>
          </ButtonWrapper>
        </>
      )}
    </Formik>
  );
};

export default PlanTypeForm;
