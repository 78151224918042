import dayjs from 'dayjs';
import React from 'react';
import { PLAN_TYPE_DISPLAY } from '../../../../../CONSTANTS';

import useStrings from '../../../../../hooks/useStrings';
import { Plan } from '../../../../../lib/types/DBModels';

import {
  CreditsHistoryWrapper,
  Title,
  SubTitle,
  TableWrapper,
  TableRow,
  Label,
  Value,
} from './Styles';

import { CustomerCreditHistoryProps } from './types';

const CustomerCreditHistory: React.FC<CustomerCreditHistoryProps> = ({ creditHistory }) => {
  const [{
    Pages: { UserProfile: { Credit: { CreditHistory: Strings } } } }] = useStrings();

  const getFromPlanText = (fromPlan: Plan | null, toPlan: Plan | null) => {
    if (fromPlan) {
      return fromPlan?.vehicle?.registration
      || (fromPlan?.name)
      || (PLAN_TYPE_DISPLAY[fromPlan.type]);
    }
    return toPlan ? Strings.credit : Strings.addedByAdmin;
  };
  const getToPlanText = (toPlan: Plan | null) => {
    if (toPlan) {
      return toPlan?.vehicle?.registration
      || (toPlan?.name)
      || (PLAN_TYPE_DISPLAY[toPlan.type]);
    }
    return Strings.credit;
  };
  return (

    <CreditsHistoryWrapper>
      <Title>{Strings.title}</Title>
      <SubTitle>{Strings.subTitle}</SubTitle>
      <TableWrapper>
        <TableRow>
          <Label>{Strings.tableColumnLabels.date}</Label>
          <Label>{Strings.tableColumnLabels.from}</Label>
          <Label>{Strings.tableColumnLabels.to}</Label>
          <Label>{Strings.tableColumnLabels.amount}</Label>
          <Label>{Strings.tableColumnLabels.by}</Label>
        </TableRow>
        {creditHistory.map((credit) => (
          <TableRow>
            <Value>{dayjs(credit.createdAt).format('DD/MM/YY')}</Value>
            <Value>{getFromPlanText(credit.fromPlan, credit.toPlan)}</Value>
            <Value>{getToPlanText(credit.toPlan)}</Value>
            <Value>{`$${credit.amount.toFixed(2)}`}</Value>
            <Value>{credit.createdByUser.firstName}</Value>
          </TableRow>
        ))}
      </TableWrapper>
    </CreditsHistoryWrapper>

  );
};

export default CustomerCreditHistory;