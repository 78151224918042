import styled from 'styled-components';

import { DotProps } from './types';

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const StatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  min-width: 40%;
`;

export const StateItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`;

export const Dot = styled.div<DotProps>`
  background: ${({ color }) => color};
  border-radius: 6px;
  height: 12px;
  margin-right: 16px;
  width: 12px;
`;

export const State = styled.span`
  color: ${({ theme }) => theme.basePalette.navyMain};
  flex: 1;
  font-size: ${({ theme }) => theme.fontScale.S as number * 1.15}rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  text-transform: uppercase;
`;

export const Status = styled.span`
  color: ${({ theme }) => theme.basePalette.navyMain};
  flex: 1;
  font-size: ${({ theme }) => theme.fontScale.S as number * 1.15}rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export const Count = styled.span`
  color: ${({ theme }) => theme.basePalette.greyDark};
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-size: ${({ theme }) => theme.fontScale.S as number * 1.15}rem;
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export const TooltipText = styled.span`
  color: ${({ theme }) => theme.basePalette.white};
  font-size: ${({ theme }) => theme.fontScale.S as number * 1.5}rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const TooltipSubtext = styled.span`
  color: ${({ theme }) => theme.palette.grey40};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
`;
