import styled from 'styled-components/macro';
import { ModalStyleProps, ModalTextProps } from './types';

export const ModalBackground = styled.div<ModalStyleProps>`
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const ModalWrapper = styled.div`
  position: relative;
  align-items: center;
  background-color: ${({ theme }) => theme.basePalette.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: default;
  border-radius: 24px;
  padding: ${({ theme }) => `${theme.spacing.XS}rem ${theme.spacing.S}rem`};
  margin: ${({ theme }) => `0 ${theme.spacing.XXS}rem`};
  
  ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => `${theme.spacing.S}rem ${theme.spacing.M}rem`};
    margin: 0;
  };
`;

export const CloseIcon = styled.img`
  position: absolute;
  top: -5px;
  right: -5px;
  color: ${({ theme }) => theme.basePalette.white};
  background-color: ${({ theme }) => theme.basePalette.navyMain};
  border-radius: 50%;
  padding: 8px;
  width: 28px;
  &:hover {
    cursor: pointer;
  }
`;

export const Icon = styled.img`
  margin-bottom: ${({ theme }) => theme.spacing.XS / 1.3}rem;
  height: 80px;
  width: 80px;
`;

export const ModalTitle = styled.p<ModalTextProps>`
  margin: ${({ theme }) => theme.spacing.XXS}rem 0;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.L}rem;
  display: inline;
  color: ${({ highlight, theme }) => highlight && theme.palette.titleHighlight};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const ModalSubtext = styled.p<ModalTextProps>`
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  text-align: center;
  color: ${({ highlight, theme }) => highlight && theme.palette.titleHighlight};
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: ${({ theme }) => `${theme.spacing.XS}rem`};
`;
