import { apiFetch, apiRequest, stripDate } from '../Utils';
import { setProfileData } from '../../store/contexts/customer/profile/actions';
import { serverCustomerAPI } from '../../../CONSTANTS';

import { APIThunkResult } from '../../types/API';
import { CustomerDTO } from '../../types/DTO';
import { UpdateCustomerProfileSchema } from '../../validators/updateCustomerProfileSchema';
import { CreateCustomerProfileSchema } from '../../validators/createCustomerProfileSchema';

export const getProfile = (): APIThunkResult<CustomerDTO> => (
  apiRequest<CustomerDTO>(async (dispatch) => {
    const { data, error } = await apiFetch<CustomerDTO>({
      method: 'GET',
      url: `${serverCustomerAPI}/profile`,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setProfileData(data.customer));
    return data;
  }, true)
);

export const createProfile = (
  input: CreateCustomerProfileSchema,
): APIThunkResult<CustomerDTO> => apiRequest<CustomerDTO>(
  async (dispatch, getState) => {
    const userId = getState().customer.profileState.profileData?.user.userId;

    if (!userId) throw new Error('No customer selected');

    const { data, error } = await apiFetch<CustomerDTO>({
      method: 'POST',
      url: `${serverCustomerAPI}/profile/update`,
      body: {
        userId,
        ...input,
        dob: stripDate(input.dob),
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setProfileData(data.customer));

    return data;
  },
  true,
);

export const updateProfile = (
  input: UpdateCustomerProfileSchema,
): APIThunkResult<CustomerDTO> => apiRequest<CustomerDTO>(
  async (dispatch, getState) => {
    const userId = getState().customer.profileState.profileData?.user.userId;

    if (!userId) throw new Error('No customer selected');

    const { data, error } = await apiFetch<CustomerDTO>({
      method: 'POST',
      url: `${serverCustomerAPI}/profile/update`,
      body: {
        userId,
        ...input,
        dob: stripDate(input.dob),
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setProfileData(data.customer));

    return data;
  },
  true,
);

export const updateTermsAccepted = (
  input: { termsAccepted: boolean },
): APIThunkResult<CustomerDTO> => apiRequest<CustomerDTO>(
  async (dispatch, getState) => {
    const userId = getState().customer.profileState.profileData?.user.userId;

    if (!userId) throw new Error('No customer selected');

    const { data, error } = await apiFetch<CustomerDTO>({
      method: 'POST',
      url: `${serverCustomerAPI}/profile/update`,
      body: {
        userId,
        ...input,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setProfileData(data.customer));

    return data;
  },
  true,
);
