import {
  apiFetch,
  apiRequest,
  objectToQueryString,
} from '../Utils';
import { DEFAULT_FILTER, serverAdminAPI } from '../../../CONSTANTS';

import {
  APIFilterOptions,
  APIThunkResult,
  OrderDirection,
} from '../../types/API';
import {
  FilteredCustomerEnquiriesDTO,
  SupportMessageDTO,
  SupportMessageRepliesDTO,
} from '../../types/DTO';
import { SupportMessageStatus } from '../../types/SupportMessage';
import { setNotification } from '../../store/contexts/notification/actions';

export const defaultFilter = {
  page: DEFAULT_FILTER.page,
  rowsPerPage: DEFAULT_FILTER.rowsPerPage,
  orderColumn: 'createdAt',
  orderDirection: OrderDirection.DESC,
};

export const getCustomerEnquiries = (
  filter: APIFilterOptions,
): APIThunkResult<FilteredCustomerEnquiriesDTO> => (
  apiRequest<FilteredCustomerEnquiriesDTO>(async () => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverAdminAPI}/contact?${query}`;

    const { data, error } = await apiFetch<FilteredCustomerEnquiriesDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);

export const getThreadReplies = (
  supportMessageId: number,
): APIThunkResult<SupportMessageRepliesDTO> => (
  apiRequest<SupportMessageRepliesDTO>(async () => {
    const { data, error } = await apiFetch<SupportMessageRepliesDTO>({
      method: 'GET',
      url: `${serverAdminAPI}/contact/replies/${supportMessageId}`,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);

export const sendReplyMessage = (
  message: string,
  parentSupportMessageId: number,
): APIThunkResult<SupportMessageDTO> => (
  apiRequest<SupportMessageDTO>(async (dispatch) => {
    const { data, error } = await apiFetch<SupportMessageDTO>({
      method: 'POST',
      url: `${serverAdminAPI}/contact/reply`,
      body: {
        message,
        parentSupportMessageId,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setNotification({ message: 'Reply sent.', variant: 'success' }));

    return data;
  }, true)
);

export const updateThreadStatus = (
  supportMessageId: number,
  status: SupportMessageStatus,
): APIThunkResult<SupportMessageDTO> => (
  apiRequest<SupportMessageDTO>(async (dispatch) => {
    const { data, error } = await apiFetch<SupportMessageDTO>({
      method: 'POST',
      url: `${serverAdminAPI}/contact/update-status`,
      body: {
        status,
        supportMessageId,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setNotification({ message: 'Status updated.', variant: 'success' }));

    return data;
  }, true)
);
