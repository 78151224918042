import React from 'react';
import DataTable from 'react-data-table-component';
import dayjs from 'dayjs';

import useStrings from '../../../../hooks/useStrings';
import { buildHandlers } from '../../../../components/Common/Table/handlers';

import Pagination from '../../../../components/Common/Table/Pagination';

import { TableJssOverrides, NoRecordsText } from '../../../../components/Common/Table/Styles';

import { ReferralsTableProps } from './types';
import { TableColumn } from '../../../../components/Common/Table/types';
import { OrderDirection, APIFilterOptions } from '../../../../lib/types/API';
import { CustomerReferral } from '../../../../lib/types/DBModels';

const ReferralsTable: React.FC<ReferralsTableProps<APIFilterOptions>> = ({
  currentData,
  recordCount,
  filter,
  updateFilter,
  clearFilters,
}) => {
  const [STRINGS] = useStrings();

  const {
    handlePageChange,
    handleSortChange,
  } = buildHandlers<APIFilterOptions>(
    updateFilter,
    filter,
    undefined,
    undefined,
    clearFilters,
  );

  const noRecords = (
    <NoRecordsText>
      <p>{STRINGS.Components.Common.Table.noRecords}</p>
    </NoRecordsText>
  );

  const columns: TableColumn<CustomerReferral>[] = [
    {
      name: 'User Name',
      selector: 'firstName',
      sortable: true,
      grow: 1,
      format: (row) => `${row.firstName} ${row.lastName}`,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      grow: 1,
      format: (row) => row.email?.toString() || '',
    },
    {
      name: 'Date invited',
      selector: 'createdAt',
      sortable: true,
      grow: 1,
      format: (row) => dayjs(row.createdAt as Date).format('DD/MM/YYYY'),
    },
    {
      name: 'Joined',
      selector: 'refereeUserId',
      sortable: true,
      grow: 1,
      format: (row) => (row.refereeUserId ? STRINGS.GenericText.yes : STRINGS.GenericText.no),
    },
  ];

  return (
    <DataTable
      subHeaderAlign="left"
      highlightOnHover
      noContextMenu
      noHeader
      data={currentData}
      keyField="key"
      columns={columns}
      pagination
      paginationServer
      paginationPerPage={filter.rowsPerPage}
      paginationTotalRows={recordCount}
      paginationDefaultPage={filter.page}
      onChangePage={handlePageChange}
      paginationComponent={Pagination}
      onSort={handleSortChange}
      sortServer
      defaultSortField={filter.orderColumn}
      defaultSortAsc={filter.orderDirection === OrderDirection.ASC}
      noDataComponent={noRecords}
      customStyles={TableJssOverrides}
      style={{ borderRadius: 0 }}
    />
  );
};

export default ReferralsTable;
