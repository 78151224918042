import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';

import CalculatorButton from '../CalculatorButton';
import RangeSlider from '../RangeSlider';

import {
  Container,
  StyledRow,
  StyledDatePicker,
  StyledHeading3,
  StyledLink,
  StyledResult,
  AdminFee,
} from './Styles';

import { calculateCustomerPlanPaymentData } from '../../../lib/utils';
import useStrings from '../../../hooks/useStrings';

import { CalculatorProps } from './types';
import { InstallmentFrequency } from '../../../lib/types/Plan';
import { INSTALLMENT_FREQUENCY_ABBR } from '../../../CONSTANTS';

// This component was originally build by a contractor to be hosted separetly.
// Because of time constraints we have added to main app, and will be iFraming it to the
// promo site from here.

// LEAVING FOLLOWING CONSTS HERE AS THEY ARE SPECIFIC ONLY TO HOW THIS HAS BEEN IMPLEMENTED
// TODO: Update to be more in line with style and logic of rest of repo

export const REGISTRATION_TERM = [
  { label: '3 months', value: '3' },
  { label: '6 months', value: '6' },
  { label: '12 months', value: '12' },
];

export const INSTALLMENT_FREQUENCY_DATA = [
  {
    value: InstallmentFrequency.WEEK,
    label: 'weekly',
    suffix: INSTALLMENT_FREQUENCY_ABBR[InstallmentFrequency.WEEK],
  },
  {
    value: InstallmentFrequency.FORTNIGHT,
    label: 'fortnightly',
    suffix: INSTALLMENT_FREQUENCY_ABBR[InstallmentFrequency.FORTNIGHT],
  },
  {
    value: InstallmentFrequency.MONTH,
    label: 'monthly',
    suffix: INSTALLMENT_FREQUENCY_ABBR[InstallmentFrequency.MONTH],
  },
];

export const DEFAULT_REGISTRATION_AMOUNT = 850;
export const DEFAULT_REGISTRATION_DURATION = REGISTRATION_TERM[2].value;
export const DEFAULT_INSTALLMENT_FREQUENCY = INSTALLMENT_FREQUENCY_DATA[0].value;

const Calculator: React.FC<CalculatorProps> = () => {
  const [{ Pages: { Customer: { PromoCalculator: Strings } } }] = useStrings();

  const [amount, setAmount] = useState(DEFAULT_REGISTRATION_AMOUNT);
  const [term, setTerm] = useState(DEFAULT_REGISTRATION_DURATION);
  const [startDate, setStartDate] = useState<Date>();
  const [dueDate, setDueDate] = useState<Date>();
  const [installmentFrequency, setInstallmentFrequency] = useState(DEFAULT_INSTALLMENT_FREQUENCY);
  const [paymentSuffix, setPaymentSuffix] = useState(INSTALLMENT_FREQUENCY_DATA[0].suffix);

  const installmentData = calculateCustomerPlanPaymentData({
    startDate,
    dueDate,
    installmentFrequency: installmentFrequency.toString(),
    totalPlanValue: amount,
  });

  const installmentFrequencyOptions = dayjs(dueDate).diff(dayjs(startDate).add(1, 'day'), 'weeks') >= 7
    ? INSTALLMENT_FREQUENCY_DATA
    : [INSTALLMENT_FREQUENCY_DATA[0], INSTALLMENT_FREQUENCY_DATA[1]];

  return (
    <Container>
      <StyledRow>
        <StyledHeading3 style={{ marginBottom: 70 }}>{Strings.planCost}</StyledHeading3>
        <RangeSlider
          defaultValue={DEFAULT_REGISTRATION_AMOUNT}
          onChange={(value) => setAmount(value)}
        />
      </StyledRow>
      <StyledRow>
        <StyledHeading3>{Strings.planLength}</StyledHeading3>
        <CalculatorButton
          data={REGISTRATION_TERM}
          onClick={(event) => setTerm(event.currentTarget.value)}
          defaultValue={term}
        />
      </StyledRow>
      <StyledRow flex>
        <StyledDatePicker>
          <StyledHeading3>{Strings.startDate}</StyledHeading3>
          <DatePicker
            selected={startDate}
            onChange={(date) => !Array.isArray(date) && !!date && setStartDate(date)}
            placeholderText="Please select"
            className="date-picker"
            dateFormat="MMMM d, yyyy"
            isClearable
            selectsRange={false}
          />
        </StyledDatePicker>
        <StyledDatePicker>
          <StyledHeading3>{Strings.dueDate}</StyledHeading3>
          <DatePicker
            selected={dueDate}
            onChange={(date) => !Array.isArray(date) && !!date && setDueDate(date)}
            placeholderText="Please select"
            className="date-picker"
            dateFormat="MMMM d, yyyy"
            minDate={dayjs(startDate).add(1, 'month').toDate()}
            isClearable
            selectsRange={false}
          />
        </StyledDatePicker>
      </StyledRow>
      <StyledRow>
        <StyledHeading3>{Strings.frequency}</StyledHeading3>
        <CalculatorButton
          data={(startDate && dueDate)
            ? installmentFrequencyOptions
            : INSTALLMENT_FREQUENCY_DATA}
          onClick={(event, suffix) => {
            if (suffix) setPaymentSuffix(suffix);
            setInstallmentFrequency(event.currentTarget.value as InstallmentFrequency);
          }}
          defaultValue={installmentFrequency}
        />
      </StyledRow>
      <StyledRow>
        <StyledHeading3>{Strings.resultHeading}</StyledHeading3>
        <StyledResult>
          <p>
            {dueDate && installmentData
              ? installmentData?.installmentAmount.toFixed(2)
              : (0).toFixed(2)}
            <span>{paymentSuffix}</span>
          </p>
          <StyledLink target="_parent" href="/sign-up">{Strings.buttonText}</StyledLink>
        </StyledResult>
        <AdminFee>{installmentData && Strings.adminFee.replace('%', installmentData?.fee.toString())}</AdminFee>
      </StyledRow>
    </Container>
  );
};

export default Calculator;
