import { PlanDataView } from '../../../../types/DBViews';

export const SET_ALL_PLAN_DATA = 'SET_ALL_PLAN_DATA';
export const SET_UPDATED_PLAN_DATA = 'SET_UPDATED_PLAN_DATA';

export interface PlanState {
  planData: {
    plans: PlanDataView[];
    count: number;
  }
}
export interface SetAllPlansAction {
  type: typeof SET_ALL_PLAN_DATA;
  planData: {
    results: PlanDataView[];
    count: number;
  }
}

export interface SetUpdatedPlansAction {
  type: typeof SET_UPDATED_PLAN_DATA;
  plans: PlanDataView[],
}

export type PlanActionTypes =
  SetAllPlansAction |
  SetUpdatedPlansAction;
