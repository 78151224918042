import {
  StatsAmountCollected,
  StatsPlanType,
  StatsLocation,
  StatsCustomerStatusTotal,
  StatsStatus,
} from '../../../../types/Statistics';

export const SET_PLAN_PAYMENTS_TOTAL = 'SET_PLAN_PAYMENTS_TOTAL';
export const SET_PLAN_FEES_TOTAL = 'SET_PLAN_FEES_TOTAL';
export const SET_CUSTOMERS_TOTAL = 'SET_CUSTOMERS_TOTAL';
export const SET_PLANS_TOTAL = 'SET_PLANS_TOTAL';
export const SET_AMOUNT_COLLECTED = 'SET_AMOUNT_COLLECTED';
export const SET_CUSTOMER_STATUS_TOTAL = 'SET_CUSTOMER_STATUS_TOTAL';
export const SET_PLAN_TYPES = 'SET_PLAN_TYPES';
export const SET_CUSTOMER_LOCATIONS = 'SET_CUSTOMER_LOCATIONS';
export const SET_CUSTOMER_STATUSES = 'SET_CUSTOMER_STATUSES';

export interface StatisticsState {
  planPaymentsTotal: number;
  planFeesTotal: number;
  customersTotal: number;
  plansTotal: number;
  amountCollected: StatsAmountCollected[];
  customerStatusTotal: StatsCustomerStatusTotal[];
  planTypes: StatsPlanType[];
  customerLocations: StatsLocation[];
  customerStatuses: StatsStatus[];
}

export interface SetPlanPaymentsTotalAction {
  type: typeof SET_PLAN_PAYMENTS_TOTAL;
  amount: number;
}

export interface SetPlanFeesTotalAction {
  type: typeof SET_PLAN_FEES_TOTAL;
  amount: number;
}

export interface SetCustomersTotalAction {
  type: typeof SET_CUSTOMERS_TOTAL;
  amount: number;
}

export interface SetPlansTotalAction {
  type: typeof SET_PLANS_TOTAL;
  amount: number;
}

export interface SetAmountCollectedAction {
  type: typeof SET_AMOUNT_COLLECTED;
  amountCollected: StatsAmountCollected[];
}

export interface SetCustomerStatusTotalAction {
  type: typeof SET_CUSTOMER_STATUS_TOTAL;
  customerStatusTotal: StatsCustomerStatusTotal[];
}

export interface SetPlanTypesAction {
  type: typeof SET_PLAN_TYPES;
  planTypes: StatsPlanType[];
}

export interface SetCustomerLocationsAction {
  type: typeof SET_CUSTOMER_LOCATIONS;
  customerLocations: StatsLocation[];
}

export interface SetCustomerStatusesAction {
  type: typeof SET_CUSTOMER_STATUSES;
  customerStatuses: StatsStatus[];
}

export type StatisticsActionTypes =
  SetPlanPaymentsTotalAction
  | SetPlanFeesTotalAction
  | SetCustomersTotalAction
  | SetPlansTotalAction
  | SetAmountCollectedAction
  | SetCustomerStatusTotalAction
  | SetPlanTypesAction
  | SetCustomerLocationsAction
  | SetCustomerStatusesAction;
