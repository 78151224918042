import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
  Toast,
  Wrapper,
  Label,
} from './Styles';

import { ToastNotificationProps } from './types';

const ToastNotification: React.FC<ToastNotificationProps> = ({
  variant = 'success',
  message,
  duration = 3,
  onDismiss,
}) => {
  let interval: NodeJS.Timeout;
  const dispatch = useDispatch();
  const toastRef = useRef<HTMLDivElement>(null);
  const [className, setClassName] = useState<'show' | 'hide'>('show');

  const dismissNotification = () => {
    setClassName('hide');
    toastRef.current?.addEventListener('animationend', () => {
      dispatch(onDismiss());
    });
  };

  const onClick = () => {
    clearTimeout(interval);
    dismissNotification();
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    interval = setTimeout(() => {
      dismissNotification();
    }, duration * 1000);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  return (
    <Toast ref={toastRef} className={className} variant={variant} onClick={onClick}>
      <Wrapper>
        <Label>{message}</Label>
      </Wrapper>
    </Toast>
  );
};

export default ToastNotification;
