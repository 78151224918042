import styled, { css } from 'styled-components/macro';

import { GridSizes } from '../../../style/core/Grid';

import { GridProps } from './types';

const getWidthStyling = (size: GridSizes) => css`
  flex: 0 0 ${(100 / 12) * size}%; // Formula for col width is 100% width / num cols (in this case 12) * the size you want (the number prop passed through)
  max-width: ${(100 / 12) * size}%;
`;

export const Row = styled.div<GridProps>`
  display: flex;
  flex-flow: row wrap;
  max-width: ${({ maxWidth }) => (`${maxWidth}` || '75rem')};
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop}` : '')};
  ${({ expanded }) => (expanded ? 'max-width: none' : '')};
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : '')};
  ${({ justify }) => (justify ? `justify-content: ${justify}` : '')};
`;

export const Column = styled.div<GridProps>`
  display: flex;
  flex-flow: column;
  flex: 1 1 0px;
  padding-left: ${({ expandedMobile }) => (expandedMobile ? 0 : '0.9375rem')};
  padding-right: ${({ expandedMobile }) => (expandedMobile ? 0 : '0.9375rem')};
  box-sizing: border-box;

  & > ${Row} {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }

  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop}` : '')};
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : '')};
  ${({ justify }) => (justify ? `justify-content: ${justify}` : '')};

  ${({ sm }) => (sm ? getWidthStyling(sm) : '')};

  ${({ theme }) => theme.device.tablet} {
    ${({ md }) => (md ? getWidthStyling(md) : '')};
    padding-left: ${({ expanded }) => (expanded ? 0 : '0.9375rem')};
    padding-right: ${({ expanded }) => (expanded ? 0 : '0.9375rem')};
  }

  ${({ theme }) => theme.device.laptop} {
    ${({ lg }) => (lg ? getWidthStyling(lg) : '')};
  }
`;
