import styled from 'styled-components/macro';

import { MainContentProps, BackgroundImageProps, BackgroundTextProps } from './types';

export const SideContent = styled.aside`
  background-color: ${({ theme }) => theme.palette.primary};
  display: flex;
  height: auto;
  position: relative;
  width: 100vw;
  
  ${({ theme }) => theme.device.tablet} {
    width: 50vw;
    height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  ${({ theme }) => theme.device.desktop} {
    width: 60vw;
  }
`;

export const BackgroundImage = styled.div<BackgroundImageProps>`
  background: url(${({ backgroundImage }) => backgroundImage}) center no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
`;

export const BackgroundTextWrap = styled.div`
  background: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing.M}rem;
  max-width: 600px;
  box-sizing: content-box;
`;

export const BackgroundText = styled.p<BackgroundTextProps>`
  color: ${({ theme, highlight }) => (highlight ? theme.palette.titleHighlight : theme.basePalette.white)};
  font-size: ${({ theme, small }) => (small ? `${theme.fontScale.S}rem` : '60px')};
  display: inline;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  line-height: 60px;
`;

export const BackgroundTextSmall = styled.p`
  color: ${({ theme }) => theme.basePalette.white};
  font-weight: ${({ theme }) => theme.fontWeight.extraLight};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  margin-top: ${({ theme }) => theme.spacing.XS / 1.4}rem;
  font-size: 1.3rem;
`;

export const MainContent = styled.main<MainContentProps>`
  flex-direction: column;
  display: flex;
  height: 100%;
  margin-top: 0;
  border-radius: .5rem;
  background-color: ${({ theme }) => theme.basePalette.white};
  z-index: 1;
  align-items: center;
  -webkit-overflow-scrolling: touch;

  ${({ theme }) => theme.device.tablet} {
    margin: ${({ margin }) => margin || '-2rem 0'};
    border-radius: 0;
    height: auto;
    flex: 1;
    overflow-y: auto;
  }
`;

export const MainContentInner = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    padding: 1rem 1rem 0;
  }

  ${({ theme }) => theme.device.laptop} {
    padding: 2rem 2rem 0;
  }
`;

export const LogoWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.XS}rem;
  width: 50%;

  ${({ theme }) => theme.device.laptop} {
    margin-top: ${({ theme }) => theme.spacing.S}rem;
  }
`;

export const BottomContent = styled.div`
  border-top: 1px solid ${({ theme }) => theme.basePalette.greyLight};
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.XS}rem;
  width: 100%;
`;

export const BottomContentText = styled.p`
  color: ${({ theme }) => theme.basePalette.navyMain};
  display: block;
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  margin-right: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const BottomContentLink = styled.a`
  color: ${({ theme }) => theme.basePalette.blueMain};
  cursor: pointer;
  text-decoration: none;
`;
