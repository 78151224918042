import React from 'react';
import { useLocation } from 'react-router';
import qs from 'qs';

import useStrings from '../../hooks/useStrings';

import LoginLayout from '../../layouts/LoginLayout';
import LoginForm from '../../components/UI/LoginForm';

import { adminLoginBg, customerLoginBg } from '../../assets/Images';

import { LoginProps } from './types';

const Login: React.FC<LoginProps> = ({ variant }) => {
  const [{ Pages: { Login: Strings } }] = useStrings();
  const location = useLocation();

  const isSignup = variant === 'customer-signup';

  // If this user is following a referral link
  const { referralId, email }: {
    referralId?: string,
    email?: string,
  } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const generateContent = () => {
    switch (variant) {
      case 'admin-login':
        return {
          backgroundImage: adminLoginBg,
          title: Strings.adminLoginTitle,
          subtitle: Strings.loginSubtitle,
        };
      case 'customer-login':
        return {
          backgroundImage: customerLoginBg,
          title: Strings.loginTitle,
          subtitle: Strings.loginSubtitle,
          titleHighlight: Strings.loginHighlight,
        };
      case 'customer-signup': default:
        return {
          backgroundImage: adminLoginBg,
          title: Strings.signupTitle,
          subtitle: Strings.signupSubtitle,
          titleHighlight: Strings.signupHighlight,
        };
    }
  };

  const { backgroundImage, title, subtitle, titleHighlight } = generateContent();

  return (
    <LoginLayout
      backgroundImage={backgroundImage}
      loginVariant={variant}
    >
      <LoginForm
        title={title}
        titleHighlight={titleHighlight}
        subtitle={subtitle}
        showTermsCheckbox={isSignup}
        isSignup={isSignup}
        referralId={referralId}
        email={email}
      />
    </LoginLayout>
  );
};

export default Login;
