import React, { useState, useRef } from 'react';

import {
  Container,
  MenuContainer,
  MenuToggle,
  MenuOptions,
  MenuItem,
  MenuItemIcon,
} from './Styles';

import { ContextMenuProps } from './types';

import useStrings from '../../../hooks/useStrings';
import useClickOutside from '../../../hooks/useClickOutside';
import {
  ellipsesIcon,
  DeleteIcon,
  RejectIcon,
  SkipIcon,
} from '../../../assets/Icons';

import { InstallmentStatus } from '../../../lib/types/Plan';

const ContextMenu: React.FC<ContextMenuProps> = ({
  forPaymentTable,
  installmentStatus = null,
  onDeferClick,
  forPlanTable,
  onDeleteClick,
  forProfileHeader,
  disabled,
}) => {
  const [{ Components: { Common: { ContextMenu: Strings } } }] = useStrings();
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState<{ x: Coordinate; y: Coordinate }>({ x: null, y: null });
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => setIsOpen(false));

  const handleToggle = (clickEvent: React.MouseEvent) => {
    setPosition({ x: clickEvent.pageX, y: clickEvent.pageY });
    setIsOpen(!isOpen);
  };

  const positionStyle = {
    top: `${position.y}px`,
    left: `${position.x}px`,
  };

  return (
    <Container ref={ref}>
      <MenuContainer isOpen={isOpen} style={positionStyle}>
        <MenuOptions>
          {forPaymentTable && onDeferClick && (
            <>
              {installmentStatus === InstallmentStatus.SKIPPED ? (
                <>
                  <MenuItem
                    onClick={() => {
                      onDeferClick(InstallmentStatus.SKIPPED);
                      setIsOpen(false);
                    }}
                  >
                    <MenuItemIcon src={SkipIcon} />
                    {Strings.reverseSkippedPayment}
                  </MenuItem>
                </>
              ) : (
                <>
                  <MenuItem
                    onClick={() => {
                      onDeferClick(InstallmentStatus.REJECTED);
                      setIsOpen(false);
                    }}
                  >
                    <MenuItemIcon src={RejectIcon} />
                    {Strings.rejectPayment}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      onDeferClick(InstallmentStatus.SKIPPED);
                      setIsOpen(false);
                    }}
                  >
                    <MenuItemIcon src={SkipIcon} />
                    {Strings.skipPayment}
                  </MenuItem>
                </>
              )}
            </>
          )}
          {(forPlanTable || forProfileHeader) && onDeleteClick && (
            <>
              <MenuItem
                onClick={() => {
                  onDeleteClick();
                  setIsOpen(false);
                }}
              >
                <MenuItemIcon src={DeleteIcon} />
                {forPlanTable ? Strings.deletePlan : Strings.deleteUser}
              </MenuItem>
            </>
          )}
          {/* <>
              <MenuItem>
                <MenuItemIcon src={DeleteIcon} />
                {Strings.deleteUser}
              </MenuItem>
              <MenuItem>
                <MenuItemIcon src={OnOffIcon} />
                {Strings.deactivateUser}
              </MenuItem>
              <MenuItem>
                <MenuItemIcon src={ClockIcon} />
                {Strings.putOnHold}
              </MenuItem>
              <MenuItem>
                <MenuItemIcon src={PauseIcon} />
                {Strings.suspendUser}
              </MenuItem>
            </> */}

        </MenuOptions>
      </MenuContainer>
      <MenuToggle
        src={ellipsesIcon}
        onClick={(e) => !disabled && handleToggle(e)}
        disabled={disabled}
      />
    </Container>
  );
};

type Coordinate = number | null;

export default ContextMenu;
