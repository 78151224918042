import React, { useState } from 'react';
import DatePicker from 'react-date-picker';

import {
  StyledDatePicker,
  MetaWrapper,
  Icon,
  Label,
  Error,
} from './Styles';
import { blueCaretDown } from '../../../assets/Icons';

import { TableDatePickerProps } from './types';

const TableDatePickerComponent: React.FC<TableDatePickerProps> = ({
  onLoadDate,
  label,
  disabled = false,
  minDate,
  maxDate,
  touched,
  error,
  margin,
  onSelect,
  setError,
  setTouched,
  selectRange = false,
  outlined = false,
  yearOnly = false,
  inTable = false,
}) => {
  const [selected, setSelected] = useState<Date| undefined>(onLoadDate && new Date(onLoadDate));
  const [open, setOpen] = useState<boolean>(false);
  const handleChange = (date: Date | Date[]) => {
    const val = Array.isArray(date) ? date[0] : date;
    setSelected(val);
    onSelect(val);
    if (setError) setError();
    if (setTouched) setTouched(true);
  };

  return (
    // Formik thinks this is a date type?
    <StyledDatePicker
      error={!!error && !!touched}
      disabled={disabled}
      open={open}
      onClick={() => {
        if (!open) setOpen(true);
      }}
      margin={margin}
      outlined={outlined}
      dateSelected={!!selected}
      inTable={inTable}
    >
      {(label || error) && (
      <MetaWrapper>
        <Label>{label}</Label>
      </MetaWrapper>
      )}
      <DatePicker
        value={selected}
        clearIcon={null}
        disabled={disabled}
        calendarIcon={!inTable ? <Icon disabled={disabled} open={open} src={blueCaretDown} /> : null}
        onChange={handleChange}
        onCalendarOpen={() => setOpen(true)}
        onCalendarClose={() => setOpen(false)}
        minDate={minDate}
        maxDate={maxDate}
        monthPlaceholder={yearOnly ? '' : 'MM'}
        dayPlaceholder={yearOnly ? '' : 'DD'}
        yearPlaceholder="YYYY"
        format={yearOnly ? 'y' : 'dd/MM/y'}
        maxDetail={yearOnly ? 'decade' : 'month'}
        selectRange={selectRange}
      />
      {touched && error && <Error className="error">{error}</Error>}
    </StyledDatePicker>
  );
};

export default TableDatePickerComponent;
