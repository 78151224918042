import React from 'react';

import useStrings from '../../../../../hooks/useStrings';
import { generateDropdownLabel } from '../../../../../lib/utils/styleUtils';
import { planTypeOptions, installmentStatusOptions } from '../../../../../CONSTANTS';

import Grid from '../../../../../components/Common/Grid';
import Button from '../../../../../components/Common/Button';
import TableFilterDropdown from '../../../../../components/Common/Table/SubComponents/TableFilterDropdown';
import TableFilterDateRange from '../../../../../components/Common/Table/SubComponents/TableFilterDateRange';
import TableFilterRangeInput from '../../../../../components/Common/Table/SubComponents/TableFilterRangeInput';

import { filterIcon, searchIcon } from '../../../../../assets/Icons';
import { IconWrapper, StyledFilterIcon, FilterMenuWrapper, StyledInput } from '../../../../../components/Common/Table/Styles';
import { LineBreak } from '../../../CustomerProfile/Styles';

import { SubHeaderProps } from './types';

const SubHeader: React.FC<SubHeaderProps> = ({
  searchTerm,
  customFiltersOpen,
  filter,
  planValueMin,
  planValueMax,
  installmentValueMin,
  installmentValueMax,
  onStatusChange,
  onPlanTypeChange,
  onUpdateDueDate,
  onUpdatePaidDate,
  onUpdatePlanValue,
  onUpdateInstallmentValue,
  onSearchChange,
  onOpenCustomFilters,
  onApplyCustomFilters,
  onClearCustomFilters,
}) => {
  const [{ GenericText, Pages: { Payments } }] = useStrings();

  const customFilters = () => (
    <>
      <TableFilterDateRange
        heading={Payments.dueDateFilterHeading}
        currentMin={filter.minDueDate}
        currentMax={filter.maxDueDate}
        handleUpdate={onUpdateDueDate}
      />
      <TableFilterDateRange
        heading={Payments.paidDateFilterHeading}
        currentMin={filter.minPaidDate}
        currentMax={filter.maxPaidDate}
        handleUpdate={onUpdatePaidDate}
      />

      <TableFilterRangeInput
        heading={Payments.planValueFilterHeading}
        min={planValueMin}
        max={planValueMax}
        currentMin={filter.planMinValue || planValueMin}
        currentMax={filter.planMaxValue || planValueMax}
        handleUpdate={onUpdatePlanValue}
      />
      <TableFilterRangeInput
        heading={Payments.installmentValueFilterHeading}
        min={installmentValueMin}
        max={installmentValueMax}
        currentMin={filter.installmentMinValue || installmentValueMin}
        currentMax={filter.installmentMaxValue || installmentValueMax}
        handleUpdate={onUpdateInstallmentValue}
      />
    </>
  );

  return (
    <Grid row>
      <IconWrapper marginRight>
        <img alt="search" src={searchIcon} />
        <StyledInput type="text" value={searchTerm} onChange={onSearchChange} />
      </IconWrapper>
      <TableFilterDropdown
        name="planType"
        options={planTypeOptions}
        selectedOptions={filter.planType || []}
        overrideLabel={generateDropdownLabel(Payments.typeFilterDropdownLabel, planTypeOptions.filter(
          (option) => filter.planType?.some((filtered) => filtered.toString() === option.value),
        ))}
        placeholder={Payments.typeFilterDropdownLabel}
        onOptionClick={onPlanTypeChange}
      />
      <TableFilterDropdown
        name="status"
        options={installmentStatusOptions}
        selectedOptions={filter.status || []}
        overrideLabel={generateDropdownLabel(Payments.statusFilterDropdownLabel, installmentStatusOptions.filter(
          (option) => filter.status?.some((filtered) => filtered.toString() === option.value),
        ))}
        placeholder={Payments.statusFilterDropdownLabel}
        onOptionClick={onStatusChange}
      />
      <IconWrapper onClick={onOpenCustomFilters} active={customFiltersOpen}>
        <StyledFilterIcon alt="search" src={filterIcon} active={customFiltersOpen} />
        {customFiltersOpen && (
        <FilterMenuWrapper onClick={(e) => { e.stopPropagation(); }}>
          {customFilters()}
          <LineBreak />
          <Grid>
            <Grid row alignItems="center" maxWidth="100%" marginTop="1.5rem">
              <Grid column sm={6}>
                <Button
                  variant="secondary"
                  onClick={onClearCustomFilters}
                  width="170px"
                  rounded
                >
                  {GenericText.clear}
                </Button>
              </Grid>
              <Grid column sm={6}>
                <Button
                  variant="primary"
                  onClick={onApplyCustomFilters}
                  width="170px"
                  rounded
                >
                  {GenericText.apply}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </FilterMenuWrapper>
        )}
      </IconWrapper>
    </Grid>
  );
};

export default SubHeader;
