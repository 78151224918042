import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import useStrings from '../../../hooks/useStrings';

import { DocPreviewIcon, ImagePreviewIcon } from '../../../assets/Icons';
import {
  StyledFileUploader,
  IconWrapper,
  Icon,
  IconDivider,
  TypeText,
  UploadText,
  Error,
  PlaceholderWrapper,
  ValuePlaceholder,
} from './Styles';

import { FileInputProps } from './types';

const FileUploader: React.FC<FileInputProps> = ({
  value,
  maxImageSize = 5000000, // 5MB
  parentError,
  onFileUploadComplete,
  onFileUploadError,
}) => {
  const [{ Components: { Common: { FileUploader: Strings } } }] = useStrings();
  const [uploadError, setUploadError] = useState('');

  const handleDropAccepted = (files: File[]) => {
    setUploadError('');
    const file = files[0];
    onFileUploadComplete(file);
  };

  const handleDropRejected = () => {
    setUploadError(Strings.uploadError);
    if (onFileUploadError) onFileUploadError(Strings.uploadError);
  };

  const {
    getRootProps,
    getInputProps,
    isDragReject,
  } = useDropzone({
    multiple: false,
    maxSize: maxImageSize,
    accept: '.jpeg, .png, .pdf, .jpg',
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,

  });

  useEffect(() => {
    if (parentError) setUploadError(parentError);
  }, [parentError]);

  const fileName = () => (value && value.name.length < 10
    ? value.name
    : `${value?.name.slice(0, 20)}... .${value?.type.split('/')[1]}`);

  return (
    <StyledFileUploader
      {...getRootProps({ isDragReject })}
      error={!!uploadError}
      hasValue={!!value}
    >
      {!value ? (
        <>
          {uploadError && <Error className="error">{uploadError}</Error>}
          <input {...getInputProps()} />
          <IconWrapper>
            <Icon src={DocPreviewIcon} />
            <IconDivider />
            <Icon src={ImagePreviewIcon} />
          </IconWrapper>
          <TypeText>{Strings.typeText}</TypeText>
          <UploadText error={!!uploadError}>{Strings.uploadText}</UploadText>
        </>
      ) : (
        <>
          {uploadError && <Error className="error">{uploadError}</Error>}
          <PlaceholderWrapper>
            <input {...getInputProps()} />
            <Icon src={DocPreviewIcon} />
            <ValuePlaceholder>
              {fileName()}
            </ValuePlaceholder>
          </PlaceholderWrapper>
        </>
      ) }
    </StyledFileUploader>
  );
};

export default FileUploader;
