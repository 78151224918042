import {
  SET_ALL_PLAN_DATA,
  PlanActionTypes,
  PlansPaginatedState,
} from './types';

const initialState: PlansPaginatedState = {
  planData: {
    plans: [],
    count: 0,
  },
};

const plansPaginatedReducer = (
  state: PlansPaginatedState = initialState,
  action: PlanActionTypes,
): PlansPaginatedState => {
  switch (action.type) {
    case SET_ALL_PLAN_DATA:
      return {
        ...state,
        planData: {
          plans: action.planData.results,
          count: action.planData.count,
        },
      };
    default:
      return state;
  }
};

export default plansPaginatedReducer;
