import React, { useState } from 'react';
import { ReactSliderProps } from 'react-slider';

import Grid from '../../Grid';

import { TableFilterRangeInputProps } from '../types';
import {
  CustomFilterHeading,
  StyledSlider,
  StyledThumb,
  StyledThumbText,
  StyledTrack,
} from './Styles';

const TableFilterRangeInput: React.FC<TableFilterRangeInputProps> = ({
  heading,
  min,
  max,
  currentMin,
  currentMax,
  handleUpdate,
}) => {
  const [thumbVals, setThumbVals] = useState<number[]>([Math.floor(currentMin), Math.ceil(currentMax)]);
  const [thumbShift, setThumbShift] = useState<{
    shiftBoth?: number, shiftLeft?: number, shiftRight?: number,
  }>({
    shiftBoth: undefined,
    shiftLeft: undefined,
    shiftRight: undefined,
  });

  const shiftLimit = 10; // %
  const shiftFactorLeft = 8; // px per char
  const shiftFactorRight = 3; // px per char
  const minShift = 5; // px

  const updateThumb = (val: number | number[] | null | undefined) => {
    if (Array.isArray(val)) {
      setThumbVals(val);
      handleUpdate(val);

      const minValue = Math.floor(min);
      const maxValue = Math.ceil(max);
      const maxDifference = maxValue - minValue;
      const valueDifference = val[1] - val[0];
      const percentageDiff = (valueDifference / maxDifference) * 100;

      // Move the label according to the number of chars
      const shiftLeft = (val[0].toString().length * shiftFactorLeft) + minShift;
      const shiftRight = (val[1].toString().length * shiftFactorRight) + minShift;
      const shiftBoth = Math.min(shiftLeft, shiftRight);

      // Thumbs are close together, move labels.
      if (percentageDiff < shiftLimit) {
        // bounds = value where label wont move, if close to end
        // ie minValue of 10, shiftLimit 10%, left label wont shift left until after 11.
        const leftBound = minValue + (minValue * (shiftLimit / 100));
        const rightBound = maxValue - (maxValue * (shiftLimit / 100));
        // Check left (min) bound/label
        if (val[0] > leftBound && val[1] < rightBound) {
          setThumbShift({ ...thumbShift, shiftBoth });
          // Check right (max) bound/label
        } else if (val[0] > leftBound) {
          setThumbShift({ ...thumbShift, shiftLeft });
          // Check right (max) bound/label
        } else if (val[1] < rightBound) {
          setThumbShift({ ...thumbShift, shiftRight });
        }
      } else setThumbShift({ shiftBoth: undefined, shiftLeft: undefined, shiftRight: undefined });
    }
  };

  const Thumb = (props: unknown, state: { valueNow: number }) => (
    <StyledThumb {...props} {...thumbShift}>
      <StyledThumbText>{state.valueNow}</StyledThumbText>
    </StyledThumb>
  );
  const Track = (
    props: unknown, state: { index: number },
  ) => <StyledTrack {...props} index={state.index} />;

  const sliderProps: ReactSliderProps = {
    defaultValue: [Math.floor(min), Math.ceil(max)],
    value: thumbVals,
    min: Math.floor(min),
    max: Math.ceil(max),
    renderTrack: Track,
    renderThumb: Thumb,
    onChange: updateThumb,

  };

  if (typeof min !== 'number' && typeof max !== 'number') return <></>;
  return (
    <Grid>
      <Grid row maxWidth="100%" marginTop="1.5rem">
        <Grid column sm={12}>
          <CustomFilterHeading>{heading}</CustomFilterHeading>
          <StyledSlider {...sliderProps} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TableFilterRangeInput;
