import { NotificationOpts } from '../../../types/Notification';

export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';

export interface NotificationState {
  visible: boolean;
  options: NotificationOpts;
}

export interface SetNotificationAction {
  type: typeof SET_NOTIFICATION;
  options: NotificationOpts;
}

export interface DismissNotificationAction {
  type: typeof DISMISS_NOTIFICATION;
}

export type NotificationActionTypes = SetNotificationAction | DismissNotificationAction;
