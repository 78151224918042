import styled from 'styled-components';

export const StyledButtonGroup = styled.div`
  background-color: ${({ theme }) => theme.basePalette.navyLight};
  border-radius: 1.875rem;
  display: flex;
  justify-content: space-between;
`;

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.basePalette.navyLight};
  border: none;
  border-radius: 1.875rem;
  color: ${({ theme }) => theme.basePalette.white};
  cursor: pointer;
  flex: 1 1 auto;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  padding: 1rem 0.5rem;
  text-transform: capitalize;
  position: relative;

  ${({ theme }) => theme.device.tablet} {
    font-size: 1.25rem;
    padding: 1rem 2rem;
  }

  &.is-active {
    background-color: ${({ theme }) => theme.basePalette.blueMain};
    font-weight: 600;
    position: relative;
    font-family: ${({ theme }) => theme.fontFamily.body};
    z-index: 1;
  }
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.basePalette.greyMain};
  opacity: 0.3;
  height: 40%;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 1px
`;
