import { PlanType, RegistrationLength, InstallmentFrequency, Concession } from '../../../../types/Plan';
import { Vehicle } from '../../../../types/DBModels';
import { CustomerPlansListView } from '../../../../types/DBViews';

export const SET_CUSTOMER_PLANS = 'SET_CUSTOMER_PLANS';
export const ADD_CUSTOMER_PLAN = 'ADD_CUSTOMER_PLAN';
export const SET_NEW_PLAN_DATA = 'SET_NEW_PLAN_DATA';
export const RESET_NEW_PLAN_DATA = 'RESET_NEW_PLAN_DATA';

export interface NewPlan {
  type: PlanType;
  vehicle?: Partial<Vehicle>;
  registrationLength?: RegistrationLength;
  startDate: Date;
  endDate: Date;
  dueDate: Date;
  value: number;
  installmentFrequency: InstallmentFrequency;
  adminFee?: number;
  name?: string;
  concessionCard: Concession;
}

export interface PlansState {
  newPlanData: Partial<NewPlan> | null;
  plans: CustomerPlansListView[];
}

export interface SetCustomerPlanDataAction {
  type: typeof SET_CUSTOMER_PLANS;
  plans: CustomerPlansListView[];
}

export interface AddCustomerPlanAction {
  type: typeof ADD_CUSTOMER_PLAN;
  plan: CustomerPlansListView;
}

export interface SetNewPlanDataAction {
  type: typeof SET_NEW_PLAN_DATA;
  newPlanData: Partial<NewPlan>;
}

export interface ResetNewPlanDataAction {
  type: typeof RESET_NEW_PLAN_DATA;
  newPlanData: null;
}

export type PlansActionTypes =
  SetCustomerPlanDataAction
  | AddCustomerPlanAction
  | SetNewPlanDataAction
  | ResetNewPlanDataAction;
