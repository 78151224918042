import { InstallmentStatus } from '../types/Plan';
import { PaymentDataView } from '../types/DBViews';

/*
'Cache' updating tools
*/

export const setUpdatedRecordsInState = <T extends unknown>(key: string, state: T[], updated: T[]): T[] => {
  const newState = state;

  // Foreach updated entry
  updated.forEach((update) => {
    // Find the value in state if exists, set from 'updated'
    const index = state.findIndex((entry) => entry[key as keyof T] === update[key as keyof T]);
    if (index !== -1) newState[index] = update;
  });

  return newState;
};

export const removeRecordsFromState = <T extends unknown>(key: string, state: T[], removedIds: number[]): T[] => {
  const newState = state;

  // Foreach removed entry
  removedIds.forEach((remove) => {
    // Find the value in state if exists, remove.
    const index = state.findIndex((entry) => Number(entry[key as keyof T]) === remove);
    if (index !== -1) newState.splice(index, 1);
  });

  return newState;
};

export const updateInstallmentStatusInState = (
  installmentId: number,
  installmentStatus: InstallmentStatus,
  payments: PaymentDataView[],
): PaymentDataView[] => {
  const updatedPayments = payments.map((payment) => {
    if (payment.installmentId === installmentId) {
      return { ...payment, installmentStatus };
    }
    return payment;
  });
  return updatedPayments;
};
