import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../../../../lib/store';
import { getCustomerCreditHistory, getCustomerCredits, updateCustomerCredits } from '../../../../lib/api/admin/customers';
import { getTransferablePlans, transferPlanFunds } from '../../../../lib/api/admin/plans';

import CustomerCredits from './CustomerCredits';
import TransferCredits from './TransferCredits/TransferCredits';
import {
  ContentWrapper, LineBreak,
} from './Styles';

import { APIThunkDispatch } from '../../../../lib/types/API';
import { CreditProps } from './types';
import CustomerCreditHistory from './CreditHistory';

const Credits: React.FC<CreditProps> = ({
  customer,
}) => {
  const dispatch: APIThunkDispatch = useDispatch();

  const {
    transferablePlans: plans,
    totalCredit,
    adminUser,
    creditHistory,
  } = useSelector((state: ApplicationState) => ({
    transferablePlans: state.admin.selectedCustomerTransferablePlanState.transferablePlans,
    totalCredit: state.admin.selectedCustomerTotalCreditsState.totalCredits,
    creditHistory: state.admin.selectedCustomerCreditHistoryState.credits,
    adminUser: state.admin.adminUserState.adminUser,
  }));

  const fetchTransferablePlans = useCallback(() => {
    dispatch<void>(getTransferablePlans(customer.userId));
  }, [dispatch, customer.userId]);

  const fetchCreditHistory = useCallback(() => {
    dispatch<void>(getCustomerCreditHistory(customer.userId));
  }, [dispatch, customer.userId]);

  const fetchTotalCredits = useCallback(() => {
    dispatch<void>(getCustomerCredits(customer.userId));
  }, [dispatch, customer.userId]);

  useEffect(() => {
    fetchTransferablePlans();
    fetchTotalCredits();
    fetchCreditHistory();
  }, [fetchTransferablePlans, fetchTotalCredits, fetchCreditHistory]);

  const updateCredits = async (creditAmount: number) => {
    if (adminUser?.userId) {
      await dispatch(updateCustomerCredits(customer.userId, creditAmount, adminUser?.userId));
    }
  };

  const transferCreditToPlan = async (creditAmount: number, planTo: string) => {
    if (adminUser?.userId) {
      await dispatch(transferPlanFunds(adminUser?.userId, customer.customerId, null, Number(planTo), creditAmount));
    }
  };
  return (
    <ContentWrapper>
      <CustomerCredits customerCredit={totalCredit} onUpdateCredits={updateCredits} />
      <LineBreak />
      <TransferCredits customerCredit={totalCredit} transferablePlans={plans} onTransfer={transferCreditToPlan} />
      <LineBreak />
      <CustomerCreditHistory creditHistory={creditHistory} />
    </ContentWrapper>
  );
};

export default Credits;