import styled, { css, DefaultTheme } from 'styled-components/macro';

import { StyledDropdownProps } from './types';

const getBorderColour = (theme: DefaultTheme, disabled?: boolean, outlined?: boolean) => {
  if (disabled) return theme.palette.btnGrey;
  if (outlined) return theme.basePalette.greyMain;
  return theme.palette.btnMidGrey;
};

export const StyledDropdown = styled.div<StyledDropdownProps>`
  width: 100%;
  margin: 0.75rem 0;
  position: relative;
  & ~ & {
    margin: 5px 0px;
  }
  font-family: ${({ theme }) => theme.fontFamily.body};

  // react-select style overrides
  .react-select__control {
    border: 1px solid ${({ disabled, theme, outlined }) => getBorderColour(theme, disabled, outlined)};
    border-radius: ${({ outlined = false }) => (outlined ? '12px' : '8px')};
    cursor: text;
    height: 60px;
    padding-left: 0.4rem;

    &:hover {
      ${({ disabled, theme, outlined }) => !disabled && css`
        border: 1px solid ${theme.palette.borderBlue}};
        background-color: (${outlined ? 'theme.palette.bgPrimary' : 'theme.palette.btnMidGrey'})
      `};
    }
  }

  .react-select__control--menu-is-open {
    .react-select__indicators {
      transform: scaleY(-1);
    }
  }

  .react-select__placeholder {
    font-family: ${({ theme }) => theme.fontFamily.bodyLight};
    color: ${({ theme }) => theme.palette.grey30};
  }

  .react-select__indicators {
    cursor: pointer;
  }

  .react-select__indicator-separator {
    display: none;
  }
`;

export const IconWrapper = styled.div`
  padding: 0 0.9rem;
`;

export const Icon = styled.img`
  width: 12px;
`;
