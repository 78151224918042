import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import dayjs from 'dayjs';

import Grid from '../../Common/Grid';
import Input from '../../Common/Input';
import Button from '../../Common/Button';
import DatePicker from '../../Common/DatePicker';
import Toggle from '../../Common/Toggle';

import {
  FormSection,
  LineBreak,
  ButtonWrapper,
} from './Styles';

import useStrings from '../../../hooks/useStrings';
import { setNewPlanData } from '../../../lib/store/contexts/customer/plans/actions';
import fundDetailsSchema from '../../../lib/validators/fundDetailsSchema';
import { INSTALLMENT_FREQUENCY_DISPLAY, NUMBER_REGEX } from '../../../CONSTANTS';

import { NewPlan } from '../../../lib/store/contexts/customer/plans/types';
import { FundDetailsFormProps } from './types';
import { InstallmentFrequency } from '../../../lib/types/Plan';
import { roundNum } from '../../../lib/utils';

const FundDetailsForm: React.FC<FundDetailsFormProps> = ({ newPlanData, setPlanValues, onBack, onNext }) => {
  const [{ Components: { UI: { FundDetailsForm: Strings } }, GenericText }] = useStrings();
  const dispatch = useDispatch();

  const getFrequencyOptions = (startDate: Date, dueDate: Date) => {
    // Remove ONE_OFF_PAYMENT options from customer plan creation forms
    const preFilteredOptions = INSTALLMENT_FREQUENCY_DISPLAY;
    delete preFilteredOptions[InstallmentFrequency.ONE_OFF_PAYMENT];

    if (dayjs(dueDate).diff(dayjs(startDate).add(1, 'day'), 'weeks') >= 7) {
      return {
        options: preFilteredOptions,
        values: Object.keys(preFilteredOptions),
      };
    }
    const filteredOptions = Object.entries(preFilteredOptions)
      .filter((option) => option[0] !== InstallmentFrequency.MONTH);
    return {
      options: Object.fromEntries(filteredOptions),
      values: [InstallmentFrequency.WEEK, InstallmentFrequency.FORTNIGHT],
    };
  };

  return (
    <Formik
      initialValues={{
        value: newPlanData?.value || undefined as number | undefined,
        startDate: newPlanData?.startDate || undefined as Date | undefined,
        dueDate: newPlanData?.dueDate || undefined as Date | undefined,
        installmentFrequency: newPlanData?.installmentFrequency || 'WEEK',
        name: newPlanData?.name || '',
      }}
      validationSchema={fundDetailsSchema}
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        dispatch(setNewPlanData(values as NewPlan));
        onNext();
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        errors,
        values,
        setFieldError,
        setFieldTouched,
      }) => (
        <>
          {isSubmitting && errors && window.scrollTo(0, 0)}
          <FormSection>
            <Grid row marginTop="1rem">
              <Grid column sm={12} lg={6} alignItems="center">
                <Input
                  type="currency"
                  name="value"
                  label={Strings.costInput}
                  placeholder={Strings.costInput}
                  width="100%"
                  outlined
                  onBlur={((e) => {
                    if (NUMBER_REGEX.test(e.target.value)) {
                      setFieldValue('value', roundNum(parseFloat(e.target.value)));
                      setPlanValues('value', roundNum(parseFloat(e.target.value)));
                      setFieldError('value', undefined);
                    } else {
                      setFieldError('value', 'Must be a number');
                      setFieldTouched('value');
                      setFieldValue('value', '');
                    }
                  })}
                />
              </Grid>
              <Grid column sm={12} lg={6} alignItems="center">
                <Input
                  type="text"
                  name="name"
                  label={Strings.nameInput}
                  placeholder={Strings.namePlaceholder}
                  optional
                  width="100%"
                  outlined
                  onChange={(e) => {
                    setFieldValue('name', e.target.value);
                    setPlanValues('name', e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </FormSection>
          <Grid row marginTop="1rem">
            <LineBreak />
          </Grid>
          <FormSection>
            <Grid row marginTop="1rem">
              <Grid column sm={12} lg={6}>
                <DatePicker
                  label={Strings.startDate}
                  onLoadDate={newPlanData?.startDate}
                  outlined
                  error={errors.startDate}
                  touched={touched.startDate}
                  minDate={dayjs().toDate()}
                  onSelect={(val) => {
                    setFieldValue('startDate', val);
                    setPlanValues('startDate', val);
                  }}
                />
              </Grid>
              <Grid column sm={12} lg={6}>
                <DatePicker
                  label={Strings.dueDate}
                  onLoadDate={newPlanData?.dueDate}
                  outlined
                  error={errors.dueDate}
                  touched={touched.dueDate}
                  minDate={dayjs(values.startDate).add(1, 'month').add(1, 'day').toDate()}
                  setTouched={(touch) => setFieldTouched('dueDate', touch)}
                  onSelect={(val) => {
                    setFieldValue('dueDate', val);
                    setPlanValues('dueDate', val);
                  }}
                  disabled={!values.startDate}
                />
              </Grid>
            </Grid>
            <Grid row>
              <Grid column sm={12} alignItems="center">
                <Toggle
                  name="installmentFrequency"
                  placeholder={Strings.frequencySelect}
                  label={Strings.frequencySelect}
                  options={(values.startDate && values.dueDate)
                    ? getFrequencyOptions(values.startDate, values.dueDate).values
                    : Object.keys(INSTALLMENT_FREQUENCY_DISPLAY)
                      .filter((key) => key !== InstallmentFrequency.ONE_OFF_PAYMENT)}
                  optionsDisplay={(values.startDate && values.dueDate)
                    ? getFrequencyOptions(values.startDate, values.dueDate).options
                    : INSTALLMENT_FREQUENCY_DISPLAY}
                  onOptionClick={((option) => {
                    setFieldValue('installmentFrequency', option);
                    setPlanValues('installmentFrequency', option);
                  })}
                  onLoadOption={newPlanData?.installmentFrequency || InstallmentFrequency.WEEK}
                />
              </Grid>
            </Grid>
          </FormSection>
          <Grid row marginTop="1rem">
            <LineBreak />
          </Grid>
          <ButtonWrapper>
            <Button
              margin="0"
              width="160px"
              variant="outlined"
              onClick={onBack}
              disabled={isSubmitting}
              expandedMobile={false}
            >
              {GenericText.back}
            </Button>
            <Button
              margin="0"
              width="160px"
              onClick={handleSubmit}
              $loading={isSubmitting}
              disabled={isSubmitting}
              expandedMobile={false}
            >
              {GenericText.next}
            </Button>
          </ButtonWrapper>
        </>
      )}
    </Formik>
  );
};

export default FundDetailsForm;
