import styled from 'styled-components';

import {
  CalendarToggleProps,
  ContainerProps,
  TitleProps,
  SelectProps,
  LoaderProps,
} from './types';

export const Container = styled.div<ContainerProps>`
  background: ${({ theme }) => theme.palette.bgMain};
  border-radius: 25px;
  height: ${({ size }) => {
    if (size === 'medium') return '230px';
    if (size === 'large') return '350px';
    return '132px';
  }};
  padding: ${({ theme }) => theme.spacing.XS / 1.3}rem;
  width: 100%;

  -webkit-box-shadow: 0px 0px 200px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 200px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 200px 0px rgba(0,0,0,0.1);
`;

export const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`;

export const TopContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
`;

export const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  width: 100%;
`;

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: ${({ theme }) => -theme.spacing.XS / 1.3}rem;
  left: 0;
  right: 0;
`;

export const LoadingSpinner = styled.div<LoaderProps>`
  border: 7px solid ${({ theme }) => theme.palette.grey02};
  border-top: 7px solid ${({ theme }) => theme.basePalette.navyMain};
  border-radius: 50%;
  border-width: ${({ small }) => (small ? '4px' : '7px')};
  height: ${({ small }) => (small ? '30px' : '60px')};
  width: ${({ small }) => (small ? '30px' : '60px')};
  animation: spin .6s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const Title = styled.h6<TitleProps>`
  color: ${({ theme, large }) => (large ? theme.basePalette.navyMain : theme.basePalette.greyDark)};
  font-size: ${({ theme, large }) => (large
    ? `${theme.fontScale.M as number * 1.15}rem`
    : `${theme.fontScale.XS}rem;`
  )};
  font-family: ${({ theme, large }) => (large ? theme.fontFamily.bodyBold : theme.fontFamily.body)};
  cursor: default;
  white-space: nowrap;
  margin-right: 8px;
  // flex: 1;
`;

export const Icon = styled.img`
  margin-right: ${({ theme }) => theme.spacing.XXS / 3}rem;
`;

export const DropdownCalendar = styled.div`
  position: absolute;
  transform: translateX(-98%);
  z-index: 1;
`;

export const CalendarToggle = styled.img<CalendarToggleProps>`
  cursor: pointer;
  margin: 2px -5px 3px auto;
  padding: 5px;
  transform: ${({ inverted }) => (inverted ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const YearSelect = styled.select<SelectProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: ${({ icon }) => `url(${icon}) right no-repeat`};
  border: none;
  padding: 0 18px 0 0;
  cursor: pointer;
  text-align: right;
  outline: none;
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontScale.XS}rem;

  option {
    font-family: sans-serif;
    font-weight: 400;
  }
`;

export const EmojiIcon = styled.span`
  font-size: 18px;
`;

export const SelectedDates = styled.div`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
`;

export const SelectedDate = styled.p`
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  white-space: nowrap;
  margin-right: 2px;
`;
