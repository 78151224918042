import {
  SET_CUSTOMER_NOTE_DATA,
  UPDATE_CUSTOMER_NOTE,
  DELETE_CUSTOMER_NOTE,
  CREATE_CUSTOMER_NOTE,
  CustomerNoteActionTypes,
  CustomerNotesState,
  CLEAR_CUSTOMER_NOTE_DATA,
} from './types';

const initialState: CustomerNotesState = {
  customerNotes: [],
};

const CustomerNotesReducer = (
  state: CustomerNotesState = initialState,
  action: CustomerNoteActionTypes,
): CustomerNotesState => {
  switch (action.type) {
    case SET_CUSTOMER_NOTE_DATA:
      return {
        ...state,
        customerNotes: action.customerNotes,
      };
    case CREATE_CUSTOMER_NOTE:
      return {
        ...state,
        customerNotes: [...state.customerNotes, action.customerNote],
      };
    case UPDATE_CUSTOMER_NOTE:
      return {
        ...state,
        customerNotes: [...state.customerNotes.map(
          (note) => (note.noteId === action.customerNote.noteId ? action.customerNote : note),
        )],
      };
    case DELETE_CUSTOMER_NOTE:
      return {
        ...state,
        customerNotes: state.customerNotes.filter((note) => note.noteId !== action.customerNoteId),
      };
    case CLEAR_CUSTOMER_NOTE_DATA:
      return {
        ...state,
        customerNotes: [],
      };
    default:
      return state;
  }
};

export default CustomerNotesReducer;
