import { apiFetch, apiRequest } from '../Utils';

import { serverCustomerAPI } from '../../../CONSTANTS';

import { CustomerMessageSchema } from '../../validators/customerMessageSchema';

import { ContactMessageDTO } from '../../types/DTO';
import { APIThunkResult } from '../../types/API';

export const sendContactMessage = (
  input: CustomerMessageSchema,
): APIThunkResult<ContactMessageDTO> => (
  apiRequest<ContactMessageDTO>(async () => {
    const { data, error } = await apiFetch<ContactMessageDTO>({
      method: 'POST',
      url: `${serverCustomerAPI}/contact/send`,
      body: {
        ...input,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);
