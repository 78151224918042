import { Customer } from '../../../../types/DBModels';
import { CustomerDataView } from '../../../../types/DBViews';

export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';
export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER';
export const RESET_SELECTED_CUSTOMER = 'RESET_SELECTED_CUSTOMER';
export const SET_UPDATED_CUSTOMER_DATA = 'SET_UPDATED_CUSTOMER_DATA';

export interface CustomerState {
  customerData: {
    customers: CustomerDataView[];
    count: number;
  }
  selectedCustomer: Customer | null;
}

export interface SetCustomersAction {
  type: typeof SET_CUSTOMER_DATA;
  customerData: {
    results: CustomerDataView[];
    count: number;
  }
}

export interface SetSelectedCustomerAction {
  type: typeof SET_SELECTED_CUSTOMER;
  selectedCustomer: Customer;
}

export interface ResetSelectedCustomerAction {
  type: typeof RESET_SELECTED_CUSTOMER;
}
export interface SetUpdatedCustomerAction {
  type: typeof SET_UPDATED_CUSTOMER_DATA;
  customers: CustomerDataView[],
}

export type CustomerActionTypes =
SetCustomersAction |
SetSelectedCustomerAction |
ResetSelectedCustomerAction |
SetUpdatedCustomerAction;
