import React from 'react';

import Grid from '../../components/Common/Grid';
import { Grid as LayoutGrid } from '../MasterLayout';
import NavHeader from '../../components/UI/NavHeader';
import FormSteps from '../../components/Common/FormSteps';

import {
  ContentWrapper,
  Container,
  MainContentHeader,
  ContentHeading,
  ContentSubheading,
  BackgroundTexture,
  AsideContentWrapper,
  FormStepsContainer,
} from './Styles';
import useWindowSize from '../../hooks/useWindowSize';
import { TABLET } from '../../CONSTANTS';
import { contentHeaderBg } from '../../assets/Images';

import { CustomerLayoutProps } from './types';

const CustomerLayout: React.FC<CustomerLayoutProps> = ({
  pageTitle,
  pageTitleTwo,
  pageTitleHighlight,
  pageSubtitle,
  currentFormStep,
  isOnboarding,
  isEditProfile,
  isCreateProfile,
  mainContentMaxWidth,
  children,
  asideContent,
}) => {
  const { windowWidth } = useWindowSize();
  const isLaptop = windowWidth > TABLET;

  const contentHeader = (
    <MainContentHeader>
      <BackgroundTexture src={contentHeaderBg} />
      <ContentHeading>{pageTitle}</ContentHeading>
      &nbsp;
      <ContentHeading highlight>{pageTitleHighlight}</ContentHeading>
      &nbsp;
      {pageTitleTwo && <ContentHeading>{pageTitleTwo}</ContentHeading>}
      {pageSubtitle && (
        <ContentSubheading>
          {pageSubtitle}
        </ContentSubheading>
      )}
    </MainContentHeader>
  );

  const renderAsideContent = () => (isLaptop ? (
    <Grid column sm={12} md={5} lg={4}>
      <AsideContentWrapper>
        {asideContent}
      </AsideContentWrapper>
    </Grid>
  ) : (
    <AsideContentWrapper>
      {asideContent}
    </AsideContentWrapper>
  ));

  return (
    <Container>
      <NavHeader
        isCreateProfile={isCreateProfile}
        isOnboarding={isOnboarding}
        currentFormStep={currentFormStep}
      />
      {!isOnboarding && !isEditProfile && currentFormStep !== undefined && (
        <LayoutGrid fullWidth={false} maxWidth autoHeight>
          <Grid row>
            <Grid expandedMobile column sm={12}>
              <FormStepsContainer maxWidth={mainContentMaxWidth}>
                <FormSteps isOnboarding={false} currentStep={currentFormStep} />
              </FormStepsContainer>
            </Grid>
          </Grid>
        </LayoutGrid>
      )}
      <LayoutGrid fullWidth={false} maxWidth autoHeight>
        <Grid row>
          <Grid expandedMobile column sm={12} md={asideContent ? 7 : 12} lg={asideContent ? 8 : 12}>
            <ContentWrapper maxWidth={mainContentMaxWidth}>
              {contentHeader}
              {asideContent && !isLaptop && renderAsideContent()}
              {children}
            </ContentWrapper>
          </Grid>
          {asideContent && isLaptop && renderAsideContent()}
        </Grid>
      </LayoutGrid>
    </Container>
  );
};

export default CustomerLayout;
