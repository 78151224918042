import { AnyAction, CombinedState, combineReducers } from 'redux';

import { LoadingState, LoadingActionTypes } from './contexts/loading/types';
import { PermissionsState, PermissionsActionTypes } from './contexts/permissions/types';
import { NotificationActionTypes, NotificationState } from './contexts/notification/types';
import { CustomerActionTypes } from './contexts/admin/customers/types';
import { ProfileActionTypes } from './contexts/customer/profile/types';
import { VehicleMakesState, VehicleMakesActionTypes } from './contexts/vehicleMakes/types';

import loadingReducer from './contexts/loading/reducers';
import permissionReducer from './contexts/permissions/reducers';
import notificationReducer from './contexts/notification/reducers';
import adminRootReducer, { AdminState, AdminActionTypes } from './contexts/admin';
import customerRootReducer, {
  CustomerState,
  CustomerActionTypes as CurrentUserCustomerActionTypes,
} from './contexts/customer';
import vehicleMakesReducer from './contexts/vehicleMakes/reducers';
import { TotalCreditActionTypes } from './contexts/admin/selectedCustomer/totalCredits/types';

export interface ApplicationState {
  loadingState: LoadingState;
  permissionsState: PermissionsState;
  notificationState: NotificationState;
  admin: AdminState;
  customer: CustomerState;
  vehicleMakesState: VehicleMakesState;
}

const appReducer = combineReducers<ApplicationState>({
  loadingState: loadingReducer,
  permissionsState: permissionReducer,
  notificationState: notificationReducer,
  admin: adminRootReducer,
  customer: customerRootReducer,
  vehicleMakesState: vehicleMakesReducer,
});

const rootReducer = (
  state: CombinedState<ApplicationState> | undefined,
  action: AnyAction,
): CombinedState<ApplicationState> => {
  let clearState: CombinedState<ApplicationState> | undefined = state;

  if (action.type === 'CLEAR_USER') {
    window.localStorage.clear();
    clearState = undefined;
  }

  return appReducer(clearState, action);
};

export type AppActions =
  | LoadingActionTypes
  | PermissionsActionTypes
  | NotificationActionTypes
  | CustomerActionTypes
  | AdminActionTypes
  | ProfileActionTypes
  | VehicleMakesActionTypes
  | CurrentUserCustomerActionTypes
  | TotalCreditActionTypes;

export default rootReducer;
