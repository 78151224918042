import { User } from '../../../../types/DBModels';

export const SET_ADMIN_USER = 'SET_ADMIN_USER';

export interface AdminUserState {
  adminUser: User | null;
}

export interface SetAdminUserAction {
  type: typeof SET_ADMIN_USER;
  adminUser: User;
}

export type AdminUserActionTypes = SetAdminUserAction;
