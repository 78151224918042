import styled, { css } from 'styled-components/macro';
import ReactSlider from 'react-slider';

import { TableFilterSelectButtonStyleProps } from '../types';
import {
  IconProps,
  StyledDropdownProps,
  OptionsWrapperProps,
  SelectedProps,
  OptionProps,
  StyledThumbProps,
} from './types';

export const TFStyledDropdown = styled.div`
  width: 100%;
  margin: 0.75rem 0;
  position: relative;
  & ~ & {
    margin: 5px 0px;
  }
  font-family: ${({ theme }) => theme.fontFamily.body};
  user-select: none;
  width: auto;
  flex: 1;
  padding: 0;
  margin: ${({ theme }) => `${theme.spacing.XXS}rem ${theme.spacing.XXS * 0.5}rem ${theme.spacing.XXS * 2}rem ${theme.spacing.XXS * 0.5}rem`};
  & ~ & {
    margin: ${({ theme }) => `${theme.spacing.XXS}rem ${theme.spacing.XXS * 0.5}rem ${theme.spacing.XXS * 2}rem ${theme.spacing.XXS * 0.5}rem`};
  }
`;

export const SelectedWrapper = styled.div<StyledDropdownProps>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  margin: ${({ theme }) => theme.spacing.XXS / 2}rem 0 0 0;
  width: 100%;
  padding: 15px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};

  border: 1px solid ${({ error, disabled, theme, outlined }) => {
    if (error) return theme.palette.btnError;
    if (disabled) return theme.palette.btnGrey;
    if (outlined) return theme.basePalette.greyMain;
    return theme.palette.btnMidGrey;
  }};

  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.borderBlue};
    background-color: ${({ theme }) => theme.palette.btnMidGrey};
    color: ${({ theme }) => theme.basePalette.navyMain};
  }

  &:hover {
    ${({ disabled, theme, outlined }) => !disabled && css`
      border: 1px solid ${theme.palette.borderBlue}};
      background-color: (${outlined ? 'theme.palette.bgPrimary' : 'theme.palette.btnMidGrey'})
    `};
  }

  border-radius: ${({ outlined = false }) => (outlined ? '12px' : '8px')};
  border-bottom-left-radius: ${({ open, outlined = false }) => {
    if (open) return '0px';
    if (outlined) return '15px';
    return '8px';
  }};
  border-bottom-right-radius: ${({ open, outlined = false }) => {
    if (open) return '0px';
    if (outlined) return '15px';
    return '8px';
  }};

  background-color: ${({ theme, outlined }) => (outlined ? theme.palette.bgPrimary : theme.palette.btnMidGrey)};
  color: ${({ disabled, theme }) => (disabled ? theme.palette.btnGrey : theme.basePalette.navyMain)};
`;

export const TFSelectedWrapper = styled(SelectedWrapper)`
  min-height: 60px;
  background-color: rgb(248 248 248);
  border-color: rgb(248 248 248);
  border-radius: 15px 15px ${({ open }) => (open ? '0px' : '15px')} ${({ open }) => (open ? '0px' : '15px')};
  align-items: center;
  margin-top: 0;
`;

export const TFOptionsWrapper = styled.div<OptionsWrapperProps>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme, outlined }) => (outlined ? theme.basePalette.greyMain : theme.palette.btnMidGrey)};
  border-left: 1px solid ${({ theme, outlined }) => (outlined ? theme.basePalette.greyMain : theme.palette.btnMidGrey)};
  border-right: 1px solid ${({ theme, outlined }) => (outlined ? theme.basePalette.greyMain : theme.palette.btnMidGrey)};
  background-color: ${({ theme }) => theme.palette.white};;
  position: absolute;
  z-index: 80;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;

  border-bottom-left-radius: ${({ outlined = false }) => (outlined ? '15px' : '8px')};
  border-bottom-right-radius: ${({ outlined = false }) => (outlined ? '15px' : '8px')};
`;

export const Selected = styled.div<SelectedProps>`
  color: ${({ theme, isPlaceholder }) => isPlaceholder && theme.basePalette.greyDark};
  font-weight: ${({ theme }) => theme.fontWeight.extraLight};
  font-family: ${({ theme }) => theme.fontFamily.body};
`;

export const OptionsWrapper = styled.div<OptionsWrapperProps>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme, outlined }) => (outlined ? theme.basePalette.greyMain : theme.palette.btnMidGrey)};
  border-left: 1px solid ${({ theme, outlined }) => (outlined ? theme.basePalette.greyMain : theme.palette.btnMidGrey)};
  border-right: 1px solid ${({ theme, outlined }) => (outlined ? theme.basePalette.greyMain : theme.palette.btnMidGrey)};
  background-color: ${({ theme }) => theme.palette.white};;
  position: absolute;
  z-index: 80;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;

  border-bottom-left-radius: ${({ outlined = false }) => (outlined ? '15px' : '8px')};
  border-bottom-right-radius: ${({ outlined = false }) => (outlined ? '15px' : '8px')};
`;

export const Option = styled.div<OptionProps>`
  cursor: pointer;
  padding: 15px 15px;
  background-color: ${({ theme }) => theme.basePalette.white};

  ${({ theme, selected = false }) => selected && `
    background-color: ${theme.palette.btnMidGrey};
    color: ${theme.basePalette.navyMain};
  `}

  &:hover{
    background-color: ${({ theme }) => theme.palette.btnMidGrey};
    color: ${({ theme }) => theme.basePalette.navyMain}
  }
`;

export const StyledButton = styled.button<TableFilterSelectButtonStyleProps>`
  padding: ${({ theme }) => `${theme.spacing.XXS * 0.5}rem ${theme.spacing.XXS * 0.75}rem`};
  ${({ theme, selected }) => (!selected
    ? `background-color: #f0f0f0;
       border: 1px solid #f0f0f0;`
    : `background-color: ${theme.palette.secondary};
       border: 1px solid ${theme.palette.secondary};
       color: ${theme.palette.white};`)}
  border-radius: 8px;
  margin: 0 0.5rem 0.5rem 0;
  cursor: pointer;
  outline: none;
  user-select: none;
`;

export const CustomFilterHeading = styled.h6`
  margin-bottom: ${({ theme }) => `${theme.spacing.XXS}rem`};
`;

export const ButtonGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const StyledSlider = styled(ReactSlider)`
  height: 2px;
  width: 100%;
  margin-bottom: ${({ theme }) => (`calc(${theme.spacing.XXS * 2}rem + 10px)`)};

`;

export const StyledThumb = styled.div<StyledThumbProps>`
  height: 15px;
  font-size: 0.6rem;
  width: 15px;
  text-align: center;
  border: 2px solid ${({ theme }) => theme.palette.secondary};
  background-color: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.secondary};
  background-color: ${({ theme }) => theme.palette.secondary};
  border-radius: 50%;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
  user-select: none;
  outline: none;
  top: -6px;

  // thumb 0 = left thumb
  &.thumb.thumb-0 p{
    left: ${({ shiftBoth, shiftLeft }) => ((shiftBoth && `-${shiftBoth}px`) || (shiftLeft && `-${shiftLeft}px`) || '-2px')};
  }

  // thumb 1 = right thumb
  &.thumb.thumb-1 p{
    left: ${({ shiftBoth, shiftRight }) => ((shiftBoth && `${shiftBoth}px`) || (shiftRight && `${shiftRight}px`) || '-2px')};
  }
`;

export const StyledThumbText = styled.p`
  position: absolute;
  left: -2px;
  margin-top: ${({ theme }) => theme.spacing.XXS}rem;
  text-align: center;
  font-size: 0.7rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.palette.black};
  user-select: none;
  outline: none;
`;

export const StyledTrack = styled.div<{ index: number }>`
    top: 0;
    bottom: 0;
    background-color: ${({ index, theme }) => {
    if (index === 1) return theme.palette.secondary;
    return '#ddd';
  }};
    border-radius: 999px;
`;

export const FilterSubHeading = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontScale.S};
`;

export const LineBreak = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.btnMidGrey};
`;

export const Icon = styled.img<IconProps>`
  transform: ${({ open }) => (open ? 'scaleY(-1)' : '')};
  width: 12px;
`;
