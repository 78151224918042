import * as yup from 'yup';

const completePlanSchema = yup.object({
  planId: yup
    .number()
    .required('Please select a plan'),
  receipt: yup
    .string()
    .trim()
    .optional(),
}).required();

export type CompletePlanSchema = yup.InferType<typeof completePlanSchema>;

export default completePlanSchema;
