import {
  NotificationState,
  NotificationActionTypes,
  SET_NOTIFICATION,
  DISMISS_NOTIFICATION,
} from './types';

const initialState: NotificationState = {
  visible: false,
  options: { message: '' },
};

const notificationReducer = (
  state: NotificationState = initialState,
  action: NotificationActionTypes,
): NotificationState => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        ...state,
        visible: true,
        options: action.options,
      };
    case DISMISS_NOTIFICATION:
      return { ...initialState };
    default:
      return state;
  }
};

export default notificationReducer;
