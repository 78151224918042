import React from 'react';
import { IDataTableColumn } from 'react-data-table-component';

import { DropdownOption, TableRow } from './types';
import {
  APIFilterOptions,
  CustomerFilterOptions,
  OrderDirection,
  PaymentFilterOptions,
  PlanFilterOptions,
} from '../../../lib/types/API';

export const buildHandlers = <T extends APIFilterOptions>(
  updateFilter: ((newFilter: T) => void),
  filter: T,
  localFilter?: Record<string, number | Date | undefined> | undefined,
  setLocalFilter?: React.Dispatch<React.SetStateAction<Record<string, number | Date | undefined> | undefined>>,
  clearFilters?: (() => void) | undefined,
  handleSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>,
  setFilterMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, ((...args: any) => void
  )> => {
  const handlePlanTypeChange = (option: DropdownOption) => {
    const castedFilter = filter as PaymentFilterOptions;
    const planType = [...castedFilter.planType || []];
    const existingIndex = planType?.findIndex((opt) => opt.toString() === option.value);
    if (existingIndex > -1 && planType) planType.splice(existingIndex, 1);
    else planType?.push(option.value);
    updateFilter({
      ...filter,
      planType,
    });
  };

  const handlePlanStatusChange = (option: DropdownOption) => {
    const castedFilter = filter as PlanFilterOptions;
    const status = [...castedFilter.status || []];
    const existingIndex = status?.findIndex((opt) => opt.toString() === option.value);
    if (existingIndex > -1 && status) status.splice(existingIndex, 1);
    else status?.push(option.value);
    updateFilter({
      ...filter,
      status,
    });
  };

  const handlePlanStateChange = (option: DropdownOption) => {
    const castedFilter = filter as PlanFilterOptions;
    const planState = [...castedFilter.state || []];
    const existingIndex = planState?.findIndex((opt) => opt.toString() === option.value);
    if (existingIndex > -1 && planState) planState.splice(existingIndex, 1);
    else planState?.push(option.value);
    updateFilter({
      ...filter,
      state: planState,
    });
  };

  const handlePaymentStatusChange = (option: DropdownOption) => {
    const castedFilter = filter as PaymentFilterOptions;
    const status = [...castedFilter.status || []];
    const existingIndex = status?.findIndex((opt) => opt.toString() === option.value);
    if (existingIndex > -1 && status) status.splice(existingIndex, 1);
    else status?.push(option.value);
    updateFilter({
      ...filter,
      status,
    });
  };

  const handleUpdatePlanValue = (planValue: number[]) => {
    if (setLocalFilter) {
      setLocalFilter({
        ...localFilter,
        planMinValue: planValue[0],
        planMaxValue: planValue[1],
      });
    }
  };

  const handleUpdateInstallmentValue = (installmentValue: number[]) => {
    if (setLocalFilter) {
      setLocalFilter({
        ...localFilter,
        installmentMinValue: installmentValue[0],
        installmentMaxValue: installmentValue[1],
      });
    }
  };

  const handleDueDateChange = ({ min, max }: Record<string, Date|undefined>) => {
    if (setLocalFilter) {
      setLocalFilter({
        ...localFilter,
        minDueDate: min,
        maxDueDate: max,
      });
    }
  };

  const handleStartDateChange = ({ min, max }: Record<string, Date|undefined>) => {
    if (setLocalFilter) {
      setLocalFilter({
        ...localFilter,
        minStartDate: min,
        maxStartDate: max,
      });
    }
  };

  const handlePaidDateChange = ({ min, max }: Record<string, Date|undefined>) => {
    if (setLocalFilter) {
      setLocalFilter({
        ...localFilter,
        minPaidDate: min,
        maxPaidDate: max,
      });
    }
  };

  const handleCustomerStateChange = (option: DropdownOption) => {
    const castedFilter = filter as CustomerFilterOptions;
    const state = [...castedFilter.state || []];
    const existingIndex = state?.findIndex((opt) => opt.toString() === option.value);
    if (existingIndex > -1 && state) state.splice(existingIndex, 1);
    else state?.push(option.value);
    updateFilter({
      ...filter,
      state,
    });
  };

  const handleCustomerStatusChange = (option: DropdownOption) => {
    const castedFilter = filter as CustomerFilterOptions;
    const customerStatus = [...castedFilter.customerStatus || []];
    const existingIndex = customerStatus?.findIndex((opt) => opt.toString() === option.value);
    if (existingIndex > -1 && customerStatus) customerStatus.splice(existingIndex, 1);
    else customerStatus?.push(option.value);
    updateFilter({
      ...filter,
      customerStatus,
    });
  };

  const handleSearchComplete = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (handleSearchChange) handleSearchChange(e);
    updateFilter({
      ...filter,
      keyword: e.currentTarget.value || '',
    });
  };

  const handlePageChange = (page: number) => {
    updateFilter({
      ...filter,
      page,
    });
  };

  const handleSortChange = ({ selector }: IDataTableColumn<TableRow>, dir: 'asc' | 'desc') => {
    updateFilter({
      ...filter,
      orderColumn: selector as string,
      orderDirection: dir === 'asc' ? OrderDirection.ASC : OrderDirection.DESC,
    });
  };

  const handleApplyCustomFilters = () => {
    updateFilter({
      ...filter,
      ...localFilter,
    });
  };

  const handleClearFilters = () => {
    if (setSearchTerm) setSearchTerm('');
    if (clearFilters) clearFilters();
    if (setFilterMenuOpen) setFilterMenuOpen(false);
  };

  return {
    handlePlanTypeChange,
    handlePlanStatusChange,
    handlePlanStateChange,
    handlePaymentStatusChange,
    handleUpdatePlanValue,
    handleUpdateInstallmentValue,
    handleDueDateChange,
    handleStartDateChange,
    handlePaidDateChange,
    handleCustomerStateChange,
    handleCustomerStatusChange,
    handleSearchComplete,
    handlePageChange,
    handleSortChange,
    handleApplyCustomFilters,
    handleClearFilters,
  };
};