import { apiFetch, apiRequest, objectToQueryString, stripDate } from '../Utils';
import { setAllPlanData, setUpdatedPlanData } from '../../store/contexts/admin/plans/actions';
import {
  setAllPlanData as setAllSelectedCustomerPlanData,
  setUpdatedPlanData as setUpdatedSelectedCustomerPlanData,
  createPlan as createSelectedCustomerPlan,
  removePlan as removeSelectedCustomerPlan,
} from '../../store/contexts/admin/selectedCustomer/plans/actions';
import {
  setAllArchivedPlanData as setAllSelectedCustomerArchivedPlanData,
  setUpdatedArchivedPlanData as setUpdatedSelectedCustomerArchivedPlanData,
} from '../../store/contexts/admin/selectedCustomer/archivedPlans/actions';
import {
  setAllPayablePlanData as setSelectedCustomerPayablePlanData,
} from '../../store/contexts/admin/selectedCustomer/payablePlans/actions';
import {
  setAllTransferablePlanData as setSelectedCustomerTransferablePlanData,
} from '../../store/contexts/admin/selectedCustomer/transferablePlans/actions';

import { DEFAULT_FILTER, serverAdminAPI } from '../../../CONSTANTS';

import { APIThunkResult, OrderDirection, PlanFilterOptions } from '../../types/API';
import {
  DeletePlanDTO,
  FilteredCustomerPlansDTO,
  FilteredPlansDTO,
  FinalisePlanDTO,
  PlanDTO,
  PlanLimitsDTO,
  PlanStatusDTO,
  PlanTransferRecordsDTO,
  TransferDTO,
  UpdatePlanReceiptDTO,
} from '../../types/DTO';
import { Plan } from '../../types/DBModels';
import { setNotification } from '../../store/contexts/notification/actions';
import { PlanDataView } from '../../types/DBViews';
import { PlanStatus } from '../../types/Plan';
import { setTotalCreditsData } from '../../store/contexts/admin/selectedCustomer/totalCredits/actions';
import { setSelectedPlanTransfers } from '../../store/contexts/admin/selectedPlanTransfers/actions';
import { setSelectedPlanData } from '../../store/contexts/admin/selectedCustomer/selectedPlan/actions';

export const defaultFilter = {
  page: DEFAULT_FILTER.page,
  rowsPerPage: DEFAULT_FILTER.rowsPerPage,
  orderColumn: 'dueDate',
  orderDirection: OrderDirection.ASC,
};

export const getPlans = (
  filter: PlanFilterOptions,
): APIThunkResult<FilteredPlansDTO> => (
  apiRequest<FilteredPlansDTO>(async (dispatch) => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverAdminAPI}/plans?${query}`;

    const { data, error } = await apiFetch<FilteredPlansDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setAllPlanData(data.plans));

    return data;
  }, true)
);
export const getCustomerPlans = (
  customerId: number,
  filter: PlanFilterOptions,
  customerPlans: 'ACTIVE' | 'ARCHIVED',
): APIThunkResult<FilteredCustomerPlansDTO> => (
  apiRequest<FilteredCustomerPlansDTO>(async (dispatch) => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverAdminAPI}/plans/${customerId}/?${query}`;

    const { data, error } = await apiFetch<FilteredCustomerPlansDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    if (customerPlans === 'ACTIVE') dispatch(setAllSelectedCustomerPlanData(data.plans));
    else if (customerPlans === 'ARCHIVED') dispatch(setAllSelectedCustomerArchivedPlanData(data.plans));

    return data;
  }, true)
);
export type CustomerPlanWithTransfers = PlanDataView & { transfersData: { count: number, value: number } };
export const getSelectedCustomerPlan = (
  customerId: number,
  planId: number,
): APIThunkResult<CustomerPlanWithTransfers> => (
  apiRequest<CustomerPlanWithTransfers>(async (dispatch) => {
    const url = `${serverAdminAPI}/plans/${customerId}/${planId}`;

    const { data, error } = await apiFetch<CustomerPlanWithTransfers>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setSelectedPlanData(data));

    return data;
  }, true)
);

export const getPlanTransferRecords = (
  planId: number,
): APIThunkResult<PlanTransferRecordsDTO> => (
  apiRequest<PlanTransferRecordsDTO>(async (dispatch) => {
    const { data, error } = await apiFetch<PlanTransferRecordsDTO>({
      method: 'GET',
      url: `${serverAdminAPI}/plans/transfers/${planId}`,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setSelectedPlanTransfers(data.transferRecords));
    return data;
  }, true)
);

export const getPayablePlans = (
  userId: string,
): APIThunkResult<PlanDataView[]> => (
  apiRequest<PlanDataView[]>(async (dispatch) => {
    const { data, error } = await apiFetch<PlanDataView[]>({
      method: 'GET',
      url: `${serverAdminAPI}/plans/payable/${userId}`,
    });

    if (!data || error) throw new Error(`${error?.message}`);
    dispatch(setSelectedCustomerPayablePlanData(data));
    return data;
  }, true)
);

export const getTransferablePlans = (
  userId: string,
): APIThunkResult<PlanDataView[]> => (
  apiRequest<PlanDataView[]>(async (dispatch) => {
    const { data, error } = await apiFetch<PlanDataView[]>({
      method: 'GET',
      url: `${serverAdminAPI}/plans/transferable/${userId}`,
    });

    if (!data || error) throw new Error(`${error?.message}`);
    dispatch(setSelectedCustomerTransferablePlanData(data));
    return data;
  }, true)
);

export const finalisePlan = (
  customerId: number,
  planId: number,
  receiptURL?: string,
): APIThunkResult<FinalisePlanDTO> => apiRequest<FinalisePlanDTO>(
  async (dispatch, getState) => {
    const userId = getState().admin.customerState.selectedCustomer?.userId;
    const { data, error } = await apiFetch<FinalisePlanDTO>({
      method: 'POST',
      url: `${serverAdminAPI}/plans/finalise`,
      body: {
        userId,
        planId,
        receiptURL,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    if (data) {
      // Plan might be auto renewed, fetch all,
      dispatch<void>(getCustomerPlans(
        customerId,
        {
          ...defaultFilter, status: [PlanStatus.ACTIVE, PlanStatus.INACTIVE, PlanStatus.DUE],
        }, 'ACTIVE', // second argument denotes what store to update.
      )); // Update active plans
      dispatch<void>(getCustomerPlans(
        customerId,
        {
          ...defaultFilter, status: [PlanStatus.CANCELLED, PlanStatus.COMPLETE],
        }, 'ARCHIVED',
      )); // Update archived plans

      dispatch(setNotification({ message: 'Plan completed!', variant: 'success', duration: 3 }));
    }
    return data;
  },
  true,
);

export const transferPlanFunds = (
  createdByUserId: string,
  customerId: number,
  fromPlanId: number | null,
  toPlanId: number | null,
  creditAmount: number | null,
): APIThunkResult<TransferDTO> => apiRequest<TransferDTO>(
  async (dispatch) => {
    const { data, error } = await apiFetch<TransferDTO>({
      method: 'POST',
      url: `${serverAdminAPI}/plans/transfer`,
      body: {
        fromPlanId,
        toPlanId,
        creditAmount,
        createdByUserId,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    if (data) {
      // fetch all plans and payments,
      dispatch<void>(getCustomerPlans(
        customerId,
        {
          ...defaultFilter, status: [PlanStatus.ACTIVE, PlanStatus.INACTIVE, PlanStatus.DUE],
        }, 'ACTIVE', // second argument denotes what store to update.
      )); // refetch active plans
      dispatch<void>(getCustomerPlans(
        customerId,
        {
          ...defaultFilter, status: [PlanStatus.CANCELLED, PlanStatus.COMPLETE],
        }, 'ARCHIVED',
      )); // refetch archived plans

      if (data.newCreditTotal || data.newCreditTotal === 0) dispatch(setTotalCreditsData(data.newCreditTotal)); // update totalCredits

      dispatch(setNotification({ message: 'Plan transferred!', variant: 'success', duration: 3 }));
    }
    return data;
  },
  true,
);

export const createOrUpdateSelectedCustomerPlan = (
  plan: Plan & { sendEmailConf?: boolean },
): APIThunkResult<PlanDTO> => apiRequest<PlanDTO>(
  async (dispatch, getState) => {
    const userId = getState().admin.customerState.selectedCustomer?.userId;

    const { planId, startDate, endDate, dueDate, ...rest } = plan;

    if (!userId) throw new Error('No customer selected');

    const { data, error } = await apiFetch<PlanDTO>({
      method: planId ? 'PUT' : 'POST',
      url: `${serverAdminAPI}/plans/${planId ? 'update' : 'create'}`,
      body: {
        userId,
        planId,
        ...rest,
        startDate: stripDate(startDate),
        endDate: stripDate(endDate),
        dueDate: stripDate(dueDate),
        vehicle: {
          ...plan.vehicle,
          year: stripDate(plan.vehicle?.year),
        },
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    if (planId) {
      if (plan.status === PlanStatus.CANCELLED || plan.status === PlanStatus.COMPLETE) {
        dispatch(setUpdatedSelectedCustomerArchivedPlanData([data.plan]));
        dispatch(removeSelectedCustomerPlan([data.plan.planId]));
      } else dispatch(setUpdatedSelectedCustomerPlanData([data.plan]));
      dispatch(setNotification({ message: 'Plan saved!', variant: 'success', duration: 3 }));
    } else {
      dispatch(createSelectedCustomerPlan(data.plan));
      dispatch(setNotification({ message: 'Plan created!', variant: 'success', duration: 3 }));
    }

    return data;
  },
  true,
);

export const deletePlan = (
  planId: number,
  userId: string,
): APIThunkResult<DeletePlanDTO> => apiRequest<DeletePlanDTO>(
  async (dispatch, getState) => {
    const adminUserId = getState().admin.adminUserState.adminUser?.userId;

    if (!adminUserId) throw new Error('No admin available');

    const { data, error } = await apiFetch<DeletePlanDTO>({
      method: 'DELETE',
      url: `${serverAdminAPI}/plans/delete`,
      body: {
        userId,
        planId,
        adminUserId,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(removeSelectedCustomerPlan([data.planId]));

    dispatch(setNotification({ message: 'Plan deleted!', variant: 'success', duration: 3 }));
    return data;
  },
  true,
);

export const updatePlanStatus = (
  planId: number,
  status: PlanStatus,
): APIThunkResult<PlanStatusDTO> => apiRequest<PlanStatusDTO>(
  async (dispatch) => {
    const { data, error } = await apiFetch<PlanStatusDTO>({
      method: 'PUT',
      url: `${serverAdminAPI}/plans/update-status`,
      body: {
        planId,
        status,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setUpdatedPlanData([data.plan]));
    dispatch(setNotification({ message: 'Plan saved!', variant: 'success', duration: 3 }));

    return data;
  },
  true,
);

export const getPlanFilterLimits = (): APIThunkResult<PlanLimitsDTO> => (
  apiRequest<PlanLimitsDTO>(async () => {
    const url = `${serverAdminAPI}/plans/filter-limit`;

    const { data, error } = await apiFetch<PlanLimitsDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);

export const uploadReceipt = (
  planId: number,
  receiptURL?: string,
): APIThunkResult<UpdatePlanReceiptDTO> => apiRequest<UpdatePlanReceiptDTO>(
  async (dispatch, getState) => {
    const userId = getState().admin.customerState.selectedCustomer?.userId;
    const { data, error } = await apiFetch<UpdatePlanReceiptDTO>({
      method: 'POST',
      url: `${serverAdminAPI}/plans/uploadReceipt`,
      body: {
        userId,
        planId,
        receiptURL,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);
    dispatch(setUpdatedSelectedCustomerArchivedPlanData([data]));
    return data;
  },
  true,
);

export const removeReceipt = (
  planId: number,
): APIThunkResult<UpdatePlanReceiptDTO> => (
  apiRequest<UpdatePlanReceiptDTO>(async (dispatch) => {
    const url = `${serverAdminAPI}/plans/remove/${planId}`;

    const { data, error } = await apiFetch<UpdatePlanReceiptDTO>({
      method: 'DELETE',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);
    dispatch(setUpdatedSelectedCustomerArchivedPlanData([data]));
    return data;
  }, true)
);