import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Formik } from 'formik';

import { resetPassword } from '../../../lib/Authentication';
import useStrings from '../../../hooks/useStrings';
import forgotPasswordSchema from '../../../lib/validators/forgotPasswordSchema';

import Grid from '../../Common/Grid';
import Input from '../../Common/Input';
import Button from '../../Common/Button';
import Heading from '../../Common/Heading';
import SuccessModal from '../../Common/SuccessModal';
import BackButton from '../../Common/BackButton';

import {
  FormWrapper,
  SubText,
  ButtonWrapper,
  Error,
  InputWrap,
} from './Styles';

import { ForgotPasswordFormProps } from './types';

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ titleHighlight, title, subtitle }) => {
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [showError, setShowError] = useState(false);
  const [{ Components: { UI: { ForgotPasswordForm: Strings } } }] = useStrings();
  const history = useHistory();

  return (
    <>
      <SuccessModal
        isOpen={isModalShowing}
        onClose={() => {
          setIsModalShowing(false);
          history.push('/');
        }}
        title={Strings.modalTitle}
        titleHighlight={Strings.modalTitleHighlight}
        subtext={Strings.modalSubtext}
        buttonText={Strings.modalButton}
      />
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={forgotPasswordSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async ({ email }, { setSubmitting }) => {
          try {
            await resetPassword(email);
            setIsModalShowing(true);
            setSubmitting(false);
            setShowError(false);
          } catch (error) {
            setShowError(true);
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <FormWrapper>
            <Grid row>
              <Grid column alignItems="center" sm={12}>
                <Grid row sm={12}>
                  <BackButton onClick={() => history.goBack()} />
                </Grid>
              </Grid>
              <Grid column alignItems="center" sm={12} marginTop="2rem">
                <Grid row sm={12}>
                  <Heading highlight defaultFont={false}>
                    {titleHighlight}
                    &nbsp;
                  </Heading>
                  <Heading defaultFont={false}>{title}</Heading>
                </Grid>
                <SubText>{subtitle}</SubText>
              </Grid>
            </Grid>
            <Grid row marginTop="40px" expanded>
              <Grid column sm={12}>
                <InputWrap>
                  <Input
                    type="email"
                    name="email"
                    label={Strings.emailInputLabel}
                    placeholder={Strings.emailPlaceholder}
                    width="100%"
                  />
                  {showError && <Error>{Strings.userNotFound}</Error>}
                </InputWrap>
              </Grid>
            </Grid>
            <Grid row marginTop="45px" expanded>
              <Grid column sm={12} alignItems="center">
                <ButtonWrapper>
                  <Button
                    onClick={handleSubmit}
                    margin="0px"
                    disabled={isSubmitting}
                    width="100%"
                    $loading={isSubmitting}
                  >
                    {Strings.submitButton}
                  </Button>
                </ButtonWrapper>
              </Grid>
            </Grid>
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};

export default ForgotPasswordForm;
