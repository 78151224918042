import {
  SET_SELECTED_ADMIN,
  SelectedAdminActionTypes,
  SelectedAdminState,
} from './types';

const initialState: SelectedAdminState = {
  selectedAdminUser: null,
};

const SelectedAdminReducer = (
  state: SelectedAdminState = initialState,
  action: SelectedAdminActionTypes,
): SelectedAdminState => {
  switch (action.type) {
    case SET_SELECTED_ADMIN:
      return {
        ...state,
        selectedAdminUser: action.adminUser,
      };
    default:
      return state;
  }
};

export default SelectedAdminReducer;
