import React from 'react';

import Button from '../Button';

import { DeleteIcon } from '../../../assets/Icons';
import {
  ModalBackground,
  ModalWrapper,
  ButtonWrapper,
  TextWrapper,
  ModalTitle,
  ModalSubtext,
  ModalBodyText,
  Icon,
} from './Styles';

import { DeleteModalProps } from './types';

const DeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  onCancel,
  onConfirm,
  title,
  titleHighlight,
  subtext,
  subtextHighlight,
  bodyText,
  cancelButtonText,
  confirmButtonText,
  isDeleting,
}) => {
  const stopClickBubbling = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  return (
    <ModalBackground
      opened={isOpen}
      onClick={onCancel}
    >
      <ModalWrapper onClick={stopClickBubbling}>
        <Icon src={DeleteIcon} />
        <TextWrapper>
          <div>
            <ModalTitle>
              {title}
            </ModalTitle>
            <ModalTitle highlight>
              &nbsp;
              {titleHighlight}
            </ModalTitle>
          </div>
          <ModalSubtext>
            {subtext}
          </ModalSubtext>
          {subtextHighlight && (
            <ModalSubtext highlight>
              {subtextHighlight}
            </ModalSubtext>
          )}
        </TextWrapper>
        {bodyText && (
          <ModalBodyText>
            {bodyText}
          </ModalBodyText>
        )}
        <ButtonWrapper>
          <Button
            onClick={onCancel}
            variant="secondary"
            rounded
            width="150px"
          >
            {cancelButtonText}
          </Button>
          <Button
            onClick={onConfirm}
            variant="important"
            rounded
            width="150px"
            disabled={isDeleting}
            $loading={isDeleting}
          >
            {confirmButtonText}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </ModalBackground>
  );
};

export default DeleteModal;
