import React from 'react';

import CustomerLayout from '../../../layouts/CustomerLayout';
import UpdatePasswordForm from '../../../components/UI/UpdatePasswordForm';

import useStrings from '../../../hooks/useStrings';
import { UpdatePasswordProps } from './types';

const UpdatePassword: React.FC<UpdatePasswordProps> = () => {
  const [{ Pages: { Customer: { UpdatePassword: Strings } } }] = useStrings();

  return (
    <CustomerLayout
      pageTitle={Strings.pageTitle}
      pageTitleHighlight={Strings.pageTitleHighlight}
      pageSubtitle={Strings.pageSubtitle}
    >
      <UpdatePasswordForm />
    </CustomerLayout>

  );
};

export default UpdatePassword;
