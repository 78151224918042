import dayjs from 'dayjs';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { FEES, CALCULATION_OFFSET } from '../../CONSTANTS';
import { InstallmentFrequency } from '../types/Plan';

/**
 * IMPORTANT
 * ANY updates to these calculations must be reflected in all code bases.
 */

export const roundNum = (num: number):number => Math.round((num + Number.EPSILON) * 100) / 100;

export const roundNumUp = (num: number):number => Math.ceil((num) * 100) / 100;

// **
// **
// ADMIN CREATE PLAN CALCULATOR
// **
// **
export type CalculatedCreatePlanPaymentData = {
  numberOfPayments: number,
  remainingPayments: number,
  installmentAmount?: number
  fee: number,
  totalCost?: number,
  lastPaymentDate: Date,
  validDates: string[],
};
export type CalculatedCreatePlanPaymentDataInputs = {
  startDate?: Date,
  dueDate?: Date,
  installmentFrequency?: string,
  totalPlanValue?: number,
  feeOverride?: number,
  lastPaymentDateOverride?: Date,
};
export const calculateCreatePlanPaymentData = ({
  startDate,
  dueDate,
  installmentFrequency,
  totalPlanValue,
  feeOverride,
  lastPaymentDateOverride,
}: CalculatedCreatePlanPaymentDataInputs): CalculatedCreatePlanPaymentData | null => {
  if (!startDate || !dueDate || !installmentFrequency) return null;
  let lastPaymentDate;
  if (lastPaymentDateOverride) {
    lastPaymentDate = lastPaymentDateOverride;
  } else {
    lastPaymentDate = dayjs(dueDate).subtract(CALCULATION_OFFSET, 'days');
  }

  let numberOfPayments = 0;
  let remainingPayments = 0;
  let installmentAmount = 0;
  let fee = 0;
  let totalCost = 0;

  // Calculate number of payments based off dueDate if no lastPaymentOverride
  let endDate = dayjs(startDate);
  if (!lastPaymentDateOverride) {
    if (installmentFrequency === InstallmentFrequency.WEEK) {
      numberOfPayments = Math.ceil(dayjs(lastPaymentDate).diff(dayjs(startDate), 'week', true));
      endDate = dayjs(endDate).add(numberOfPayments - 1, 'weeks');

      fee = feeOverride || feeOverride === 0 ? feeOverride : FEES.weekly;
    }
    if (installmentFrequency === InstallmentFrequency.FORTNIGHT) {
      numberOfPayments = Math.ceil(dayjs(lastPaymentDate).diff(dayjs(startDate), 'week', true) / 2);
      endDate = dayjs(endDate).add(2 * (numberOfPayments - 1), 'weeks');

      fee = feeOverride || feeOverride === 0 ? feeOverride : FEES.fortnightly;
    }
    if (installmentFrequency === InstallmentFrequency.MONTH) {
      numberOfPayments = Math.ceil(dayjs(lastPaymentDate).diff(dayjs(startDate), 'month', true));
      endDate = dayjs(endDate).add(numberOfPayments - 1, 'month');

      fee = feeOverride || feeOverride === 0 ? feeOverride : FEES.monthly;
    }
    if (installmentFrequency === InstallmentFrequency.ONE_OFF_PAYMENT) {
      numberOfPayments = 1;
      endDate = dayjs(endDate);

      fee = feeOverride || feeOverride === 0 ? feeOverride : FEES.weekly;
    }
  // Calculate number of payments from lastPaymentDateOverride
  } else {
    endDate = dayjs(lastPaymentDateOverride);

    if (installmentFrequency === InstallmentFrequency.WEEK) {
      numberOfPayments = (dayjs(lastPaymentDate).diff(dayjs(startDate), 'week', true)) + 1;

      fee = feeOverride || feeOverride === 0 ? feeOverride : FEES.weekly;
    }
    if (installmentFrequency === InstallmentFrequency.FORTNIGHT) {
      numberOfPayments = (dayjs(lastPaymentDate).diff(dayjs(startDate), 'week', true) / 2) + 1;

      fee = feeOverride || feeOverride === 0 ? feeOverride : FEES.fortnightly;
    }
    if (installmentFrequency === InstallmentFrequency.MONTH) {
      numberOfPayments = (dayjs(lastPaymentDate).diff(dayjs(startDate), 'month', true)) + 1;

      fee = feeOverride || feeOverride === 0 ? feeOverride : FEES.monthly;
    }

    if (installmentFrequency === InstallmentFrequency.ONE_OFF_PAYMENT) {
      numberOfPayments = 1;

      fee = feeOverride || feeOverride === 0 ? feeOverride : FEES.weekly;
    }
  }
  remainingPayments = numberOfPayments;

  // Get valid dates for end date that are in cadence with the start date
  // e.g same day of the week if weekly/fornightly payments
  // or same date of the month if monthly payments
  const validDates = [];
  let validDate = dayjs(startDate);

  if (installmentFrequency === InstallmentFrequency.ONE_OFF_PAYMENT) {
    validDates.push(validDate.toISOString());
  } else {
    let i = 1;
    while (dayjs(validDate).isBefore(dayjs(dueDate))) {
      if (installmentFrequency === InstallmentFrequency.WEEK) {
        validDates.push(dayjs(validDate).toISOString());
        validDate = dayjs(startDate).add(i, 'weeks');
        i += 1;
      }
      if (installmentFrequency === InstallmentFrequency.FORTNIGHT) {
        validDates.push(dayjs(validDate).toISOString());
        validDate = dayjs(startDate).add(2 * i, 'weeks');
        i += 1;
      }
      if (installmentFrequency === InstallmentFrequency.MONTH) {
        validDates.push(dayjs(validDate).toISOString());
        validDate = dayjs(startDate).add(i, 'month');
        i += 1;
      }
    }
  }

  // Calculate instalment amount and total cost of plan
  if (totalPlanValue) {
    installmentAmount = roundNumUp(((roundNum(totalPlanValue)) / numberOfPayments) + roundNum(fee));
    totalCost = numberOfPayments * installmentAmount;

    if (installmentAmount < 0 || installmentAmount === Infinity || Number.isNaN(installmentAmount)) return null;
  }

  return {
    numberOfPayments,
    remainingPayments,
    installmentAmount,
    fee,
    totalCost,
    lastPaymentDate: dayjs(endDate).toDate(),
    validDates,
  };
};

// **
// **
// CUSTOMER CREATE PLAN CALCULATOR
// **
// **
export type CalculatedCustomerPlanPaymentData = {
  numberOfPayments: number,
  installmentAmount: number
  fee: number,
  totalCost: number,
  lastPaymentDate: Date,
};
export type CalculatedCustomerPlanPaymentDataInputs = {
  startDate?: Date,
  dueDate?: Date,
  installmentFrequency?: string,
  totalPlanValue?: number,
};
export const calculateCustomerPlanPaymentData = ({
  startDate,
  dueDate,
  installmentFrequency,
  totalPlanValue,
}: CalculatedCustomerPlanPaymentDataInputs): CalculatedCustomerPlanPaymentData | null => {
  if (!startDate || !dueDate || !installmentFrequency || !totalPlanValue) return null;

  const lastPaymentDate = dayjs(dueDate).subtract(CALCULATION_OFFSET, 'days');

  let numberOfPayments = 0;
  let installmentAmount = 0;
  let fee = 0;
  let totalCost = 0;

  // Calculate number of payments based off dueDate no override for customer app
  let endDate = dayjs(startDate);
  if (installmentFrequency === InstallmentFrequency.WEEK) {
    numberOfPayments = Math.ceil(dayjs(lastPaymentDate).diff(dayjs(startDate), 'week', true));
    endDate = dayjs(endDate).add(numberOfPayments - 1, 'weeks');

    fee = FEES.weekly;
  }
  if (installmentFrequency === InstallmentFrequency.FORTNIGHT) {
    numberOfPayments = Math.ceil(dayjs(lastPaymentDate).diff(dayjs(startDate), 'week', true) / 2);
    endDate = dayjs(endDate).add(2 * (numberOfPayments - 1), 'weeks');

    fee = FEES.fortnightly;
  }
  if (installmentFrequency === InstallmentFrequency.MONTH) {
    numberOfPayments = Math.ceil(dayjs(lastPaymentDate).diff(dayjs(startDate), 'month', true));
    endDate = dayjs(endDate).add(numberOfPayments - 1, 'month');

    fee = FEES.monthly;
  }

  // Calculate instalment amount and total cost of plan
  installmentAmount = roundNumUp(((roundNum(totalPlanValue)) / numberOfPayments) + roundNum(fee));
  totalCost = numberOfPayments * installmentAmount;

  if (installmentAmount < 0 || installmentAmount === Infinity || Number.isNaN(installmentAmount)) return null;

  return {
    numberOfPayments,
    installmentAmount,
    fee,
    totalCost,
    lastPaymentDate: dayjs(endDate).toDate(),
  };
};

// **
// **
// ADMIN EDIT PLAN CALCULATOR
// **
// **

export type CalculatedEditPaymentData = {
  numberOfPayments: number,
  remainingPayments: number,
  installmentAmount: number
  fee: number,
  totalCost: number,
  validDates: string[],
  lastPaymentDate: Date,
};

export type CalculatedEditPaymentDataInputs = {
  isEditing: boolean,
  allPayments: number,
  latestInstallmentValue: number,
  startDate?: Date,
  lastPaymentDateOverride?: Date,
  dueDate?: Date,
  installmentFrequency?: string,
  totalPlanValue?: number,
  receivedValue: number,
  alreadyPaidLessFees: number,
  alreadyPaid: number,
  remainingPaymentsOverride: number,
  feeOverride?: number,
};
export const calculateEditPaymentData = ({
  isEditing = false,
  allPayments,
  latestInstallmentValue,
  startDate,
  lastPaymentDateOverride,
  dueDate,
  installmentFrequency,
  totalPlanValue,
  receivedValue,
  alreadyPaidLessFees,
  alreadyPaid,
  feeOverride,
  remainingPaymentsOverride,
}: CalculatedEditPaymentDataInputs): CalculatedEditPaymentData | null => {
  if (!startDate || !dueDate || !installmentFrequency || !totalPlanValue || !allPayments
    || !latestInstallmentValue || (!remainingPaymentsOverride && remainingPaymentsOverride !== 0)
    || (!feeOverride && feeOverride !== 0)) return null;

  let installmentAmount = latestInstallmentValue;
  const fee = roundNum(feeOverride);
  let numberOfPayments = allPayments;
  let remainingPayments = remainingPaymentsOverride;
  let totalCost = 0;

  let lastPaymentDate = lastPaymentDateOverride || dayjs(dueDate).subtract(CALCULATION_OFFSET, 'days').toDate();

  // We don't have access to rejected/skipped payments, so the remainingPaymentsOverride isnt matched on the back end.
  // Instead that handles deferred payments explicity. Here, we imply the presence of them by overriding the
  // number of remaining payments.

  // If a plan doesn't have payments or received values calculate from number of payments from lastPaymentDateOverride
  if (isEditing && numberOfPayments === remainingPayments && receivedValue === 0) {
    if (!lastPaymentDateOverride) {
      if (installmentFrequency === InstallmentFrequency.WEEK) {
        numberOfPayments = Math.ceil(dayjs(lastPaymentDate).diff(dayjs(startDate), 'week', true));
        lastPaymentDate = dayjs(startDate).add(numberOfPayments - 1, 'weeks').toDate();
      }
      if (installmentFrequency === InstallmentFrequency.FORTNIGHT) {
        numberOfPayments = Math.ceil(dayjs(lastPaymentDate).diff(dayjs(startDate), 'week', true) / 2);
        lastPaymentDate = dayjs(startDate).add(2 * (numberOfPayments - 1), 'weeks').toDate();
      }
      if (installmentFrequency === InstallmentFrequency.MONTH) {
        numberOfPayments = Math.ceil(dayjs(lastPaymentDate).diff(dayjs(startDate), 'month', true));
        lastPaymentDate = dayjs(startDate).add(numberOfPayments - 1, 'month').toDate();
      }
      if (installmentFrequency === InstallmentFrequency.ONE_OFF_PAYMENT) {
        numberOfPayments = 1;
        lastPaymentDate = dayjs(startDate).toDate();
      }
    // Calculate number of payments from lastPaymentDateOverride
    } else {
      if (installmentFrequency === InstallmentFrequency.WEEK) {
        numberOfPayments = (dayjs(lastPaymentDateOverride).diff(dayjs(startDate), 'week', true)) + 1;
      }
      if (installmentFrequency === InstallmentFrequency.FORTNIGHT) {
        numberOfPayments = (dayjs(lastPaymentDateOverride).diff(dayjs(startDate), 'week', true) / 2) + 1;
      }
      if (installmentFrequency === InstallmentFrequency.MONTH) {
        numberOfPayments = (dayjs(lastPaymentDateOverride).diff(dayjs(startDate), 'month', true)) + 1;
      }
      if (installmentFrequency === InstallmentFrequency.ONE_OFF_PAYMENT) {
        numberOfPayments = 1;
      }
    }

    remainingPayments = numberOfPayments;
  }

  // Get valid dates for end date that are in cadence with the start date
  // e.g same day of the week if weekly/fornightly payments
  // or same date of the month if monthly payments
  const validDates = [];
  let validDate = dayjs(startDate);
  if (installmentFrequency === InstallmentFrequency.ONE_OFF_PAYMENT) {
    validDates.push(validDate.toISOString());
  } else {
    let i = 1;
    while (dayjs(validDate).isBefore(dayjs(dueDate))) {
      if (installmentFrequency === InstallmentFrequency.WEEK) {
        validDates.push(dayjs(validDate).toISOString());
        validDate = dayjs(startDate).add(i, 'weeks');
        i += 1;
      }
      if (installmentFrequency === InstallmentFrequency.FORTNIGHT) {
        validDates.push(dayjs(validDate).toISOString());
        validDate = dayjs(startDate).add(2 * i, 'weeks');
        i += 1;
      }
      if (installmentFrequency === InstallmentFrequency.MONTH) {
        validDates.push(dayjs(validDate).toISOString());
        validDate = dayjs(startDate).add(i, 'month');
        i += 1;
      }
    }
  }

  if (!isEditing) {
    installmentAmount = roundNum(latestInstallmentValue);
  } else if (receivedValue && alreadyPaidLessFees) {
    installmentAmount = (
      ((roundNum(totalPlanValue) - roundNum(receivedValue) - roundNum(alreadyPaidLessFees)) / remainingPayments) + (fee)
    );
  } else if (alreadyPaidLessFees) {
    installmentAmount = (((roundNum(totalPlanValue) - roundNum(alreadyPaidLessFees)) / remainingPayments) + fee);
  } else if (receivedValue) {
    installmentAmount = (
      ((roundNum(totalPlanValue) - roundNum(receivedValue)) / remainingPayments) + fee);
  } else {
    installmentAmount = (((roundNum(totalPlanValue)) / remainingPayments) + fee);
  }

  installmentAmount = roundNumUp(installmentAmount);

  if (numberOfPayments === remainingPayments) {
    totalCost = numberOfPayments * installmentAmount;
  } else if (receivedValue && alreadyPaid) {
    totalCost = (remainingPayments * installmentAmount) + roundNum(alreadyPaid) + roundNum(receivedValue);
  } else if (alreadyPaid) {
    totalCost = (remainingPayments * installmentAmount) + roundNum(alreadyPaid);
  } else if (receivedValue) {
    totalCost = (remainingPayments * installmentAmount) + roundNum(receivedValue);
  } else totalCost = remainingPayments * installmentAmount;

  if (installmentAmount < 0 || installmentAmount === Infinity || Number.isNaN(installmentAmount)) return null;

  return {
    numberOfPayments,
    remainingPayments,
    installmentAmount,
    fee,
    totalCost,
    validDates,
    lastPaymentDate,
  };
};

export const getValidEndDates = (
  startDate: Date,
  installmentFrequency: InstallmentFrequency,
  numberOfPayments: number,
): Date[] => {
  const validDates = [];
  let endDate = dayjs(startDate);
  if (installmentFrequency === InstallmentFrequency.ONE_OFF_PAYMENT) {
    validDates.push(endDate.toDate());
  } else {
    for (let i = 0; i < numberOfPayments; i += 1) {
      if (installmentFrequency === InstallmentFrequency.WEEK) {
        endDate = dayjs(endDate).add(1, 'weeks');
        validDates.push(dayjs(endDate).add(1, 'weeks').toDate());
      }
      if (installmentFrequency === InstallmentFrequency.FORTNIGHT) {
        endDate = dayjs(endDate).add(2, 'weeks');
        validDates.push(dayjs(endDate).add(2, 'weeks').toDate());
      }
      if (installmentFrequency === InstallmentFrequency.MONTH) {
        endDate = dayjs(endDate).add(1, 'month');
        validDates.push(dayjs(endDate).add(1, 'months').toDate());
      }
    }
  }
  return validDates;
};

/**
 * Format currency values to comma separate thousands as well as adding decimal places
 *
 * @param {number | string} val - Currency input value
 * @example
 * currencyDisplayFormat(1200);
 * returns '1,200.00'
 * @example
 * currencyDisplayFormat(1200.50);
 * returns '1,200.50'
 */
export const currencyDisplayFormat = (val: string | number): string => {
  if (typeof val === 'string') {
    return parseFloat(val).toFixed(2).replace(/(\.\d{2})\d*/, '$1').replace(/(\d)(?=(\d{3})+\b)/g, '$1,');
  }
  return val.toFixed(2).replace(/(\.\d{2})\d*/, '$1').replace(/(\d)(?=(\d{3})+\b)/g, '$1,');
};

/**
 * Strip commas from formatted currency values and return as float
 *
 * @param {string} val - Currency input value
 */
export const convertDisplayCurrencyToFloat = (val: string): number => (
  parseFloat(val.replace(',', ''))
);

const phoneUtil = PhoneNumberUtil.getInstance();
/**
 * https://www.npmjs.com/package/google-libphonenumber
 * Validates that an phone number is well-formed & legitimate
 * @param phoneNumber The  phone number that will be validated
 */
export const isPhoneNumberValid = (phoneNumber?: string, countryCode = 'AU'): boolean => {
  try {
    const number = phoneUtil.parse(phoneNumber, countryCode);
    const isValid = phoneUtil.isValidNumberForRegion(number, countryCode);
    return isValid;
  } catch (e) {
    return false;
  }
};
/**
 * Round large number values to have thousands represented as 'k' and millions as 'm'
 * Will also round number to strip decimal values
 *
 * @param {number} value - Number input value
 *
 * @example
 * formatLargeNumber(21000);
 * returns '21k'
 * @example
 * formatLargeNumber(12500);
 * returns '12.5k'
 */
export const formatLargeNumber = (val: number): string => {
  if (val > 999 && val < 1000000) {
    return `${Math.round((val / 1000) * 10) / 10}k`;
  }
  if (val > 1000000) {
    return `${Math.round((val / 1000000) * 10) / 10}m`;
  }
  return `${Math.round(val * 100) / 100}`;
};

/**
 * Returns an array of years as strings between a given year and current one
 *
 * @param {string} start - Start year input value
 *
 * @example
 * if current yeah is 2021
 * getYears(2016);
 * returns ['2021', 2020', '2019', '2018', '2017', '2016']
 */
export const generateChartSelectYears = (start: string): string[] => {
  const currentYear = dayjs().format('YYYY');
  const diff = dayjs(currentYear).diff(start, 'years', true);
  return Array(diff + 1).fill(null)
    .map((_, i) => dayjs(currentYear).subtract(i, 'year').format('YYYY'));
};
