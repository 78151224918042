import styled from 'styled-components/macro';

export const LoadingSpinner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Spinner = styled.div`
  border: 7px solid ${({ theme }) => theme.palette.grey02};
  border-top: 7px solid ${({ theme }) => theme.basePalette.navyMain};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 1em;
  animation: spin .6s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
