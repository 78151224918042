import styled from 'styled-components/macro';

import { MenuProps, MenuToggleProps } from './types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

export const MenuContainer = styled.div<MenuProps>`
  background: ${({ theme }) => theme.basePalette.white};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.basePalette.navyMain};
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  padding: ${({ theme }) => theme.spacing.XXS}rem;
  position: fixed;
  transform: translate(-110%, -50%);
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  z-index: 1;

  &:before {
    content:"";
    position: absolute;
    bottom: auto;
    left: auto;
    border-style: solid;
    border-color: transparent ${({ theme }) => theme.basePalette.navyMain};
    transform: translate(100%, -50%);
    display: block;
    width: 0;
    top: 50%;
    right: 0;
    border-width: 7px 0 7px 12px;
  }

  &:after {
    content:"";
    position:absolute;
    bottom:auto;
    left:auto;
    border-style: solid;
    border-color: transparent ${({ theme }) => theme.basePalette.white};
    display: block;
    width: 0;
    top: 50%;
    transform: translate(100%, -50%);
    right: 0;
    border-width: 5px 0 5px 10px;
  }
`;

export const MenuOptions = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

export const MenuToggle = styled.img<MenuToggleProps>`
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  padding-left: ${({ theme }) => theme.spacing.XXS}rem;
  padding-right: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const MenuItem = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.basePalette.navyMain};
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const MenuItemIcon = styled.img`
  color: ${({ theme }) => theme.basePalette.navyMain};
  margin-right: 14px;
`;
