/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import dayjs from 'dayjs';
import 'firebase/storage';
import { useDispatch } from 'react-redux';

import useStrings from '../../../../hooks/useStrings';
import { loadReceiptBlob } from '../../../../lib/Media';
import { generatePlanFileName } from '../../../../lib/utils/plan';
import { buildHandlers } from '../../../../components/Common/Table/handlers';
import { setNotification } from '../../../../lib/store/contexts/notification/actions';
import {
  PLAN_STATUS_DISPLAY,
  PLAN_TYPE_DISPLAY_ADMIN_TABLES,
} from '../../../../CONSTANTS';

import Pagination from '../../../../components/Common/Table/Pagination';

import {
  Select,
  TableJssOverrides,
  NoRecordsText,
} from '../../../../components/Common/Table/Styles';
import { ReceiptLink } from '../Styles';

import { ArchivedPlansTableProps } from './types';
import { TableColumn, TableRow } from '../../../../components/Common/Table/types';
import {
  APIThunkDispatch,
  OrderDirection,
  PlanFilterOptions,
} from '../../../../lib/types/API';
import { PlanDataView } from '../../../../lib/types/DBViews';
import LoadingScreen from '../../../../components/UI/LoadingScreen';
import { FileMeta } from '../../../../lib/types/Media';

const ArchivedPlansTable: React.FC<ArchivedPlansTableProps<PlanFilterOptions>> = ({
  currentData,
  recordCount,
  filter,
  updateFilter,
  onRowClicked,
  clearFilters,
}) => {
  const Dispatch = useDispatch<APIThunkDispatch>();
  const [{ GenericText, Components: { Common: { Table: TableStrings } } }] = useStrings();
  const [preparedData, setPreparedData] = useState<(TableRow & FileMeta)[]>(); // currentData + any loaded image files.

  useEffect(() => {
    const preparedRows: (TableRow & FileMeta)[] = [];
    if (!currentData.length) return;
    // For each row of data, attach an externally downloaded receipt url, if present.
    (async () => {
      for (const row of currentData) {
        const preparedRow = row;
        if (row.receiptLink) {
          const fileMeta = await loadReceiptBlob(
            row.receiptLink as string,
            generatePlanFileName(row),
            GenericText.downloadError,
          );
          if (fileMeta) preparedRow.fileMeta = fileMeta;
        }
        preparedRows.push(preparedRow);
      }
      setPreparedData(preparedRows);
    })().catch(() => null);

    // Revoke urls on cleanup
    return () => {
      preparedRows?.forEach((row: (TableRow & FileMeta)) => {
        if (!row.fileMeta) return;
        const { url, error } = row.fileMeta as FileMeta;
        if (url && !error) URL.revokeObjectURL(url);
      });
    };
  }, [currentData, GenericText]);

  const alertFileError = (error: string) => Dispatch(setNotification({ message: error, variant: 'danger' }));

  const columns: TableColumn<PlanDataView>[] = [
    {
      name: 'Type',
      selector: 'planType',
      sortable: true,
      grow: 0.8,
      format: (row) => PLAN_TYPE_DISPLAY_ADMIN_TABLES[row.planType as string],
    },
    {
      name: 'Reg No',
      selector: 'registration',
      sortable: true,
      grow: 0.75,
      format: (row) => row.registration?.toString() || '',
    },
    {
      name: 'Plan Name',
      selector: 'planName',
      sortable: true,
      grow: 1.5,
    },
    {
      name: 'End Date',
      selector: 'endDate',
      sortable: true,
      grow: 1,
      format: (row) => dayjs((row.scheduledEndDate || row.endDate) as Date).format('DD/MM/YYYY'),
    },
    {
      name: 'Due Date',
      selector: 'dueDate',
      sortable: true,
      grow: 1,
      format: (row) => dayjs(row.dueDate as Date).format('DD/MM/YYYY'),
    },
    {
      name: 'Paid Date',
      selector: 'paidDate',
      sortable: true,
      grow: 1,
      format: (row) => (row.paidDate ? dayjs(row.paidDate as Date).format('DD/MM/YYYY') : 'N/A'),
    },
    {
      name: 'Amount',
      selector: 'value',
      sortable: true,
      grow: 0.5,
      format: (row) => `$${Number(row.value).toFixed(2)}`,
    },
    {
      name: 'Receipt',
      selector: 'receiptLink',
      sortable: true,
      grow: 0.5,
      cell: ({ fileMeta }) => {
        if (!fileMeta) return 'N/A';
        const { url, error, fileName } = fileMeta as FileMeta;
        if (error) {
          // If error loading file, preserve 'download' button so user knows receipt should be present.
          return (
            <ReceiptLink onClick={() => alertFileError(error)}>{GenericText.download}</ReceiptLink>
          );
        } if (url) {
          return (
            <a href={url} download={fileName}>
              <ReceiptLink>{GenericText.download}</ReceiptLink>
            </a>
          );
        }
        return 'N/A';
      },
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      grow: 1.8,
      style: {
        minWidth: '125px',
      },
      cell: ({ status, id }) => (
        <Select
          status={status as string}
          statusType="PLAN"
          key={id}
          disabled
        >
          <option value={status as string} key={id}>
            {PLAN_STATUS_DISPLAY[status as string]}
          </option>
        </Select>
      ),
    },
  ];

  const {
    handlePageChange,
    handleSortChange,
  } = buildHandlers<PlanFilterOptions>(
    updateFilter,
    filter,
    undefined,
    undefined,
    clearFilters,
  );

  const noRecords = (
    <NoRecordsText>
      <p>{TableStrings.noRecords}</p>
    </NoRecordsText>
  );

  if (!preparedData?.length && currentData.length) return <LoadingScreen />;

  return (
    <DataTable
      subHeaderAlign="left"
      highlightOnHover
      noContextMenu
      noHeader
      onRowClicked={onRowClicked}
      data={preparedData || []}
      keyField="key"
      columns={columns}
      pagination
      paginationServer
      paginationPerPage={filter.rowsPerPage}
      paginationTotalRows={recordCount}
      paginationDefaultPage={filter.page}
      onChangePage={handlePageChange}
      paginationComponent={Pagination}
      onSort={handleSortChange}
      sortServer
      defaultSortField={filter.orderColumn}
      defaultSortAsc={filter.orderDirection === OrderDirection.ASC}
      noDataComponent={noRecords}
      customStyles={TableJssOverrides}
      style={{ borderRadius: 0 }}
    />
  );
};

export default ArchivedPlansTable;
