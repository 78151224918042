import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useStrings from '../../../hooks/useStrings';
import {
  getCustomerEnquiries,
  updateThreadStatus,
  defaultFilter,
} from '../../../lib/api/admin/contact';

import PageLayout from '../../../layouts/PageLayout';
import Grid from '../../../components/Common/Grid';
import EmailTable from './EmailTable';
import EmailView from './EmailView';

import { ListWrapper } from './Styles';

import { SupportProps } from './types';
import { APIThunkDispatch, APIFilterOptions } from '../../../lib/types/API';
import { SupportMessageListView } from '../../../lib/types/DBViews';
import { TableRow } from '../../../components/Common/Table/types';
import { SupportMessageStatus } from '../../../lib/types/SupportMessage';

const Support: React.FC<SupportProps> = () => {
  const [{ Pages: { Support: Strings } }] = useStrings();
  const dispatch: APIThunkDispatch = useDispatch();
  const [filter, setFilter] = useState<APIFilterOptions>(defaultFilter);
  const [supportMessages, setSupportMessages] = useState<{
    data: SupportMessageListView[],
    count: number,
  }>({ data: [], count: 0 });
  const [selectedMessage, setSelectedMessage] = useState<SupportMessageListView>();

  const updateFilter = (newFilter: APIFilterOptions) => {
    setFilter({
      ...defaultFilter,
      ...newFilter,
    });
  };

  useEffect(() => {
    (async () => {
      const { data } = await dispatch(getCustomerEnquiries(filter));
      if (data) {
        setSupportMessages({
          data: data.messages.results,
          count: data.messages.count,
        });
        if (data.messages.results.length) setSelectedMessage(data.messages.results[0]);
      }
    })().catch(() => null);
  }, [dispatch, filter]);

  const handleRowClicked = (row: TableRow) => {
    const record = supportMessages.data.find((entry) => entry.supportMessageId === row.id);
    if (record) setSelectedMessage(record);
  };

  const onUpdateThreadStatus = async (supportMessageId: number, status: SupportMessageStatus) => {
    const { data } = await dispatch(updateThreadStatus(supportMessageId, status));
    if (data) {
      const newState = supportMessages;
      const index = newState.data.findIndex((entry) => entry.supportMessageId === supportMessageId);
      if (index !== -1) newState.data[index] = data.message;
      setSupportMessages(newState);
      setSelectedMessage(data.message);
    }
  };

  const currentData = supportMessages.data.map((message) => ({ id: message.supportMessageId, ...message }));

  return (
    <PageLayout
      pageTitle={`📨 ${Strings.pageTitle}`}
      maxWidth="100%"
    >
      <Grid row>
        <Grid column sm={12} md={6} alignItems="center">
          <ListWrapper>
            <EmailTable
              currentData={currentData}
              filter={filter}
              updateFilter={updateFilter}
              recordCount={supportMessages.count}
              onRowClicked={handleRowClicked}
              selectedMessage={selectedMessage}
            />
          </ListWrapper>
        </Grid>
        <Grid column sm={12} md={6} alignItems="center">
          { selectedMessage
          && <EmailView supportMessage={selectedMessage} handleUpdateThreadStatus={onUpdateThreadStatus} /> }
        </Grid>
      </Grid>
    </PageLayout>

  );
};

export default Support;
