import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import dayjs from 'dayjs';

import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import useStrings from '../../../hooks/useStrings';
import { PLAN_TYPE_DISPLAY } from '../../../CONSTANTS';

import Button from '../Button';
import Input from '../Input';
import Dropdown from '../Dropdown';
import { Spinner } from '../../UI/LoadingScreen/Styles';

import {
  ModalBackground,
  ModalWrapper,
  ButtonWrapper,
  TextWrapper,
  ModalTitle,
  ModalSubtext,
  NoPlansText,
  LoaderContainer,
} from './Styles';

import { TransferFundsModalProps } from './types';
import { ApplicationState } from '../../../lib/store';
import { APIThunkDispatch } from '../../../lib/types/API';
import { getTransferablePlans } from '../../../lib/api/admin/plans';
import { PlanType } from '../../../lib/types/Plan';

const TransferFundsModal: React.FC<TransferFundsModalProps> = ({ onConfirm, onCancel }) => {
  const stopClickBubbling = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();
  const [{ Components: { Common: { TransferFundsModal: TransferFundsModalStrings } }, GenericText }] = useStrings();
  const { userId } = useParams<{ userId: string, tab: string }>();
  const dispatch: APIThunkDispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);

  const { transferablePlans: plans } = useSelector((state: ApplicationState) => ({
    transferablePlans: state.admin.selectedCustomerTransferablePlanState.transferablePlans,
  }));

  const fetchTransferablePlans = useCallback(() => {
    setIsFetching(true);
    dispatch(getTransferablePlans(userId)).then(() => setIsFetching(false)).catch(() => {});
  }, [dispatch, userId]);

  useEffect(() => {
    fetchTransferablePlans();
  }, [fetchTransferablePlans]);

  const planOptionsFrom = plans.map((plan) => (
    { value: plan.planId?.toString(),
      label: `${PLAN_TYPE_DISPLAY[plan.planType]} - ${plan.planType === PlanType.VEHICLE_REGISTRATION
        ? `${plan.vehicleRegistration} (${dayjs(plan.dueDate).format('DD/MM/YY')})`
        : `${plan.planName || ''} (${dayjs(plan.dueDate).format('DD/MM/YY')})`} ` }
  ));
  const planOptionsTo = plans.map((plan) => (
    { value: plan.planId?.toString(),
      label: `${PLAN_TYPE_DISPLAY[plan.planType]} - ${plan.planType === PlanType.VEHICLE_REGISTRATION
        ? `${plan.vehicleRegistration} (${dayjs(plan.dueDate).format('DD/MM/YY')})`
        : `${plan.planName ? plan.planName : ''} (${dayjs(plan.dueDate).format('DD/MM/YY')})`} ` }
  ));
  planOptionsTo.push({ value: 'CREDIT', label: 'Credit' });

  return (
    <Formik
      initialValues={{
        planFrom: '',
        planTo: '',
        amount: NaN,
      }}
      validate={({ planTo, planFrom, amount }) => {
        const errors: { planTo?: string, planFrom?: string, amount?: string } = {};

        const planToData = plans.find((p) => (p.planId.toString() === planTo));
        const planFromData = plans.find((p) => (p.planId.toString() === planFrom));

        if (planFromData?.alreadyPaid === 0) errors.planFrom = TransferFundsModalStrings.noAmountError;
        if (!planFrom) errors.planFrom = GenericText.required;
        if (planTo !== 'CREDIT') {
          if (!planTo) errors.planTo = GenericText.required;
          if (planToData && planToData.value - planToData.alreadyPaid < amount) {
            errors.planTo = TransferFundsModalStrings.amountError;
          }
          if (planFrom === planTo) errors.planTo = TransferFundsModalStrings.samePlanError;
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await onConfirm(values.planFrom, values.planTo);
        setSubmitting(false);
      }}
      validateOnChange
    >
      {({
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        values,
      }) => (
        <ModalBackground
          onClick={onCancel}
        >
          <ModalWrapper
            onClick={stopClickBubbling}
          >
            <TextWrapper>
              <ModalTitle>
                {TransferFundsModalStrings.title}
              </ModalTitle>
              <ModalSubtext>
                {TransferFundsModalStrings.subtitle}
              </ModalSubtext>
            </TextWrapper>
            {isFetching && !plans.length ? (
              <LoaderContainer>
                <Spinner />
              </LoaderContainer>
            ) : (
              <>
                {plans.length ? (
                  <>
                    <Dropdown
                      name="planFrom"
                      options={planOptionsFrom}
                      placeholder={GenericText.pleaseSelect}
                      label={TransferFundsModalStrings.transferFromLabel}
                      onOptionClick={((option) => {
                        setFieldValue('amount', plans.find((p) => p.planId?.toString() === option)?.alreadyPaidLessFees.toFixed(2));
                        setFieldValue('planFrom', option);
                        setFieldTouched('planFrom', true, false);
                      })}
                    />
                    <Dropdown
                      name="planTo"
                      options={planOptionsTo}
                      placeholder={GenericText.pleaseSelect}
                      label={TransferFundsModalStrings.transferToLabel}
                      onOptionClick={((option) => {
                        setFieldValue('planTo', option);
                        setFieldTouched('planTo', true, false);
                      })}
                    />
                    <Input
                      name="amount"
                      type="currency"
                      label={TransferFundsModalStrings.amountLabel}
                      width="100%"
                      placeholder={TransferFundsModalStrings.amountPlaceholder}
                      disabled
                    />
                  </>
                ) : <NoPlansText>{TransferFundsModalStrings.noPlansString}</NoPlansText>}
              </>
            )}
            <ButtonWrapper>
              <Button variant="secondary" onClick={onCancel} rounded>{GenericText.cancel}</Button>
              {plans.length > 0 && (
                <Button
                  onClick={() => handleSubmit()}
                  rounded
                  disabled={isSubmitting || !values.planFrom || !values.planTo || values.planFrom === values.planTo}
                  $loading={isSubmitting}
                >
                  {GenericText.transfer}
                </Button>
              )}
            </ButtonWrapper>
          </ModalWrapper>
        </ModalBackground>
      )}
    </Formik>
  );
};

export default TransferFundsModal;
