import styled from 'styled-components';

import { PaymentAmountWrapperProps } from './types';

export const DeferPayment = styled.div`
  width: 100%;
`;

export const PaymentAmountWrapper = styled.div<PaymentAmountWrapperProps>`
  align-items: flex-start;
  background: ${({ theme, outlined }) => (outlined ? 'transparent' : theme.basePalette.greyMain)};
  border: ${({ theme }) => `1px solid ${theme.basePalette.navyMain}`};
  border-radius: 20px;
  border-width: ${({ outlined }) => (outlined ? '1px' : '0px')};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.XS / 1.2}rem;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const PaymentAmountText = styled.span`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  display: block;
  margin-bottom: 5px;
`;

export const PaymentAmount = styled.span`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
`;

export const NoRemainingPaymentsText = styled.p`
  color: ${({ theme }) => theme.basePalette.redLight};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  text-align: center;
`;