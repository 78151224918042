import {
  SET_ALL_PAYMENT_DATA,
  SET_UPDATED_PAYMENT_DATA,
  UPDATE_INSTALLMENT_STATUS,
  PaymentActionTypes,
  PaymentState,
} from './types';

import {
  setUpdatedRecordsInState,
  updateInstallmentStatusInState,
} from '../../../utils';

const initialState: PaymentState = {
  paymentData: {
    payments: [],
    count: 0,
  },
};

const PlanReducer = (
  state: PaymentState = initialState,
  action: PaymentActionTypes,
): PaymentState => {
  switch (action.type) {
    case SET_ALL_PAYMENT_DATA:
      return {
        ...state,
        paymentData: {
          payments: action.paymentData.results,
          count: action.paymentData.count,
        },
      };
    case SET_UPDATED_PAYMENT_DATA:
      return {
        ...state,
        paymentData: {
          payments: setUpdatedRecordsInState('installmentId', state.paymentData.payments, action.payments),
          count: state.paymentData.count,
        },
      };
    case UPDATE_INSTALLMENT_STATUS:
      return {
        ...state,
        paymentData: {
          payments: updateInstallmentStatusInState(
            action.installmentId,
            action.installmentStatus,
            state.paymentData.payments,
          ),
          count: state.paymentData.count,
        },
      };
    default:
      return state;
  }
};

export default PlanReducer;
