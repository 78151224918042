import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import useStrings from '../../../hooks/useStrings';
import { getIntegrationResult } from '../../../lib/api/customer/paymentService';

import SuccessModal from '../../../components/Common/SuccessModal';

import { ProcessPaymentServiceProps } from './types';
import { APIThunkDispatch } from '../../../lib/types/API';

const ProcessPaymentService: React.FC<ProcessPaymentServiceProps> = ({
  webPageToken,
  isOpen,
  onComplete,
}) => {
  const dispatch: APIThunkDispatch = useDispatch();
  const [{ GenericText, Pages: { Customer: { Dashboard: { PaymentServiceIntegration } } } }] = useStrings();

  const [fetchingResult, setFetchingResult] = useState({
    fetching: true,
    error: false,
  });

  const fetchIntegrationResult = useCallback(async () => {
    const { data, error } = await dispatch(getIntegrationResult(webPageToken));
    if (error) setFetchingResult({ fetching: false, error: true });
    if (data) setFetchingResult({ fetching: false, error: false });
  }, [webPageToken, dispatch]);

  useEffect(() => {
    if (!webPageToken) return;
    fetchIntegrationResult().catch(() => null);
  }, [webPageToken, fetchIntegrationResult]);

  const getStatusText = () => {
    if (!fetchingResult.fetching && fetchingResult.error) {
      return PaymentServiceIntegration.resultErrorText;
    }
    return PaymentServiceIntegration.fetchingResultText;
  };

  return (
    <SuccessModal
      isOpen={isOpen}
      onClose={() => {
        onComplete();
      }}
      title={PaymentServiceIntegration.title}
      subtext={getStatusText()}
      buttonText={GenericText.close}
      buttonDisabled={!!fetchingResult.fetching}
    />
  );
};

export default ProcessPaymentService;
