import { User } from '../../../../types/DBModels';

import {
  SetSelectedAdminAction,
  SET_SELECTED_ADMIN,
} from './types';

export const setSelectedAdmin = (
  adminUser: User | null,
): SetSelectedAdminAction => ({
  type: SET_SELECTED_ADMIN,
  adminUser,
});
