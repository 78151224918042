import React from 'react';

import CustomerLayout from '../../../layouts/CustomerLayout';
import CreateProfileForm from '../../../components/UI/CreateProfileForm';

import useStrings from '../../../hooks/useStrings';
import { CreateProfileProps } from './types';

const CreateProfile: React.FC<CreateProfileProps> = ({ profileData, isOnboarding, isCreateProfile, isEditProfile }) => {
  const [{ Pages: { Customer: { CreateProfile: Strings } } }] = useStrings();

  return (
    <CustomerLayout
      pageTitle={isOnboarding ? Strings.pageTitleOnboarding : Strings.pageTitle}
      pageTitleHighlight={Strings.pageTitleHighlight}
      pageSubtitle={isOnboarding ? Strings.pageSubtitle : Strings.pageSubtitleEdit}
      currentFormStep={0}
      isOnboarding={isOnboarding}
      isEditProfile={isEditProfile}
      isCreateProfile={isCreateProfile}
    >
      <CreateProfileForm profileData={profileData} isEditProfile={isEditProfile} />
    </CustomerLayout>

  );
};

export default CreateProfile;
