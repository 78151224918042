import React from 'react';

import useStrings from '../../hooks/useStrings';

import LoginLayout from '../../layouts/LoginLayout';
import ForgotPasswordForm from '../../components/UI/ForgotPasswordForm';

import { forgotPasswordBg } from '../../assets/Images';

import { ForgotPasswordProps } from './types';

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const [{ Pages: { ForgotPassword: Strings } }] = useStrings();

  return (
    <LoginLayout
      hideLogo
      backgroundImage={forgotPasswordBg}
      reversed
    >
      <ForgotPasswordForm
        titleHighlight={Strings.pageTitleHighlight}
        title={Strings.pageTitle}
        subtitle={Strings.pageSubtitle}
      />
    </LoginLayout>
  );
};

export default ForgotPassword;
