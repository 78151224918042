import { PaymentDataView } from '../../../../types/DBViews';

export const SET_CUSTOMER_PAYMENT_DATA = 'SET_CUSTOMER_PAYMENT_DATA';

export interface PaymentsState {
  payments: PaymentDataView[];
}

export interface SetCustomerPaymentDataAction {
  type: typeof SET_CUSTOMER_PAYMENT_DATA;
  payments: PaymentDataView[];
}

export type PaymentsActionTypes = SetCustomerPaymentDataAction;
