import {
  SET_VEHICLE_MAKES,
  VehicleMakesState,
  VehicleMakesActionTypes,
} from './types';

const initialState: VehicleMakesState = {
  vehicleMakes: [],
};

const vehicleMakesReducer = (
  state: VehicleMakesState = initialState,
  action: VehicleMakesActionTypes,
): VehicleMakesState => {
  switch (action.type) {
    case SET_VEHICLE_MAKES:
      return {
        ...state,
        vehicleMakes: action.vehicleMakes,
      };
    default:
      return state;
  }
};

export default vehicleMakesReducer;
