import styled from 'styled-components/macro';

import { StyledTextAreaProps } from './types';

export const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  width: 100%;
  outline: none;
  resize: none;
  background-color: ${({ theme }) => theme.palette.bgPrimary};
  border-radius: 15px;
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  padding: ${({ theme }) => theme.spacing.XXS}rem;
  margin-top: 0.5rem;

  border: 1px solid ${({ error, theme }) => (error ? theme.palette.btnError : theme.palette.btnDarkGrey)};
  
  &::-webkit-input-placeholder { /* Edge */
    font-family: ${({ theme }) => theme.fontFamily.bodyLight};
    color: ${({ theme }) => theme.basePalette.greyDark};
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: ${({ theme }) => theme.fontFamily.bodyLight};
    color: ${({ theme }) => theme.basePalette.greyDark};
  }

  &::placeholder {
    font-family: ${({ theme }) => theme.fontFamily.bodyLight};
    color: ${({ theme }) => theme.basePalette.greyDark};
  }
`;

export const TextAreaLabel = styled.div`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 5px;
  margin-right: auto;
`;

export const TextAreaError = styled.p`
  color: ${({ theme }) => theme.palette.btnError};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-style: italic;
  margin-bottom: 5px;
  margin-right: auto;
  margin-top: 5px;
`;