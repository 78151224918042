import React from 'react';

import LoginLayout from '../../../layouts/LoginLayout';
import AdminUpdatePasswordForm from '../../../components/UI/AdminUpdatePasswordForm';

import { adminLoginBg } from '../../../assets/Images';

import { UpdatePasswordProps } from './types';

const UpdatePassword: React.FC<UpdatePasswordProps> = () => (
  <LoginLayout
    backgroundImage={adminLoginBg}
    loginVariant="admin-login"
  >
    <AdminUpdatePasswordForm />
  </LoginLayout>

);

export default UpdatePassword;
