import { PaymentDataView } from '../../../../types/DBViews';
import { InstallmentStatus } from '../../../../types/Plan';

export const SET_ALL_PAYMENT_DATA = 'SET_ALL_PAYMENT_DATA';
export const SET_UPDATED_PAYMENT_DATA = 'SET_UPDATED_PAYMENT_DATA';
export const UPDATE_INSTALLMENT_STATUS = 'UPDATE_INSTALLMENT_STATUS';

export interface PaymentState {
  paymentData: {
    payments: PaymentDataView[];
    count: number;
  }
}
export interface SetAllPaymentsAction {
  type: typeof SET_ALL_PAYMENT_DATA;
  paymentData: {
    results: PaymentDataView[];
    count: number;
  }
}
export interface SetUpdatedPaymentsAction {
  type: typeof SET_UPDATED_PAYMENT_DATA;
  payments: PaymentDataView[],
}

export interface UpdateInstallmentStatusAction {
  type: typeof UPDATE_INSTALLMENT_STATUS;
  installmentId: number,
  installmentStatus: InstallmentStatus,
}

export type PaymentActionTypes =
SetAllPaymentsAction |
SetUpdatedPaymentsAction |
UpdateInstallmentStatusAction;
