import React from 'react';

import useStrings from '../../../hooks/useStrings';

import Button from '../Button';

import {
  ModalBackground,
  ModalWrapper,
  ButtonWrapper,
  TextWrapper,
  ModalTitle,
  ModalSubtext,
} from './Styles';

import { ConfirmModalProps } from './types';

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  onCancel,
  onConfirm,
  title,
  subtext,
  cancelButtonText,
  confirmButtonText,
  variant = 'primary',
  children,
}) => {
  const stopClickBubbling = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();
  const [{ GenericText: Strings }] = useStrings();

  return (
    <ModalBackground
      opened={isOpen}
      onClick={onCancel}
    >
      <ModalWrapper
        onClick={stopClickBubbling}
        variant={variant}
      >
        <TextWrapper>
          <ModalTitle>
            {title}
          </ModalTitle>
          {subtext && (
            <ModalSubtext>
              {subtext}
            </ModalSubtext>
          )}
        </TextWrapper>
        {children}
        <ButtonWrapper>
          <Button
            onClick={onCancel}
            variant="secondary"
            rounded
            width="100%"
          >
            {cancelButtonText || Strings.cancel}
          </Button>
          <Button
            onClick={onConfirm}
            variant="primary"
            rounded
            width="100%"
          >
            {confirmButtonText || Strings.confirm}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </ModalBackground>
  );
};

export default ConfirmModal;
