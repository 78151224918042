import styled from 'styled-components/macro';

export const PaginationButtonWrapper = styled.div`
  display: flex;
  width: 300px;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-wrap: wrap;
`;

export const PaginationButton = styled.div<{ active: boolean }>`
  max-width: ${({ active }) => (active ? '60px' : '40px')};
  height: 26px;
  border-radius: 5px;
  background-color: ${({ theme, active }) => (active ? theme.basePalette.blueMain : theme.basePalette.white)};
  border: 2px solid ${({ theme, active }) => (active ? theme.basePalette.blueMain : theme.palette.btnGrey)};
  color: ${({ theme, active }) => (active ? theme.basePalette.white : theme.basePalette.navyMain)};
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  display: flex;
  flex: 1;
  padding: 0.2rem 0.2rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: all .1s ease;
  cursor: pointer;
  white-space: nowrap;

  &:not(:first-child) {
    margin-left: 8px;
  }

  &:hover {
    transform: scale(1.02);
    border-color: ${({ theme }) => theme.basePalette.blueMain};
  }
  
  &:active {
    transform: scale(1);
  }

`;
