import styled from 'styled-components';

export const CreditsHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;

  margin: ${({ theme }) => theme.spacing.XS}rem;
`;

export const Title = styled.p`
color: ${({ theme }) => theme.basePalette.navyMain};
font-family: ${({ theme }) => theme.fontFamily.bodyBold};
font-size: 1.125rem;
margin-bottom: 5px;
`;

export const SubTitle = styled.p`
color: ${({ theme }) => theme.basePalette.greyDark};
font-family: ${({ theme }) => theme.fontFamily.bodyLight};
font-size: 0.875rem;
`;

export const TableWrapper = styled.table`
width: 100%;
margin-top: 2rem;
`;

export const TableRow = styled.tr`
`;

export const Label = styled.th`
color: ${({ theme }) => theme.basePalette.greyDark};
font-family: ${({ theme }) => theme.fontFamily.bodyBold};
font-size: 0.875rem;
text-align: left; 
border-bottom: 1rem solid transparent;
`;

export const Value = styled.td`
color: ${({ theme }) => theme.basePalette.greyDark};
font-family: ${({ theme }) => theme.fontFamily.bodyLight};
font-size: 0.875rem;
border-bottom: 1rem solid transparent;
`;