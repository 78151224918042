import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, generatePath } from 'react-router';
import { useParams } from 'react-router-dom';

import useStrings from '../../../hooks/useStrings';
import { signOut } from '../../../lib/api/Auth';
import { SETTINGS_TABS_DISPLAY } from '../../../CONSTANTS';

import PageLayout from '../../../layouts/PageLayout';
import ConfirmModal from '../../../components/Common/ConfirmModal';
import Grid from '../../../components/Common/Grid';
import AdminAccounts from './AdminAccounts';
import EditProfile from './EditProfile';
import LoadingScreen from '../../../components/UI/LoadingScreen';

import {
  MainWrapper,
  LineBreak,
  TabButton,
  TabWrapper,
} from './Styles';

import { SettingsProps, SettingsView } from './types';
import { APIThunkDispatch } from '../../../lib/types/API';
import { ApplicationState } from '../../../lib/store';

const Settings: React.FC<SettingsProps> = () => {
  const { tab } = useParams<{ tab: string }>();
  const [view, setView] = useState(
    Object.keys(SETTINGS_TABS_DISPLAY).find((key) => SETTINGS_TABS_DISPLAY[key] === tab),
  );
  const [{ Pages: { Settings: settingsStrings } }] = useStrings();

  const user = useSelector((state: ApplicationState) => state.admin.adminUserState.adminUser);

  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const history = useHistory();
  const Dispatch: APIThunkDispatch = useDispatch();

  const onSignOut = async () => {
    await Dispatch(signOut());
    history.push('/');
  };
  const viewTab = (thisView: SettingsView) => {
    const buttonText = {
      [SettingsView.EDIT_PROFILE]: settingsStrings.tabText.editProfile,
      [SettingsView.ADMIN_ACCOUNTS]: settingsStrings.tabText.adminAccounts,
    };
    return (
      <TabButton
        active={view === thisView}
        onClick={() => {
          setView(thisView);
          history.replace({
            pathname: generatePath('/admin/settings/:tab', { tab: SETTINGS_TABS_DISPLAY[thisView] }),
          });
        }}
      >
        {buttonText[thisView]}
      </TabButton>
    );
  };

  if (!user) return <LoadingScreen />;
  return (
    <PageLayout
      pageTitle={settingsStrings.pageTitle}
      maxWidth="100%"
    >
      <ConfirmModal
        isOpen={logoutModalOpen}
        onConfirm={onSignOut}
        onCancel={() => setLogoutModalOpen(false)}
        title={settingsStrings.logoutModalTitle}
        subtext={settingsStrings.logoutModalSubtext}
        cancelButtonText={settingsStrings.cancelButton}
        confirmButtonText={settingsStrings.confirmLogoutButton}
      />
      <Grid>
        <Grid row marginTop="2rem" maxWidth="100%">
          <Grid column sm={12}>
            <MainWrapper>
              <TabWrapper>
                {viewTab(SettingsView.EDIT_PROFILE)}
                {viewTab(SettingsView.ADMIN_ACCOUNTS)}
              </TabWrapper>
              <Grid row marginTop="0.5rem" maxWidth="100%">
                <LineBreak />
              </Grid>
              <Grid row maxWidth="100%">
                <Grid column sm={12} alignItems="center" expanded expandedMobile>
                  {view === SettingsView.EDIT_PROFILE && (
                  <EditProfile
                    admin={user}
                    onLogoutButtonPress={() => setLogoutModalOpen(true)}
                  />
                  )}
                  {view === SettingsView.ADMIN_ACCOUNTS && (
                  <AdminAccounts
                    user={user}
                  />
                  )}
                </Grid>
              </Grid>
            </MainWrapper>
          </Grid>
        </Grid>
      </Grid>

    </PageLayout>
  );
};

export default Settings;
