import React from 'react';

import {
  Container,
  NoDataText,
} from './Styles';

import useStrings from '../../../../hooks/useStrings';

const NoData: React.FC = () => {
  const [{ Pages: { Admin: { Dashboard: Strings } } }] = useStrings();

  return (
    <Container>
      <NoDataText>{Strings.noData}</NoDataText>
    </Container>
  );
};

export default NoData;
