import React from 'react';

import useStrings from '../../../../../hooks/useStrings';
import { generateDropdownLabel } from '../../../../../lib/utils/styleUtils';
import { stateOptions, customerStatusOptions } from '../../../../../CONSTANTS';

import Grid from '../../../../../components/Common/Grid';
import TableFilterDropdown from '../../../../../components/Common/Table/SubComponents/TableFilterDropdown';

import { searchIcon } from '../../../../../assets/Icons';
import { IconWrapper, StyledInput } from '../../../../../components/Common/Table/Styles';

import { SubHeaderProps } from './types';

const SubHeader: React.FC<SubHeaderProps> = ({
  searchTerm,
  filter,
  onCustomerStatusChange,
  onStateChange,
  onSearchChange,
}) => {
  const [{ Pages: { Users } }] = useStrings();

  return (
    <Grid row>
      <IconWrapper marginRight>
        <img alt="search" src={searchIcon} />
        <StyledInput type="text" value={searchTerm} onChange={onSearchChange} />
      </IconWrapper>
      <TableFilterDropdown
        name="customerStatus"
        options={customerStatusOptions}
        selectedOptions={filter.customerStatus || []}
        overrideLabel={generateDropdownLabel(Users.customerStatusFilterDropdownLabel, customerStatusOptions.filter(
          (option) => filter.customerStatus?.some((filtered) => filtered.toString() === option.value),
        ))}
        placeholder={Users.customerStatusFilterDropdownLabel}
        onOptionClick={onCustomerStatusChange}
      />
      <TableFilterDropdown
        name="state"
        options={stateOptions}
        selectedOptions={filter.state || []}
        overrideLabel={generateDropdownLabel(Users.stateFilterDropdownLabel, stateOptions.filter(
          (option) => filter.state?.some((filtered) => filtered.toString() === option.value),
        ))}
        placeholder={Users.stateFilterDropdownLabel}
        onOptionClick={onStateChange}
      />
    </Grid>
  );
};

export default SubHeader;
