import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Formik } from 'formik';

import { updatePassword } from '../../../lib/Authentication';
import useStrings from '../../../hooks/useStrings';
import updatePasswordSchema from '../../../lib/validators/updatePasswordSchema';

import Grid from '../../Common/Grid';
import Input from '../../Common/Input';
import Button from '../../Common/Button';
import SuccessModal from '../../Common/SuccessModal';

import {
  FormSection,
  ButtonWrapper,
  LineBreak,
} from './Styles';

import { UpdatePasswordFormProps } from './types';
import { setNotification } from '../../../lib/store/contexts/notification/actions';

const UpdatePasswordForm: React.FC<UpdatePasswordFormProps> = () => {
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [{ Components: { UI: { UpdatePasswordForm: Strings } }, GenericText }] = useStrings();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      <SuccessModal
        isOpen={isModalShowing}
        onClose={() => {
          setIsModalShowing(false);
          history.push('/');
        }}
        title={Strings.modalTitle}
        titleHighlight={Strings.modalTitleHighlight}
        subtext={Strings.modalSubtext}
      />
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
        }}
        validationSchema={updatePasswordSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async ({ currentPassword, newPassword }, { setSubmitting }) => {
          await updatePassword(currentPassword, newPassword).then(() => {
            setIsModalShowing(true);
            setSubmitting(false);
          }).catch((error) => {
            dispatch(setNotification({
              message: error.message,
              variant: 'danger',
              duration: 5,
            }));
          });
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <FormSection>
              <Grid row>
                <Grid column sm={12} md={6} alignItems="center">
                  <Input
                    type="password"
                    name="currentPassword"
                    label={Strings.currentPasswordInput}
                    placeholder={Strings.currentPasswordInput}
                    width="100%"
                    outlined
                  />
                </Grid>
                <Grid column sm={12} md={6} alignItems="center">
                  <Input
                    type="password"
                    name="newPassword"
                    label={Strings.newPasswordInput}
                    placeholder={Strings.newPasswordInput}
                    width="100%"
                    outlined
                  />
                </Grid>
              </Grid>
            </FormSection>
            <Grid row marginTop="1rem">
              <LineBreak />
            </Grid>
            <ButtonWrapper>
              <Button
                margin="0"
                width="160px"
                onClick={handleSubmit}
                disabled={isSubmitting}
                $loading={isSubmitting}
                expandedMobile={false}
              >
                {GenericText.save}
              </Button>
            </ButtonWrapper>
          </>
        )}
      </Formik>
    </>
  );
};

export default UpdatePasswordForm;
