import * as yup from 'yup';
import dayjs from 'dayjs';

import { State, Gender } from '../types/Customer';
import { isPhoneNumberValid } from '../utils/index';

const createCustomerProfileSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Invalid Email Address')
    .required('Email is required'),
  firstName: yup
    .string()
    .trim()
    .required('First name is required'),
  lastName: yup
    .string()
    .trim()
    .required('Last name is required'),
  dob: yup
    .date()
    .min(dayjs('01/01/1900'), 'Please enter a valid Date of Birth')
    .max(dayjs().toDate(), 'Date of Birth must be in the past')
    .nullable()
    .required('Date of Birth is required'),
  phone: yup
    .string()
    .trim()
    .test({ name: 'is-valid-phone', test: (value) => isPhoneNumberValid(value) })
    .required('Phone number is required'),
  gender: yup
    .string()
    .trim()
    .typeError('Please select gender')
    .oneOf(Object.values(Gender))
    .required('Gender is required'),
  address: yup
    .string()
    .trim()
    .required('Address is required'),
  suburb: yup
    .string()
    .trim()
    .required('Suburb is required'),
  state: yup
    .string()
    .trim()
    .typeError('Please select state')
    .oneOf(Object.values(State))
    .required('State is required'),
  postcode: yup
    .string()
    .trim()
    .matches(/^[0-9]{4}$/, 'Post Code is not valid')
    .required('Post code is required'),
}).required();

export type CreateCustomerProfileSchema = yup.InferType<typeof createCustomerProfileSchema>;

export default createCustomerProfileSchema;
