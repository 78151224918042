import {
  SET_CUSTOMER_CREDIT_HISTORY,
  CreditHistoryActionTypes,
  CustomerCreditHistoryState,
} from './types';

const initialState: CustomerCreditHistoryState = {
  credits: [],
};

const CustomerCreditHistoryReducer = (
  state: CustomerCreditHistoryState = initialState,
  action: CreditHistoryActionTypes,
): CustomerCreditHistoryState => {
  switch (action.type) {
    case SET_CUSTOMER_CREDIT_HISTORY:
      return {
        ...state,
        credits: action.credits,
      };
    default:
      return state;
  }
};

export default CustomerCreditHistoryReducer;
