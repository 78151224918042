import React from 'react';
import { useHistory } from 'react-router';

import Grid from '../../../components/Common/Grid';

import useStrings from '../../../hooks/useStrings';

import { LifeSaverIcon, ReferFriendIcon } from '../../../assets/Icons';
import {
  Links,
  LinkWrapper,
  LinkIcon,
  LinkTitle,
  LinkSubTitle,
} from './Styles';

const InternalLinks: React.FC = () => {
  const [{ Pages: { Customer: { Dashboard: { InternalLinks: Strings } } } }] = useStrings();
  const history = useHistory();

  const handleContactUsClick = () => {
    history.push('/customer/contact-us');
  };

  const handleReferFriendClick = () => {
    history.push('/customer/refer-friend');
  };

  return (
    <Grid row maxWidth="100%">
      <Links>
        <LinkWrapper onClick={handleContactUsClick}>
          <LinkIcon src={LifeSaverIcon} />
          <LinkTitle>{Strings.getHelp.title}</LinkTitle>
          <LinkSubTitle>{Strings.getHelp.subtitle}</LinkSubTitle>
        </LinkWrapper>
        <LinkWrapper onClick={handleReferFriendClick}>
          <LinkIcon src={ReferFriendIcon} />
          <LinkTitle>{Strings.referFriend.title}</LinkTitle>
          <LinkSubTitle>{Strings.referFriend.subtitle}</LinkSubTitle>
        </LinkWrapper>
      </Links>
    </Grid>
  );
};

export default InternalLinks;
