import styled from 'styled-components';

export const Container = styled.div`
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none; 

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TooltipText = styled.span`
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: left;
`;
