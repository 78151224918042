import { createGlobalStyle } from 'styled-components';

import PoppinsL from '../../assets/Fonts/Poppins-Light.woff2';
import PoppinsR from '../../assets/Fonts/Poppins-Regular.woff2';
import PoppinsB from '../../assets/Fonts/Poppins-SemiBold.woff2';
import Bebas from '../../assets/Fonts/BebasNeue-Regular.woff2';
import Gotham from '../../assets/Fonts/Gotham-Medium.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Poppins Light';
        src: url(${PoppinsL}) format('woff2');
        font-display: fallback;
    }
    @font-face {
        font-family: 'Poppins Regular';
        src: url(${PoppinsR}) format('woff2');
        font-display: fallback;
    }
    @font-face {
        font-family: 'Poppins Bold';
        src: url(${PoppinsB}) format('woff2');
        font-display: fallback;
    }
    @font-face {
        font-family: 'Bebas Neue';
        src: url(${Bebas}) format('woff2');
        font-display: fallback;
    }
    @font-face {
        font-family: 'Gotham';
        src: url(${Gotham}) format('woff2');
        font-display: fallback;
    }
`;
