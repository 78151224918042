import styled from 'styled-components/macro';
import { TabButtonProps } from './types';

export const MainWrapper = styled.div`
  width: 100%;
  border-radius: 15px;
  padding: 1rem 0 2rem 0;
  box-shadow: 0 0 10 0 rgba(0,0,0,0.2);
`;

export const TabWrapper = styled.div`
  display: flex;
  width: 40%; // TODO: 80% when 4 tabs
  padding: 10px;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-wrap: wrap;
  margin: 0 auto;

  @media (max-width: 930px) {
    width: 67.5%;
  };

  @media (min-width: 931px) and  (max-width: 1065px) {
    width: 95%;
  };

  @media (min-width: 1080px) and (max-width: 1270px) {
    width: 90%;
  };
`;

export const TabButton = styled.div<TabButtonProps>`
  background-color: ${({ theme, active }) => (active ? theme.basePalette.blueMain : theme.basePalette.white)};
  border: 2px solid ${({ theme, active }) => (active ? theme.basePalette.blueMain : theme.basePalette.greyLight)};
  color: ${({ theme, active }) => (active ? theme.basePalette.white : theme.basePalette.navyMain)};
  font-size: ${({ theme }) => `clamp(${theme.fontScale.XS}rem, ${theme.fontScale.S}rem, ${theme.fontScale.M as number * 0.8}rem)`};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};

  display: flex;
  flex: 1;
  min-height: 35px;
  margin: 5px 10px;
  padding: 0.2rem 0.6rem;
  border-radius: 12px;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: all .1s ease;
  cursor: pointer;

  white-space: nowrap;

  &:hover {
    transform: scale(1.04);
  }

  &:active {
    transform: scale(1);
  }

  @media (min-width: 930px) {
    margin-top: 0;
  };
`;

export const LineBreak = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.basePalette.greyMain};
`;

// ** Sub page styles ** //
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.XXS}rem ${theme.spacing.XS}rem`};
  border-bottom: solid 1px ${({ theme }) => theme.basePalette.greyMain};
  min-height: 97px;
`;

export const TabTitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  color: ${({ theme }) => theme.basePalette.navyMain};
  line-height: 20px;
`;

export const TabSubTitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  color: ${({ theme }) => theme.basePalette.greyDark};
  line-height: 20px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* gap: 24px; */
  padding: ${({ theme }) => theme.spacing.XXS}rem;
  width: 100%;
`;