export enum VehicleType {
  CAR = 'CAR',
  TRUCK = 'TRUCK',
  MOTORCYCLE = 'MOTORCYCLE',
  BOAT = 'BOAT',
  CARAVAN = 'CARAVAN',
  TRAILER = 'TRAILER',
  HEAVY_VEHICLE = 'HEAVY_VEHICLE',
  UNKNOWN = 'UNKNOWN',
}
