import React from 'react';
import Grid from '../../Grid';

import { PaginationButton, PaginationButtonWrapper } from './Styles';
import { PaginationProps } from './types';

const PaginationComponent: React.FC<PaginationProps> = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  currentPage,
}) => {
  const numberOfPages = Math.ceil(rowCount / rowsPerPage);

  const setNextPage = () => onChangePage(currentPage === numberOfPages ? numberOfPages : currentPage + 1);
  const setPreviousPage = () => onChangePage(currentPage === 1 ? 1 : currentPage - 1);
  const setFirstPage = () => onChangePage(1);
  const setLastPage = () => onChangePage(numberOfPages);

  return (
    <Grid>
      <Grid row marginTop="2rem" maxWidth="100%">
        <Grid column sm={12}>
          <PaginationButtonWrapper>
            <PaginationButton key={1} onClick={setFirstPage} active={false}>{'|<'}</PaginationButton>
            <PaginationButton key={2} onClick={setPreviousPage} active={false}>{'<'}</PaginationButton>
            <PaginationButton key={3} active>{`${currentPage} of ${numberOfPages}`}</PaginationButton>
            <PaginationButton key={4} onClick={setNextPage} active={false}>{'>'}</PaginationButton>
            <PaginationButton key={5} onClick={setLastPage} active={false}>{'>|'}</PaginationButton>
          </PaginationButtonWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaginationComponent;
