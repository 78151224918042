import styled from 'styled-components/macro';
import { Palette } from '../../../../lib/types/Style';

import { PlanInfoTextProps, ProgressFillProps } from './types';

export const Container = styled.div`
  background: ${({ theme }) => theme.basePalette.white};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    margin-bottom: ${({ theme }) => theme.spacing.XS}rem;
  }
`;

export const ContentWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.XS / 1.3}rem;
  width: 100%;
`;

export const PlanTypeText = styled.span`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-size: ${({ theme }) => theme.fontScale.M as number * 1.0625}rem;
  margin-bottom: 5px;
  display: block;
`;
export const PlanInfoWrapper = styled.div`
  height: 3rem;
`;

export const DueDateWrap = styled.div`
  background: ${({ theme }) => theme.basePalette.navyMain};
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: ${({ theme }) => `${theme.spacing.XXS / 1.5}rem ${theme.spacing.XS / 1.2}rem`};
  position: absolute;
  right: 0;
  top: ${({ theme }) => theme.spacing.S}rem;
`;

export const DueDate = styled.span`
  color: ${({ theme }) => theme.basePalette.white};
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
`;

export const IconWrapper = styled.div`
  align-items: center;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.borderGrey};
  border-radius: 50%;
  border-width: 1px;
  display: flex;
  height: 80px;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
  width: 80px;
`;

export const PlanInfoText = styled.span<PlanInfoTextProps>`
  color: ${({ theme, dark }) => (dark ? theme.basePalette.navyMain : theme.basePalette.greyMedium)};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const InstallmentAmount = styled.span`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-size: ${({ theme }) => theme.fontScale.L as number / 1.17}rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
`;

export const InstallmentFrequency = styled.span`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
`;

export const ProgressBar = styled.div`
  background: ${({ theme }) => theme.basePalette.greyMain};
  height: 6px;
  border-radius: 3px;
  margin-top: 8px;
  position: relative;
  width: 100%;
`;

export const ProgressTextWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProgressFill = styled.div<ProgressFillProps>`
  background: ${({ theme }) => theme.basePalette.blueMain};
  height: 6px;
  border-radius: 3px;
  bottom: 0;
  position: absolute;
  top: 0;
  width: ${({ progress }) => `${progress}%`};
`;

export const LineBreak = styled.div`
  background: ${({ theme }) => theme.basePalette.greyMain};
  height: 1px;
  width: 100%;
`;

const getStatusTextColour = (planStatus: string, palette: Palette) => {
  switch (planStatus) {
    case 'ACTIVE':
      return palette.borderGreen;
    case 'INACTIVE':
      return palette.borderYellow;
    case 'CANCELLED':
      return palette.borderRed;
    case 'DUE':
      return palette.borderOrange;
    default:
      return palette.borderGreen;
  }
};

export const StatusText = styled.span<{ planStatus: string }>`
  color: ${({ theme, planStatus }) => getStatusTextColour(planStatus, theme.palette)};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  float: right;
`;