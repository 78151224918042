import firebase from 'firebase/app';
import 'firebase/storage';
import { v4 as uuid } from 'uuid';

import { FileMeta, ProgressCallback } from './types/Media';

/**
 * Handles uploading media to Firebase Storage.
 *
 * @param media The media file to upload
 * @param storagePath File store path
 * @param onProgress upload progress
 * @param fileName The filename, else use a uid.
 *
 * @returns URL of the saved image
 * */
export const uploadMedia = async (
  media: File,
  storagePath: string,
  onProgress?: ProgressCallback,
  fileName = null,
): Promise<string | null> => {
  const ext = media.name.split('.').pop()?.toLowerCase();

  const uploadTask = firebase
    .storage()
    .ref()
    .child(`${storagePath}/${fileName || uuid()}.${ext}`)
    .put(media);

  uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, ({ bytesTransferred, totalBytes }) => {
    const progress = (bytesTransferred / totalBytes) * 100;
    if (onProgress) onProgress(progress, bytesTransferred, totalBytes);
  });
  const finalSnapshot = await uploadTask;
  return finalSnapshot.ref.getDownloadURL();
};

/**
 * Handles deleting media from Firebase Storage.
 *
 * @param url The url of the media to be deleted
 *
 * */
export const deleteMedia = async (url: string): Promise<null> => {
  await firebase
    .storage()
    .refFromURL(url)
    .delete();
  return null;
};

export const xhrRequest = (
  url: string,
  fileName: string,
  genericErrorText: string,
): Promise<string> => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = () => {
    const blob = xhr.response;
    const file = new File([blob], fileName, { type: blob.type });
    const blobURL = URL.createObjectURL(file);
    if (blobURL) resolve(blobURL);
    reject(new Error(genericErrorText));
  };

  xhr.onerror = (err) => {
    reject(err);
  };

  xhr.open('GET', url);
  xhr.send();
});

export const loadReceiptBlob = async (
  link: string,
  fileName: string,
  genericErrorText: string,
): Promise<FileMeta> => {
  const fileMeta: FileMeta = { url: '', error: '', fileName };
  try {
    // Firebase storage ref
    const storageRef = firebase.storage().refFromURL(link);
    const url = await storageRef.getDownloadURL();
    // External file request
    fileMeta.url = await xhrRequest(url, fileName, genericErrorText);
  } catch (error: unknown) {
    fileMeta.error = genericErrorText;
  }
  return fileMeta;
};

export default {
  uploadMedia,
  deleteMedia,
  xhrRequest,
  loadReceiptBlob,
};
