import styled from 'styled-components/macro';

import { StyledModalReadOnlyFieldProps } from './types';

export const StyledReadOnlyField = styled.div<StyledModalReadOnlyFieldProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  min-height: 94px;
  min-width: 195px;
  border: 1px solid ${({ theme }) => theme.palette.btnDarkGrey};
  border-radius: 24px;

  background-color: ${({ disabled, theme }) => {
    if (disabled) return theme.palette.btnDarkGrey;
    return theme.palette.white;
  }};
`;

export const StyledInput = styled.input<StyledModalReadOnlyFieldProps>`
  outline: none;
  border: none;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.M}rem;

  background-color: ${({ disabled, theme }) => {
    if (disabled) return theme.palette.btnDarkGrey;
    return theme.palette.white;
  }};

  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  &::-webkit-input-placeholder { /* Edge */
    font-family: ${({ theme }) => theme.fontFamily.bodyLight};
    color: ${({ theme }) => theme.basePalette.greyDark};
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: ${({ theme }) => theme.fontFamily.bodyLight};
    color: ${({ theme }) => theme.basePalette.greyDark};
  }

  &::placeholder {
    font-family: ${({ theme }) => theme.fontFamily.bodyLight};
    color: ${({ theme }) => theme.basePalette.greyDark};
  }
`;

export const Label = styled.p<StyledModalReadOnlyFieldProps>`
  display: inline;
  color: ${({ theme }) => theme.basePalette.greyDark};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.extraLight};
  margin-bottom: 5px;
`;
