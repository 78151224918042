import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../../../lib/store';
import { getCustomerPlansPaginated, defaultFilter } from '../../../lib/api/customer/plans';

import ReceiptsTable from './ReceiptsTable';
import { buildHandlers } from '../../../components/Common/Table/handlers';

import { APIThunkDispatch, PlanFilterOptions } from '../../../lib/types/API';
import { ReceiptsProps } from './types';
import { PlanStatus } from '../../../lib/types/Plan';

const Receipts: React.FC<ReceiptsProps> = () => {
  const Dispatch: APIThunkDispatch = useDispatch();

  const [filter, setFilter] = useState<PlanFilterOptions>({
    ...defaultFilter,
    status: [PlanStatus.COMPLETE], // Only fetch plans with these statuses.
  });
  const clearFilters = () => setFilter({
    ...defaultFilter,
    status: [PlanStatus.COMPLETE],
  });
  const updateFilter = (newFilter: PlanFilterOptions) => {
    setFilter({
      ...defaultFilter,
      ...newFilter,
      status: [PlanStatus.COMPLETE],
    });
  };

  const { plansPaginatedState: { planData } } = useSelector((state: ApplicationState) => state.customer);
  const recordCount = planData.count;
  const receiptData = planData.plans.map((plan) => ({ ...plan, id: plan.planId }));

  const fetchCustomerPlans = useCallback(() => {
    Dispatch<void>(getCustomerPlansPaginated(filter));
  }, [Dispatch, filter]);

  useEffect(() => {
    fetchCustomerPlans();
  }, [fetchCustomerPlans]);

  const {
    handlePageChange,
  } = buildHandlers<PlanFilterOptions>(
    updateFilter,
    filter,
    undefined,
    undefined,
    clearFilters,
  );

  return (
    <ReceiptsTable
      data={receiptData}
      count={recordCount}
      rowsPerPage={filter.rowsPerPage}
      page={filter.page}
      onPageChange={handlePageChange}
    />

  );
};

export default Receipts;
