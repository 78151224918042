import { Plan } from '../../../lib/types/DBModels';

export interface CustomerProfileProps {}

export enum CustomerProfileView {
  PLANS = 'PLANS',
  ARCHIVED_PLANS = 'ARCHIVED_PLANS',
  PAYMENTS = 'PAYMENTS',
  PERSONAL_INFO = 'PERSONAL_INFO',
  NOTES = 'NOTES',
  CREDIT = 'CREDIT',
  REFERRALS = 'REFERRALS',
}

export interface TabButtonProps {
  active: boolean;
}

export type RenewPlanData = Plan & {
  renewing?: boolean,
  duplicating?: boolean,
  alreadyPaidLessFees?: number,
  alreadyPaid?: number,
  mostRecentPaymentPaidDate?: Date,
  remainingPayments?: number
  allPayments?: number
  latestInstallmentValue?: number
  inputVehicleMake?: string;
  definedVehicleMakeId?: number;
};