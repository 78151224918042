import React, { useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';

import useStrings from '../../../hooks/useStrings';
import { getUserInfoReport, getPlanInfoReport } from '../../../lib/api/admin/reports';

import Grid from '../../../components/Common/Grid';
import Button from '../../../components/Common/Button';

import PageLayout from '../../../layouts/PageLayout';

import {
  Wrapper,
  Heading,
  Details,
  InfoWrapper,
  ButtonWrapper,
  LineBreak,
} from './Styles';
import { Spinner } from '../../../components/UI/LoadingScreen/Styles';

import { ReportsProps } from './types';
import { APIThunkDispatch } from '../../../lib/types/API';

const Reports: React.FC<ReportsProps> = () => {
  const [{ GenericText, Pages: { Reports: reportsStrings } }] = useStrings();
  const dispatch: APIThunkDispatch = useDispatch();

  const [userInfoReportData, setUserInfoReportData] = useState({
    data: [],
    downloadJSX: <></>,
    loading: false,
  });
  const [planInfoReportData, setPlanInfoReportData] = useState({
    data: [],
    downloadJSX: <></>,
    loading: false,
  });

  const userReportClickRef = useRef<HTMLElement>(null);
  const planReportClickRef = useRef<HTMLElement>(null);

  // fetch & download report data
  const handleGenerateUserInfo = useCallback(async () => {
    setUserInfoReportData({ data: userInfoReportData.data, loading: true, downloadJSX: <></> });
    const { data } = await dispatch(getUserInfoReport());
    if (!data) {
      setUserInfoReportData({ data: userInfoReportData.data, loading: false, downloadJSX: <></> });
      return;
    }

    const headers = [
      reportsStrings.userReportHeaders.uniqueUserId,
      reportsStrings.userReportHeaders.customerName,
      reportsStrings.userReportHeaders.gender,
      reportsStrings.userReportHeaders.email,
      reportsStrings.userReportHeaders.phone,
      reportsStrings.userReportHeaders.address,
      reportsStrings.userReportHeaders.subscribedToNewsletter,
      reportsStrings.userReportHeaders.status,
    ];

    const userData = data.userInfo.map((userInfo) => ([
      userInfo.userId,
      userInfo.customerName,
      userInfo.gender,
      userInfo.email,
      userInfo.phone,
      userInfo.fullAddress,
      userInfo.newsletterSubscription ? GenericText.yes : GenericText.no,
      userInfo.status,
    ]));
    const csvData = [headers, ...userData];
    const linkProps = {
      filename: `${reportsStrings.userInfoReportTitle} ${dayjs().format('DD-MM-YYYY')}.csv`,
      style: { display: 'none' },
      data: csvData,
      target: '_blank',
    };

    setUserInfoReportData({
      data: userInfoReportData.data,
      loading: false,
      downloadJSX: <CSVLink {...linkProps}><span ref={userReportClickRef} /></CSVLink>,
    });
    if (userReportClickRef.current) {
      userReportClickRef.current.click();
    }
  }, [dispatch, userInfoReportData, reportsStrings, GenericText]);

  const handleGeneratePlanInfo = useCallback(async () => {
    setPlanInfoReportData({ data: planInfoReportData.data, loading: true, downloadJSX: <></> });
    const { data } = await dispatch(getPlanInfoReport());
    if (!data) {
      setPlanInfoReportData({ data: planInfoReportData.data, loading: false, downloadJSX: <></> });
      return;
    }

    const headers = [
      reportsStrings.planReportHeaders.uniqueUserId,
      reportsStrings.planReportHeaders.customerName,
      reportsStrings.planReportHeaders.planName,
      reportsStrings.planReportHeaders.planType,
      reportsStrings.planReportHeaders.planConcessionCard,
      reportsStrings.planReportHeaders.planStartDate,
      reportsStrings.planReportHeaders.planEndDate,
      reportsStrings.planReportHeaders.planDueDate,
      reportsStrings.planReportHeaders.registrationNo,
      reportsStrings.planReportHeaders.registrationDuration,
      reportsStrings.planReportHeaders.registrationState,
      reportsStrings.planReportHeaders.vehicleType,
      reportsStrings.planReportHeaders.vehicleMake,
      reportsStrings.planReportHeaders.vehicleModel,
      reportsStrings.planReportHeaders.vehicleYear,
      reportsStrings.planReportHeaders.vehicleColor,
      reportsStrings.planReportHeaders.planAmount,
      reportsStrings.planReportHeaders.adminFee,
      reportsStrings.planReportHeaders.installmentAmount,
      reportsStrings.planReportHeaders.installmentFrequency,
      reportsStrings.planReportHeaders.planStatus,
    ];

    const planData = data.planInfo.map((planInfo) => ([
      planInfo.userId,
      planInfo.customerName,
      planInfo.planName,
      planInfo.planType,
      planInfo.concessionCard,
      planInfo.startDate,
      planInfo.endDate,
      planInfo.dueDate,
      planInfo.registration,
      planInfo.registrationLength,
      planInfo.vehicleRegisteredState,
      planInfo.vehicleType,
      planInfo.definedVehicleMake ? planInfo.definedVehicleMake : planInfo.inputVehicleMake,
      planInfo.vehicleModel,
      planInfo.vehicleYear,
      planInfo.vehicleColor,
      planInfo.value,
      planInfo.adminFee,
      planInfo.installmentAmount,
      planInfo.frequency,
      planInfo.status,
    ]));
    const csvData = [headers, ...planData];
    const linkProps = {
      filename: `${reportsStrings.planInfoReportTitle} ${dayjs().format('DD-MM-YYYY')}.csv`,
      style: { display: 'none' },
      data: csvData,
      target: '_blank',
    };

    setPlanInfoReportData({
      data: planInfoReportData.data,
      loading: false,
      downloadJSX: <CSVLink {...linkProps}><span ref={planReportClickRef} /></CSVLink>,
    });
    if (planReportClickRef.current) {
      planReportClickRef.current.click();
    }
  }, [dispatch, planInfoReportData, reportsStrings]);

  return (
    <PageLayout
      pageTitle={`📑 ${reportsStrings.pageTitle}`}
      maxWidth="100%"
    >
      {userInfoReportData.downloadJSX}
      {planInfoReportData.downloadJSX}
      <Grid>
        <Grid row>
          <Grid column>
            <Wrapper>
              <Grid row marginTop="3rem" maxWidth="100%">
                <InfoWrapper>
                  <Heading>{reportsStrings.userInfoReport}</Heading>
                  <Details>{reportsStrings.userInfoDetails}</Details>
                </InfoWrapper>
                <ButtonWrapper>
                  { userInfoReportData.loading ? (
                    <Spinner />
                  ) : (
                    <Button
                      onClick={handleGenerateUserInfo}
                      variant={!userInfoReportData.data.length ? 'dark-inverse' : 'dark'}
                      width="220px"
                      height="24px"
                      rounded
                    >
                      {!userInfoReportData.data.length
                        ? reportsStrings.generateReportButton : reportsStrings.downloadReportButton}
                    </Button>
                  )}
                </ButtonWrapper>
              </Grid>
              <Grid row marginTop="1.5rem" maxWidth="100%">
                <LineBreak />
              </Grid>
              <Grid row marginTop="2rem" maxWidth="100%">
                <InfoWrapper>
                  <Heading>{reportsStrings.planInfoReport}</Heading>
                  <Details>{reportsStrings.planInfoDetails}</Details>
                </InfoWrapper>
                <ButtonWrapper>
                  { planInfoReportData.loading ? (
                    <Spinner />
                  ) : (
                    <Button
                      onClick={handleGeneratePlanInfo}
                      variant={!planInfoReportData.data.length ? 'dark-inverse' : 'dark'}
                      width="220px"
                      height="24px"
                      rounded
                    >
                      {!planInfoReportData.data.length
                        ? reportsStrings.generateReportButton : reportsStrings.downloadReportButton}
                    </Button>
                  )}
                </ButtonWrapper>
              </Grid>
              <Grid row marginTop="1rem" maxWidth="100%">
                <LineBreak />
              </Grid>
            </Wrapper>
          </Grid>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default Reports;
