import styled from 'styled-components/macro';

export const SideContent = styled.aside`
  width: 100vw;
  height: auto;
  background-color: ${({ theme }) => theme.basePalette.navyMain};

  ${({ theme }) => theme.device.tablet} {
    width: 250px;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none; 
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const MainContent = styled.main`
  flex-direction: column;
  padding: 2rem;
  display: flex;
  height: 100%;
  margin-top: 0;
  border-radius: .5rem;
  background-color: ${({ theme }) => theme.palette.bgPrimary};
  z-index: 1;
  align-items: center;
  -webkit-overflow-scrolling: touch;

  ${({ theme }) => theme.device.tablet} {
    border-radius: 0;
    height: auto;
    flex: 1;
    overflow-y: auto;
  }
`;
