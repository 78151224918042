import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import dayjs from 'dayjs';

import useStrings from '../../../hooks/useStrings';
import { uploadMedia } from '../../../lib/Media';
import { getPayablePlans } from '../../../lib/api/admin/plans';

import Button from '../Button';
import Dropdown from '../Dropdown';
import FileInput from '../FileInput';
import Grid from '../Grid';
import { Spinner } from '../../UI/LoadingScreen/Styles';

import {
  ModalBackground,
  ModalWrapper,
  ButtonWrapper,
  TextWrapper,
  ModalTitle,
  ModalSubtext,
  NoPlansText,
  LoaderContainer,
} from './Styles';

import { CompletePlanModalProps } from './types';
import completePlanSchema from '../../../lib/validators/completePlanSchema';
import { PlanType } from '../../../lib/types/Plan';
import { ApplicationState } from '../../../lib/store';
import { APIThunkDispatch } from '../../../lib/types/API';
import { imageHostingFilePaths, PLAN_TYPE_DISPLAY } from '../../../CONSTANTS';

const CompletePlanModal: React.FC<CompletePlanModalProps> = ({ onConfirm, onCancel, userId }) => {
  const dispatch: APIThunkDispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);

  const [{ Components: { Common: { CompletePlanModal: CompletePlanModalStrings } }, GenericText }] = useStrings();

  const imageHostingPath = imageHostingFilePaths.customerPaidPlanReceipts;
  const stopClickBubbling = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  const {
    payablePlans: plans,
  } = useSelector((state: ApplicationState) => state.admin.selectedCustomerPayablePlanState);

  const planOptions = plans.map((plan) => (
    { value: plan.planId?.toString(),
      label: `${PLAN_TYPE_DISPLAY[plan.planType]} - ${plan.planType === PlanType.VEHICLE_REGISTRATION
        ? `${plan.vehicleRegistration} (${dayjs(plan.dueDate).format('DD/MM/YY')})`
        : `${plan.planName || ''} (${dayjs(plan.dueDate).format('DD/MM/YY')})`} ` }
  ));

  const uploadFile = async (file: File, setError: (message: string) => void): Promise<string | null> => {
    const filePath = `${imageHostingPath}/${userId}`;
    try {
      const url = await uploadMedia(file, filePath);
      return url;
    } catch (err) {
      setError(CompletePlanModalStrings.uploadErrorText);
    }
    return null;
  };

  const fetchPayablePlans = useCallback(() => {
    setIsFetching(true);
    dispatch(getPayablePlans(userId)).then(() => setIsFetching(false)).catch(() => {});
  }, [dispatch, userId]);

  useEffect(() => {
    fetchPayablePlans();
  }, [fetchPayablePlans]);

  return (
    <Formik
      initialValues={{
        planId: undefined as undefined | number,
        receipt: undefined,
      }}
      validationSchema={completePlanSchema}
      validate={(values) => {
        const errors: { planId?: string, receipt?: string } = {};
        if (!values.planId) errors.planId = CompletePlanModalStrings.planRequiredString;
        if (!values.planId) errors.planId = CompletePlanModalStrings.planRequiredString;
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        const url = values.receipt && await uploadFile(
          values.receipt as unknown as File,
          (message) => setFieldError('receipt', message),
        );
        await onConfirm({
          planId: values.planId as number,
          receiptURL: url || undefined,
        });
        setSubmitting(false);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        values,
        isSubmitting,
      }) => (
        <ModalBackground
          onClick={onCancel}
        >
          <ModalWrapper
            onClick={stopClickBubbling}
          >
            <TextWrapper>
              <ModalTitle>
                {CompletePlanModalStrings.title}
              </ModalTitle>
              <ModalSubtext>
                {CompletePlanModalStrings.subtitle}
              </ModalSubtext>
            </TextWrapper>
            {isFetching && !plans.length ? (
              <LoaderContainer>
                <Spinner />
              </LoaderContainer>
            ) : (
              <>
                <Grid>
                  {plans.length ? (
                    <>
                      <Grid row maxWidth="100%">
                        <Grid column>
                          <Dropdown
                            name="planId"
                            options={planOptions}
                            placeholder={GenericText.pleaseSelect}
                            label={CompletePlanModalStrings.planDropDownLabel}
                            uppercaseLabel
                            onOptionClick={((option) => {
                              setFieldValue('planId', plans.find((p) => p.planId?.toString() === option)?.planId);
                            })}
                          />
                        </Grid>
                      </Grid>
                      <Grid row maxWidth="100%" marginTop="1rem">
                        <Grid column>
                          <FileInput
                            onFileUploadComplete={((file) => {
                              setFieldValue('receipt', file);
                              setFieldTouched('receipt', true);
                            })}
                            value={values.receipt}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Grid row maxWidth="100%">
                      <Grid column alignItems="center">
                        <NoPlansText>{CompletePlanModalStrings.noPlansString}</NoPlansText>
                      </Grid>
                    </Grid>
                  )}
                  <Grid row maxWidth="100%" marginTop="2rem">
                    <Grid column>
                      <ButtonWrapper>
                        <Button
                          onClick={onCancel}
                          variant="secondary"
                          rounded
                          width="136px"
                        >
                          {GenericText.cancel}
                        </Button>
                        {plans.length ? (
                          <Button
                            onClick={handleSubmit}
                            variant="primary"
                            rounded
                            width="136px"
                            disabled={isSubmitting || (!values.planId && !values.receipt)}
                            $loading={isSubmitting}
                          >
                            {GenericText.complete}
                          </Button>
                        ) : null}
                      </ButtonWrapper>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </ModalWrapper>
        </ModalBackground>
      )}
    </Formik>
  );
};

export default CompletePlanModal;
