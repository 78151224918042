import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

export interface NavProps {
  active?: boolean;
}

export const SideNavWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.basePalette.navyMain};
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center; 
`;

export const LogoWrapper = styled.div`
  max-width: 250px;
  padding: ${({ theme }) => theme.spacing.XS * 1.5}rem;
`;

// -------------- Nav Icon
export const SideNavIcon = styled.img<{ margin?: string; }>`
  ${({ margin = null, theme }) => (margin ? `margin: ${margin};` : `margin-right: ${theme.spacing.XXS}rem;`)}
`;

// -------------- Nav Option Text
export const SideNavLinkText = styled.span`
  color: ${({ theme }) => theme.palette.greyDark};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  line-height: 19px;
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
`;

// -------------- Nav Option
export const SideNavLink = styled(NavLink)<NavProps>`
  color: ${({ theme }) => theme.palette.navLink};
  display: flex;
  padding-left: ${({ theme }) => theme.spacing.XS}rem;
  padding-right: ${({ theme }) => theme.spacing.XS}rem;
  padding-top: ${({ theme }) => theme.spacing.XXS * 1.2}rem;
  padding-bottom: ${({ theme }) => theme.spacing.XXS * 1.2}rem;
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  text-decoration: none;
  text-align: left;
  justify-content: flex-start;
  align-items: center;

  &.active {
    color: ${({ theme }) => theme.palette.navLinkActive};
    background-color: ${({ theme }) => theme.palette.navActive};
  }

  &.active ${SideNavLinkText} {
    color: ${({ theme }) => theme.palette.navLinkHover};
    font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  }

  &:focus ${SideNavLinkText}, &:hover ${SideNavLinkText} {
    color: ${({ theme }) => theme.palette.navLinkHover};
  }
  
  &:hover ${SideNavIcon}, &.active ${SideNavIcon} {
    filter: brightness(10);
  }

  &:last-child {
    margin-top: auto;
  }
`;

export const Logout = styled.div`
  color: ${({ theme }) => theme.palette.navLink};
  display: flex;
  padding-left: ${({ theme }) => theme.spacing.XS}rem;
  padding-right: ${({ theme }) => theme.spacing.XS}rem;
  padding-top: ${({ theme }) => theme.spacing.XXS * 1.2}rem;
  padding-bottom: ${({ theme }) => theme.spacing.XXS * 1.2}rem;
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  text-decoration: none;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;
  cursor: pointer;

  &:focus ${SideNavLinkText}, &:hover ${SideNavLinkText} {
    color: ${({ theme }) => theme.palette.navLinkHover};
  }
  
  &:hover ${SideNavIcon}, &.active ${SideNavIcon} {
    filter: brightness(10);
  }
`;
