import React from 'react';
import { useField } from 'formik';

import {
  StyledRadioOuter,
  StyledRadioContainer,
  StyledRadio,
  StyledRadioLabel,
  HiddenRadio,
  Icon,
  Error,
} from './Styles';

import { checkmarkIcon } from '../../../assets/Icons';

import { RadioProps } from './types';

const Radio: React.FC<RadioProps> = ({
  name,
  options,
  disabled,
  marginBottom,
}) => {
  const [field, meta] = useField(name);

  const {
    value,
    touched,
    error,
  } = meta;

  return (
    <>
      {options.map((option) => (
        <StyledRadioOuter key={option.value}>
          {touched && error && <Error className="error">{error}</Error>}
          <StyledRadioLabel marginBottom={marginBottom}>
            <StyledRadioContainer>
              <HiddenRadio
                name={name}
                value={option.value}
                checked={option.value === value}
                disabled={disabled}
                onChange={field.onChange}
              />
              <StyledRadio checked={option.value === value} error={!!error}>
                <Icon src={checkmarkIcon} />
              </StyledRadio>
            </StyledRadioContainer>
            {option.label}
          </StyledRadioLabel>
        </StyledRadioOuter>
      ))}

    </>
  );
};

export default Radio;
