import React from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import useStrings from '../../../../hooks/useStrings';
import { updateProfile } from '../../../../lib/api/admin/customers';
import updateCustomerProfileSchema from '../../../../lib/validators/updateCustomerProfileSchema';

import Grid from '../../../../components/Common/Grid';
import Input from '../../../../components/Common/Input';
import Button from '../../../../components/Common/Button';
import DatePicker from '../../../../components/Common/DatePicker';
import Dropdown from '../../../../components/Common/Dropdown';

import { LineBreak } from '../Styles';

import { PersonalInfoProps } from './types';
import { APIThunkDispatch } from '../../../../lib/types/API';
import { stateOptions } from '../../../../CONSTANTS';

const PersonalInfo: React.FC<PersonalInfoProps> = ({
  customer,
}) => {
  const dispatch: APIThunkDispatch = useDispatch();
  const [{ GenericText, Pages: { UserProfile: { PersonalInfo: PersonalInfoStrings } } }] = useStrings();

  return (
    <Formik
      initialValues={{
        firstName: customer.user.firstName || '',
        lastName: customer.user.lastName || '',
        email: customer.user.email || '',
        phone: customer.user.phone || '',
        address: customer.address || '',
        suburb: customer.suburb || '',
        state: customer.state || '',
        postcode: customer.postcode || '',
        dob: customer.dob || new Date(),
        gender: customer.gender || '',
      }}
      validationSchema={updateCustomerProfileSchema}
      validateOnChange
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await dispatch(updateProfile(values));
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        isSubmitting,
        values,
        errors,
        touched,
      }) => (
        <>
          <Grid row marginTop="1rem">
            <Grid column sm={12} md={6} alignItems="center">
              <Input
                type="text"
                name="firstName"
                label={PersonalInfoStrings.firstNameInput}
                placeholder={PersonalInfoStrings.firstNameInput}
                width="100%"
              />
            </Grid>
            <Grid column sm={12} md={6} alignItems="center">
              <Input
                type="text"
                name="lastName"
                label={PersonalInfoStrings.lastNameInput}
                placeholder={PersonalInfoStrings.lastNameInput}
                width="100%"
              />
            </Grid>
          </Grid>
          <Grid row marginTop="1rem">
            <Grid column sm={12} md={6} alignItems="center">
              <Input
                type="text"
                name="email"
                label={PersonalInfoStrings.emailInput}
                placeholder={PersonalInfoStrings.emailInput}
                width="100%"
              />
            </Grid>
            <Grid column sm={12} md={6} alignItems="center">
              <Input
                type="text"
                name="phone"
                label={PersonalInfoStrings.phoneInput}
                placeholder={PersonalInfoStrings.phoneInput}
                width="100%"
              />
            </Grid>
          </Grid>
          <Grid row marginTop="1.5rem">
            <Grid column sm={12}>
              <LineBreak />
            </Grid>
          </Grid>
          <Grid row marginTop="1rem">
            <Grid column sm={12} md={6} alignItems="center">
              <Input
                type="text"
                name="address"
                label={PersonalInfoStrings.addressInput}
                placeholder={PersonalInfoStrings.addressInput}
                width="100%"
              />
            </Grid>
            <Grid column sm={12} md={6} alignItems="center">
              <Input
                type="text"
                name="suburb"
                label={PersonalInfoStrings.suburbInput}
                placeholder={PersonalInfoStrings.suburbInput}
                width="100%"
              />
            </Grid>
          </Grid>
          <Grid row marginTop="1rem">
            <Grid column sm={12} md={6} alignItems="center">
              <Dropdown
                name="state"
                onLoadOption={values.state}
                label={PersonalInfoStrings.stateInput}
                placeholder={PersonalInfoStrings.stateInput}
                options={stateOptions}
                onOptionClick={((option) => setFieldValue('state', option))}
              />
            </Grid>
            <Grid column sm={12} md={6} alignItems="center">
              <Input
                type="text"
                name="postcode"
                label={PersonalInfoStrings.postcodeInput}
                placeholder={PersonalInfoStrings.postcodeInput}
                width="100%"
              />
            </Grid>
          </Grid>
          <Grid row marginTop="1.5rem">
            <Grid column sm={12}>
              <LineBreak />
            </Grid>
          </Grid>
          <Grid row marginTop="1rem">
            <Grid column sm={12} md={6} alignItems="center">
              <DatePicker
                label={PersonalInfoStrings.dobInput}
                onLoadDate={values.dob}
                onSelect={(date) => setFieldValue('dob', date)}
                error={errors.dob}
                touched={touched.dob}
                setError={(error) => setFieldError('dob', error)}
                setTouched={(fieldTouched) => setFieldTouched('dob', fieldTouched)}
                maxDate={new Date()}
              />
            </Grid>
          </Grid>
          <Grid row marginTop="2rem">
            <Grid column sm={12}>
              <LineBreak />
            </Grid>
          </Grid>
          <Grid row marginTop="2rem">
            <Grid column sm={12} alignItems="flex-end" justify="flex-end">
              <Button
                margin="0"
                width="200px"
                variant="dark"
                rounded
                onClick={handleSubmit}
                disabled={isSubmitting}
                $loading={isSubmitting}
              >
                {GenericText.saveChanges}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
};

export default PersonalInfo;
