import styled from 'styled-components';

import { HeadingTextProps, ItemTextProps, MainContentProps } from './types';

export const ContentHeader = styled.div<MainContentProps>`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.bgPrimary};
  border-radius: 15px;
  ${({ open }) => open && `
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  `}
  padding: ${({ theme }) => theme.spacing.XS}rem;
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export const Icon = styled.img<MainContentProps>`
  transform: ${({ open }) => (open ? 'scaleY(-1)' : '')};
  width: 12px;

  ${({ theme }) => theme.device.tablet} {
    display: none;
  }
`;

export const MainContent = styled.div<MainContentProps>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  background: ${({ theme }) => theme.palette.bgPrimary};
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    display: block;
  }
`;

export const HeadingText = styled.span<HeadingTextProps>`
  color: ${({ theme, highlight }) => (highlight ? theme.palette.titleHighlight : theme.basePalette.navyMain)};
  font-size: ${({ theme }) => theme.fontScale.XL}rem;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

export const Subtitle = styled.span`
  display: block;
  margin-top: 5px;
`;

export const ItemText = styled.span<ItemTextProps>`
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  ${({ alignRight }) => alignRight && 'text-align: right'};
  font-family: ${({ theme, lightWeight }) => (lightWeight ? theme.fontFamily.bodyLight : theme.fontFamily.body)};
  color: ${({ theme }) => theme.basePalette.navyMain};
`;

export const FormSection = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.XS}rem 0`};

  ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => `${theme.spacing.XXS * 1.5}rem ${theme.spacing.XS}rem`};
  }
`;

export const LineBreak = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.borderGrey};
`;

export const InfoBar = styled.div`
  background: ${({ theme }) => theme.basePalette.navyMain};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 32px;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.S}rem;
`;

export const InfoBarMain = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
  }
`;

export const InfoBarTextWrap = styled.div`
  margin-bottom: 0.5rem;

  ${({ theme }) => theme.device.tablet} {
    margin-bottom: 0;
  }
`;

export const InfoBarText = styled.span<HeadingTextProps>`
  color: ${({ theme, highlight }) => (highlight ? theme.palette.titleHighlight : theme.basePalette.white)};
`;

export const InfoBarPrice = styled.span`
  color: ${({ theme }) => theme.palette.titleHighlight};
  font-size: 30px;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.extraLight};
`;

export const InfoBarFrequency = styled.span`
  color: ${({ theme }) => theme.basePalette.white};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
`;

export const AdminFee = styled.span`
  color: ${({ theme }) => theme.basePalette.white};
  display: block;
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  margin-top: 0.5rem;
`;
