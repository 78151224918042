import {
  apiFetch,
  apiRequest,
} from '../Utils';
import { serverAdminAPI } from '../../../CONSTANTS';

import { APIThunkResult } from '../../types/API';
import { UserInfoReportDTO, PlanInfoReportDTO } from '../../types/DTO';

export const getUserInfoReport = (): APIThunkResult<UserInfoReportDTO> => (
  apiRequest<UserInfoReportDTO>(async () => {
    const { data, error } = await apiFetch<UserInfoReportDTO>({
      method: 'GET',
      url: `${serverAdminAPI}/customers/reports/userInfo`,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);

export const getPlanInfoReport = (): APIThunkResult<PlanInfoReportDTO> => (
  apiRequest<PlanInfoReportDTO>(async () => {
    const { data, error } = await apiFetch<PlanInfoReportDTO>({
      method: 'GET',
      url: `${serverAdminAPI}/customers/reports/planInfo`,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);
