import styled from 'styled-components/macro';

import { StyledInputProps } from './types';

export const OuterWrapper = styled.div`
  margin: 0.75rem 0rem;
  width: 100%;
`;

export const InnerWrapper = styled.div`
  position: relative;
  margin: ${({ theme }) => theme.spacing.XXS / 2}rem 0 0 0;
`;

export const StyledInput = styled.input<StyledInputProps>`
  position: relative;
  padding: ${({ theme }) => theme.spacing.XXS}rem;
  border: 1px solid ${({ error, theme, outlined }) => {
    if (error) return theme.palette.btnError;
    if (outlined) return theme.basePalette.greyMain;
    return theme.palette.btnMidGrey;
  }};
  border-radius: ${({ outlined = false }) => (outlined ? '15px' : '8px')};
  outline: none;
  width: ${({ width = 'auto' }) => width};
  ${({ theme, error }) => (error && `background-color: ${theme.palette.inputErrorBg}`)};
  ${({ theme, error }) => (error && `color: ${theme.palette.inputError}`)};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  background-color: ${({ theme, outlined = false }) => (outlined ? theme.palette.bgPrimary : theme.palette.btnMidGrey)};
  padding-left: ${({ isCurrency, theme }) => isCurrency && theme.spacing.M * 1.2}rem;

  ${({ disabled, isCurrency }) => ((disabled || isCurrency) && `
    -moz-appearance: textfield;
    &:-webkit-outer-spin-button, &:-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }`
  )}

  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.borderBlue};
    background-color: ${({ theme, outlined }) => (outlined ? theme.palette.bgPrimary : theme.palette.btnMidGrey)};
    color: ${({ theme }) => theme.basePalette.navyMain};
  }

  &:hover {
    border: 1px solid ${({ theme, disabled }) => (disabled ? theme.palette.btnMidGrey : theme.palette.borderBlue)};
    background-color: ${({ theme, outlined = false }) => (outlined ? theme.palette.bgPrimary : theme.palette.btnMidGrey)};
  }
  
  &::-webkit-input-placeholder { /* Edge */
    font-family: ${({ theme }) => theme.fontFamily.bodyLight};
    color: ${({ theme }) => theme.basePalette.greyDark};
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: ${({ theme }) => theme.fontFamily.bodyLight};
    color: ${({ theme }) => theme.basePalette.greyDark};
  }

  &::placeholder {
    font-family: ${({ theme }) => theme.fontFamily.bodyLight};
    color: ${({ theme }) => theme.basePalette.greyDark};
  }
`;

export const MetaWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.p`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  margin-bottom: 5px;
  margin-right: auto;
`;

export const OptionalLabel = styled.span`
  color: ${({ theme }) => theme.palette.grey20};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  margin-bottom: 5px;
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.palette.btnError};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-style: italic;
  margin-bottom: 5px;
  margin-left: auto;
  margin-top: 5px;
`;

export const DollarIcon = styled.div`
  left: 0;
  top: 1px;
  bottom: 1px;
  border-right: 1px solid ${({ theme }) => theme.basePalette.greyMain};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.XXS * 1.5}rem;
`;

export const PasswordToggle = styled.div`
  position: absolute;
  cursor: pointer;
  right: ${({ theme }) => `${theme.spacing.XXS}rem`};
  top: 50%;
  transform: translatey(-50%);
`;
