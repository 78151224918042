import { BorderStyles } from '../../lib/types/Style';

// TODO
const borderStyles: BorderStyles = {
  primary: '1px solid #',
  secondary: '1px solid #',
  tertiary: '1px solid #',
  error: '1px solid #',
  disabled: '',
};

export default borderStyles;
