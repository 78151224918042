import * as yup from 'yup';

import { InstallmentFrequency, PlanType } from '../types/Plan';

const adminFundSchema = yup.object({
  type: yup
    .string()
    .oneOf(Object.keys(PlanType))
    .required('Plan type is required'),
  startDate: yup
    .date()
    .required('Start date is required'),
  dueDate: yup
    .date()
    .required('Due date is required'),
  endDate: yup
    .date().min(
      yup.ref('startDate'),
      "End date can't be before start date",
    ).max(
      yup.ref('dueDate'),
      "End date can't be after due date",
    ),
  value: yup
    .number()
    .typeError('Please enter valid amount')
    .required('Amount is required'),
  receivedValue: yup
    .number(),
  installmentFrequency: yup
    .string()
    .typeError('Please select installment frequency')
    .oneOf(Object.keys(InstallmentFrequency))
    .required('Installment frequency is required'),
  adminFee: yup
    .number()
    .typeError('Please enter valid admin fee')
    .required('Admin fee is required'),
}).required();

export type AdminFundSchema = yup.InferType<typeof adminFundSchema>;

export default adminFundSchema;
