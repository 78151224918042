import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import useStrings from '../../../hooks/useStrings';
import {
  CustomerPlanWithTransfers,
  getPlanTransferRecords,
  getSelectedCustomerPlan,
  removeReceipt,
  uploadReceipt,
} from '../../../lib/api/admin/plans';
import { generatePlanFileName } from '../../../lib/utils/plan';

import Button from '../Button';
import Grid from '../Grid';
import ModalReadOnlyField from '../ModalReadOnlyField';
import ModalPlanTransfers from '../ModalPlanTransfers';
import UploadReceipt from './UploadReceipt';
import ReceiptThumb from './ReceiptThumb';
import { Spinner } from '../../UI/LoadingScreen/Styles';

import { addIcon, UploadIcon } from '../../../assets/Icons';
import { FileUploadIcon } from '../../../pages/Admin/CustomerProfile/Notes/NoteInput/Styles';
import {
  ScrollingModal,
  ModalBackground,
  ModalWrapper,
  ButtonWrapper,
  ModalTitle,
  LineBreak,
  DuplicatePlanIcon,
} from './Styles';
import { ReceiptLink } from '../../../pages/Admin/CustomerProfile/Styles';

import { ReadOnlyPlanModalProps } from './types';
import { PlanStatus, PlanType } from '../../../lib/types/Plan';
import {
  INSTALLMENT_FREQUENCY_DISPLAY,
  PLAN_TYPE_DISPLAY,
  REGISTRATION_LENGTH_DISPLAY,
  VEHICLE_TYPE_DISPLAY,
} from '../../../CONSTANTS';
import { ApplicationState } from '../../../lib/store';
import { APIThunkDispatch } from '../../../lib/types/API';
import { setSelectedPlan } from '../../../lib/store/contexts/admin/selectedCustomer/payments/actions';
import { deleteMedia, loadReceiptBlob } from '../../../lib/Media';

const ReadOnlyPlanModal: React.FC<ReadOnlyPlanModalProps> = ({
  planData,
  onClose,
  onViewPayments,
  onDuplicatePlan,
}) => {
  const dispatch: APIThunkDispatch = useDispatch();
  const [{
    GenericText,
    Components: { Common: { PlanModal, FileUploader } },
  }] = useStrings();
  const stopClickBubbling = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  const [plan, setPlan] = useState<CustomerPlanWithTransfers>();
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [receiptURL, setReceiptURL] = useState(''); // Store created URL for revoking
  const [uploadError, setUploadError] = useState('');

  const { selectedPlanTransfers } = useSelector((state: ApplicationState) => ({
    selectedPlanTransfers: state.admin.selectedPlanTransfersState.selectedPlanTransfers,
  }));

  const fetchPlanTransfers = useCallback((planId: number) => {
    dispatch<void>(getPlanTransferRecords(planId));
  }, [dispatch]);

  const fetchPlan = useCallback(async (customerId: number, planId: number) => {
    const { data } = await dispatch(getSelectedCustomerPlan(customerId, planId));
    if (data) {
      setPlan(data);
    }
  }, [dispatch]);

  useEffect(() => {
    if (planData) {
      fetchPlan(planData.customerId, planData.planId).catch(() => { });
    }
  }, [fetchPlan, planData]);

  useEffect(() => {
    if (planData?.planId) fetchPlanTransfers(planData?.planId);
  }, [fetchPlanTransfers, planData?.planId]);

  // Revoke receipt url on cleanup
  useEffect(() => () => {
    if (!receiptURL) return;
    URL.revokeObjectURL(receiptURL);
  }, [receiptURL]);

  const onUploadReceiptConfirm = async (url: string) => {
    if (!plan) return;
    setUploadError('');
    const { data } = await dispatch(uploadReceipt(plan.planId, url));
    if (data) {
      setPlan({ ...plan, receiptLink: data.receiptLink });
      setUploadModalOpen(false);
    } else {
      await deleteMedia(url);
      setUploadError(FileUploader.uploadError);
    }
  };

  const onRemoveReceipt = async () => {
    if (!plan || !plan.receiptLink) return;
    const { data } = await dispatch(removeReceipt(plan.planId));
    await deleteMedia(plan.receiptLink);
    if (data) {
      setPlan({ ...plan, receiptLink: data.receiptLink });
    }
  };

  const handleDownload = async () => {
    if (!plan?.receiptLink) return;
    const fileName = generatePlanFileName(plan);
    const fileMeta = await loadReceiptBlob(
      plan.receiptLink,
      fileName,
      GenericText.downloadError,
    );
    if (!fileMeta.url) return;
    setReceiptURL(fileMeta.url);
    const link = document.createElement('a');
    link.href = fileMeta.url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  if (!plan) {
    return (
      <ModalBackground onClick={onClose}>
        <ModalWrapper onClick={stopClickBubbling}>
          <Grid row marginTop="3rem" maxWidth="100%">
            <Grid column sm={12} alignItems="center">
              <ModalTitle>
                {PlanModal.archivedPlanTitle}
              </ModalTitle>
            </Grid>
          </Grid>
          <Grid row marginTop="2rem" maxWidth="100%">
            <Grid column sm={12} alignItems="center">
              <Spinner />
            </Grid>
          </Grid>
        </ModalWrapper>
      </ModalBackground>
    );
  }

  return (
    <ModalBackground onClick={onClose}>
      <ModalWrapper onClick={stopClickBubbling}>
        {uploadModalOpen && (
          <UploadReceipt
            onConfirm={onUploadReceiptConfirm}
            onCancel={() => {
              setUploadModalOpen(false);
              setUploadError('');
            }}
            parentError={uploadError}
            userId={plan.userId}
          />
        )}
        <Grid row marginTop="3rem" maxWidth="100%">
          <Grid column sm={12} alignItems="center">
            <ModalTitle>
              {PlanModal.archivedPlanTitle}
            </ModalTitle>
          </Grid>
        </Grid>
        <Grid row marginTop="2rem" maxWidth="100%">
          <LineBreak />
        </Grid>
        <ScrollingModal>
          <Grid row maxWidth="97.5%">
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="planName"
                label={PlanModal.planNameInput}
                value={plan?.planName || ''}
                disabled={!plan?.planName}
              />
            </Grid>
            {selectedPlanTransfers.map((transfer) => (
              <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                <ModalPlanTransfers transfer={transfer} planId={planData.planId} />
              </Grid>
            ))}
          </Grid>
          <Grid row maxWidth="97.5%">
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="planType"
                label={PlanModal.planTypeDropdown}
                value={plan?.planType ? PLAN_TYPE_DISPLAY[plan.planType] : ''}
              />
            </Grid>
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="startDate"
                label={PlanModal.startDatePicker}
                value={dayjs(plan?.startDate).format('D/M/YYYY') || ''}
              />
            </Grid>
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="dueDate"
                label={PlanModal.dueDatePicker}
                value={dayjs(plan?.dueDate).format('D/M/YYYY') || ''}
              />
            </Grid>
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="installmentFrequency"
                label={PlanModal.installmentFrequencyDropdown}
                value={plan?.frequency ? INSTALLMENT_FREQUENCY_DISPLAY[plan.frequency] : ''}
              />
            </Grid>
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="registrationLength"
                label={PlanModal.RegistrationLengthDropdown}
                value={plan?.registrationLength ? REGISTRATION_LENGTH_DISPLAY[plan?.registrationLength] : ''}
                disabled={plan?.planType !== PlanType.VEHICLE_REGISTRATION}
              />
            </Grid>
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="registration"
                label={PlanModal.registrationInput}
                value={plan?.vehicleRegistration || ''}
                disabled={plan?.planType !== PlanType.VEHICLE_REGISTRATION}
              />
            </Grid>
          </Grid>
          <Grid row marginTop="1rem" maxWidth="97.5%">
            <Grid column>
              <LineBreak />
            </Grid>
          </Grid>
          <Grid row maxWidth="97.5%">
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="vehicleType"
                label={PlanModal.vehicleTypeDropdown}
                value={VEHICLE_TYPE_DISPLAY[plan?.vehicleType] || ''}
                disabled={plan?.planType !== PlanType.VEHICLE_REGISTRATION}
              />
            </Grid>
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="vehicleMake"
                label={PlanModal.vehicleMakeInput}
                value={plan?.definedVehicleMake || plan?.inputVehicleMake || ''}
                disabled={plan?.planType !== PlanType.VEHICLE_REGISTRATION}
              />
            </Grid>
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="vehicleModel"
                label={PlanModal.vehicleModelInput}
                value={plan?.vehicleModel || ''}
                disabled={plan?.planType !== PlanType.VEHICLE_REGISTRATION}
              />
            </Grid>
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="vehicleColor"
                label={PlanModal.vehicleColorDropdown}
                value={plan?.vehicleColor || ''}
                disabled={plan?.planType !== PlanType.VEHICLE_REGISTRATION}
              />
            </Grid>
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="registeredState"
                label={PlanModal.vehicleRegisteredState}
                value={plan?.vehicleRegisteredState || ''}
                disabled={plan?.planType !== PlanType.VEHICLE_REGISTRATION}
              />
            </Grid>
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="vehicleYear"
                label={PlanModal.vehicleYearInput}
                value={plan?.vehicleYear ? dayjs(plan.vehicleYear).format('YYYY') : ''}
                disabled={plan?.planType !== PlanType.VEHICLE_REGISTRATION}
              />
            </Grid>
          </Grid>
          <Grid row marginTop="1rem" maxWidth="97.5%">
            <Grid column>
              <LineBreak />
            </Grid>
          </Grid>
          <Grid row maxWidth="97.5%">
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="value"
                label={PlanModal.valueInput}
                value={`$${plan?.value?.toFixed(2)}` || ''}
              />
            </Grid>
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="adminFee"
                label={PlanModal.adminFeesDisplay}
                value={`$${plan?.adminFee?.toFixed(2)}` || ''}
              />
            </Grid>
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="installmentAmount"
                label={PlanModal.installmentAmountDisplay}
                value={`$${plan?.latestInstallmentValue?.toFixed(2)}` || ''}
              />
            </Grid>
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="allPayments"
                label={PlanModal.paymentsMadeDisplay}
                value={plan.status === PlanStatus.COMPLETE
                  ? plan?.allPayments?.toString()
                  : plan.transfersData.count.toString()}
              />
            </Grid>
            <Grid column md={6} lg={2} marginTop="1rem">
              <ModalReadOnlyField
                name="totalPaid"
                label={PlanModal.totalPaidDisplay}
                value={plan.status === PlanStatus.COMPLETE
                  ? `$${plan?.alreadyPaid?.toFixed(2)}`
                  : `$${plan.transfersData.value.toFixed(2)}`}
              />
            </Grid>
          </Grid>
          <Grid row marginTop="1rem" maxWidth="100%">
            <LineBreak />
          </Grid>
          <Grid row marginTop="1.5rem" maxWidth="100%">
            <Grid column sm={12} alignItems="flex-end">
              <ButtonWrapper>
                <DuplicatePlanIcon src={addIcon} onClick={() => onDuplicatePlan(plan)} />
                {plan.receiptLink ? (
                  <ReceiptThumb
                    onClick={() => handleDownload()}
                    file={{ url: ReceiptLink, fileName: generatePlanFileName(plan) }}
                    handleRemoveReceipt={onRemoveReceipt}
                  />
                )
                  : (
                    <FileUploadIcon src={UploadIcon} onClick={() => setUploadModalOpen(true)} />
                  )}
                <Button
                  onClick={() => {
                    if (plan?.planId) {
                      dispatch(setSelectedPlan(plan.planId.toString()));
                      onClose();
                      onViewPayments();
                    }
                  }}
                  variant="dark-inverse"
                  width="220px"
                  rounded
                >
                  {PlanModal.viewPayments}
                </Button>
                <Button
                  onClick={onClose}
                  variant="primary"
                  width="220px"
                  rounded
                >
                  {GenericText.close}
                </Button>
              </ButtonWrapper>
            </Grid>
          </Grid>
        </ScrollingModal>
      </ModalWrapper>
    </ModalBackground>
  );
};

export default ReadOnlyPlanModal;
