import React from 'react';

import Button from '../Button';

import {
  ModalBackground,
  ModalWrapper,
  ButtonWrapper,
  TextWrapper,
  ModalTitle,
  ModalSubtext,
  ModalBodyText,
  Icon,
} from './Styles';

import { CustomerConfirmModalProps } from './types';
import { SuccessIcon } from '../../../assets/Icons';

const CustomerConfirmModal: React.FC<CustomerConfirmModalProps> = ({
  isOpen,
  isSubmitting = false,
  onCancel,
  onConfirm,
  title,
  titleHighlight,
  subtext,
  subtextHighlight,
  bodyText,
  cancelButtonText,
  confirmButtonText,
}) => {
  const stopClickBubbling = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  return (
    <ModalBackground
      opened={isOpen}
      onClick={onCancel}
    >
      <ModalWrapper onClick={stopClickBubbling}>
        <Icon src={SuccessIcon} />
        <TextWrapper>
          <div>
            <ModalTitle>
              {title}
            </ModalTitle>
            <ModalTitle highlight>
              &nbsp;
              {titleHighlight}
            </ModalTitle>
          </div>
          <ModalSubtext>
            {subtext}
          </ModalSubtext>
          {subtextHighlight && (
            <ModalSubtext highlight>
              {subtextHighlight}
            </ModalSubtext>
          )}
        </TextWrapper>
        {bodyText && (
          <ModalBodyText>
            {bodyText}
          </ModalBodyText>
        )}
        <ButtonWrapper>
          <Button
            onClick={onCancel}
            variant="outlined"
          >
            {cancelButtonText}
          </Button>
          <Button
            onClick={onConfirm}
            disabled={isSubmitting}
            $loading={isSubmitting}
            variant="primary"
          >
            {confirmButtonText}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </ModalBackground>
  );
};

export default CustomerConfirmModal;
