import { FirebaseError } from 'firebase';
import dayjs from 'dayjs';

import {
  setApiLoading,
  apiLoadingSuccess,
  apiLoadingError,
} from '../store/contexts/loading/actions';
import {
  APIThunkResult, APIThunkDispatch, APIResult, FetchOptions,
} from '../types/API';
import { ApplicationState } from '../store';
import { getAuthUser } from '../Authentication';
import { setNotification } from '../store/contexts/notification/actions';

export function apiSuccess<T>(
  dispatch: APIThunkDispatch,
  data: T,
  load = true,
): APIResult<T> {
  if (load) dispatch(apiLoadingSuccess());
  return { success: true, data };
}

export function apiError<T>(
  dispatch: APIThunkDispatch,
  res: APIResult | FirebaseError,
  load = true,
  notify = false,
): APIResult<T> {
  if (load) dispatch(apiLoadingError());

  // Standard API Error notification/return
  const { error } = res as APIResult;
  if (error) {
    const { info, code, message } = error;
    if (notify) {
      dispatch(setNotification({
        message: error?.message,
        variant: 'danger',
        duration: 3,
      }));
    }
    return {
      success: false,
      error: { info, message, code },
    };
  }

  // Firebase Error notification/return
  const { code, message } = res as FirebaseError;
  if (message) {
    if (notify) {
      dispatch(setNotification({
        message,
        variant: 'danger',
        duration: 3,
      }));
    }
    return {
      success: false,
      error: { info: code, message, code: 400 },
    };
  }

  // Fallback Error notification/return
  const unexpectedMsg = 'An unexpected error occurred on the API';
  if (notify) {
    dispatch(setNotification({
      message: unexpectedMsg,
      variant: 'danger',
      duration: 3,
    }));
  }
  return { success: false, error: { info: unexpectedMsg, message: unexpectedMsg, code: 500 } };
}

export function apiRequest<T>(
  request: (dispatch: APIThunkDispatch, getState: () => ApplicationState) => Promise<T>,
  errorNotification = false,
  enableLoading = true,
): APIThunkResult<T> {
  return async (
    dispatch: APIThunkDispatch,
    getState: () => ApplicationState,
  ): Promise<APIResult<T>> => {
    try {
      // Loads for any request. apiSuccess and apiError will stop it
      if (enableLoading) dispatch(setApiLoading());

      // Call specific request and retrieve return result
      const data: T = await request(dispatch, getState);

      return apiSuccess(dispatch, data, enableLoading);
    } catch (errorResponse) {
      return apiError(dispatch, errorResponse, enableLoading, errorNotification);
    }
  };
}

export async function apiFetch<T>(options: FetchOptions): Promise<APIResult<T>> {
  const { method, url, param, body, headers } = options;
  const getUrl = param ? `${url}/${param}` : url;
  const token = await getAuthUser()?.getIdToken();

  const response = await fetch(getUrl, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
      ...headers,
    },
    body: JSON.stringify(body),
  });

  const json = response.status === 204 ? 'No Content' : await response.json();

  if (!response.ok) {
    return Promise.reject(json);
  }

  return json;
}

export const stripDate = (input?: Date | string): string | null => (input ? dayjs(input).format('YYYY-MM-DD') : null);

export const objectToQueryString = (params: Record<string, unknown>): string => Object.keys(params)
  .map((key) => {
    if (params[key] && params[key] instanceof Date) {
      const date = stripDate(params[key] as string);
      if (date) return `${key}=${encodeURIComponent(date)}`;
      return null;
    }
    return params[key] && `${key}=${encodeURIComponent(params[key] as string)}`;
  }).join('&');
