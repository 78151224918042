import { Note } from '../../../../types/DBModels';

export const SET_CUSTOMER_NOTE_DATA = 'SET_CUSTOMER_NOTE_DATA';
export const CREATE_CUSTOMER_NOTE = 'CREATE_CUSTOMER_NOTE';
export const DELETE_CUSTOMER_NOTE = 'DELETE_CUSTOMER_NOTE';
export const UPDATE_CUSTOMER_NOTE = 'UPDATE_CUSTOMER_NOTE';
export const CLEAR_CUSTOMER_NOTE_DATA = 'CLEAR_CUSTOMER_NOTE_DATA';

export interface CustomerNotesState {
  customerNotes: Note[];
}
export interface SetCustomerNotesAction {
  type: typeof SET_CUSTOMER_NOTE_DATA;
  customerNotes: Note[];
}
export interface CreateCustomerNoteAction {
  type: typeof CREATE_CUSTOMER_NOTE;
  customerNote: Note,
}
export interface UpdateCustomerNoteAction {
  type: typeof UPDATE_CUSTOMER_NOTE;
  customerNote: Note,
}
export interface DeleteCustomerNoteAction {
  type: typeof DELETE_CUSTOMER_NOTE;
  customerNoteId: number | undefined,
}
export interface ClearCustomerNotesAction {
  type: typeof CLEAR_CUSTOMER_NOTE_DATA;
}

export type CustomerNoteActionTypes =
CreateCustomerNoteAction |
SetCustomerNotesAction |
UpdateCustomerNoteAction |
ClearCustomerNotesAction |
DeleteCustomerNoteAction;
