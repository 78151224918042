import {
  SetPermissionsAction,
  SetRoleAction,
  SET_PERMISSIONS,
  SET_ROLE,
} from './types';
import { Roles } from '../../../types/Auth';
import { RolePermission } from '../../../types/DBModels';

export const setPermissions = (
  permissions: RolePermission[],
): SetPermissionsAction => ({
  type: SET_PERMISSIONS,
  permissions,
});

export const setRole = (
  role: Roles,
): SetRoleAction => ({
  type: SET_ROLE,
  role,
});

export default setPermissions;
