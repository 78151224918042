import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import useStrings from '../../../hooks/useStrings';
import { defaultFilter, getCustomers, updateStatus } from '../../../lib/api/admin/customers';
import { resetSelectedCustomer } from '../../../lib/store/contexts/admin/customers/actions';
import { getAuthUser } from '../../../lib/Authentication';

import PageLayout from '../../../layouts/PageLayout';
import CustomerTable from './CustomerTable';

import { UsersListProps } from './types';
import { TableRow } from '../../../components/Common/Table/types';
import { APIThunkDispatch, CustomerFilterOptions } from '../../../lib/types/API';
import { ApplicationState } from '../../../lib/store';
import { CUSTOMER_PROFILE_TABS_DISPLAY } from '../../../CONSTANTS';
import { clearCustomerNotesData } from '../../../lib/store/contexts/admin/customerNotes/actions';
import { clearSelectedCustomerPlans } from '../../../lib/store/contexts/admin/selectedCustomer/plans/actions';
import { clearSelectedCustomerArchivedPlans } from '../../../lib/store/contexts/admin/selectedCustomer/archivedPlans/actions';
import { clearSelectedCustomerPayments, setSelectedPlan } from '../../../lib/store/contexts/admin/selectedCustomer/payments/actions';
import { CustomerStatus, PaymentStatus } from '../../../lib/types/Customer';
import { setTotalCreditsData } from '../../../lib/store/contexts/admin/selectedCustomer/totalCredits/actions';
import { setCreditHistoryData } from '../../../lib/store/contexts/admin/selectedCustomer/creditHistory/actions';

const UsersList: React.FC<UsersListProps> = () => {
  const history = useHistory();
  const [{ Pages: { Users: Strings } }] = useStrings();
  const Dispatch: APIThunkDispatch = useDispatch();

  const { selectedCustomer } = useSelector((state: ApplicationState) => ({
    selectedCustomer: state.admin.customerState.selectedCustomer,
  }));

  const onUpdateCustomerStatus = (userId: string, status?: CustomerStatus, paymentStatus?: PaymentStatus) => {
    Dispatch<void>(updateStatus(userId, status, paymentStatus));
  };

  const viewUser = (row: TableRow) => {
    if (selectedCustomer?.user.userId !== row.userId) {
      Dispatch(resetSelectedCustomer());
      Dispatch(clearCustomerNotesData());
      Dispatch(clearSelectedCustomerPlans());
      Dispatch(clearSelectedCustomerPayments());
      Dispatch(clearSelectedCustomerArchivedPlans());
      Dispatch(setSelectedPlan());
      Dispatch(setTotalCreditsData(0));
      Dispatch(setCreditHistoryData([]));
    }
    const win = window.open(`/admin/user/${row.userId}/${CUSTOMER_PROFILE_TABS_DISPLAY.PLANS}`, '_blank');
    if (win) win.focus();
  };

  const { customerState: { customerData } } = useSelector((state: ApplicationState) => state.admin);

  // Filter management
  const [filter, setFilter] = useState<CustomerFilterOptions>(defaultFilter);

  const updateFilter = (newFilter: CustomerFilterOptions) => {
    setFilter({
      ...defaultFilter,
      ...newFilter,
    });
  };

  const showCustomers = useCallback(() => {
    Dispatch<void>(getCustomers(filter));
  }, [Dispatch, filter]);

  useEffect(() => {
    showCustomers();
  }, [showCustomers]);

  // Check if a password update is required.
  useEffect(() => {
    (async () => {
      const user = getAuthUser();
      const token = await user?.getIdTokenResult();
      const claims = token?.claims;
      const mustUpdatePassword = claims?.mustUpdatePassword;
      if (mustUpdatePassword) {
        history.push('/admin/update-password');
      }
    })().catch(() => null);
  }, [history]);

  const currentData = customerData.customers.map((customer) => ({ id: customer.customerId, ...customer }));

  return (
    <PageLayout
      pageTitle={`🙎‍♀️ ${Strings.pageTitle}`}
      maxWidth="100%"
    >
      <CustomerTable
        currentData={currentData}
        recordCount={customerData.count}
        filter={filter}
        updateFilter={updateFilter}
        onRowClicked={viewUser}
        handleUpdateCustomerStatus={onUpdateCustomerStatus}
      />
    </PageLayout>

  );
};

export default UsersList;
