import { apiFetch, apiRequest } from '../Utils';
import { setVehicleMakes } from '../../store/contexts/vehicleMakes/actions';
import { serverAdminAPI } from '../../../CONSTANTS';

import { APIThunkResult } from '../../types/API';
import { VehicleMakesDTO } from '../../types/DTO';

export const getVehicleMakesAdmin = (): APIThunkResult<VehicleMakesDTO> => (
  apiRequest<VehicleMakesDTO>(async (dispatch) => {
    const { data, error } = await apiFetch<VehicleMakesDTO>({
      method: 'GET',
      url: `${serverAdminAPI}/vehicleMakes`,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setVehicleMakes(data.vehicleMakes));
    return data;
  }, true)
);
