import styled from 'styled-components/macro';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.basePalette.white};
  display: flex;
  justify-content: center;
`;

export const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.XXS}rem;
  max-width: ${({ theme }) => theme.container.maxWidth}rem;
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => `${theme.spacing.XXS}rem ${theme.spacing.XS}rem`};
  };
`;

export const LogoWrapper = styled.div`
  width: 150px;
`;

export const Spacer = styled.div`
  display: none;
  height: 50px;
  visibility: hidden;

  ${({ theme }) => theme.device.laptop} {
    display: block;
  };
`;
