import styled from 'styled-components';

export const Value = styled.span`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-size: ${({ theme }) => theme.fontScale.XXL as number / 1.05}rem;
  font-family: ${({ theme }) => theme.fontFamily.gotham};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  display: block;

  // Correct vertical alignment of text
  margin-bottom: -5px;
`;
