import {
  SET_PROFILE_DATA,
  UPDATE_CUSTOMER_STATUS,
  ProfileState,
  ProfileActionTypes,
} from './types';

const initialState: ProfileState = {
  profileData: null,
};

const profileReducer = (
  state: ProfileState = initialState,
  action: ProfileActionTypes,
): ProfileState => {
  switch (action.type) {
    case SET_PROFILE_DATA:
      return {
        ...state,
        profileData: action.profileData,
      };
    case UPDATE_CUSTOMER_STATUS:
      return {
        ...state,
        profileData: state.profileData && {
          ...state.profileData,
          status: action.status,
        },
      };
    default:
      return state;
  }
};

export default profileReducer;
