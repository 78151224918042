import React, { useCallback } from 'react';

import {
  FormStepsWrapper,
  FormStep,
  StepIndicator,
  StepText,
  FormStepOuter,
} from './Styles';

import useStrings from '../../../hooks/useStrings';
import { FormStepsProps } from './types';
import { GreyCheckmarkIcon } from '../../../assets/Icons';
import { ONBOARDING_STEPS, ADD_PLAN_STEPS, TABLET } from '../../../CONSTANTS';
import useWindowSize from '../../../hooks/useWindowSize';

const FormSteps: React.FC<FormStepsProps> = ({ currentStep, isOnboarding, isCreateProfile }) => {
  const [{ GenericText }] = useStrings();
  const { windowWidth } = useWindowSize();
  const formSteps = isOnboarding ? ONBOARDING_STEPS : ADD_PLAN_STEPS;
  // Increment step by one for onboarding process as create profile is first step
  const calculatedStep = (isOnboarding && !isCreateProfile) ? currentStep + 1 : currentStep;
  const isLaptop = windowWidth > TABLET;

  const renderFormStep = useCallback((step: string, index: number) => {
    const isCurrentStep = index === calculatedStep;
    const stepComplete = index < calculatedStep;

    return (
      <FormStepOuter key={step}>
        <FormStep>
          <StepIndicator isCurrentStep={isCurrentStep}>
            {stepComplete ? (
              <img src={GreyCheckmarkIcon} alt="Check Mark" />
            ) : (
              <span>{index + 1}</span>
            )}
          </StepIndicator>
        </FormStep>
        <StepText isCurrentStep={isCurrentStep}>{step}</StepText>
      </FormStepOuter>
    );
  }, [calculatedStep]);

  return (
    <FormStepsWrapper centered={!isOnboarding}>
      {isLaptop ? (
        formSteps.map((step, i) => renderFormStep(step, i))
      ) : (
        <>
          <FormStepOuter key={currentStep}>
            <FormStep>
              <StepIndicator isCurrentStep>
                <span>{currentStep + 1}</span>
              </StepIndicator>
            </FormStep>
            <StepText isCurrentStep={false}>{GenericText.stepDivider}</StepText>
          </FormStepOuter>
          <FormStepOuter key={formSteps.length}>
            <FormStep>
              <StepIndicator isCurrentStep={false}>
                <span>{formSteps.length}</span>
              </StepIndicator>
            </FormStep>
          </FormStepOuter>
        </>
      )}
    </FormStepsWrapper>
  );
};

export default FormSteps;
