import { Roles } from '../../../types/Auth';
import { RolePermission } from '../../../types/DBModels';

export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_ROLE = 'SET_ROLE';

export interface PermissionsState {
  permissions: RolePermission[];
  role: Roles;
}

export interface SetPermissionsAction {
  type: typeof SET_PERMISSIONS;
  permissions: RolePermission[];
}

export interface SetRoleAction {
  type: typeof SET_ROLE;
  role: Roles;
}

export type PermissionsActionTypes = SetPermissionsAction | SetRoleAction;
