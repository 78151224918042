import { DropdownOption } from '../../components/Common/Table/types';

/**
 * Adjust color brightness/darkness.
 *
 * @param hex The hexadecimal color value.
 * @param lum The luminance value, i.e. 0.2 = 20% brighter, -0.2 = 20% darker.
 */
export const luminance = (hex: string, lum: number): string => {
  // Validate hex string - remove '#' & expand if 3 val hex '#FFF'
  let hexInput = hex.replace(/[^0-9a-f]/gi, '');

  if (hexInput.length < 6) {
    hexInput = hexInput[0] + hexInput[0] + hexInput[1] + hexInput[1] + hexInput[2] + hexInput[2];
  }

  const lumInput = lum || 0;

  // convert to decimal and adjust luminance
  let hexOutput = '#';
  let c;
  let i;

  for (i = 0; i < 3; i += 1) {
    c = parseInt(hexInput.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + (c * lumInput)), 255)).toString(16);
    hexOutput += (`00${c}`).substr(c.length);
  }

  return hexOutput;
};

export const generateDropdownLabel = (label: string, selectedOptions: DropdownOption[]): string => {
  if (selectedOptions.length === 1) return `${selectedOptions[0].label}`;
  if (selectedOptions.length > 1) {
    const multipleLabel = `Multiple ${label}`;
    return multipleLabel[multipleLabel.length - 1] === 's'
      ? `${multipleLabel}' Selected`
      : `${multipleLabel}s Selected `;
  }
  const formattedLabel = `All ${label}`;
  return formattedLabel[formattedLabel.length - 1] === 's'
    ? `${formattedLabel}`
    : `${formattedLabel}s`;
};

export default { luminance };
