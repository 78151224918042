import styled from 'styled-components/macro';

import { StyledRowProps } from './types';

export const Container = styled.main`
  background-color: #142432;
  min-height: 100vh;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`;

export const StyledRow = styled.div<StyledRowProps>`
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  flex-direction: column;
  margin-bottom: 4.375rem;
  max-width: 530px;
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledDatePicker = styled.div`
  margin-bottom: 2.375rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.device.tablet} {
    margin-bottom: 0;
    width: 50%;

    &:first-child {
      margin-right: 1rem;
    }

    &:last-child {
      margin-left: 1rem;
    }
  }

  .date-picker {
    border: 1px solid ${({ theme }) => theme.basePalette.blueMain};
    border-radius: 3.75rem;
    background-color: ${({ theme }) => theme.basePalette.navyMain};
    color: white;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    padding: 1.25rem 2rem;

    &:active, &:focus {
      outline: none;
    }
  }

  .react-datepicker-popper {
    z-index: 2;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__triangle {
  }

  .react-datepicker__close-icon::after {
    align-items: center;
    background-color: hsl(205deg 91% 63% / 65%);
    display: flex;
    font-size: 1.25rem;
    height: 26px;
    justify-content: center;
    width: 26px;
    margin-right: 0.625rem;
  }
`;

export const StyledResult = styled.div`
  border: 1px solid #314453;
  border-radius: 10rem;
  padding: 1rem 1rem 1rem 1.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: ${({ theme }) => theme.basePalette.white};
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    margin: 0;

    ${({ theme }) => theme.device.tablet} {
      font-size: 3rem;
    }

    &:before {
      content: '$';
    }
  }

  span {
    color: ${({ theme }) => theme.basePalette.white};
    font-size: 1rem;
  }
`;

export const StyledHeading3 = styled.h3`
  color: ${({ theme }) => theme.basePalette.white};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: 1.5rem;
  letter-spacing: 1px;
  margin-bottom: 1.25rem;
`;

export const StyledLink = styled.a`
  background-color: ${({ theme }) => theme.basePalette.blueMain};
  border: none;
  border-radius: 2rem;
  color: ${({ theme }) => theme.basePalette.white};
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem;
  text-decoration: none;

  ${({ theme }) => theme.device.tablet} {
    font-size: 1.25rem;
    padding: 1rem 2rem;
  }
`;

export const AdminFee = styled.span`
  color: ${({ theme }) => theme.basePalette.white};
  font-size: 11px;
  display: block;
  margin-top: 1rem;
  margin-left: 2rem;
`;
