import React from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import useStrings from '../../../../hooks/useStrings';
import updateAdminProfileSchema from '../../../../lib/validators/updateAdminProfileSchema';

import Grid from '../../../../components/Common/Grid';
import Input from '../../../../components/Common/Input';
import Button from '../../../../components/Common/Button';

import {
  ContentWrapper,
  HeaderWrapper,
  TabSubTitle,
  TabTitle,
  Wrapper,
} from '../Styles';

import { EditProfileProps } from './types';
import { updateProfile } from '../../../../lib/api/admin/admins';
import { APIThunkDispatch } from '../../../../lib/types/API';

const EditProfile: React.FC<EditProfileProps> = ({
  admin,
  onLogoutButtonPress,
}) => {
  const [{ Pages: { Settings: { EditProfile: editProfileStrings } } }] = useStrings();
  const Dispatch: APIThunkDispatch = useDispatch();

  return (
    <Wrapper>
      <HeaderWrapper>
        <div>
          <TabTitle>{editProfileStrings.tabTitle}</TabTitle>
          <TabSubTitle>{editProfileStrings.tabSubtitle}</TabSubTitle>
        </div>
      </HeaderWrapper>
      <ContentWrapper>
        <Formik
          initialValues={{
            firstName: admin.firstName || '',
            lastName: admin.lastName || '',
            email: admin.email || '',
            phone: admin.phone || '',
          }}
          validationSchema={updateAdminProfileSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await Dispatch(updateProfile(values, admin));
            setSubmitting(false);
          }}
          validateOnChange={false}
        >
          {({
            handleSubmit,
            isSubmitting,
          }) => (
            <>
              <Grid row marginTop="1rem">
                <Grid column sm={12} md={6} alignItems="center">
                  <Input
                    type="text"
                    name="firstName"
                    label={editProfileStrings.firstNameInput}
                    placeholder={editProfileStrings.firstNameInput}
                    width="100%"
                  />
                </Grid>
                <Grid column sm={12} md={6} alignItems="center">
                  <Input
                    type="text"
                    name="lastName"
                    label={editProfileStrings.lastNameInput}
                    placeholder={editProfileStrings.lastNameInput}
                    width="100%"
                  />
                </Grid>
              </Grid>
              <Grid row marginTop="1rem">
                <Grid column sm={12} md={6} alignItems="center">
                  <Input
                    type="text"
                    name="email"
                    label={editProfileStrings.emailInput}
                    placeholder={editProfileStrings.emailInput}
                    width="100%"
                  />
                </Grid>
                <Grid column sm={12} md={6} alignItems="center">
                  <Input
                    type="text"
                    name="phone"
                    label={editProfileStrings.phoneInput}
                    placeholder={editProfileStrings.phoneInput}
                    width="100%"
                  />
                </Grid>
              </Grid>
              <Grid row marginTop="1rem">
                <Grid column sm={12} md={6} alignItems="flex-start" marginTop="1rem" expandedMobile>
                  <Button
                    margin="0"
                    width="200px"
                    variant="secondary"
                    onClick={() => onLogoutButtonPress()}
                    rounded
                  >
                    {editProfileStrings.logoutButton}
                  </Button>
                </Grid>
                <Grid column sm={12} md={6} alignItems="flex-end" marginTop="1rem" expandedMobile>
                  <Button
                    margin="0"
                    width="200px"
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    $loading={isSubmitting}
                    rounded
                  >
                    {editProfileStrings.saveProfileChangesButton}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </ContentWrapper>
    </Wrapper>
  );
};

export default EditProfile;
