import styled from 'styled-components/macro';
import { checkmarkIcon } from '../../../assets/Icons';
import { CheckboxProps } from './types';

export const Checkbox = styled.div<CheckboxProps>`
border-radius: 8px;
border: ${({ theme, sendEmailConf }) => (sendEmailConf ? 'none' : `1px solid ${theme.palette.btnDarkGrey}`)};;
height: 30px;
width: 30px;
margin-right: 8px;
background-image: ${({ sendEmailConf }) => (sendEmailConf ? `url(${checkmarkIcon})` : '')};
background-color: ${({ theme, sendEmailConf }) => (sendEmailConf ? theme.palette.borderBlue : '')};
background-size: 12px 10px;
background-repeat: no-repeat;
background-position: center;
cursor: pointer;
`;

export const CheckboxWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
margin-right: 12px;

@media (max-width: 520px) {
  margin-bottom: 12px;
  margin-right: 0;
}
`;

export const CheckboxText = styled.p`
font-size: ${({ theme }) => theme.fontScale.S}rem;
color: ${({ theme }) => theme.palette.primary};
`;
