import styled from 'styled-components/macro';

export const FormWrapper = styled.form`
  width: 100%;
  margin: ${({ theme }) => `${theme.spacing.M}rem 0`};

  ${({ theme }) => theme.device.tablet} {
    max-width: 90%;
  }

  ${({ theme }) => theme.device.desktop} {
    max-width: 75%;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 45px;
  width: 100%;
`;

export const SubText = styled.h3`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  text-align: center;
  width: 80%;
`;