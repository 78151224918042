import { CustomerPlansListView } from '../../../../../types/DBViews';

export const SET_ALL_SELECTED_CUSTOMER_PLANS = 'SET_ALL_SELECTED_CUSTOMER_PLANS';
export const SET_UPDATED_PLAN_DATA = 'SET_UPDATED_PLAN_DATA';
export const CREATE_PLAN = 'CREATE_PLAN';
export const REMOVE_PLAN = 'REMOVE_PLAN';
export const CLEAR_SELECTED_CUSTOMER_PLANS = 'CLEAR_SELECTED_CUSTOMER_PLANS';

export interface PlanState {
  planData: {
    plans: CustomerPlansListView[];
    count: number;
  }
}
export interface SetAllPlansAction {
  type: typeof SET_ALL_SELECTED_CUSTOMER_PLANS;
  planData: {
    results: CustomerPlansListView[];
    count: number;
  }
}
export interface SetUpdatedPlansAction {
  type: typeof SET_UPDATED_PLAN_DATA;
  plans: CustomerPlansListView[],
}
export interface CreatePlan {
  type: typeof CREATE_PLAN;
  plan: CustomerPlansListView,
}
export interface RemovePlanAction {
  type: typeof REMOVE_PLAN;
  planIds: number[],
}
export interface ClearSelectedCustomerPlansAction {
  type: typeof CLEAR_SELECTED_CUSTOMER_PLANS;
}

export type PlanActionTypes =
SetAllPlansAction |
SetUpdatedPlansAction |
CreatePlan |
ClearSelectedCustomerPlansAction |
RemovePlanAction;
