import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import {
  defaultFilter,
  getPayments,
  updatePayments,
  getPlansForPaymentFilter,
} from '../../../../lib/api/admin/payments';
import { setSelectedPlan } from '../../../../lib/store/contexts/admin/selectedCustomer/payments/actions';
import useStrings from '../../../../hooks/useStrings';

import PaymentsTable from './PaymentsTable';
import PlanSelectDropdown from './PlanSelectDropdown';

import { DropdownWrapper, FilterContainer, Label } from './Styles';

import { PaymentsProps } from './types';
import { APIThunkDispatch, PaymentFilterOptions, UpdatePaymentActionTypes } from '../../../../lib/types/API';
import { ApplicationState } from '../../../../lib/store';
import { PLAN_TYPE_DISPLAY_SHORT } from '../../../../CONSTANTS';
import { DropdownOption } from '../../../../lib/types/Dropdown';
import { PlanStatus } from '../../../../lib/types/Plan';

const SHOW_ALL = 'ALL';
const SHOW_ACTIVE = 'ACTIVE';

const Payments: React.FC<PaymentsProps> = ({ customer }) => {
  const Dispatch: APIThunkDispatch = useDispatch();
  const [{ Pages: { UserProfile: { Payments: Strings } } }] = useStrings();

  const {
    paymentData,
    selectedPlan,
    paymentFilterPlans,
    needsUpdate,
  } = useSelector((state: ApplicationState) => state.admin.selectedCustomerPaymentState);

  const planDropdownOptions = paymentFilterPlans.reduce((acc, curr) => (
    [
      ...acc,
      {
        label: curr.registration
          ? `${PLAN_TYPE_DISPLAY_SHORT[curr.type]} ${curr.registration} - ${curr.name || ''} (${dayjs(curr.dueDate).format('DD/MM/YY')})`
          : `${PLAN_TYPE_DISPLAY_SHORT[curr.type]} - ${curr.name || ''} (${dayjs(curr.dueDate).format('DD/MM/YY')})`,
        value: curr.planId.toString(),
      },
    ]
  ), [] as DropdownOption[]);

  const currentData = paymentData.payments.map((payment) => ({ id: payment.installmentId, ...payment }));

  const [selectedPlanId, setSelectedPlanId] = useState(selectedPlan || SHOW_ACTIVE);

  // Filter management
  const [filter, setFilter] = useState<PaymentFilterOptions>({
    ...defaultFilter,
    customerId: customer.customerId,
    planId: selectedPlanId === SHOW_ALL || selectedPlanId === SHOW_ACTIVE ? undefined : selectedPlanId,
    planStatus: selectedPlanId === SHOW_ACTIVE ? PlanStatus.ACTIVE : undefined,
  });
  const clearFilters = () => setFilter({
    ...defaultFilter,
    customerId: customer.customerId,
  });
  const updateFilter = (newFilter: PaymentFilterOptions) => {
    setFilter({
      ...defaultFilter,
      ...newFilter,
      customerId: customer.customerId, // customerId is fixed
    });
  };

  const fetchPayments = useCallback((paymentFilter) => {
    Dispatch<void>(getPayments(paymentFilter));
  }, [Dispatch]);

  const fetchPlans = useCallback(() => {
    Dispatch<void>(getPlansForPaymentFilter(customer.customerId));
  }, [Dispatch, customer.customerId]);

  useEffect(() => {
    if (needsUpdate) fetchPayments(filter);
  }, [fetchPayments, needsUpdate, filter]);

  useEffect(() => {
    fetchPayments(filter);
  }, [fetchPayments, filter]);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  const onUpdatePayments = (
    installmentIds: number[],
    actionType: UpdatePaymentActionTypes,
    paidDate?: Date,
    dueDate?: Date,
  ) => {
    Dispatch<void>(updatePayments({ installmentIds, actionType, paidDate, dueDate, customerId: customer.customerId }));
  };

  return (
    <>
      <FilterContainer>
        <Label>{Strings.filterLabel}</Label>
        <DropdownWrapper>
          <PlanSelectDropdown
            onLoadOption={selectedPlanId}
            options={[
              { label: Strings.showActive, value: SHOW_ACTIVE },
              { label: Strings.showAll, value: SHOW_ALL },
              ...planDropdownOptions,
            ]}
            onOptionClick={(option) => {
              setSelectedPlanId(option);
              const planId = option === SHOW_ALL || option === SHOW_ACTIVE ? undefined : option;

              updateFilter({ planId, planStatus: option === SHOW_ACTIVE ? PlanStatus.ACTIVE : undefined });

              Dispatch(setSelectedPlan(planId));
            }}
          />

        </DropdownWrapper>
      </FilterContainer>
      <PaymentsTable
        currentData={currentData}
        recordCount={paymentData.count}
        filter={filter}
        updateFilter={updateFilter}
        clearFilters={clearFilters}
        handleUpdatePayments={onUpdatePayments}
      />
    </>
  );
};

export default Payments;
