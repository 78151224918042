import styled from 'styled-components';

// FileTab styles
export const FileTabWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
position: relative;
padding: ${({ theme }) => theme.spacing.XXS * 1.2}rem;
border: 1px solid ${({ theme }) => theme.basePalette.greyMain};
border-radius: 16px;
&:hover {
  cursor: pointer;
}
margin-right: 1rem;
`;

export const FileTabText = styled.p`
font-family: ${({ theme }) => theme.fontFamily.body};
font-size: ${({ theme }) => theme.fontScale.M as number * 0.875}rem;
font-weight: ${({ theme }) => theme.fontWeight.semibold};
color: ${({ theme }) => theme.basePalette.navyMain};
`;

export const CloseIcon = styled.img`
  position: absolute;
  top: -5px;
  right: -5px;
  color: ${({ theme }) => theme.basePalette.white};
  background-color: ${({ theme }) => theme.basePalette.navyMain};
  border-radius: 50%;
  padding: 5px;
  width: 20px;
  height: 20px;
`;

export const Icon = styled.img`
margin-right: ${({ theme }) => theme.spacing.XXS}rem;
width: 18px;
`;