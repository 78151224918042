import React from 'react';

import { LoadingSpinner, Spinner } from './Styles';

const LoadingScreen: React.FC = () => (
  <LoadingSpinner>
    <Spinner />
  </LoadingSpinner>
);

export default LoadingScreen;
