import {
  StatsAmountCollected,
  StatsPlanType,
  StatsLocation,
  StatsStatus,
  StatsCustomerStatusTotal,
} from '../../../../types/Statistics';

import {
  SetPlanPaymentsTotalAction,
  SetPlanFeesTotalAction,
  SetCustomersTotalAction,
  SetPlansTotalAction,
  SetAmountCollectedAction,
  SetPlanTypesAction,
  SetCustomerLocationsAction,
  SetCustomerStatusesAction,
  SetCustomerStatusTotalAction,
  SET_PLAN_PAYMENTS_TOTAL,
  SET_PLAN_FEES_TOTAL,
  SET_CUSTOMERS_TOTAL,
  SET_PLANS_TOTAL,
  SET_AMOUNT_COLLECTED,
  SET_PLAN_TYPES,
  SET_CUSTOMER_LOCATIONS,
  SET_CUSTOMER_STATUSES,
  SET_CUSTOMER_STATUS_TOTAL,
} from './types';

export const setPlanPaymentsTotal = (amount: number): SetPlanPaymentsTotalAction => ({
  type: SET_PLAN_PAYMENTS_TOTAL,
  amount,
});

export const setPlanFeesTotal = (amount: number): SetPlanFeesTotalAction => ({
  type: SET_PLAN_FEES_TOTAL,
  amount,
});

export const setCustomersTotal = (amount: number): SetCustomersTotalAction => ({
  type: SET_CUSTOMERS_TOTAL,
  amount,
});

export const setPlansTotal = (amount: number): SetPlansTotalAction => ({
  type: SET_PLANS_TOTAL,
  amount,
});

export const setAmountCollected = (amountCollected: StatsAmountCollected[]): SetAmountCollectedAction => ({
  type: SET_AMOUNT_COLLECTED,
  amountCollected,
});

export const setCustomerStatusTotal = (
  customerStatusTotal: StatsCustomerStatusTotal[],
): SetCustomerStatusTotalAction => ({
  type: SET_CUSTOMER_STATUS_TOTAL,
  customerStatusTotal,
});

export const setPlanTypes = (planTypes: StatsPlanType[]): SetPlanTypesAction => ({
  type: SET_PLAN_TYPES,
  planTypes,
});

export const setCustomerLocations = (customerLocations: StatsLocation[]): SetCustomerLocationsAction => ({
  type: SET_CUSTOMER_LOCATIONS,
  customerLocations,
});

export const setCustomerStatuses = (customerStatuses: StatsStatus[]): SetCustomerStatusesAction => ({
  type: SET_CUSTOMER_STATUSES,
  customerStatuses,
});
