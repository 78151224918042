import styled from 'styled-components';

export const TransferFundsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: ${({ theme }) => theme.spacing.XS}rem;
`;

export const Title = styled.p`
color: ${({ theme }) => theme.basePalette.navyMain};
font-family: ${({ theme }) => theme.fontFamily.bodyBold};
font-size: 1.125rem;
margin-bottom: 5px;
`;

export const SubTitle = styled.p`
color: ${({ theme }) => theme.basePalette.greyDark};
font-family: ${({ theme }) => theme.fontFamily.bodyLight};
font-size: 0.875rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: ${({ theme }) => `${theme.spacing.XS}rem`};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.XS}rem;
`;

export const NoPlansText = styled.p`
  color: ${({ theme }) => theme.basePalette.redLight};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  text-align: center;
`;