import { apiFetch, apiRequest } from '../Utils';
import { serverCustomerAPI } from '../../../CONSTANTS';
import { setCustomerPaymentData } from '../../store/contexts/customer/payments/actions';

import { APIThunkResult } from '../../types/API';
import { PaymentDataView } from '../../types/DBViews';

export const getAllOutstandingPayments = (): APIThunkResult<PaymentDataView[]> => (
  apiRequest<PaymentDataView[]>(async (dispatch) => {
    const url = `${serverCustomerAPI}/payment/outstanding`;

    const { data, error } = await apiFetch<PaymentDataView[]>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setCustomerPaymentData(data));

    return data;
  }, true)
);
