import React from 'react';
import { useHistory } from 'react-router';

import Grid from '../../../../components/Common/Grid';
import Button from '../../../../components/Common/Button';
import PlanItem from '../PlanItem';

import {
  Heading,
  Subtext,
  ListHeader,
  CreatePlanLink,
  NewPlanCta,
  IconWrapper,
} from './Styles';

import { PlansListProps } from './types';
import useStrings from '../../../../hooks/useStrings';
import { PlusIcon } from '../../../../assets/Icons';

const PlansList: React.FC<PlansListProps> = ({ plans }) => {
  const [{ Pages: { Customer: { Dashboard: { PlansList: Strings } } } }] = useStrings();
  const history = useHistory();

  return (
    <Grid row>
      <Grid row marginTop="2rem">
        <Grid column sm={12}>
          <ListHeader>
            <div>
              <Heading light small>{Strings.plansHeading}</Heading>
              &nbsp;
              <Heading small highlight>{Strings.plansHeadingHighlight}</Heading>
            </div>
            <CreatePlanLink href="/customer/create-plan">{Strings.headerCreate}</CreatePlanLink>
          </ListHeader>
        </Grid>
      </Grid>
      <Grid row marginTop="2rem">
        {plans.map((plan) => (
          <Grid key={plan.planId} column sm={12} md={6} lg={3}>
            <PlanItem plan={plan} />
          </Grid>
        ))}
        <Grid column sm={12} md={6} lg={3}>
          <NewPlanCta>
            <IconWrapper>
              <img src={PlusIcon} alt="Plus Icon" />
            </IconWrapper>
            <div>
              <Heading light small>{Strings.createHeading}</Heading>
              &nbsp;
              <Heading highlight small>{Strings.createHeadingHighligh}</Heading>
            </div>
            <Subtext light>
              {Strings.createSubtext}
            </Subtext>
            <Button
              fontSize="0.85rem"
              padding="0.6rem 1.5rem"
              onClick={() => history.push('/customer/create-plan')}
            >
              {Strings.createButton}
            </Button>
          </NewPlanCta>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlansList;
