import { Customer } from '../../../../types/DBModels';
import { CustomerStatus } from '../../../../types/Customer';

export const SET_PROFILE_DATA = 'SET_SELECTED_CUSTOMER';
export const UPDATE_CUSTOMER_STATUS = 'UPDATE_CUSTOMER_STATUS';

export interface ProfileState {
  profileData: Customer | null;
}

export interface SetProfileDataAction {
  type: typeof SET_PROFILE_DATA;
  profileData: Customer;
}

export interface UpdateCustomerStatusAction {
  type: typeof UPDATE_CUSTOMER_STATUS;
  status: CustomerStatus;
}

export type ProfileActionTypes = SetProfileDataAction | UpdateCustomerStatusAction;
