import React from 'react';
import { useTheme } from 'styled-components';
import {
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  TooltipProps,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import StatsTooltip from '../StatsTooltip';

import {
  Container,
  ContainerLeft,
  ContainerRight,
  Value,
  TooltipText,
  TooltipSubtext,
} from './Styles';

import { AmountCollectedProps } from './types';
import { formatLargeNumber } from '../../../../lib/utils';

const AmountCollected: React.FC<AmountCollectedProps> = ({ data, showAllTime }) => {
  const theme = useTheme();
  const chartData = data.map(({ month, value }) => ({ name: month, value }));
  const chartDataYearTotals = data.map((item) => {
    const currentYearMonths = data.filter((i) => i.year === item.year);
    const currentYearTotal = currentYearMonths.reduce((acc, curr) => acc + curr.value, 0);
    const displayName = !!item.year && item.year.toString();
    return {
      name: displayName,
      value: currentYearTotal,
    };
  }).filter((item, i, arr) => arr.findIndex((ele) => ele.name === item.name) === i);

  const amountTotal = chartData.reduce((acc, curr) => acc + curr.value, 0);
  const displayValue = formatLargeNumber(amountTotal);

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      const currentName = payload[0].payload.name;
      return (
        <StatsTooltip>
          <TooltipText>{`$${formatLargeNumber(payload[0].value as number)}`}</TooltipText>
          <TooltipSubtext>{currentName}</TooltipSubtext>
        </StatsTooltip>
      );
    }
    return null;
  };

  return (
    <Container>
      <ContainerLeft>
        <Value>${displayValue}</Value>
      </ContainerLeft>
      <ContainerRight>
        <ResponsiveContainer width="99%" height="100%">
          <LineChart
            data={showAllTime ? chartDataYearTotals : chartData}
            margin={{
              top: 10,
              right: 10,
              left: 20,
              bottom: 10,
            }}
          >
            <XAxis dataKey="name" hide />
            <Tooltip
              content={<CustomTooltip />}
              cursor={false}
            />
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="value"
              stroke={theme.basePalette.blueMain}
              strokeWidth={4}
              dot={false}
              activeDot={{ stroke: theme.basePalette.blueMain, fill: 'white', strokeWidth: 3, r: 5 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </ContainerRight>
    </Container>
  );
};

export default AmountCollected;
