import styled from 'styled-components';

export const FilterContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.basePalette.greyMain};
  display: flex;
  position: relative;
  left: 0;
  padding: 0 2rem;
  width: calc(100% + 30px);
`;

export const Label = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.S as number * 1.05}rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-right: ${({ theme }) => theme.spacing.XS}rem;
`;

export const DropdownWrapper = styled.div`
  width: 40%;
  min-width: 300px;
`;