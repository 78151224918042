import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import useStrings from '../../../../hooks/useStrings';
import { buildHandlers } from '../../../../components/Common/Table/handlers';
import { deletePlan } from '../../../../lib/api/admin/plans';
import {
  INSTALLMENT_FREQUENCY_DISPLAY,
  PLAN_STATUS_DISPLAY,
  hiddenPlanStatusOptions,
  PLAN_TYPE_DISPLAY_ADMIN_TABLES,
} from '../../../../CONSTANTS';

import Pagination from '../../../../components/Common/Table/Pagination';
import { TableColumn } from '../../../../components/Common/Table/types';
import ContextMenu from '../../../../components/Common/ContextMenu';
import DeleteModal from '../../../../components/Common/DeleteModal';

import {
  Select,
  TableJssOverrides,
  NoRecordsText,
} from '../../../../components/Common/Table/Styles';

import { PlansTableProps } from './types';
import { APIThunkDispatch, OrderDirection, PlanFilterOptions } from '../../../../lib/types/API';
import { PlanDataView } from '../../../../lib/types/DBViews';
import { PlanStatus } from '../../../../lib/types/Plan';

const PlansTable: React.FC<PlansTableProps<PlanFilterOptions>> = ({
  currentData,
  recordCount,
  filter,
  updateFilter,
  onRowClicked,
  clearFilters,
  onSetPlanStatus,
}) => {
  const Dispatch: APIThunkDispatch = useDispatch();
  const [{
    GenericText,
    Pages: { UserProfile: { Plans: { deletePlanModal } } },
    Components: { Common: { Table } } }] = useStrings();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletePlanData, setDeletePlanData] = useState<{
    userId: string,
    planId: number,
  } | null>(null);

  const handleDeletePlan = async () => {
    if (deletePlanData?.planId && deletePlanData?.userId) {
      const { userId, planId } = deletePlanData;
      setIsDeleting(true);
      await Dispatch(deletePlan(planId, userId));
      setIsDeleting(false);

      setIsDeleteModalOpen(false);
    }
  };
  const columns: TableColumn<PlanDataView>[] = [
    {
      name: 'Type',
      selector: 'planType',
      sortable: true,
      grow: 0.8,
      format: (row) => PLAN_TYPE_DISPLAY_ADMIN_TABLES[row.planType as string],
    },
    {
      name: 'Reg No',
      selector: 'registration',
      sortable: true,
      grow: 0.75,
      format: (row) => row.registration?.toString() || '',
    },
    {
      name: 'Plan Name',
      selector: 'planName',
      sortable: true,
      grow: 1.5,
    },
    {
      name: 'Frequency',
      selector: 'frequency',
      sortable: true,
      grow: 1,
      format: (row) => INSTALLMENT_FREQUENCY_DISPLAY[row.frequency as string],
    },
    {
      name: 'Start Date',
      selector: 'startDate',
      sortable: true,
      grow: 1,
      format: (row) => dayjs(row.startDate as Date).format('DD/MM/YYYY'),
    },
    {
      name: 'End Date',
      selector: 'endDate',
      sortable: true,
      grow: 1,
      format: (row) => dayjs((row.scheduledEndDate || row.endDate) as Date).format('DD/MM/YYYY'),
    },
    {
      name: 'Due Date',
      selector: 'dueDate',
      sortable: true,
      grow: 1,
      format: (row) => dayjs(row.dueDate as Date).format('DD/MM/YYYY'),
    },
    {
      name: 'Amount',
      selector: 'value',
      sortable: true,
      grow: 0.5,
      format: (row) => `$${Number(row.value).toFixed(2)}`,
    },
    {
      name: 'Instalment',
      selector: 'latestInstallmentValue',
      sortable: true,
      grow: 0.5,
      format: (row) => `$${Number(row.latestInstallmentValue).toFixed(2)}`,
    },
    {
      name: 'Fee',
      selector: 'adminFee',
      sortable: true,
      grow: 0.5,
      format: (row) => `$${Number(row.adminFee).toFixed(2)}`,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      grow: 1.8,
      style: {
        minWidth: '125px',
      },
      cell: ({ status, id, userId, planId }) => (
        <>
          <Select
            status={status as string}
            statusType="PLAN"
            key={id}
            onChange={(val) => onSetPlanStatus(id, PlanStatus[val.target.value as keyof typeof PlanStatus])}
            disabled={status === PlanStatus.CANCELLED || status === PlanStatus.COMPLETE}
          >
            <option value={status as string} key={id}>
              {PLAN_STATUS_DISPLAY[status as string]}
            </option>
            {hiddenPlanStatusOptions.map(({ value, label }) => value !== status && (
            <option key={`planStatus - ${value}:${id}`} value={value}>{label}</option>
            ))}
          </Select>
          <ContextMenu
            forPlanTable
            onDeleteClick={() => {
              setDeletePlanData({
                userId: userId as string,
                planId: planId as number,
              });
              setIsDeleteModalOpen(true);
            }}
          />
        </>
      ),
    },
  ];

  const {
    handlePageChange,
    handleSortChange,
  } = buildHandlers<PlanFilterOptions>(
    updateFilter,
    filter,
    undefined,
    undefined,
    clearFilters,
  );

  const noRecords = (
    <NoRecordsText>
      <p>{Table.noRecords}</p>
    </NoRecordsText>
  );

  return (
    <>
      {isDeleteModalOpen && (
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setDeletePlanData(null);
        }}
        onConfirm={() => handleDeletePlan()}
        title={deletePlanModal.title}
        subtext={deletePlanModal.subTitle}
        cancelButtonText={GenericText.cancel}
        confirmButtonText={GenericText.delete}
        isDeleting={isDeleting}

      />
      )}
      <DataTable
        subHeaderAlign="left"
        highlightOnHover
        noContextMenu
        noHeader
        onRowClicked={onRowClicked}
        data={currentData}
        keyField="key"
        columns={columns}
        pagination
        paginationServer
        paginationPerPage={filter.rowsPerPage}
        paginationTotalRows={recordCount}
        paginationDefaultPage={filter.page}
        onChangePage={handlePageChange}
        paginationComponent={Pagination}
        onSort={handleSortChange}
        sortServer
        defaultSortField={filter.orderColumn}
        defaultSortAsc={filter.orderDirection === OrderDirection.ASC}
        noDataComponent={noRecords}
        customStyles={TableJssOverrides}
        style={{ borderRadius: 0 }}
      />
    </>
  );
};

export default PlansTable;
