import { Note } from '../../../../types/DBModels';

import {
  SetCustomerNotesAction,
  CreateCustomerNoteAction,
  UpdateCustomerNoteAction,
  DeleteCustomerNoteAction,
  ClearCustomerNotesAction,
  SET_CUSTOMER_NOTE_DATA,
  UPDATE_CUSTOMER_NOTE,
  DELETE_CUSTOMER_NOTE,
  CREATE_CUSTOMER_NOTE,
  CLEAR_CUSTOMER_NOTE_DATA,
} from './types';

export const setCustomerNotesData = (
  customerNotes: Note[],
): SetCustomerNotesAction => ({
  type: SET_CUSTOMER_NOTE_DATA,
  customerNotes,
});

export const createCustomerNote = (
  customerNote: Note,
): CreateCustomerNoteAction => ({
  type: CREATE_CUSTOMER_NOTE,
  customerNote,
});
export const updateCustomerNote = (
  customerNote: Note,
): UpdateCustomerNoteAction => ({
  type: UPDATE_CUSTOMER_NOTE,
  customerNote,
});
export const deleteCustomerNote = (
  customerNoteId: number | undefined,
): DeleteCustomerNoteAction => ({
  type: DELETE_CUSTOMER_NOTE,
  customerNoteId,
});

export const clearCustomerNotesData = (): ClearCustomerNotesAction => ({
  type: CLEAR_CUSTOMER_NOTE_DATA,
});
