import styled from 'styled-components';

export const Container = styled.div`
  padding-top: ${({ theme }) => theme.spacing.XXS}rem;
  height: 100%;
  width: 100%;
`;

export const NoDataText = styled.span`
  color: ${({ theme }) => theme.basePalette.navyMain};
`;
