import React from 'react';

import { Header, PageName, PageSubtitle } from './Styles';

import { PageHeaderProps } from './types';

const PageHeader: React.FC<PageHeaderProps> = ({ pageTitle, pageSubtitle }) => (
  <Header>
    <PageSubtitle>{pageSubtitle}</PageSubtitle>
    <PageName>{pageTitle}</PageName>
  </Header>
);

export default PageHeader;
