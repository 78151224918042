import { CustomerPlansListView } from '../../../../../types/DBViews';

import {
  SetAllArchivedPlansAction,
  SetUpdatedArchivedPlansAction,
  CreateArchivedPlan,
  ClearSelectedCustomerArchivedPlansAction,
  SET_ALL_ARCHIVED_PLAN_DATA,
  SET_UPDATED_ARCHIVED_PLAN_DATA,
  CREATE_ARCHIVED_PLAN,
  CLEAR_SELECTED_CUSTOMER_ARCHIVED_PLANS,
} from './types';

export const setAllArchivedPlanData = (
  archivedPlanData: {
    results: CustomerPlansListView[];
    count: number;
  },
): SetAllArchivedPlansAction => ({
  type: SET_ALL_ARCHIVED_PLAN_DATA,
  archivedPlanData,
});

export const setUpdatedArchivedPlanData = (
  archivedPlans: CustomerPlansListView[],
): SetUpdatedArchivedPlansAction => ({
  type: SET_UPDATED_ARCHIVED_PLAN_DATA,
  archivedPlans,
});

export const createArchivedPlan = (
  archivedPlan: CustomerPlansListView,
): CreateArchivedPlan => ({
  type: CREATE_ARCHIVED_PLAN,
  archivedPlan,
});

export const clearSelectedCustomerArchivedPlans = (): ClearSelectedCustomerArchivedPlansAction => ({
  type: CLEAR_SELECTED_CUSTOMER_ARCHIVED_PLANS,
});
