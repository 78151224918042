import { PlanDataView } from '../../../../types/DBViews';

export const SET_ALL_PLAN_DATA = 'SET_ALL_PLAN_DATA';

export interface PlansPaginatedState {
  planData: {
    plans: PlanDataView[];
    count: number;
  }
}
export interface SetPlansAction {
  type: typeof SET_ALL_PLAN_DATA;
  planData: {
    results: PlanDataView[];
    count: number;
  }
}

export type PlanActionTypes =
SetPlansAction;
