import React from 'react';

import PageLayout from '../../../layouts/PageLayout';

import useStrings from '../../../hooks/useStrings';
import { NewslettersProps } from './types';

const Newsletters: React.FC<NewslettersProps> = () => {
  const [{ Pages: { Newsletters: Strings } }] = useStrings();

  return (
    <PageLayout
      pageTitle={Strings.pageTitle}
      maxWidth="100%"
    >
      <div>{Strings.pageTitle}</div>
    </PageLayout>
  );
};

export default Newsletters;
