import styled from 'styled-components/macro';

import { HeadingProps } from './types';

const StyledHeading = styled.h1<HeadingProps>`
  color: ${({ theme, highlight }) => (
    highlight ? theme.palette.titleHighlight : theme.palette.title
  )};
  font-family: ${({ theme, defaultFont }) => (
    defaultFont ? theme.fontFamily.bodyBold : theme.fontFamily.heading
  )};
  font-size: 3rem;
  font-family: ${({ theme, defaultFont }) => (
    defaultFont ? theme.fontWeight.regular : theme.fontWeight.light
  )};
  margin-bottom: 0.5rem;
`;

export default StyledHeading;
