import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { setNotification } from '../../../lib/store/contexts/notification/actions';
import { updateTermsAccepted } from '../../../lib/api/customer/profile';
import { createPassword } from '../../../lib/Authentication';
import useStrings from '../../../hooks/useStrings';
import createPasswordSchema from '../../../lib/validators/createPasswordSchema';

import Grid from '../../Common/Grid';
import Input from '../../Common/Input';
import Button from '../../Common/Button';
import Heading from '../../Common/Heading';
import {
  FormSection,
  ButtonWrapper,
  SubText } from './Styles';

import { UpdatePasswordFormProps } from './types';
import { APIThunkDispatch } from '../../../lib/types/API';

const CreatePasswordForm: React.FC<UpdatePasswordFormProps> = () => {
  const [{ Pages: { CreatePassword: Strings } }] = useStrings();
  const Dispatch: APIThunkDispatch = useDispatch();

  return (
    <>
      <Formik
        initialValues={{
          newPassword: '',
          confirmPassword: '',
        }}
        validationSchema={createPasswordSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async ({ newPassword }, { setSubmitting }) => {
          try {
            await createPassword(newPassword);
            await Dispatch(updateTermsAccepted({ termsAccepted: true }));

            setSubmitting(false);
          } catch (error) {
            Dispatch(setNotification({
              message: error.message,
              variant: 'danger',
              duration: 5,
            }));
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <FormSection>
              <Grid row alignItems="center">
                <Grid column alignItems="center" sm={12}>
                  <Grid row alignItems="center">
                    <Heading defaultFont={false}>
                      {Strings.createPasswordTitle}
                    </Heading>
                    {Strings.createPasswordHighlight && (
                    <Heading highlight defaultFont={false}>
                      {' '}
                      &nbsp;
                      {Strings.createPasswordHighlight}
                    </Heading>
                    )}
                  </Grid>
                  <SubText>{Strings.createPasswordSubtitle}</SubText>
                </Grid>
              </Grid>
              <Grid row marginTop="5rem" />
              <Grid row alignItems="center">
                <Grid column alignItems="center" sm={12}>
                  <Input
                    type="password"
                    name="newPassword"
                    label={Strings.currentPasswordInput}
                    placeholder={Strings.currentPasswordInput}
                    width="100%"
                    outlined
                  />
                </Grid>
              </Grid>
              <Grid row alignItems="center">
                <Grid column alignItems="center" sm={12}>
                  <Input
                    type="password"
                    name="confirmPassword"
                    label={Strings.newPasswordInput}
                    placeholder={Strings.newPasswordInput}
                    width="100%"
                    outlined
                  />
                </Grid>
              </Grid>
            </FormSection>
            <ButtonWrapper>
              <Button
                margin="0"
                width="160%"
                onClick={handleSubmit}
                disabled={isSubmitting}
                $loading={isSubmitting}
                expandedMobile={false}
              >
                {Strings.resetPassword}
              </Button>
            </ButtonWrapper>
          </>
        )}
      </Formik>
    </>
  );
};

export default CreatePasswordForm;
