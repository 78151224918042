import React from 'react';

import useStrings from '../../../hooks/useStrings';

import Logo from '../../Common/Logo';

import { SideNavIcons } from '../../../assets/Icons';
import {
  SideNavLink,
  SideNavIcon,
  SideNavLinkText,
  SideNavWrapper,
  LogoWrapper,
} from './Styles';

const SideNav: React.FC = () => {
  const [{ Components: { UI: { SideNav: Strings } } }] = useStrings();

  const linkOptions = [
    {
      to: '/admin/dashboard',
      text: Strings.dashboard,
      icon: SideNavIcons.dashboard,
    },
    {
      to: '/admin/users',
      text: Strings.customers,
      icon: SideNavIcons.users,
    },
    {
      to: '/admin/due-plans',
      text: Strings.duePlans,
      icon: SideNavIcons.duePlans,
    },
    {
      to: '/admin/payments',
      text: Strings.payments,
      icon: SideNavIcons.payments,
    },
    {
      to: '/admin/overdue-installments',
      text: Strings.overdueInstallments,
      icon: SideNavIcons.overdueInstallments,
    },
    /* { */
    /*   to: '/admin/newsletters', */
    /*   text: Strings.newsletters, */
    /*   icon: SideNavIcons.newsletters, */
    /* }, */
    {
      to: '/admin/support',
      text: Strings.support,
      icon: SideNavIcons.support,
    },
    {
      to: '/admin/reports',
      text: Strings.reports,
      icon: SideNavIcons.reports,
      iconMargin: '0 1.25rem 0 0.25rem',
    },
    /* { */
    /*   to: '/admin/notifications', */
    /*   text: Strings.notifications, */
    /*   icon: SideNavIcons.notifications, */
    /* }, */
    /* { */
    /*   to: '/admin/settings', */
    /*   text: Strings.settings, */
    /*   icon: SideNavIcons.settings, */
    /* }, */
    {
      to: '/admin/settings/edit-profile',
      text: Strings.settings,
      icon: SideNavIcons.settings,
    },
  ];

  return (
    <SideNavWrapper>
      <LogoWrapper><Logo /></LogoWrapper>
      {linkOptions.map((link) => (
        <SideNavLink key={link.to} to={link.to}>
          <SideNavIcon margin={link?.iconMargin} src={link.icon} />
          <SideNavLinkText>{link.text}</SideNavLinkText>
        </SideNavLink>
      ))}
    </SideNavWrapper>
  );
};

export default SideNav;
