import React, { useState } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import Grid from '../../Common/Grid';
import {
  FormSection,
  LineBreak,
  HeadingText,
  Subtitle,
  ItemText,
  InfoBar,
  InfoBarPrice,
  InfoBarMain,
  InfoBarTextWrap,
  InfoBarText,
  InfoBarFrequency,
  ContentHeader,
  MainContent,
  Icon,
  AdminFee,
} from './Styles';

import useStrings from '../../../hooks/useStrings';
import { PlanEstimateProps } from './types';
import {
  PLAN_TYPE_DISPLAY,
  REGISTRATION_LENGTH_DISPLAY,
  INSTALLMENT_FREQUENCY_DISPLAY,
  INSTALLMENT_FREQUENCY_ABBR,
  FEES,
  CONCESSION_DISPLAY,
} from '../../../CONSTANTS';
import { calculateCustomerPlanPaymentData } from '../../../lib/utils';
import { blueCaretDown } from '../../../assets/Icons';
import { PlanType } from '../../../lib/types/Plan';

dayjs.extend(advancedFormat);

const PlanEstimate: React.FC<PlanEstimateProps> = ({ planValues, planType }) => {
  const [{ Components: { UI: { PlanEstimate: Strings } } }] = useStrings();
  const [openMobile, setOpenMobile] = useState(false);

  const selectedPlanType = planType ? PLAN_TYPE_DISPLAY[planType] : '';
  const startDate = planValues?.startDate ? dayjs(planValues.startDate).format('Do MMMM YYYY') : '';
  const dueDate = planValues?.dueDate ? dayjs(planValues.dueDate).format('Do MMMM YYYY') : '';
  const registrationLength = planValues?.registrationLength ? REGISTRATION_LENGTH_DISPLAY[planValues?.registrationLength] : '';
  const installmentFrequency = planValues?.installmentFrequency ? INSTALLMENT_FREQUENCY_DISPLAY[planValues?.installmentFrequency] : '';
  const installmentFrequencyAbbr = planValues?.installmentFrequency ? INSTALLMENT_FREQUENCY_ABBR[planValues?.installmentFrequency] : '';
  const adminFee = FEES[installmentFrequency.toLowerCase()];

  const calculatePaymentAmount = () => calculateCustomerPlanPaymentData({
    startDate: planValues?.startDate,
    dueDate: planValues?.dueDate,
    installmentFrequency: planValues?.installmentFrequency,
    totalPlanValue: planValues?.value,
  });

  return (
    <>
      <ContentHeader open={openMobile} onClick={() => setOpenMobile(!openMobile)}>
        <div>
          <HeadingText>{Strings.title}</HeadingText>
          &nbsp;
          <HeadingText highlight>{Strings.titleHighlight}</HeadingText>
          <Subtitle>{Strings.subtitle}</Subtitle>
        </div>
        <Icon open={openMobile} src={blueCaretDown} />
      </ContentHeader>
      <MainContent open={openMobile}>
        <Grid>
          <LineBreak />
        </Grid>
        <FormSection>
          <Grid row marginTop="1.5rem">
            <Grid column sm={6} alignItems="flex-start">
              <ItemText>{Strings.type}</ItemText>
            </Grid>
            <Grid column sm={6} alignItems="flex-end">
              <ItemText alignRight lightWeight>{selectedPlanType}</ItemText>
            </Grid>
          </Grid>
          {planValues?.name && (
            <Grid row marginTop="1.5rem">
              <Grid column sm={6} alignItems="flex-start">
                <ItemText>{Strings.name}</ItemText>
              </Grid>
              <Grid column sm={6} alignItems="flex-end">
                <ItemText alignRight lightWeight>{planValues.name}</ItemText>
              </Grid>
            </Grid>
          )}
          <Grid row marginTop="1.5rem">
            <Grid column sm={6} alignItems="flex-start">
              <ItemText>{Strings.amount}</ItemText>
            </Grid>
            <Grid column sm={6} alignItems="flex-end">
              <ItemText alignRight lightWeight>{`$${planValues?.value || '0.00'}`}</ItemText>
            </Grid>
          </Grid>
          {planType === PlanType.VEHICLE_REGISTRATION && (
            <Grid row marginTop="1.5rem">
              <Grid column sm={6} alignItems="flex-start">
                <ItemText>{Strings.renewalPeriod}</ItemText>
              </Grid>
              <Grid column sm={6} alignItems="flex-end">
                <ItemText alignRight lightWeight>{registrationLength}</ItemText>
              </Grid>
            </Grid>
          )}
          <Grid row marginTop="1.5rem">
            <Grid column sm={6} alignItems="flex-start">
              <ItemText>{Strings.starts}</ItemText>
            </Grid>
            <Grid column sm={6} alignItems="flex-end">
              <ItemText alignRight lightWeight>{startDate}</ItemText>
            </Grid>
          </Grid>
          <Grid row marginTop="1.5rem">
            <Grid column sm={6} alignItems="flex-start">
              <ItemText>{Strings.due}</ItemText>
            </Grid>
            <Grid column sm={6} alignItems="flex-end">
              <ItemText alignRight lightWeight>{dueDate}</ItemText>
            </Grid>
          </Grid>
          <Grid row marginTop="1.5rem">
            <Grid column sm={6} alignItems="flex-start">
              <ItemText>{Strings.frequency}</ItemText>
            </Grid>
            <Grid column sm={6} alignItems="flex-end">
              <ItemText alignRight lightWeight>{installmentFrequency}</ItemText>
            </Grid>
          </Grid>
        </FormSection>
        {planType === PlanType.VEHICLE_REGISTRATION && (
          <>
            <Grid row marginTop="1.5rem">
              <LineBreak />
            </Grid>
            <FormSection>
              <Grid row marginTop="1.5rem">
                <Grid column sm={6} alignItems="flex-start">
                  <ItemText>{Strings.year}</ItemText>
                </Grid>
                <Grid column sm={6} alignItems="flex-end">
                  <ItemText alignRight lightWeight>
                    {planValues?.vehicle?.year ? dayjs(planValues.vehicle.year).format('YYYY') : ''}
                  </ItemText>
                </Grid>
              </Grid>
              <Grid row marginTop="1.5rem">
                <Grid column sm={6} alignItems="flex-start">
                  <ItemText>{Strings.make}</ItemText>
                </Grid>
                <Grid column sm={6} alignItems="flex-end">
                  <ItemText alignRight lightWeight>{planValues?.vehicle?.make || ''}</ItemText>
                </Grid>
              </Grid>
              <Grid row marginTop="1.5rem">
                <Grid column sm={6} alignItems="flex-start">
                  <ItemText>{Strings.model}</ItemText>
                </Grid>
                <Grid column sm={6} alignItems="flex-end">
                  <ItemText alignRight lightWeight>{planValues?.vehicle?.model || ''}</ItemText>
                </Grid>
              </Grid>
              <Grid row marginTop="1.5rem">
                <Grid column sm={6} alignItems="flex-start">
                  <ItemText>{Strings.colour}</ItemText>
                </Grid>
                <Grid column sm={6} alignItems="flex-end">
                  <ItemText alignRight lightWeight>{planValues?.vehicle?.color || ''}</ItemText>
                </Grid>
              </Grid>
              <Grid row marginTop="1.5rem">
                <Grid column sm={6} alignItems="flex-start">
                  <ItemText>{Strings.registration}</ItemText>
                </Grid>
                <Grid column sm={6} alignItems="flex-end">
                  <ItemText alignRight lightWeight>{planValues?.vehicle?.registration || ''}</ItemText>
                </Grid>
              </Grid>
              <Grid row marginTop="1.5rem">
                <Grid column sm={6} alignItems="flex-start">
                  <ItemText>{Strings.state}</ItemText>
                </Grid>
                <Grid column sm={6} alignItems="flex-end">
                  <ItemText alignRight lightWeight>{planValues?.vehicle?.registeredState || ''}</ItemText>
                </Grid>
              </Grid>
              <Grid row marginTop="1.5rem">
                <Grid column sm={6} alignItems="flex-start">
                  <ItemText>{Strings.concessionCard}</ItemText>
                </Grid>
                <Grid column sm={6} alignItems="flex-end">
                  <ItemText alignRight lightWeight>{planValues?.concessionCard ? CONCESSION_DISPLAY[planValues.concessionCard] : ''}</ItemText>
                </Grid>
              </Grid>
            </FormSection>
          </>
        )}
        <Grid row marginTop="1rem">
          <LineBreak />
        </Grid>
        {calculatePaymentAmount() && (
          <InfoBar>
            <InfoBarMain>
              <InfoBarTextWrap>
                <InfoBarText>{Strings.startDateText1}</InfoBarText>
                &nbsp;
                <InfoBarText highlight>{dayjs(planValues?.startDate).format('Do MMMM')}</InfoBarText>
                <br />
                <InfoBarText>{Strings.startDateText2}</InfoBarText>
                &nbsp;
                <InfoBarText highlight>{calculatePaymentAmount()?.numberOfPayments}</InfoBarText>
                &nbsp;
                <InfoBarText>{Strings.startDateText3}</InfoBarText>
              </InfoBarTextWrap>
              <div>
                <InfoBarPrice>{`$${calculatePaymentAmount()?.installmentAmount.toFixed(2)}`}</InfoBarPrice>
                &nbsp;
                <InfoBarFrequency>{installmentFrequencyAbbr}</InfoBarFrequency>
              </div>
            </InfoBarMain>
            <AdminFee>{Strings.adminFee.replace('%', adminFee.toString())}</AdminFee>
          </InfoBar>
        )}
      </MainContent>
    </>
  );
};

export default PlanEstimate;
