import { TableJssOverrides } from '../../../../components/Common/Table/Styles';

export const extendedTableStyles = {
  ...TableJssOverrides,
  rows: {
    style: {
      cursor: 'pointer',
    },
  },
  table: {
    style: {
      height: 'calc(100vh - 150.2833px - 6rem)',
      overflow: 'scroll',
    },
  },
};