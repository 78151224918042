import { PaymentDataView } from '../../../../../types/DBViews';
import { InstallmentStatus } from '../../../../../types/Plan';
import { PlanForPayment } from '../../../../../types/DTO';

export const SET_ALL_PAYMENT_DATA = 'SET_ALL_PAYMENT_DATA';
export const SET_UPDATED_PAYMENT_DATA = 'SET_UPDATED_PAYMENT_DATA';
export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const UPDATE_INSTALLMENT_STATUS = 'UPDATE_INSTALLMENT_STATUS';
export const CLEAR_SELECTED_CUSTOMER_PAYMENTS = 'CLEAR_SELECTED_CUSTOMER_PAYMENTS';
export const SET_PAYMENT_FILTER_PLANS = 'SET_PAYMENT_FILTER_PLANS';
export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';
export const SET_NEEDS_UPDATE = 'SET_NEEDS_UPDATE';

export interface PaymentState {
  paymentData: {
    payments: PaymentDataView[];
    count: number;
  },
  selectedPlan?: string;
  paymentFilterPlans: PlanForPayment[];
  needsUpdate: boolean
}

export interface SetAllPaymentsAction {
  type: typeof SET_ALL_PAYMENT_DATA;
  paymentData: {
    results: PaymentDataView[];
    count: number;
  }
}
export interface SetUpdatedPaymentsAction {
  type: typeof SET_UPDATED_PAYMENT_DATA;
  payments: PaymentDataView[],
}
export interface CreatePayment {
  type: typeof CREATE_PAYMENT;
  payment: PaymentDataView,
}
export interface UpdateInstallmentStatusAction {
  type: typeof UPDATE_INSTALLMENT_STATUS;
  installmentId: number,
  installmentStatus: InstallmentStatus,
}
export interface ClearSelectedCustomerPaymentsAction {
  type: typeof CLEAR_SELECTED_CUSTOMER_PAYMENTS;
}
export interface SetPaymentFilterPlansAction {
  type: typeof SET_PAYMENT_FILTER_PLANS;
  plans: PlanForPayment[];
}
export interface SetSelectedPlanAction {
  type: typeof SET_SELECTED_PLAN;
  planId?: string;
}
export interface SetNeedsUpdateAction {
  type: typeof SET_NEEDS_UPDATE;
  needsUpdate: boolean;
}

export type PaymentActionTypes =
SetAllPaymentsAction |
SetUpdatedPaymentsAction |
UpdateInstallmentStatusAction |
ClearSelectedCustomerPaymentsAction |
SetPaymentFilterPlansAction |
SetSelectedPlanAction |
CreatePayment |
SetNeedsUpdateAction;
