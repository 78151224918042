import { apiFetch, apiRequest, objectToQueryString } from '../Utils';
import { serverAdminAPI } from '../../../CONSTANTS';

import { APIThunkResult, StatsFilterOptions } from '../../types/API';
import {
  SumDTO,
  CountDTO,
  CountListDTO,
  MonthlySumsListDTO,
} from '../../types/DTO';

import {
  setPlanPaymentsTotal,
  setPlanFeesTotal,
  setCustomersTotal,
  setPlansTotal,
  setAmountCollected,
  setPlanTypes,
  setCustomerLocations,
  setCustomerStatusTotal,
  setCustomerStatuses,
} from '../../store/contexts/admin/statistics/actions';

export const fetchPlanPaymentsTotal = (
  filter?: StatsFilterOptions,
): APIThunkResult<SumDTO> => (
  apiRequest<SumDTO>(async (dispatch) => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverAdminAPI}/statistics/payment-sum?${query}`;

    const { data, error } = await apiFetch<SumDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setPlanPaymentsTotal(data.sum));

    return data;
  }, true)
);

export const fetchPlanFeesTotal = (
  filter?: StatsFilterOptions,
): APIThunkResult<SumDTO> => (
  apiRequest<SumDTO>(async (dispatch) => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverAdminAPI}/statistics/fee-sum?${query}`;

    const { data, error } = await apiFetch<SumDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setPlanFeesTotal(data.sum));

    return data;
  }, true)
);

export const fetchCustomersTotal = (
  filter?: StatsFilterOptions,
): APIThunkResult<CountDTO> => (
  apiRequest<CountDTO>(async (dispatch) => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverAdminAPI}/statistics/customer-count?${query}`;

    const { data, error } = await apiFetch<CountDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setCustomersTotal(data.count));

    return data;
  }, true)
);

export const fetchPlansTotal = (
  filter?: StatsFilterOptions,
): APIThunkResult<CountDTO> => (
  apiRequest<CountDTO>(async (dispatch) => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverAdminAPI}/statistics/plan-count?${query}`;

    const { data, error } = await apiFetch<CountDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setPlansTotal(data.count));

    return data;
  }, true)
);

// TODO: remove once API number values are fixed
const transformAmountCollected = (data: MonthlySumsListDTO['monthlySums']) => (
  data.map(({ month, monthNumber, value, year }) => {
    const transformedValue = typeof value === 'string' ? parseFloat(value) : value;
    const transformedYear = typeof year === 'string' ? parseFloat(year) : year;
    return {
      month: month as string,
      monthNumber: monthNumber as number,
      value: transformedValue,
      year: transformedYear,
    };
  })
);

export const fetchAmountCollected = (
  filter?: StatsFilterOptions,
): APIThunkResult<MonthlySumsListDTO> => (
  apiRequest<MonthlySumsListDTO>(async (dispatch) => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverAdminAPI}/statistics/monthly-collected-sum?${query}`;

    const { data, error } = await apiFetch<MonthlySumsListDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    const transformedData = transformAmountCollected(data.monthlySums);

    dispatch(setAmountCollected(transformedData));

    return data;
  }, true)
);

// TODO: remove once API number values are fixed
const transformPlanTypeData = (data: CountListDTO['counts']) => data.map(({ type, value }) => {
  const transformedValue = typeof value === 'string' ? parseFloat(value) : value;
  return { type: type as string, value: transformedValue };
});

export const fetchPlanTypes = (
  filter?: StatsFilterOptions,
): APIThunkResult<CountListDTO> => (
  apiRequest<CountListDTO>(async (dispatch) => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverAdminAPI}/statistics/plan-type-count?${query}`;

    const { data, error } = await apiFetch<CountListDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    const transformedData = transformPlanTypeData(data.counts);

    dispatch(setPlanTypes(transformedData));

    return data;
  }, true)
);

// TODO: remove once API number values are fixed
const transformLocationData = (data: CountListDTO['counts']) => data.map(({ state, value }) => {
  const transformedValue = typeof value === 'string' ? parseFloat(value) : value;
  return { state: state as string, value: transformedValue };
});

export const fetchCustomerLocations = (
  filter?: StatsFilterOptions,
): APIThunkResult<CountListDTO> => (
  apiRequest<CountListDTO>(async (dispatch) => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverAdminAPI}/statistics/customer-location-count?${query}`;

    const { data, error } = await apiFetch<CountListDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    const transformedData = transformLocationData(data.counts);

    dispatch(setCustomerLocations(transformedData));

    return data;
  }, true)
);

const transformCustomerStatus = (data: MonthlySumsListDTO['monthlySums']) => (
  data.map(({ month, monthNumber, newUsers, year }) => {
    const transformedValue = typeof newUsers === 'string' ? parseFloat(newUsers) : newUsers;
    const transformedYear = typeof year === 'string' ? parseFloat(year) : year;
    return {
      month: month as string,
      monthNumber: monthNumber as number,
      newUsers: transformedValue,
      year: transformedYear,
    };
  })
);

export const fetchCustomerStatusTotal = (
  filter?: StatsFilterOptions,
): APIThunkResult<MonthlySumsListDTO> => (
  apiRequest<MonthlySumsListDTO>(async (dispatch) => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverAdminAPI}/statistics/monthly-customer-status-sum?${query}`;

    const { data, error } = await apiFetch<MonthlySumsListDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    const transformedData = transformCustomerStatus(data.monthlySums);

    dispatch(setCustomerStatusTotal(transformedData));

    return data;
  }, true)
);

const transformStatusData = (data: CountListDTO['counts']) => data.map(({ status, value }) => {
  const transformedValue = typeof value === 'string' ? parseFloat(value) : value;
  return { status: status as string, value: transformedValue };
});

export const fetchCustomerStatuses = (
  filter?: StatsFilterOptions,
): APIThunkResult<CountListDTO> => (
  apiRequest<CountListDTO>(async (dispatch) => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverAdminAPI}/statistics/customer-status-count?${query}`;

    const { data, error } = await apiFetch<CountListDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    const transformedData = transformStatusData(data.counts);

    dispatch(setCustomerStatuses(transformedData));

    return data;
  }, true)
);