import { VehicleMake } from '../../../types/DBModels';

export const SET_VEHICLE_MAKES = 'SET_VEHICLE_MAKES';

export interface VehicleMakesState {
  vehicleMakes: VehicleMake[];
}

export interface SetVehicleMakesAction {
  type: typeof SET_VEHICLE_MAKES;
  vehicleMakes: VehicleMake[];
}

export type VehicleMakesActionTypes = SetVehicleMakesAction;
