import { CustomerPlansListView } from '../../../../../types/DBViews';

export const SET_ALL_ARCHIVED_PLAN_DATA = 'SET_ALL_ARCHIVED_PLAN_DATA';
export const SET_UPDATED_ARCHIVED_PLAN_DATA = 'SET_UPDATED_ARCHIVED_PLAN_DATA';
export const CREATE_ARCHIVED_PLAN = 'CREATE_ARCHIVED_PLAN';
export const CLEAR_SELECTED_CUSTOMER_ARCHIVED_PLANS = 'CLEAR_SELECTED_CUSTOMER_ARCHIVED_PLANS';

export interface ArchivedPlanState {
  archivedPlanData: {
    archivedPlans: CustomerPlansListView[];
    count: number;
  }
}
export interface SetAllArchivedPlansAction {
  type: typeof SET_ALL_ARCHIVED_PLAN_DATA;
  archivedPlanData: {
    results: CustomerPlansListView[];
    count: number;
  }
}
export interface SetUpdatedArchivedPlansAction {
  type: typeof SET_UPDATED_ARCHIVED_PLAN_DATA;
  archivedPlans: CustomerPlansListView[],
}
export interface CreateArchivedPlan {
  type: typeof CREATE_ARCHIVED_PLAN;
  archivedPlan: CustomerPlansListView,
}
export interface ClearSelectedCustomerArchivedPlansAction {
  type: typeof CLEAR_SELECTED_CUSTOMER_ARCHIVED_PLANS;
}

export type ArchivedPlanActionTypes =
SetAllArchivedPlansAction |
SetUpdatedArchivedPlansAction |
ClearSelectedCustomerArchivedPlansAction |
CreateArchivedPlan;
