import {
  SET_ALL_TRANSFERABLE_PLAN_DATA,
  TransferablePlanActionTypes,
  TransferablePlanState,
} from './types';

const initialState: TransferablePlanState = {
  transferablePlans: [],
};

const TransferablePlanReducer = (
  state: TransferablePlanState = initialState,
  action: TransferablePlanActionTypes,
): TransferablePlanState => {
  switch (action.type) {
    case SET_ALL_TRANSFERABLE_PLAN_DATA:
      return {
        ...state,
        transferablePlans: action.transferablePlans,
      };
    default:
      return state;
  }
};

export default TransferablePlanReducer;
