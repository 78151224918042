import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import StatsTooltip from '../StatsTooltip';

import {
  Container,
  StatesContainer,
  StateItem,
  Dot,
  State,
  Count,
  TooltipText,
  TooltipSubtext,
} from './Styles';

import { CustomerLocationsProps } from './types';
import { formatLargeNumber } from '../../../../lib/utils';

const STATE_COLORS: { [key: string]: string } = {
  NSW: '#EA34A6',
  VIC: '#5073B8',
  QLD: '#0AB39C',
  WA: '#F1963A',
  SA: '#F16548',
  NT: '#C749F1',
  ACT: '#48D0F1',
  TAS: '#F1B948',
};

const CustomerLocations: React.FC<CustomerLocationsProps> = ({ data }) => {
  const chartData = data.map(({ state, value }) => ({ name: state, value }));

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <StatsTooltip>
          <TooltipText>{`${formatLargeNumber(payload[0].value as number)}`}</TooltipText>
          <TooltipSubtext>{payload[0].payload.name}</TooltipSubtext>
        </StatsTooltip>
      );
    }
    return null;
  };

  return (
    <Container>
      <StatesContainer>
        {chartData.map(({ name, value }) => (
          <StateItem key={name}>
            <Dot color={STATE_COLORS[name]} />
            <State>{name}</State>
            <Count>{value}</Count>
          </StateItem>
        ))}
      </StatesContainer>
      <ResponsiveContainer width="60%" height="100%">
        <PieChart
          margin={{
            top: 10,
            bottom: 10,
            left: 30,
          }}
        >
          <Tooltip content={<CustomTooltip />} />
          <Pie
            isAnimationActive={false}
            data={chartData}
            labelLine={false}
            outerRadius="100%"
            dataKey="value"
          >
            {chartData.map(({ name }, index) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <Cell key={`cell-${index}`} fill={STATE_COLORS[name]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default CustomerLocations;
