import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { defaultFilter, getCustomerPlans } from '../../../../lib/api/admin/plans';

import PlansTable from './ArchivedPlansTable';

import { ArchivedPlansProps } from './types';
import { APIThunkDispatch, PlanFilterOptions } from '../../../../lib/types/API';
import { ApplicationState } from '../../../../lib/store';
import { PlanStatus } from '../../../../lib/types/Plan';

const ArchivedPlans: React.FC<ArchivedPlansProps> = ({
  customer,
  onViewArchivedPlan,
}) => {
  const Dispatch: APIThunkDispatch = useDispatch();

  const { archivedPlanData } = useSelector((state: ApplicationState) => state.admin.selectedCustomerArchivedPlanState);

  const currentData = archivedPlanData.archivedPlans.map((plan) => ({ id: plan.planId, ...plan }));

  // Filter management
  const [filter, setFilter] = useState<PlanFilterOptions>({
    ...defaultFilter,
    customerId: customer.customerId,
    status: [PlanStatus.CANCELLED, PlanStatus.COMPLETE],
  });
  const clearFilters = () => setFilter({
    ...defaultFilter,
    customerId: customer.customerId,
    status: [PlanStatus.CANCELLED, PlanStatus.COMPLETE],
  });
  const updateFilter = (newFilter: PlanFilterOptions) => {
    setFilter({
      ...defaultFilter,
      ...newFilter,
      customerId: customer.customerId, // customerId is fixed
      status: [PlanStatus.CANCELLED, PlanStatus.COMPLETE], // Only fetch plans with these statuses.
    });
  };

  const fetchPlans = useCallback(() => {
    Dispatch<void>(getCustomerPlans(customer.customerId, filter, 'ARCHIVED')); // second argument tells function where to store resulting data.
  }, [Dispatch, filter, customer.customerId]);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  const viewPlan = (rowId: number) => {
    const plan = currentData.find((planDataView) => planDataView.id === rowId);
    if (!plan) return;
    onViewArchivedPlan(plan.planId, plan.customerId);
  };

  return (
    <PlansTable
      currentData={currentData}
      recordCount={archivedPlanData.count}
      filter={filter}
      updateFilter={updateFilter}
      clearFilters={clearFilters}
      onRowClicked={(row) => viewPlan(row.id)}
    />
  );
};

export default ArchivedPlans;
