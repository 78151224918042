import React from 'react';

import { Grid as LayoutGrid } from '../MasterLayout';
import Logo from '../../components/Common/Logo';

import {
  SideContent,
  MainContent,
  MainContentInner,
  BackgroundImage,
  LogoWrapper,
  BottomContent,
  BottomContentLink,
  BottomContentText,
  BackgroundText,
  BackgroundTextWrap,
  BackgroundTextSmall,
} from './Styles';

import useStrings from '../../hooks/useStrings';
import { LoginLayoutProps } from './types';

const LoginLayout: React.FC<LoginLayoutProps> = ({
  children,
  backgroundImage,
  mainContentMargin = '0',
  reversed = false,
  loginVariant,
  hideLogo = false,
}) => {
  const [{ Components: { UI: { LoginLayout: Strings } } }] = useStrings();

  const renderBottomContent = () => {
    if (loginVariant === 'customer-login') {
      return (
        <BottomContent>
          <BottomContentText>{Strings.signupCtaText}</BottomContentText>
          <BottomContentLink href="/sign-up">
            {Strings.signupCta}
          </BottomContentLink>
        </BottomContent>
      );
    }
    if (loginVariant === 'customer-signup') {
      return (
        <BottomContent>
          <BottomContentText>{Strings.loginCtaText}</BottomContentText>
          <BottomContentLink href="/">
            {Strings.loginCta}
          </BottomContentLink>
        </BottomContent>
      );
    }
    return null;
  };

  const renderBackgroundText = () => {
    if (loginVariant === 'customer-login') {
      return (
        <BackgroundTextWrap>
          <div>
            <BackgroundText>
              {Strings.bgTextLogin1}
            </BackgroundText>
            <BackgroundText highlight>
              &nbsp;
              {Strings.bgTextLoginHighlight}
            </BackgroundText>
            <BackgroundText>
              &nbsp;
              {Strings.bgTextLogin2}
            </BackgroundText>
          </div>
          <div>
            <BackgroundTextSmall>{Strings.bgTextLoginSmall}</BackgroundTextSmall>
          </div>
        </BackgroundTextWrap>
      );
    }
    if (loginVariant === 'customer-signup') {
      return (
        <BackgroundTextWrap>
          <div>
            <BackgroundText>
              {Strings.bgTextSignup1}
            </BackgroundText>
            <BackgroundText highlight>
              &nbsp;
              {Strings.bgTextSignupHighlight}
            </BackgroundText>
            <BackgroundText>
              &nbsp;
              {Strings.bgTextSignup2}
            </BackgroundText>
          </div>
          <div>
            <BackgroundTextSmall>{Strings.bgTextSignupSmall}</BackgroundTextSmall>
          </div>
        </BackgroundTextWrap>
      );
    }
    return null;
  };

  return (
    <LayoutGrid reversed={reversed}>
      <SideContent>
        <BackgroundImage backgroundImage={backgroundImage} />
        {renderBackgroundText()}
      </SideContent>
      <MainContent margin={mainContentMargin}>
        <MainContentInner>
          {!hideLogo && (
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
          )}
          {children}
        </MainContentInner>
        {renderBottomContent()}
      </MainContent>
    </LayoutGrid>
  );
};

export default LoginLayout;
