import {
  CustomerStatus,
  PaymentStatus,
  State,
} from './lib/types/Customer';
import {
  PlanStatus,
  PlanType,
  InstallmentFrequency,
  RegistrationLength,
  InstallmentStatus,
  Concession,
} from './lib/types/Plan';
import { CustomerProfileView } from './pages/Admin/CustomerProfile/types';
import { VehicleType } from './lib/types/Vehicle';
import { SettingsView } from './pages/Admin/Settings/types';
import { UpdatePaymentActionTypes } from './lib/types/API';
import { SupportMessageStatus } from './lib/types/SupportMessage';

export const serverBaseAPI = `${process.env.REACT_APP_API_URL}/api`;
export const serverAdminAPI = `${process.env.REACT_APP_API_URL}/admin-api`;
export const serverCustomerAPI = `${process.env.REACT_APP_API_URL}/customer-api`;

/* TODO: CHANGE LINK ONCE LIVE
This is the current link for terms and conditions:
https://benj35.sg-host.com/wp-content/uploads/2021/09/Forgetreg_terms_and_conditions.pdf
But it will change to:
https://forgetreg.com.au/wp-content/uploads/2021/09/Forgetreg_terms_and_conditions.pdf
Once everything goes live */

export const termsAndConditionsLink = 'https://benj35.sg-host.com/wp-content/uploads/2021/09/Forgetreg_terms_and_conditions.pdf';

export const imageHostingFilePaths = {
  customerPaidPlanReceipts: '/customers/plans/receipts',
  customerNotes: '/customers/notes/attachments',
};

export const CUSTOMER_STATUS_DISPLAY: { [key: string]: string } = {
  [CustomerStatus.PROFILE_INCOMPLETE]: 'Profile Incomplete',
  [CustomerStatus.ON_HOLD]: 'On Hold',
  [CustomerStatus.ACTIVE]: 'Active',
  [CustomerStatus.INACTIVE]: 'Inactive',
  [CustomerStatus.SUSPENDED]: 'Suspended',
  [CustomerStatus.ATTENTION]: 'Attention',
  [CustomerStatus.NO_PLANS]: 'No Plans',
};

export const PAYMENT_STATUS_DISPLAY: { [key: string]: string } = {
  [PaymentStatus.ACTIVE]: 'Active',
  [PaymentStatus.INACTIVE]: 'Inactive',
  [PaymentStatus.ON_HOLD]: 'On Hold',
};

export const REGISTRATION_LENGTH_DISPLAY: { [key: string]: string } = {
  [RegistrationLength.THREE_MONTHS]: '3 Months',
  [RegistrationLength.SIX_MONTHS]: '6 Months',
  [RegistrationLength.TWELVE_MONTHS]: '12 Months',
  [RegistrationLength.UNKNOWN]: 'Unknown',
};

export const PLAN_STATUS_DISPLAY: { [key: string]: string } = {
  [PlanStatus.INACTIVE]: 'Pending',
  [PlanStatus.ACTIVE]: 'Active',
  [PlanStatus.COMPLETE]: 'Complete',
  [PlanStatus.CANCELLED]: 'Cancelled',
  [PlanStatus.DUE]: 'Due',
};
export const CUSTOMER_PROFILE_TABS_DISPLAY: { [key: string]: string } = {
  [CustomerProfileView.PLANS]: 'plans',
  [CustomerProfileView.ARCHIVED_PLANS]: 'archived-plans',
  [CustomerProfileView.PAYMENTS]: 'payments',
  [CustomerProfileView.PERSONAL_INFO]: 'personal-info',
  [CustomerProfileView.NOTES]: 'notes',
  [CustomerProfileView.CREDIT]: 'credit',
  [CustomerProfileView.REFERRALS]: 'Referral',
};
export const SETTINGS_TABS_DISPLAY: { [key: string]: string } = {
  [SettingsView.EDIT_PROFILE]: 'edit-profile',
  // [SettingsView.CHANGE_PASSWORD]: 'password',
  [SettingsView.ADMIN_ACCOUNTS]: 'admin-accounts',
  // [SettingsView.SYSTEM_MAINTENANCE]: 'maintenance',
};

export const PLAN_TYPE_DISPLAY: { [key: string]: string } = {
  [PlanType.VEHICLE_REGISTRATION]: 'Vehicle Registration',
  [PlanType.CHRISTMAS_FUND]: 'Christmas Fund',
  [PlanType.HOLIDAY]: 'Holiday',
  [PlanType.INSURANCE]: 'Insurance',
  [PlanType.RATE_PAYMENTS]: 'Rate Payments',
  [PlanType.SAVINGS]: 'Savings',
  [PlanType.SCHOOL_FEES]: 'School Fees',
  [PlanType.OTHER]: 'Other',
};

export const PLAN_TYPE_DISPLAY_ADMIN_TABLES: { [key: string]: string } = {
  [PlanType.VEHICLE_REGISTRATION]: 'Rego',
  [PlanType.CHRISTMAS_FUND]: 'Xmas',
  [PlanType.HOLIDAY]: 'Holiday',
  [PlanType.INSURANCE]: 'Insurance',
  [PlanType.RATE_PAYMENTS]: 'Rates',
  [PlanType.SAVINGS]: 'Savings',
  [PlanType.SCHOOL_FEES]: 'School',
  [PlanType.OTHER]: 'Other',
};

export const PLAN_TYPE_DISPLAY_SHORT: { [key: string]: string } = {
  [PlanType.VEHICLE_REGISTRATION]: 'Vehicle',
  [PlanType.CHRISTMAS_FUND]: 'Christmas',
  [PlanType.HOLIDAY]: 'Holiday',
  [PlanType.INSURANCE]: 'Insurance',
  [PlanType.RATE_PAYMENTS]: 'Rates',
  [PlanType.SAVINGS]: 'Savings',
  [PlanType.SCHOOL_FEES]: 'School',
  [PlanType.OTHER]: 'Other',
};

export const VEHICLE_TYPE_DISPLAY: { [key: string]: string } = {
  [VehicleType.CAR]: 'Car',
  [VehicleType.TRUCK]: 'Truck',
  [VehicleType.MOTORCYCLE]: 'Motorcycle',
  [VehicleType.BOAT]: 'Boat',
  [VehicleType.CARAVAN]: 'Caravan',
  [VehicleType.TRAILER]: 'Trailer',
  [VehicleType.HEAVY_VEHICLE]: 'Heavy Vehicle',
  [VehicleType.UNKNOWN]: 'Unknown',
};

export const INSTALLMENT_FREQUENCY_DISPLAY: { [key: string]: string } = {
  [InstallmentFrequency.WEEK]: 'Weekly',
  [InstallmentFrequency.FORTNIGHT]: 'Fortnightly',
  [InstallmentFrequency.MONTH]: 'Monthly',
  [InstallmentFrequency.ONE_OFF_PAYMENT]: 'One Off Payment',
};

export const CONCESSION_DISPLAY: { [key: string]: string } = {
  [Concession.NONE]: 'No',
  [Concession.HEALTH_CARE]: 'Health Care',
  [Concession.PENSIONER]: 'Pensioner',
};

export const INSTALLMENT_STATUS_DISPLAY: { [key: string]: string } = {
  [InstallmentStatus.PAID]: 'Paid',
  [InstallmentStatus.UNPAID]: 'Unpaid',
  [InstallmentStatus.TRANSFERRED]: 'Transferred',
  [InstallmentStatus.REJECTED]: 'Rejected',
  [InstallmentStatus.SKIPPED]: 'Skipped',
  [InstallmentStatus.CANCELLED]: 'Cancelled',
};

export const INSTALLMENT_FREQUENCY_ABBR: { [key: string]: string } = {
  [InstallmentFrequency.WEEK]: '/week',
  [InstallmentFrequency.FORTNIGHT]: '/fortnight',
  [InstallmentFrequency.MONTH]: '/month',
};

export const UPDATE_PAYMENT_ACTION_TYPES_DISPLAY: { [key: string]: string } = {
  [UpdatePaymentActionTypes.MARK_AS_PAID]: 'Marked as Paid',
  [UpdatePaymentActionTypes.MARK_AS_UNPAID]: 'Marked as Unpaid',
  [UpdatePaymentActionTypes.SET_PAID_DATE]: 'Marked as Paid',
};

export const SUPPORT_MESSAGE_STATUS_DISPLAY: { [key: string]: string } = {
  [SupportMessageStatus.PENDING]: 'Pending',
  [SupportMessageStatus.RESOLVED]: 'Resolved',
  [SupportMessageStatus.RESPONDED]: 'Responded',
};

export const MONTH_ABBR: { [key: string]: string } = {
  january: 'Jan',
  february: 'Feb',
  march: 'Mar',
  april: 'Apr',
  may: 'May',
  june: 'Jun',
  july: 'Jul',
  august: 'Aug',
  september: 'Sep',
  october: 'Oct',
  november: 'Nov',
  december: 'Dec',
};

// Don't render these options in table filters
const hiddenPlanStatuses: (keyof typeof PLAN_STATUS_DISPLAY)[] = [
  PlanStatus.COMPLETE,
  PlanStatus.CANCELLED,
];
// Used for filter dropdown
export const planStatusOptions = Object.keys(PLAN_STATUS_DISPLAY)
  .map((key) => (
    { value: key, label: PLAN_STATUS_DISPLAY[key] }
  ));
// Used for status change dropdown
export const hiddenPlanStatusOptions = Object.keys(PLAN_STATUS_DISPLAY)
  .filter((status) => !hiddenPlanStatuses.includes(status))
  .map((key) => (
    { value: key, label: PLAN_STATUS_DISPLAY[key] }
  ));

export const customerPaymentStatusOptions = Object.keys(PAYMENT_STATUS_DISPLAY).map((key) => (
  { value: key, label: PAYMENT_STATUS_DISPLAY[key] }
));

// Don't render these options in table filters
const hiddenCustomerStatuses: (keyof typeof CUSTOMER_STATUS_DISPLAY)[] = [
  CustomerStatus.PROFILE_INCOMPLETE,
  CustomerStatus.NO_PLANS,
];

export const customerStatusOptions = Object.keys(CUSTOMER_STATUS_DISPLAY)
  .filter((status) => !hiddenCustomerStatuses.includes(status))
  .map((key) => (
    { value: key, label: CUSTOMER_STATUS_DISPLAY[key] }
  ));

// Don't render these options in table filters
const hiddenInstallmentStatuses: (keyof typeof INSTALLMENT_STATUS_DISPLAY)[] = [
  InstallmentStatus.TRANSFERRED,
  InstallmentStatus.SKIPPED,
  InstallmentStatus.REJECTED,
  InstallmentStatus.CANCELLED,
];
// Dont render these options on plan modals
const hiddenRegistrationLengthOptions: (keyof typeof REGISTRATION_LENGTH_DISPLAY)[] = [
  RegistrationLength.UNKNOWN,
];

export const planTypeOptions = Object.keys(PLAN_TYPE_DISPLAY).map((key) => (
  { value: key, label: PLAN_TYPE_DISPLAY[key] }
));

export const installmentStatusOptions = Object.keys(INSTALLMENT_STATUS_DISPLAY).map((key) => (
  { value: key, label: INSTALLMENT_STATUS_DISPLAY[key] }
));

export const hiddeninstallmentStatusOptions = Object.keys(INSTALLMENT_STATUS_DISPLAY)
  .filter((status) => !hiddenInstallmentStatuses.includes(status))
  .map((key) => (
    { value: key, label: INSTALLMENT_STATUS_DISPLAY[key] }
  ));

export const installmentFrequencyOptions = Object.keys(INSTALLMENT_FREQUENCY_DISPLAY).map((key) => (
  { value: key, label: INSTALLMENT_FREQUENCY_DISPLAY[key] }
));

export const vehicleTypeOptions = Object.keys(VEHICLE_TYPE_DISPLAY).map((key) => (
  { value: key, label: VEHICLE_TYPE_DISPLAY[key] }
));

const hiddenVehicleTypes: (keyof typeof VEHICLE_TYPE_DISPLAY)[] = [
  VehicleType.UNKNOWN,
];
export const hiddenVehicleTypeOptions = Object.keys(VEHICLE_TYPE_DISPLAY)
  .filter((type) => !hiddenVehicleTypes.includes(type))
  .map((key) => (
    { value: key, label: VEHICLE_TYPE_DISPLAY[key] }
  ));

export const concessionOptions = Object.keys(CONCESSION_DISPLAY).map((key) => (
  { value: key, label: CONCESSION_DISPLAY[key] }
));

export const registrationLengthOptions = Object.keys(REGISTRATION_LENGTH_DISPLAY)
  .filter((status) => !hiddenRegistrationLengthOptions.includes(status))
  .map((key) => (
    { value: key, label: REGISTRATION_LENGTH_DISPLAY[key] }
  ));

export const supportMessageStatusOptions = Object.keys(SUPPORT_MESSAGE_STATUS_DISPLAY)
  .map((key) => (
    { value: key, label: SUPPORT_MESSAGE_STATUS_DISPLAY[key] }
  ));

export const stateOptions = Object.values(State).map((val) => ({
  label: val,
  value: val,
}));

export const ONBOARDING_STEPS = [
  'Create Profile',
  'Plan Type',
  'Plan Details',
  'Review Plan',
];

export const ADD_PLAN_STEPS = [
  'Plan Type',
  'Plan Details',
  'Review Plan',
];

export const FEES: { [key: string ]: number } = {
  weekly: 1.30,
  fortnightly: 2.55,
  monthly: 5.50,
};

// 2 weeks in days offset for calculation
// Ensures plan is completed 2 weeks before due date
export const CALCULATION_OFFSET = 14;

export const TABLET = 768;

export const DEFAULT_FILTER = {
  rowsPerPage: 20,
  page: 1,
};

export const CONTACT_NUMBER = '1800 367 438';

export const NUMBER_REGEX = /^[0-9.]*$/;

export const MIN_YEAR_FILTER = '2010';
