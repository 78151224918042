import styled from 'styled-components/macro';

import { OptionsWrapperProps, OptionProps } from './types';

export const ToggleWrapper = styled.div`
  margin: 0.75rem 0rem;
  cursor: pointer;
  width: 100%;
`;

export const OptionsWrapper = styled.div<OptionsWrapperProps>`
  margin: ${({ theme }) => theme.spacing.XXS / 2}rem 0 0 0;
  border-radius: 15px;
  border: 1px solid ${({ error, disabled, theme }) => {
    if (disabled) return theme.palette.btnGrey;
    if (error) return theme.palette.btnError;
    return theme.basePalette.greyMain;
  }};
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

export const Option = styled.div<OptionProps>`
  border-radius: 14px;
  color: ${({ selected, theme }) => (selected ? theme.basePalette.white : `${theme.basePalette.greyDark}70`)};
  background: ${({ selected, theme }) => (selected ? theme.basePalette.blueMain : 'transparent')};
  border: ${({ selected, theme }) => selected && `1px solid ${theme.basePalette.blueMain}`};
  flex: 1;
  padding: ${({ theme }) => theme.spacing.XXS}rem;
  line-height: 1.5;

  z-index: ${({ selected }) => selected && 1};
  text-align: center;
  white-space: nowrap;

  &:hover {
    background: ${({ theme, selected }) => !selected && theme.basePalette.greyMain};
  }
`;

export const Divider = styled.div`
  align-self: center;
  background: ${({ theme }) => theme.basePalette.greyMain};
  height: 30px;
  width: 1px;
`;

export const MetaWrapper = styled.div`
  display: flex;
`;

export const Label = styled.p`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  margin-bottom: 5px;
  margin-right: auto;
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.palette.btnError};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-style: italic;
  margin-bottom: 5px;
  margin-left: auto;
  margin-top: 5px;
`;
