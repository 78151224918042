import { VehicleMake } from '../../../types/DBModels';

import {
  SetVehicleMakesAction,
  SET_VEHICLE_MAKES,
} from './types';

export const setVehicleMakes = (
  vehicleMakes: VehicleMake[],
): SetVehicleMakesAction => ({
  type: SET_VEHICLE_MAKES,
  vehicleMakes,
});
