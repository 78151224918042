import React from 'react';
import dayjs from 'dayjs';

import useStrings from '../../../../../hooks/useStrings';

import { NotesAttachActive, NotesAttachInactive } from '../../../../../assets/Icons';
import { AttachmentIcon, NoteTabWrapper, NoteTabDate, NoteTabSub, NoteTabTitle } from './Styles';

import { NoteTabProps } from './types';

const NoteTab: React.FC<NoteTabProps> = (
  { active, note, onClick },
) => {
  const [{ Pages: { UserProfile: { Notes: NotesStrings } } }] = useStrings();
  return (
    <NoteTabWrapper
      active={active}
      onClick={onClick}
    >
      <NoteTabTitle active={active}>{note.description ? `${note.description.substring(0, 20)}...` : NotesStrings.newNoteText}</NoteTabTitle>
      <NoteTabSub>
        <NoteTabDate active={active}>{dayjs(note.createdAt).format('DD/MM/YY')}</NoteTabDate>
        {note.noteFiles.length > 0
        && (
        <AttachmentIcon
          src={active ? NotesAttachActive : NotesAttachInactive}
        />
        )}
      </NoteTabSub>
    </NoteTabWrapper>
  );
};
export default NoteTab;