import React, { useState } from 'react';

import Tooltip from '../Tooltip';

import { closeWhite, DocPreviewIcon } from '../../../assets/Icons';
import {
  FileTabWrapper,
  FileTabText,
  CloseIcon,
  Icon,
} from '../../../pages/Admin/CustomerProfile/Notes/FileTab/Styles';

import { ReceiptThumbProps } from './types';

const ReceiptThumb: React.FC<ReceiptThumbProps> = ({
  file,
  handleRemoveReceipt,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FileTabWrapper onClick={onClick}>
      <Tooltip
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        onConfirm={() => {
          handleRemoveReceipt();
          setIsOpen(false);
        }}
      />
      <CloseIcon
        src={closeWhite}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      />
      <Icon src={DocPreviewIcon} />
      <FileTabText>{file.fileName}</FileTabText>
    </FileTabWrapper>
  );
};
export default ReceiptThumb;