import { User } from '../../../../types/DBModels';

export const SET_SELECTED_ADMIN = 'SET_SELECTED_ADMIN';

export interface SelectedAdminState {
  selectedAdminUser: User | null;
}

export interface SetSelectedAdminAction {
  type: typeof SET_SELECTED_ADMIN;
  adminUser: User | null;
}

export type SelectedAdminActionTypes = SetSelectedAdminAction;
