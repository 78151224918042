import React from 'react';

import { Value } from './Styles';

import { DashboardStatsProps } from './types';
import { formatLargeNumber } from '../../../../lib/utils';

const DashboardStats: React.FC<DashboardStatsProps> = ({ value, isCurrency = false }) => {
  const formattedValue = formatLargeNumber(value);
  const displayValue = isCurrency ? `$${formattedValue}` : formattedValue;

  return (
    <Value>
      {displayValue}
    </Value>
  );
};

export default DashboardStats;
