import styled from 'styled-components/macro';

import { DownChevronIcon } from '../../../assets/Icons';
import appTheme from '../../../style/themes/appTheme';
import { Palette } from '../../../lib/types/Style';

import { IconWrapperProps, SelectProps } from './types';

export const TableWrapper = styled.div`
  border-radius: 15px;
  padding: 1rem 1.5rem;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  border-radius: 15px;
  min-height: 60px;
  min-width: 60px;
  background-color: ${({ theme, active = false }) => (active ? theme.palette.primary : 'rgb(248 248 248)')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 0.5rem;
  margin: ${({ marginRight = false }) => (marginRight
    ? '1rem 0.5rem 2rem 0' : '1rem 0 2rem 0.5rem')};
  transition: 0.4s background-color ease-in-out;
  cursor: pointer;
  user-select: none;

  /* Open Styled Input on Hover */
  &:hover > input {
    width: 260px;
    margin: 0 0.5rem 0 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.secondary};
  }

  &:hover > button {
    display: block;
    border: 1px solid ${({ theme }) => theme.basePalette.greyMain};
    border-radius: 10px;
    height: 42px
  }

`;

export const StyledInput = styled.input`
  background-color: transparent;
  border: none;
  outline: none;
  width: 0;
  padding: 0;
  margin: 0;
  transition: 0.4s all ease-in-out;
  font-family: ${({ theme }) => theme.fontFamily.body};
  
  &:focus, &:active {
    width: 260px;
    margin: 0 0.5rem 0 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.secondary};
  }
`;

export const StyledFilterIcon = styled.img<IconWrapperProps>`
  transition: 0.2s filter ease-in-out;
  ${({ active = false }) => (active ? 'filter: invert(1)' : '')};
`;

export const FilterMenuWrapper = styled.div`
  position: absolute;
  width: 440px;
  border: 1px solid rgb(218 218 218);
  padding: ${({ theme }) => `${theme.spacing.XXS}rem`};
  background-color: white;
  z-index: 81;
  border-radius: 15px;
  top: ${({ theme }) => `calc(50px + ${theme.spacing.XXS}rem)`};
  right: 0;
`;

export const TableJssOverrides = {
  table: {
    style: {
      minHeight: '420px',
      overflow: 'auto',
    },
  },
  headCells: {
    style: {
      fontSize: `${appTheme.fontScale.S as number * 1.05}rem`,
      fontWeight: appTheme.fontWeight.light,
      color: 'rgba(0, 0, 0, 0.54)',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
    activeSortStyle: {
      color: 'rgba(0, 0, 0, 0.54)',
      '&:focus': {
        outline: 'none',
      },
      '&:hover:not(:focus)': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  rows: {
    style: {
      fontFamily: appTheme.fontFamily.bodyLight,
      fontSize: `${appTheme.fontScale.S as number * 1.05}rem`,
      minHeight: '48px',
      padding: '12px 0px',
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
      },
    },
  },
  subHeader: {
    style: {
      padding: '0px',
      justifyContent: 'space-between',
    },
  },
  pagination: {
    style: {
      padding: '2rem 8px 1rem 8px',
      justifyContent: 'center',
    },
  },
};

export const getBorderColour = (statusType: string, status: string, palette: Palette): string => {
  if (statusType === 'PLAN') {
    switch (status) {
      case 'ACTIVE':
        return palette.borderGreen;
      case 'INACTIVE':
        return palette.borderYellow;
      case 'CANCELLED':
        return palette.borderRed;
      case 'DUE':
        return palette.borderOrange;
      default:
        return palette.borderGreen;
    }
  }
  if (statusType === 'INSTALLMENT') {
    switch (status) {
      case 'PAID':
        return palette.borderGreen;
      case 'UNPAID':
        return palette.borderRed;
      case 'TRANSFERRED':
        return palette.borderYellow;
      case 'CANCELLED':
        return palette.borderYellow;
      case 'REJECTED':
        return palette.borderOrange;
      case 'SKIPPED':
        return palette.borderOrange;
      default:
        return palette.borderGreen;
    }
  }
  if (statusType === 'CUSTOMER' || statusType === 'PAYMENT') {
    switch (status) {
      case 'ACTIVE':
        return palette.borderGreen;
      case 'INACTIVE':
        return palette.borderRed;
      case 'NO_PLANS':
        return palette.borderRed;
      case 'ON_HOLD':
        return palette.borderYellow;
      case 'ATTENTION':
        return palette.borderYellow;
      case 'SUSPENDED':
        return palette.borderOrange;
      default:
        return palette.borderGreen;
    }
  }
  return palette.borderGreen;
};

export const Select = styled.select<SelectProps>`
  background: right 0.7rem top 55% ${({ disabled }) => !disabled && `url('${DownChevronIcon}')`}  no-repeat;
  appearance: none;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  border-radius: 25px;
  padding: 0.5rem 1rem;
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  border: 1px solid ${({ theme, statusType, status }) => getBorderColour(statusType, status, theme.palette)};
  min-width: 120px;
  font-family: ${({ theme }) => theme.fontFamily.body};

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.basePalette.white};
  }

  &:focus {
    outline: 0;
  }
`;

export const NoRecordsText = styled.div`
  margin-top: ${({ theme }) => theme.spacing.XS}rem;
`;

export default TableWrapper;
