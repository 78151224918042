import React, { useState } from 'react';
import { useField } from 'formik';

import useStrings from '../../../hooks/useStrings';

import {
  StyledInput,
  Label,
  OptionalLabel,
  OuterWrapper,
  MetaWrapper,
  InnerWrapper,
  Error,
  DollarIcon,
  PasswordToggle,
} from './Styles';

import { InputProps } from './types';
import { PasswordHide, PasswordShow } from '../../../assets/Icons';

const Input: React.FC<InputProps> = ({ label, type, optional = false, ...props }) => {
  const [{ GenericText: Strings }] = useStrings();

  // Component Must be used inside of <Formik> tags to access Formik context
  const [field, meta] = useField(props);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { touched, error } = meta;

  // To handle toggle show password logic
  const inputType = type === 'password' && isPasswordVisible
    ? 'text'
    : type;

  return (
    <OuterWrapper>
      <MetaWrapper>
        {label && (<Label>{label}</Label>)}
        {optional && (<OptionalLabel>{Strings.optional}</OptionalLabel>)}
      </MetaWrapper>
      <InnerWrapper>
        <StyledInput
          isCurrency={type === 'currency'}
          className="text-input"
          {...field}
          {...props}
          type={inputType === 'currency' ? 'number' : inputType}
          error={!!error && touched}
        />
        {type === 'currency' && (
          <DollarIcon>&nbsp;$</DollarIcon>
        )}
        {type === 'password' && (
          <PasswordToggle
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          >
            <img
              alt="Toggle Password"
              src={isPasswordVisible ? PasswordShow : PasswordHide}
            />
          </PasswordToggle>
        )}
      </InnerWrapper>
      {touched && error && <Error className="error">{error}</Error>}
    </OuterWrapper>
  );
};

export default Input;
