import styled from 'styled-components/macro';

export const FormWrapper = styled.form`
  width: 100%;
  margin: ${({ theme }) => `${theme.spacing.M}rem 0`};

  ${({ theme }) => theme.device.tablet} {
    max-width: 90%;
  }

  ${({ theme }) => theme.device.desktop} {
    max-width: 75%;
  }
`;

export const FormLink = styled.a`
  color: ${({ theme }) => theme.basePalette.blueMain};
  text-decoration: none !important;
  cursor: pointer;
  display: inline;
`;

export const SubText = styled.h3`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export const ButtonWrapper = styled.div`
  margin-top: 45px;
  width: 100%;
`;

export const BodyText = styled.p`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  display: inline;
`;

export const CheckboxWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.basePalette.redMain};
  display: block;
  font-size: 16px;
  margin-bottom: ${({ theme }) => theme.spacing.XS}rem;
`;
