import styled from 'styled-components/macro';

export const StyledReadOnlyField = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  max-height: 94px;
  min-width: 195px;
  border: 1px solid ${({ theme }) => theme.palette.btnDarkGrey};
  background-color: ${({ theme }) => theme.palette.btnDarkGrey};
  border-radius: 24px;
`;

export const TransferHeading = styled.p`
  display: inline;
  color: ${({ theme }) => theme.basePalette.greyDark};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.extraLight};
  margin-bottom: 5px;
`;

export const TransferAmountText = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  background-color: ${({ theme }) => theme.palette.btnDarkGrey};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const TransferAmountText1 = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  background-color: ${({ theme }) => theme.palette.btnDarkGrey};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const TransferSubText = styled.p`
  color: ${({ theme }) => theme.basePalette.greyDark};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.extraLight};
  margin-top: 5px;
`;