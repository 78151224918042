import { Customer } from '../../../../types/DBModels';
import { CustomerStatus } from '../../../../types/Customer';

import {
  SetProfileDataAction,
  SET_PROFILE_DATA,
  UpdateCustomerStatusAction,
  UPDATE_CUSTOMER_STATUS,
} from './types';

export const setProfileData = (
  customer: Customer,
): SetProfileDataAction => ({
  type: SET_PROFILE_DATA,
  profileData: customer,
});

export const updateCustomerStatus = (
  status: CustomerStatus,
): UpdateCustomerStatusAction => ({
  type: UPDATE_CUSTOMER_STATUS,
  status,
});
