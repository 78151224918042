import { Spacing } from '../../lib/types/Style';

// TODO: Update theme values
const spacing: Spacing = {
  NONE: 0,
  XXS: 1,
  XS: 2,
  S: 3,
  M: 4.5,
  L: 8,
  XL: 13,
  XXL: 21,
};

export default spacing;
