import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { formatLargeNumber } from '../../../../lib/utils';
import { CUSTOMER_STATUS_DISPLAY } from '../../../../CONSTANTS';

import StatsTooltip from '../StatsTooltip';

import {
  Container,
  StatesContainer,
  StateItem,
  Dot,
  Status,
  Count,
  TooltipText,
  TooltipSubtext,
} from '../CustomerLocations/Styles';

import { CustomerStatusesProps } from './types';

const STATUS_COLORS: { [key: string]: string } = {
  PROFILE_INCOMPLETE: '#5073B8',
  NO_PLANS: '#0AB39C',
  ON_HOLD: '#F1963A',
  ACTIVE: '#F16548',
  INACTIVE: '#C749F1',
  SUSPENDED: '#48D0F1',
  ATTENTION: '#F1B948',
};

const CustomerStatuses: React.FC<CustomerStatusesProps> = ({ data }) => {
  const chartData = data.map(({ status, value }) => ({ name: status, value }));

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <StatsTooltip>
          <TooltipText>{`${formatLargeNumber(payload[0].value as number)}`}</TooltipText>
          <TooltipSubtext>{CUSTOMER_STATUS_DISPLAY[payload[0].payload.name]}</TooltipSubtext>
        </StatsTooltip>
      );
    }
    return null;
  };

  return (
    <Container>
      <StatesContainer>
        {chartData.map(({ name, value }) => (
          <StateItem key={name}>
            <Dot color={STATUS_COLORS[name]} />
            <Status>{CUSTOMER_STATUS_DISPLAY[name]}</Status>
            <Count>{value}</Count>
          </StateItem>
        ))}
      </StatesContainer>
      <ResponsiveContainer width="60%" height="100%">
        <PieChart
          margin={{
            top: 10,
            bottom: 10,
            left: 30,
          }}
        >
          <Tooltip content={<CustomTooltip />} />
          <Pie
            isAnimationActive={false}
            data={chartData}
            labelLine={false}
            outerRadius="100%"
            dataKey="value"
          >
            {chartData.map(({ name }, index) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <Cell key={`cell-${index}`} fill={STATUS_COLORS[name]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default CustomerStatuses;
