import * as yup from 'yup';

import {
  Concession,
  InstallmentFrequency,
  PlanType,
  RegistrationLength,
} from '../types/Plan';
import { State } from '../types/Customer';
import { VehicleType } from '../types/Vehicle';

const adminRegoPlanSchema = yup.object({
  type: yup
    .string()
    .oneOf(Object.keys(PlanType))
    .required('Plan type is required'),
  vehicle: yup.object({
    type: yup.string().typeError('Please select vehicle type').oneOf(Object.keys(VehicleType)).required('Vehicle type is required'),
    make: yup.string().when('type', {
      is: (val: string) => (val !== VehicleType.CAR && val !== VehicleType.MOTORCYCLE),
      then: yup.string().required('Make is required').typeError('Make is required'),
    }),
    vehicleMakeId: yup.number().when('type', {
      is: (val: string) => (val === VehicleType.CAR || val === VehicleType.MOTORCYCLE),
      then: yup.number().required('Make is required').typeError('Make is required'),
    }),
    model: yup.string().required('Model is required'),
    registration: yup.string().required('Registration is required'),
    color: yup.string().required('Color is required'),
    year: yup.string().required('Year is required'),
    registeredState: yup.string().typeError('Please select registered state').oneOf(Object.keys(State)).required('Registered state is required'),
  }),
  registrationLength: yup.string().when('type', {
    is: (val: PlanType) => val === PlanType.VEHICLE_REGISTRATION,
    then: yup.string().typeError('Please select registration length').oneOf(Object.keys(RegistrationLength)).required('Registered length is required'),
  }),
  startDate: yup
    .date()
    .required('Start date is required'),
  dueDate: yup
    .date()
    .required('Due date is required'),
  endDate: yup
    .date().min(
      yup.ref('startDate'),
      "End date can't be before start date",
    ).max(
      yup.ref('dueDate'),
      "End date can't be after due date",
    ),
  value: yup
    .number()
    .typeError('Please enter valid amount')
    .required('Amount is required'),
  receivedValue: yup
    .number(),
  installmentFrequency: yup
    .string()
    .typeError('Please select installment frequency')
    .oneOf(Object.keys(InstallmentFrequency))
    .required('Installment frequency is required'),
  adminFee: yup
    .number()
    .typeError('Please enter valid admin fee')
    .required('Admin fee is required'),
  concessionCard: yup
    .string()
    .trim()
    .oneOf(Object.values(Concession))
    .required('Concession Card is required'),
}).required();

export type AdminRegoPlanSchema = yup.InferType<typeof adminRegoPlanSchema>;

export default adminRegoPlanSchema;
