import styled from 'styled-components';

export const EmailViewWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const LabelWrapper = styled.div`
  display: flex;
  margin: 0 0.5rem;
  min-width: 40%;
  line-height: 2rem;
`;

export const InfoLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-size: 0.8125rem;
  color: ${({ theme }) => theme.basePalette.navyMain};
`;

export const Info = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-size: 0.8125rem;
  color: ${({ theme }) => theme.basePalette.navyMain};
  margin-left: 0.5rem;
`;

export const CustomerName = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.basePalette.navyMain};
  margin-left: 0.5rem;
`;

export const TimeAgo = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  color: ${({ theme }) => theme.basePalette.greyDark};
  margin-left: 1rem;
`;

export const CustomerInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmailTitle = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-size: 1.5rem;
  color: ${({ theme }) => theme.basePalette.navyMain};
  line-height: 32px;
`;
export const EmailContent = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.basePalette.greyDark};
  line-height: 24px;
  margin-bottom: 1rem;
`;

export const ReplyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ReplyHeading = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.basePalette.greyDark};
  margin-bottom: 1rem;
  letter-spacing: 0.1rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 66%;

  @media(max-width: 1300px) {
    width: 100%;
  }

`;