import React from 'react';

import { StyledButton } from './Styles';

import { TableFilterSelectButtonProps } from '../types';

const TableFilterSelectButton: React.FC<TableFilterSelectButtonProps> = ({
  children,
  selected,
  handleClick,
}) => (
  <StyledButton type="button" selected={selected} onClick={() => handleClick()}>
    {children}
  </StyledButton>
);

export default TableFilterSelectButton;
