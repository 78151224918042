import styled, { DefaultTheme } from 'styled-components/macro';

import { HeadingProps, SubtextProps } from './types';

export const NextPayment = styled.div`
  background: ${({ theme }) => theme.basePalette.white};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.XXS}rem;
  padding-left: ${({ theme }) => theme.spacing.XXS}rem;
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    align-items: center;
    align-self: flex-end;
    flex-direction: row;
    margin: 0;
    padding-right: ${({ theme }) => theme.spacing.XXS * 1.5}rem;
    width: auto;
  }
`;

export const NextPaymentDate = styled.div`
  align-items: center;
  align-self: stretch;
  background: ${({ theme }) => theme.basePalette.navyMain};
  border-radius: 15px;
  padding: ${({ theme }) => `${theme.spacing.XXS / 2}rem ${theme.spacing.XXS}rem`};
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
  display: flex;
  margin-left: ${({ theme }) => -theme.spacing.XXS}rem;
  margin-right: ${({ theme }) => -theme.spacing.XXS}rem;
  width: calc(100% + 1rem);

  ${({ theme }) => theme.device.tablet} {
    height: 100%;
    margin-bottom: 0;
    margin-right: ${({ theme }) => theme.spacing.XXS * 1.5}rem;
    padding: ${({ theme }) => `${theme.spacing.XXS / 2}rem ${theme.spacing.XS / 1.5}rem`};
  }
`;

export const DirectDebitBanner = styled.div`
  align-items: stretch;
  background: ${({ theme }) => theme.basePalette.redDark};
  border-radius: 15px;
  padding: ${({ theme }) => theme.spacing.XXS}rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  ${({ theme }) => theme.device.tablet} {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${({ theme }) => theme.spacing.XS / 1.3}rem ${({ theme }) => theme.spacing.XS / 1.5}rem;
  }
`;

export const DirectDebitContent = styled.div`
  align-items: flex-start;
  display: flex;
  margin: ${({ theme }) => theme.spacing.XXS}rem 0;

  ${({ theme }) => theme.device.tablet} {
    margin: 0 ${({ theme }) => theme.spacing.XS}rem 0 0;
    align-items: center;
  }
`;

export const DirectDebitTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DirectDebitButtonWrap = styled.div`
  position: relative;
  z-index: 1;
`;

export const BackgroundTexture = styled.img`
  position: absolute;
  top: -270px;
  right: -451px;
  z-index: 0;
`;

const generateHeadingColor = (theme: DefaultTheme, highlight?: boolean, light?: boolean) => {
  if (highlight && light) return theme.basePalette.redLight;
  if (highlight) return theme.basePalette.blueMain;
  if (light) return theme.basePalette.white;
  return theme.basePalette.navyMain;
};

const generateHeadingSize = (theme: DefaultTheme, small?: boolean, large?: boolean) => {
  if (small) return theme.fontScale.XL as number / 1.3;
  if (large) return theme.fontScale.XXL;
  return theme.fontScale.XXL as number / 1.1;
};

export const Heading = styled.h2<HeadingProps>`
  color: ${({ highlight, light, theme }) => generateHeadingColor(theme, highlight, light)};
  font-size: ${({ theme, small, large }) => generateHeadingSize(theme, small, large)}rem;
  display: inline;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export const Subtext = styled.p<SubtextProps>`
  color: ${({ theme, light }) => (light ? theme.basePalette.white : theme.basePalette.navyMain)};
  font-family: ${({ theme, bold }) => (bold ? theme.fontFamily.bodyBold : theme.fontFamily.bodyLight)};
  display: inline;
`;

export const DollarIcon = styled.img`
  margin-right: ${({ theme }) => theme.spacing.XXS}rem;
`;
