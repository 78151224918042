import styled from 'styled-components/macro';

export const StyledBackButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
  border: 1px solid #00000010;
  border-radius: 16px;
  height: 48px;
  width: 48px;
  transition: all .1s ease;

  &:hover {
    transform: scale(1.05);
    border-color: ${({ theme }) => theme.basePalette.blueMain};
  }
`;

export const Icon = styled.img`
  height: 16px;
  width: 16px;
`;
