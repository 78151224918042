import { Credit } from '../../../../../types/DBModels';
import {
  CreditHistoryActionTypes,
  SET_CUSTOMER_CREDIT_HISTORY,
} from './types';

export const setCreditHistoryData = (
  credits: Credit[],
): CreditHistoryActionTypes => ({
  type: SET_CUSTOMER_CREDIT_HISTORY,
  credits,
});
