import { PlanDataView } from '../../../../../types/DBViews';

export const SET_ALL_PAYABLE_PLAN_DATA = 'SET_ALL_PAYABLE_PLAN_DATA';

export interface PayablePlanState {
  payablePlans: PlanDataView[];
}
export interface SetAllPayablePlansAction {
  type: typeof SET_ALL_PAYABLE_PLAN_DATA;
  payablePlans: PlanDataView[];
}

export type PayablePlanActionTypes =
SetAllPayablePlansAction;