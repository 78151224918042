import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';

import AuthRoute from '../AuthRoute';
import CreateProfile from '../../pages/Customer/CreateProfile';
import CreatePlan from '../../pages/Customer/CreatePlan';
import Login from '../../pages/Login';

import { OnboardingRouterProps } from './types';
import { Roles } from '../../lib/types/Auth';
import { ApplicationState } from '../../lib/store';
import { CustomerStatus } from '../../lib/types/Customer';

const OnboardingRouter: React.FC<OnboardingRouterProps> = ({
  match,
  role,
}) => {
  const loggedIn = role !== Roles.DEFAULT;
  const { profileState: { profileData } } = useSelector((state: ApplicationState) => state.customer);

  return (
    <Switch>
      <AuthRoute role={role} requiresOneOfRole={[Roles.CUSTOMER]} path={`${match.path}/profile`}>
        <CreateProfile profileData={profileData} isOnboarding isCreateProfile />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.CUSTOMER]} path={`${match.path}/create-plan`}>
        <CreatePlan isOnboarding />
        {profileData?.status === CustomerStatus.PROFILE_INCOMPLETE && (
          <Redirect to="/sign-up/profile" />
        )}
      </AuthRoute>
      <Route path={match.path}>
        {loggedIn && <Redirect to="/sign-up/profile" />}
        <Login variant="customer-signup" />
      </Route>
    </Switch>
  );
};

export default OnboardingRouter;
