import { Credit, Transfers } from '../../../../types/DBModels';
import {
  SetSelectedPlanTransfersAction,
  SET_SELECTED_PLAN_TRANSFERS,
} from './types';

export const setSelectedPlanTransfers = (planTransfers: (Credit | Transfers)[]): SetSelectedPlanTransfersAction => ({
  type: SET_SELECTED_PLAN_TRANSFERS,
  planTransfers,
});
