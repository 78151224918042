import {
  CLEAR_SELECTED_PLAN,
  SelectedPlanActionTypes, SelectedPlanState, SET_SELECTED_PLAN,
} from './types';

const initialState: SelectedPlanState = {
  planData: null,
};

const SelectedPlanReducer = (
  state: SelectedPlanState = initialState,
  action: SelectedPlanActionTypes,
): SelectedPlanState => {
  switch (action.type) {
    case SET_SELECTED_PLAN:
      return {
        ...state,
        planData: action.planData,
      };
    case CLEAR_SELECTED_PLAN:
      return {
        ...state,
        planData: null,
      };
    default:
      return state;
  }
};

export default SelectedPlanReducer;
