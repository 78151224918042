import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import useStrings from '../../../hooks/useStrings';
import {
  defaultFilter,
  getPayments,
  updatePayments,
  getPaymentFilterLimits,
} from '../../../lib/api/admin/payments';

import PageLayout from '../../../layouts/PageLayout';
import PaymentsTable from './PaymentsTable';

import { PaymentsProps } from './types';
import { TableRow } from '../../../components/Common/Table/types';
import {
  APIThunkDispatch,
  UpdatePaymentActionTypes,
  PaymentFilterOptions,
} from '../../../lib/types/API';
import { ApplicationState } from '../../../lib/store';
import { PaymentFilterLimits } from '../../../lib/types/Plan';
import { CUSTOMER_PROFILE_TABS_DISPLAY } from '../../../CONSTANTS';
import { resetSelectedCustomer } from '../../../lib/store/contexts/admin/customers/actions';
import { clearCustomerNotesData } from '../../../lib/store/contexts/admin/customerNotes/actions';
import { clearSelectedCustomerPlans } from '../../../lib/store/contexts/admin/selectedCustomer/plans/actions';
import { clearSelectedCustomerPayments } from '../../../lib/store/contexts/admin/selectedCustomer/payments/actions';
import { clearSelectedCustomerArchivedPlans } from '../../../lib/store/contexts/admin/selectedCustomer/archivedPlans/actions';

const Payments: React.FC<PaymentsProps> = () => {
  const [{ Pages: { Payments: Strings } }] = useStrings();
  const Dispatch: APIThunkDispatch = useDispatch();
  const history = useHistory();

  const {
    paymentState: { paymentData },
    customerState: { selectedCustomer },
  } = useSelector((state: ApplicationState) => state.admin);

  const viewUser = (row: TableRow) => {
    if (selectedCustomer?.user.userId === row.userId) {
      history.push(`/admin/user/${row.userId}/${CUSTOMER_PROFILE_TABS_DISPLAY.PAYMENTS}`);
    } else {
      Dispatch(resetSelectedCustomer());
      Dispatch(clearCustomerNotesData());
      Dispatch(clearSelectedCustomerPlans());
      Dispatch(clearSelectedCustomerPayments());
      Dispatch(clearSelectedCustomerArchivedPlans());
      history.push(`/admin/user/${row.userId}/${CUSTOMER_PROFILE_TABS_DISPLAY.PAYMENTS}`);
    }
  };

  // Filter management
  const [filter, setFilter] = useState<PaymentFilterOptions>(defaultFilter);
  const clearFilters = () => setFilter(defaultFilter);
  const updateFilter = (newFilter: PaymentFilterOptions) => {
    setFilter({
      ...defaultFilter,
      ...newFilter,
    });
  };
  const [filterLimits, setFilterLimits] = useState<PaymentFilterLimits>();

  const onUpdatePayments = (
    installmentIds: number[],
    actionType: UpdatePaymentActionTypes,
    paidDate?: Date,
    dueDate?: Date,
  ) => {
    Dispatch<void>(updatePayments({ installmentIds, actionType, paidDate, dueDate }));
  };

  const fetchPaymentFilterLimits = useCallback(async () => {
    const { data } = await Dispatch(getPaymentFilterLimits({}));
    if (data) setFilterLimits(data);
  }, [Dispatch]);

  // Fetch data
  const fetchPayments = useCallback(() => {
    Dispatch<void>(getPayments(filter));
  }, [Dispatch, filter]);

  useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);

  useEffect(() => {
    fetchPaymentFilterLimits().catch(() => null);
  }, [fetchPaymentFilterLimits]);

  const currentData = paymentData.payments.map((payment) => ({ id: payment.installmentId, ...payment }));

  return (
    <PageLayout
      pageTitle={`💰 ${Strings.pageTitle}`}
      maxWidth="100%"
    >
      <PaymentsTable
        currentData={currentData}
        recordCount={paymentData.count}
        filter={filter}
        updateFilter={updateFilter}
        onRowClicked={viewUser}
        clearFilters={clearFilters}
        handleUpdatePayments={onUpdatePayments}
        filterLimits={filterLimits}
      />
    </PageLayout>
  );
};

export default Payments;
