import styled from 'styled-components/macro';

export const ModalBackground = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const ModalWrapper = styled.div`
  background-color: ${({ theme }) => theme.basePalette.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: default;
  border-radius: 24px;
  padding: ${({ theme }) => `${theme.spacing.XS}rem`};
`;

export const ModalTitle = styled.h4`
  margin: 
  ${({ theme }) => theme.spacing.XXS}rem
  0
  ${({ theme }) => theme.spacing.XXS * 0.5}rem
  0;

  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-size: ${({ theme }) => theme.fontScale.M as number * 1.2}rem;
`;

export const ModalSubtext = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  text-align: center;
  color: ${({ theme }) => theme.basePalette.greyDark};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  width: 65%;
  line-height: 20px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.XS * 0.5}rem;
  margin-top: ${({ theme }) => theme.spacing.XXS * 0.8}rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.XS}rem;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background: ${({ theme }) => theme.basePalette.greyMain};
  border-radius: 40px;
  margin: ${({ theme }) => theme.spacing.XXS * 0.8}rem auto auto;
`;

export const Icon = styled.img`
  width: 36px;
  height: 36px;
`;