import styled from 'styled-components/macro';

export const DataTableWrapper = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const ViewReceipt = styled.div`
  border: solid 1px #1AC117;
  border-radius: 16px;
  background-color: rgba(26, 193, 23, .05);
  &:hover {
    cursor: pointer;
  }
`;

export const ReceiptText = styled.p`
  color: #1AC117;
  margin: 4px 20px;
  white-space: nowrap;
`;

export const ReceiptLink = styled.a`
  text-decoration: none;
`;