import * as yup from 'yup';

import { isPhoneNumberValid } from '../utils';

const updateAdminProfileSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .required('First name is required'),
  lastName: yup
    .string()
    .trim()
    .required('Last name is required'),
  email: yup
    .string()
    .trim()
    .email('Invalid email address')
    .required('Email is required'),
  phone: yup
    .string()
    .trim()
    .test({ name: 'is-valid-phone', test: (value) => isPhoneNumberValid(value), message: 'Phone number is not valid' })
    .required('Phone number is required'),
}).required();

export type UpdateAdminProfileSchema = yup.InferType<typeof updateAdminProfileSchema>;
export default updateAdminProfileSchema;
