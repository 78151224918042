export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
}

export enum GenderHidden {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum State {
  VIC = 'VIC',
  NSW = 'NSW',
  ACT = 'ACT',
  QLD = 'QLD',
  NT = 'NT',
  WA = 'WA',
  SA = 'SA',
  TAS = 'TAS',
}

export enum CustomerStatus {
  PROFILE_INCOMPLETE = 'PROFILE_INCOMPLETE',
  NO_PLANS = 'NO_PLANS',
  ON_HOLD = 'ON_HOLD',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SUSPENDED = 'SUSPENDED',
  ATTENTION = 'ATTENTION',
}

export enum PaymentStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ON_HOLD = 'ON_HOLD',
}