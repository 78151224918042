import styled from 'styled-components/macro';

import { IconProps, StyledDatePickerProps } from './types';

export const StyledDatePicker = styled.div<StyledDatePickerProps>`
  position: relative;
  width: 100%;
  margin: ${({ margin, inTable }) => {
    if (inTable) return '0';
    return margin ?? '0.75rem 0';
  }};
  
  // Style overrides
  // If updating, ensure changes are reflected in DatePickerCalendar component too
  .react-date-picker {
    display: ${({ inTable }) => !inTable && 'block'};
    cursor: ${({ disabled }) => !disabled && 'pointer'};
    color: ${({ theme }) => theme.palette.grey20};

    input {
      &::-webkit-input-placeholder { /* Edge */
        font-family: ${({ theme }) => theme.fontFamily.bodyLight};
        color: ${({ theme }) => theme.basePalette.greyDark};
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-family: ${({ theme }) => theme.fontFamily.bodyLight};
        color: ${({ theme }) => theme.basePalette.greyDark};
      }

      &::placeholder {
        font-family: ${({ theme }) => theme.fontFamily.bodyLight};
        color: ${({ theme }) => theme.basePalette.greyDark};
      }
    }

    &--disabled {
      background: transparent;
    }
  }

  .react-date-picker__wrapper {
    width: ${({ inTable }) => !inTable && '100%'};
    height: ${({ inTable }) => !inTable && '60px'};
    margin: ${({ theme, inTable }) => !inTable && `${(theme.spacing.XXS / 2)}rem 0 0 0`};
    border-radius: ${({ outlined = false }) => (outlined ? '15px' : '8px')};
    border: 1px solid ${({ error, disabled, theme, outlined = false }) => {
    if (disabled) return theme.palette.btnGrey;
    if (error) return theme.palette.btnError;
    if (outlined) return theme.basePalette.greyMain;
    return theme.palette.btnMidGrey;
  }};

  background-color: ${({ disabled, outlined, theme }) => {
    if (disabled) return theme.palette.btnGrey;
    if (outlined) return theme.palette.bgPrimary;
    return theme.palette.btnMidGrey;
  }};

    &:hover {
      border: 1px solid ${({ error, disabled, theme }) => {
    if (disabled) return theme.palette.btnGrey;
    if (error) return theme.palette.btnError;
    return theme.basePalette.blueMain;
  }};
  }
  }

  .react-calendar {
    border: 1px solid ${({ theme }) => theme.basePalette.greyMain};
    border-radius: ${({ outlined = false }) => (outlined ? '15px' : '8px')};
    overflow: hidden;
  }

  .react-calendar__navigation {
    margin-bottom: 0;
  }

  .react-date-picker__calendar {
    z-index: 10;
  }

  .react-date-picker__inputGroup {
    padding-left: ${({ inTable }) => !inTable && '1rem'};
    color: ${({ theme, dateSelected }) => dateSelected && theme.basePalette.navyMain};
  }

  .react-date-picker__inputGroup__input {
    &:invalid {
      background: none;
    }
    &:focus {
      outline: none;
    }
  }
  .react-date-picker__inputGroup__divider {
    cursor: auto;
  }
  
  .react-date-picker__button {
    padding-right: 0.9rem;
    &:focus {
      outline: none;
    }
  }

  .react-calendar button {
    &:enabled {
      &:hover {
        background-color: ${({ theme }) => theme.basePalette.blueMain};
        color: ${({ theme }) => theme.basePalette.white};
      }
    }

    &:disabled {
      cursor: default;
      color: ${({ theme }) => theme.palette.grey20};

      &:hover {
        background-color: ${({ theme }) => theme.basePalette.greyLight};
        color: ${({ theme }) => theme.palette.grey20};
      }
    }
  }

  .react-calendar__month-view__days__day {
    color: ${({ theme }) => theme.basePalette.greyDark};

    &:hover{
      background-color: ${({ theme }) => theme.basePalette.blueMain};
      color: ${({ theme }) => theme.palette.white};
    }
  }

  .react-calendar__tile--now {
    background: ${({ theme }) => theme.basePalette.white};
    color: ${({ theme }) => theme.basePalette.blueMain};
    font-family: ${({ theme }) => theme.fontFamily.bodyBold};
    font-weight: ${({ theme }) => theme.fontWeight.bold};

    &:disabled {
      background: ${({ theme }) => theme.palette.btnGrey};
    }
  }

  .react-calendar__tile--active, .react-calendar__tile--hasActive {
    background-color: ${({ theme }) => theme.basePalette.blueMain};
    color: ${({ theme }) => theme.basePalette.white};
  }
`;

export const Icon = styled.img<IconProps>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  transform: ${({ open }) => (open ? 'scaleY(-1)' : '')};
`;

export const MetaWrapper = styled.div`
  display: flex;
`;

export const Label = styled.p`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  margin-bottom: 5px;
  margin-right: auto;
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.palette.btnError};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-style: italic;
  margin-bottom: 5px;
  margin-left: auto;
  margin-top: 5px;
`;
