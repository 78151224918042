export default {
  GenericText: {
    next: 'Next',
    back: 'Back',
    yes: 'Yes',
    no: 'No',
    saveChanges: 'Save Changes',
    search: 'Search',
    clear: 'Clear',
    apply: 'Apply',
    save: 'Save',
    submit: 'Submit',
    cancel: 'Cancel',
    complete: 'Complete',
    stepDivider: 'of',
    pleaseSelect: 'Please Select...',
    pleaseEnter: 'Please Enter...',
    okay: 'Okay',
    confirm: 'Confirm',
    download: 'Download',
    transfer: 'Transfer',
    received: 'Received',
    at: 'at',
    by: 'by',
    close: 'Close',
    required: 'Required',
    allTime: 'All Time',
    optional: 'Optional',
    from: 'from',
    to: 'to',
    Credit: 'Credit',
    delete: 'Delete',
    create: 'Create',
    downloadError: 'Error loading file',
    thankYou: 'Thank You',
  },
  Components: {
    UI: {
      LoginForm: {
        emailInputLabel: 'Email',
        emailPlaceholder: 'email',
        passwordInputLabel: 'Password',
        passwordPlaceholder: 'password',
        registrationDueDateLabel: 'What is the due date of your registration or payment plan?',
        forgotPasswordText: 'Forgot Password?',
        loginButton: 'Log In',
        signupButton: 'Create Account',
        termsText1: 'I agree to the',
        termsLink: 'Terms and Conditions',
        newsletterText: 'Subscribe to our Newsletter',
        authError: 'The login details you have entered are incorrect, please try again.',
      },
      ForgotPasswordForm: {
        emailInputLabel: 'Email',
        emailPlaceholder: 'email',
        submitButton: 'Send Instructions',
        modalTitle: 'Instructions',
        modalTitleHighlight: 'Sent',
        modalSubtext: 'We have sent you instructions on how to reset your password. Please check your inbox.',
        modalButton: 'Okay',
        userNotFound: 'No user found for that email address.',
      },
      UpdatePasswordForm: {
        currentPasswordInput: 'Old Password',
        newPasswordInput: 'New Password',
        modalTitle: 'Password',
        modalTitleHighlight: 'Updated',
        modalSubtext: 'You have successfully updated your password',
      },
      AdminUpdatePasswordForm: {
        title: 'Create Password',
        subtitle: 'Please create a new password to replace the temporary password we sent you.',
        currentPasswordInput: 'Current Password',
        newPasswordInput: 'New Password',
      },
      SideNav: {
        dashboard: 'Dashboard',
        customers: 'Customers',
        duePlans: 'Plans',
        payments: 'Payments',
        overdueInstallments: 'Overdue Instalments',
        newsletters: 'Newsletters',
        support: 'Email Support',
        notifications: 'Notifications',
        settings: 'Settings',
        reports: 'Reports',
      },
      TopNav: {
        dashboard: 'Dashboard',
        createPlan: 'New Plan',
        payments: 'Payments',
        receipts: 'Receipts',
        editProfile: 'Edit Profile',
        updatePassword: 'Update Password',
        contactUs: 'Contact Us',
        logout: 'Log out',
        modalTitle: 'Log',
        modalTitleHighlight: 'Out',
        modalSubtext: 'Are you sure you want to logout of your account',
        confirmButton: 'Yes, Log Out',
        cancelButton: 'No, Cancel',
        referFriend: 'Refer Friend',
      },
      LoginLayout: {
        signupCtaText: "Don't have an account?",
        loginCtaText: 'Already have an account?',
        signupCta: 'Sign Up',
        loginCta: 'Log In',
        bgTextLogin1: 'Use Forget Reg for',
        bgTextLogin2: 'than just your rego.',
        bgTextLoginHighlight: 'more',
        bgTextLoginSmall: 'You can now save for that trip away, pay off the kids school fees, or use Forget Reg for whatever you want.',
        bgTextSignup1: 'Pay as little as',
        bgTextSignup2: 'for your rego.',
        bgTextSignupHighlight: '$15 a week',
        bgTextSignupSmall: 'Pre pay your vehicle registration in weekly, fortnightly or monthly instalments.',
      },
      CreateProfileForm: {
        firstNameInput: 'First name',
        lastNameInput: 'Last name',
        emailInput: 'Email',
        phoneInput: 'Phone',
        addressInput: 'Street Address',
        suburbInput: 'Suburb',
        stateInput: 'State',
        postcodeInput: 'Post Code',
        dobInput: 'Date of Birth',
        genderSelect: 'Gender',
        concessionDropdown: 'Concession',
        modalTitle: 'Profile',
        modalTitleHighlight: 'Updated',
        modalSubtext: 'You have successfully updated your profile.',
      },
      PlanTypeForm: {
        registration: 'Vehicle Registration',
        christmas: 'Christmas Fund',
        holiday: 'Holiday',
        insurance: 'Insurance',
        ratePayments: 'Rate Payments',
        savings: 'Savings',
        schoolFees: 'School Fees',
        other: 'Other...',
      },
      FundDetailsForm: {
        costInput: 'How much do you want to save?',
        dueDate: 'When is it due?',
        startDate: 'When would you like to start?',
        frequencySelect: 'How often would you like to pay?',
        nameInput: 'Plan Name',
        namePlaceholder: 'What are you saving for?',
      },
      PlanDetailsForm: {
        costInput: 'How much is your registration?',
        dueDate: 'When is your registration due?',
        startDate: 'When would you like to make your first payment?',
        lengthSelect: 'What is your registration renewal period?',
        registrationInput: 'What is the registration number?',
        frequencySelect: 'How often would you like to pay?',
        vehicleTypeDropdown: 'What type of vehicle is it?',
        vehicleMakeDropdown: 'What is the vehicle make?',
        vehicleModelDropdown: 'What model is it?',
        vehicleColourInput: 'What colour is it?',
        vehicleStateDropdown: 'Where is it registered?',
        vehicleYearInput: 'What year is the vehicle?',
        nameInput: 'Plan Name',
        namePlaceholder: 'What are you saving for?',
        concessionTitle: 'Concession card',
        concessionText: 'Do you have either a Pensioner or Health Care card?',
      },
      ReviewPlan: {
        planType: 'Type',
        amount: 'Amount',
        renewalPeriod: 'Renewal Period',
        starts: 'Starts',
        ends: 'Ends',
        due: 'Due',
        name: 'Plan Name',
        frequency: 'Frequency',
        vehicleType: 'Type',
        vehicleMake: 'Make',
        vehicleModel: 'Model',
        vehicleYear: 'Year',
        vehicleColour: 'Colour',
        registration: 'Registration',
        registeredState: 'State',
        concessionCard: 'Concession Card',
        startDateText1: 'Starting',
        startDateText2: 'you will pay',
        startDateText3: 'instalments of',
        successTitle: 'Plan',
        successTitleHighlight: 'Created',
        successSubtitle: 'You have successfully created a plan for',
        successSubtitleFund: 'You have successfully created a plan for your',
        successBodyText: 'A member of the ForgetReg team will be in touch to finalise and activate your plan. \n \n \n Would you like to create another plan?',
        modalCancelButton: 'Create another plan',
        modalConfirmButton: 'Set up direct debit',
        adminFee: '*includes $% admin fee',
      },
      PlanEstimate: {
        title: 'Your',
        titleHighlight: 'Estimate',
        subtitle: 'Your details will appear below',
        type: 'Type',
        amount: 'Amount',
        renewalPeriod: 'Renewal Period',
        starts: 'Starts',
        due: 'Due',
        frequency: 'Frequency',
        make: 'Make',
        model: 'Model',
        year: 'Year',
        state: 'State',
        concessionCard: 'Concession Card',
        colour: 'Colour',
        registration: 'Registration',
        name: 'Plan Name',
        startDateText1: 'Starting',
        startDateText2: 'you will pay',
        startDateText3: 'instalments of',
        adminFee: '*includes $% admin fee',
      },
    },
    Common: {
      DeferPaymentModal: {
        rejectHeading: 'Reject Payment',
        skipHeading: 'Skip Payment',
        deferSubtext: 'Please confirm the updated instalment amount for the % remaining payments.',
        rejectConfirm: 'Reject',
        skipConfirm: 'Skip',
        currentInstalmentText: 'Current Instalment Amount',
        newInstalmentText: 'New Instalment Amount',
        rejectPaymentSuccess: 'Payment successfully rejected',
        skipPaymentSuccess: 'Payment successfully skipped',
        noRemainingPaymentsError: 'There are no remaining Payments can not defer',
        paymentDeferralReversedSuccess: 'Successfully reverse payment deferral',
      },
      TableBulkEdit: {
        oneSelected: 'payment selected',
        multipleSelected: 'payments selected',
        allSelected: 'All visible payments selected',
        markAsPaidButton: 'Mark as Paid',
        markAsUnPaidButton: 'Mark as Unpaid',
      },
      ImageCropper: {
        completeButtonText: 'Done',
      },
      Table: {
        loading: 'Loading...',
        noRecords: 'No records to display',
      },
      CompletePlanModal: {
        title: 'Finalise Plan',
        subtitle: 'Please select which plan you would like to complete and upload the receipt.',
        planDropDownLabel: 'Plan',
        planRequiredString: 'Please select a plan',
        noPlansString: 'Customer has no plans to finalise',
        uploadErrorText: 'Error uploading file, please try again',
      },
      TransferFundsModal: {
        title: 'Transfer Funds',
        subtitle: 'Please select which plan you would like to transfer funds to and from.',
        amountLabel: 'Amount',
        amountPlaceholder: 'Please select a plan...',
        transferFromLabel: 'Transfer From',
        transferToLabel: 'Transfer To',
        planRequiredString: 'Please select a plan',
        noPlansString: 'Customer has no active plans to transfer funds to or from.',
        samePlanError: 'Can\'t transfer between the same Plans.',
        amountError: 'Funds to be transferred exceeds amount remaining on the receiving plan',
        noAmountError: 'Can\'t transfer from plan with no paid value',
      },
      AddAdminModal: {
        title: 'Create Admin Account',
        subtitle: 'Please add the new staff member\'s information below.',
        firstNameLabel: 'First Name',
        lastNameLabel: 'Last Name',
        emailLabel: 'Email',
        phoneLabel: 'Phone',
        adminRoleSubheader: 'Admin Role',
        radioLabels: {
          admin: 'Admin',
          master: 'Master Admin',
        },
      },
      EditAdminModal: {
        title: 'Edit Admin Account',
        subtitle: 'Please edit the existing staff member\'s information below.',
      },
      FileUploadModal: {
        title: 'Upload File',
        uploadErrorText: 'Error uploading file, please try again',
      },
      DeleteNoteModal: {
        title: 'Delete Note',
        subtitle: 'Are you sure you want to delete this note?',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Delete',
      },
      PlanModal: {
        createPlanTitle: 'Create New Plan',
        editPlanTitle: 'Plan Details',
        renewPlanTitle: 'Renew Plan',
        duplicatePlanTitle: 'Duplicate Plan',
        createButtonText: 'Create Plan',
        editButtonText: 'Edit Plan',
        planTypeDropdown: 'Plan Type',
        concessionCardDropdown: 'Concession Card',
        planNameInput: 'Plan Name',
        startDatePicker: 'Start Date',
        endDatePicker: 'End Date',
        dueDatePicker: 'Due Date',
        installmentFrequencyDropdown: 'Instalment Frequency',
        planStatusDropdown: 'Plan Status',
        registrationInput: 'Registration Number',
        vehicleTypeDropdown: 'Vehicle Type',
        vehicleMakeInput: 'Vehicle Make',
        vehicleModelInput: 'Vehicle Model',
        vehicleColorDropdown: 'Vehicle Colour',
        vehicleYearInput: 'Vehicle Year',
        vehicleRegisteredState: 'Vehicle Registered State',
        RegistrationLengthDropdown: 'Registration Duration',
        valueInput: 'Amount',
        installmentAmountDisplay: 'Instalment Amount',
        alreadyPaidDisplay: 'Paid Plan / Admin Fees',
        adminFeesDisplay: 'Admin Fees',
        totalCostDisplay: 'Total Cost',
        remainingPaymentsDisplay: 'Payments Remaining',
        sendEmail: 'Email User to notifiy them of changes to their plan.',
        viewPayments: 'View Payments',
        renewButtonText: 'Renew Plan',
        archivedPlanTitle: 'Archived Plan',
        totalPaidDisplay: 'Total Paid',
        paymentsMadeDisplay: 'Payments Made',
        invalidFrequencyForDates: 'Invalid Frequency for given dates',
        startDateTooClose: 'Start Date too close to due date',
        dueDateTooClose: 'Due Date too close to start date',
      },
      ContextMenu: {
        deleteUser: 'Delete User',
        deactivateUser: 'Deactivate User',
        putOnHold: 'Put On Hold',
        suspendUser: 'Suspend User',
        rejectPayment: 'Reject Payment',
        skipPayment: 'Skip Payment',
        deletePlan: 'Delete Plan',
        reverseSkippedPayment: 'Reverse Skipped Payment',
      },
      FileUploader: {
        typeText: 'Photos or Documents',
        uploadText: 'Click or drop file to upload',
        uploadError: 'There was an error with your upload. Please ensure your file is a PNG, JPG or PDF and less than 5mb.',
      },
      ToolTip: {
        title: 'Remove File?',
        cancelText: 'No, Cancel',
        confirmText: 'Yes, Remove',
      },
      ModalPlanTransfers: {
        title: 'Remove File?',
        cancelText: 'No, Cancel',
        confirmText: 'Yes, Remove',
      },
    },
  },
  Pages: {
    Customer: {
      PromoCalculator: {
        planCost: 'How much is your regristration?',
        planLength: 'How long is this regristration?',
        startDate: 'Your preferred start date?',
        dueDate: 'When is it due?',
        frequency: 'How often would you pay?',
        resultHeading: 'If you join today your registration will cost you',
        buttonText: 'Apply Now',
        adminFee: '*includes $% admin fee',
      },
      Dashboard: {
        UserInfo: {
          pageTitle: 'Welcome back,',
          pageSubtitle: 'Below is a summary of your account',
          nextPayment: 'Next',
          nextPaymentHighlight: 'Payment:',
          directDebitTitle1: 'Set up your',
          directDebitTitle2: 'Now!',
          directDebitTitleHighlight: 'Direct Debit',
          directDebitSubtext: 'Make sure you never miss a payment. Set up your direct debit now.',
          directDebitButton: 'Set Up My Direct Debit',
          due: 'Due',
        },
        PlansList: {
          plansHeading: 'Payment',
          plansHeadingHighlight: 'Plans',
          headerCreate: 'Create New',
          createHeading: 'Create a new',
          createHeadingHighligh: 'Plan',
          createSubtext: 'Start a savings plan for something new.',
          createButton: 'Create a plan',
        },
        PaymentServiceIntegration: {
          title: 'Thanks for setting up your direct debit!',
          fetchingResultText: 'Please wait while we check the details.',
          resultErrorText: 'There was an error retrieving your direct debit result. Please close this modal and try again.',
        },
        InternalLinks: {
          getHelp: {
            title: 'Get Help',
            subtitle: 'Need some advice or help, drop us a line',
          },
          referFriend: {
            title: 'Refer a Friend',
            subtitle: 'Let others know about Forget Reg',
          },
        },
      },
      Payments: {
        pageTitle: 'Customer',
        pageTitleHighlight: 'Payments',
      },
      Receipts: {
        pageTitle: 'Customer',
        pageTitleHighlight: 'Receipts',
        viewReceipt: 'View Receipt',
      },
      UpdatePassword: {
        pageTitle: 'Update',
        pageTitleHighlight: 'Password',
        pageSubtitle: 'Please enter your old password followed by your new password.',
      },
      ContactUs: {
        pageTitle: 'Get',
        pageTitleHighlight: 'Help',
        pageSubtitlePartOne: 'IF YOU HAVE ANY',
        pageSubTitleHighlight: 'QUESTIONS,',
        pageSubtitlePartTwo: 'JUST GET IN TOUCH',
        bodyPartOne: 'Let us know how we can help and one of customer service staff will get in touch with you asap to help.',
        bodyPartTwo: 'Alternatively you can contact us directly on the details below:',
        contactEmail: 'info@forgetreg.com.au',
        contactPhone: '1800 FORGET (1800 367 438)',
        subjectInputLabel: 'Subject',
        subjectInputPlaceholder: 'How can we help?',
        messageInputLabel: 'Add your message',
        messageInputPlaceholder: 'How can we help?',
        submitInputButton: 'Send Message',
        confirmationMessage: 'Your message has been received by us, we will get in touch with you shortly.',
      },
      ReferFriend: {
        pageTitle: 'Refer',
        pageTitleHighlight: 'a Friend',
        pageSubtitle: 'Enjoying Forget Reg, tell all your friends',
        bodyPart: 'We really appreciate any referrals, and any referrals get logged against your account so we can reward you when they join us themselves.',
        firstNameInputLabel: 'Friends First Name',
        firstNameInputPlaceHolder: 'First Name...',
        lastNameInputLabel: 'Friends Last Name',
        lastNameInputPlaceHolder: 'Last Name...',
        emailInputLabel: 'Friends Email Address',
        emailInputPlaceHolder: 'Email...',
        messageInputLabel: 'Add A message',
        messageInputPlaceholder: 'Add A message',
        messageInputDefault: 'Check out Forget Reg! It’s a great way to spread your costs over time and make sure your rego and other bills get paid on time.  You can also use it to save for a holiday or Christmas fund.',
        submitInputButton: 'Send Referral',
        confirmationMessage: 'You message has been sent to your friend. We appreciate the referral and thanks for using Forget Reg.',
        sendAnother: 'Send Another',
      },
      CreateProfile: {
        pageTitle: 'Edit',
        pageTitleOnboarding: 'Create',
        pageTitleHighlight: 'Profile',
        pageSubtitle: 'To get started, please tell use a bit about yourself',
        pageSubtitleEdit: 'Please update your personal information below.',
      },
      CreatePlan: {
        PlanType: {
          pageTitle: 'What will this plan be',
          pageTitleHighlight: 'used',
          pageTitleTwo: 'for?',
          pageSubtitle: 'So we can provide you with the most accurate quote.',
        },
        PlanDetails: {
          pageTitle: 'Tell us about your',
          pageTitleHighlight: 'vehicle',
          pageSubtitle: 'So we can pay your registration for you.',
        },
        FundDetails: {
          pageTitle: 'Tell us about your',
          pageTitleHighlight: 'fund',
          pageSubtitle: 'So we can help you save.',
        },
        ReviewPlan: {
          pageTitle: 'Review',
          pageTitleHighlight: 'plan',
          pageSubtitle: 'To make sure we have everything correct.',
        },
      },
    },
    Admin: {
      Dashboard: {
        pageSubtitle: 'Hi %,',
        pageTitle: 'Welcome Back ',
        revenuePanel: 'Instalments',
        feesPanel: 'Fees',
        usersPanel: 'Customers',
        plansPanel: 'Plans',
        customerStatusPanel: 'Customers Status',
        customersPanel: 'Customers',
        amountCollectedPanel: 'Amount Collected',
        planTypesPanel: 'Fund Types',
        locationsPanel: 'Customer Locations',
        filterAll: 'All Time',
        noData: 'No data availaible for selected date range',
      },
    },
    Login: {
      adminLoginTitle: 'Admin Login',
      loginTitle: 'Welcome',
      loginHighlight: 'Back',
      loginSubtitle: 'Please log in to your account below',
      signupTitle: 'Create Your',
      signupHighlight: 'Account',
      signupSubtitle: 'Join Forget Reg and start saving today',
    },
    CreatePassword: {
      createPasswordTitle: 'Create',
      createPasswordHighlight: 'Password',
      createPasswordSubtitle: 'Please create a new password to replace the temporary password we sent you',
      currentPasswordInput: 'New Password',
      newPasswordInput: 'Confirm New Password',
      resetPassword: 'Reset Password',
    },
    ForgotPassword: {
      pageTitleHighlight: 'Forgot',
      pageTitle: 'Password',
      pageSubtitle: 'Please enter your email below and we will send you instructions on how to reset your password.',
    },
    Users: {
      pageTitle: 'Customers',
      customerStatusFilterDropdownLabel: 'Customer Status',
      stateFilterDropdownLabel: 'Location',
    },
    Reports: {
      pageTitle: 'Reports',
      userInfoReport: 'User Info',
      userInfoDetails: 'Unique User ID, Customer Name, Gender, Email, Phone, Address, Subscribed to Newsletter, Customer Status',
      planInfoReport: 'Plan Info',
      planInfoDetails: 'Unique User ID, Customer Name, Plan Name, Plan Type, Concession, Plan Start Date, Plan End Date, Plan Due Date, Registration No., '
        + 'Registration Duration, Vehicle Registration State, Vehicle Type, Vehicle Make, Vehicle Model, Vehicle Year, Vehicle Color, Plan Amount, '
        + 'Admin Fee, Instalment amount, Instalment Frequency, Plan Status.',
      generateReportButton: 'Generate Report',
      downloadReportButton: 'Download',
      userInfoReportTitle: 'User Info Report',
      planInfoReportTitle: 'Plan Info Report',
      userReportHeaders: {
        uniqueUserId: 'Unique User ID',
        customerName: 'Customer Name',
        gender: 'Gender',
        email: 'Email',
        phone: 'Phone',
        address: 'Address',
        subscribedToNewsletter: 'Subscribed to Newsletter',
        status: 'Customer Status',
      },
      planReportHeaders: {
        uniqueUserId: 'Unique User ID',
        customerName: 'Customer Name',
        planName: 'Plan Name',
        planType: 'Plan Type',
        planConcessionCard: 'Plan Concession Card',
        planStartDate: 'Plan Start Date',
        planEndDate: 'Plan End Date',
        planDueDate: 'Plan Due Date',
        registrationNo: 'Registration No.',
        registrationDuration: 'Registration Duration',
        registrationState: 'Registration State',
        vehicleType: 'Vehicle Type',
        vehicleMake: 'Vehicle Make',
        vehicleModel: 'Vehicle Model',
        vehicleYear: 'Vehicle Year',
        vehicleColor: 'Vehicle Color',
        planAmount: 'Plan Amount',
        adminFee: 'Admin Fee',
        installmentAmount: 'Instalment Amount',
        installmentFrequency: 'Instalment Frequency',
        planStatus: 'Plan Status',
      },
    },
    UserProfile: {
      pageTitle: 'Customer Profile',
      ProfileHeader: {
        transferFundsButtonText: 'Transfer Funds',
        createPlanButtonText: '+ Create Plan',
        finalisePlanButtonText: 'Finalise Plan',
        deleteUserModalTitle: 'Delete User',
        deleteUserModalSubTitlePartOne: 'Are you sure you want to delete',
        deleteUserModalSubTitlePartTwo: '\'s account?',
      },
      Plans: {
        tabButtonText: 'Plans',
        total: 'Total',
        plans: 'Plans',
        installments: 'Instalments',
        fees: 'Fees',
        deletePlanModal: {
          title: 'Delete Plan',
          subTitle: 'Are you sure you want to delete this plan?',
        },
      },
      ArchivedPlans: {
        tabButtonText: 'Archived Plans',
      },
      Payments: {
        tabButtonText: 'Payments',
        filterLabel: 'Plan Filter',
        showAll: 'Show All Plans Payments',
        showActive: 'Show Active Plans Payments',
      },
      Referrals: {
        tabButtonText: 'Referrals',
      },
      PersonalInfo: {
        tabButtonText: 'Personal Info',
        firstNameInput: 'First Name',
        lastNameInput: 'Last Name',
        emailInput: 'Email',
        phoneInput: 'Phone',
        addressInput: 'Street Address',
        suburbInput: 'Suburb',
        stateInput: 'State',
        postcodeInput: 'Post Code',
        dobInput: 'Date Of Birth',
        concessionDropdown: 'Concession',
        concessionNumberInput: 'Card Number',
        concessionExpiryInput: 'Card Expiry',
      },
      Notes: {
        tabButtonText: 'Notes',
        TextAreaPlaceholder: 'Please type your note here...',
        createNewText: 'Create New',
        deleteText: 'Delete',
        newNoteText: 'New Note',
      },
      Credit: {
        tabButtonText: 'Credit',
        CustomerCredits: {
          title: 'Customer Credits',
          subTitle: 'This customer currently has the following amount in credit.',
          amountError: 'Amount must be a number',
        },
        TransferCredits: {
          title: 'Transfer Credits',
          subTitle: 'Please select which plan you would like to transfer funds to and the amount to transfer from credit.',
          amountLabel: 'Amount to transfer from credit',
          amountPlaceholder: 'Enter transfer amount',
          planToLabel: 'Transfer To Which Plan',
          amountError: 'Sorry. That amount is too much, the customer doesn’t have enough funds to cover this transfer.',
          planError: 'Sorry.  That amount is more than the total value of the plan you are trying to transfer it to.',
        },
        CreditHistory: {
          title: 'Transaction History',
          subTitle: 'Shows a full list of any credit changes for this user.',
          addedByAdmin: 'Added by Admin',
          credit: 'Credit',
          tableColumnLabels: {
            date: 'Date',
            from: 'From',
            to: 'To',
            amount: 'Amount',
            by: 'By',
          },
        },
      },
    },
    DuePlans: {
      pageTitle: 'Plans',
      typeFilterDropdownLabel: 'Type',
      statusFilterDropdownLabel: 'Plan Status',
      dueDateFilterHeading: 'Due Date',
      startDateFilterHeading: 'Start Date',
      amountFilterHeading: 'Amount',
      stateFilterHeading: 'State',
    },
    Payments: {
      pageTitle: 'Payments',
      typeFilterDropdownLabel: 'Type',
      statusFilterDropdownLabel: 'Payment Status',
      dueDateFilterHeading: 'Due Date',
      paidDateFilterHeading: 'Paid Date',
      planValueFilterHeading: 'Plan Amount',
      installmentValueFilterHeading: 'Instalment Amount',
    },
    OverdueInstallments: {
      pageTitle: 'Overdue Instalments',
      typeFilterDropdownLabel: 'Type',
      dueDateFilterHeading: 'Due Date',
      planValueFilterHeading: 'Plan Amount',
      installmentValueFilterHeading: 'Instalment Amount',
    },
    Newsletters: {
      pageTitle: 'Newsletters',
    },
    Support: {
      pageTitle: 'Email Support',
      viewProfileButton: 'View Profile',
      addNoteButton: 'Add Note',
      statusDropdownLabel: 'Status',
      emailLabel: 'Email:',
      phoneLabel: 'Phone:',
      replyInputLabel: 'Reply',
      replyInputPlaceHolder: 'Enter Email',
      replyInputSubmitButton: 'Send Reply',
      messageRequiredError: 'A message is required',
      replySent: 'Reply Sent',
    },
    Notifications: {
      pageTitle: 'Notifications',
    },
    Settings: {
      pageTitle: 'Settings',
      confirmLogoutButton: 'Yes, Log Out',
      cancelButton: 'No, Cancel',
      logoutModalTitle: 'Log Out',
      logoutModalSubtext: 'Are you sure you want to log out?',
      tabText: {
        editProfile: 'Edit Profile',
        changePassword: 'Change Password',
        adminAccounts: 'Admin Accounts',
        systemMaintenance: 'System Maintenance',
      },
      AdminAccounts: {
        tabTitle: 'Admin Accounts',
        tabSubtitle: 'You can Manage the admin accounts below.',
        noAdmins: 'There are no other admin accounts',
        addNewButton: '+ Add New',
        DeleteAdminModal: {
          title: 'Delete Admin Account',
          subtext: 'Are you sure you want to delete this admin account?',
          cancelButton: 'Cancel',
          confirmButton: 'Delete',
        },
      },
      EditProfile: {
        tabTitle: 'Edit Profile',
        tabSubtitle: 'Please update your profile below.',
        logoutButton: 'Log Out',
        saveProfileChangesButton: 'Save Changes',
        firstNameInput: 'First Name',
        lastNameInput: 'Last Name',
        emailInput: 'Email',
        phoneInput: 'Phone',
      },
    },
  },
};
