import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, generatePath } from 'react-router';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { setSelectedPlanTransfers } from '../../../lib/store/contexts/admin/selectedPlanTransfers/actions';
import { setNeedsUpdate as setPaymentsNeedUpdate } from '../../../lib/store/contexts/admin/selectedCustomer/payments/actions';
import { getCustomer } from '../../../lib/api/admin/customers';
import {
  createOrUpdateSelectedCustomerPlan,
  CustomerPlanWithTransfers,
  finalisePlan,
  transferPlanFunds,
} from '../../../lib/api/admin/plans';
import { getPlansForPaymentFilter } from '../../../lib/api/admin/payments';

import useStrings from '../../../hooks/useStrings';
import { CUSTOMER_PROFILE_TABS_DISPLAY, FEES } from '../../../CONSTANTS';

import TransferFundsModal from '../../../components/Common/TransferFundsModal';
import PageLayout from '../../../layouts/PageLayout';
import Grid from '../../../components/Common/Grid';
import ProfileHeader from '../../../components/UI/ProfileHeader';
import LoadingScreen from '../../../components/UI/LoadingScreen';
import RenewPlanModal from '../../../components/Common/RenewPlanModal';
import EditPlanModal from '../../../components/Common/EditPlanModal';
import CreatePlanModal from '../../../components/Common/CreatePlanModal';
import CompletePlanModal from '../../../components/Common/CompletePlanModal';
import ReadOnlyPlanModal from '../../../components/Common/ReadOnlyPlanModal';
import PersonalInfo from './PersonalInfo';
import Plans from './Plans';
import ArchivedPlans from './ArchivedPlans';
import Payments from './Payments';
import Referrals from './Referrals';
import Notes from './Notes';
import PlanTotal from './Plans/PlanTotal';
import Credits from './Credits';

import {
  ContentWrapper,
  LineBreak,
  PlanTotalWrapper,
  TabButton,
  TabWrapper,
} from './Styles';

import {
  CustomerProfileProps,
  CustomerProfileView,
  RenewPlanData,
} from './types';
import { ApplicationState } from '../../../lib/store';
import { APIThunkDispatch } from '../../../lib/types/API';
import { Plan } from '../../../lib/types/DBModels';
import {
  InstallmentFrequency,
  PlanStatus,
  PlanType,
  RegistrationLength,
} from '../../../lib/types/Plan';

const CustomerProfile: React.FC<CustomerProfileProps> = () => {
  const [{ Pages: { UserProfile } }] = useStrings();
  const dispatch: APIThunkDispatch = useDispatch();
  const history = useHistory();
  const {
    userId,
    tab,
    planId: paramPlanId,
    customerId: paramCustomerId,
  } = useParams<{ userId: string, tab: string, planId?: string, customerId?: string }>();
  const [view, setView] = useState(
    Object.keys(CUSTOMER_PROFILE_TABS_DISPLAY).find((key) => CUSTOMER_PROFILE_TABS_DISPLAY[key] === tab),
  );
  const [creatingPlan, setCreatingPlan] = useState<boolean>(false);
  const [editingPlan, setEditingPlan] = useState<{ planId: number, customerId: number } | null>(
    paramPlanId && paramCustomerId ? { planId: Number(paramPlanId), customerId: Number(paramCustomerId) } : null,
  );
  const [renewingPlan, setRenewingPlan] = useState<RenewPlanData| null>();
  const [viewArchivedPlan, setViewArchivedPlan] = useState<{ planId: number, customerId: number } | null>();
  const [finalizingPlan, setFinalizingPlan] = useState(false);
  const [transferFunds, setTransferFunds] = useState(false);

  const { selectedCustomer, adminUser } = useSelector((state: ApplicationState) => ({
    selectedCustomer: state.admin.customerState.selectedCustomer,
    adminUser: state.admin.adminUserState.adminUser,
  }));

  const onFinalisePlan = async ({ planId, receiptURL }: { planId: number, receiptURL?: string }) => {
    if (selectedCustomer) {
      const { data, success } = await dispatch(
        finalisePlan(selectedCustomer.customerId, planId, receiptURL),
      );

      const completedPlanData = data?.customerPlanListView;
      if (success && completedPlanData) {
        let newDueDate;
        if (completedPlanData.registrationLength === RegistrationLength.THREE_MONTHS) {
          newDueDate = dayjs(completedPlanData.dueDate).add(3, 'month');
        } else if (completedPlanData.registrationLength === RegistrationLength.SIX_MONTHS) {
          newDueDate = dayjs(completedPlanData.dueDate).add(6, 'month');
        } else if (!completedPlanData.registrationLength // default to 12 months
          || completedPlanData.registrationLength === RegistrationLength.UNKNOWN
          || completedPlanData.registrationLength === RegistrationLength.TWELVE_MONTHS) {
          newDueDate = dayjs(completedPlanData.dueDate).add(12, 'month');
        }

        let newStartDate = dayjs(completedPlanData.endDate); // initialise to avoid null checks
        if (completedPlanData.frequency === InstallmentFrequency.WEEK) {
          newStartDate = dayjs(completedPlanData.endDate).add(1, 'week');
        } else if (completedPlanData.frequency === InstallmentFrequency.FORTNIGHT) {
          newStartDate = dayjs(completedPlanData.endDate).add(2, 'week');
        } else if (completedPlanData.frequency === InstallmentFrequency.MONTH) {
          newStartDate = dayjs(completedPlanData.endDate).add(1, 'month');
        }

        let regLength;
        if (completedPlanData.planType === PlanType.VEHICLE_REGISTRATION) {
          if (completedPlanData.registrationLength
            && completedPlanData.registrationLength !== RegistrationLength.UNKNOWN) {
            regLength = completedPlanData.registrationLength;
          } else regLength = RegistrationLength.TWELVE_MONTHS;
        }

        let adminFee = 0; // initialise to avoid null checks
        if (data?.originalAdminFee && data.originalAdminFee === 0) {
          adminFee = data.originalAdminFee;
          // set to defaults if original not available
        } else if (completedPlanData.frequency === InstallmentFrequency.WEEK) {
          adminFee = FEES.weekly;
        } else if (completedPlanData.frequency === InstallmentFrequency.FORTNIGHT) {
          adminFee = FEES.fortnightly;
        } else if (completedPlanData.frequency === InstallmentFrequency.MONTH) {
          adminFee = FEES.monthly;
        }

        const renewedData: RenewPlanData = {
          renewing: true,
          type: PlanType[completedPlanData.planType as keyof typeof PlanType],
          registrationLength: regLength,
          vehicle: {
            model: completedPlanData.vehicleModel,
            type: completedPlanData.vehicleType,
            year: completedPlanData.vehicleYear,
            registration: completedPlanData.vehicleRegistration,
            color: completedPlanData.vehicleColor,
            registeredState: completedPlanData.vehicleRegisteredState,
          },
          inputVehicleMake: completedPlanData.inputVehicleMake,
          definedVehicleMakeId: completedPlanData.definedVehicleMakeId,
          startDate: newStartDate?.toDate(),
          dueDate: newDueDate?.toDate(),
          value: completedPlanData.value,
          installmentFrequency: InstallmentFrequency[completedPlanData.frequency as keyof typeof InstallmentFrequency],
          adminFee,
          name: completedPlanData.planName,
          receivedValue: 0,
          status: PlanStatus.ACTIVE,
          concessionCard: completedPlanData.concessionCard,
        };
        setRenewingPlan(renewedData);
      }
    }
    setFinalizingPlan(false);
  };

  const onPlanConfirm = async (planData: Plan & { sendEmailConf?: boolean }) => {
    await dispatch(createOrUpdateSelectedCustomerPlan(planData));
    setCreatingPlan(false);
    setRenewingPlan(null);
    setEditingPlan(null);
    dispatch(setSelectedPlanTransfers([]));
    if (selectedCustomer) dispatch<void>(getPlansForPaymentFilter(selectedCustomer.customerId));
  };

  const handleDuplicatePlan = (plan: CustomerPlanWithTransfers) => {
    setViewArchivedPlan(null);
    const renewedData: RenewPlanData = {
      duplicating: true,
      type: PlanType[plan.planType as keyof typeof PlanType],
      installmentFrequency: InstallmentFrequency[plan.frequency as keyof typeof InstallmentFrequency],
      name: plan.planName,
      receivedValue: 0,
      vehicle: {
        model: plan.vehicleModel,
        type: plan.vehicleType,
        year: plan.vehicleYear,
        registration: plan.vehicleRegistration,
        color: plan.vehicleColor,
        registeredState: plan.vehicleRegisteredState,
      },
      registrationLength: plan.registrationLength,
      inputVehicleMake: plan.inputVehicleMake,
      definedVehicleMakeId: plan.definedVehicleMakeId,
      startDate: dayjs(plan.startDate).toDate(),
      dueDate: dayjs(plan.dueDate).toDate(),
      value: plan.value,
      adminFee: plan.adminFee,
      concessionCard: plan.concessionCard,
    };
    setRenewingPlan(renewedData);
  };

  const loadUser = useCallback(() => {
    dispatch<void>(getCustomer(userId));
  }, [dispatch, userId]);

  const onTransferFundsConfirm = async (fromPlanId: string, toPlanId: string) => {
    if (selectedCustomer && adminUser?.userId) {
      if (toPlanId === 'CREDIT') {
        await dispatch(transferPlanFunds(
          adminUser.userId,
          selectedCustomer.customerId,
          Number(fromPlanId),
          null,
          null,
        ));
      } else {
        await dispatch(transferPlanFunds(
          adminUser.userId,
          selectedCustomer.customerId,
          Number(fromPlanId),
          Number(toPlanId),
          null,
        ));
      }
    }
    dispatch(setPaymentsNeedUpdate(true));
    setTransferFunds(false);
  };

  useEffect(() => {
    if (!userId) return;
    loadUser();
  }, [loadUser, userId]);

  if (!selectedCustomer) return <LoadingScreen />;

  const viewTab = (thisView: CustomerProfileView) => {
    const buttonText = {
      [CustomerProfileView.PLANS]: UserProfile.Plans.tabButtonText,
      [CustomerProfileView.ARCHIVED_PLANS]: UserProfile.ArchivedPlans.tabButtonText,
      [CustomerProfileView.NOTES]: UserProfile.Notes.tabButtonText,
      [CustomerProfileView.PAYMENTS]: UserProfile.Payments.tabButtonText,
      [CustomerProfileView.PERSONAL_INFO]: UserProfile.PersonalInfo.tabButtonText,
      [CustomerProfileView.CREDIT]: UserProfile.Credit.tabButtonText,
      [CustomerProfileView.REFERRALS]: UserProfile.Referrals.tabButtonText,
    };
    return (
      <TabButton
        active={view === thisView}
        onClick={() => {
          // Always open notes tab in a new window tab.
          if (thisView === CustomerProfileView.NOTES) {
            const win = window.open(`/admin/user/${userId}/${CUSTOMER_PROFILE_TABS_DISPLAY.NOTES}`, '_blank');
            if (win) win.focus();
          } else {
            setView(thisView);
            history.replace({
              pathname: generatePath('/admin/user/:userId/:tab', { userId, tab: CUSTOMER_PROFILE_TABS_DISPLAY[thisView] }),
            });
          }
        }}
      >
        {buttonText[thisView]}
      </TabButton>
    );
  };

  return (
    <PageLayout
      pageTitle={`🙎‍♀️ ${UserProfile.pageTitle}`}
      maxWidth="100%"
    >
      { creatingPlan && (
        <CreatePlanModal
          onConfirm={onPlanConfirm}
          onCancel={() => setCreatingPlan(false)}
        />
      )}
      { renewingPlan && (
        <RenewPlanModal
          renewPlanData={renewingPlan}
          onConfirm={onPlanConfirm}
          onCancel={() => setRenewingPlan(null)}
        />
      )}
      { editingPlan && (
        <EditPlanModal
          planData={editingPlan}
          onConfirm={onPlanConfirm}
          onViewPayments={() => {
            setView(CustomerProfileView.PAYMENTS);
            history.replace({
              pathname: generatePath('/admin/user/:userId/:tab', { userId, tab: CUSTOMER_PROFILE_TABS_DISPLAY[CustomerProfileView.PAYMENTS] }),
            });
          }}
          onCancel={() => {
            setEditingPlan(null);
            dispatch(setSelectedPlanTransfers([]));
          }}
        />
      )}
      { !!finalizingPlan && (
      <CompletePlanModal
        onConfirm={onFinalisePlan}
        onCancel={() => setFinalizingPlan(false)}
        userId={userId}
      />
      )}
      { !!transferFunds && (
      <TransferFundsModal
        onConfirm={onTransferFundsConfirm}
        onCancel={() => setTransferFunds(false)}
      />
      )}
      { viewArchivedPlan && (
        <ReadOnlyPlanModal
          planData={viewArchivedPlan}
          onClose={() => {
            setViewArchivedPlan(null);
            dispatch(setSelectedPlanTransfers([]));
          }}
          onViewPayments={() => {
            setView(CustomerProfileView.PAYMENTS);
            history.replace({
              pathname: generatePath('/admin/user/:userId/:tab', { userId, tab: CUSTOMER_PROFILE_TABS_DISPLAY[CustomerProfileView.PAYMENTS] }),
            });
          }}
          onDuplicatePlan={handleDuplicatePlan}
        />
      )}
      <ProfileHeader
        customer={selectedCustomer}
        onBack={() => (history.length === 1 ? history.push('/admin/users') : history.goBack())}
        handleCreatePlan={() => setCreatingPlan(true)}
        handleFinalizePlan={() => setFinalizingPlan(true)}
        handleTransferFunds={() => setTransferFunds(true)}
      />
      <Grid>
        <Grid row marginTop="2rem" maxWidth="100%">
          <Grid column sm={12}>
            <ContentWrapper>
              <TabWrapper>
                {viewTab(CustomerProfileView.PLANS)}
                {viewTab(CustomerProfileView.ARCHIVED_PLANS)}
                {viewTab(CustomerProfileView.PAYMENTS)}
                {viewTab(CustomerProfileView.PERSONAL_INFO)}
                {viewTab(CustomerProfileView.NOTES)}
                {viewTab(CustomerProfileView.CREDIT)}
                {viewTab(CustomerProfileView.REFERRALS)}
              </TabWrapper>
              <Grid row marginTop="0.5rem" maxWidth="100%">
                <LineBreak />
              </Grid>
              <Grid row maxWidth="100%">
                <Grid
                  column
                  sm={12}
                  alignItems="center"
                  expanded={
                    view === CustomerProfileView.PERSONAL_INFO
                    || view === CustomerProfileView.NOTES
                    || view === CustomerProfileView.CREDIT
                  }
                >
                  {view === CustomerProfileView.PERSONAL_INFO
                    && <PersonalInfo customer={selectedCustomer} /> }
                  {view === CustomerProfileView.PLANS
                    && (
                    <Plans
                      customer={selectedCustomer}
                      onEditPlan={(planId, customerId) => {
                        const win = window.open(`/admin/user/${userId}/${CUSTOMER_PROFILE_TABS_DISPLAY.PLANS}/${planId}/${customerId}`, '_blank');
                        if (win) win.focus();
                      }}
                      onSetPlanStatus={onPlanConfirm}
                    />
                    )}
                  {view === CustomerProfileView.ARCHIVED_PLANS
                    && (
                    <ArchivedPlans
                      customer={selectedCustomer}
                      onViewArchivedPlan={(planId, customerId) => setViewArchivedPlan({ planId, customerId })}
                    />
                    )}
                  {view === CustomerProfileView.PAYMENTS
                    && (
                    <Payments
                      customer={selectedCustomer}
                    />
                    )}
                  {view === CustomerProfileView.NOTES
                    && (
                    <Notes
                      customer={selectedCustomer}
                    />
                    )}
                  {view === CustomerProfileView.CREDIT
                    && (
                    <Credits customer={selectedCustomer} />
                    )}
                  {view === CustomerProfileView.REFERRALS
                    && (
                    <Referrals customer={selectedCustomer} />
                    )}
                </Grid>
              </Grid>
            </ContentWrapper>
            {view === CustomerProfileView.PLANS
              && (
              <PlanTotalWrapper>
                <PlanTotal />
              </PlanTotalWrapper>
              )}
          </Grid>
        </Grid>
      </Grid>
    </PageLayout>

  );
};

export default CustomerProfile;
