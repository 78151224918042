import {
  SET_ALL_SELECTED_CUSTOMER_PLANS,
  CLEAR_SELECTED_CUSTOMER_PLANS,
  SET_UPDATED_PLAN_DATA,
  CREATE_PLAN,
  REMOVE_PLAN,
  PlanActionTypes,
  PlanState,
} from './types';

import {
  setUpdatedRecordsInState,
  removeRecordsFromState,
} from '../../../../utils';

const initialState: PlanState = {
  planData: {
    plans: [],
    count: 0,
  },
};

const PlanReducer = (
  state: PlanState = initialState,
  action: PlanActionTypes,
): PlanState => {
  switch (action.type) {
    case SET_ALL_SELECTED_CUSTOMER_PLANS:
      return {
        ...state,
        planData: {
          plans: action.planData.results,
          count: action.planData.count,
        },
      };
    case SET_UPDATED_PLAN_DATA:
      return {
        ...state,
        planData: {
          plans: setUpdatedRecordsInState('planId', state.planData.plans, action.plans),
          count: state.planData.count,
        },
      };
    case CREATE_PLAN:
      return {
        ...state,
        planData: {
          plans: [...state.planData.plans, action.plan],
          count: state.planData.count + 1,
        },
      };
    case REMOVE_PLAN:
      return {
        ...state,
        planData: {
          plans: removeRecordsFromState('planId', state.planData.plans, action.planIds),
          count: state.planData.count - action.planIds.length,
        },
      };
    case CLEAR_SELECTED_CUSTOMER_PLANS:
      return {
        ...state,
        planData: {
          plans: [],
          count: 0,
        },
      };
    default:
      return state;
  }
};

export default PlanReducer;
