import styled from 'styled-components/macro';

import { StyledModalDropdownProps, IconProps, LabelProps } from './types';

export const StyledModalDropdown = styled.div<StyledModalDropdownProps>`
  opacity: ${({ notEditable }) => (notEditable ? 0.5 : 1)};
  background-color: ${({ theme, error, disabled }) => (error && !disabled && theme.palette.inputErrorBg)};
  border: 1px solid ${({ error, disabled, theme, isOpen }) => {
    if (error && !disabled) return theme.palette.btnError;
    if (isOpen) return theme.basePalette.blueMain;
    return theme.palette.btnDarkGrey;
  }};
  ${({ disabled, notEditable }) => !disabled && !notEditable && 'cursor: pointer'};
  ${({ disabled, notEditable }) => (disabled || notEditable) && 'pointer-events: none'};
  color: ${({ theme, error, disabled }) => (error && !disabled && theme.palette.inputError)};
  min-height: 94px;
  min-width: 195px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.5rem;
  border-radius: 24px;
  font-family: ${({ theme }) => theme.fontFamily.body};
  position: relative;
  width: 100%;
  & ~ & {
    margin: 5px 0px;
  }
  cursor: ${({ disabled }) => !disabled && 'pointer'};

  &:hover {
    border: 1px solid ${({ error, theme, disabled, notEditable }) => {
    if (error) return theme.palette.btnError;
    if (disabled || notEditable) return theme.palette.btnGrey;
    return theme.basePalette.blueMain;
  }};
  }

  // react-select style overrides
  .react-select__control {
    border: none;
    cursor: text;
    border: none;
    padding: 0;
    min-height: 0;
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: ${({ theme }) => theme.fontScale.M}rem;
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    background-color: ${({ error, disabled }) => (error && !disabled && 'transparent')};
    border: none;

    &--is-disabled {
      background: ${({ theme }) => theme.basePalette.white};
    }

    &--is-focused {
      border: none;
      box-shadow: none;
      min-height: 0;
    }

    &--menu-is-open {
      border: none;
      .react-select__indicators {
        transform: scaleY(-1);
      }
    }
  }

  .react-select__placeholder {
    font-size: ${({ theme }) => theme.fontScale.M}rem;
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    font-family: ${({ theme }) => theme.fontFamily.bodyLight};
    color: ${({ disabled, theme }) => (disabled ? theme.palette.btnGrey : theme.basePalette.greyDark)};
  }

  .react-select__indicators {
    cursor: pointer;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__value-container {
    padding: 0;
  }
`;

export const MetaWrapper = styled.div`
  display: flex;
`;

export const Label = styled.p<LabelProps>`
  color: ${({ error, disabled, theme }) => {
    if (disabled) return theme.palette.btnGrey;
    if (error && !disabled) return theme.palette.btnError;
    return theme.basePalette.greyDark;
  }};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  margin-right: auto;
  line-height: 18px;
  margin-bottom: 4px;
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.palette.btnError};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  font-style: italic;
  margin-bottom: 5px;
  margin-left: auto;
  margin-top: 5px;
`;

export const IconWrapper = styled.div`
  padding: 4px 6px;
`;

export const Icon = styled.img<IconProps>`
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  width: 12px;
`;
