import styled from 'styled-components';

// Notes styles
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NotesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.basePalette.greyMain};
`;

export const CreateNew = styled.div`
color: ${({ theme }) => theme.basePalette.navyMain};
border-right: 1px solid ${({ theme }) => theme.basePalette.greyMain};
min-width: 218px;
display: flex;
justify-content: center;
padding: 1.25rem 2.1875rem 1.25rem 1.25rem;
&:hover {
  text-decoration: underline;
  cursor: pointer;
}
`;

export const Delete = styled.div`
color: ${({ theme }) => theme.basePalette.navyMain};
display: flex;
justify-content: center;
padding: ${({ theme }) => theme.spacing.XXS * 1.25}rem;
&:hover {
  text-decoration: underline;
  cursor: pointer;
}
`;

export const DeleteText = styled.p`
font-family: ${({ theme }) => theme.fontFamily.body};
font-size: ${({ theme }) => theme.fontScale.M as number * 0.875}rem;
font-weight: ${({ theme }) => theme.fontWeight.semibold};
color: ${({ theme }) => theme.basePalette.navyMain};
`;

export const NotesMain = styled.div`
  display: flex;
  width: 100%;
`;

export const NotesList = styled.div`
  min-width: 218px;
  border-right: 1px solid ${({ theme }) => theme.basePalette.greyMain};
`;

export const NoteDisplay = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: ${({ theme }) => theme.spacing.XS}rem;
`;

export const List = styled.ul`
max-height: 700px;
  overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none; 
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const NoteInfo = styled.div`
color: ${({ theme }) => theme.basePalette.greyDark};
font-family: ${({ theme }) => theme.fontFamily.bodyLight};
font-size: ${({ theme }) => theme.fontScale.S}rem;
font-weight: ${({ theme }) => theme.fontWeight.regular};
text-align: center;
margin: ${({ theme }) => theme.spacing.XXS * 1.5}rem 0;
`;

export const Icon = styled.img`
margin-right: ${({ theme }) => theme.spacing.XXS}rem;
width: 18px;
`;
