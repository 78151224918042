import { apiFetch, apiRequest } from '../Utils';

import { serverCustomerAPI } from '../../../CONSTANTS';

import { ReferFriendSchema } from '../../validators/referFriendSchema';

import { ReferralDTO } from '../../types/DTO';
import { APIThunkResult } from '../../types/API';

export const sendReferral = (
  input: ReferFriendSchema,
): APIThunkResult<ReferralDTO> => (
  apiRequest<ReferralDTO>(async () => {
    const { data, error } = await apiFetch<ReferralDTO>({
      method: 'POST',
      url: `${serverCustomerAPI}/referral/new`,
      body: {
        ...input,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);