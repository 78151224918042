import {
  SET_PLAN_PAYMENTS_TOTAL,
  SET_PLAN_FEES_TOTAL,
  SET_CUSTOMERS_TOTAL,
  SET_PLANS_TOTAL,
  SET_AMOUNT_COLLECTED,
  SET_CUSTOMER_STATUS_TOTAL,
  SET_PLAN_TYPES,
  SET_CUSTOMER_LOCATIONS,
  SET_CUSTOMER_STATUSES,
  StatisticsActionTypes,
  StatisticsState,
} from './types';

const initialState: StatisticsState = {
  planPaymentsTotal: 0,
  planFeesTotal: 0,
  customersTotal: 0,
  plansTotal: 0,
  amountCollected: [],
  customerStatusTotal: [],
  planTypes: [],
  customerLocations: [],
  customerStatuses: [],
};

const StatisticsReducer = (
  state: StatisticsState = initialState,
  action: StatisticsActionTypes,
): StatisticsState => {
  switch (action.type) {
    case SET_PLAN_PAYMENTS_TOTAL:
      return {
        ...state,
        planPaymentsTotal: action.amount,
      };
    case SET_PLAN_FEES_TOTAL:
      return {
        ...state,
        planFeesTotal: action.amount,
      };
    case SET_CUSTOMERS_TOTAL:
      return {
        ...state,
        customersTotal: action.amount,
      };
    case SET_PLANS_TOTAL:
      return {
        ...state,
        plansTotal: action.amount,
      };
    case SET_PLAN_TYPES:
      return {
        ...state,
        planTypes: action.planTypes,
      };
    case SET_AMOUNT_COLLECTED:
      return {
        ...state,
        amountCollected: action.amountCollected,
      };
    case SET_CUSTOMER_STATUS_TOTAL:
      return {
        ...state,
        customerStatusTotal: action.customerStatusTotal,
      };
    case SET_CUSTOMER_LOCATIONS:
      return {
        ...state,
        customerLocations: action.customerLocations,
      };
    case SET_CUSTOMER_STATUSES:
      return {
        ...state,
        customerStatuses: action.customerStatuses,
      };
    default:
      return state;
  }
};

export default StatisticsReducer;
