import React, { useEffect, useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useDispatch } from 'react-redux';

import AuthRoute from '../AuthRoute';
import Dashboard from '../../pages/Admin/Dashboard';
import CustomerProfile from '../../pages/Admin/CustomerProfile';
import UsersList from '../../pages/Admin/UsersList';
import DuePlans from '../../pages/Admin/DuePlans';
import Payments from '../../pages/Admin/Payments';
import OverdueInstallments from '../../pages/Admin/OverdueInstallments';
import Newsletters from '../../pages/Admin/Newsletters';
import Support from '../../pages/Admin/Support';
import Notifications from '../../pages/Admin/Notifications';
import Settings from '../../pages/Admin/Settings';
import Login from '../../pages/Login';
import UpdatePassword from '../../pages/Admin/UpdatePassword';
import Reports from '../../pages/Admin/Reports';

import { AdminRouterProps } from './types';
import { Roles } from '../../lib/types/Auth';
import { getAdminProfile } from '../../lib/api/admin/profile';

const AdminRouter: React.FC<AdminRouterProps> = ({
  match,
  role,
}) => {
  const loggedIn = role !== Roles.DEFAULT;
  const dispatch = useDispatch();

  const getProfileData = useCallback(() => {
    if (loggedIn && role !== Roles.CUSTOMER) dispatch(getAdminProfile());
  }, [dispatch, loggedIn, role]);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  return (
    <Switch>
      <AuthRoute role={role} requiresOneOfRole={[Roles.MASTER_ADMIN, Roles.ADMIN]} path={`${match.path}/dashboard`}>
        <Dashboard />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.MASTER_ADMIN, Roles.ADMIN]} path={`${match.path}/users`}>
        <UsersList />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.MASTER_ADMIN, Roles.ADMIN]} path={`${match.path}/user/:userId/:tab/:planId?/:customerId?`}>
        <CustomerProfile />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.MASTER_ADMIN, Roles.ADMIN]} path={`${match.path}/due-plans`}>
        <DuePlans />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.MASTER_ADMIN, Roles.ADMIN]} path={`${match.path}/payments`}>
        <Payments />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.MASTER_ADMIN, Roles.ADMIN]} path={`${match.path}/overdue-installments`}>
        <OverdueInstallments />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.MASTER_ADMIN, Roles.ADMIN]} path={`${match.path}/newsletters`}>
        <Newsletters />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.MASTER_ADMIN, Roles.ADMIN]} path={`${match.path}/support`}>
        <Support />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.MASTER_ADMIN, Roles.ADMIN]} path={`${match.path}/notifications`}>
        <Notifications />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.MASTER_ADMIN, Roles.ADMIN]} path={`${match.path}/settings/:tab`}>
        <Settings />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.MASTER_ADMIN, Roles.ADMIN]} path={`${match.path}/update-password`}>
        <UpdatePassword />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.MASTER_ADMIN, Roles.ADMIN]} path={`${match.path}/reports`}>
        <Reports />
      </AuthRoute>
      <Route path={`${match.path}`}>
        {role === Roles.MASTER_ADMIN && <Redirect to="/admin/dashboard" />}
        {role === Roles.ADMIN && <Redirect to="/admin/users" />}
        <Login variant="admin-login" />
      </Route>
    </Switch>
  );
};

export default AdminRouter;
