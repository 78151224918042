import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import useStrings from '../../../hooks/useStrings';
import { roundNum } from '../../../lib/utils';
import { deferPayment, getPayments, getPlanPayments, reverseDeferredPayment } from '../../../lib/api/admin/payments';
import { setNotification } from '../../../lib/store/contexts/notification/actions';

import ConfirmModal from '../ConfirmModal';

import {
  DeferPayment,
  PaymentAmountWrapper,
  PaymentAmountText,
  PaymentAmount,
  NoRemainingPaymentsText,
} from './Styles';

import { DeferPaymentModalProps } from './types';
import { InstallmentStatus } from '../../../lib/types/Plan';
import { APIThunkDispatch } from '../../../lib/types/API';

const DeferPaymentModal: React.FC<DeferPaymentModalProps> = ({
  userId,
  planId,
  installmentId,
  installmentValue,
  onClose,
  cacheToUpdate,
  filter,
  status,
  reversal = false,
}) => {
  const Dispatch: APIThunkDispatch = useDispatch();
  const [{ Components: { Common: { DeferPaymentModal: Strings } }, GenericText }] = useStrings();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [remainingPayments, setRemainingPayments] = useState(0);
  const [currentInstalmentValue, setCurrentInstalmentValue] = useState(0);
  const [newInstalmentValue, setNewInstalmentValue] = useState(0);

  const handleCacheUpdate = () => {
    switch (cacheToUpdate) {
      case 'overdueInstallments':
        Dispatch<void>(getPayments(filter, 'OVERDUE_INSTALLMENTS'));
        break;
      case 'payments':
        Dispatch<void>(getPayments(filter));
        break;
      case 'selectedCustomer':
        Dispatch<void>(getPayments(filter));
        break;
      default: break;
    }
  };

  const handleConfirmDeferPayment = () => {
    Dispatch(deferPayment(installmentId, status)).then(({ success }) => {
      if (success) {
        onClose();
        Dispatch(setNotification({ message: status === InstallmentStatus.REJECTED ? Strings.rejectPaymentSuccess : Strings.skipPaymentSuccess, variant: 'success' }));
        handleCacheUpdate();
      }
    }).catch(() => {});
  };

  const handleConfirmReverseDeferPayment = () => {
    Dispatch(reverseDeferredPayment(installmentId)).then(({ success }) => {
      if (success) {
        onClose();
        Dispatch(setNotification({ message: Strings.paymentDeferralReversedSuccess, variant: 'success' }));
        handleCacheUpdate();
      }
    }).catch(() => {});
  };

  const handleDeferPayment = useCallback(() => {
    async function defer() {
      const queryParams = { userId, planId };
      await Dispatch(getPlanPayments(queryParams)).then((response) => {
        if (!response.data) return;
        // All unpaid payments excluding self if deferring or including self if reversing deferral
        const unpaidPayments = response?.data?.filter((payment) => (
          reversal
            ? payment.installmentStatus === InstallmentStatus.UNPAID || payment.installmentId === installmentId
            : payment.installmentStatus === InstallmentStatus.UNPAID && payment.installmentId !== installmentId
        )).sort((a, b) => dayjs(a.dueDate).diff(dayjs(b.dueDate)));

        // paid amount minus admin fees
        const remainingPlanValue = response?.data
          ?.filter((payment) => (payment.installmentStatus === InstallmentStatus.UNPAID))
          ?.map((payment) => roundNum(payment.installmentValue) - roundNum(payment.adminFee))
          ?.reduce((a, b) => a + b);

        const totalAdminFees = response?.data
          ?.filter((payment) => (payment.installmentStatus === InstallmentStatus.UNPAID))
          ?.map((p) => p.adminFee)
          ?.reduce((a, b) => a + b);

        const newAdminFee = roundNum(totalAdminFees / unpaidPayments.length);
        const newInstallmentVal = roundNum(remainingPlanValue / unpaidPayments.length + newAdminFee);

        const currentInstallmentValue = unpaidPayments
          ?.[unpaidPayments?.length - 1]?.installmentValue || installmentValue;

        setRemainingPayments(unpaidPayments.length);
        setCurrentInstalmentValue(currentInstallmentValue);
        setNewInstalmentValue(newInstallmentVal);
      }).catch(() => {});
      setIsModalOpen(true);
    }
    defer().catch(() => {});
  }, [Dispatch, reversal, installmentValue, installmentId, planId, userId]);

  useEffect(() => {
    handleDeferPayment();
  }, [handleDeferPayment]);

  return (
    <ConfirmModal
      isOpen={isModalOpen}
      onConfirm={reversal ? handleConfirmReverseDeferPayment : handleConfirmDeferPayment}
      onCancel={onClose}
      title={status === InstallmentStatus.REJECTED ? Strings.rejectHeading : Strings.skipHeading}
      subtext={Strings.deferSubtext.replace('%', remainingPayments.toString())}
      cancelButtonText={GenericText.cancel}
      confirmButtonText={GenericText.confirm}
    >
      <DeferPayment>
        {remainingPayments === 0
          ? <NoRemainingPaymentsText>{Strings.noRemainingPaymentsError}</NoRemainingPaymentsText> : (
            <>
              <PaymentAmountWrapper>
                <PaymentAmountText>{Strings.currentInstalmentText}</PaymentAmountText>
                <PaymentAmount>${currentInstalmentValue.toFixed(2)}</PaymentAmount>
              </PaymentAmountWrapper>
              <PaymentAmountWrapper outlined>
                <PaymentAmountText>{Strings.newInstalmentText}</PaymentAmountText>
                <PaymentAmount>${newInstalmentValue.toFixed(2)}</PaymentAmount>
              </PaymentAmountWrapper>
            </>
          )}
      </DeferPayment>

    </ConfirmModal>
  );
};

export default DeferPaymentModal;
