import { AnyAction, CombinedState, combineReducers } from 'redux';

import { CustomerState, CustomerActionTypes } from './customers/types';
import { PlanState, PlanActionTypes } from './plans/types';
import { PaymentState, PaymentActionTypes } from './payments/types';
import { OverdueInstallmentState, OverdueInstallmentActionTypes } from './overdueInstallments/types';
import {
  PlanState as SelectedCustomerPlanState,
  PlanActionTypes as SelectedCustomerPlanActionTypes,
} from './selectedCustomer/plans/types';
import {
  ArchivedPlanState as SelectedCustomerArchivedPlanState,
  ArchivedPlanActionTypes as SelectedCustomerArchivedPlanActionTypes,
} from './selectedCustomer/archivedPlans/types';
import {
  PaymentState as SelectedCustomerPaymentState,
  PaymentActionTypes as SelectedCustomerPaymentActionTypes,
} from './selectedCustomer/payments/types';
import {
  PayablePlanState as SelectedCustomerPayablePlanState,
  PayablePlanActionTypes as SelectedCustomerPayablePlanActionTypes,
} from './selectedCustomer/payablePlans/types';
import {
  TransferablePlanState as SelectedCustomerTransferablePlanState,
  TransferablePlanActionTypes as SelectedCustomerTransferablePlanActionTypes,
} from './selectedCustomer/transferablePlans/types';
import {
  StatisticsState,
  StatisticsActionTypes,
} from './statistics/types';
import {
  AdminUserState,
  AdminUserActionTypes,
} from './user/types';

import customerReducer from './customers/reducers';
import planReducer from './plans/reducers';
import paymentReducer from './payments/reducers';
import overdueInstallmentReducer from './overdueInstallments/reducers';
import selectedCustomerPlanReducer from './selectedCustomer/plans/reducers';
import selectedCustomerArchivedPlanReducer from './selectedCustomer/archivedPlans/reducers';
import selectedCustomerPaymentReducer from './selectedCustomer/payments/reducers';
import selectedCustomerPayablePlanReducer from './selectedCustomer/payablePlans/reducers';
import selectedCustomerTransferablePlanReducer from './selectedCustomer/transferablePlans/reducers';
import CustomerNotesReducer from './customerNotes/reducers';
import { CustomerNoteActionTypes, CustomerNotesState } from './customerNotes/types';
import statisticsReducer from './statistics/reducers';
import adminUserReducer from './user/reducers';
import TotalCreditsReducer from './selectedCustomer/totalCredits/reducers';
import { TotalCreditsState } from './selectedCustomer/totalCredits/types';
import { SelectedPlanTransfersActionTypes, SelectedPlanTransfersState } from './selectedPlanTransfers/types';
import SelectedPlanTransfersReducer from './selectedPlanTransfers/reducers';
import { SelectedPlanActionTypes, SelectedPlanState } from './selectedCustomer/selectedPlan/types';
import SelectedPlanReducer from './selectedCustomer/selectedPlan/reducers';
import CustomerCreditHistoryReducer from './selectedCustomer/creditHistory/reducers';
import { CreditHistoryActionTypes, CustomerCreditHistoryState } from './selectedCustomer/creditHistory/types';
import { SelectedAdminActionTypes, SelectedAdminState } from './selectedAdmin/types';
import SelectedAdminReducer from './selectedAdmin/reducers';

export interface AdminState {
  customerState: CustomerState;
  planState: PlanState;
  paymentState: PaymentState;
  customerNotesState: CustomerNotesState;
  overdueInstallmentState: OverdueInstallmentState;
  selectedCustomerPlanState: SelectedCustomerPlanState,
  selectedCustomerArchivedPlanState: SelectedCustomerArchivedPlanState,
  selectedCustomerPaymentState: SelectedCustomerPaymentState,
  selectedCustomerPayablePlanState: SelectedCustomerPayablePlanState,
  selectedCustomerTransferablePlanState: SelectedCustomerTransferablePlanState,
  selectedCustomerTotalCreditsState: TotalCreditsState,
  selectedPlanTransfersState: SelectedPlanTransfersState,
  statisticsState: StatisticsState,
  adminUserState: AdminUserState,
  selectedAdminState: SelectedAdminState,
  selectedPlanState: SelectedPlanState,
  selectedCustomerCreditHistoryState: CustomerCreditHistoryState,
}

const adminReducer = combineReducers<AdminState>({
  customerState: customerReducer,
  planState: planReducer,
  paymentState: paymentReducer,
  customerNotesState: CustomerNotesReducer,
  overdueInstallmentState: overdueInstallmentReducer,
  selectedCustomerPlanState: selectedCustomerPlanReducer,
  selectedCustomerArchivedPlanState: selectedCustomerArchivedPlanReducer,
  selectedCustomerPaymentState: selectedCustomerPaymentReducer,
  selectedCustomerPayablePlanState: selectedCustomerPayablePlanReducer,
  selectedCustomerTransferablePlanState: selectedCustomerTransferablePlanReducer,
  selectedCustomerTotalCreditsState: TotalCreditsReducer,
  selectedPlanTransfersState: SelectedPlanTransfersReducer,
  statisticsState: statisticsReducer,
  adminUserState: adminUserReducer,
  selectedAdminState: SelectedAdminReducer,
  selectedPlanState: SelectedPlanReducer,
  selectedCustomerCreditHistoryState: CustomerCreditHistoryReducer,
});

const adminRootReducer = (
  state: CombinedState<AdminState> | undefined,
  action: AnyAction,
): CombinedState<AdminState> => {
  const clearState: CombinedState<AdminState> | undefined = state;
  return adminReducer(clearState, action);
};

export type AdminActionTypes =
  | CustomerActionTypes
  | PlanActionTypes
  | PaymentActionTypes
  | OverdueInstallmentActionTypes
  | SelectedCustomerPlanActionTypes
  | SelectedCustomerArchivedPlanActionTypes
  | SelectedCustomerPaymentActionTypes
  | SelectedCustomerPayablePlanActionTypes
  | SelectedCustomerTransferablePlanActionTypes
  | SelectedPlanTransfersActionTypes
  | CustomerNoteActionTypes
  | StatisticsActionTypes
  | AdminUserActionTypes
  | SelectedPlanActionTypes
  | CreditHistoryActionTypes
  | SelectedAdminActionTypes;

export default adminRootReducer;
