import {
  SET_ALL_PAYMENT_DATA,
  SET_UPDATED_PAYMENT_DATA,
  UPDATE_INSTALLMENT_STATUS,
  CREATE_PAYMENT,
  CLEAR_SELECTED_CUSTOMER_PAYMENTS,
  SET_PAYMENT_FILTER_PLANS,
  SET_SELECTED_PLAN,
  SET_NEEDS_UPDATE,
  PaymentActionTypes,
  PaymentState,
} from './types';

import {
  setUpdatedRecordsInState,
  updateInstallmentStatusInState,
} from '../../../../utils';

const initialState: PaymentState = {
  paymentData: {
    payments: [],
    count: 0,
  },
  selectedPlan: undefined,
  paymentFilterPlans: [],
  needsUpdate: false,
};

const PaymentReducer = (
  state: PaymentState = initialState,
  action: PaymentActionTypes,
): PaymentState => {
  switch (action.type) {
    case SET_ALL_PAYMENT_DATA:
      return {
        ...state,
        paymentData: {
          payments: action.paymentData.results,
          count: action.paymentData.count,
        },
      };
    case SET_UPDATED_PAYMENT_DATA:
      return {
        ...state,
        paymentData: {
          payments: setUpdatedRecordsInState('installmentId', state.paymentData.payments, action.payments),
          count: state.paymentData.count,
        },
      };
    case CREATE_PAYMENT:
      return {
        ...state,
        paymentData: {
          payments: [...state.paymentData.payments, action.payment],
          count: state.paymentData.count + 1,
        },
      };
    case UPDATE_INSTALLMENT_STATUS:
      return {
        ...state,
        paymentData: {
          payments: updateInstallmentStatusInState(
            action.installmentId,
            action.installmentStatus,
            state.paymentData.payments,
          ),
          count: state.paymentData.count,
        },
      };
    case CLEAR_SELECTED_CUSTOMER_PAYMENTS:
      return {
        ...state,
        paymentData: {
          payments: [],
          count: 0,
        },
      };
    case SET_PAYMENT_FILTER_PLANS:
      return {
        ...state,
        paymentFilterPlans: action.plans,
      };
    case SET_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.planId,
      };
    case SET_NEEDS_UPDATE:
      return {
        ...state,
        needsUpdate: action.needsUpdate,
      };
    default:
      return state;
  }
};

export default PaymentReducer;
