import { setUpdatedRecordsInState } from '../../../utils';

import {
  SET_ALL_PLAN_DATA,
  SET_UPDATED_PLAN_DATA,
  PlanActionTypes,
  PlanState,
} from './types';

const initialState: PlanState = {
  planData: {
    plans: [],
    count: 0,
  },
};

const PlanReducer = (
  state: PlanState = initialState,
  action: PlanActionTypes,
): PlanState => {
  switch (action.type) {
    case SET_ALL_PLAN_DATA:
      return {
        ...state,
        planData: {
          plans: action.planData.results,
          count: action.planData.count,
        },
      };
    case SET_UPDATED_PLAN_DATA:
      return {
        ...state,
        planData: {
          plans: setUpdatedRecordsInState('planId', state.planData.plans, action.plans),
          count: state.planData.count,
        },
      };
    default:
      return state;
  }
};

export default PlanReducer;
