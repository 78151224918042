import React, { useState } from 'react';
import DatePicker from 'react-date-picker';

import {
  StyledDatePicker,
  MetaWrapper,
  Icon,
  Label,
  Error,
} from './Styles';

import { DatePickerProps } from './types';
import { blueCaretDown } from '../../../assets/Icons';

const DatePickerComponent: React.FC<DatePickerProps> = ({
  onLoadDate,
  label,
  disabled = false,
  minDate,
  maxDate,
  touched,
  error,
  margin,
  onSelect,
  setError,
  setTouched,
  selectRange = false,
  outlined = false,
  yearOnly = false,
}) => {
  const [selected, setSelected] = useState<Date| undefined>(onLoadDate && new Date(onLoadDate));
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (date: Date | Date[]) => {
    const val = Array.isArray(date) ? date[0] : date;
    setSelected(val);
    onSelect(val);
    if (setError) setError();
    if (setTouched) setTouched(true);
  };

  return (
    // Formik thinks this is a date type?
    <StyledDatePicker
      error={!!error && !!touched}
      disabled={disabled}
      open={open}
      onClick={() => {
        if (!open) setOpen(true);
      }}
      margin={margin}
      outlined={outlined}
      dateSelected={!!selected}
    >
      {label && (
        <MetaWrapper>
          <Label>{label}</Label>
        </MetaWrapper>
      )}
      <DatePicker
        value={selected}
        clearIcon={null}
        disabled={disabled}
        calendarIcon={<Icon disabled={disabled} open={open} src={blueCaretDown} />}
        onChange={handleChange}
        onCalendarOpen={() => setOpen(true)}
        onCalendarClose={() => setOpen(false)}
        minDate={minDate}
        maxDate={maxDate}
        isOpen={open}
        monthPlaceholder={yearOnly ? '' : 'MM'}
        dayPlaceholder={yearOnly ? '' : 'DD'}
        yearPlaceholder="YYYY"
        format={yearOnly ? 'y' : 'dd/MM/y'}
        maxDetail={yearOnly ? 'decade' : 'month'}
        selectRange={selectRange}
      />
      {touched && error && <Error className="error">{error}</Error>}
    </StyledDatePicker>
  );
};

export default DatePickerComponent;
