import { PaymentDataView } from '../../../../types/DBViews';
import { InstallmentStatus } from '../../../../types/Plan';

import {
  SetAllPaymentsAction,
  SetUpdatedPaymentsAction,
  UpdateInstallmentStatusAction,
  SET_ALL_PAYMENT_DATA,
  SET_UPDATED_PAYMENT_DATA,
  UPDATE_INSTALLMENT_STATUS,
} from './types';

export const setAllPaymentData = (
  paymentData: {
    results: PaymentDataView[];
    count: number;
  },
): SetAllPaymentsAction => ({
  type: SET_ALL_PAYMENT_DATA,
  paymentData,
});

export const setUpdatedPaymentData = (
  payments: PaymentDataView[],
): SetUpdatedPaymentsAction => ({
  type: SET_UPDATED_PAYMENT_DATA,
  payments,
});

export const updateInstallmentStatus = (
  installmentId: number,
  installmentStatus: InstallmentStatus,
): UpdateInstallmentStatusAction => ({
  type: UPDATE_INSTALLMENT_STATUS,
  installmentId,
  installmentStatus,
});
