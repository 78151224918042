import * as yup from 'yup';
import dayjs from 'dayjs';

import { InstallmentFrequency, RegistrationLength } from '../types/Plan';
import { State } from '../types/Customer';
import { VehicleType } from '../types/Vehicle';
import { CONTACT_NUMBER } from '../../CONSTANTS';

const planDetailsSchema = yup.object({
  value: yup
    .number()
    .nullable()
    .typeError('Must be a number')
    .required('Cost is required'),
  registrationLength: yup
    .string()
    .trim()
    .typeError('Please select registration length')
    .oneOf(Object.values(RegistrationLength))
    .required('Registration length is required'),
  vehicle: yup
    .object({
      type: yup
        .string()
        .trim()
        .oneOf(Object.values(VehicleType))
        .required('Type is required'),
      make: yup.string().when('type', {
        is: (val: string) => (val !== VehicleType.CAR && val !== VehicleType.MOTORCYCLE),
        then: yup.string().required('Make is required'),
      }),
      vehicleMakeId: yup.number().when('type', {
        is: (val: string) => (val === VehicleType.CAR || val === VehicleType.MOTORCYCLE),
        then: yup.number().required('Make is required'),
      }),
      model: yup
        .string()
        .trim()
        .required('Model is required'),
      year: yup
        .date()
        .nullable()
        .required('Vehicle year is required'),
      registeredState: yup
        .string()
        .trim()
        .typeError('Please select state')
        .oneOf(Object.values(State))
        .required('State is required'),
      color: yup
        .string()
        .trim()
        .required('Colour is required'),
      registration: yup
        .string()
        .trim()
        .required('Registration is required'),
    }).required(),
  startDate: yup
    .date()
    .nullable()
    .required('Start date is required'),
  dueDate: yup
    .date()
    .nullable()
    .required('Due date is required')
    .when('startDate', (startDate: Date) => startDate && (
      yup
        .date()
        .min(dayjs(startDate).add(6, 'weeks').toDate(), `Plan is due too soon, please call us on ${CONTACT_NUMBER}`)
        .required('Due date is required')
    )),
  installmentFrequency: yup
    .string()
    .trim()
    .typeError('Please select an installment frequency')
    .oneOf(Object.values(InstallmentFrequency))
    .required('Frequency is required'),
  name: yup
    .string()
    .trim(),
}).required();

export type PlanDetailsSchema = yup.InferType<typeof planDetailsSchema>;

export default planDetailsSchema;
