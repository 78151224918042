import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ForgotPassword from '../pages/ForgotPassword';
import Login from '../pages/Login';

import AdminRouter from './AdminRouter';
import CustomerRouter from './CustomerRouter';
import OnboardingRouter from './OnboardingRouter';
import PromoCalculator from '../pages/PromoCalculator';

import { RoutesProps } from './types';
import { Roles } from '../lib/types/Auth';
import { CustomerStatus } from '../lib/types/Customer';
import CreatePassword from '../pages/CreatePassword';

const Routes: React.FC<RoutesProps> = ({ permissions, role, profileData }) => {
  const renderCustomerRouter = !!profileData && profileData?.status !== CustomerStatus.NO_PLANS
    && profileData?.status !== CustomerStatus.PROFILE_INCOMPLETE;
  const renderOnboardingRouter = !!profileData && (profileData?.status === CustomerStatus.NO_PLANS
    || profileData?.status === CustomerStatus.PROFILE_INCOMPLETE);
  const renderCreatePassword = !!profileData && !profileData.termsAccepted;
  const loggedIn = role !== Roles.DEFAULT;

  return (
    <Switch>
      <Route path="/calculator">
        <PromoCalculator />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route
        path="/sign-up"
        render={(props) => (
          <>
            {renderOnboardingRouter && loggedIn && (
              <OnboardingRouter {...props} role={role} permissions={permissions} />
            )}
            {(role === Roles.ADMIN || role === Roles.MASTER_ADMIN) && (
              <AdminRouter {...props} role={role} permissions={permissions} />
            )}
            {renderCustomerRouter && loggedIn && (
              <CustomerRouter {...props} role={role} permissions={permissions} profileData={profileData} />
            )}
            {!loggedIn && (
              <Login variant="customer-signup" />
            )}
          </>
        )}
      />
      <Route
        path="/customer"
        render={(props) => (
          <>
            {renderCreatePassword && loggedIn && (
              <CreatePassword />
            )}
            {renderCustomerRouter && loggedIn && !renderCreatePassword && (
              <CustomerRouter {...props} role={role} permissions={permissions} profileData={profileData} />
            )}
            {renderOnboardingRouter && loggedIn && !renderCreatePassword && (
              <OnboardingRouter {...props} role={role} permissions={permissions} />
            )}
            {!loggedIn && (
              <Redirect to="/" />
            )}
          </>
        )}
      />
      <Route
        path="/admin"
        render={(props) => (
          <AdminRouter {...props} role={role} permissions={permissions} />
        )}
      />
      <Route path="/">
        {role === Roles.CUSTOMER && <Redirect to="/customer/dashboard" />}
        {role === Roles.MASTER_ADMIN && <Redirect to="/admin/dashboard" />}
        {role === Roles.ADMIN && <Redirect to="/admin/users" />}
        {role === Roles.DEFAULT && <Redirect to="/" />}
        <Login variant="customer-login" />
      </Route>
      <Route path="/sign-up">
        {renderOnboardingRouter && loggedIn && <Redirect to="/sign-up/profile" />}
        <Login variant="customer-signup" />
      </Route>
    </Switch>
  );
};

export default Routes;
