/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import useStrings from '../../../../hooks/useStrings';
import useAuth from '../../../../hooks/useAuth';
import { getAdmins, createAdmin, disableAdmin, editAdmin } from '../../../../lib/api/admin/admins';
import { CreateAdminSchema } from '../../../../lib/validators/createAdminSchema';

import AddAdminModal from './AddAdminModal';
import Button from '../../../../components/Common/Button';
import DeleteModal from '../../../../components/Common/DeleteModal';

import { closeBlack } from '../../../../assets/Icons';
import {
  AdminName,
  AdminStatus,
  AdminCardsWrapper,
  AdminCard,
  DeleteIcon,
  TextWrapper,
  ImageContainer,
  ProfileImage,
  ProfileInitials,
} from './Styles';
import {
  Wrapper,
  HeaderWrapper,
  TabSubTitle,
  TabTitle,
  ContentWrapper,
} from '../Styles';
import { Spinner } from '../../../../components/UI/LoadingScreen/Styles';
import { NoRecordsText } from '../../../../components/Common/Table/Styles';

import { AdminAccountsProps } from './types';
import { APIThunkDispatch } from '../../../../lib/types/API';
import { User } from '../../../../lib/types/DBModels';
import { Roles } from '../../../../lib/types/Auth';
import { setSelectedAdmin } from '../../../../lib/store/contexts/admin/selectedAdmin/actions';
import { EditAdminSchema } from '../../../../lib/validators/editAdminSchema';

const AdminAccounts: React.FC<AdminAccountsProps> = ({
  user,
}) => {
  const [{ Pages: { Settings: { AdminAccounts: adminAccountsStrings } } }] = useStrings();
  const [addAdminOpen, setAddAdminOpen] = useState(false);
  const [editAdminOpen, setEditAdminOpen] = useState(false);
  const [deleteAdminOpen, setDeleteAdminOpen] = useState<string | null>(); // userId of admin to be deleted.
  const Dispatch: APIThunkDispatch = useDispatch();

  const [adminUserData, setAdminUserData] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);

  const { role } = useAuth();

  // Admin users currently sorted by email, changing this util will alter in all places
  // the list gets updated
  const sortAdmins = useCallback((adminUserA: User, adminUserB: User) => {
    if (adminUserA.email < adminUserB.email) { return -1; }
    if (adminUserA.email > adminUserB.email) { return 1; }
    return 0;
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await Dispatch(getAdmins());
      if (data) {
        // Do not include own user
        const adminsWithoutSelf = data.admins.filter((entry) => entry.userId !== user.userId);
        const sortedAdmins = adminsWithoutSelf.sort(sortAdmins);
        setAdminUserData(sortedAdmins);
      }
      setLoading(false);
    })().catch(() => null);
  }, [user, Dispatch, sortAdmins]);

  const handleCreateAdmin = async (values: CreateAdminSchema) => {
    const { data } = await Dispatch(createAdmin(values));
    if (data) {
      const allAdmins = [...adminUserData, data.admin].sort(sortAdmins);
      setAdminUserData(allAdmins);
      setAddAdminOpen(false);
    }
  };
  const handleEditAdmin = async (values: EditAdminSchema) => {
    const { data } = await Dispatch(editAdmin(values));
    if (data) {
      const adminsWithoutEdited = adminUserData.filter((admin) => admin.userId !== data.admin.userId);
      const allAdmins = [...adminsWithoutEdited, data.admin];
      const sortedAdmins = allAdmins.sort(sortAdmins);
      setAdminUserData(sortedAdmins);
      setEditAdminOpen(false);
    }
  };
  const handleDeleteAdmin = async (userId: string) => {
    setIsDeleting(true);
    const { data } = await Dispatch(disableAdmin({ userId }));
    if (data) {
      setAdminUserData(adminUserData.filter((entry) => entry.userId !== data.admin.userId).sort(sortAdmins));
      setDeleteAdminOpen(null);
    }
    setIsDeleting(false);
  };

  const openAddModal = useCallback(() => {
    // clear selected admin user
    Dispatch(setSelectedAdmin(null));
    setAddAdminOpen(true);
  }, [Dispatch]);

  const openEditModal = useCallback((adminUser: User) => {
    // set selected admin user
    Dispatch(setSelectedAdmin(adminUser));
    setEditAdminOpen(true);
  }, [Dispatch]);

  const getContent = () => {
    if (loading) return <Spinner />;
    if (!adminUserData.length) {
      return (
        <NoRecordsText>{adminAccountsStrings.noAdmins}</NoRecordsText>
      );
    }
    return adminUserData.map((admin) => {
      const initials = `
      ${admin.firstName.charAt(0).toUpperCase()}${admin.lastName.charAt(0).toUpperCase()}
    `;
      return (
        <AdminCard onClick={() => openEditModal(admin)} key={admin.userId}>
          <ImageContainer>
            <ProfileImage>
              <ProfileInitials>{initials}</ProfileInitials>
            </ProfileImage>
          </ImageContainer>
          <TextWrapper>
            <AdminName>{`${admin.firstName} ${admin.lastName}`}</AdminName>
            <AdminStatus>{admin.email}</AdminStatus>
            <AdminStatus>{admin.role.label}</AdminStatus>
          </TextWrapper>
          {role === Roles.MASTER_ADMIN
          && (<DeleteIcon src={closeBlack} onClick={() => setDeleteAdminOpen(admin.userId)} />)}
        </AdminCard>
      );
    });
  };

  return (
    <>
      {addAdminOpen
      && <AddAdminModal onCancel={() => setAddAdminOpen(false)} onConfirm={handleCreateAdmin} />}
      {editAdminOpen
      && <AddAdminModal onCancel={() => setEditAdminOpen(false)} onConfirm={handleEditAdmin} />}
      <DeleteModal
        isOpen={!!deleteAdminOpen}
        title={adminAccountsStrings.DeleteAdminModal.title}
        subtext={adminAccountsStrings.DeleteAdminModal.subtext}
        cancelButtonText={adminAccountsStrings.DeleteAdminModal.cancelButton}
        confirmButtonText={adminAccountsStrings.DeleteAdminModal.confirmButton}
        onCancel={() => setDeleteAdminOpen(null)}
        onConfirm={async () => {
          if (deleteAdminOpen) await handleDeleteAdmin(deleteAdminOpen);
        }}
        isDeleting={isDeleting}
      />
      <Wrapper>
        <HeaderWrapper>
          <div>
            <TabTitle>{adminAccountsStrings.tabTitle}</TabTitle>
            <TabSubTitle>{adminAccountsStrings.tabSubtitle}</TabSubTitle>
          </div>
          {role === Roles.MASTER_ADMIN && (
          <Button
            variant="primary"
            onClick={openAddModal}
            rounded
            height="48px"
          >
            {adminAccountsStrings.addNewButton}
          </Button>
          )}
        </HeaderWrapper>
        <ContentWrapper>
          <AdminCardsWrapper>
            {getContent()}
          </AdminCardsWrapper>
        </ContentWrapper>
      </Wrapper>
    </>
  );
};

export default AdminAccounts;
