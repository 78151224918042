import styled from 'styled-components';

export const StyledTextArea = styled.textarea`
outline: none;
border: none;
resize: none;
font-family: ${({ theme }) => theme.fontFamily.bodyLight};
font-size: ${({ theme }) => theme.fontScale.M}rem;
font-weight: ${({ theme }) => theme.fontWeight.light};
padding-bottom: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const NoteFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: auto;
  width: 100%;
`;

export const FileTabOutterWrapper = styled.div`
margin-right: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const FileUploadIcon = styled.img`
padding: ${({ theme }) => theme.spacing.XXS * 1.2}rem;
margin-right: ${({ theme }) => theme.spacing.XXS}rem;
border: 1px solid ${({ theme }) => theme.basePalette.greyMain};
border-radius: 16px;
&:hover {
  transform: scale(1.02);
  cursor: pointer;
}
`;