import styled from 'styled-components/macro';

import { ColProps, RowProps, GridProps } from './types';

export const Grid = styled.div<GridProps>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-width: ${({ maxWidth, theme }) => !!maxWidth && theme.container.maxWidth}rem;
  margin: 0 auto;

  ${({ theme }) => theme.device.tablet} {
    flex-direction: ${({ reversed }) => (reversed ? 'row-reverse' : 'row')};
    height: ${({ autoHeight = false }) => (autoHeight ? 'auto' : '100vh')};
    padding: ${({ theme, fullWidth = true }) => (fullWidth ? 0 : `0 ${theme.spacing.XXS}rem`)};
  }
`;

export const Row = styled.div<RowProps>`
  display: flex;  
  flex-direction:row;
  width: 100%;
  margin: ${(props) => (props.centerVertically ? 'auto' : '0')};
  ${({ padding = '' }) => (padding ? `padding: ${padding}` : '')};
`;

export const Col = styled.div<ColProps>`
  display: flex;  
  flex-direction:column;
  align-items: ${({ alignItems = 'inherit' }) => alignItems};
  flex: ${(props) => props.size || 1};
  ${({ padding = '' }) => (padding ? `padding: ${padding}` : '')};
`;
