import React from 'react';
import { Switch, Redirect, Route } from 'react-router';

import AuthRoute from '../AuthRoute';
import ContactUs from '../../pages/Customer/ContactUs';
import CreateProfile from '../../pages/Customer/CreateProfile';
import CreatePlan from '../../pages/Customer/CreatePlan';
import Dashboard from '../../pages/Customer/Dashboard';
import Payments from '../../pages/Customer/Payments';
import Receipts from '../../pages/Customer/Receipts';
import ReferFriend from '../../pages/Customer/ReferFriend';
import UpdatePassword from '../../pages/Customer/UpdatePassword';
import Login from '../../pages/Login';

import { CustomerRouterProps } from './types';
import { Roles } from '../../lib/types/Auth';
import { CustomerStatus } from '../../lib/types/Customer';

const CustomerRouter: React.FC<CustomerRouterProps> = ({
  match,
  role,
  profileData,
}) => {
  const loggedIn = role !== Roles.DEFAULT;

  return (
    <Switch>
      <AuthRoute role={role} requiresOneOfRole={[Roles.CUSTOMER]} path={`${match.path}/dashboard`}>
        {profileData?.status === CustomerStatus.NO_PLANS && (
          <Redirect to="/sign-up/create-plan" />
        )}
        {profileData?.status === CustomerStatus.PROFILE_INCOMPLETE && (
          <Redirect to="/sign-up/profile" />
        )}
        {profileData && <Dashboard profileData={profileData} />}
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.CUSTOMER]} path={`${match.path}/create-plan`}>
        <CreatePlan />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.CUSTOMER]} path={`${match.path}/payments`}>
        <Payments />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.CUSTOMER]} path={`${match.path}/receipts`}>
        <Receipts />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.CUSTOMER]} path={`${match.path}/edit-profile`}>
        <CreateProfile profileData={profileData} isEditProfile />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.CUSTOMER]} path={`${match.path}/update-password`}>
        <UpdatePassword />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.CUSTOMER]} path={`${match.path}/contact-us`}>
        <ContactUs />
      </AuthRoute>
      <AuthRoute role={role} requiresOneOfRole={[Roles.CUSTOMER]} path={`${match.path}/refer-friend`}>
        <ReferFriend />
      </AuthRoute>
      <Route path={`${match.path}`}>
        {loggedIn && <Redirect to={{ pathname: '/customer/dashboard' }} /> }
        <Login variant="customer-login" />
      </Route>
    </Switch>
  );
};

export default CustomerRouter;
