export interface SettingsProps {}

export enum SettingsView {
  EDIT_PROFILE = 'EDIT_PROFILE',
  // CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  ADMIN_ACCOUNTS = 'ADMIN_ACCOUNTS',
  // SYSTEM_MAINTENANCE = 'SYSTEM_MAINTENANCE',
}
export interface TabButtonProps {
  active: boolean;
}