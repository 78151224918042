import * as yup from 'yup';

const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Invalid Email Address')
    .required('Email is required'),
}).required();

export type ForgotPasswordSchema = yup.InferType<typeof forgotPasswordSchema>;
export default forgotPasswordSchema;
