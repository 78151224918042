import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import {
  StyledDropdown,
  IconWrapper,
  Icon,
} from './Styles';

import { DropdownProps } from './types';
import { blueCaretDown } from '../../../../../assets/Icons';

import { DropdownOption } from '../../../../../lib/types/Dropdown';

const Dropdown: React.FC<DropdownProps> = ({
  onLoadOption,
  options,
  disabled = false,
  onOptionClick,
}) => {
  const [value, setValue] = useState<DropdownOption | null>();

  const SelectIcon = () => (
    <IconWrapper>
      <Icon src={blueCaretDown} />
    </IconWrapper>
  );

  useEffect(() => {
    if (onLoadOption === undefined) return;
    const currentOption = options.find((option) => option.value === onLoadOption) || null;
    setValue(currentOption);
  }, [onLoadOption, options]);

  return (
    <StyledDropdown
      outlined
      disabled={disabled}
    >
      <Select
        classNamePrefix="react-select"
        options={options}
        disabled={disabled}
        onChange={(option) => option && onOptionClick(option.value)}
        value={value}
        isDisabled={disabled}
        components={{ DropdownIndicator: SelectIcon }}
      />
    </StyledDropdown>
  );
};

export default Dropdown;
