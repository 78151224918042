import React from 'react';
import dayjs from 'dayjs';

import {
  StyledReadOnlyField,
  TransferHeading,
  TransferAmountText,
  TransferAmountText1,
  TransferSubText,
} from './Styles';

import { PLAN_TYPE_DISPLAY } from '../../../CONSTANTS';
import { ModalPlanTransfersProps } from './types';
import useStrings from '../../../hooks/useStrings';

const ModalPlanTransfers: React.FC<ModalPlanTransfersProps> = ({
  transfer,
  planId,
}) => {
  const [{ GenericText }] = useStrings();

  const getPlanTransferText = () => {
    if (transfer.fromPlanId === planId && transfer.toPlan) {
      return transfer.toPlan?.vehicle?.registration
      || (transfer.toPlan?.name && transfer.toPlan?.name)
      || (transfer.toPlan?.type && PLAN_TYPE_DISPLAY[transfer.toPlan?.type]);
    }
    if (transfer.toPlanId === planId && transfer.fromPlan) {
      return transfer.fromPlan?.vehicle?.registration
      || (transfer.toPlan?.type && PLAN_TYPE_DISPLAY[transfer.toPlan?.type]);
    }
    return GenericText.Credit;
  };
  return (
    <StyledReadOnlyField>
      <TransferHeading>{transfer.toPlanId === planId ? GenericText.received : GenericText.transfer}</TransferHeading>
      <TransferAmountText>
        {`$${Math.abs(transfer.amount).toFixed(2)} `}
        <TransferAmountText1>{transfer.toPlanId === planId ? `${GenericText.from} ` : `${GenericText.to} `}</TransferAmountText1>
        {getPlanTransferText()}
      </TransferAmountText>
      <TransferSubText>{`${dayjs(transfer.updatedAt).format('DD/MM/YYYY')} ${transfer.createdByUser.firstName} ${transfer.createdByUser.lastName}`}</TransferSubText>

    </StyledReadOnlyField>
  );
};

export default ModalPlanTransfers;
