import styled from 'styled-components/macro';

export const ModalBackground = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const ModalWrapper = styled.div`
  width: 100vw;
  position: absolute;
  bottom: 0;
  background-color: ${({ theme }) => theme.basePalette.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: default;
  border-radius: 24px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

`;

export const ScrollingModal = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  @media (max-width: 1499px) {
    height: 75vh;
    overflow-y: scroll;
  };
`;

export const ModalTitle = styled.h4`
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-size: ${({ theme }) => theme.fontScale.M as number * 1.2}rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;

  @media (max-width: 520px) {
    flex-direction: column;
    align-self: center;
  }
`;

export const LineBreak = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.btnDarkGrey};
`;
