import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  LabelList,
  Cell,
  TooltipProps,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import StatsTooltip from '../StatsTooltip';

import { Container, TooltipText } from './Styles';

import { PlanTypesProps } from './types';
import { formatLargeNumber } from '../../../../lib/utils';
import { PLAN_TYPE_DISPLAY_SHORT } from '../../../../CONSTANTS';

const PlanTypes: React.FC<PlanTypesProps> = ({ data }) => {
  const theme = useTheme();
  const [currentBar, setCurrentBar] = useState<string | null>(null);
  const chartData = data.map(({ type, value }) => ({ name: type, value }));

  const labelStyle = {
    fill: theme.basePalette.navyMain,
    display: 'block',
    fontSize: `${theme.fontScale.XS}rem`,
  };

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <StatsTooltip>
          <TooltipText>{`${formatLargeNumber(payload[0].value as number)}`}</TooltipText>
        </StatsTooltip>
      );
    }
    return null;
  };

  return (
    <Container>
      <ResponsiveContainer width="100%" height="88%">
        <BarChart
          width={150}
          height={40}
          barSize={42}
          barGap={5}
          barCategoryGap={5}
          data={chartData}
          margin={{
            top: 10,
            bottom: 25,
          }}
          onMouseLeave={() => setCurrentBar(null)}
        >
          <Tooltip
            content={<CustomTooltip />}
            cursor={false}
          />
          <Bar
            isAnimationActive={false}
            dataKey="value"
            radius={5}
            onMouseOver={(props) => {
              setCurrentBar(props.name);
            }}
          >
            {chartData.map((item) => (
              <Cell
                key={item.name}
                fill={item.name === currentBar || currentBar === null ? theme.basePalette.blueMain : '#D7ECFB'}
              />
            ))}
            <LabelList
              offset={10}
              style={labelStyle}
              dataKey="name"
              position="bottom"
              formatter={(value: string) => ([PLAN_TYPE_DISPLAY_SHORT[value]])}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default PlanTypes;
