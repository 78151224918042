import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.basePalette.white};
  border-radius: 15px;
  padding: 2rem;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  width: 100%;
`;

export const Heading = styled.div`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Details = styled.div`
  color: ${({ theme }) => theme.basePalette.greyMedium};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 18px;
  font-style: italic;
  margin-top: 0.5rem;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
`;

export const LineBreak = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.basePalette.greyMain};
`;