import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getReferrals, defaultFilter } from '../../../../lib/api/admin/referrals';

import ReferralsTable from './ReferralsTable';

import { ReferralsProps } from './types';
import { APIFilterOptions, APIThunkDispatch } from '../../../../lib/types/API';
import { CustomerReferral } from '../../../../lib/types/DBModels';

const Referrals: React.FC<ReferralsProps> = ({ customer }) => {
  const dispatch: APIThunkDispatch = useDispatch();
  const [filter, setFilter] = useState<APIFilterOptions>(defaultFilter);
  const [referrals, setReferrals] = useState<{
    data: CustomerReferral[],
    count: number,
  }>({ data: [], count: 0 });

  const updateFilter = (newFilter: APIFilterOptions) => {
    setFilter({
      ...defaultFilter,
      ...newFilter,
    });
  };

  const currentData = referrals.data.map((referral) => ({ id: referral.customerReferralId, ...referral }));

  useEffect(() => {
    (async () => {
      const { data } = await dispatch(getReferrals(customer.customerId, filter));
      if (data) {
        setReferrals({
          data: data.referrals.results,
          count: data.referrals.count,
        });
      }
    })().catch(() => null);
  }, [dispatch, customer, filter]);

  return (
    <ReferralsTable
      currentData={currentData}
      recordCount={referrals.count}
      filter={filter}
      updateFilter={updateFilter}
    />
  );
};

export default Referrals;
