export const SET_CUSTOMER_TOTAL_CREDITS = 'SET_CUSTOMER_TOTAL_CREDITS';

export interface TotalCreditsState {
  totalCredits: number;
}
export interface SetCustomerTotalCredits {
  type: typeof SET_CUSTOMER_TOTAL_CREDITS;
  totalCredits: number;
}

export type TotalCreditActionTypes =
SetCustomerTotalCredits;