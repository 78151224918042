import styled from 'styled-components/macro';

// import { MainContentProps, BackgroundImageProps, BackgroundTextProps } from './types';

export const FormSection = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.spacing.M}rem 0;
  ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => `${theme.spacing.XXS}rem ${theme.spacing.XS}rem`};
  }
`;

export const LineBreak = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.borderGrey};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing.XS}rem ${theme.spacing.XXS}rem`};
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => `${theme.spacing.XXS * 1.5}rem ${theme.spacing.S}rem`};
    justify-content: flex-end;
  }
`;

export const SubText = styled.h3`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;