import React from 'react';

import { Formik } from 'formik';

import useStrings from '../../../../../hooks/useStrings';

import Input from '../../../../../components/Common/Input';
import Button from '../../../../../components/Common/Button';
import Dropdown from '../../../../../components/Common/Dropdown';

import {
  TransferFundsWrapper,
  TextWrapper,
  ButtonWrapper,
  Title,
  SubTitle,
} from './Styles';

import { TransferFundsProps } from './types';
import { PlanType } from '../../../../../lib/types/Plan';
import { PLAN_TYPE_DISPLAY } from '../../../../../CONSTANTS';

const TransferCredits: React.FC<TransferFundsProps> = ({ transferablePlans, customerCredit, onTransfer }) => {
  const [{
    Pages: { UserProfile: { Credit: { TransferCredits: TransferCreditsStrings } } },
    GenericText }] = useStrings();

  const planOptions = transferablePlans.map((plan) => ({ value: plan.planId?.toString(),
    label: `${PLAN_TYPE_DISPLAY[plan.planType]} ${plan?.planName && `- ${plan?.planName}`} (${plan.planType === PlanType.VEHICLE_REGISTRATION
      ? plan.vehicleRegistration
      : `$${plan.value}`})` }));

  return (
    <Formik
      initialValues={{
        amount: NaN,
        planTo: '',
      }}
      validate={({ amount, planTo }) => {
        const errors: { amount?: string, planTo?: string } = {};

        const planToData = transferablePlans.find((p) => (p.planId.toString() === planTo));

        if (!planTo) errors.planTo = GenericText.required;

        if (amount > customerCredit) errors.amount = TransferCreditsStrings.amountError;
        if (planToData
          && planToData.value - planToData.alreadyPaid < amount) errors.planTo = TransferCreditsStrings.planError;
        return errors;
      }}
      onSubmit={async ({ amount, planTo }, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        await onTransfer(amount, planTo);
        setSubmitting(false);
        resetForm();
      }}
      validateOnChange
    >
      {({ isSubmitting, setFieldValue, setFieldTouched, handleSubmit, resetForm, values }) => (
        <TransferFundsWrapper>
          <TextWrapper>
            <Title>
              {TransferCreditsStrings.title}
            </Title>
            <SubTitle>
              {TransferCreditsStrings.subTitle}
            </SubTitle>
          </TextWrapper>
          <Input
            name="amount"
            type="currency"
            label={TransferCreditsStrings.amountLabel}
            width="100%"
            placeholder={TransferCreditsStrings.amountPlaceholder}
          />
          <Dropdown
            name="planTo"
            options={planOptions}
            placeholder={GenericText.pleaseSelect}
            label={TransferCreditsStrings.planToLabel}
            onOptionClick={((option) => {
              setFieldValue('planTo', option);
              setFieldTouched('planTo', true, false);
            })}
            onLoadOption={values.planTo || ''}
          />

          <ButtonWrapper>
            <Button variant="secondary" onClick={resetForm} rounded>{GenericText.cancel}</Button>
            <Button
              onClick={() => handleSubmit()}
              rounded
              disabled={isSubmitting}
              $loading={isSubmitting}
            >{GenericText.transfer}
            </Button>
          </ButtonWrapper>
        </TransferFundsWrapper>
      )}
    </Formik>

  );
};

export default TransferCredits;