import styled from 'styled-components/macro';
import { ModalStyleProps, SuccessModalHeadingProps } from './types';

export const ModalBackground = styled.div<ModalStyleProps>`
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const ModalWrapper = styled.div<ModalStyleProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.basePalette.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: default;
  border-radius: 24px;
  padding: ${({ theme }) => `${theme.spacing.XS}rem ${theme.spacing.S}rem`};
  max-width: 480px;
  width: 90vw;
`;

export const ModalTitle = styled.h4<SuccessModalHeadingProps>`
  margin-top: ${({ theme }) => theme.spacing.XXS}rem;

  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontScale.XL as number * 1.2}rem;
  ${({ theme, highlight }) => highlight && `color: ${theme.palette.titleHighlight}`};
`;

export const ModalSubtext = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  text-align: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: ${({ theme }) => `${theme.spacing.XS}rem`};
`;

export const HeadingWrapper = styled.div`
  display: flex;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
