import {
  SET_CUSTOMER_TOTAL_CREDITS,
  TotalCreditActionTypes,
  TotalCreditsState,
} from './types';

const initialState: TotalCreditsState = {
  totalCredits: 0,
};

const TotalCreditsReducer = (
  state: TotalCreditsState = initialState,
  action: TotalCreditActionTypes,
): TotalCreditsState => {
  switch (action.type) {
    case SET_CUSTOMER_TOTAL_CREDITS:
      return {
        ...state,
        totalCredits: action.totalCredits,
      };
    default:
      return state;
  }
};

export default TotalCreditsReducer;
