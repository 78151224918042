import dayjs from 'dayjs';

import { installmentFrequencyOptions, PLAN_TYPE_DISPLAY } from '../../CONSTANTS';

import { TableRow } from '../../components/Common/Table/types';
import { PlanDataView } from '../types/DBViews';
import { PlanType } from '../types/Plan';

export const generatePlanFileName = (row: TableRow | PlanDataView): string => {
  // File name is determined from available plan information
  let fileName;
  if (row.planType === PlanType.VEHICLE_REGISTRATION) {
    fileName = row.registration as string;
  } else if (row.planName) fileName = row.planName as string;
  else fileName = PLAN_TYPE_DISPLAY[row.planType as string];
  return fileName;
};

export const getFrequencyOptions = (startDate?: Date, dueDate?: Date): Record<string, string>[] => {
  if (
    !startDate
    || !dueDate
    || dueDate < startDate
  ) return [];

  // If due date is = to start date, or the diff is less than the minimum 3 weeks for regular
  // installments
  if (dayjs(dueDate).diff(startDate, 'weeks') < 3 || dueDate === startDate) {
    // One off payments
    return [installmentFrequencyOptions[3]];
  }

  // if the due date and start date are less than a month different
  if (dayjs(dueDate).diff(dayjs(startDate).add(1, 'day'), 'month') < 1) {
    return [installmentFrequencyOptions[0], installmentFrequencyOptions[3]];
  }
  // if due date and start date are greater than or equal to 7 weeks apart (~2months)
  if (dayjs(dueDate).diff(dayjs(startDate).add(1, 'day'), 'weeks') >= 7) {
    return installmentFrequencyOptions;
  }
  return [installmentFrequencyOptions[0], installmentFrequencyOptions[1], installmentFrequencyOptions[3]];
};