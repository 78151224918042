import React, { useState } from 'react';

import { getAuthUser } from '../../../lib/Authentication';
import { imageHostingFilePaths } from '../../../CONSTANTS';
import { uploadMedia } from '../../../lib/Media';
import useStrings from '../../../hooks/useStrings';

import FileInputNotes from '../FileInputNotes';

import { closeWhite } from '../../../assets/Icons/index';
import {
  ModalBackground,
  ModalWrapper,
  TextWrapper,
  ModalTitle,
  ModalBody,
  CloseIcon,
} from './Styles';

import { FileUploadModalProps, FileUploadStatus } from './types';

const FileUploadModal: React.FC<FileUploadModalProps> = ({
  onCancel,
  title,
  formik,
  userId,
  handleAddAttachment,
}) => {
  const [uploadingFile, setUploadingFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<FileUploadStatus>({ progress: 0, uploaded: 0, total: 0 });
  const [files, setFiles] = useState<File[]>([]);
  const stopClickBubbling = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  const [{ Components: { Common: { FileUploadModal: { uploadErrorText } } } }] = useStrings();

  const uploadFile = async (file: File, setError: (message: string) => void): Promise<string | null> => {
    const authUser = getAuthUser();
    if (!authUser) return null;
    const filePath = `${imageHostingFilePaths.customerNotes}/${userId}`;
    try {
      setUploadingFile(file);
      const url = await uploadMedia(
        file,
        filePath,
        (progress, uploaded, total) => (setUploadStatus({ progress, uploaded, total })),
      );
      setUploadingFile(null);
      return url;
    } catch (err) {
      setError(uploadErrorText);
    }
    return null;
  };

  const onFileUploadComplete = async (file: File) => {
    try {
      const url = await uploadFile(file, (message) => formik.setFieldError('file', message));
      setFiles((state) => [...state, file]);
      if (url) handleAddAttachment({ url, fileName: file.name });
    } catch (err) {
      formik.setFieldError('file', uploadErrorText);
    }
  };

  return (
    <ModalBackground
      onClick={onCancel}
    >
      <ModalWrapper onClick={stopClickBubbling}>
        <CloseIcon src={closeWhite} onClick={onCancel} />
        <TextWrapper>
          <div>
            <ModalTitle>
              {title}
            </ModalTitle>
          </div>
        </TextWrapper>
        <ModalBody>
          <FileInputNotes
            onFileUploadComplete={onFileUploadComplete}
            onFileUploadError={(() => {
              formik.setFieldError('file', uploadErrorText);
            })}
            values={files}
            uploadStatus={uploadStatus}
            uploadingFile={uploadingFile}
          />
        </ModalBody>
      </ModalWrapper>
    </ModalBackground>
  );
};

export default FileUploadModal;
