import { Palette, BasePalette } from '../../lib/types/Style';
import { luminance } from '../../lib/utils/styleUtils';

export const basePalette: BasePalette = {
  blueMain: '#02B0FF',
  blueMedium: '#359EEA',
  blueLight: '#F2FBFF',
  navyMain: '#102534',
  navyLight: '#0E3348',
  orangeMain: '#EAA334',
  redMain: '#EB504C',
  redDark: '#9D1840',
  redLight: '#FF678C',
  purpleMain: '#9834E8',
  tealMain: '#27A2AD',
  greenMain: '#4DD078',
  greyDark: '#77777A',
  greyMain: '#EAEAEC',
  greyLight: '#F1F1F1',
  greyMedium: '#879299',
  white: '#FFFFFF',
  black: '#000000',
};

const palette: Palette = {
  /* --------- Brand --------- */
  primary: basePalette.navyMain,
  secondary: basePalette.blueMain,

  /* ----------- UI ---------- */

  // Text
  title: basePalette.navyMain,
  titleHighlight: basePalette.blueMain,
  subHeader: basePalette.navyMain,
  body: basePalette.navyMain,
  emphasis: basePalette.blueMain,
  label: basePalette.greyDark,
  inputError: basePalette.redMain,
  inputErrorBg: `${basePalette.redMain}14`,

  // Buttons
  btnPrimary: basePalette.blueMain,
  btnSecondary: basePalette.navyMain,
  btnActive: '#1FB851',
  btnGreen: '#34BE61',
  /* btnDeactivated: '', */
  /* btnSuccess: '', */
  btnError: basePalette.redMain,
  btnWarning: basePalette.redMain,
  btnGrey: '#F1F1F1',
  btnMidGrey: '#F8F8F8',
  btnDarkGrey: '#E7E7E9',

  // Borders
  borderGreen: '#1FB851',
  borderRed: '#E34D49',
  borderYellow: '#FCD524',
  borderOrange: '#EC7019',
  borderGrey: basePalette.greyMain,
  borderBlue: '#02B0FF',

  // Checkboxes
  checkboxChecked: basePalette.blueMain,
  checkbox: basePalette.white,
  checkboxBorder: basePalette.greyMain,
  checkboxError: basePalette.redMain,

  /* --------- Layout --------- */

  // Background
  bgPrimary: basePalette.white,
  bgSecondary: basePalette.greyLight,

  // Navigation Menu
  nav: basePalette.navyMain,
  navHover: basePalette.blueMain,
  navActive: basePalette.blueMain,
  navLink: basePalette.greyDark,
  navLinkHover: basePalette.white,
  navLinkActive: basePalette.white,

  // Default Table
  tableHeader: '',
  tableHeaderBg: '',
  tableBody: '',
  // tableBodyBg: basePalette,
  tableRow: '',
  // tableRowBg: basePalette,
  // tableColumn: basePalette,
  tableColumnBg: '',

  // Status
  status01: '',
  status02: '',
  statusO3: '',
  status04: '',
  status05: '',
  active: '',
  inactive: '',

  /* --------- Universal --------- */

  // Neutral
  black: '#000000',
  grey90: luminance('#787878', -0.8),
  grey80: luminance('#787878', -0.6),
  grey70: luminance('#787878', -0.4),
  grey60: luminance('#787878', -0.2),
  grey50: luminance('#787878', 0),
  grey40: luminance('#787878', 0.2),
  grey30: luminance('#787878', 0.4),
  grey20: luminance('#787878', 0.6),
  grey10: luminance('#787878', 0.9),
  grey05: luminance('#787878', 0.95),
  grey02: luminance('#787878', 0.98),
  grey01: luminance('#787878', 1),
  // white: basePalette,

  // Semantic
  danger: '#EB504C',
  success: '#33DF6C',
  warning: '#F89735',
  // info: basePalette
};

export default palette;
