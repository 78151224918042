import { PaymentDataView } from '../../../../../types/DBViews';
import { InstallmentStatus } from '../../../../../types/Plan';
import { PlanForPayment } from '../../../../../types/DTO';

import {
  SetAllPaymentsAction,
  SetUpdatedPaymentsAction,
  UpdateInstallmentStatusAction,
  CreatePayment,
  ClearSelectedCustomerPaymentsAction,
  SetPaymentFilterPlansAction,
  SetSelectedPlanAction,
  SetNeedsUpdateAction,
  SET_ALL_PAYMENT_DATA,
  SET_UPDATED_PAYMENT_DATA,
  UPDATE_INSTALLMENT_STATUS,
  CREATE_PAYMENT,
  CLEAR_SELECTED_CUSTOMER_PAYMENTS,
  SET_PAYMENT_FILTER_PLANS,
  SET_SELECTED_PLAN,
  SET_NEEDS_UPDATE,
} from './types';

export const setAllPaymentData = (
  paymentData: {
    results: PaymentDataView[];
    count: number;
  },
): SetAllPaymentsAction => ({
  type: SET_ALL_PAYMENT_DATA,
  paymentData,
});

export const setUpdatedPaymentData = (
  payments: PaymentDataView[],
): SetUpdatedPaymentsAction => ({
  type: SET_UPDATED_PAYMENT_DATA,
  payments,
});

export const createPayment = (
  payment: PaymentDataView,
): CreatePayment => ({
  type: CREATE_PAYMENT,
  payment,
});

export const updateInstallmentStatus = (
  installmentId: number,
  installmentStatus: InstallmentStatus,
): UpdateInstallmentStatusAction => ({
  type: UPDATE_INSTALLMENT_STATUS,
  installmentId,
  installmentStatus,
});

export const setPlansForPaymentFilter = (
  plans: PlanForPayment[],
): SetPaymentFilterPlansAction => ({
  type: SET_PAYMENT_FILTER_PLANS,
  plans,
});

export const setSelectedPlan = (
  planId?: string,
): SetSelectedPlanAction => ({
  type: SET_SELECTED_PLAN,
  planId,
});

export const setNeedsUpdate = (
  needsUpdate: boolean,
): SetNeedsUpdateAction => ({
  type: SET_NEEDS_UPDATE,
  needsUpdate,
});

export const clearSelectedCustomerPayments = (): ClearSelectedCustomerPaymentsAction => ({
  type: CLEAR_SELECTED_CUSTOMER_PAYMENTS,
});
