import * as Auth from '../Authentication';
import { apiRequest, apiFetch } from './Utils';
import { AuthSchema } from '../validators/authSchema';
import { SignupSchema } from '../validators/signupSchema';

import { APIThunkResult } from '../types/API';
import { UserCredentials, Roles } from '../types/Auth';
import { SignUpDTO } from '../types/DTO';

import { setAuthLoading } from '../store/contexts/loading/actions';
import { serverCustomerAPI } from '../../CONSTANTS';
import { getProfile } from './customer/profile';

export const signIn = (
  { email, password }: AuthSchema,
): APIThunkResult<UserCredentials> => apiRequest<UserCredentials>(async (dispatch) => {
  dispatch(setAuthLoading());

  const authUser = await Auth.signIn(email, password);

  if (authUser.user) {
    const { claims: { role } } = await authUser.user?.getIdTokenResult();
    if (role === Roles.CUSTOMER) await dispatch(getProfile());
  }

  return authUser;
});

export const signUp = (
  {
    email,
    password,
    termsAccepted,
    newsletterAccepted,
    referralId,
    // Omit registrationDueDate as this field is not part of payload but requred in form validation
  }: Omit<SignupSchema, 'planDueDate'>,
): APIThunkResult<SignUpDTO> => apiRequest<SignUpDTO>(async (dispatch) => {
  dispatch(setAuthLoading());

  const { data, error } = await apiFetch<SignUpDTO>({
    method: 'POST',
    url: `${serverCustomerAPI}/auth/signup`,
    body: {
      email,
      password,
      termsAccepted,
      newsletterAccepted,
      referralId,
    },
  });

  if (!data || error) throw new Error(`${error?.message}`);

  if (data) {
    await dispatch(signIn({ email, password }));
  }

  return data;
});

export const signOut = (): APIThunkResult<void> => apiRequest<void>(async () => {
  await Auth.signOut();
});
