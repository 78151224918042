import {
  TotalCreditActionTypes,
  SET_CUSTOMER_TOTAL_CREDITS,
} from './types';

export const setTotalCreditsData = (
  totalCredits: number,
): TotalCreditActionTypes => ({
  type: SET_CUSTOMER_TOTAL_CREDITS,
  totalCredits,
});
