import styled from 'styled-components/macro';

import { InfoBarTextProps } from './types';

export const FormSection = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.XS}rem 0`};

  ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => `${theme.spacing.XS * 1.2}rem ${theme.spacing.XS}rem`};
  }
`;

export const PlanValueTile = styled.div`
  border: 1px solid ${({ theme }) => theme.basePalette.greyMain};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  padding: 24px 32px;
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    margin-bottom: 0.75rem;
  }
`;

export const PlanValueText = styled.span`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-size: ${({ theme }) => theme.fontScale.L as number * 1.1}rem;
  margin-bottom: 5px;
  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

export const LineBreak = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.borderGrey};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing.XS}rem ${theme.spacing.XXS}rem`};
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => `${theme.spacing.XXS * 1.5}rem ${theme.spacing.S}rem`};
  }
`;

export const InfoBar = styled.div`
  background: ${({ theme }) => theme.basePalette.navyMain};
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    border-radius: 20px;
    padding: 24px 32px;
  }
`;

export const InfoBarText = styled.span<InfoBarTextProps>`
  color: ${({ theme, highlight }) => (highlight ? theme.palette.titleHighlight : theme.basePalette.white)};
`;

export const InfoBarPrice = styled.span`
  color: ${({ theme }) => theme.palette.titleHighlight};
  font-size: ${({ theme }) => theme.fontScale.XL}rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

export const InfoBarFrequency = styled.span`
  color: ${({ theme }) => theme.basePalette.white};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
`;

export const AdminFee = styled.span`
  color: ${({ theme }) => theme.basePalette.white};
  display: block;
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  margin-top: 0.5rem;
`;
