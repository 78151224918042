import styled from 'styled-components';

export const CustomerCreditsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: ${({ theme }) => theme.spacing.XS}rem;
`;

export const Title = styled.p`
color: ${({ theme }) => theme.basePalette.navyMain};
font-family: ${({ theme }) => theme.fontFamily.bodyBold};
font-size: 1.125rem;
margin-bottom: 5px;
`;

export const SubTitle = styled.p`
color: ${({ theme }) => theme.basePalette.greyDark};
font-family: ${({ theme }) => theme.fontFamily.bodyLight};
font-size: 0.875rem;
`;

export const InputWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
max-width: 500px;
`;