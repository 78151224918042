import styled from 'styled-components/macro';

import { StyledInputProps } from './types';

export const OuterWrapper = styled.div<StyledInputProps>`
  background-color: ${({ theme, error, disabled }) => (error && !disabled && theme.palette.inputErrorBg)};
  color: ${({ theme, error, disabled }) => (error && !disabled && theme.palette.inputError)};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  border-radius: 24px;
  min-height: 94px;
  min-width: 195px;
  padding: 1.5rem;
  border: 1px solid ${({ error, theme, disabled }) => {
    if (error && !disabled) return theme.palette.btnError;
    return theme.palette.btnDarkGrey;
  }};

  &:focus {
    border: 1px solid ${({ theme }) => theme.basePalette.blueMain};
    color: ${({ theme }) => theme.basePalette.navyMain};
  }

  &:hover {
    ${({ theme, disabled }) => !disabled && `border: 1px solid ${theme.basePalette.blueMain}`};
  }
`;

export const InnerWrapper = styled.div`
  position: relative;
`;

export const StyledInput = styled.input<StyledInputProps>`
  position: relative;
  border-radius: 24px;
  outline: none;
  width: ${({ width = 'auto' }) => width};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  padding-left: ${({ isCurrency, theme }) => isCurrency && theme.spacing.XXS}rem;
  width: 100%;

  border: none;
  background: none;
  
  &::-webkit-input-placeholder { /* Edge */
    font-family: ${({ theme }) => theme.fontFamily.bodyLight};
    color: ${({ disabled, theme }) => (disabled ? theme.palette.btnGrey : theme.basePalette.greyDark)};
    opacity: 1;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: ${({ theme }) => theme.fontFamily.bodyLight};
    color: ${({ disabled, theme }) => (disabled ? theme.palette.btnGrey : theme.basePalette.greyDark)};
    opacity: 1;
  }

  &::placeholder {
    font-family: ${({ theme }) => theme.fontFamily.bodyLight};
    color: ${({ disabled, theme }) => (disabled ? theme.palette.btnGrey : theme.basePalette.greyDark)};
    opacity: 1;
  }
`;

export const MetaWrapper = styled.div`
  display: flex;
`;

export const Label = styled.p<StyledInputProps>`
  color: ${({ error, disabled, theme }) => {
    if (disabled) return theme.palette.btnGrey;
    if (error) return theme.palette.btnError;
    return theme.basePalette.greyDark;
  }};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.extraLight};
  margin-bottom: 5px;
  margin-right: auto;
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.palette.btnError};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  font-style: italic;
  margin-bottom: 5px;
  margin-left: auto;
  margin-top: 5px;
`;

export const DollarIcon = styled.div`
  left: 0;
  top: 1px;
  bottom: 1px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;
export const DollarIcon1 = styled.div`
  left: 0;
  top: 1px;
  bottom: 1px;
  border-right: 1px solid ${({ theme }) => theme.basePalette.greyMain};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.XXS * 1.5}rem;
`;

export const PasswordToggle = styled.div`
  position: absolute;
  cursor: pointer;
  right: ${({ theme }) => `${theme.spacing.XXS}rem`};
  top: 50%;
  transform: translatey(-50%);
`;
