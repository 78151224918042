/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import DataTable from 'react-data-table-component';

import useStrings from '../../../hooks/useStrings';
import { generatePlanFileName } from '../../../lib/utils/plan';
import { loadReceiptBlob } from '../../../lib/Media';
import { PLAN_TYPE_DISPLAY } from '../../../CONSTANTS';

import CustomerLayout from '../../../layouts/CustomerLayout';
import Pagination from '../../../components/Common/Table/Pagination';
import LoadingScreen from '../../../components/UI/LoadingScreen';

import { DataTableWrapper, ReceiptText, ViewReceipt, ReceiptLink } from './Styles';
import appTheme from '../../../style/themes/appTheme';

import { APIThunkDispatch } from '../../../lib/types/API';
import { TableColumn, TableRow } from '../../../components/Common/Table/types';
import { PlanDataView } from '../../../lib/types/DBViews';
import { ReceiptsTableProps } from './types';
import { FileMeta } from '../../../lib/types/Media';
import { setNotification } from '../../../lib/store/contexts/notification/actions';

const ReceiptsTable: React.FC<ReceiptsTableProps> = ({
  data,
  count,
  rowsPerPage,
  page,
  onPageChange,
}) => {
  const [{ GenericText, Pages: { Customer: { Receipts: Strings } } }] = useStrings();
  const [preparedData, setPreparedData] = useState<(TableRow & FileMeta)[]>(); // currentData + any loaded image files.
  const Dispatch: APIThunkDispatch = useDispatch();

  useEffect(() => {
    const preparedRows: (TableRow & FileMeta)[] = [];
    if (!data.length) return;
    // For each row of data, attach an externally downloaded receipt url, if present.
    (async () => {
      for (const row of data) {
        const preparedRow = row;
        if (row.receiptLink) {
          const fileMeta = await loadReceiptBlob(
            row.receiptLink as string,
            generatePlanFileName(row),
            GenericText.downloadError,
          );
          if (fileMeta) preparedRow.fileMeta = fileMeta;
        }
        preparedRows.push(preparedRow);
      }
      setPreparedData(preparedRows);
    })().catch(() => null);

    // Revoke urls on cleanup
    return () => {
      preparedRows?.forEach((row: (TableRow & FileMeta)) => {
        if (!row.fileMeta) return;
        const { url, error } = row.fileMeta as FileMeta;
        if (url && !error) URL.revokeObjectURL(url);
      });
    };
  }, [data, GenericText]);

  const alertFileError = (error: string) => Dispatch(setNotification({ message: error, variant: 'danger' }));

  const columns: TableColumn<PlanDataView>[] = [
    {
      name: 'DATE PAID',
      selector: 'paidDate',
      format: (row) => (row.paidDate ? dayjs(row.paidDate as Date).format('DD/MM/YYYY') : 'N/A'),
      grow: 0.6,
      sortable: true,
    },
    {
      name: 'PLAN',
      selector: 'planType',
      hide: 768,
      sortable: true,
      format: (row) => PLAN_TYPE_DISPLAY[row.planType as string],
    },
    {
      name: 'REGO NO.',
      selector: 'registration',
      grow: 2,
      hide: 768,
      sortable: true,
      format: (row) => row.registration?.toString() || 'N/A',
    },
    {
      name: 'AMOUNT PAID',
      selector: 'alreadyPaid',
      grow: 0.3,
      sortable: true,
      format: (row) => `$${Number(row.alreadyPaid).toFixed(2)}`,
    },
    {
      name: 'RECEIPT',
      selector: 'receiptLink',
      cell: ({ fileMeta }) => {
        if (!fileMeta) return 'N/A';
        const { url, error, fileName } = fileMeta as FileMeta;
        if (error) {
          // If error loading file, preserve 'download' button so user knows receipt should be present.
          return (
            <ReceiptLink onClick={() => alertFileError(error)}>
              <ViewReceipt><ReceiptText>{Strings.viewReceipt}</ReceiptText></ViewReceipt>
            </ReceiptLink>
          );
        } if (url) {
          return (
            <ReceiptLink href={url} download={fileName}>
              <ViewReceipt><ReceiptText>{Strings.viewReceipt}</ReceiptText></ViewReceipt>
            </ReceiptLink>
          );
        }
        return 'N/A';
      },
      grow: 1,
      center: true,
      sortable: true,
    },
  ];

  const customStyles = {
    table: { style: { color: appTheme.basePalette.navyMain } },
    headCells: { style: { opacity: 0.5, color: appTheme.basePalette.navyMain, fontSize: '11px' } },
    cells: { style: { color: appTheme.basePalette.navyMain } },
    headRow: { style: { background: 'rgba(122, 133, 140, 0.1)', minHeight: '40px' } },
    rows: { style: { minHeight: '68px' } },
  };

  if (!preparedData?.length && data.length) return <LoadingScreen />;

  return (
    <CustomerLayout
      pageTitle={Strings.pageTitle}
      pageTitleHighlight={Strings.pageTitleHighlight}
    >
      <DataTableWrapper>
        <DataTable
          noHeader
          columns={columns}
          data={preparedData || []}
          pagination
          paginationServer
          paginationPerPage={rowsPerPage}
          paginationTotalRows={count}
          paginationDefaultPage={page}
          onChangePage={onPageChange}
          paginationComponent={Pagination}
          customStyles={customStyles}
        />
      </DataTableWrapper>
    </CustomerLayout>

  );
};

export default ReceiptsTable;
