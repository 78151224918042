import { DefaultTheme } from 'styled-components/macro';

import {
  device,
  fontWeight,
  fontScale,
  spacing,
  fontFamily,
  palette,
  borderStyles,
  container,
} from '../core';

import { basePalette } from '../core/Color';

const appTheme: DefaultTheme = {
  basePalette,
  palette,
  fontFamily,
  fontScale,
  fontWeight,
  spacing,
  device,
  borderStyles,
  container,
};

export default appTheme;
