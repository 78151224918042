import styled from 'styled-components';

export const Container = styled.div`
display: flex;
justify-content: center;
gap: 2rem;
align-items: center;
padding: 1rem 2rem;
`;

export const TotalText = styled.p`
color: ${({ theme }) => theme.basePalette.navyMain};
font-weight: ${({ theme }) => theme.fontWeight.medium};
font-size: ${({ theme }) => theme.fontScale.M}rem;
font-family: ${({ theme }) => theme.fontFamily.bodyBold};
`;

export const PlansText = styled.p`
color: ${({ theme }) => theme.basePalette.navyMain};
font-weight: ${({ theme }) => theme.fontWeight.medium};
font-family: ${({ theme }) => theme.fontFamily.bodyBold};
margin-left: auto;
`;

export const InstallmentsText = styled.p`
color: ${({ theme }) => theme.basePalette.navyMain};
font-weight: ${({ theme }) => theme.fontWeight.medium};
font-family: ${({ theme }) => theme.fontFamily.bodyBold};
`;

export const FeesText = styled.p`
color: ${({ theme }) => theme.basePalette.navyMain};
font-weight: ${({ theme }) => theme.fontWeight.medium};
font-family: ${({ theme }) => theme.fontFamily.bodyBold};
`;

export const Value = styled.span`
margin-left: 10px;
font-weight: ${({ theme }) => theme.fontWeight.regular};
`;