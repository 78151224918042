import * as yup from 'yup';
import dayjs from 'dayjs';

import { InstallmentFrequency } from '../types/Plan';
import { CONTACT_NUMBER } from '../../CONSTANTS';

const fundDetailsSchema = yup.object({
  value: yup
    .number()
    .nullable()
    .typeError('Must be a number')
    .required('Please enter an amount'),
  startDate: yup
    .date()
    .nullable()
    .required('Start date is required'),
  dueDate: yup
    .date()
    .nullable()
    .required('Due date is required')
    .when('startDate', (startDate: Date) => startDate && (
      yup
        .date()
        .min(dayjs(startDate).add(6, 'weeks').toDate(), `Plan is due too soon, please call us on ${CONTACT_NUMBER}`)
        .required('Due date is required')
    )),
  installmentFrequency: yup
    .string()
    .trim()
    .typeError('Please select an installment frequency')
    .oneOf(Object.values(InstallmentFrequency))
    .required('Frequency is required'),
  name: yup
    .string()
    .trim(),
}).required();

export type FundDetailsSchema = yup.InferType<typeof fundDetailsSchema>;

export default fundDetailsSchema;
