import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import dayjs from 'dayjs';

import useStrings from '../../../hooks/useStrings';
import { calculateEditPaymentData } from '../../../lib/utils';
import { getFrequencyOptions } from '../../../lib/utils/plan';
import { setSelectedPlan } from '../../../lib/store/contexts/admin/selectedCustomer/payments/actions';

import Button from '../Button';
import Grid from '../Grid';
import ModalDropdown from '../ModalDropdown';
import ModalVehicleDropdown from '../ModalDropdown/ModalVehicleDropdown';
import ModalDatePicker from '../ModalDatePicker';
import ModalInput from '../ModalInput';
import ModalReadOnlyField from '../ModalReadOnlyField';
import ModalPlanTransfers from '../ModalPlanTransfers';

import {
  ScrollingModal,
  ModalBackground,
  ModalWrapper,
  ButtonWrapper,
  ModalTitle,
  LineBreak,
} from '../CreatePlanModal/Styles';
import {
  CheckboxWrapper,
  CheckboxText,
  Checkbox,
} from './Styles';
import { HighlightedDay } from '../ModalDatePicker/Styles';

import { EditPlanModalFormProps } from './types';
import { VehicleType } from '../../../lib/types/Vehicle';
import {
  InstallmentFrequency,
  PlanType,
  PlanStatus,
  Concession,
} from '../../../lib/types/Plan';
import { State } from '../../../lib/types/Customer';
import { APIThunkDispatch } from '../../../lib/types/API';
import { VehicleDropdownOption } from '../../../lib/types/Dropdown';
import adminFundSchema from '../../../lib/validators/adminFundSchema';
import adminRegoPlanSchema from '../../../lib/validators/adminRegoPlanSchema';
import {
  FEES,
  NUMBER_REGEX,
  planTypeOptions,
  installmentFrequencyOptions,
  registrationLengthOptions,
  stateOptions,
  hiddenVehicleTypeOptions,
  concessionOptions,
} from '../../../CONSTANTS';
import { PlanDataView } from '../../../lib/types/DBViews';

const EditPlanModalForm: React.FC<EditPlanModalFormProps> = ({
  plan,
  vehicleMakes,
  selectedPlanTransfers,
  onCancel,
  onConfirm,
  onViewPayments,
}) => {
  const dispatch: APIThunkDispatch = useDispatch();
  const [{ GenericText, Components: { Common: { PlanModal: Strings } } }] = useStrings();
  const stopClickBubbling = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  const [adminFeeEdited, setAdminFeeEdited] = useState(false);
  const [isRegoPlan, setIsRegoPlan] = useState(plan.planType === PlanType.VEHICLE_REGISTRATION);
  const [isEditing, setIsEditing] = useState(false);

  const carMakes = vehicleMakes.reduce<VehicleDropdownOption[]>((acc, curr) => (
    curr.type === VehicleType.CAR ? [
      ...acc,
      {
        label: curr.name,
        value: curr.vehicleMakeId,
      },
    ] : acc
  ), []);

  const motorcycleMakes = vehicleMakes.reduce<VehicleDropdownOption[]>((acc, curr) => (
    curr.type === VehicleType.MOTORCYCLE ? [
      ...acc,
      {
        label: curr.name,
        value: curr.vehicleMakeId,
      },
    ] : acc
  ), []);

  const getCalculatedValues = (values: Partial<PlanDataView> & { installmentFrequency?: string }) => {
    const data = calculateEditPaymentData({
      isEditing,
      allPayments: plan?.allPayments,
      remainingPaymentsOverride: plan?.remainingPayments,
      latestInstallmentValue: plan?.latestInstallmentValue,
      feeOverride: values?.adminFee,
      startDate: values?.startDate,
      lastPaymentDateOverride: values?.endDate,
      dueDate: values?.dueDate,
      installmentFrequency: values?.installmentFrequency,
      totalPlanValue: values?.value,
      receivedValue: plan?.receivedValue,
      alreadyPaid: plan?.alreadyPaid,
      alreadyPaidLessFees: plan?.alreadyPaidLessFees,
    });
    if (!data) return null;
    return {
      remainingPayments: data?.remainingPayments,
      numberOfPayments: data?.numberOfPayments,
      installmentAmount: Number(data?.installmentAmount).toFixed(2),
      fee: data?.fee.toFixed(2),
      totalCost: Number(data?.totalCost).toFixed(2),
      validDates: data?.validDates,
      lastPaymentDate: data.lastPaymentDate,
    };
  };

  const clearVehicleFields = (
    setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void,
  ) => {
    const fields = [
      'registrationLength', 'vehicle.registration', 'vehicle.type',
      'vehicle.make', 'vehicle.vehicleMakeId', 'vehicle.model', 'vehicle.color',
      'vehicle.registeredState', 'vehicle.year',
    ];
    fields.forEach((field) => { setFieldValue(field, ''); });
    setFieldValue('concessionCard', Concession.NONE);
  };

  return (
    <Formik
      initialValues={{
        type: plan?.planType || undefined,
        startDate: plan?.startDate || undefined,
        endDate: plan?.scheduledEndDate || plan?.endDate || undefined,
        dueDate: plan?.dueDate || undefined,
        installmentFrequency: plan?.frequency || undefined,
        registrationLength: plan?.registrationLength || undefined,
        name: plan?.planName || '',
        vehicle: {
          registration: plan?.vehicleRegistration || '',
          type: plan?.vehicleType || undefined,
          make: plan?.inputVehicleMake || '',
          vehicleMakeId: plan?.definedVehicleMakeId || undefined,
          model: plan?.vehicleModel || '',
          color: plan?.vehicleColor || '',
          registeredState: plan?.vehicleRegisteredState || undefined,
          year: plan?.vehicleYear || undefined,
        },
        value: plan?.value || undefined,
        receivedValue: plan?.receivedValue || 0,
        status: plan?.status || undefined,
        adminFee: plan?.adminFee || 0,
        sendEmailConf: false,
        remainingPayments: plan?.remainingPayments,
        concessionCard: plan?.concessionCard || Concession.NONE,
      }}
      validationSchema={isRegoPlan ? adminRegoPlanSchema : adminFundSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        // If data contains vehicleMakeId, remove make prop before submitting
        const newPlanData = JSON.parse(JSON.stringify(values));
        if (values?.vehicle?.vehicleMakeId) {
          delete newPlanData?.vehicle?.make;
        }
        // Use calculated end date if not set
        const calcValues = getCalculatedValues(values);
        if (!values.endDate && calcValues?.lastPaymentDate) {
          newPlanData.endDate = calcValues?.lastPaymentDate;
        }
        if (plan) newPlanData.planId = plan?.planId;
        await onConfirm({ ...newPlanData, sendEmailConf: values.sendEmailConf });
        setSubmitting(false);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        setErrors,
        isSubmitting,
        values,
        errors,
        touched,
      }) => {
        const calcValues = getCalculatedValues(values);
        const primaryDateErrors = !!errors.installmentFrequency || !!errors.startDate || !!errors.dueDate;
        const planStarted = !!plan?.alreadyPaidLessFees
          || !!plan?.receivedValue
          || (plan.allPayments !== plan.remainingPayments);
        return (
          <ModalBackground
            onClick={onCancel}
          >
            <ModalWrapper
              onClick={stopClickBubbling}
            >
              <>
                <Grid row marginTop="3rem" maxWidth="100%">
                  <Grid column sm={12} alignItems="center">
                    <ModalTitle>
                      {Strings.editPlanTitle}
                    </ModalTitle>
                  </Grid>
                </Grid>
                <Grid row marginTop="2rem" maxWidth="100%">
                  <LineBreak />
                </Grid>
                <ScrollingModal>
                  <Grid row maxWidth="97.5%">
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalInput
                        type="text"
                        label={Strings.planNameInput}
                        placeholder={GenericText.pleaseEnter}
                        onChange={((e) => setFieldValue('name', e.target.value))}
                        onLoadValue={values?.name}
                      />
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalDropdown
                        name="type"
                        label={Strings.planTypeDropdown}
                        placeholder={GenericText.pleaseSelect}
                        options={planTypeOptions}
                        onOptionClick={((option) => {
                          setFieldValue('type', option);
                          setIsRegoPlan(option === PlanType.VEHICLE_REGISTRATION);
                          if (option !== PlanType.VEHICLE_REGISTRATION) clearVehicleFields(setFieldValue);
                          setErrors({});
                        })}
                        onLoadOption={values.type || ''}
                      />
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalDropdown
                        name="concessionCard"
                        label={Strings.concessionCardDropdown}
                        placeholder={GenericText.pleaseSelect}
                        options={concessionOptions}
                        onOptionClick={((option) => {
                          setFieldValue('concessionCard', option);
                        })}
                        onLoadOption={values.concessionCard}
                        disabled={values.type !== PlanType.VEHICLE_REGISTRATION}
                      />
                    </Grid>
                    {selectedPlanTransfers.map((transfer) => (
                      <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                        <ModalPlanTransfers transfer={transfer} planId={plan.planId} />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid row maxWidth="97.5%">
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalDropdown
                        name="installmentFrequency"
                        label={Strings.installmentFrequencyDropdown}
                        placeholder={GenericText.pleaseSelect}
                        notEditable={planStarted}
                        options={installmentFrequencyOptions}
                        onOptionClick={((option) => {
                          setFieldValue('endDate', undefined);
                          setFieldError('endDate', '');
                          setFieldValue('installmentFrequency', option);
                          setIsEditing(true);
                          if (values.startDate
                            && option === InstallmentFrequency.ONE_OFF_PAYMENT) {
                            setFieldError('startDate', '');
                            if (values.dueDate) {
                              setFieldValue('endDate', values.dueDate);
                            } else {
                              setFieldValue('dueDate', values.startDate);
                              setFieldValue('endDate', values.startDate);
                            }
                            setFieldError('dueDate', '');
                            setFieldError('endDate', '');
                          } else if (values.startDate && values.dueDate) {
                            const validFrequencies = getFrequencyOptions(values.startDate, values.dueDate);
                            if (!validFrequencies.some((entry) => entry.value === option)) {
                              setFieldError('installmentFrequency', Strings.invalidFrequencyForDates);
                            } else setFieldError('installmentFrequency', '');
                            setFieldError('startDate', '');
                            setFieldError('dueDate', '');
                          }
                          if (!adminFeeEdited) {
                            switch (option) {
                              case InstallmentFrequency.ONE_OFF_PAYMENT:
                              case InstallmentFrequency.WEEK:
                                setFieldValue('adminFee', FEES.weekly);
                                break;
                              case InstallmentFrequency.FORTNIGHT:
                                setFieldValue('adminFee', FEES.fortnightly);
                                break;
                              case InstallmentFrequency.MONTH:
                                setFieldValue('adminFee', FEES.monthly);
                                break;
                              default:
                                setFieldValue('adminFee', 0);
                                break;
                            }
                          }
                        })}
                        onLoadOption={values.installmentFrequency}
                      />
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalDatePicker
                        error={errors.dueDate}
                        label={Strings.dueDatePicker}
                        outlined
                        setError={(error) => setFieldError('dueDate', error)}
                        onSelect={(val) => {
                          setFieldValue('dueDate', val);
                          if (values.startDate && values.installmentFrequency) {
                            const validFrequencies = getFrequencyOptions(values.startDate, val);
                            if (!validFrequencies.some((entry) => entry.value === values.installmentFrequency)) {
                              setFieldError('dueDate', Strings.dueDateTooClose);
                            } else setFieldError('dueDate', '');
                            setFieldError('installmentFrequency', '');
                            setFieldError('startDate', '');
                          }

                          if (values?.installmentFrequency === InstallmentFrequency.ONE_OFF_PAYMENT) {
                            setFieldValue('endDate', val);
                            setFieldError('endDate', '');
                          } else if (!planStarted) {
                            setFieldValue('endDate', undefined);
                            setFieldError('endDate', '');
                          }
                          setIsEditing(true);
                        }}
                        onLoadDate={values.dueDate}
                      />
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalDatePicker
                        error={errors.startDate}
                        label={Strings.startDatePicker}
                        outlined
                        notEditable={planStarted}
                        setError={(error) => setFieldError('startDate', error)}
                        onSelect={(val) => {
                          setFieldValue('startDate', val);
                          if (values.dueDate && values?.installmentFrequency) {
                            const validFrequencies = getFrequencyOptions(val, values.dueDate);
                            if (!validFrequencies.some((entry) => entry.value === values.installmentFrequency)) {
                              setFieldError('startDate', Strings.startDateTooClose);
                            } else setFieldError('startDate', '');
                            setFieldError('installmentFrequency', '');
                            setFieldError('dueDate', '');
                          }
                          if (values.installmentFrequency !== InstallmentFrequency.ONE_OFF_PAYMENT) {
                            setFieldValue('endDate', undefined);
                            setFieldError('endDate', '');
                          }
                          setIsEditing(true);
                        }}
                        onLoadDate={values.startDate}
                      />
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalDatePicker
                        error={errors.endDate}
                        label={Strings.endDatePicker}
                        outlined
                        notEditable={
                          !values.startDate
                          || !values.installmentFrequency
                          || !values.dueDate
                          || values.installmentFrequency === InstallmentFrequency.ONE_OFF_PAYMENT
                        }
                        minDate={values.installmentFrequency === InstallmentFrequency.ONE_OFF_PAYMENT
                          ? undefined
                          : dayjs(values.startDate).toDate()}
                        maxDate={values.installmentFrequency === InstallmentFrequency.ONE_OFF_PAYMENT
                          ? undefined
                          : dayjs(values.dueDate).toDate()}
                        setError={(error) => setFieldError('endDate', error)}
                        setTouched={(touch) => setFieldTouched('endDate', touch)}
                        disabled={primaryDateErrors}
                        onSelect={(val) => {
                          // add error handle logic for end date
                          if (planStarted
                            || (calcValues?.validDates && calcValues.validDates.includes(dayjs(val).toISOString()))) {
                            setFieldValue('endDate', val);
                            setFieldError('endDate', '');

                            setIsEditing(true);
                          } else {
                            setFieldError('endDate', 'Not valid end date');
                          }
                        }}
                        onLoadDate={values.endDate || (!primaryDateErrors ? calcValues?.lastPaymentDate : undefined)}
                        tileContent={({ date }) => {
                          if (!planStarted
                            && calcValues?.validDates
                            && calcValues.validDates.includes(dayjs(date).toISOString())) {
                            return <HighlightedDay />;
                          }
                          return null;
                        }}
                      />
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalDropdown
                        name="registrationLength"
                        label={Strings.RegistrationLengthDropdown}
                        placeholder={GenericText.pleaseSelect}
                        options={registrationLengthOptions}
                        onOptionClick={((option) => {
                          setFieldValue('registrationLength', option);
                          setFieldError('registrationLength', '');
                        })}
                        disabled={values.type !== PlanType.VEHICLE_REGISTRATION}
                        onLoadOption={values.registrationLength}
                      />
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalInput
                        type="text"
                        label={Strings.registrationInput}
                        placeholder={GenericText.pleaseEnter}
                        touched={touched.vehicle?.registration}
                        error={errors.vehicle?.registration}
                        onChange={((e) => {
                          setFieldValue('vehicle.registration', e.target.value.toUpperCase());
                          if (e.target.value) setFieldError('vehicle.registration', '');
                        })}
                        disabled={values.type !== PlanType.VEHICLE_REGISTRATION}
                        onLoadValue={values.vehicle.registration}
                      />
                    </Grid>
                  </Grid>
                  <Grid row marginTop="1rem" maxWidth="97.5%">
                    <Grid column>
                      <LineBreak />
                    </Grid>
                  </Grid>
                  <Grid row maxWidth="97.5%">
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalDropdown
                        name="vehicle.type"
                        label={Strings.vehicleTypeDropdown}
                        placeholder={GenericText.pleaseSelect}
                        options={hiddenVehicleTypeOptions}
                        onOptionClick={((option) => {
                          setFieldValue('vehicle.type', option);
                          setFieldError('vehicle.type', '');
                          setFieldValue('vehicle.make', undefined);
                          setFieldValue('vehicle.vehicleMakeId', undefined);
                        })}
                        disabled={values.type !== PlanType.VEHICLE_REGISTRATION}
                        onLoadOption={values.vehicle.type || ''}
                      />
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      {values.vehicle.type === VehicleType.CAR
                        || values.vehicle.type === VehicleType.MOTORCYCLE ? (
                          <ModalVehicleDropdown
                            name="vehicle.vehicleMakeId"
                            label={Strings.vehicleMakeInput}
                            placeholder={GenericText.pleaseSelect}
                            options={values.vehicle.type === VehicleType.CAR
                              ? carMakes
                              : motorcycleMakes}
                            onOptionClick={((option) => {
                              setFieldValue('vehicle.vehicleMakeId', option);
                              setFieldError('vehicle.vehicleMakeId', '');
                              setFieldError('vehicle.make', '');
                            })}
                            disabled={values.type !== PlanType.VEHICLE_REGISTRATION}
                            onLoadOption={values.vehicle.vehicleMakeId}
                          />
                        ) : (
                          <ModalInput
                            type="text"
                            touched={touched.vehicle?.make}
                            error={errors.vehicle?.make}
                            label={Strings.vehicleMakeInput}
                            placeholder={GenericText.pleaseEnter}
                            onChange={((e) => {
                              setFieldValue('vehicle.make', e.target.value.toUpperCase());
                              setFieldError('vehicle.make', '');
                              setFieldError('vehicle.vehicleMakeId', '');
                            })}
                            disabled={values.type !== PlanType.VEHICLE_REGISTRATION}
                            onLoadValue={values.vehicle.make}
                          />
                        )}
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalInput
                        type="text"
                        touched={touched.vehicle?.model}
                        error={errors.vehicle?.model}
                        label={Strings.vehicleModelInput}
                        placeholder={GenericText.pleaseEnter}
                        onChange={((e) => {
                          setFieldValue('vehicle.model', e.target.value.toUpperCase());
                          setFieldError('vehicle.model', '');
                        })}
                        disabled={values.type !== PlanType.VEHICLE_REGISTRATION}
                        onLoadValue={values.vehicle.model}
                      />
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalInput
                        type="text"
                        touched={touched.vehicle?.color}
                        error={errors.vehicle?.color}
                        label={Strings.vehicleColorDropdown}
                        placeholder={GenericText.pleaseEnter}
                        onChange={((e) => {
                          setFieldValue('vehicle.color', e.target.value.toUpperCase());
                          setFieldError('vehicle.color', '');
                        })}
                        disabled={values.type !== PlanType.VEHICLE_REGISTRATION}
                        onLoadValue={values.vehicle.color}
                      />
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalDropdown
                        name="vehicle.registeredState"
                        label={Strings.vehicleRegisteredState}
                        placeholder={GenericText.pleaseSelect}
                        options={stateOptions}
                        onOptionClick={((option) => {
                          setFieldValue('vehicle.registeredState', option);
                          setFieldError('vehicle.registeredState', '');
                        })}
                        disabled={values.type !== PlanType.VEHICLE_REGISTRATION}
                        onLoadOption={State[values.vehicle.registeredState as keyof typeof State]}
                      />
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalDatePicker
                        error={errors.vehicle?.year}
                        label={Strings.vehicleYearInput}
                        outlined
                        setError={(error) => setFieldError('vehicle.year', error)}
                        onSelect={(val) => {
                          setFieldValue('vehicle.year', val);
                        }}
                        disabled={values.type !== PlanType.VEHICLE_REGISTRATION}
                        onLoadDate={values.vehicle.year}
                        yearOnly
                        maxDate={dayjs().add(1, 'year').toDate()}
                      />
                    </Grid>
                  </Grid>
                  <Grid row marginTop="1rem" maxWidth="97.5%">
                    <Grid column>
                      <LineBreak />
                    </Grid>
                  </Grid>
                  <Grid row maxWidth="97.5%">
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalInput
                        type="currency"
                        touched={touched.value}
                        error={errors.value}
                        label={Strings.valueInput}
                        placeholder={GenericText.pleaseEnter}
                        onChange={(() => {})}
                        onBlur={((e) => {
                          setFieldTouched('value');
                          setIsEditing(true);
                          if (NUMBER_REGEX.test(e.target.value)) {
                            setFieldValue('value', Number(e.target.value));
                            setFieldError('value', '');
                          } else {
                            setFieldError('value', 'Must be a number');
                          }
                        })}
                        onLoadValue={values?.value?.toString()}
                      />
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalReadOnlyField
                        name="alreadyPaid"
                        label={Strings.alreadyPaidDisplay}
                        value={plan?.alreadyPaidLessFees || plan?.receivedValue || plan.alreadyPaid
                          ? `$${((plan.alreadyPaidLessFees) + (plan.receivedValue)).toFixed(2)} / $${(plan.alreadyPaid - plan.alreadyPaidLessFees).toFixed(2)}`
                          : '$0 / $0'}
                        disabled
                      />
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalInput
                        type="currency"
                        touched={touched.adminFee}
                        error={errors.adminFee}
                        label={Strings.adminFeesDisplay}
                        placeholder={GenericText.pleaseEnter}
                        onChange={(() => {})}
                        onBlur={((e) => {
                          if (NUMBER_REGEX.test(e.target.value)) {
                            setFieldValue('adminFee', Number(e.target.value));
                            setIsEditing(true);
                            setAdminFeeEdited(true);
                            setFieldError('adminFee', '');
                          } else {
                            setFieldError('adminFee', 'Must be a number');
                            setAdminFeeEdited(false);
                          }
                        })}
                        setTouched={(isTouched) => setFieldTouched('adminFee', isTouched)}
                        onLoadValue={values?.adminFee?.toFixed(2) || 'TBC'}
                      />
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalReadOnlyField
                        name="paymentsRemaining"
                        label={Strings.remainingPaymentsDisplay}
                        value={(calcValues?.remainingPayments
                          || calcValues?.remainingPayments === 0)
                          && calcValues.numberOfPayments
                          && (values.endDate || calcValues.lastPaymentDate)
                          && !primaryDateErrors && !errors.endDate
                          ? `${calcValues?.remainingPayments.toString()} / ${calcValues?.numberOfPayments}`
                          : 'TBC'}
                        disabled={!calcValues}
                      />
                    </Grid>
                    <Grid column sm={6} md={4} lg={2} marginTop="1rem">
                      <ModalReadOnlyField
                        name="installmentAmount"
                        label={Strings.installmentAmountDisplay}
                        value={calcValues?.installmentAmount && !primaryDateErrors && !errors.endDate ? `$${calcValues?.installmentAmount}` : 'TBC'}
                        disabled={!calcValues}
                      />
                    </Grid>
                    <Grid column md={4} lg={2} marginTop="1rem">
                      <ModalReadOnlyField
                        name="totalCost"
                        label={Strings.totalCostDisplay}
                        value={calcValues?.totalCost && !primaryDateErrors && !errors.endDate ? `$${calcValues?.totalCost}` : 'TBC'}
                        disabled={!calcValues}
                      />
                    </Grid>
                  </Grid>
                  <Grid row marginTop="1rem" maxWidth="100%">
                    <LineBreak />
                  </Grid>
                  <Grid row marginTop="1.5rem" maxWidth="100%">
                    <Grid column sm={12} alignItems="flex-end">
                      <ButtonWrapper>
                        {!!plan && (
                          <CheckboxWrapper>
                            <Checkbox onClick={() => setFieldValue('sendEmailConf', !values.sendEmailConf)} sendEmailConf={values.sendEmailConf} />
                            <CheckboxText>{Strings.sendEmail}</CheckboxText>
                          </CheckboxWrapper>
                        )}
                        {plan && plan?.status !== PlanStatus.INACTIVE && (
                          <Button
                            onClick={() => {
                              if (plan?.planId) {
                                dispatch(setSelectedPlan(plan.planId.toString()));
                                onCancel();
                                onViewPayments();
                              }
                            }}
                            variant="dark-inverse"
                            width="220px"
                            rounded
                          >
                            {Strings.viewPayments}
                          </Button>
                        )}
                        <Button
                          onClick={onCancel}
                          variant="dark-inverse"
                          width="220px"
                          rounded
                          disabled={isSubmitting}
                        >
                          {GenericText.cancel}
                        </Button>
                        <Button
                          onClick={() => {
                            if (!errors.endDate) {
                              handleSubmit();
                            }
                          }}
                          variant="primary"
                          width="220px"
                          rounded
                          disabled={isSubmitting || primaryDateErrors || !!errors.endDate}
                          $loading={isSubmitting}
                        >
                          {GenericText.saveChanges}
                        </Button>
                      </ButtonWrapper>
                    </Grid>
                  </Grid>
                </ScrollingModal>
              </>
            </ModalWrapper>
          </ModalBackground>
        );
      }}
    </Formik>
  );
};

export default EditPlanModalForm;
