import React from 'react';

import { TextAreaLabel, StyledTextArea, TextAreaError } from './Styles';

import { TextAreaProps } from './types';

const TextArea: React.FC<TextAreaProps> = ({
  name,
  label,
  rows,
  placeHolder,
  handleChange,
  value,
  error,
}) => (
  <>
    <TextAreaLabel>{label}</TextAreaLabel>
    <StyledTextArea
      name={name}
      rows={rows}
      placeholder={placeHolder}
      onChange={(e) => handleChange(e.target.value)}
      value={value}
      error={error}
    />
    {error && (<TextAreaError>{error}</TextAreaError>)}
  </>
);

export default TextArea;
