import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import dayjs from 'dayjs';

import useStrings from '../../../../hooks/useStrings';
import { buildHandlers } from '../../../../components/Common/Table/handlers';
import {
  INSTALLMENT_FREQUENCY_DISPLAY,
  INSTALLMENT_STATUS_DISPLAY,
  hiddeninstallmentStatusOptions,
  PLAN_TYPE_DISPLAY_ADMIN_TABLES,
} from '../../../../CONSTANTS';

import Pagination from '../../../../components/Common/Table/Pagination';
import TableDatePicker from '../../../../components/Common/TableDatePicker';
import ContextMenu from '../../../../components/Common/ContextMenu';
import DeferPaymentModal from '../../../../components/Common/DeferPaymentModal';

import {
  Select,
  TableJssOverrides,
  NoRecordsText,
} from '../../../../components/Common/Table/Styles';

import { PaymentsTableProps } from './types';
import { TableColumn } from '../../../../components/Common/Table/types';
import { OrderDirection, PaymentFilterOptions, UpdatePaymentActionTypes } from '../../../../lib/types/API';
import { PaymentDataView } from '../../../../lib/types/DBViews';
import { InstallmentStatus } from '../../../../lib/types/Plan';
import { DeferPaymentModalProps } from '../../../../components/Common/DeferPaymentModal/types';

const PaymentsTable: React.FC<PaymentsTableProps<PaymentFilterOptions>> = ({
  currentData,
  recordCount,
  filter,
  updateFilter,
  clearFilters,
  handleUpdatePayments,
}) => {
  const [STRINGS] = useStrings();

  const [deferPaymentData, setDeferPaymentData] = useState<Omit<DeferPaymentModalProps, 'onClose' | 'cacheToUpdate' | 'filter'>>();
  const [deferModalOpen, setIsDeferModalOpen] = useState(false);

  const {
    handlePageChange,
    handleSortChange,
  } = buildHandlers<PaymentFilterOptions>(
    updateFilter,
    filter,
    undefined,
    undefined,
    clearFilters,
  );

  const noRecords = (
    <NoRecordsText>
      <p>{STRINGS.Components.Common.Table.noRecords}</p>
    </NoRecordsText>
  );

  const columns: TableColumn<PaymentDataView>[] = [
    {
      name: 'Type',
      selector: 'planType',
      sortable: true,
      grow: 0.8,
      format: (row) => PLAN_TYPE_DISPLAY_ADMIN_TABLES[row.planType as string],
    },
    {
      name: 'Reg No',
      selector: 'registration',
      sortable: true,
      grow: 0.2,
      format: (row) => row.registration?.toString() || '',
    },
    {
      name: 'Plan Name',
      selector: 'planName',
      sortable: true,
      grow: 1.5,
    },
    {
      name: 'Frequency',
      selector: 'frequency',
      sortable: true,
      grow: 1,
      format: (row) => INSTALLMENT_FREQUENCY_DISPLAY[row.frequency as string],
    },
    {
      name: 'Due Date',
      selector: 'dueDate',
      sortable: true,
      cell: ({ installmentStatus, scheduledDueDate, dueDate, id }) => {
        if (installmentStatus as string === InstallmentStatus.REJECTED
          || installmentStatus as string === InstallmentStatus.SKIPPED
          || installmentStatus as string === InstallmentStatus.TRANSFERRED
          || installmentStatus as string === InstallmentStatus.CANCELLED) {
          return (<>{dayjs((scheduledDueDate || dueDate) as Date).format('DD/MM/YYYY')} </>);
        }
        return (
          <TableDatePicker
            onLoadDate={(scheduledDueDate || dueDate) as Date}
            onSelect={(val) => handleUpdatePayments([id], UpdatePaymentActionTypes.SET_DUE_DATE, undefined, val)}
            inTable
            key={id}
          />
        );
      },
    },
    {
      name: 'Date Paid',
      selector: 'paidDate',
      sortable: true,
      cell: ({ installmentStatus, paidDate, id }) => {
        if (installmentStatus as string === InstallmentStatus.REJECTED
        || installmentStatus as string === InstallmentStatus.SKIPPED
        || installmentStatus as string === InstallmentStatus.TRANSFERRED
        || installmentStatus as string === InstallmentStatus.CANCELLED) {
          if (paidDate) return (<>{dayjs(paidDate as Date).format('DD/MM/YYYY')} </>);
          return <></>;
        }
        return (
          <TableDatePicker
            onLoadDate={paidDate as Date}
            onSelect={(val) => handleUpdatePayments([id], UpdatePaymentActionTypes.SET_PAID_DATE, val)}
            inTable
            key={id}
          />
        );
      },
    },
    {
      name: 'Plan Amount',
      selector: 'planValue',
      sortable: true,
      format: (row) => `$${Number(row.planValue).toFixed(2)}`,
    },
    {
      name: 'Instalment',
      selector: 'installmentValue',
      sortable: true,
      grow: 0.5,
      format: (row) => `$${Number(row.installmentValue).toFixed(2)}`,
    },
    {
      name: 'Fee',
      selector: 'adminFee',
      sortable: true,
      grow: 0.1,
      format: (row) => `$${Number(row.adminFee).toFixed(2)}`,
    },
    {
      name: 'Status',
      selector: 'installmentStatus',
      sortable: true,
      grow: 2.2,
      cell: ({ installmentStatus, installmentId, id, planId, userId, installmentValue, dueDate, scheduledDueDate }) => (
        <>
          <Select
            status={installmentStatus as string}
            disabled={installmentStatus as string === InstallmentStatus.REJECTED
              || installmentStatus as string === InstallmentStatus.SKIPPED
              || installmentStatus as string === InstallmentStatus.TRANSFERRED
              || installmentStatus as string === InstallmentStatus.CANCELLED}
            statusType="INSTALLMENT"
            onChange={(val) => handleUpdatePayments(
              [id],
              (val.target.value as keyof typeof InstallmentStatus) === InstallmentStatus.PAID
                ? UpdatePaymentActionTypes.MARK_AS_PAID
                : UpdatePaymentActionTypes.MARK_AS_UNPAID, (scheduledDueDate || dueDate) as Date,
            )}
          >
            <option value={installmentStatus as string}>
              {INSTALLMENT_STATUS_DISPLAY[installmentStatus as string]}
            </option>
            {hiddeninstallmentStatusOptions.map(({ value, label }) => value !== installmentStatus && (
              <option key={`installmentStatus - ${value}`} value={value}>{label}</option>
            ))}
          </Select>
          {installmentStatus === InstallmentStatus.SKIPPED
            ? (
              <ContextMenu
                forPaymentTable
                installmentStatus={InstallmentStatus.SKIPPED}
                onDeferClick={(status) => {
                  setDeferPaymentData({
                    userId: userId as string,
                    planId: planId as number,
                    installmentId: installmentId as number,
                    installmentValue: installmentValue as number,
                    dueDate: dueDate as Date,
                    reversal: true,
                    status,
                  });
                  setIsDeferModalOpen(true);
                }}
                disabled={installmentStatus as string !== InstallmentStatus.UNPAID
                  && installmentStatus as string !== InstallmentStatus.SKIPPED}
              />
            ) : (
              <ContextMenu
                forPaymentTable
                onDeferClick={(status) => {
                  setDeferPaymentData({
                    userId: userId as string,
                    planId: planId as number,
                    installmentId: installmentId as number,
                    installmentValue: installmentValue as number,
                    dueDate: dueDate as Date,
                    status,
                  });
                  setIsDeferModalOpen(true);
                }}
                disabled={installmentStatus as string !== InstallmentStatus.UNPAID
                  && installmentStatus as string !== InstallmentStatus.SKIPPED}
              />
            )}
        </>
      ),
    },
  ];

  return (
    <>
      {deferModalOpen && deferPaymentData && (
        <DeferPaymentModal
          {...deferPaymentData}
          cacheToUpdate="selectedCustomer"
          filter={filter}
          onClose={() => setIsDeferModalOpen(false)}
        />
      )}
      <DataTable
        subHeaderAlign="left"
        highlightOnHover
        noContextMenu
        noHeader
        data={currentData}
        keyField="key"
        columns={columns}
        pagination
        paginationServer
        paginationPerPage={filter.rowsPerPage}
        paginationTotalRows={recordCount}
        paginationDefaultPage={filter.page}
        onChangePage={handlePageChange}
        paginationComponent={Pagination}
        onSort={handleSortChange}
        sortServer
        defaultSortField={filter.orderColumn}
        defaultSortAsc={filter.orderDirection === OrderDirection.ASC}
        noDataComponent={noRecords}
        customStyles={TableJssOverrides}
        style={{ borderRadius: 0 }}
      />
    </>
  );
};

export default PaymentsTable;
