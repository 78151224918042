import styled from 'styled-components/macro';
import { DownChevronIcon } from '../../../assets/Icons';
import { getBorderColour } from '../../Common/Table/Styles';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  display: flex;
  height: auto;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: ${({ theme }) => theme.spacing.XS}rem;
  padding-left: ${({ theme }) => theme.spacing.XS}rem;
  padding-right: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const HeaderRow = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.XS}rem;
`;

export const Back = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: ${({ theme }) => theme.spacing.XXS * 0.5}rem;
  background-color: ${({ theme }) => theme.palette.btnGrey};
  margin-right: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const LocationPin = styled.img`
  width: 9px;
  height: 12px;
`;

export const CustomerName = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontScale.M as number * 1.1}rem;
`;

export const AddressWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-top: 2px;
  margin-bottom: 10px;
`;

export const CustomerAddress = styled.p`
  font-size: ${({ theme }) => theme.fontScale.M as number * 1.1}rem;
  font-weight: ${({ theme }) => theme.fontWeight.extraLight as number - 50}rem;;
  color: ${({ theme }) => theme.palette.grey30};
  margin-left: 5px;
`;

export const ImageContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacing.XXS}rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

export const ProfileImage = styled.div`
  position: relative;
  border-radius: 50%;
  background-color: rgba(53, 159, 234, 0.1);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: ${({ theme }) => theme.palette.btnActive};
    border: ${({ theme }) => `2px solid ${theme.basePalette.white}`};
    border-radius: 50%;
    margin-left: 35px;
    margin-bottom: 35px;
  }
`;

export const ProfileInitials = styled.div`
  color: ${({ theme }) => theme.basePalette.blueMain};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
`;

export const DollarIcon = styled.img`
  width: 56px;
  height: 56px;
  margin-right: ${({ theme }) => theme.spacing.XXS / 2}rem;
`;

export const EllipsesIcon = styled.img`
  width: 4px;
  height: 24px;
  margin: ${({ theme }) => theme.spacing.XXS}rem;

  @media (max-width: 1365px) {
    margin-left: ${({ theme }) => theme.spacing.XXS}rem;
  };
`;

interface SelectProps {
  statusType: 'PLAN' | 'PAYMENT' | 'INSTALLMENT' | 'CUSTOMER';
  status: string;
  disabled?: boolean;
}

export const Select = styled.select<SelectProps>`
  background: right 0.7rem top 55% ${({ disabled }) => !disabled && `url('${DownChevronIcon}')`}  no-repeat;
  appearance: none;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  border-radius: 25px;
  padding: 0.5rem 1rem;
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  border: 1px solid ${({ theme, statusType, status }) => getBorderColour(statusType, status, theme.palette)};
  max-width: 140px;
  font-family: ${({ theme }) => theme.fontFamily.body};

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.basePalette.white};
  }

  &:focus {
    outline: 0;
  }
`;