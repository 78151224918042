import React, { useState } from 'react';
import { useField } from 'formik';

import {
  StyledCheckboxOuter,
  StyledCheckboxContainer,
  StyledCheckbox,
  StyledCheckboxLabel,
  HiddenCheckbox,
  Icon,
  Error,
} from './Styles';

import { checkmarkIcon } from '../../../assets/Icons';

import { CheckboxProps } from './types';

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  disabled,
  marginBottom,
  children,
}) => {
  const [, meta, helpers] = useField(name);

  const {
    touched,
    error,
  } = meta;

  const {
    setValue,
    setError,
  } = helpers;

  const [checked, setIsChecked] = useState<boolean>(false);
  const checkboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = e.currentTarget.checked;
    setIsChecked(newVal);
    setValue(newVal);
    setError(null);
  };

  return (
    <StyledCheckboxOuter>
      {touched && error && <Error className="error">{error}</Error>}
      <StyledCheckboxLabel marginBottom={marginBottom}>
        <StyledCheckboxContainer>
          <HiddenCheckbox
            checked={checked}
            disabled={disabled}
            onChange={checkboxChange}
          />
          <StyledCheckbox checked={checked} error={!!error}>
            <Icon src={checkmarkIcon} />
          </StyledCheckbox>
        </StyledCheckboxContainer>
        {children}
      </StyledCheckboxLabel>
    </StyledCheckboxOuter>
  );
};

export default Checkbox;
