import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';

import useStrings from '../../../hooks/useStrings';
import { getAuthUser } from '../../../lib/Authentication';
import { uploadMedia } from '../../../lib/Media';

import Button from '../Button';
import FileInput from '../FileInput';
import Grid from '../Grid';

import {
  ModalWrapper,
  ButtonWrapper,
  ModalTitle,
} from '../CompletePlanModal/Styles';
import { UploadModalBackground, UploadModalTextWrapper } from './Styles';

import { imageHostingFilePaths } from '../../../CONSTANTS';
import { UploadReceiptProps } from './types';

const UploadReceipt: React.FC<UploadReceiptProps> = ({
  onConfirm,
  onCancel,
  parentError,
  userId,
}) => {
  const [{
    Components: { Common: {
      FileUploadModal,
      FileUploader,
    },
    }, GenericText }] = useStrings();

  const imageHostingPath = imageHostingFilePaths.customerPaidPlanReceipts;
  const stopClickBubbling = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  const [uploadError, setUploadError] = useState('');

  useEffect(() => {
    if (parentError) setUploadError(parentError);
  }, [parentError]);

  const uploadFile = async (file: File): Promise<string | null> => {
    setUploadError('');
    const authUser = getAuthUser();
    if (!authUser) return null;
    const filePath = `${imageHostingPath}/${userId}`;
    try {
      const url = await uploadMedia(file, filePath);
      return url;
    } catch (err) {
      setUploadError(FileUploader.uploadError);
    }
    return null;
  };

  return (
    <Formik
      initialValues={{
        receipt: undefined,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const url = await uploadFile(values.receipt as unknown as File);
        if (url) await onConfirm(url);
        else setUploadError(FileUploader.uploadError);
        setSubmitting(false);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values,
        isSubmitting,
      }) => (
        <UploadModalBackground
          onClick={onCancel}
        >
          <ModalWrapper
            onClick={stopClickBubbling}
          >
            <UploadModalTextWrapper>
              <ModalTitle>
                {FileUploadModal.title}
              </ModalTitle>
            </UploadModalTextWrapper>
            <Grid>
              <Grid row maxWidth="100%" marginTop="1rem">
                <Grid column>
                  <FileInput
                    onFileUploadComplete={((file) => {
                      setFieldValue('receipt', file);
                      setUploadError('');
                    })}
                    onFileUploadError={setUploadError}
                    value={values.receipt}
                    parentError={uploadError}
                  />
                </Grid>
              </Grid>
              <Grid row maxWidth="100%" marginTop="2rem">
                <Grid column>
                  <ButtonWrapper>
                    <Button
                      onClick={onCancel}
                      variant="secondary"
                      rounded
                      width="136px"
                    >
                      {GenericText.cancel}
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      variant="primary"
                      rounded
                      width="136px"
                      disabled={isSubmitting || !values.receipt || uploadError}
                      $loading={isSubmitting}
                    >
                      {GenericText.complete}
                    </Button>
                  </ButtonWrapper>
                </Grid>
              </Grid>
            </Grid>
          </ModalWrapper>
        </UploadModalBackground>
      )}
    </Formik>
  );
};

export default UploadReceipt;
