import React from 'react';

import {
  StyledButtonGroup,
  StyledButton,
  Divider,
} from './Styles';

import { CalculatorButtonProps } from './types';

const CalculatorButton: React.FC<CalculatorButtonProps> = ({
  data,
  onClick,
  defaultValue,
}) => (
  <StyledButtonGroup>
    {data.map((el, i) => {
      const { label, suffix, value } = el;
      const buttonValue = value.toString();
      const defaultButtonValue = defaultValue.toString();
      const isActive = defaultButtonValue === buttonValue;
      const showDivider = i !== data.length - 1;
      return (
        <StyledButton
          key={label}
          className={isActive ? 'is-active' : ''}
          type="button"
          value={buttonValue}
          defaultValue={defaultButtonValue}
          onClick={(event) => onClick(event, suffix)}
        >
          {label}
          {showDivider && <Divider />}
        </StyledButton>
      );
    })}
  </StyledButtonGroup>
);

export default CalculatorButton;
