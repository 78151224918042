import styled from 'styled-components';

export const AdminCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
`;

export const AdminCard = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.XXS}rem;
  border: solid 1px ${({ theme }) => theme.basePalette.greyMain};
  border-radius: 15px;
  min-width: 45%;
  margin: 1rem 2.5%;

  @media (max-width: 910px) {
    min-width: 95%;
  }
`;

export const TextWrapper = styled.div`
  margin-left: 24px;
  line-height: 1.5;
`;

export const AdminName = styled.p`
  font-size: ${({ theme }) => theme.fontScale.S}rem;
`;

export const AdminStatus = styled.p`
  color: ${({ theme }) => theme.basePalette.greyDark};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
`;
export const DeleteIcon = styled.img`
  background-color: ${({ theme }) => theme.basePalette.greyMain}; 
  width: 24px;
  height: 24px;
  padding: 8px;
  border-radius: 50%;
  margin-left: auto;
  &:hover {
    cursor: pointer;
  }
`;

export const ImageContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacing.XXS}rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

export const ProfileImage = styled.div`
  position: relative;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.basePalette.greyMain};
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProfileInitials = styled.div`
  color: ${({ theme }) => theme.basePalette.black};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  letter-spacing: 0.1rem;
`;
