import { Roles } from '../../../types/Auth';
import {
  PermissionsState,
  PermissionsActionTypes,
  SET_PERMISSIONS,
  SET_ROLE,
} from './types';

const initialState: PermissionsState = {
  permissions: [],
  role: Roles.DEFAULT,
};

const permissionsReducer = (
  state: PermissionsState = initialState,
  action: PermissionsActionTypes,
): PermissionsState => {
  switch (action.type) {
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions || [],
      };
    case SET_ROLE:
      return {
        ...state,
        role: action.role || Roles.DEFAULT,
      };
    default:
      return state;
  }
};

export default permissionsReducer;
