import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { getNotes, deleteNote, createNewNote, updateNote } from '../../../../lib/api/admin/notes';
import { ApplicationState } from '../../../../lib/store';

import useStrings from '../../../../hooks/useStrings';
import { deleteMedia } from '../../../../lib/Media';

import NoteInput from './NoteInput';
import NoteTab from './NoteTab';
import NoteDeleteConfirmModal from '../../../../components/Common/DeleteModal';

import { DeleteIcon, NotesAdd } from '../../../../assets/Icons';
import {
  ContentWrapper,
  NotesHeader,
  NotesMain,
  CreateNew,
  Icon,
  NotesList,
  NoteDisplay,
  Delete,
  List,
  NoteInfo,
  DeleteText,
} from './Styles';

import { NewNote, NewNoteFile, NotesProps } from './types';
import { APIThunkDispatch } from '../../../../lib/types/API';
import { Note, NoteFile } from '../../../../lib/types/DBModels';

const Notes: React.FC<NotesProps> = ({
  customer,
}) => {
  const Dispatch: APIThunkDispatch = useDispatch();
  const [{
    Components: { Common: { DeleteNoteModal } },
    Pages: { UserProfile: { Notes: { createNewText, deleteText } } },
    GenericText,
  }] = useStrings();

  const {
    customerNotesState: { customerNotes },
    adminUserState: { adminUser },
  } = useSelector((state: ApplicationState) => state.admin);

  const newNote = useMemo<NewNote>(() => ({
    description: '',
    createdAt: dayjs().toISOString(),
    createdByUser: { firstName: adminUser?.firstName, lastName: adminUser?.lastName },
    createdByUserId: adminUser?.userId,
    noteFiles: [],
    noteId: null,
  }), [adminUser]);

  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentNote, setCurrentNote] = useState<Note | NewNote>(customerNotes.length > 0 ? customerNotes[0] : newNote);
  const [dirty, setDirty] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const fetchNotes = useCallback(() => {
    Dispatch<void>(getNotes(customer.userId));
  }, [Dispatch, customer.userId]);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  useEffect(() => {
    if (customerNotes.length > 0 && !currentNote.noteId) {
      setCurrentNote(customerNotes[0]);
    } else if (customerNotes.length === 0) {
      setCurrentNote(newNote);
    }
    if (textAreaRef.current) textAreaRef.current.focus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerNotes, newNote]);

  const handleNewNote = () => {
    setCurrentNote(newNote);
    if (textAreaRef.current) textAreaRef.current.focus();
  };

  const handleDeleteNote = async (note: Note | NewNote) => {
    if (!note.noteId) {
      setCurrentNote(customerNotes[0] || newNote);
    } else {
      const results = note.noteFiles && note.noteFiles.map((file: NewNoteFile | NoteFile) => deleteMedia(file.url || ''));
      if (results) {
        await Promise.all(results);
        const data = await Dispatch(deleteNote(note.noteId));
        const newCustomerNotes = customerNotes.filter((element) => element.noteId !== note.noteId);
        if (data.success) setCurrentNote(newCustomerNotes[0] || newNote);
      }
    }
  };

  const saveNote = async (note: Note | NewNote) => {
    if (note.noteId) {
      await Dispatch(updateNote(note as Note, customer.userId));
    } else {
      await Dispatch(createNewNote(note, customer.userId));
    }
    setDirty(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleConfirm = async () => {
    setIsDeleting(true);
    await handleDeleteNote(currentNote);
    setIsDeleting(false);
    setIsOpen(false);
  };

  const handleAddAttachment = async (noteFile : NoteFile) => {
    const updatedNote = { ...currentNote, noteFiles: [...currentNote.noteFiles || [], noteFile] };
    setCurrentNote(updatedNote);
    await saveNote(updatedNote);
  };

  const handleRemoveAttachment = async (noteAttachmentUrl : string) => {
    await deleteMedia(noteAttachmentUrl);
    const updatedNote = { ...currentNote,
      noteFiles: currentNote.noteFiles?.filter((file) => file.url !== noteAttachmentUrl) };
    setCurrentNote(updatedNote);
    await saveNote(updatedNote);
  };

  const handleNoteChange = (val: string) => {
    setCurrentNote((state) => ({ ...state, description: val }));
    setDirty(true);
  };

  return (
    <ContentWrapper>
      <NoteDeleteConfirmModal
        isOpen={isOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        title={DeleteNoteModal.title}
        subtext={DeleteNoteModal.subtitle}
        cancelButtonText={DeleteNoteModal.cancelButtonText}
        confirmButtonText={DeleteNoteModal.confirmButtonText}
        isDeleting={isDeleting}
      />
      <NotesHeader>
        <CreateNew onClick={handleNewNote}>
          <Icon src={NotesAdd} alt="Create New Note" />
          <DeleteText>{createNewText}</DeleteText>
        </CreateNew>
        <Delete onClick={() => setIsOpen(true)}>
          <Icon src={DeleteIcon} alt="Delete Note" />
          <DeleteText>{deleteText}</DeleteText>
        </Delete>
      </NotesHeader>
      <NotesMain>
        <NotesList>
          <List>
            {!currentNote.noteId ? (
              <NoteTab
                note={currentNote}
                active
                onClick={() => {}}
              />
            ) : null}
            {customerNotes.sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix()).map((note) => (
              <NoteTab
                onClick={() => {
                  setCurrentNote(note);
                  setDirty(false);
                  if (textAreaRef.current) textAreaRef.current.focus();
                }}
                note={note}
                key={note.noteId}
                active={note.noteId === currentNote.noteId}
              />
            ))}
          </List>
        </NotesList>
        <NoteDisplay>
          <NoteInfo>
            {`${dayjs(currentNote.createdAt).format('Do MMMM YYYY')} 
            ${GenericText.at} ${dayjs(currentNote.createdAt).format('h:mm a')} 
            ${GenericText.by} ${currentNote?.createdByUser?.firstName} ${currentNote?.createdByUser?.lastName}`}
          </NoteInfo>
          <NoteInput
            note={currentNote}
            dirty={dirty}
            handleAddAttachment={handleAddAttachment}
            handleRemoveAttachment={handleRemoveAttachment}
            handleNoteChange={handleNoteChange}
            saveNote={saveNote}
            textAreaRef={textAreaRef}
            userId={customer.userId}
          />
        </NoteDisplay>
      </NotesMain>
    </ContentWrapper>
  );
};

export default Notes;