import { PlanDataView } from '../../../../../types/DBViews';

import {
  SetAllTransferablePlansAction,
  SET_ALL_TRANSFERABLE_PLAN_DATA,
} from './types';

export const setAllTransferablePlanData = (
  transferablePlans: PlanDataView[],
): SetAllTransferablePlansAction => ({
  type: SET_ALL_TRANSFERABLE_PLAN_DATA,
  transferablePlans,
});
