import styled from 'styled-components';

export const Container = styled.div`
  align-items: flex-end;
  display: flex;
  height: 100%;
  overflow: hidden;
  justify-content: space-between;
  width: 100%;
`;

export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
`;

export const ContainerRight = styled.div`
  height: 100%;
  max-height: 100%;
  flex-grow: 1;
`;

export const Value = styled.span`
  color: ${({ theme }) => theme.basePalette.navyMain};
  display: block;
  font-size: ${({ theme }) => theme.fontScale.XXL as number / 1.05}rem;
  font-family: ${({ theme }) => theme.fontFamily.gotham};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  margin: 0 5px -5px 0;
`;

export const TooltipText = styled.span`
  color: ${({ theme }) => theme.basePalette.white};
  font-size: ${({ theme }) => theme.fontScale.S as number * 1.5}rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const TooltipSubtext = styled.span`
  color: ${({ theme }) => theme.basePalette.greyDark};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
`;
