import styled from 'styled-components/macro';

import { HeadingProps, MainContentProps } from './types';

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.bgSecondary};
  min-height: 100vh;
`;

export const FormStepsContainer = styled.div<MainContentProps>`
  margin-top: ${({ theme }) => theme.spacing.XXS}rem;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.device.tablet} {
    margin-top: ${({ theme }) => theme.spacing.XS}rem;
    max-width: ${({ maxWidth }) => maxWidth};
  }
`;

export const ContentWrapper = styled.main<MainContentProps>`
  flex-direction: column;
  display: flex;
  background-color: ${({ theme }) => theme.palette.bgPrimary};
  z-index: 1;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ theme }) => theme.spacing.XXS}rem;

  ${({ theme }) => theme.device.tablet} {
    border-radius: 15px;
    margin-top: ${({ theme }) => theme.spacing.XS}rem;
    margin-bottom: ${({ theme }) => theme.spacing.S}rem;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  }
`;

export const AsideContentWrapper = styled.aside<MainContentProps>`
  flex-direction: column;
  display: flex;
  background-color: ${({ theme }) => theme.palette.bgSecondary};
  z-index: 1;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  margin-left: auto;
  margin-right: auto;
  padding: ${({ theme }) => theme.spacing.XXS}rem;

  ${({ theme }) => theme.device.tablet} {
    background-color: ${({ theme }) => theme.palette.bgPrimary};
    border-radius: 15px;
    margin-top: ${({ theme }) => theme.spacing.XS}rem;
    margin-bottom: ${({ theme }) => theme.spacing.S}rem;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    padding: 0;
  }
`;

export const MainContentHeader = styled.div`
  background: ${({ theme }) => theme.basePalette.navyMain};
  padding: ${({ theme }) => theme.spacing.XXS}rem;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding: ${({ theme }) => theme.spacing.XS * 1.3}rem;
  }
`;

export const BackgroundTexture = styled.img`
  position: absolute;
  top: -270px;
  right: -500px;
`;

export const ContentHeading = styled.h2<HeadingProps>`
  color: ${({ highlight, theme }) => (highlight ? theme.palette.titleHighlight : theme.basePalette.white)};
  font-size: ${({ theme }) => theme.fontScale.XL}rem;
  display: inline;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export const ContentSubheading = styled.p`
  color: ${({ theme }) => theme.basePalette.white};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.extraLight};
  margin-top: 10px;
  display: block;
`;
