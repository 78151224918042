import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components/macro';
import { initializeApp, apps } from 'firebase/app';

import Routes from './routes';
import NotificationContainer from './components/UI/NotificationContainer';
import LoadingScreen from './components/UI/LoadingScreen';

import useAuth from './hooks/useAuth';
import { setPermissions, setRole } from './lib/store/contexts/permissions/actions';
import FirebaseConfig from './lib/firebase-config.json';
import { Roles } from './lib/types/Auth';
import { getProfile } from './lib/api/customer/profile';
import { APIThunkDispatch } from './lib/types/API';
import { ApplicationState } from './lib/store';

import GlobalFonts from './style/themes/globalFonts';
import appTheme from './style/themes/appTheme';
import { reset } from './style/themes/reset';

const GlobalStyle = createGlobalStyle`
  ${reset}
`;

// Firebase Initialize
if (!apps.length) initializeApp(FirebaseConfig);

const App: React.FC = () => {
  const dispatch: APIThunkDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { profileState: { profileData } } = useSelector((state: ApplicationState) => state.customer);

  const { permissions, pending, role } = useAuth();

  const loggedIn = role !== Roles.DEFAULT;
  const isLoading = loading || pending;

  dispatch(setPermissions(permissions));
  dispatch(setRole(role));

  const getProfileData = useCallback(() => {
    if (loggedIn && role === Roles.CUSTOMER) {
      setLoading(true);
      dispatch(getProfile()).then(() => {
        setLoading(false);
      }).catch(() => {});
    }
  }, [dispatch, loggedIn, role]);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  return (
    <ThemeProvider theme={appTheme}>
      <GlobalStyle />
      <GlobalFonts />
      {isLoading && !profileData ? <LoadingScreen /> : (
        <div className="App">
          <Router>
            <Routes permissions={permissions} role={role} profileData={profileData} />
          </Router>
          <NotificationContainer />
        </div>
      )}
    </ThemeProvider>
  );
};

export default App;
