import React from 'react';

import { LogoContainer, LogoImg } from './Styles';

import { frLogo } from '../../../assets/Images';

const Logo: React.FC = () => (
  <LogoContainer>
    <LogoImg src={frLogo} />
  </LogoContainer>
);

export default Logo;
