import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import Select from 'react-select';

import {
  StyledDropdown,
  MetaWrapper,
  Label,
  Error,
  IconWrapper,
  Icon,
} from '../Styles';

import { VehicleDropdownProps } from './types';
import { blueCaretDown } from '../../../../assets/Icons';

import { VehicleDropdownOption } from '../../../../lib/types/Dropdown';

// Currently used as value for vehicle makes must be a number
// TODO: update existing dropdowns to handle this so we don't need this component
const VehicleDropdown: React.FC<VehicleDropdownProps> = ({
  name,
  onLoadOption,
  options,
  placeholder,
  label,
  disabled = false,
  onOptionClick,
  outlined = false,
  uppercaseLabel = false,
}) => {
  const [value, setValue] = useState<VehicleDropdownOption | null>();

  // Component Must be used inside of <Formik> tags to access Formik context
  const [, meta] = useField(name);
  const { touched, error } = meta;

  const SelectIcon = () => (
    <IconWrapper>
      <Icon src={blueCaretDown} />
    </IconWrapper>
  );

  useEffect(() => {
    if (onLoadOption === undefined) return;
    const currentOption = options.find((option) => option.value === onLoadOption) || null;
    setValue(currentOption);
  }, [onLoadOption, options]);

  return (
    <StyledDropdown
      outlined={outlined}
      disabled={disabled}
      error={!!error && touched}
    >
      <MetaWrapper>
        {!!label && <Label uppercaseLabel={uppercaseLabel}>{label}</Label>}
      </MetaWrapper>
      <Select
        classNamePrefix="react-select"
        options={options}
        disabled={disabled}
        onChange={(option) => option && onOptionClick(option)}
        value={value}
        placeholder={placeholder}
        isDisabled={disabled}
        components={{ DropdownIndicator: SelectIcon }}
      />
      {touched && error && <Error className="error">{error}</Error>}
    </StyledDropdown>
  );
};

export default VehicleDropdown;
