import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { ApplicationState, AppActions } from '../store';
import { CustomerDataView, PaymentDataView, PlanDataView } from './DBViews';

export interface ErrorInfo { name: string, debug?: string }

export type APIResult<T = undefined> = {
  success: boolean;
  error?: { info: string | ErrorInfo, message: string, code: number };
  data?: T;
};

export type APIThunkResult<T = undefined> = ThunkAction<
Promise<APIResult<T>>,
ApplicationState,
undefined,
AppActions
>;

export type APIThunkDispatch = ThunkDispatch<ApplicationState, undefined, AppActions>;

export type APIRequestType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export enum APIStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  UNCHANGED = 'UNCHANGED',
}

export interface APIErrorResponse {
  name: string;
  message: string;
  data?: Record<string, unknown>;
}

export interface FetchOptions {
  method: APIRequestType;
  url: string;
  param?: Record<string, unknown>;
  headers?: Record<string, unknown>;
  body?: Record<string, unknown>;
}

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum UpdatePaymentActionTypes {
  SET_PAID_DATE = 'SET_PAID_DATE',
  DELETE = 'DELETE',
  MARK_AS_PAID = 'MARK_AS_PAID',
  MARK_AS_UNPAID = 'MARK_AS_UNPAID',
  SET_DUE_DATE = 'SET_DUE_DATE',
}
export interface APIFilterOptions {
  page?: number;
  rowsPerPage?: number;
  orderColumn?: string;
  orderDirection?: OrderDirection;
  keyword?: string;
}

export interface PlanFilterOptions extends APIFilterOptions {
  keyword?: string;
  customerId?: number;
  status?: string[];
  planType?: string[];
  state?: string[];
  planMinValue?: number;
  planMaxValue?: number;
  minDueDate?: Date;
  maxDueDate?: Date;
  minStartDate?: Date;
  maxStartDate?: Date;
  searchForNull?: Array<keyof PlanDataView>
}

export interface PaymentFilterOptions extends APIFilterOptions {
  keyword?: string;
  userId?: string;
  planId?: string;
  customerId?: number;
  status?: string[];
  planStatus?: string;
  planType?: string[];
  installmentMinValue?: number;
  installmentMaxValue?: number;
  planMinValue?: number;
  planMaxValue?: number;
  minDueDate?: Date;
  maxDueDate?: Date;
  minPaidDate?: Date;
  maxPaidDate?: Date;
  searchForNull?: Array<keyof PaymentDataView>
}

export interface CustomerFilterOptions extends APIFilterOptions {
  keyword?: string;
  customerId?: number;
  customerStatus?: string[];
  paymentStatus?: string[];
  state?: string[];
  searchForNull?: Array<keyof CustomerDataView>
}

export interface StatsFilterOptions {
  fromDate?: Date;
  toDate?: Date
}

export interface PlanPaymentsQuery {
  planId: number;
  userId: string;
}
