import React from 'react';

import { StyledButton, LoadingSpinner } from './Styles';

import ButtonProps from './types';

const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  width,
  height,
  margin,
  padding,
  disabled,
  $loading,
  rounded = false,
  inline = true,
  textTransform,
  expandedMobile = true,
  fontSize,
  children,
  onClick,
  backgroundOverride,
  type = 'button',
}) => (
  <StyledButton
    onClick={onClick}
    rounded={rounded}
    inline={inline}
    variant={variant}
    width={width}
    height={height}
    margin={margin}
    padding={padding}
    disabled={disabled || $loading}
    $loading={$loading}
    textTransform={textTransform}
    expandedMobile={expandedMobile}
    fontSize={fontSize}
    backgroundOverride={backgroundOverride}
    type={type}
  >
    {$loading ? <LoadingSpinner /> : children}
  </StyledButton>
);

export default Button;
