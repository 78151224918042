import styled from 'styled-components/macro';
import { ModalStyleProps, ModalTextProps } from './types';

export const ModalBackground = styled.div<ModalStyleProps>`
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const ModalWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.basePalette.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: default;
  border-radius: 24px;
  padding: ${({ theme }) => `${theme.spacing.XS}rem ${theme.spacing.S}rem`};
  margin: ${({ theme }) => `0 ${theme.spacing.XXS}rem`};

  ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => `${theme.spacing.S}rem ${theme.spacing.M}rem`};
    margin: 0;
  };
`;

export const Icon = styled.img`
  margin-bottom: ${({ theme }) => theme.spacing.XS / 1.3}rem;
  height: 80px;
  width: 80px;
`;

export const ModalTitle = styled.h4<ModalTextProps>`
  margin: ${({ theme }) => theme.spacing.XXS}rem 0;
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-size: ${({ theme }) => theme.fontScale.L}rem;
  display: inline;
  color: ${({ highlight, theme }) => highlight && theme.palette.titleHighlight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export const ModalSubtext = styled.p<ModalTextProps>`
  margin-top: 1rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  text-align: center;
  color: ${({ highlight, theme }) => highlight && theme.palette.titleHighlight};
`;

export const ModalBodyText = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: ${({ theme }) => `${theme.spacing.XS}rem`};
`;

export const ButtonWrapper = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
  }
`;
