import {
  apiFetch,
  apiRequest,
  objectToQueryString,
  stripDate,
} from '../Utils';
import {
  setAllPaymentData,
  setUpdatedPaymentData,
} from '../../store/contexts/admin/payments/actions';
import {
  setAllPaymentData as setAllSelectedCustomerPaymentData,
  setUpdatedPaymentData as setUpdatedSelectedCustomerPaymentData,
  setPlansForPaymentFilter,
  setNeedsUpdate,
} from '../../store/contexts/admin/selectedCustomer/payments/actions';
import { setNotification } from '../../store/contexts/notification/actions';
import {
  removeOverdueInstallmentData,
  setAllOverdueInstallmentData,
  setUpdatedOverdueInstallmentData,
} from '../../store/contexts/admin/overdueInstallments/actions';
import { getPayablePlans } from './plans';
import { DEFAULT_FILTER, serverAdminAPI, UPDATE_PAYMENT_ACTION_TYPES_DISPLAY } from '../../../CONSTANTS';

import {
  APIThunkResult,
  OrderDirection,
  PaymentFilterOptions,
  UpdatePaymentActionTypes,
  PlanPaymentsQuery,
} from '../../types/API';
import {
  FilteredPaymentsDTO,
  PaymentsUpdateDTO,
  DeferPaymentDTO,
  PaymentsDTO,
  PaymentLimitsDTO,
  PlansForPaymentFilterDTO,
} from '../../types/DTO';
import { InstallmentStatus } from '../../types/Plan';

export const defaultFilter = {
  page: DEFAULT_FILTER.page,
  rowsPerPage: DEFAULT_FILTER.rowsPerPage,
  orderColumn: 'dueDate',
  orderDirection: OrderDirection.ASC,
};

export const getPayments = (
  filter: PaymentFilterOptions,
  subSelect?: 'OVERDUE_INSTALLMENTS',
): APIThunkResult<FilteredPaymentsDTO> => (
  apiRequest<FilteredPaymentsDTO>(async (dispatch) => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverAdminAPI}/payments?${query}`;

    const { data, error } = await apiFetch<FilteredPaymentsDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    if (filter.customerId) dispatch(setAllSelectedCustomerPaymentData(data.payments));
    else if (subSelect === 'OVERDUE_INSTALLMENTS') {
      dispatch(setAllOverdueInstallmentData(data.payments));
    } else dispatch(setAllPaymentData(data.payments));

    dispatch(setNeedsUpdate(false));

    return data;
  }, true)
);

export const getPlanPayments = (
  query: PlanPaymentsQuery,
): APIThunkResult<PaymentsDTO> => (
  apiRequest<PaymentsDTO>(async () => {
    const url = `${serverAdminAPI}/payments/${query.userId}/${query.planId}`;

    const { data, error } = await apiFetch<PaymentsDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);

export const deferPayment = (
  installmentId: number,
  status: string,
): APIThunkResult<DeferPaymentDTO> => (
  apiRequest<DeferPaymentDTO>(async () => {
    const url = `${serverAdminAPI}/payments/defer`;

    const { data, error } = await apiFetch<DeferPaymentDTO>({
      method: 'POST',
      url,
      body: {
        installmentId,
        status,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);

export const reverseDeferredPayment = (
  installmentId: number,
): APIThunkResult<DeferPaymentDTO> => (
  apiRequest<DeferPaymentDTO>(async () => {
    const url = `${serverAdminAPI}/payments/reverse-deferral`;

    const { data, error } = await apiFetch<DeferPaymentDTO>({
      method: 'POST',
      url,
      body: {
        installmentId,
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);

export interface UpdatePaymentsPayload {
  installmentIds: number[],
  actionType: UpdatePaymentActionTypes,
  paidDate?: Date,
  dueDate?: Date,
  customerId?: number,
  view?: 'OVERDUE_INSTALLMENTS',
}
export const updatePayments = (
  {
    installmentIds,
    actionType,
    paidDate,
    dueDate,
    customerId,
    view,
  }: UpdatePaymentsPayload,
): APIThunkResult<PaymentsUpdateDTO> => apiRequest<PaymentsUpdateDTO>(
  async (dispatch, getState) => {
    const userId = getState().admin.customerState.selectedCustomer?.userId;
    const { data, error } = await apiFetch<PaymentsUpdateDTO>({
      method: 'POST',
      url: `${serverAdminAPI}/payments/update`,
      body: {
        installmentIds,
        actionType,
        paidDate: stripDate(paidDate),
        dueDate: stripDate(dueDate),
      },
    });

    if (!data || error) throw new Error(`${error?.message}`);

    let updateError = false;

    // Cache management
    // TODO: This is a bit silly. Need to look at a better way to update local data.
    if (data.success) {
      if (data.payments) {
        if (customerId) dispatch(setUpdatedSelectedCustomerPaymentData(data.payments)); // customer payments table
        else if (view === 'OVERDUE_INSTALLMENTS') {
          dispatch(setUpdatedOverdueInstallmentData(data.payments)); // Overdue installments table
          dispatch(removeOverdueInstallmentData(installmentIds)); // remove from overdue installments table after updating
        } else dispatch(setUpdatedPaymentData(data.payments)); // All payments table

        if (data.payments.length > 1) {
          dispatch(setNotification({ message: `${data.payments.length} Payments ${UPDATE_PAYMENT_ACTION_TYPES_DISPLAY[actionType]}`, variant: 'success', duration: 3 }));
        } else if (data.payments.length === 1) {
          if (dueDate) dispatch(setNotification({ message: `Due date updated for ${data.payments[0].customerName}'s Payment`, variant: 'success', duration: 3 }));
          else dispatch(setNotification({ message: `${data.payments[0].customerName}'s Payment was ${UPDATE_PAYMENT_ACTION_TYPES_DISPLAY[actionType]}`, variant: 'success', duration: 3 }));
        } else dispatch(setNotification({ message: 'Payments updated', variant: 'success', duration: 3 }));
      } else updateError = true; // error - refresh cache.
    } else updateError = true;

    if (updateError) {
      if (customerId) dispatch<void>(getPayments({ ...defaultFilter, customerId }));
      else if (view === 'OVERDUE_INSTALLMENTS') {
        dispatch<void>(getPayments(
          {
            ...defaultFilter,
            status: [InstallmentStatus.UNPAID],
            maxDueDate: new Date(),
          },
          'OVERDUE_INSTALLMENTS',
        ));
      } else dispatch<void>(getPayments(defaultFilter));
      throw new Error('Error updating some payments');
    }

    if (userId) dispatch<void>(getPayablePlans(userId)); // update payable plans. userId should never be null here.
    return data;
  },
  true,
);

export const getPaymentFilterLimits = (
  filter: PaymentFilterOptions,
): APIThunkResult<PaymentLimitsDTO> => (
  apiRequest<PaymentLimitsDTO>(async () => {
    const query = objectToQueryString(Object(filter));
    const url = `${serverAdminAPI}/payments/filter-limit?${query}`;

    const { data, error } = await apiFetch<PaymentLimitsDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    return data;
  }, true)
);

export const getPlansForPaymentFilter = (
  customerId: number,
): APIThunkResult<PlansForPaymentFilterDTO> => (
  apiRequest<PlansForPaymentFilterDTO>(async (dispatch) => {
    const url = `${serverAdminAPI}/payments/plans-for-payment-filter/${customerId}`;

    const { data, error } = await apiFetch<PlansForPaymentFilterDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setPlansForPaymentFilter(data.plans));

    return data;
  }, true)
);
