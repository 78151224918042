import * as yup from 'yup';

const updatePasswordSchema = yup.object({
  currentPassword: yup
    .string()
    .trim()
    .required('Your old password is required'),
  newPassword: yup
    .string()
    .trim()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$^+=!*()@%&?-_]).{8,99}$/, 'Your password must be at least 8 characters long, contain at least one number, one special character and have a mixture of uppercase and lowercase letters')
    .required('Password is required'),
}).required();

export type UpdatePasswordSchema = yup.InferType<typeof updatePasswordSchema>;

export default updatePasswordSchema;
