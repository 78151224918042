import { FontFamily, FontScale, FontWeight } from '../../lib/types/Style';

export const fontFamily: FontFamily = {
  heading: 'Bebas Neue',
  bodyBold: 'Poppins Bold',
  body: 'Poppins Regular',
  bodyLight: 'Poppins Light',
  gotham: 'Gotham',
};

// What scale adapts to the font-size relationship (golden ratio, minor third, perfect fourth etc.)
export const fontScale: FontScale = {
  XXS: 0.569,
  XS: 0.686,
  S: 0.829,
  M: 1,
  L: 1.4,
  XL: 2,
  XXL: 2.8,
};

// Font Weight
export const fontWeight: FontWeight = {
  extraLight: 150,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  black: 800,
};
