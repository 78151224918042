import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useStrings from '../../../hooks/useStrings';
import {
  defaultFilter,
  getPayments,
  updatePayments,
  getPaymentFilterLimits,
} from '../../../lib/api/admin/payments';

import PageLayout from '../../../layouts/PageLayout';
import OverdueInstallmentsTable from './OverdueInstallmentsTable';

import { OverdueInstallmentsProps } from './types';
import {
  APIThunkDispatch,
  UpdatePaymentActionTypes,
  PaymentFilterOptions,
} from '../../../lib/types/API';
import { ApplicationState } from '../../../lib/store';
import { InstallmentStatus, PaymentFilterLimits } from '../../../lib/types/Plan';

const OverdueInstallments: React.FC<OverdueInstallmentsProps> = () => {
  const [{ Pages: { OverdueInstallments: Strings } }] = useStrings();
  const Dispatch: APIThunkDispatch = useDispatch();

  const { overdueInstallmentData } = useSelector((state: ApplicationState) => state.admin.overdueInstallmentState);

  // We are only fetching certain payments defined here
  // Unpaid and due
  const fixedFilter = {
    status: [InstallmentStatus.UNPAID],
  } as Partial<PaymentFilterOptions>;

  const initialFilter = {
    maxDueDate: new Date(),
  } as Partial<PaymentFilterOptions>;

  // Filter management
  const [filter, setFilter] = useState<PaymentFilterOptions>({
    ...defaultFilter,
    ...fixedFilter,
    ...initialFilter,
  });
  const clearFilters = () => setFilter({
    ...defaultFilter,
    ...fixedFilter,
    ...initialFilter,
  });
  const updateFilter = (newFilter: PaymentFilterOptions) => {
    setFilter({
      ...defaultFilter,
      ...newFilter,
      ...fixedFilter,
    });
  };
  const [filterLimits, setFilterLimits] = useState<PaymentFilterLimits>();

  const onUpdatePayments = (installmentIds: number[], actionType: UpdatePaymentActionTypes, paidDate?: Date) => {
    Dispatch<void>(updatePayments({ installmentIds, actionType, paidDate, customerId: NaN, view: 'OVERDUE_INSTALLMENTS' }));
  };

  // Fetch data
  const fetchPayments = useCallback(() => {
    Dispatch<void>(getPayments(filter, 'OVERDUE_INSTALLMENTS')); // second argument denotes which store to use
  }, [Dispatch, filter]);

  const fetchPaymentFilterLimits = useCallback(async () => {
    const { data } = await Dispatch(getPaymentFilterLimits(
      { status: [InstallmentStatus.UNPAID], maxDueDate: new Date() },
    ));
    if (data) setFilterLimits(data);
  }, [Dispatch]);

  useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);

  useEffect(() => {
    fetchPaymentFilterLimits().catch(() => null);
  }, [fetchPaymentFilterLimits]);

  const currentData = overdueInstallmentData.overdueInstallments.map(
    (payment) => ({ id: payment.installmentId, ...payment }),
  );

  useEffect(() => {
    if (overdueInstallmentData.overdueInstallments.length === 0 && overdueInstallmentData.count > 0) {
      fetchPayments();
    }
  }, [overdueInstallmentData, fetchPayments]);
  return (
    <PageLayout
      pageTitle={Strings.pageTitle}
      maxWidth="100%"
    >
      <OverdueInstallmentsTable
        currentData={currentData}
        recordCount={overdueInstallmentData.count}
        filter={filter}
        updateFilter={updateFilter}
        clearFilters={clearFilters}
        handleUpdatePayments={onUpdatePayments}
        filterLimits={filterLimits}
      />
    </PageLayout>

  );
};

export default OverdueInstallments;
