import { PaymentDataView } from '../../../../types/DBViews';

export const SET_ALL_OVERDUE_INSTALLMENT_DATA = 'SET_ALL_OVERDUE_INSTALLMENT_DATA';
export const SET_UPDATED_OVERDUE_INSTALLMENT_DATA = 'SET_UPDATED_OVERDUE_INSTALLMENT_DATA';
export const REMOVE_OVERDUE_INSTALLMENTS = 'REMOVE_OVERDUE_INSTALLMENTS';

export interface OverdueInstallmentState {
  overdueInstallmentData: {
    overdueInstallments: PaymentDataView[];
    count: number;
  }
}
export interface SetAllOverdueInstallmentsAction {
  type: typeof SET_ALL_OVERDUE_INSTALLMENT_DATA;
  overdueInstallmentData: {
    results: PaymentDataView[];
    count: number;
  }
}
export interface SetUpdatedOverdueInstallmentsAction {
  type: typeof SET_UPDATED_OVERDUE_INSTALLMENT_DATA;
  overdueInstallments: PaymentDataView[],
}
export interface RemoveOverdueInstallmentsAction {
  type: typeof REMOVE_OVERDUE_INSTALLMENTS;
  installmentIds: number[],
}

export type OverdueInstallmentActionTypes =
SetAllOverdueInstallmentsAction |
SetUpdatedOverdueInstallmentsAction |
RemoveOverdueInstallmentsAction;
