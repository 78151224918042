import { User } from '../../../../types/DBModels';

import {
  SetAdminUserAction,
  SET_ADMIN_USER,
} from './types';

export const setAdminUser = (
  adminUser: User,
): SetAdminUserAction => ({
  type: SET_ADMIN_USER,
  adminUser,
});
