import React, { ChangeEvent, useState, useEffect } from 'react';

import {
  StyledInput,
  Label,
  OuterWrapper,
  MetaWrapper,
  InnerWrapper,
  Error,
  DollarIcon,
  PasswordToggle,
} from './Styles';

import { ModalInputProps } from './types';
import { PasswordHide, PasswordShow } from '../../../assets/Icons';

const ModalInput: React.FC<ModalInputProps> = ({
  label,
  type,
  onLoadValue,
  disabled = false,
  touched,
  error,
  placeholder,
  onChange,
  onBlur,
  setTouched,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [value, setValue] = useState<string>(onLoadValue || '');
  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (setTouched) setTouched(true);
    onChange(e);
  };

  // To handle toggle show password logic
  const inputType = type === 'password' && isPasswordVisible
    ? 'text'
    : type;

  useEffect(() => {
    if (onLoadValue) setValue(onLoadValue);
    else setValue('');
  }, [onLoadValue]);

  return (
    <OuterWrapper
      isCurrency={type === 'currency'}
      className="text-input"
      disabled={disabled}
      error={error}
    >
      <MetaWrapper>
        {label && (<Label disabled={disabled} error={error} isCurrency={type === 'currency'}>{label}</Label>)}
      </MetaWrapper>
      <InnerWrapper>
        <StyledInput
          isCurrency={type === 'currency'}
          className="text-input"
          disabled={disabled}
          value={value}
          type={inputType === 'currency' ? 'text' : inputType}
          error={error}
          onChange={onValueChange}
          onBlur={onBlur}
          placeholder={placeholder}
        />
        {type === 'currency' && (
          <DollarIcon>&nbsp;$</DollarIcon>
        )}
        {type === 'password' && (
          <PasswordToggle
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          >
            <img
              alt="Toggle Password"
              src={isPasswordVisible ? PasswordHide : PasswordShow}
            />
          </PasswordToggle>
        )}
      </InnerWrapper>
      {touched && error && !disabled && <Error className="error">{error}</Error>}
    </OuterWrapper>
  );
};

export default ModalInput;
