import {
  SET_CUSTOMER_PAYMENT_DATA,
  PaymentsState,
  PaymentsActionTypes,
} from './types';

const initialState: PaymentsState = {
  payments: [],
};

const plansReducer = (
  state: PaymentsState = initialState,
  action: PaymentsActionTypes,
): PaymentsState => {
  switch (action.type) {
    case SET_CUSTOMER_PAYMENT_DATA:
      return {
        ...state,
        payments: action.payments,
      };
    default:
      return state;
  }
};

export default plansReducer;
