import styled from 'styled-components/macro';
import { ButtonWrapperProps } from './types';

export const FormSection = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.XS}rem 0`};

  ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => `${theme.spacing.XXS * 1.5}rem ${theme.spacing.XS}rem`};
  }
`;

export const LineBreak = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.borderGrey};
`;

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  justify-content: ${({ isEditProfile }) => (isEditProfile ? 'flex-end' : 'space-between')};
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
`;
