import { PlanDataView } from '../../../../../types/DBViews';

export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';
export const CLEAR_SELECTED_PLAN = 'CLEAR_SELECTED_PLAN';

export interface SelectedPlanState {
  planData: PlanDataView | null;
}
export interface SetSelectedPlanAction {
  type: typeof SET_SELECTED_PLAN;
  planData: PlanDataView;
}

export interface ClearSelectedPlanAction {
  type: typeof CLEAR_SELECTED_PLAN;
}

export type SelectedPlanActionTypes =
SetSelectedPlanAction |
ClearSelectedPlanAction;
