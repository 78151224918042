import React from 'react';

import { StyledBackButton, Icon } from './Styles';

import { darkLeftArrow } from '../../../assets/Icons';

import { BackButtonProps } from './types';

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => (
  <StyledBackButton title="Go back a step" onClick={onClick}>
    <Icon src={darkLeftArrow} />
  </StyledBackButton>
);

export default BackButton;
