import styled from 'styled-components/macro';

import { IconProps, StyledDatePickerProps } from './types';

export const StyledDatePicker = styled.div<StyledDatePickerProps>`
  opacity: ${({ notEditable }) => (notEditable ? 0.5 : 1)};
  position: relative;
  ${({ disabled, notEditable }) => !disabled && !notEditable && 'cursor: pointer'};
  ${({ disabled, notEditable }) => (disabled || notEditable) && 'pointer-events: none'};
  min-height: 94px;
  min-width: 195px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.5rem;
  border-radius: 24px;
  ${({ theme, error }) => (error && `background-color: ${theme.palette.inputErrorBg}`)};
  border: 1px solid ${({ error, theme, disabled }) => {
    if (error) return theme.palette.btnError;
    if (disabled) return theme.palette.btnGrey;
    return theme.palette.btnDarkGrey;
  }};

  &:hover {
    border: 1px solid ${({ error, theme, disabled, notEditable }) => {
    if (error) return theme.palette.btnError;
    if (disabled || notEditable) return theme.palette.btnGrey;
    return theme.basePalette.blueMain;
  }};
  }
  
  
  // Style overrides
  .react-date-picker {  
    color: ${({ theme, disabled }) => (disabled ? theme.basePalette.greyMain : theme.basePalette.navyMain)};

    input {
      &::-webkit-input-placeholder { /* Edge */
        font-family: ${({ theme }) => theme.fontFamily.body};
        color: ${({ theme, disabled }) => (disabled ? theme.basePalette.greyMain : theme.basePalette.greyDark)};
        opacity: 1;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-family: ${({ theme }) => theme.fontFamily.body};
        color: ${({ theme, disabled }) => (disabled ? theme.basePalette.greyMain : theme.basePalette.greyDark)};
        opacity: 1;
      }

      &::placeholder {
        font-family: ${({ theme }) => theme.fontFamily.body};
        color: ${({ theme, disabled }) => (disabled ? theme.basePalette.greyMain : theme.basePalette.greyDark)};
        opacity: 1;
      }
    }
  }

  .react-date-picker__wrapper {
    width: 100%;
    border: none;
  }

  .react-calendar {
    border: 1px solid ${({ theme }) => theme.basePalette.greyMain};
    border-radius: 24px;
    overflow: hidden;
  }

  .react-date-picker__calendar {
    z-index: 2;
  }

  .react-date-picker__inputGroup {
  input, span {
  color: ${({ theme, dateSelected, disabled, notEditable }) => {
    if (dateSelected) return theme.basePalette.navyMain;
    if (disabled || notEditable) return theme.palette.greyMain;
    return theme.palette.navyMain;
  }};
  }
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    ${({ theme, disabled, notEditable }) => ((disabled || notEditable) && `background-color: ${theme.basePalette.white}`)};
  }

  .react-date-picker__inputGroup__input {
    font-weight: ${({ theme }) => theme.fontWeight.bold};

    &:invalid {
      background: none;
    }
    &:focus {
      outline: none;
    }
  }
  .react-date-picker__inputGroup__divider {
    cursor: auto;
  }
  
  .react-date-picker__button {
    ${({ theme, disabled, notEditable }) => ((disabled || notEditable) && `background-color: ${theme.basePalette.white}`)};

    &:focus {
      outline: none;
    }
  }

  .react-calendar button {
    position: relative;
    &:enabled {
      &:hover {
        background-color: ${({ theme }) => theme.basePalette.blueMain};
        color: ${({ theme }) => theme.basePalette.white};
      }
    }

    &:disabled {
      cursor: default;
      color: ${({ theme }) => theme.palette.grey20};

      &:hover {
        background-color: ${({ theme }) => theme.basePalette.greyLight};
        color: ${({ theme }) => theme.palette.grey20};
      }
    }
  }

  .react-calendar__month-view__days__day {
    color: ${({ theme }) => theme.basePalette.greyDark};
    &:hover{
      background-color: ${({ theme }) => theme.basePalette.blueMain};
      color: ${({ theme }) => theme.palette.white};
    }
  }

  .react-calendar__tile--now {
    background: ${({ theme }) => theme.basePalette.white};
    color: ${({ theme }) => theme.basePalette.blueMain};
    font-family: ${({ theme }) => theme.fontFamily.bodyBold};
    font-weight: ${({ theme }) => theme.fontWeight.bold};

    &:disabled {
      background: ${({ theme }) => theme.palette.btnGrey};
    }
  }

  .react-calendar__tile--active, .react-calendar__tile--hasActive {
    background-color: ${({ theme }) => theme.basePalette.blueMain};
    color: ${({ theme }) => theme.basePalette.white};
  }
`;

export const HighlightedDay = styled.div`
z-index: 1;
pointer-events: none;
position: absolute;
background-color: ${({ theme }) => theme.basePalette.blueMain};
opacity: 0.2;
left: 0;
right: 0;
top: 0;
bottom: 0;
`;

export const Icon = styled.img<IconProps>`
  transform: ${({ open }) => (open ? 'scaleY(-1)' : '')};
`;

export const MetaWrapper = styled.div`
  display: flex;
`;

export const Label = styled.p<StyledDatePickerProps>`
  color: ${({ error, disabled, theme }) => {
    if (disabled) return theme.palette.btnGrey;
    if (error) return theme.palette.btnError;
    return theme.basePalette.greyDark;
  }};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  margin-bottom: 6px;
  margin-right: auto;
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.palette.btnError};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  font-style: italic;
  margin-bottom: 5px;
  margin-left: auto;
  margin-top: 5px;
`;
