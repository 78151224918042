import { PlanType } from '../../lib/types/Plan';
import { VehicleType } from '../../lib/types/Vehicle';

import driversNavIcon from './drivers-icon.svg';
import bellIcon from './bell-icon.svg';
import backArrow from './back-arrow-icon.svg';
import checkmarkIcon from './checkmark-icon.png';
import greenCheck from './green-check.svg';
import profilePhotoIcon from './profile-photo-icon.svg';
import downArrowIcon from './down-arrow-icon.svg';
import addIcon from './add-icon.svg';
import closeWhite from './close-white.svg';
import closeBlack from './close-black.svg';
import processingHourglass from './processing-hourglass.svg';
import searchIcon from './search-icon.svg';
import filterIcon from './filter-icon.svg';
import darkLeftArrow from './dark-left-arrow-icon.svg';
import storesNavIcon from './stores-nav-icon.svg';
import blueCaretDown from './blue-caret-down.svg';
import driverApplicationsPageIcon from './drivers-application-page-icon.svg';
import storeApplicationsPageIcon from './store-applications-page-icon.svg';
import folderIcon from './folder-icon.svg';
import redWarning from './red-warning.svg';
import locationPinIcon from './location-pin-icon.svg';
import greenCircleCheckIcon from './green-circle-check-icon.svg';
import phoneIcon from './phone-icon.svg';
import ImagePlaceholderIcon from './image-placeholder-icon.svg';
import PasswordHide from './password-hide.svg';
import PasswordShow from './password-show.svg';
import SuccessIcon from './success-icon.svg';
import dollarIcon from './dollar-icon.svg';
import ellipsesIcon from './ellipses-icon.svg';
import DownChevronIcon from './down-chevron.svg';
import CarIcon from './car.svg';
import GiftIcon from './gift.svg';
import SunIcon from './sun.svg';
import ShieldIcon from './shield.svg';
import HouseIcon from './house.svg';
import PiggyBankIcon from './piggy-bank.svg';
import SchoolHatIcon from './school-hat.svg';
import TransparentEllipses from './transparent-ellipses.svg';
import GreyCheckmarkIcon from './grey-checkmark.svg';
import HamburgerIcon from './hamburger.svg';
import DeleteIcon from './delete-icon.svg';
import OnOffIcon from './on-off-icon.svg';
import ClockIcon from './clock-icon.svg';
import PauseIcon from './pause-icon.svg';
import DollarIconRed from './dollar-red.svg';
import DollarIconSmall from './dollar-icon-small.svg';
import PlusIcon from './plus-icon.svg';
import DashboardIcon from './dashboard-icon.svg';
import SettingsIcon from './settings-icon.svg';
import NewslettersIcon from './newsletters-icon.svg';
import NotificationsIcon from './notifications-icon.svg';
import OverdueInstallmentsIcon from './overdue-installments-icon.svg';
import PaymentsIcon from './payments-icon.svg';
import SupportIcon from './support-icon.svg';
import UsersIcon from './users-icon.svg';
import DuePlansIcon from './due-plans-icon.svg';
import ImagePreviewIcon from './doc-preview-icon.svg';
import DocPreviewIcon from './image-preview-icon.svg';
import NotesAdd from './ic-notes-add.svg';
import NotesAttachActive from './ic-notes-attachment.svg';
import NotesAttachInactive from './ic-notes-attachment-inactive.svg';
import UploadIcon from './upload-icon.svg';
import FeesStatsIcon from './fees-stats-icon.svg';
import UsersStatsIcon from './users-stats-icon.svg';
import RevenueStatsIcon from './revenue-stats-icon.svg';
import PlansStatsIcon from './plans-stats-icon.svg';
import BoatIcon from './boat-icon.svg';
import TruckIcon from './truck-icon.svg';
import CaravanIcon from './caravan-icon.svg';
import MotorcycleIcon from './motorbike-icon.svg';
import RejectIcon from './reject-payment.svg';
import SkipIcon from './skip-payment.svg';
import AddUserIcon from './add-user-icon.svg';
import LifeSaverIcon from './lifesaver-icon.svg';
import BluePhoneIcon from './blue-phone-icon.svg';
import BlueEmailIcon from './blue-email-icon.svg';
import ReferFriendIcon from './refer-friend-icon.svg';
import ReportsIcon from './reports-icon.svg';

export {
  RejectIcon,
  SkipIcon,
  NotesAdd,
  NotesAttachActive,
  NotesAttachInactive,
  storesNavIcon,
  driversNavIcon,
  bellIcon,
  backArrow,
  blueCaretDown,
  checkmarkIcon,
  greenCheck,
  profilePhotoIcon,
  downArrowIcon,
  addIcon,
  processingHourglass,
  searchIcon,
  filterIcon,
  darkLeftArrow,
  driverApplicationsPageIcon,
  storeApplicationsPageIcon,
  folderIcon,
  redWarning,
  locationPinIcon,
  greenCircleCheckIcon,
  phoneIcon,
  ImagePlaceholderIcon,
  PasswordHide,
  PasswordShow,
  SuccessIcon,
  dollarIcon,
  ellipsesIcon,
  DownChevronIcon,
  CarIcon,
  GiftIcon,
  SunIcon,
  ShieldIcon,
  HouseIcon,
  PiggyBankIcon,
  SchoolHatIcon,
  TransparentEllipses,
  GreyCheckmarkIcon,
  HamburgerIcon,
  DeleteIcon,
  OnOffIcon,
  ClockIcon,
  PauseIcon,
  DollarIconRed,
  PlusIcon,
  DashboardIcon,
  SettingsIcon,
  NewslettersIcon,
  NotificationsIcon,
  OverdueInstallmentsIcon,
  PaymentsIcon,
  SupportIcon,
  UsersIcon,
  DuePlansIcon,
  ImagePreviewIcon,
  DocPreviewIcon,
  closeWhite,
  UploadIcon,
  FeesStatsIcon,
  UsersStatsIcon,
  RevenueStatsIcon,
  PlansStatsIcon,
  DollarIconSmall,
  closeBlack,
  BoatIcon,
  CaravanIcon,
  TruckIcon,
  MotorcycleIcon,
  AddUserIcon,
  LifeSaverIcon,
  BluePhoneIcon,
  BlueEmailIcon,
  ReferFriendIcon,
  ReportsIcon,
};

export const StatusIcons = {
  success: greenCheck,
  danger: closeWhite,
  warning: closeWhite,
  info: closeWhite,
};

export const SideNavIcons = {
  dashboard: DashboardIcon,
  settings: SettingsIcon,
  newsletters: NewslettersIcon,
  notifications: NotificationsIcon,
  overdueInstallments: OverdueInstallmentsIcon,
  payments: PaymentsIcon,
  support: SupportIcon,
  users: UsersIcon,
  duePlans: DuePlansIcon,
  reports: ReportsIcon,
};

export const PlanIcons: { [key: string]: string } = {
  [PlanType.VEHICLE_REGISTRATION]: CarIcon,
  [PlanType.CHRISTMAS_FUND]: GiftIcon,
  [PlanType.HOLIDAY]: SunIcon,
  [PlanType.INSURANCE]: ShieldIcon,
  [PlanType.RATE_PAYMENTS]: HouseIcon,
  [PlanType.SAVINGS]: PiggyBankIcon,
  [PlanType.SCHOOL_FEES]: SchoolHatIcon,
  [PlanType.OTHER]: TransparentEllipses,
};

export const VehicleIcons: { [key: string]: string } = {
  [VehicleType.CAR]: CarIcon,
  [VehicleType.UNKNOWN]: CarIcon,
  [VehicleType.TRUCK]: TruckIcon,
  [VehicleType.HEAVY_VEHICLE]: TruckIcon,
  [VehicleType.CARAVAN]: CaravanIcon,
  [VehicleType.TRAILER]: CaravanIcon,
  [VehicleType.MOTORCYCLE]: MotorcycleIcon,
  [VehicleType.BOAT]: BoatIcon,
};
