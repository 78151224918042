import styled from 'styled-components/macro';

export const FormSection = styled.div`
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => `${theme.spacing.XXS}rem ${theme.spacing.XS}rem`};
  }
`;

export const LineBreak = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.borderGrey};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing.XS}rem ${theme.spacing.XXS}rem`};
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => `${theme.spacing.XXS * 1.5}rem ${theme.spacing.S}rem`};
    justify-content: flex-end;
  }
`;
