import React from 'react';

import {
  StyledReadOnlyField,
  StyledInput,
  Label,
} from './Styles';

import { ModalReadOnlyFieldProps } from './types';

const ModalReadOnlyField: React.FC<ModalReadOnlyFieldProps> = ({
  label,
  name,
  value,
  disabled = false,
}) => (
  <StyledReadOnlyField disabled={disabled}>
    {label && (<Label disabled={disabled}>{label}</Label>)}
    <StyledInput
      className="text-input"
      name={name}
      value={value}
      disabled={disabled}
      readOnly
    />
  </StyledReadOnlyField>
);

export default ModalReadOnlyField;
