import * as yup from 'yup';

const selectPlanSchema = yup.object({
  type: yup
    .string()
    .trim()
    .typeError('Please select a plan')
    .required('Please select a plan'),
}).required();

export type SelectPlanSchema = yup.InferType<typeof selectPlanSchema>;
export default selectPlanSchema;
