import styled, { keyframes } from 'styled-components/macro';

import { NotificationVariant } from '../../../lib/types/Notification';

interface ToastProps {
  variant: NotificationVariant;
}

const fromTop = keyframes`
  from {
    transform: translate(-50%, -100%);
  }

  to {
    transform: translate(-50%, 0);
  }
`;

const toTop = keyframes`
  from {
    transform: translate(-50%, 0);
  }

  to {
    transform: translate(-50%, -100%);
  }
`;

export const Toast = styled.div<ToastProps>`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 102;
  background-color: ${({ theme, variant }) => theme.palette[variant]};
  max-width: 36rem;
  width: 100vw;
  top: 0;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.XS}rem;

  &.show {
    animation: ${fromTop} 0.5s;
  }

  &.hide {
    animation: ${toTop} 0.5s;
  }

  ${({ theme }) => theme.device.tablet} {
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    width: auto;
  };
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const Label = styled.p`
  align-self: center;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  color: white;
`;
