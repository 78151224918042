import { apiFetch, apiRequest } from '../Utils';
import { createCustomerNote, deleteCustomerNote, setCustomerNotesData, updateCustomerNote } from '../../store/contexts/admin/customerNotes/actions';
import { serverAdminAPI } from '../../../CONSTANTS';

import { APIThunkResult } from '../../types/API';
import { NotesDTO, NoteDTO, NoteDeleteDTO } from '../../types/DTO';
import { Note } from '../../types/DBModels';
import { NewNote } from '../../../pages/Admin/CustomerProfile/Notes/types';

export const getNotes = (userId: string): APIThunkResult<NotesDTO> => (
  apiRequest<NotesDTO>(async (dispatch) => {
    const url = `${serverAdminAPI}/notes/${userId}`;

    const { data, error } = await apiFetch<NotesDTO>({
      method: 'GET',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(setCustomerNotesData(data.notes));

    return data;
  }, true)
);
export const deleteNote = (noteId: number | undefined): APIThunkResult<NoteDeleteDTO> => (
  apiRequest<NoteDeleteDTO>(async (dispatch) => {
    const url = `${serverAdminAPI}/notes/remove/${noteId}`;

    const { data, error } = await apiFetch<NoteDeleteDTO>({
      method: 'DELETE',
      url,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(deleteCustomerNote(noteId));

    return data;
  }, true)
);

export const createNewNote = (note: NewNote, userId: string): APIThunkResult<NoteDTO> => (
  apiRequest<NoteDTO>(async (dispatch) => {
    const url = `${serverAdminAPI}/notes/create`;
    const body = {
      description: note.description,
      userId,
      attachments: note.noteFiles,
      createdById: note.createdByUserId,

    };
    const { data, error } = await apiFetch<NoteDTO>({
      method: 'POST',
      url,
      body,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(createCustomerNote(data.note));

    return data;
  }, true)
);

export const updateNote = (note: Note, userId: string): APIThunkResult<NoteDTO> => (
  apiRequest<NoteDTO>(async (dispatch) => {
    const url = `${serverAdminAPI}/notes/update`;
    const body = {
      description: note.description,
      userId,
      attachments: note.noteFiles,
      createdById: note.createdByUserId,
      noteId: note.noteId,
    };
    const { data, error } = await apiFetch<NoteDTO>({
      method: 'PUT',
      url,
      body,
    });

    if (!data || error) throw new Error(`${error?.message}`);

    dispatch(updateCustomerNote(data.note));

    return data;
  }, true)
);
