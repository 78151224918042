import React, { FC } from 'react';
import useStrings from '../../../hooks/useStrings';

import Button from '../Button';

import { ContentWrapper, ToolTipWrapper, ButtonWrapper } from './Styles';

import { ToolTipProps } from './types';

const Tooltip: FC<ToolTipProps> = ({
  onConfirm,
  onCancel,
  isOpen,
}) => {
  const [{ Components: { Common: { ToolTip: ToolTipStrings } } }] = useStrings();
  return (
    <ToolTipWrapper opened={isOpen} onClick={(e: React.MouseEvent<HTMLDivElement>) => { e.stopPropagation(); }}>
      <ContentWrapper>
        <p>{ToolTipStrings.title}</p>
        <ButtonWrapper>
          <Button variant="dark-outline" rounded onClick={() => { onCancel(); }} height="20px" width="140px" fontSize="12px">{ToolTipStrings.cancelText}</Button>
          <Button variant="important" rounded onClick={() => { onConfirm(); }} height="20px" width="150px" fontSize="12px">{ToolTipStrings.confirmText}</Button>
        </ButtonWrapper>
      </ContentWrapper>
    </ToolTipWrapper>
  );
};
export default Tooltip;
