import { Customer } from '../../../../types/DBModels';
import { CustomerDataView } from '../../../../types/DBViews';

import {
  SetCustomersAction,
  SetSelectedCustomerAction,
  ResetSelectedCustomerAction,
  SetUpdatedCustomerAction,
  SET_CUSTOMER_DATA,
  SET_SELECTED_CUSTOMER,
  RESET_SELECTED_CUSTOMER,
  SET_UPDATED_CUSTOMER_DATA,
} from './types';

export const setCustomerData = (
  customerData: {
    results: CustomerDataView[];
    count: number;
  },
): SetCustomersAction => ({
  type: SET_CUSTOMER_DATA,
  customerData,
});

export const setSelectedCustomer = (
  customer: Customer,
): SetSelectedCustomerAction => ({
  type: SET_SELECTED_CUSTOMER,
  selectedCustomer: customer,
});

export const resetSelectedCustomer = (): ResetSelectedCustomerAction => ({
  type: RESET_SELECTED_CUSTOMER,
});

export const setUpdatedCustomerData = (
  customers: CustomerDataView[],
): SetUpdatedCustomerAction => ({
  type: SET_UPDATED_CUSTOMER_DATA,
  customers,
});