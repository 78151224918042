import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useHistory } from 'react-router';

import Grid from '../../Common/Grid';
import Input from '../../Common/Input';
import Button from '../../Common/Button';
import DatePicker from '../../Common/DatePicker';
import Dropdown from '../../Common/Dropdown';
import Toggle from '../../Common/Toggle';
import SuccessModal from '../../Common/SuccessModal';

import {
  FormSection,
  LineBreak,
  ButtonWrapper,
} from './Styles';

import { CreateProfileFormProps } from './types';
import { APIThunkDispatch } from '../../../lib/types/API';
import useStrings from '../../../hooks/useStrings';
import createCustomerProfileSchema, { CreateCustomerProfileSchema } from '../../../lib/validators/createCustomerProfileSchema';
import { Gender, GenderHidden } from '../../../lib/types/Customer';
import { createProfile } from '../../../lib/api/customer/profile';
import { signOut } from '../../../lib/api/Auth';
import { stateOptions } from '../../../CONSTANTS';

const CreateProfileForm: React.FC<CreateProfileFormProps> = ({ profileData, isEditProfile }) => {
  const dispatch: APIThunkDispatch = useDispatch();
  const history = useHistory();
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [{ Components: { UI: { CreateProfileForm: Strings } }, GenericText }] = useStrings();

  return (
    <Formik
      initialValues={{
        email: profileData?.user.email || '',
        firstName: profileData?.user.firstName || '',
        lastName: profileData?.user.lastName || '',
        phone: profileData?.user.phone || '',
        gender: profileData?.gender || Gender.MALE,
        address: profileData?.address || '',
        suburb: profileData?.suburb || '',
        state: profileData?.state || '',
        postcode: profileData?.postcode || '',
        dob: profileData?.dob || undefined as Date | undefined,
      }}
      validationSchema={createCustomerProfileSchema}
      validateOnChange
      onSubmit={async (values, { setSubmitting }) => {
        const { success } = await dispatch(createProfile(values as CreateCustomerProfileSchema));
        setSubmitting(false);
        if (success) {
          if (isEditProfile) setIsModalShowing(true);
          else history.push('/sign-up/create-plan');
        }
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        isSubmitting,
        errors,
        touched,
        setFieldError,
        setFieldTouched,
        values,
      }) => (
        <>
          {isEditProfile && isModalShowing && (
            <SuccessModal
              isOpen={isModalShowing}
              onClose={() => {
                setIsModalShowing(false);
                history.push('/customer/dashboard');
              }}
              title={Strings.modalTitle}
              titleHighlight={Strings.modalTitleHighlight}
              subtext={Strings.modalSubtext}
            />
          )}
          <FormSection>
            <Grid row marginTop="1rem">
              <Grid column sm={12} md={12} alignItems="center">
                <Input
                  type="text"
                  name="email"
                  label={Strings.emailInput}
                  placeholder={Strings.emailInput}
                  width="100%"
                  outlined
                />
              </Grid>
            </Grid>
            <Grid row marginTop="1rem">
              <Grid column sm={12} md={6} alignItems="center">
                <Input
                  type="text"
                  name="firstName"
                  label={Strings.firstNameInput}
                  placeholder={Strings.firstNameInput}
                  width="100%"
                  outlined
                />
              </Grid>
              <Grid column sm={12} md={6} alignItems="center">
                <Input
                  type="text"
                  name="lastName"
                  label={Strings.lastNameInput}
                  placeholder={Strings.lastNameInput}
                  width="100%"
                  outlined
                />
              </Grid>
            </Grid>
            <Grid row marginTop="1rem">
              <Grid column sm={12} md={6} alignItems="center">
                <DatePicker
                  label={Strings.dobInput}
                  onSelect={(date) => setFieldValue('dob', date)}
                  onLoadDate={values.dob}
                  error={errors.dob}
                  touched={touched.dob}
                  setError={(error) => setFieldError('dob', error)}
                  setTouched={(fieldTouched) => setFieldTouched('dob', fieldTouched)}
                  outlined
                  maxDate={new Date()}
                />
              </Grid>
              <Grid column sm={12} md={6} alignItems="center">
                <Input
                  type="text"
                  name="phone"
                  label={Strings.phoneInput}
                  placeholder={Strings.phoneInput}
                  width="100%"
                  outlined
                />
              </Grid>
            </Grid>
            <Grid row marginTop="1rem">
              <Grid column sm={12}>
                <Toggle
                  name="gender"
                  label={Strings.genderSelect}
                  placeholder={Strings.genderSelect}
                  options={Object.values(GenderHidden)}
                  onOptionClick={((option) => setFieldValue('gender', option))}
                  onLoadOption={values.gender}
                />
              </Grid>
            </Grid>
          </FormSection>
          <Grid row marginTop="1rem">
            <LineBreak />
          </Grid>
          <FormSection>
            <Grid row marginTop="1rem">
              <Grid column sm={12} md={6} alignItems="center">
                <Input
                  type="text"
                  name="address"
                  label={Strings.addressInput}
                  placeholder={Strings.addressInput}
                  width="100%"
                  outlined
                />
              </Grid>
              <Grid column sm={12} md={6} alignItems="center">
                <Input
                  type="text"
                  name="suburb"
                  label={Strings.suburbInput}
                  placeholder={Strings.suburbInput}
                  width="100%"
                  outlined
                />
              </Grid>
            </Grid>
            <Grid row marginTop="1rem">
              <Grid column sm={12} md={6} alignItems="center">
                <Dropdown
                  name="state"
                  label={Strings.stateInput}
                  placeholder={Strings.stateInput}
                  options={stateOptions}
                  onLoadOption={values.state}
                  onOptionClick={((option) => setFieldValue('state', option))}
                  outlined
                />
              </Grid>
              <Grid column sm={12} md={6} alignItems="center">
                <Input
                  type="text"
                  name="postcode"
                  label={Strings.postcodeInput}
                  placeholder={Strings.postcodeInput}
                  width="100%"
                  outlined
                />
              </Grid>
            </Grid>
          </FormSection>
          <Grid row marginTop="1rem">
            <LineBreak />
          </Grid>
          <FormSection>
            <ButtonWrapper isEditProfile={isEditProfile}>
              {!isEditProfile && (
                <Button
                  margin="0"
                  width="160px"
                  variant="outlined"
                  onClick={async () => {
                    await dispatch(signOut());
                    history.push('/');
                  }}
                  disabled={isSubmitting}
                  expandedMobile={false}
                >
                  {GenericText.cancel}
                </Button>
              )}
              <Button
                margin="0"
                width="160px"
                onClick={handleSubmit}
                disabled={isSubmitting}
                $loading={isSubmitting}
                expandedMobile={false}
              >
                {GenericText.save}
              </Button>
            </ButtonWrapper>
          </FormSection>
        </>
      )}
    </Formik>
  );
};

export default CreateProfileForm;
