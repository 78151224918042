import { Credit } from '../../../../../types/DBModels';

export const SET_CUSTOMER_CREDIT_HISTORY = 'SET_CUSTOMER_CREDIT_HISTORY';

export interface CustomerCreditHistoryState {
  credits: Credit[];
}
export interface SetCustomerCreditHistory {
  type: typeof SET_CUSTOMER_CREDIT_HISTORY;
  credits: Credit[];

}

export type CreditHistoryActionTypes =
SetCustomerCreditHistory;