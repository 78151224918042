import styled from 'styled-components';
import { NoteTabTitleProps, NoteTabDateProps, NoteTabWrapperProps } from './types';

export const NoteTabWrapper = styled.li<NoteTabWrapperProps >`
border-bottom: 1px solid ${({ theme }) => theme.basePalette.greyMain};
padding: ${({ theme }) => theme.spacing.XXS}rem;
background-color: ${({ theme, active }) => (active ? theme.basePalette.blueMedium : theme.basePalette.white)};
&:hover {
  cursor: pointer;
}
`;

export const NoteTabTitle = styled.p<NoteTabTitleProps>`
font-family: ${({ theme }) => theme.fontFamily.body};
font-size: ${({ theme }) => theme.fontScale.M as number * 0.875}rem;
font-weight: ${({ theme }) => theme.fontWeight.semibold};
color: ${({ theme, active }) => (active ? theme.basePalette.white : theme.basePalette.navyMain)};
`;

export const NoteTabSub = styled.div`
display: flex;
margin-top: ${({ theme }) => theme.spacing.XXS * 0.5}rem;
justify-content: space-between;
`;

export const NoteTabDate = styled.p<NoteTabDateProps>`
font-family: ${({ theme }) => theme.fontFamily.bodyLight};
font-size: ${({ theme }) => theme.fontScale.M as number * 0.875}rem;
font-weight: ${({ theme }) => theme.fontWeight.light};
color: ${({ theme, active }) => (active ? theme.basePalette.white : theme.basePalette.navyMain)};
`;

export const AttachmentIcon = styled.img`
`;
