import React from 'react';
import dayjs from 'dayjs';

import useStrings from '../../../../hooks/useStrings';

import Grid from '../../../../components/Common/Grid';
import Button from '../../../../components/Common/Button';

import { contentHeaderBg } from '../../../../assets/Images/index';
import { DollarIconRed } from '../../../../assets/Icons/index';
import {
  Heading,
  Subtext,
  DirectDebitBanner,
  DirectDebitContent,
  DirectDebitTextWrap,
  BackgroundTexture,
  DollarIcon,
  DirectDebitButtonWrap,
  NextPayment,
  NextPaymentDate,
} from './Styles';

import { UserInfoProps } from './types';

const UserInfo: React.FC<UserInfoProps> = ({
  firstName,
  nextPayment,
  nextPaymentDate,
  fetchingRedirectParams,
  onIntegratePaymentService,
  hidePaymentBanner,
}) => {
  const [{ Pages: { Customer: { Dashboard: { UserInfo: Strings } } } }] = useStrings();

  return (
    <Grid row>
      <Grid row>
        <Grid column sm={12} md={5}>
          <div>
            <Heading>{Strings.pageTitle}</Heading>
            &nbsp;
            <Heading highlight>{firstName}</Heading>
          </div>
          <Subtext>{Strings.pageSubtitle}</Subtext>
        </Grid>
        {nextPayment && (
          <Grid column sm={12} md={7}>
            <NextPayment>
              <NextPaymentDate>
                <Heading small light>{Strings.nextPayment}</Heading>
                &nbsp;
                <Heading small highlight>{Strings.nextPaymentHighlight}</Heading>
                &nbsp;
                &nbsp;
                <Subtext light bold>{Strings.due} {dayjs(nextPaymentDate).format('DD MMM YY')}</Subtext>
              </NextPaymentDate>
              <Heading large>${nextPayment.toFixed(2)}</Heading>
            </NextPayment>
          </Grid>
        )}
      </Grid>
      {!hidePaymentBanner && (
        <Grid row marginTop="2rem">
          <Grid column sm={12}>
            <DirectDebitBanner>
              <BackgroundTexture src={contentHeaderBg} />
              <DirectDebitContent>
                <DollarIcon src={DollarIconRed} />
                <DirectDebitTextWrap>
                  <div>
                    <Heading small light>{Strings.directDebitTitle1}</Heading>
                    &nbsp;
                    <Heading small light highlight>{Strings.directDebitTitleHighlight}</Heading>
                    &nbsp;
                    <Heading small light>{Strings.directDebitTitle2}</Heading>
                  </div>
                  <Subtext light>{Strings.directDebitSubtext}</Subtext>
                </DirectDebitTextWrap>
              </DirectDebitContent>
              <DirectDebitButtonWrap>
                <Button
                  onClick={onIntegratePaymentService}
                  variant="light"
                  margin="0.5rem 0"
                  disabled={fetchingRedirectParams}
                  $loading={fetchingRedirectParams}
                >
                  {Strings.directDebitButton}
                </Button>
              </DirectDebitButtonWrap>
            </DirectDebitBanner>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default UserInfo;
