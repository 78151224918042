import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';

import {
  StyledDatePicker,
  MetaWrapper,
  Icon,
  Label,
  Error,
} from './Styles';

import { ModalDatePickerProps } from './types';
import { blueCaretDown } from '../../../assets/Icons';

const ModalDatePickerComponent: React.FC<ModalDatePickerProps> = ({
  onLoadDate,
  label,
  minDate,
  maxDate,
  error,
  onSelect,
  setError,
  setTouched,
  yearOnly = false,
  disabled,
  notEditable,
  tileContent,
}) => {
  const [selected, setSelected] = useState<Date | null>();
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (date: Date | Date[]) => {
    const val = Array.isArray(date) ? date[0] : date;
    setSelected(val);
    if (setError) setError();
    if (setTouched) setTouched(true);
    onSelect(val);
  };

  useEffect(() => {
    if (onLoadDate && onLoadDate as unknown as string !== '0') setSelected(new Date(onLoadDate));
    else setSelected(null);
  }, [onLoadDate]);

  return (
    // Formik thinks this is a date type?
    <StyledDatePicker
      error={error as string}
      disabled={disabled}
      notEditable={notEditable}
      open={open}
      onClick={() => {
        if (setTouched) setTouched(true);
        if (!open) setOpen(true);
      }}
      dateSelected={!!selected}
    >
      {(label || error) && (
      <MetaWrapper>
        <Label
          error={error as string}
          disabled={disabled}
          open={open}
          dateSelected={!!selected}
        >
          {label}
        </Label>
      </MetaWrapper>
      )}
      <DatePicker
        value={selected}
        clearIcon={null}
        disabled={disabled || notEditable}
        calendarIcon={!disabled ? <Icon open={open} src={blueCaretDown} /> : null}
        onChange={handleChange}
        onCalendarOpen={() => setOpen(true)}
        onCalendarClose={() => setOpen(false)}
        isOpen={open}
        minDate={minDate}
        maxDate={maxDate}
        monthPlaceholder={yearOnly ? '' : 'MM'}
        dayPlaceholder={yearOnly ? '' : 'DD'}
        yearPlaceholder="YYYY"
        format={yearOnly ? 'y' : 'dd/MM/y'}
        maxDetail={yearOnly ? 'decade' : 'month'}
        tileContent={tileContent}
      />
      {error && <Error className="error">{error}</Error>}
    </StyledDatePicker>
  );
};

export default ModalDatePickerComponent;
