import {
  SET_ALL_ARCHIVED_PLAN_DATA,
  SET_UPDATED_ARCHIVED_PLAN_DATA,
  CREATE_ARCHIVED_PLAN,
  CLEAR_SELECTED_CUSTOMER_ARCHIVED_PLANS,
  ArchivedPlanActionTypes,
  ArchivedPlanState,
} from './types';

import { setUpdatedRecordsInState } from '../../../../utils';

const initialState: ArchivedPlanState = {
  archivedPlanData: {
    archivedPlans: [],
    count: 0,
  },
};

const ArchivedPlanReducer = (
  state: ArchivedPlanState = initialState,
  action: ArchivedPlanActionTypes,
): ArchivedPlanState => {
  switch (action.type) {
    case SET_ALL_ARCHIVED_PLAN_DATA:
      return {
        ...state,
        archivedPlanData: {
          archivedPlans: action.archivedPlanData.results,
          count: action.archivedPlanData.count,
        },
      };
    case SET_UPDATED_ARCHIVED_PLAN_DATA:
      return {
        ...state,
        archivedPlanData: {
          archivedPlans: setUpdatedRecordsInState('planId', state.archivedPlanData.archivedPlans, action.archivedPlans),
          count: state.archivedPlanData.count,
        },
      };
    case CREATE_ARCHIVED_PLAN:
      return {
        ...state,
        archivedPlanData: {
          archivedPlans: [...state.archivedPlanData.archivedPlans, action.archivedPlan],
          count: state.archivedPlanData.count + 1,
        },
      };
    case CLEAR_SELECTED_CUSTOMER_ARCHIVED_PLANS:
      return {
        ...state,
        archivedPlanData: {
          archivedPlans: [],
          count: 0,
        },
      };
    default:
      return state;
  }
};

export default ArchivedPlanReducer;
