import * as yup from 'yup';

import { isPhoneNumberValid } from '../utils';

const editAdminSchema = yup.object({
  userId: yup.string().required('An existing user must be selected to edit'),
  email: yup
    .string()
    .trim()
    .email('Email is not valid')
    .required('Email is required'),
  firstName: yup
    .string()
    .trim()
    .required('First name is required'),
  lastName: yup
    .string()
    .trim()
    .required('Last name is required'),
  phone: yup
    .string()
    .trim()
    .test({ name: 'is-valid-phone', test: (value) => isPhoneNumberValid(value), message: 'Phone number is not valid' })
    .required('Phone number is required'),
  isMaster: yup
    .boolean()
    .required(),
}).required();

export type EditAdminSchema = yup.InferType<typeof editAdminSchema>;
export default editAdminSchema;
