import styled from 'styled-components/macro';

export const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const LogoImg = styled.img`
  width: 100%;
  height: auto;
  margin-right: auto;
`;
