import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import dayjs from 'dayjs';

import useStrings from '../../../../hooks/useStrings';
import { buildHandlers } from '../../../../components/Common/Table/handlers';
import {
  INSTALLMENT_FREQUENCY_DISPLAY,
  INSTALLMENT_STATUS_DISPLAY,
  hiddeninstallmentStatusOptions,
  PLAN_TYPE_DISPLAY_ADMIN_TABLES,
} from '../../../../CONSTANTS';

import Grid from '../../../../components/Common/Grid';
import Pagination from '../../../../components/Common/Table/Pagination';
import TableBulkEdit from '../../../../components/Common/TableBulkEdit';
import TableDatePicker from '../../../../components/Common/TableDatePicker';
import SubHeader from './SubHeader';
import ContextMenu from '../../../../components/Common/ContextMenu';
import DeferPaymentModal from '../../../../components/Common/DeferPaymentModal';

import { Spinner } from '../../../../components/UI/LoadingScreen/Styles';
import {
  TableWrapper,
  Select,
  TableJssOverrides,
  NoRecordsText,
} from '../../../../components/Common/Table/Styles';

import { PaymentsTableProps } from './types';
import { SelectedRows, TableColumn, TableRow } from '../../../../components/Common/Table/types';
import { ApplicationState } from '../../../../lib/store';
import { OrderDirection, PaymentFilterOptions, UpdatePaymentActionTypes } from '../../../../lib/types/API';
import { InstallmentStatus } from '../../../../lib/types/Plan';
import { PaymentDataView } from '../../../../lib/types/DBViews';
import { DeferPaymentModalProps } from '../../../../components/Common/DeferPaymentModal/types';

const PaymentsTable: React.FC<PaymentsTableProps<PaymentFilterOptions>> = ({
  currentData,
  recordCount,
  filter,
  updateFilter,
  onRowClicked,
  clearFilters,
  handleUpdatePayments,
  filterLimits,
}) => {
  const [STRINGS] = useStrings();

  const [searchTerm, setSearchTerm] = useState(filter.keyword || '');
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.currentTarget.value || '');
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [bulkEditOpen, setBulkEditOpen] = useState(false);
  const [deferPaymentData, setDeferPaymentData] = useState<Omit<DeferPaymentModalProps, 'onClose' | 'cacheToUpdate' | 'filter'>>();
  const [deferModalOpen, setIsDeferModalOpen] = useState(false);

  // Manage state for custom filters that will be applied on button press
  const [localFilter, setLocalFilter] = useState<Record<string, number | Date | undefined>>();

  const defaultSelectedRows: SelectedRows = {
    allSelected: false,
    selectedCount: 0,
    selectedRows: [],
  };

  const [selectedRows, setSelectedRows] = useState<SelectedRows>(defaultSelectedRows);

  const handleRowsSelected = ({ allSelected, selectedCount, selectedRows: selected }: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: TableRow[];
  }) => {
    setBulkEditOpen(selectedCount > 0);
    setSelectedRows({
      allSelected,
      selectedCount,
      selectedRows: selected,
    });
  };

  const onMarkSelectedAsPaid = () => {
    handleUpdatePayments(
      selectedRows.selectedRows.map((row) => row.id),
      UpdatePaymentActionTypes.MARK_AS_PAID,
      new Date(),
    );
    setBulkEditOpen(false);
    setSelectedRows(defaultSelectedRows);
  };

  const onMarkSelectedAsUnpaid = () => {
    handleUpdatePayments(selectedRows.selectedRows.map((row) => row.id), UpdatePaymentActionTypes.MARK_AS_UNPAID);
    setBulkEditOpen(false);
    setSelectedRows(defaultSelectedRows);
  };

  const {
    handlePlanTypeChange,
    handlePaymentStatusChange,
    handleUpdatePlanValue,
    handleUpdateInstallmentValue,
    handleDueDateChange,
    handlePaidDateChange,
    handleSearchComplete,
    handlePageChange,
    handleSortChange,
    handleApplyCustomFilters,
    handleClearFilters,
  } = buildHandlers<PaymentFilterOptions>(
    updateFilter,
    filter,
    localFilter,
    setLocalFilter,
    clearFilters,
    handleSearchChange,
    setSearchTerm,
    setFilterMenuOpen,
  );

  const { loading } = useSelector((state: ApplicationState) => ({
    loading: state.loadingState.apiCallsInProgress > 0,
  }));

  const Loader = loading ? (
    <div>
      <Spinner />
    </div>
  ) : (
    <NoRecordsText>
      <p>{STRINGS.Components.Common.Table.noRecords}</p>
    </NoRecordsText>
  );

  const columns: TableColumn<PaymentDataView>[] = [
    {
      name: 'Name',
      selector: 'customerName',
      sortable: true,
      grow: 2,
      conditionalCellStyles: [
        {
          when: () => true,
          style: { fontWeight: 'bold' },
        },
      ],
    },
    {
      name: 'Reg No',
      selector: 'registration',
      sortable: true,
      grow: 0.2,
      format: (row) => row.registration?.toString() || '',
    },
    {
      name: 'Plan Name',
      selector: 'planName',
      sortable: true,
      grow: 1.5,
    },
    {
      name: 'Type',
      selector: 'planType',
      sortable: true,
      format: (row) => PLAN_TYPE_DISPLAY_ADMIN_TABLES[row.planType as string],
    },
    {
      name: 'Frequency',
      selector: 'frequency',
      sortable: true,
      grow: 0.2,
      format: (row) => INSTALLMENT_FREQUENCY_DISPLAY[row.frequency as string],
    },
    {
      name: 'Due Date',
      selector: 'dueDate',
      sortable: true,
      cell: ({ installmentStatus, scheduledDueDate, dueDate, id }) => {
        if (installmentStatus as string === InstallmentStatus.REJECTED
          || installmentStatus as string === InstallmentStatus.SKIPPED
          || installmentStatus as string === InstallmentStatus.TRANSFERRED
          || installmentStatus as string === InstallmentStatus.CANCELLED) {
          return (<>{dayjs((scheduledDueDate || dueDate) as Date).format('DD/MM/YYYY')} </>);
        }
        return (
          <TableDatePicker
            onLoadDate={(scheduledDueDate || dueDate) as Date}
            onSelect={(val) => handleUpdatePayments([id], UpdatePaymentActionTypes.SET_DUE_DATE, undefined, val)}
            inTable
            key={id}
          />
        );
      },
    },
    {
      name: 'Date Paid',
      selector: 'paidDate',
      sortable: true,
      cell: ({ installmentStatus, paidDate, id }) => {
        if (installmentStatus as string === InstallmentStatus.REJECTED
          || installmentStatus as string === InstallmentStatus.SKIPPED
          || installmentStatus as string === InstallmentStatus.TRANSFERRED
          || installmentStatus as string === InstallmentStatus.CANCELLED) {
          if (paidDate) return (<>{dayjs(paidDate as Date).format('DD/MM/YYYY')} </>);
          return <></>;
        }
        return (
          <TableDatePicker
            onLoadDate={paidDate as Date}
            onSelect={(val) => handleUpdatePayments([id], UpdatePaymentActionTypes.SET_PAID_DATE, val)}
            inTable
            key={id}
          />
        );
      },
    },
    {
      name: 'Plan Amount',
      selector: 'planValue',
      sortable: true,
      format: (row) => `$${Number(row.planValue).toFixed(2)}`,
    },
    {
      name: 'Instalment',
      selector: 'installmentValue',
      sortable: true,
      grow: 0.5,
      format: (row) => `$${Number(row.installmentValue).toFixed(2)}`,
    },
    {
      name: 'Fee',
      selector: 'adminFee',
      sortable: true,
      grow: 0.1,
      format: (row) => `$${Number(row.adminFee).toFixed(2)}`,
    },
    {
      name: 'Status',
      selector: 'installmentStatus',
      sortable: true,
      grow: 2.2,
      cell: ({ installmentStatus, installmentId, id, planId, userId, installmentValue, dueDate, scheduledDueDate }) => (
        <>
          <Select
            status={installmentStatus as string}
            statusType="INSTALLMENT"
            disabled={installmentStatus as string === InstallmentStatus.REJECTED
              || installmentStatus as string === InstallmentStatus.SKIPPED
              || installmentStatus as string === InstallmentStatus.TRANSFERRED
              || installmentStatus as string === InstallmentStatus.CANCELLED}
            onChange={(val) => handleUpdatePayments(
              [id],
              (val.target.value as keyof typeof InstallmentStatus) === InstallmentStatus.PAID
                ? UpdatePaymentActionTypes.MARK_AS_PAID
                : UpdatePaymentActionTypes.MARK_AS_UNPAID, (scheduledDueDate || dueDate) as Date,
            )}
          >
            <option value={installmentStatus as string}>
              {INSTALLMENT_STATUS_DISPLAY[installmentStatus as string]}
            </option>
            {hiddeninstallmentStatusOptions.map(({ value, label }) => value !== installmentStatus && (
              <option key={`installmentStatus - ${value}`} value={value}>{label}</option>
            ))}
          </Select>
          <ContextMenu
            forPaymentTable
            onDeferClick={(status) => {
              setDeferPaymentData({
                userId: userId as string,
                planId: planId as number,
                installmentId: installmentId as number,
                installmentValue: installmentValue as number,
                dueDate: dueDate as Date,
                status,
              });
              setIsDeferModalOpen(true);
            }}
            disabled={installmentStatus as string !== InstallmentStatus.UNPAID}
          />
        </>
      ),
    },
  ];

  const SubHeaderElement = (
    <SubHeader
      searchTerm={searchTerm}
      customFiltersOpen={filterMenuOpen}
      filter={filter}
      planValueMin={filterLimits?.minPlanValue || 0}
      planValueMax={filterLimits?.maxPlanValue || 0}
      installmentValueMin={filterLimits?.minInstallmentValue || 0}
      installmentValueMax={filterLimits?.maxInstallmentValue || 0}
      onStatusChange={handlePaymentStatusChange}
      onPlanTypeChange={handlePlanTypeChange}
      onUpdateDueDate={handleDueDateChange}
      onUpdatePaidDate={handlePaidDateChange}
      onUpdatePlanValue={handleUpdatePlanValue}
      onUpdateInstallmentValue={handleUpdateInstallmentValue}
      onSearchChange={handleSearchComplete}
      onOpenCustomFilters={() => setFilterMenuOpen(!filterMenuOpen)}
      onApplyCustomFilters={handleApplyCustomFilters}
      onClearCustomFilters={handleClearFilters}
    />
  );

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {deferModalOpen && deferPaymentData && (
        <DeferPaymentModal
          {...deferPaymentData}
          cacheToUpdate="payments"
          onClose={() => setIsDeferModalOpen(false)}
          filter={filter}
        />
      )}
      <Grid row maxWidth="100%">
        <Grid column sm={12}>
          <TableWrapper>
            <DataTable
              subHeaderAlign="left"
              highlightOnHover
              noContextMenu
              noHeader
              selectableRows
              onRowClicked={onRowClicked}
              data={currentData}
              keyField="key"
              columns={columns}
              subHeader
              subHeaderComponent={SubHeaderElement}
              pagination
              paginationServer
              paginationPerPage={filter.rowsPerPage}
              paginationTotalRows={recordCount}
              paginationDefaultPage={filter.page}
              onChangePage={handlePageChange}
              paginationComponent={Pagination}
              onSort={handleSortChange}
              sortServer
              defaultSortField={filter.orderColumn}
              defaultSortAsc={filter.orderDirection === OrderDirection.ASC}
              noDataComponent={Loader}
              onSelectedRowsChange={handleRowsSelected}
              selectableRowsHighlight
              selectableRowSelected={(row) => selectedRows.selectedRows.some((sr) => sr.id === row.id)}
              customStyles={TableJssOverrides}
              style={{
                borderRadius: 0,
              }}
            />
          </TableWrapper>
        </Grid>
      </Grid>
      {bulkEditOpen && (
        <Grid row maxWidth="100%">
          <Grid column sm={12}>
            <TableBulkEdit
              numberSelected={selectedRows?.selectedCount || 0}
              allSelected={!!selectedRows?.allSelected}
              onMarkSelectedAsPaid={onMarkSelectedAsPaid}
              onMarkSelectedAsUnpaid={onMarkSelectedAsUnpaid}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default PaymentsTable;
