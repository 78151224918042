import styled from 'styled-components/macro';

import { StyledFileInputProps } from './types';

export const StyledFileUploader = styled.div<StyledFileInputProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${({ hasValue }) => (hasValue ? '0' : '1.25rem')};
  border-width: ${({ hasValue }) => (hasValue ? '0' : '1px')};
  border-style: dashed;
  border-color: ${({ isDragReject, error, theme }) => {
    if (error) return theme.palette.btnError;
    if (isDragReject) return theme.basePalette.blueMain;
    return theme.basePalette.greyDark;
  }};
  border-radius: 24px;
  background-color: ${({ theme }) => theme.basePalette.white};
  color: #bdbdbd;
  outline: none;
  transition: ${({ hasValue }) => (hasValue ? '' : 'border .24s ease-in-out')};;
  cursor: pointer;
  
  &:hover {
      border-color: ${({ error, theme }) => {
    if (error) return theme.palette.btnError;
    return theme.basePalette.blueMain;
  }};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 40%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0 1rem;
`;

export const Icon = styled.img`
  width: 33px;
  height: 33px;
`;

export const IconDivider = styled.div`
  background-color: ${({ theme }) => theme.basePalette.greyDark};
  width: 1px;
  height: 16px;
`;

export const TypeText = styled.p`
  color: ${({ theme }) => theme.basePalette.greyDark};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  margin-bottom: 1rem;
`;

export const UploadText = styled.p<StyledFileInputProps>`
  color: ${({ error, theme }) => {
    if (error) return theme.palette.btnError;
    return theme.basePalette.blueMain;
  }};

  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-decoration: underline;
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.palette.btnError};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-style: italic;
  margin-bottom: 5px;
`;

export const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 88px;
  align-items: center;
  background-color: ${({ theme }) => theme.basePalette.greyMain};
  border-radius: 24px;
  cursor: pointer;

  img:nth-child(2) {
      margin-left: 1.25rem;
    }
`;

export const ValuePlaceholder = styled.p`
  color: ${({ theme }) => theme.basePalette.navyMain};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-left: 1.25rem;

`;
