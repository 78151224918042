import { useEffect, useState } from 'react';
import { User } from 'firebase';

import { onAuthChange } from '../lib/Authentication';
import { Roles } from '../lib/types/Auth';
import { AuthState } from '../lib/types/DBModels';

const useAuth = (): AuthState => {
  const [authState, setAuthState] = useState<AuthState>({
    permissions: [],
    role: Roles.DEFAULT,
    pending: true,
  });

  const handleAuthChange = async (authUser: User | null) => {
    if (authUser) {
      const { claims } = await authUser?.getIdTokenResult();
      const { role } = claims;

      setAuthState({
        permissions: [],
        role: Roles[role as keyof typeof Roles],
        pending: false,
      });
      return;
    }
    setAuthState({ permissions: [], role: Roles.DEFAULT, pending: false });
  };

  useEffect(() => {
    const authSubscriber = onAuthChange(handleAuthChange);
    return () => authSubscriber();
  }, []);

  return { ...authState };
};

export default useAuth;
