import React from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';

import useStrings from '../../../../../hooks/useStrings';

import Button from '../../../../../components/Common/Button';
import Input from '../../../../../components/Common/Input';
import Grid from '../../../../../components/Common/Grid';
import Radio from '../../../../../components/Common/Radio';

import { AddUserIcon } from '../../../../../assets/Icons';
import {
  ModalBackground,
  ModalWrapper,
  ButtonWrapper,
  TextWrapper,
  ModalTitle,
  ModalSubtext,
  IconWrapper,
  Icon,
} from './Styles';

import { AddAdminModalProps } from './types';
import { Roles } from '../../../../../lib/types/Auth';
import createAdminSchema from '../../../../../lib/validators/createAdminSchema';
import { ApplicationState } from '../../../../../lib/store';
import editAdminSchema from '../../../../../lib/validators/editAdminSchema';

const AddAdminModal: React.FC<AddAdminModalProps> = ({ onConfirm, onCancel }) => {
  const stopClickBubbling = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();
  const [{
    Components: {
      Common: {
        AddAdminModal: AddAdminModalStrings,
        EditAdminModal: EditAdminModalStrings,
      },
    },
    GenericText,
  }] = useStrings();

  const { selectedAdminState: { selectedAdminUser } } = useSelector((state: ApplicationState) => state.admin);

  const radioOptions = [
    { value: Roles.ADMIN, label: AddAdminModalStrings.radioLabels.admin },
    { value: Roles.MASTER_ADMIN, label: AddAdminModalStrings.radioLabels.master },
  ];

  return (
    <Formik
      initialValues={{
        userId: selectedAdminUser?.userId || '',
        firstName: selectedAdminUser?.firstName || '',
        lastName: selectedAdminUser?.lastName || '',
        email: selectedAdminUser?.email || '',
        phone: selectedAdminUser?.phone || '',
        isMaster: selectedAdminUser?.role.role === Roles.MASTER_ADMIN || false,
        adminRole: selectedAdminUser?.role.role || Roles.ADMIN,
      }}
      validationSchema={selectedAdminUser ? editAdminSchema : createAdminSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const { adminRole, ...rest } = values;
        const newAdminValues = {
          ...rest,
          isMaster: adminRole === Roles.MASTER_ADMIN,
        };
        await onConfirm(newAdminValues);
        setSubmitting(false);
      }}
      validateOnChange={false}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
      }) => (
        <ModalBackground
          onClick={onCancel}
        >
          <ModalWrapper
            onClick={stopClickBubbling}
          >
            <IconWrapper>
              <Icon src={AddUserIcon} />
            </IconWrapper>
            <TextWrapper>
              <ModalTitle>
                {selectedAdminUser ? EditAdminModalStrings.title : AddAdminModalStrings.title}
              </ModalTitle>
              <ModalSubtext>
                {selectedAdminUser ? EditAdminModalStrings.subtitle : AddAdminModalStrings.subtitle}
              </ModalSubtext>
            </TextWrapper>
            <Grid row marginTop="15px">
              <Grid column sm={6}>
                <Input name="firstName" type="text" label={AddAdminModalStrings.firstNameLabel} />
              </Grid>
              <Grid column sm={6}>
                <Input name="lastName" type="text" label={AddAdminModalStrings.lastNameLabel} />
              </Grid>
            </Grid>
            <Grid row marginTop="15px">
              <Grid column sm={6}>
                <Input name="email" type="text" label={AddAdminModalStrings.emailLabel} />
              </Grid>
              <Grid column sm={6}>
                <Input name="phone" type="text" label={AddAdminModalStrings.phoneLabel} />
              </Grid>
            </Grid>
            <Grid row marginTop="15px">
              <Grid column>{AddAdminModalStrings.adminRoleSubheader}</Grid>
            </Grid>
            <Grid row marginTop="15px">
              <Radio name="adminRole" options={radioOptions} />
            </Grid>

            <ButtonWrapper>
              <Button
                variant="secondary"
                onClick={onCancel}
                rounded
              >
                {GenericText.cancel}
              </Button>
              <Button
                onClick={() => handleSubmit()}
                rounded
                disabled={isSubmitting || !values.firstName || !values.lastName || !values.email || !values.phone}
                $loading={isSubmitting}
              >
                {selectedAdminUser ? GenericText.confirm : GenericText.create}
              </Button>

            </ButtonWrapper>
          </ModalWrapper>
        </ModalBackground>
      )}
    </Formik>
  );
};

export default AddAdminModal;
