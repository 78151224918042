import styled, { DefaultTheme } from 'styled-components/macro';

import { HeadingProps, SubtextProps } from './types';

export const ListHeader = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.basePalette.navyMain};
  border-radius: 15px;
  padding: ${({ theme }) => theme.spacing.XXS}rem;
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => theme.spacing.XXS}rem ${({ theme }) => theme.spacing.XS / 1.5}rem;
  }
`;

export const PlanItem = styled.div`
  background: ${({ theme }) => theme.basePalette.white};
  border-radius: 15px;
  height: 340px;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
  padding: ${({ theme }) => theme.spacing.XS / 1.3}rem;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    margin-bottom: ${({ theme }) => theme.spacing.XS}rem;
  }
`;

export const IconWrapper = styled.div`
  align-items: center;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.borderGrey}33;
  border-radius: 50%;
  border-width: 1px;
  display: flex;
  height: 80px;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
  width: 80px;
`;

export const NewPlanCta = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.basePalette.navyMain};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  height: 349.5px;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.S}rem;
  text-align: center;
  width: 100%;
`;

export const AddIcon = styled.img`
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
`;

const generateHeadingColor = (theme: DefaultTheme, highlight?: boolean, light?: boolean) => {
  if (highlight && light) return theme.basePalette.redLight;
  if (highlight) return theme.basePalette.blueMain;
  if (light) return theme.basePalette.white;
  return theme.basePalette.navyMain;
};

const generateHeadingSize = (theme: DefaultTheme, small?: boolean, large?: boolean) => {
  if (small) return theme.fontScale.XL as number / 1.3;
  if (large) return theme.fontScale.XXL;
  return theme.fontScale.XXL as number / 1.1;
};

export const Heading = styled.h2<HeadingProps>`
  color: ${({ highlight, light, theme }) => generateHeadingColor(theme, highlight, light)};
  font-size: ${({ theme, small, large }) => generateHeadingSize(theme, small, large)}rem;
  display: inline;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export const Subtext = styled.p<SubtextProps>`
  color: ${({ theme, light }) => (light ? theme.basePalette.white : theme.basePalette.navyMain)};
  font-family: ${({ theme }) => theme.fontFamily.bodyLight};
  display: inline;
`;

export const CreatePlanLink = styled.a`
  color: ${({ theme }) => theme.basePalette.blueMain};
  cursor: pointer;
  text-decoration: none;
`;
