import styled from 'styled-components/macro';

export const StyledCalendar = styled.div`
  position: relative;
  width: 100%;
  
  // Style overrides
  // If updating, ensure changes are reflected in DatePickerCalendar component too
  .react-date-picker {
    background-color: ${({ theme }) => theme.palette.btnMidGrey};
    cursor: pointer;
    color: ${({ theme }) => theme.palette.grey20};

    &:hover {
      border: 1px solid ${({ theme }) => theme.basePalette.blueMain};
    }

    input {
      &::-webkit-input-placeholder { /* Edge */
        font-family: ${({ theme }) => theme.fontFamily.bodyLight};
        color: ${({ theme }) => theme.basePalette.greyDark};
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-family: ${({ theme }) => theme.fontFamily.bodyLight};
        color: ${({ theme }) => theme.basePalette.greyDark};
      }

      &::placeholder {
        font-family: ${({ theme }) => theme.fontFamily.bodyLight};
        color: ${({ theme }) => theme.basePalette.greyDark};
      }
    }
  }

  .react-date-picker__wrapper {
    width: 100%;
    height: 60px;
    margin: ${({ theme }) => `${(theme.spacing.XXS / 2)}rem 0 0 0`};
    border-radius: 8px;
  }};

  .react-calendar {
    border: 1px solid ${({ theme }) => theme.basePalette.greyMain};
    border-radius: 8px;
  }

  .react-date-picker__calendar {
    z-index: 2;
  }

  .react-date-picker__button {
    padding-right: 0.9rem;
    &:focus {
      outline: none;
    }
  }

  .react-calendar__navigation {
    margin-bottom: 0;
  }

  .react-calendar button {
    &:enabled {
      &:hover {
        background-color: ${({ theme }) => theme.basePalette.blueMain};
        color: ${({ theme }) => theme.basePalette.white};
      }
    }

    &:disabled {
      cursor: default;
      color: ${({ theme }) => theme.palette.grey20};

      &:hover {
        background-color: ${({ theme }) => theme.basePalette.greyLight};
        color: ${({ theme }) => theme.palette.grey20};
      }
    }
  }

  .react-calendar__month-view__days__day {
    color: ${({ theme }) => theme.basePalette.greyDark};

    &:hover{
      background-color: ${({ theme }) => theme.basePalette.blueMain};
      color: ${({ theme }) => theme.palette.white};
    }
  }

  .react-calendar__tile--now {
    background: ${({ theme }) => theme.basePalette.white};
    color: ${({ theme }) => theme.basePalette.blueMain};
    font-family: ${({ theme }) => theme.fontFamily.bodyBold};
    font-weight: ${({ theme }) => theme.fontWeight.bold};

    &:disabled {
      background: ${({ theme }) => theme.palette.btnGrey};
    }
  }

  .react-calendar__tile--active, .react-calendar__tile--hasActive {
    background-color: ${({ theme }) => theme.basePalette.blueMain};
    color: ${({ theme }) => theme.basePalette.white};
  }
`;

export const CustomHeader = styled.div`
  background: ${({ theme }) => theme.basePalette.white};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid ${({ theme }) => theme.basePalette.greyMain};
  border-bottom-width: 0;
  display: flex;
  justify-content: flex-end;
  padding: 15px 10px 10px;
  position: relative;
  margin-bottom: -5px;
  width: 100%;
  z-index: 10;
`;

export const ClearButton = styled.span`
  color: ${({ theme }) => theme.basePalette.navyMain};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  text-transform: uppercase;

  &:hover {
    color: ${({ theme }) => theme.basePalette.blueMain};
  }
`;
