import React from 'react';

import LoginLayout from '../../layouts/LoginLayout';
import CreatePasswordForm from '../../components/UI/CreatePasswordForm';
import { forgotPasswordBg } from '../../assets/Images';

import { CreatePasswordProps } from './types';

const CreatePassword: React.FC<CreatePasswordProps> = () => (
  <LoginLayout
    backgroundImage={forgotPasswordBg}
    loginVariant="customer-create-password"
  >
    <CreatePasswordForm />
  </LoginLayout>
);

export default CreatePassword;
