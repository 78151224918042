import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { setPassword } from '../../../lib/api/admin/admins';
import useStrings from '../../../hooks/useStrings';
import updatePasswordSchema from '../../../lib/validators/updatePasswordSchema';

import Grid from '../../Common/Grid';
import Input from '../../Common/Input';
import Button from '../../Common/Button';
import Heading from '../../Common/Heading';

import {
  FormWrapper,
  ButtonWrapper,
  SubText,
} from './Styles';

import { AdminUpdatePasswordFormProps } from './types';
import { APIThunkDispatch } from '../../../lib/types/API';

const AdminUpdatePasswordForm: React.FC<AdminUpdatePasswordFormProps> = () => {
  const [{ Components: { UI: { AdminUpdatePasswordForm: Strings } }, GenericText }] = useStrings();
  const dispatch: APIThunkDispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
      }}
      validationSchema={updatePasswordSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async ({ currentPassword, newPassword }, { setSubmitting }) => {
        setSubmitting(true);
        await dispatch(setPassword(currentPassword, newPassword));
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <>
          <FormWrapper>
            <Grid row>
              <Grid column alignItems="center" sm={12}>
                <Grid row justify="center">
                  <Heading defaultFont={false}>
                    {Strings.title}
                  </Heading>
                  <SubText>{Strings.subtitle}</SubText>
                </Grid>
              </Grid>
            </Grid>
            <Grid row marginTop="60px" expanded>
              <Grid column sm={12}>
                <Input
                  type="password"
                  name="currentPassword"
                  label={Strings.currentPasswordInput}
                  placeholder={Strings.currentPasswordInput}
                  width="100%"
                  outlined
                />
                <Input
                  type="password"
                  name="newPassword"
                  label={Strings.newPasswordInput}
                  placeholder={Strings.newPasswordInput}
                  width="100%"
                  outlined
                />
              </Grid>
            </Grid>
            <Grid row marginTop="1rem" expanded>
              <Grid column sm={12} alignItems="center">
                <ButtonWrapper>
                  <Button
                    onClick={handleSubmit}
                    margin="0px"
                    disabled={isSubmitting}
                    width="100%"
                    type="submit"
                    $loading={isSubmitting}
                  >
                    {GenericText.submit}
                  </Button>
                </ButtonWrapper>
              </Grid>
            </Grid>
          </FormWrapper>
        </>
      )}
    </Formik>
  );
};

export default AdminUpdatePasswordForm;
