import {
  SET_ADMIN_USER,
  AdminUserState,
  AdminUserActionTypes,
} from './types';

const initialState: AdminUserState = {
  adminUser: null,
};

const adminProfileReducer = (
  state: AdminUserState = initialState,
  action: AdminUserActionTypes,
): AdminUserState => {
  switch (action.type) {
    case SET_ADMIN_USER:
      return {
        ...state,
        adminUser: action.adminUser,
      };
    default:
      return state;
  }
};

export default adminProfileReducer;
