import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';

import useStrings from '../../../../hooks/useStrings';
import { buildHandlers } from '../../../../components/Common/Table/handlers';
import {
  customerPaymentStatusOptions,
  customerStatusOptions,
  CUSTOMER_STATUS_DISPLAY,
  PAYMENT_STATUS_DISPLAY,
} from '../../../../CONSTANTS';

import Grid from '../../../../components/Common/Grid';
import Pagination from '../../../../components/Common/Table/Pagination';
import SubHeader from './SubHeader/SubHeader';

import { Spinner } from '../../../../components/UI/LoadingScreen/Styles';
import {
  TableWrapper,
  Select,
  TableJssOverrides,
  NoRecordsText,
} from '../../../../components/Common/Table/Styles';

import { TableColumn } from '../../../../components/Common/Table/types';
import { ApplicationState } from '../../../../lib/store';
import { CustomerFilterOptions, OrderDirection } from '../../../../lib/types/API';
import { CustomerDataView } from '../../../../lib/types/DBViews';
import { CustomerStatus, PaymentStatus } from '../../../../lib/types/Customer';
import { CustomerTableProps } from '../types';

const CustomerTable: React.FC<CustomerTableProps<CustomerFilterOptions>> = ({
  currentData,
  recordCount,
  filter,
  updateFilter,
  onRowClicked,
  handleUpdateCustomerStatus,
}) => {
  const columns: TableColumn<CustomerDataView>[] = [
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: false,
      omit: true,
    },
    {
      name: 'Name',
      selector: 'customerName',
      sortable: true,
      grow: 1.5,
      conditionalCellStyles: [
        {
          when: () => true,
          style: { fontWeight: 'bold' },
        },
      ],
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      grow: 2,
    },
    {
      name: 'Phone',
      selector: 'phone',
      sortable: true,
    },
    {
      name: 'Location',
      selector: 'state',
      sortable: true,
      grow: 0.1,
    },
    {
      name: 'Plans',
      selector: 'numberPlans',
      sortable: true,
      grow: 0.1,
    },
    {
      name: 'Payment',
      selector: 'paymentStatus',
      sortable: true,
      grow: 1.2,
      cell: ({ userId, paymentStatus }) => (
        <Select
          status={paymentStatus as string}
          statusType="PAYMENT"
          onChange={
            (val) => handleUpdateCustomerStatus(
              userId as string,
              undefined,
              PaymentStatus[val.target.value as keyof typeof PaymentStatus],
            )
          }
        >
          <option value={paymentStatus as string}>
            {PAYMENT_STATUS_DISPLAY[paymentStatus as string]}
          </option>
          {customerPaymentStatusOptions.map(({ value, label }) => value !== paymentStatus && (
            <option key={`paymentStatus - ${value}`} value={value}>{label}</option>
          ))}
        </Select>
      ),
    },
    {
      name: 'Status',
      selector: 'customerStatus',
      sortable: true,
      grow: 1.2,
      cell: ({ userId, customerStatus }) => (
        <Select
          status={customerStatus as string}
          statusType="CUSTOMER"
          onChange={
            (val) => handleUpdateCustomerStatus(
              userId as string,
              CustomerStatus[val.target.value as keyof typeof CustomerStatus],
            )
          }
        >
          <option value={customerStatus as string}>
            {CUSTOMER_STATUS_DISPLAY[customerStatus as string]}
          </option>
          {customerStatusOptions.map(({ value, label }) => value !== customerStatus && (
            <option key={`customerStatus - ${value}`} value={value}>{label}</option>
          ))}
        </Select>
      ),
    },
  ];
  const [STRINGS] = useStrings();

  const [searchTerm, setSearchTerm] = useState(filter.keyword || '');
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.currentTarget.value || '');
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const { loading } = useSelector((state: ApplicationState) => ({
    loading: state.loadingState.apiCallsInProgress > 0,
  }));

  const Loader = loading ? (
    <div>
      <Spinner />
    </div>
  ) : (
    <NoRecordsText>
      <p>{STRINGS.Components.Common.Table.noRecords}</p>
    </NoRecordsText>
  );

  const {
    handleCustomerStatusChange,
    handleCustomerStateChange,
    handleSearchComplete,
    handlePageChange,
    handleSortChange,
  } = buildHandlers<CustomerFilterOptions>(
    updateFilter,
    filter,
    undefined,
    undefined,
    undefined,
    handleSearchChange,
    setSearchTerm,
    setFilterMenuOpen,
  );

  const SubHeaderElement = (
    <SubHeader
      searchTerm={searchTerm}
      customFiltersOpen={filterMenuOpen}
      filter={filter}
      onCustomerStatusChange={handleCustomerStatusChange}
      onStateChange={handleCustomerStateChange}
      onSearchChange={handleSearchComplete}
      onOpenCustomFilters={() => setFilterMenuOpen(!filterMenuOpen)}
    />
  );

  return (
    <Grid row maxWidth="100%">
      <Grid column sm={12}>
        <TableWrapper>
          <DataTable
            subHeaderAlign="left"
            highlightOnHover
            noContextMenu
            noHeader
            onRowClicked={onRowClicked}
            selectableRows
            data={currentData}
            keyField="key"
            columns={columns}
            subHeader
            subHeaderComponent={SubHeaderElement}
            pagination
            paginationServer
            paginationPerPage={filter.rowsPerPage}
            paginationTotalRows={recordCount}
            onChangePage={handlePageChange}
            paginationComponent={Pagination}
            onSort={handleSortChange}
            sortServer
            defaultSortField={filter.orderColumn}
            defaultSortAsc={filter.orderDirection === OrderDirection.ASC}
            noDataComponent={Loader}
            customStyles={TableJssOverrides}
            style={{ borderRadius: 0 }}
          />
        </TableWrapper>
      </Grid>
    </Grid>
  );
};

export default CustomerTable;
