import {
  SET_SELECTED_PLAN_TRANSFERS,
  SelectedPlanTransfersState,
  SetSelectedPlanTransfersAction,
} from './types';

const initialState: SelectedPlanTransfersState = {
  selectedPlanTransfers: [],
};

const SelectedPlanTransfersReducer = (
  state: SelectedPlanTransfersState = initialState,
  action: SetSelectedPlanTransfersAction,
): SelectedPlanTransfersState => {
  switch (action.type) {
    case SET_SELECTED_PLAN_TRANSFERS:
      return {
        ...state,
        selectedPlanTransfers: action.planTransfers,
      };

    default:
      return state;
  }
};

export default SelectedPlanTransfersReducer;
